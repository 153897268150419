/* eslint-disable no-underscore-dangle */
import moment from 'moment'
import { getDiscount } from '../api/paymentApi'

export const emptyDiscount = {
  id: 'NO-DISCOUNT',
  name: '',
  alternativeName: '',
  amount: 0,
}

const removeExistingAdvertLineItemsFor = (propertyId, lineItems, products) => {
  return lineItems.filter(
    lineItem => lineItem.propertyId !== propertyId || !products.isAdvert(lineItem.sku)
  )
}

const removeExistingToLetBoardLineItemsFor = (propertyId, lineItems, products) => {
  return lineItems.filter(
    lineItem => lineItem.propertyId !== propertyId || !products.isToLetBoard(lineItem.sku)
  )
}

const summaryFromLineItems = (lineItems, products) => {
  return lineItems.reduce((summary, nextLineItem) => {
    const existingSummaryItem = summary.find(
      x => x.description.toLowerCase() === nextLineItem.description.toLowerCase()
    )

    const getCorrectCasingForDescription = (nextItemSku, description) => {
      if (products.isToLetBoard(nextItemSku)) {
        return '‘To Let’ board'
      }

      return description.toLowerCase()
    }

    if (existingSummaryItem) {
      const updatedSummaryItem = {
        price: existingSummaryItem.price + nextLineItem.price,
        quantity: existingSummaryItem.quantity + 1,
        description: getCorrectCasingForDescription(
          nextLineItem.sku,
          existingSummaryItem.description
        ),
      }
      const newSummary = summary.filter(
        summaryItems => summaryItems.description !== existingSummaryItem.description
      )
      return [...newSummary, updatedSummaryItem]
    }

    const newSummaryItem = {
      price: nextLineItem.price,
      quantity: 1,
      description: getCorrectCasingForDescription(nextLineItem.sku, nextLineItem.description),
    }

    return [...summary, newSummaryItem]
  }, [])
}

class Order {
  _lineItems
  _subtotalWithoutVat
  _totalWithVat
  _vatOnly
  _vatRate
  _currency
  _products

  constructor(vatRate, currency, products, authToken) {
    this._lineItems = []
    this._subtotalWithoutVat = 0
    this._totalWithVat = 0
    this._vatOnly = 0
    this._vatRate = vatRate
    this._currency = currency
    this._products = products
    this._authToken = authToken

    this.addAdvertisement = this.addAdvertisement.bind(this)
    this.advertisements = this.advertisements.bind(this)
    this.advertisementsToApiModel = this.advertisementsToApiModel.bind(this)
    this.boostCreditsToApiModel = this.boostCreditsToApiModel.bind(this)
    this.toLetBoardsToApiModel = this.toLetBoardsToApiModel.bind(this)
  }

  advertisements() {
    return this._lineItems.filter(lineItem => this._products.isAdvert(lineItem.sku))
  }

  boostCredits() {
    return this._lineItems.find(lineItem => this._products.isBoostCredits(lineItem.sku))
  }

  toLetBoards() {
    return this._lineItems.filter(lineItem => this._products.isToLetBoard(lineItem.sku))
  }

  containsAdvertisements() {
    return this._lineItems.some(lineItem => this._products.isAdvert(lineItem.sku))
  }

  containsToLetBoards() {
    return this._lineItems.some(lineItem => this._products.isToLetBoard(lineItem.sku))
  }

  updateAdvertisedFromDateForAllAdvertisements(advertisedFromDate) {
    this._lineItems = this._lineItems.map(lineItem => {
      if (this._products.isAdvert(lineItem.sku)) {
        return { ...lineItem, startingDate: advertisedFromDate }
      }
      return lineItem
    })
  }

  removeDeselectedProperties(selectedPropertyIds) {
    this._lineItems = this._lineItems.filter(lineItem => {
      const isBoostCredits = this._products.isBoostCredits(lineItem.sku)

      const isAdvertForASelectedPropertyId =
        this._products.isAdvert(lineItem.sku) && selectedPropertyIds.includes(lineItem.propertyId)

      const isToLetBoardForASelectedPropertyId =
        this._products.isToLetBoard(lineItem.sku) &&
        selectedPropertyIds.includes(lineItem.propertyId)

      return isBoostCredits || isAdvertForASelectedPropertyId || isToLetBoardForASelectedPropertyId
    })
  }

  advertisementsToApiModel() {
    return this.advertisements().map(advertisement => {
      return {
        ...advertisement,
        startingDate: `${moment(advertisement.startingDate, 'DD/MM/YYYY').format(
          'YYYY-MM-DDT00:00:00.000'
        )}`,
      }
    })
  }

  boostCreditsToApiModel() {
    const boostCreditsInBasket = this.boostCredits()

    if (!boostCreditsInBasket) {
      return {}
    }

    const { sku, price } = boostCreditsInBasket
    return {
      sku,
      price,
    }
  }

  toLetBoardsToApiModel() {
    return this.toLetBoards()
  }

  addAdvertisement(propertyId, startingDate, product) {
    this._lineItems = removeExistingAdvertLineItemsFor(propertyId, this._lineItems, this._products)

    this._lineItems.push({
      ...product,
      propertyId,
      startingDate,
    })
  }

  addBoostCredits(product) {
    this._lineItems.push({
      ...product,
    })
  }

  addToLetBoard(propertyId, product) {
    this._lineItems = removeExistingToLetBoardLineItemsFor(
      propertyId,
      this._lineItems,
      this._products
    )

    this._lineItems.push({
      ...product,
      propertyId,
    })
  }

  removeToLetBoard(propertyId) {
    this._lineItems = removeExistingToLetBoardLineItemsFor(
      propertyId,
      this._lineItems,
      this._products
    )
  }

  async subtotalWithoutVat() {
    const subTotal = this._lineItems.reduce((total, nextLineItem) => total + nextLineItem.price, 0)
    const { discount } = await this.getDiscount()

    const subtotalWithoutVat = (subTotal - discount.amount).toFixed(2)
    return Number(subtotalWithoutVat)
  }

  nonDiscountedSubtotalWithoutVat() {
    const subTotal = this._lineItems.reduce((total, nextLineItem) => total + nextLineItem.price, 0)
    return Number(subTotal)
  }

  async vatOnly() {
    const vatOnly = (await this.subtotalWithoutVat()) * this._vatRate
    return Number(vatOnly.toFixed(2))
  }

  async totalWithVat() {
    const totalWithVat = (await this.subtotalWithoutVat()) + (await this.vatOnly())
    return Number(totalWithVat.toFixed(2))
  }

  async getDiscount() {
    const payload = {
      advertisementOrderItems: this.advertisementsToApiModel(),
    }

    const hasCachedDiscount =
      JSON.stringify(payload) === JSON.stringify(this._previousDiscountPayload)

    if (hasCachedDiscount) return Promise.resolve({ success: true, discount: this._cachedDiscount })

    const result = await getDiscount(payload, this._authToken)

    if (!result.success) return { success: false, discount: emptyDiscount }

    this._cachedDiscount = result.discount
    this._previousDiscountPayload = payload

    return { success: true, discount: result.discount }
  }

  async hasDiscountApplied() {
    const { discount } = await this.getDiscount()
    return discount.amount > 0
  }

  advertisementSummary() {
    return summaryFromLineItems(this.advertisements(), this._products)
  }

  boostCreditSummary() {
    if (this.boostCredits()) {
      return summaryFromLineItems([this.boostCredits()], this._products)
    }
    return []
  }

  toLetBoardSummary() {
    return summaryFromLineItems(this.toLetBoards(), this._products)
  }

  summary() {
    return [
      ...this.advertisementSummary(),
      ...this.boostCreditSummary(),
      ...this.toLetBoardSummary(),
    ]
  }

  lineItemCount() {
    return this._lineItems.length
  }
}

export default Order
