import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.scss'

const PropertyTitle = ({ className, rooms, type, area, ...props }) => {
  const titleClasses = classNames('property-title', className)
  const areaSegment = area === '' ? '' : `, ${area}`
  const title = `${rooms} bed ${type}${areaSegment}`

  return (
    <strong className={titleClasses} data-testid="property-title" {...props}>
      {title}
    </strong>
  )
}

PropertyTitle.propTypes = {
  className: PropTypes.string,
  rooms: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  area: PropTypes.string.isRequired,
}

export default PropertyTitle
