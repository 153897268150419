import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import './styles.scss'

const Link = ({ children, underlined = false, size = 'normal', className, ...props }) => {
  const componentClasses = classNames('link', className, {
    'link--underlined': underlined,
    'link--tiny': size === 'tiny',
  })

  if (props.to) {
    return (
      <NavLink className={componentClasses} {...props}>
        {children}
      </NavLink>
    )
  }
  return (
    <a className={componentClasses} {...props}>
      {children}
    </a>
  )
}

Link.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
  underlined: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
}

export default Link
