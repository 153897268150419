import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Spinner from '@afs/components/Spinner'

import loadingPage from './loadingPage.module.scss'

const LoadingPage = ({ className }) => {
  return (
    <div className={classNames(loadingPage.wrapper, className)} data-testid="loading-page">
      <Spinner className={loadingPage.spinner} primary />
    </div>
  )
}

LoadingPage.propTypes = {
  className: PropTypes.string,
}

export default LoadingPage
