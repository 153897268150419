import { getUserAgentRegex } from 'browserslist-useragent-regexp'

const browsersWeDontShowUpdateBannerOn =
  '> 1%, last 10 chrome versions, last 10 firefox versions, last 6 opera versions, last 2 versions, not ie 11, not op_mini all, not dead'

const modernBrowsersRegExp = getUserAgentRegex({
  browsers: browsersWeDontShowUpdateBannerOn,
  allowHigherVersions: true,
})

export default modernBrowsersRegExp
