import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import InfoTooltip from '@afs/components/InfoTooltip'

import Enquiry from '../../../models/enquiry'
import { config } from '../../../config'

import Card from '../../atoms/Card'
import IconButton from '../../molecules/IconButton'
import Heading from '../../atoms/Heading'

import useMedia from '../../../hooks/useMedia'
import DeleteEnquiryOverlay from './overlays/deleteEnquiryOverlay'
import Link from '../../atoms/Link'

import Chevron from '../../../svgs/icons/chevron-right.svg'
import EnquiryRemoved from '../../../svgs/icons/speech-bubble.svg'

import enquiryCard from './enquiryCard.module.scss'
import './transitions.scss'

const EnquiryCard = ({ enquiry, deleteEnquiry, isFeedAccount, ...props }) => {
  const propertyUrl = isFeedAccount
    ? `${config.mainSiteBaseUrl}property/${enquiry.propertyId()}`
    : `/landlord-admin/property/${enquiry.propertyId()}`

  const emailLink = `mailto:${enquiry.studentEmail()}?subject=${enquiry.emailSubject()}&body=${enquiry.emailBody()}`
  const phoneNumber = enquiry.studentPhone()
  const { hasPhoneNumber, isMigrated } = enquiry

  const showShortButtonText = useMedia(
    [
      '(max-width: 400px)',
      '(min-width: 661px) and (max-width: 800px)',
      '(min-width: 961px) and (max-width: 1184px)',
    ],
    [true, true, true],
    false
  )

  const [visible, setVisible] = useState(true)
  const [overlay, setOverlay] = useState({ active: false, error: false })

  const renderBottomCard = () => (
    <Link className={enquiryCard.link} href={propertyUrl} target="_blank" rel="noopener noreferrer">
      <p className={enquiryCard.address}>
        <strong className={enquiryCard.addressFirstLine}>
          {enquiry.propertyAddressFirstLine()},
        </strong>
        <span className={enquiryCard.addressRest}>
          {enquiry.propertyAddressWithoutFirstLineAndPostcode()},
        </span>
        <span className={classNames(enquiryCard.postcode, enquiryCard.addressRest)}>
          {' '}
          {enquiry.propertyPostcode()}
        </span>
      </p>
      <Chevron className={enquiryCard.chevron} />
    </Link>
  )

  const renderStudentName = isMigrated => {
    return isMigrated ? (
      <div className={enquiryCard.missingName}>
        <Heading className={enquiryCard.missingNameText} level={4}>
          Name is missing
        </Heading>
        <InfoTooltip
          wide
          tooltipContent={
            <>
              This enquiry has been imported from the old admin area.
              <span className={enquiryCard.toolTipText}>
                Some of the datafields are a new addition and were previously missing.
              </span>
            </>
          }
        />
      </div>
    ) : (
      <h3 className={enquiryCard.name}>{enquiry.studentName()}</h3>
    )
  }

  const handleCloseOverlay = () => {
    setOverlay({ active: false, error: false })
  }

  const handleDeleteEnquiry = async () => {
    const response = await deleteEnquiry(enquiry.id)
    if (response.success) {
      handleCloseOverlay()
      setVisible(false)
    } else {
      setOverlay({ active: true, error: true })
    }
  }

  return (
    <div className={enquiryCard.cardWrapper}>
      <Card
        middleCardClass={visible ? '' : enquiryCard.middleCard}
        bottomCard={visible ? renderBottomCard() : ''}
        noPadding
        data-testid="enquiry-card"
        {...props}
      >
        <article className={visible ? enquiryCard.removedCardHidden : enquiryCard.removedCard}>
          <EnquiryRemoved className={enquiryCard.removedIcon} />
          <p className={enquiryCard.removedText}>Enquiry removed.</p>
        </article>

        <CSSTransition in={visible} timeout={300} classNames="fade">
          <article className={visible ? enquiryCard.article : enquiryCard.articleHidden}>
            <header className={enquiryCard.header}>
              <div className={enquiryCard.row}>
                {renderStudentName(isMigrated)}
                <time
                  className={classNames(enquiryCard.time, 'u-hidden-in-percy')}
                  dateTime={enquiry.dateTime.format()}
                  data-testid="enquiry-date"
                >
                  {enquiry.postedToday() ? (
                    <>
                      {`${enquiry.timeString()} `}
                      <span className={enquiryCard.period}>{enquiry.timePeriodString()}</span>
                    </>
                  ) : (
                    enquiry.dateString()
                  )}
                </time>
              </div>
              {visible && (
                <Link className={enquiryCard.email} href={emailLink} target="_blank">
                  {enquiry.studentEmail()}
                </Link>
              )}

              {hasPhoneNumber ? (
                <Link className={enquiryCard.phone} href={`tel:${phoneNumber}`}>
                  {phoneNumber}
                </Link>
              ) : (
                <span className={enquiryCard.noPhoneNumber} data-testid="no-phone-number">
                  —
                </span>
              )}
              <p className={enquiryCard.text}>{enquiry.message}</p>
            </header>
            <div className={enquiryCard.contentWrapper}>
              <div className={enquiryCard.buttonWrapper}>
                <IconButton
                  className={enquiryCard.replyButton}
                  icon={{
                    icon: 'email',
                    width: '16',
                    height: '16',
                    viewBox: '0 0 16 16',
                  }}
                  secondary
                  href={emailLink}
                  target="_blank"
                >
                  {showShortButtonText ? 'Reply' : 'Reply to message'}
                </IconButton>
                <IconButton
                  className={enquiryCard.deleteButton}
                  icon={{
                    icon: 'delete',
                    width: '18',
                    height: '18',
                    viewBox: '0 0 18 18',
                  }}
                  primary
                  onClick={() => setOverlay({ active: true, error: false })}
                  data-testid="enquiry-delete-button"
                >
                  Delete
                </IconButton>
                <DeleteEnquiryOverlay
                  isActive={overlay.active}
                  handleDeleteEnquiry={handleDeleteEnquiry}
                  handleCloseOverlay={handleCloseOverlay}
                  showError={overlay.error}
                />
              </div>
            </div>
          </article>
        </CSSTransition>
      </Card>
    </div>
  )
}

EnquiryCard.propTypes = {
  enquiry: PropTypes.instanceOf(Enquiry).isRequired,
  deleteEnquiry: PropTypes.func.isRequired,
  isFeedAccount: PropTypes.bool,
}

export default EnquiryCard
