import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './banner.module.scss'

const Banner = ({ className, children, ...props }) => {
  return (
    <div className={classNames(styles.banner, className)} {...props}>
      {children}
    </div>
  )
}

Banner.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
}

export default Banner
