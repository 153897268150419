import React from 'react'
import PropTypes from 'prop-types'
import Label from '@afs/components/Label'

import Icon from '../../atoms/Icon'

const FieldAddressLabel = ({ address, inputId, ...props }) => {
  return (
    <Label className="address-list__label" htmlFor={inputId} key={inputId} {...props}>
      <span className="address-list__icon">
        <Icon
          icon="tick"
          className="address-list__media"
          width="9"
          height="9"
          viewBox="0 0 12 12"
          fill="#FFFFFF"
        />
      </span>
      <span className="address-list__text">
        <strong>{address.firstLine()}, </strong>
        {address.withoutFirstLine().join(', ')}
      </span>
    </Label>
  )
}

FieldAddressLabel.propTypes = {
  address: PropTypes.object,
  inputId: PropTypes.string,
}

export default FieldAddressLabel
