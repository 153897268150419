import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.scss'

import Link from '../../atoms/Link'

const AccountCard = ({ className, icon, link, text, tagline, showNewTag, ...props }) => {
  const accountCardClasses = classNames('account-card__link', className)

  return (
    <Link className={accountCardClasses} to={link} {...props}>
      <div className="account-card" data-testid="account-card">
        {showNewTag && <span className="account-card__new-tag">New</span>}
        {icon}
        <div className="account-card__text-wrapper">
          <p
            className={classNames('account-card__title', {
              'account-card__title--with-tagline': !!tagline,
            })}
          >
            {text}
          </p>
          {tagline && <p className="account-card__tagline">{tagline}</p>}
        </div>
      </div>
    </Link>
  )
}

AccountCard.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  text: PropTypes.string,
  tagline: PropTypes.string,
  icon: PropTypes.object,
  showNewTag: PropTypes.bool,
}

export default AccountCard
