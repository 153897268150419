import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Excellent from './svgs/trustpilot-excellent.svg'

import styles from './styles.module.scss'

const TrustpilotMicrostarRating = ({ className }) => {
  return (
    <Excellent
      className={classNames(styles.rating, className)}
      title="Accommodation for Students is rated Excellent on Trustpilot"
    />
  )
}

TrustpilotMicrostarRating.propTypes = {
  className: PropTypes.string,
}

export default TrustpilotMicrostarRating
