import React from 'react'
import PropTypes from 'prop-types'

import LazyImage from '../../atoms/LazyImage'

const Picture = ({ sources, src, alt, lazyload = true, ...props }) => {
  let Image
  if (lazyload) {
    Image = <LazyImage dataSrc={src} alt={alt} />
  } else {
    Image = <img src={src} alt={alt} />
  }

  return (
    <picture {...props}>
      {sources.map((source) => (
        <source key={source.id} srcSet={source.src} media={source.media} />
      ))}
      {Image}
    </picture>
  )
}

Picture.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.object).isRequired,
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  lazyload: PropTypes.bool,
}

export default Picture
