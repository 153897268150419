const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

const scrollToNotification = () => {
  if (!canUseDOM) return

  const firstNotificationElement = window.document.querySelectorAll('.notification')[0]

  if (firstNotificationElement !== undefined) {
    const topEdgeOfFirstNotification = firstNotificationElement.getBoundingClientRect().top
    const offset = 64
    const firstNotification = window.pageYOffset + topEdgeOfFirstNotification - offset

    window.scroll({ behavior: 'smooth', left: 0, top: firstNotification })
  }
}

export default scrollToNotification
