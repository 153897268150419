import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import paymentOptions from './paymentOptions.module.scss'

const PaymentOptions = ({ panels, currentPanel, showPanel, ...props }) => (
  <div className={paymentOptions.wrapper} {...props}>
    {panels.map(panel => {
      const { name, heading, subHeading, content } = panel
      const panelClasses = classNames(paymentOptions.panel, {
        [paymentOptions.isActive]: panel.name === currentPanel,
      })

      return (
        <div key={name} className={panelClasses}>
          <button
            className={paymentOptions.button}
            aria-expanded={currentPanel === name}
            onClick={() => showPanel(name)}
          >
            <span>
              <strong className={paymentOptions.buttonHeading}>{heading}</strong>
              <span className={paymentOptions.buttonSubHeading}>{subHeading}</span>
            </span>
          </button>
          <div
            className={paymentOptions.content}
            open={currentPanel === name}
            hidden={currentPanel !== name}
          >
            {content}
          </div>
        </div>
      )
    })}
  </div>
)

PaymentOptions.propTypes = {
  panels: PropTypes.array.isRequired,
  currentPanel: PropTypes.string.isRequired,
  showPanel: PropTypes.func.isRequired,
}

export default PaymentOptions
