import React from 'react'
import PropTypes from 'prop-types'

import { notificationService } from '../../../container'

import TwoColumnPageTemplate from '../../templates/TwoColumnPageTemplate'
import Header from '../../organisms/Header'
import EditFormContainer from '../../containers/EditFormContainer'
import Notifications from '../../organisms/Notifications'
import BackLink from '../../molecules/BackLink'

import editPage from './editPage.module.scss'

const EditPropertyFormPage = ({ history, match }) => {
  const renderLeftColumn = () => {
    return (
      <>
        <BackLink
          className="form-navigation__back-link"
          onClick={event => {
            event.preventDefault()
            notificationService.clearNotifications()
            history.goBack()
          }}
        />
        <Notifications className={editPage.notifications} />
        <EditFormContainer history={history} match={match} />
      </>
    )
  }

  return (
    <TwoColumnPageTemplate
      header={<Header />}
      title="Edit property details | AFS"
      leftColumn={renderLeftColumn()}
    />
  )
}

EditPropertyFormPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default EditPropertyFormPage
