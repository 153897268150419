import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { getAllFeedEnquiries, deleteFeedEnquiryById } from '../../api/enquiryApi'
import { useAuthContext } from '../../authContext'

import EnquiriesPageFeeds from '../pages/EnquiriesPageFeeds'
import LoadingPage from '../pages/LoadingPage'
import ErrorPage from '../pages/ErrorPage'

export const EnquiriesContainerFeeds = ({
  getAllEnquiries,
  deleteEnquiryById,
  enquiriesPage,
  loadingPage,
  errorPage,
  useAuthContext,
}) => {
  const [enquiries, setEnquiries] = useState([])
  const [loading, setLoading] = useState(true)
  const [showError, setShowError] = useState(false)

  const { token } = useAuthContext()

  useEffect(() => {
    getAllEnquiries(token).then(response => {
      if (response.success) {
        setEnquiries(response.enquiries)
        setLoading(false)
      } else {
        setShowError(true)
      }
    })
  }, [])

  if (showError) return errorPage()
  if (loading) return loadingPage()

  const deleteEnquiry = async id => {
    const response = await deleteEnquiryById(id, token)

    return response
  }

  return enquiriesPage(enquiries, deleteEnquiry)
}

EnquiriesContainerFeeds.propTypes = {
  getAllEnquiries: PropTypes.func.isRequired,
  deleteEnquiryById: PropTypes.func.isRequired,
  enquiriesPage: PropTypes.func.isRequired,
  loadingPage: PropTypes.func.isRequired,
  errorPage: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
}

export const EnquiriesContainerFeedsCompositionRoot = () => {
  return (
    <EnquiriesContainerFeeds
      getAllEnquiries={getAllFeedEnquiries}
      deleteEnquiryById={deleteFeedEnquiryById}
      enquiriesPage={(enquiries, deleteEnquiry) => (
        <EnquiriesPageFeeds enquiries={enquiries} deleteEnquiry={deleteEnquiry} />
      )}
      loadingPage={() => <LoadingPage />}
      errorPage={() => <ErrorPage />}
      useAuthContext={useAuthContext}
    />
  )
}
