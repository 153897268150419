import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { config } from '../../config'
import { getBoostableProperties } from '../../api/propertyApi'
import { useAuthContext } from '../../authContext'
import { useStateContext } from '../../appStateContext'

import LoadingPage from '../pages/LoadingPage'
import ErrorPage from '../pages/ErrorPage'
import BoostPropertiesPage from '../pages/BoostPropertiesPage'
import { useIntercom } from 'react-use-intercom'

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

const intercomActive = config.intercomActive && window.innerWidth >= 768

export const BoostPropertiesContainer = ({
  boostPropertiesPage,
  getBoostableProperties,
  useAuthContext,
  useStateContext,
  errorPage,
  history,
}) => {
  const [loading, setLoading] = useState(true)
  const [properties, setProperties] = useState([])
  const [selectedPropertyIds, setSelectedPropertyIds] = useState([])
  const [serverError, setServerError] = useState(false)
  const { token } = useAuthContext()
  const { propertyIds } = useStateContext()

  const { hide, update } = useIntercom()

  const hideIntercomLauncher = () => {
    if (canUseDOM && intercomActive) {
      hide()
      update({ hideDefaultLauncher: true })
    }
  }

  const showIntercomLauncher = () => {
    if (canUseDOM && intercomActive) {
      update({ hideDefaultLauncher: false })
    }
  }

  useEffect(() => {
    getBoostableProperties(token).then((response) => {
      if (response.success) {
        setProperties(response.properties)
        setSelectedPropertyIds(propertyIds.selected())
        setLoading(false)
      } else {
        setLoading(false)
        setServerError(true)
      }
    })
  }, [])

  useEffect(() => {
    if (properties.length === 0) {
      showIntercomLauncher()
    } else {
      hideIntercomLauncher()
    }
    return () => showIntercomLauncher()
  }, [properties])

  const toggleSelectProperty = (propertyId) => {
    if (propertyIds.selected().includes(propertyId)) {
      propertyIds.deselect(propertyId)

      const updatedPropertyIds = [...propertyIds.selected()]
      setSelectedPropertyIds(updatedPropertyIds)
    } else {
      propertyIds.select(propertyId)

      const updatedPropertyIds = [...propertyIds.selected()]
      setSelectedPropertyIds(updatedPropertyIds)
    }
  }

  const deselectAllProperties = () => {
    setSelectedPropertyIds([])
    propertyIds.deselectAll()
  }

  const selectAllProperties = () => {
    const allPropertyIds = properties.map((property) => property.id)
    setSelectedPropertyIds(allPropertyIds)
    allPropertyIds.map((propertyId) => propertyIds.select(propertyId))
  }

  if (serverError) return errorPage()

  if (loading) return <LoadingPage />

  return boostPropertiesPage(
    properties,
    selectedPropertyIds,
    toggleSelectProperty,
    deselectAllProperties,
    selectAllProperties,
    history
  )
}

BoostPropertiesContainer.propTypes = {
  boostPropertiesPage: PropTypes.func.isRequired,
  getBoostableProperties: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
  useStateContext: PropTypes.func.isRequired,
  errorPage: PropTypes.func.isRequired,
}

export const BoostPropertiesContainerCompositionRoot = ({ history }) => {
  return (
    <BoostPropertiesContainer
      boostPropertiesPage={(
        properties,
        selectedPropertyIds,
        toggleSelectProperty,
        deselectAllProperties,
        selectAllProperties,
        history
      ) => (
        <BoostPropertiesPage
          properties={properties}
          selectedPropertyIds={selectedPropertyIds}
          toggleSelectProperty={toggleSelectProperty}
          deselectAllProperties={deselectAllProperties}
          selectAllProperties={selectAllProperties}
          history={history}
        />
      )}
      useAuthContext={useAuthContext}
      getBoostableProperties={getBoostableProperties}
      errorPage={() => <ErrorPage />}
      history={history}
      useStateContext={useStateContext}
    />
  )
}

BoostPropertiesContainerCompositionRoot.propTypes = {
  history: PropTypes.object.isRequired,
}
