import React from 'react'
import PropTypes from 'prop-types'

import { getAccountEmailForResetPassword } from '../../../api/accountApi'

import PageTemplate from '../../templates/PageTemplate'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import ResetPasswordForm from '../../organisms/ResetPasswordForm'
import Heading from '../../atoms/Heading'

import styles from './newLandlordAdminPasswordResetPage.module.scss'

const NewLandlordAdminPasswordResetPage = ({
  history,
  resetPasswordFormViewModel,
  notificationService,
}) => {
  return (
    <PageTemplate
      className={styles.page}
      header={<Header />}
      footer={<Footer />}
      title="Reset Password | AFS"
    >
      <div className={styles.content}>
        <Heading className={styles.topHeading} level={4} data-testid="new-password-top-heading">
          The all-new Landlord Admin
        </Heading>
        <Heading className={styles.mainHeading} level={1} data-testid="new-password-main-heading">
          Reset your password
        </Heading>
        <p className={styles.text} data-testid="new-password-text">
          Before you can access your account, we will need you to{' '}
          <strong>set a new password.</strong>
        </p>
        <ResetPasswordForm
          className={styles.form}
          history={history}
          viewModel={resetPasswordFormViewModel}
          notificationService={notificationService}
          getLandlordEmailForResetPassword={getAccountEmailForResetPassword}
          buttonText="Set new password"
        />
      </div>
    </PageTemplate>
  )
}

NewLandlordAdminPasswordResetPage.propTypes = {
  history: PropTypes.object.isRequired,
  resetPasswordFormViewModel: PropTypes.func.isRequired,
  notificationService: PropTypes.object.isRequired,
}

export default NewLandlordAdminPasswordResetPage
