import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SpinnerButton from '@afs/components/SpinnerButton'

import Notification from '../Notification'

import styles from './accountSettingsForm.module.scss'

const AccountSettingsForm = ({
  className,
  description,
  children,
  onSubmit,
  invalid,
  loading,
  notifications,
  formName,
  ...props
}) => {
  const hasNotifications = notifications && notifications.length > 0

  const buttonText = formName === 'password' ? 'Update password' : 'Save changes'

  return (
    <form
      className={classNames(styles.form, className)}
      onSubmit={!loading ? onSubmit : undefined}
      {...props}
    >
      {description && <p className={styles.description}>{description}</p>}
      {children}
      {hasNotifications && (
        <div>
          {notifications.map(notification => (
            <Notification
              key={notification}
              className={styles.formNotification}
              type="error"
              text={notification}
            />
          ))}
        </div>
      )}
      <SpinnerButton
        className={styles.submit}
        primary
        disabled={invalid}
        inactive={loading}
        data-testid="save-button"
      >
        {buttonText}
      </SpinnerButton>
    </form>
  )
}

AccountSettingsForm.propTypes = {
  className: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.any,
  onSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  loading: PropTypes.bool,
  notifications: PropTypes.arrayOf(PropTypes.string),
  formName: PropTypes.string,
}

export default AccountSettingsForm
