import React, { memo, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SpinnerButton from '@afs/components/SpinnerButton'

import { truncateStringInTheMiddle } from '../../../utils/truncate'
import convertBytesToMb from '../../../utils/convertBytesToMb'
import reorderPhotos from './reorderPhotos'

import Button from '../../atoms/Button'
import Overlay from '../Overlay'
import DraggableList from './DraggableList'
import Notification from '../Notification'

import PictureIcon from '../../../svgs/icons/picture.svg'

import './styles.scss'

const FieldImageUpload = ({
  uploadedImages = {
    items: [],
    order: [],
  },
  onSyncState,
  accepts = ['image/png', 'image/jpeg'],
  maximumUploads = 15,
  maxFileSizeInMb = 6,
  forceRenderList,
  storageService,
  imageApiUrl,
  useMeasure,
}) => {
  const [bindMeasure, { width: containerWidth }] = useMeasure()
  const [notifications, setNotifications] = useState({
    errors: [],
    success: [],
  })
  const [images, setImages] = useState(uploadedImages)
  const [overlay, setOverlay] = useState({
    active: false,
    photo: null,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    onSyncState({
      images,
      loading,
    })
  }, [images, loading])

  const clearNotifications = () => setNotifications({ errors: [], success: [] })

  const saveFiles = async (files) => {
    const savedPhotoResults = await storageService.savePhotos(files)
    const successfulUploads = savedPhotoResults.filter((result) => result.success)
    const unsuccessfulUploads = savedPhotoResults.filter((result) => !result.success)

    return {
      successfulUploads,
      unsuccessfulUploads,
    }
  }

  const removePhoto = async (idToRemove) => {
    const photoSrc = images.items.find((photo) => photo.id === idToRemove).src
    const deletionResult = await storageService.removePhoto(photoSrc)
    const photoHasBeenDeleted = deletionResult.success

    if (photoHasBeenDeleted) {
      setImages((prevState) => {
        const updatedPhotos = prevState.items.filter((photo) => photo.id !== idToRemove)
        const updatedOrder = prevState.order.filter((i) => i !== idToRemove)

        return reorderPhotos(updatedPhotos, updatedOrder, idToRemove)
      })
    }
  }

  const truncateFilesIfOverMaxUploads = (files) => {
    const alreadyUploadedCount = images.items.length
    const totalBeingUploadedCount = files.length
    const totalAttemptedUploads = alreadyUploadedCount + totalBeingUploadedCount
    let truncatedFiles = [...files]

    if (totalAttemptedUploads >= maximumUploads) {
      setNotifications((prevNotifications) => ({
        ...prevNotifications,
        errors: [
          ...prevNotifications.errors,
          {
            heading: 'Photos limit reached.',
            text: `Maximum upload limit of ${maximumUploads} images reached.`,
            type: 'warning',
          },
        ],
      }))

      const delta = totalAttemptedUploads - maximumUploads

      const remainingPhotosAllowedCount = files.length - delta

      truncatedFiles = files.slice(0, remainingPhotosAllowedCount)
    }

    return truncatedFiles
  }

  const validateFiles = (files) => {
    const isAcceptedFileType = (file) => accepts.indexOf(file.type) !== -1
    const isBelowMaxFilesize = (file) => convertBytesToMb(file.size) <= maxFileSizeInMb
    const isAboveMaxFilesize = (file) => convertBytesToMb(file.size) > maxFileSizeInMb

    const validFiles = files.filter((file) => isAcceptedFileType(file) && isBelowMaxFilesize(file))

    const invalidFiles = files.filter(
      (file) => isAcceptedFileType(file) && isAboveMaxFilesize(file)
    )

    return {
      validFiles,
      invalidFiles,
    }
  }

  const handleFileUpload = async (event) => {
    clearNotifications()
    setLoading(true)

    const filesInThisUpload = Array.from(event.target.files)
    const truncatedFiles = truncateFilesIfOverMaxUploads(filesInThisUpload)
    const { validFiles, invalidFiles } = validateFiles(truncatedFiles)

    const invalidFilesErrors = invalidFiles.map((invalidFile) => {
      return {
        heading: 'File size is too large!',
        text: `This photo (${truncateStringInTheMiddle(
          invalidFile.name,
          25
        )}) exceeds the ${maxFileSizeInMb}MB file size limit.`,
        type: 'error',
      }
    })

    if (invalidFilesErrors.length > 0) {
      setNotifications((prevNotifications) => ({
        ...prevNotifications,
        errors: invalidFilesErrors,
      }))
    }

    if (validFiles.length === 0) {
      setLoading(false)
      return
    }

    const { successfulUploads, unsuccessfulUploads } = await saveFiles(validFiles)

    if (unsuccessfulUploads.length > 0) {
      const unsuccessfulUploadErrors = unsuccessfulUploads.map((error) => ({
        heading: 'Upload failed.',
        text: `This photo (${error.originalFilename}) hasn't been uploaded.`,
        type: 'error',
      }))

      setNotifications((prevNotifications) => {
        return {
          ...prevNotifications,
          errors: prevNotifications.errors.concat(unsuccessfulUploadErrors),
        }
      })
    }

    if (successfulUploads.length > 0) {
      const savedPhotosItems = successfulUploads.map((savedFile, index) => ({
        id: images.items.length + index,
        filename: savedFile.filename,
        src: savedFile.fileUrl,
      }))

      const savedPhotosOrder = successfulUploads.map(
        (savedFile, index) => images.items.length + index
      )

      setImages((prevState) => ({
        items: [...prevState.items, ...savedPhotosItems],
        order: [...prevState.order, ...savedPhotosOrder],
      }))

      setNotifications((prevNotifications) => {
        const photoText = successfulUploads.length > 1 ? 'photos' : 'photo'
        return {
          ...prevNotifications,
          success: [
            ...prevNotifications.success,
            {
              heading: `${photoText.charAt(0).toUpperCase() + photoText.slice(1)} uploaded!`,
              text: `You successfully uploaded ${successfulUploads.length} ${photoText}.`,
            },
          ],
        }
      })
    }

    setLoading(false)
  }

  const handleOnDrop = useCallback(
    (order) => {
      if (order !== images.order) {
        setImages((prevState) => ({
          ...prevState,
          order,
        }))
      }
    },
    [images.order]
  )

  const handleOnClick = useCallback((photo) => {
    setOverlay({
      active: true,
      photo,
    })
  })

  const handleOverlayClose = () =>
    setOverlay({
      active: false,
      photo: null,
    })

  const handleRemovePhoto = () => {
    removePhoto(overlay.photo.id).then(() => {
      clearNotifications()
      handleOverlayClose()
    })
  }

  const renderUploadedImages = () => {
    if (containerWidth === 0 && !forceRenderList) {
      return false
    }

    return (
      <DraggableList
        className="field-upload__grid"
        images={images}
        containerWidth={containerWidth}
        onDrop={handleOnDrop}
        onClick={handleOnClick}
        imageApiUrl={imageApiUrl}
      />
    )
  }

  const renderDefaultGrid = () => (
    <div className="field-upload__grid field-upload__grid--empty" data-testid="upload-grid">
      <div className="field-upload__item field-upload__item--placeholder">
        <PictureIcon className="field-upload__item-icon" />
      </div>
      <div className="field-upload__item field-upload__item--placeholder">
        <PictureIcon className="field-upload__item-icon" />
      </div>
      <div className="field-upload__item field-upload__item--placeholder">
        <PictureIcon className="field-upload__item-icon" />
      </div>
    </div>
  )

  const renderNotifications = () => {
    const { errors, success } = notifications

    return (
      <div className="field-upload__notifications">
        {errors.length > 0 && (
          <div className="field-upload__errors" data-testid="upload-errors">
            {errors.map((error, index) => (
              <Notification
                key={error.text}
                className="field-upload__notifications-item"
                data-testid={`upload-error-${index}`}
                {...error}
              />
            ))}
          </div>
        )}
        {success.length > 0 && (
          <div className="field-upload__success" data-testid="upload-success">
            {success.map((success, index) => (
              <Notification
                key={success.text}
                className="field-upload__notifications-item"
                type="success"
                data-testid={`upload-success-${index}`}
                {...success}
              />
            ))}
          </div>
        )}
      </div>
    )
  }

  const renderUploadButton = () => {
    const hasUploads = images.items.length > 0
    const buttonClasses = classNames('field-upload__button', {
      'field-upload__button--secondary': hasUploads,
    })
    const buttonLabel = hasUploads ? 'Upload More Photos' : 'Upload Photos'

    if (loading) {
      return (
        <SpinnerButton className="field-upload__spinner" primary inactive>
          {buttonLabel}
        </SpinnerButton>
      )
    }

    return (
      <>
        <input
          id="property-images"
          className="field-upload__button-field"
          type="file"
          accept={accepts.join(',')}
          onChange={handleFileUpload}
          multiple
          data-testid="upload-field"
        />
        <label
          className={buttonClasses}
          htmlFor="property-images"
          aria-label={buttonLabel}
          data-testid="upload-button"
        >
          <PictureIcon className="field-upload__button-icon" />
          {buttonLabel}
        </label>
      </>
    )
  }

  const renderRemovePhotoOverlay = () => {
    if (!overlay.photo) {
      return null
    }

    return (
      <Overlay
        id="remove-photo"
        heading="Remove photo"
        closeLabel="Cancel"
        isActive={overlay.active}
        onClose={handleOverlayClose}
      >
        <div className="field-upload__overlay photo-overlay">
          <p
            className="photo-overlay__text"
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{
              __html:
                'Are you sure you want to remove this photo? This action <strong>can not be undone</strong>.',
            }}
          />
          <figure className="photo-overlay__figure">
            <img
              src={`${
                imageApiUrl ? `${imageApiUrl}w=228/${overlay.photo.src}` : overlay.photo.src
              }`}
              alt=""
            />
          </figure>
          <div className="photo-overlay__actions">
            <Button onClick={handleRemovePhoto} data-testid="button-remove-photo" deletion>
              Remove photo
            </Button>
            <Button onClick={handleOverlayClose}>Cancel</Button>
          </div>
        </div>
      </Overlay>
    )
  }

  return (
    <div className="field-upload">
      <div className="field-upload__wrapper" {...bindMeasure}>
        {images.items.length > 0 ? renderUploadedImages() : renderDefaultGrid()}
      </div>
      {renderNotifications()}
      {images.items.length < maximumUploads && renderUploadButton()}
      {renderRemovePhotoOverlay()}
    </div>
  )
}

FieldImageUpload.propTypes = {
  uploadedImages: PropTypes.object,
  onSyncState: PropTypes.func.isRequired,
  accepts: PropTypes.array,
  maximumUploads: PropTypes.number.isRequired,
  maxFileSizeInMb: PropTypes.number,
  forceRenderList: PropTypes.bool,
  storageService: PropTypes.object.isRequired,
  imageApiUrl: PropTypes.string,
  useMeasure: PropTypes.func.isRequired,
}

export default memo(FieldImageUpload)
