import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import InfoTooltip from '@afs/components/InfoTooltip'
import useMedia from '../../../hooks/useMedia'

import SingleColumnFormTemplate from '../../templates/SingleColumnFormTemplate'
import BillsOptionalPropertySelectionCard from '../../molecules/BillsOptionalPropertySelectionCard'
import LinkButton from '../../atoms/LinkButton'
import Button from '../../atoms/Button'
import RemoveBillsIncludedExplainerOverlay from '../../organisms/RemoveBillsIncludedExplainerOverlay'
import Link from '../../atoms/Link'

import SelectIcon from './svgs/select.svg'
import DeselectIcon from './svgs/deselect.svg'
import TooltipIcon from '../BillsOptionalAddUtilityBillsPage/svgs/tooltip-icon.svg'

import styles from './styles.module.scss'

const statusCodes = {
  noProperties: 'BO-2001',
  allPropertiesAlreadyBillsOptional: 'BO-2002',
  noEligibleProperties: 'BO-2003',
}

const BillsOptionalSelectPropertiesPage = ({
  history,
  properties,
  statusCode,
  selectedPropertyIds,
  toggleSelectProperty,
  selectAllProperties,
  deselectAllProperties,
  handleSubmit,
}) => {
  const allPropertiesSelected = properties.every(property => {
    return selectedPropertyIds.includes(property.propertyId)
  })

  const isMobile = useMedia(['(max-width: 660px)'], [true], false)

  const renderContent = () => {
    return properties.map(property => (
      <BillsOptionalPropertySelectionCard
        key={property.propertyId}
        className={styles.card}
        property={property}
        onChange={toggleSelectProperty}
        checked={selectedPropertyIds.includes(property.propertyId)}
      />
    ))
  }

  const renderNoProperties = () => {
    if (statusCode === statusCodes.noProperties) {
      return (
        <div className={styles.noPropertiesMessage}>
          <strong className={styles.noPropertiesHeading}>You don’t have any properties</strong>

          <Link className={styles.noPropertiesLink} to="/landlord-admin/add-property">
            Add a property here
          </Link>
        </div>
      )
    }

    if (statusCode === statusCodes.allPropertiesAlreadyBillsOptional) {
      return (
        <div className={styles.noPropertiesMessage}>
          <strong className={styles.noPropertiesHeading}>
            Your properties are already bills optional
          </strong>

          <Link className={styles.noPropertiesLink} to="/landlord-admin/bills-optional/properties">
            View your ‘Bills optional’ properties
          </Link>
        </div>
      )
    }

    return (
      <div
        className={classNames(styles.noPropertiesMessage, styles.noPropertiesMessageLeftAligned)}
      >
        <strong className={styles.noPropertiesHeading}>
          You don’t have any eligible properties
        </strong>
        <p className={styles.noPropertiesText}>
          Only properties that have ‘No bills’ included are eligible for a ‘Bills optional’ quote.
        </p>
        <p className={styles.noPropertiesText}>
          You can easily edit your properties by removing the bills included on the listing.{' '}
          <RemoveBillsIncludedExplainerOverlay
            renderButton={onClick => (
              <LinkButton className={styles.noPropertiesLink} onClick={onClick}>
                Learn how
              </LinkButton>
            )}
          />
        </p>

        <Link className={styles.noPropertiesLink} to="/landlord-admin/properties">
          Go to Properties
        </Link>
      </div>
    )
  }

  return (
    <SingleColumnFormTemplate
      heading="1. Select your properties"
      subheading={
        <>
          Select from your{' '}
          <InfoTooltip
            className={styles.tooltip}
            tooltipContent="Only properties that have ‘No bills’ included are eligible for a ‘Bills optional’ quote."
            icon={<TooltipIcon className={styles.tooltipIcon} />}
            wide
            position={isMobile ? 'bottom' : 'top'}
          >
            <strong>eligible properties</strong>
          </InfoTooltip>{' '}
          below to add a bills package quote.
        </>
      }
      title="Select Your Properties | Bills Optional | AFS"
      history={history}
      showStickyMenu={selectedPropertyIds.length > 0}
      stickyMenuContent={
        <div className={styles.menuContent}>
          {allPropertiesSelected ? (
            <LinkButton className={styles.selectButton} onClick={deselectAllProperties}>
              <DeselectIcon className={styles.buttonIcon} />
              Deselect all
            </LinkButton>
          ) : (
            <LinkButton className={styles.selectButton} onClick={selectAllProperties}>
              <SelectIcon className={styles.buttonIcon} />
              Select all
            </LinkButton>
          )}
          <span className={styles.selectedCount} data-testid="selected-property-counter">
            {selectedPropertyIds.length} selected
          </span>
          <Button className={styles.nextButton} primary onClick={handleSubmit}>
            Next
          </Button>
        </div>
      }
    >
      {properties.length > 0 ? renderContent() : renderNoProperties()}
    </SingleColumnFormTemplate>
  )
}

BillsOptionalSelectPropertiesPage.propTypes = {
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      propertyId: PropTypes.number.isRequired,
      photo: PropTypes.string.isRequired,
      hasPhoto: PropTypes.bool.isRequired,
      address: PropTypes.shape({
        line1: PropTypes.string.isRequired,
        line2: PropTypes.string.isRequired,
      }),
      rentFrom: PropTypes.string.isRequired,
    })
  ),
  statusCode: PropTypes.string.isRequired,
  selectedPropertyIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  toggleSelectProperty: PropTypes.func.isRequired,
  selectAllProperties: PropTypes.func.isRequired,
  deselectAllProperties: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default BillsOptionalSelectPropertiesPage
