import React from 'react'
import PropTypes from 'prop-types'
import FieldAddressLabel from './FieldAddressLabel'
import './styles.scss'

const FieldAddressListRow = ({ name, address, inputId, selected, onSelect }) => {
  return (
    <div className="address-list__result" data-testid="address-list-row">
      <input
        id={inputId}
        className="address-list__input"
        type="radio"
        name={name}
        onChange={() => onSelect(address)}
        checked={selected}
        data-testid="address-list-input"
      />
      <FieldAddressLabel address={address} inputId={inputId} data-testid="address-list-label" />
    </div>
  )
}

FieldAddressListRow.propTypes = {
  name: PropTypes.string,
  address: PropTypes.object,
  inputId: PropTypes.string,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
}

export default FieldAddressListRow
