import bathroomPageConfig from './bathroomPageConfig'
import reviewPageConfig from './reviewPageConfig'
import lettingTypePageConfig from './lettingTypePageConfig'
import photoPageConfig from './photoPageConfig'
import availabilityPageConfig from './availabilityPageConfig'
import bedroomsPageConfig from './bedroomsPageConfig'
import descriptionPageConfig from './descriptionPageConfig'
import billsPageConfig from './billsPageConfig'
import amenitiesPageConfig from './amenitiesPageConfig'
import referenceNumberPageConfig from './referenceNumberPageConfig'
import videoTourPageConfig from './videoTourPageConfig'
import virtualTourPageConfig from './virtualTourPageConfig'

import {
  updatePropertyReferenceNumber,
  updatePropertyVideoTour,
  updatePropertyVirtualTour,
} from '../../../../api/propertyApi'

export const pageConfig = propertyId => [
  reviewPageConfig(propertyId),
  lettingTypePageConfig(propertyId),
  photoPageConfig(propertyId),
  availabilityPageConfig(propertyId),
  bathroomPageConfig(propertyId),
  amenitiesPageConfig(propertyId),
  billsPageConfig(propertyId),
  descriptionPageConfig(propertyId),
  bedroomsPageConfig(propertyId),
  referenceNumberPageConfig(propertyId, updatePropertyReferenceNumber),
  videoTourPageConfig(propertyId, updatePropertyVideoTour),
  virtualTourPageConfig(propertyId, updatePropertyVirtualTour),
]
