import React from 'react'
import PropTypes from 'prop-types'
import SpinnerButton from '@afs/components/SpinnerButton'

import Link from '../../atoms/Link'

import LockIcon from '../../../svgs/icons/lock.svg'

import paymentSubmit from './paymentSubmit.module.scss'

const PaymentSubmit = ({ loading }) => (
  <div className={paymentSubmit.component}>
    <div className={paymentSubmit.inner}>
      <SpinnerButton primary wide inactive={loading} type="submit" data-testid="pay-button">
        <LockIcon className={paymentSubmit.icon} />
        Pay Now
      </SpinnerButton>

      <p className={paymentSubmit.terms} data-testid="terms-and-conditions">
        By placing this order, you agree to our{' '}
        <Link
          href="https://www.accommodationforstudents.com/terms"
          target="_blank"
          size="tiny"
          underlined
        >
          Terms
        </Link>{' '}
        and understand our{' '}
        <Link
          href="https://www.accommodationforstudents.com/privacy"
          target="_blank"
          size="tiny"
          underlined
        >
          Privacy
        </Link>{' '}
        &{' '}
        <Link
          href="https://www.accommodationforstudents.com/privacy"
          target="_blank"
          size="tiny"
          underlined
        >
          Cookies Policy
        </Link>
        .
      </p>
    </div>
  </div>
)

PaymentSubmit.propTypes = {
  loading: PropTypes.bool,
}

export default PaymentSubmit
