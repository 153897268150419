import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import FieldCheckbox from '@afs/components/FieldCheckbox'
import Link from '../../atoms/Link'

import Forward from '../../../svgs/icons/forward.svg'

import styles from './styles.module.scss'

const SelectablePropertyCardListView = ({
  className,
  children,
  href,
  to,
  checkboxName,
  checkboxValue,
  checkboxLabel,
  checkboxOnChange,
  checked,
  ...props
}) => {
  return (
    <div className={classNames(styles.container, className)} {...props}>
      <Link
        href={href}
        to={to}
        className={classNames(styles.link, {
          [styles.linkSelected]: checked,
        })}
      >
        <div className={styles.children}>{children}</div>
        <Forward className={styles.forwardIcon} />
      </Link>
      <FieldCheckbox
        className={styles.checkbox}
        name={checkboxName}
        value={checkboxValue}
        label={checkboxLabel}
        checked={checked}
        onChange={checkboxOnChange}
        hideLabel
        data-testid="selectable-property-card-checkbox"
      />
    </div>
  )
}

SelectablePropertyCardListView.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  to: PropTypes.string,
  checkboxName: PropTypes.string.isRequired,
  checkboxValue: PropTypes.string.isRequired,
  checkboxLabel: PropTypes.string.isRequired,
  checkboxOnChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
}

export default SelectablePropertyCardListView
