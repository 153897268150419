import 'react-app-polyfill/stable'
import 'react-app-polyfill/ie11'

import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import { HelmetProvider } from 'react-helmet-async'
import { IntercomProvider } from 'react-use-intercom'
import { createRoot } from 'react-dom/client'

import App from './App'
import Routes from './Routes'
import awsConfig from './aws-exports'
import { config } from './config'
import ScrollToTop from './ScrollToTop'
import BrowserBanner from './components/atoms/BrowserBanner'
import { browserBannerService } from './container'

Amplify.configure(awsConfig)

let { intercomActive } = config

if (window.innerWidth < 768) {
  intercomActive = false
}

const renderApp = (Component) => {
  const container = document.getElementById('app')
  const root = createRoot(container)

  root.render(
    <HelmetProvider>
      <IntercomProvider appId={config.intercomID}>
        <Component intercomActive={intercomActive}>
          <BrowserBanner browserBannerService={browserBannerService} />
          <BrowserRouter>
            <ScrollToTop>{Routes}</ScrollToTop>
          </BrowserRouter>
        </Component>
      </IntercomProvider>
    </HelmetProvider>
  )
}

renderApp(App)

if (module.hot) {
  module.hot.accept(() => {
    renderApp(App)
  })
}
