import React, { useState } from 'react'
import PropTypes from 'prop-types'
import OverlaySlideUp from '@afs/components/OverlaySlideUp'
import { useOverlayContext } from '@afs/components/OverlayContext'
import VideoEmbed from '@afs/components/VideoEmbed'

import Link from '../../atoms/Link'

import styles from './styles.module.scss'

const RemoveBillsIncludedExplainerOverlay = ({ renderButton }) => {
  const [isPlayingVideo, setIsPlayingVideo] = useState(false)

  return (
    <OverlaySlideUp
      className={styles.overlay}
      overlayUniqueId="remove-utility-bills-explainer"
      renderButton={renderButton}
      heading="Remove bills included from your listing"
      useOverlayContext={useOverlayContext}
    >
      <div className={styles.videoWrapper}>
        <VideoEmbed
          embedUrl="https://www.youtube.com/embed/XjCqn6yFmQY?autoplay=1&rel=0&modestbranding=1"
          placeholderUrl="https://i3.ytimg.com/vi/XjCqn6yFmQY/maxresdefault.jpg"
          isActive={isPlayingVideo}
          toggleActive={setIsPlayingVideo}
          description="How to remove bills included from a property"
        />
      </div>
      <ol className={styles.list}>
        <li>
          <p>
            Go to the <Link to="/landlord-admin/properties">Properties</Link> section and select a
            property.
          </p>
        </li>
        <li>
          <p>
            Click the <span className={styles.highlight}>Edit property details</span> button.
          </p>
        </li>
        <li>
          <p>
            Scroll down to the ‘Bills included’ section and click{' '}
            <span className={styles.highlight}>Change</span>
          </p>
        </li>
        <li>
          <p>Deselect all the utilities.</p>
        </li>
        <li>
          <p>
            Click the <span className={styles.highlight}>Save with no bills included</span> button
            to save the changes.
          </p>
        </li>
        <li>
          <p>
            Following this, you should also adjust your weekly rental price after removing the bills
            included. You can do this in the ‘Bedrooms’ section on the edit property details page.
          </p>
        </li>
      </ol>
    </OverlaySlideUp>
  )
}

RemoveBillsIncludedExplainerOverlay.propTypes = {
  renderButton: PropTypes.func.isRequired,
}

export default RemoveBillsIncludedExplainerOverlay
