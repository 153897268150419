/* eslint-disable no-underscore-dangle */
import moment from 'moment'
import { symbolForIsoCode } from './currency'

import Address from './address'

class Property {
  constructor(property) {
    Object.assign(this, property)
    this._id = property.id
    this.address = new Address(property.address)
    this.photos =
      property.photos && property.photos.length > 0
        ? property.photos.sort((a, b) => (a.position > b.position ? 1 : -1))
        : []
  }

  isAvailable() {
    const availableFrom = moment(this.availableFrom, 'DD/MM/YYYY')
    const availableUntil = moment(this.availableUntil, 'DD/MM/YYYY')
    const dateNow = moment()

    return availableUntil.isValid()
      ? dateNow.isBetween(availableFrom, availableUntil)
      : dateNow.isAfter(availableFrom)
  }

  availability() {
    return this.availabilityDisplay
  }

  availableBedrooms() {
    return this.bedrooms.filter(bedroom => bedroom.available === true)
  }

  numberOfAvailableBedrooms() {
    return this.availableBedrooms().length
  }

  totalBedrooms() {
    return this.bedrooms.length
  }

  startDate() {
    return moment(this.advertisedSince, 'DD/MM/YYYY').format('D MMM YYYY')
  }

  startDateWithFullMonth() {
    return moment(this.advertisedSince, 'DD/MM/YYYY').format('D MMMM YYYY')
  }

  expiryDate() {
    return moment(this.advertisedUntil, 'DD/MM/YYYY').format('D MMM')
  }

  expiryDateWithYear() {
    return moment(this.advertisedUntil, 'DD/MM/YYYY').format('D MMM YYYY')
  }

  rentCurrencySymbol() {
    const rentCurrency = this.bedrooms[0].rentPerWeek.currency
    return symbolForIsoCode(rentCurrency)
  }

  allBedroomsAreAvailable() {
    return this.numberOfAvailableBedrooms() === this.totalBedrooms()
  }

  cheapestAvailableRoomRent() {
    const cheapestAvailableBedroom = this.availableBedrooms().sort(
      (a, b) => a.rentPerWeek.amount - b.rentPerWeek.amount
    )[0]

    const { rentPerWeek } = cheapestAvailableBedroom

    return `${this.rentCurrencySymbol()}${rentPerWeek.amount}`
  }

  propertyCardTerms() {
    const cheapestAvailableBedroom = this.availableBedrooms().sort(
      (a, b) => a.rentPerWeek.amount - b.rentPerWeek.amount
    )[0]

    const { rentPerWeek } = cheapestAvailableBedroom

    const rentPpw = {
      value: rentPerWeek.amount,
      currency: rentPerWeek.currency,
      fixed: 2,
    }

    return { rentPpw, billsIncluded: this.billsIncludedString().toLowerCase() }
  }

  billsIncludedString() {
    const totalNumberOfBills = 4

    if (this.isBillsOptional) {
      return 'Optional'
    }

    if (this.bills.length === 0) {
      return 'None'
    }

    if (this.bills.length === totalNumberOfBills) {
      return 'All'
    }

    return 'Some'
  }
}

export default Property
