import PropTypes from 'prop-types'

const Money = ({ value, fixed, roundedUp }) => {
  const symbols = {
    GBP: '£',
    EUR: '€',
  }

  if (roundedUp) {
    return `${symbols[value.currency]}${Math.ceil(value.value)}`
  }

  if (typeof fixed !== 'undefined') {
    return `${symbols[value.currency]}${value.value.toFixed(fixed)}`
  }

  return `${symbols[value.currency]}${value.value}`
}

Money.propTypes = {
  value: PropTypes.object.isRequired,
  fixed: PropTypes.number,
}

export default Money
