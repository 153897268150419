import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Spinner from '@afs/components/Spinner'

import Address from '../../../models/address'
import useMedia from '../../../hooks/useMedia'
import { config } from '../../../config'

import ListRow from '../../atoms/ListRow'
import PropertyRoomCount from '../../atoms/PropertyRoomCount'
import AdStatusTag from '../../atoms/AdStatusTag'
import AvatarImage from '../../atoms/AvatarImage'

import performanceListRow from './performanceListRow.module.scss'

const formatPropertyAddress = (propertyAddress) => {
  const address = new Address(propertyAddress)
  const addressFirstLine = address.firstLine()
  const addressSecondLine = address.withoutFirstLine().join(', ')

  return {
    addressFirstLine,
    addressSecondLine,
  }
}

const LeftCard = ({ property }) => {
  const { addressFirstLine, addressSecondLine } = formatPropertyAddress(property.address)

  return (
    <div className={performanceListRow.leftCardInner}>
      <div className={performanceListRow.property} data-testid="performance-list-row-property">
        <AvatarImage
          className={performanceListRow.propertyImage}
          hasPhoto={property.hasPhoto}
          photoUrl={property.photoUrl}
          alt={addressFirstLine}
        />
        <div className={performanceListRow.propertyAddress}>
          <p className={performanceListRow.addressTitle}>{addressFirstLine}</p>
          <p className={performanceListRow.addressSubtitle}>{addressSecondLine}</p>
        </div>
      </div>
      <PropertyRoomCount className={performanceListRow.roomCount} count={property.bedrooms} />
      <span className={performanceListRow.adStatusWrapper}>
        <AdStatusTag
          className={performanceListRow.adStatus}
          status={property.status}
          isListed={property.isListed}
          startDate={property.advertisedSince}
          expiryDate={property.advertisedUntil}
        />
      </span>
    </div>
  )
}

const LeftCardFeeds = ({ property }) => {
  const { addressFirstLine, addressSecondLine } = formatPropertyAddress(property.address)

  const showLargeAvatarImage = useMedia(['(min-width: 481px)'], [true], false)
  const showCommaAfterAddressFirstLine = useMedia(
    ['(min-width: 769px) and (max-width: 960px)', '(min-width: 1151px)'],
    [true, true],
    false
  )

  return (
    <div className={performanceListRow.leftCardInner}>
      <div
        className={performanceListRow.propertyFeeds}
        data-testid="performance-list-row-property-feeds"
      >
        <AvatarImage
          className={classNames(performanceListRow.propertyImage, 'u-hidden-in-percy')}
          hasPhoto={property.hasPhoto}
          photoUrl={property.photoUrl}
          alt={addressFirstLine}
          large={showLargeAvatarImage}
        />
        <p className={performanceListRow.propertyAddressFeeds}>
          <strong>{addressFirstLine}</strong>
          {showCommaAfterAddressFirstLine ? ', ' : ' '}
          {addressSecondLine}
        </p>
      </div>
      <PropertyRoomCount className={performanceListRow.roomCountFeeds} count={property.bedrooms} />
    </div>
  )
}

const RightCard = ({
  data,
  isWaitingForAnalytics,
  isWaitingForEnquiries,
  showWarning,
  showError,
}) =>
  data.map((item) => {
    const isAnalyticsItem = item.label === 'impressions'
    const isEnquiriesItem = item.label === 'enquiries'

    const renderValue = () => {
      if (
        (isAnalyticsItem && isWaitingForAnalytics) ||
        (isEnquiriesItem && isWaitingForEnquiries)
      ) {
        return (
          <Spinner className={performanceListRow.spinner} data-testid={`${item.label}-spinner`} />
        )
      }
      if (isAnalyticsItem && showError) {
        return '—'
      }
      return item.value
    }

    const valueClasses = classNames(performanceListRow.value, {
      [performanceListRow.warningText]: isAnalyticsItem && showWarning,
    })

    const labelClasses = classNames(performanceListRow.label, {
      [performanceListRow.warningText]: isAnalyticsItem && showWarning,
    })

    return (
      <div
        className={performanceListRow.dataColumn}
        key={item.label}
        data-testid="performance-data-column"
      >
        <span className={valueClasses} data-testid={`${item.label}-value`}>
          {renderValue()}
        </span>
        <span className={labelClasses} data-testid={`${item.label}-label`}>
          {item.label}
        </span>
      </div>
    )
  })

const PerformanceListRow = ({
  className,
  property,
  smallerThanLargeScreen,
  isWaitingForAnalytics,
  isWaitingForEnquiries,
  showError,
  isFeedAccount,
}) => {
  const performanceData = [
    { label: 'impressions', value: property.impressions },
    { label: 'enquiries', value: property.enquiries },
  ]

  const wrapperClasses = classNames(performanceListRow.wrapper, className)
  const showWarning = property.analyticsAvailable === false

  return (
    <ListRow
      key={property.id}
      className={wrapperClasses}
      leftCard={
        isFeedAccount ? <LeftCardFeeds property={property} /> : <LeftCard property={property} />
      }
      rightCard={
        <RightCard
          data={performanceData}
          isWaitingForAnalytics={isWaitingForAnalytics}
          isWaitingForEnquiries={isWaitingForEnquiries}
          showWarning={showWarning}
          showError={showError}
        />
      }
      leftCardWrapperProps={{
        className: performanceListRow.leftCard,
        to: !isFeedAccount ? `/landlord-admin/property/${property.id}` : undefined,
        href: isFeedAccount ? `${config.mainSiteBaseUrl}property/${property.id}` : undefined,
        target: isFeedAccount ? '_blank' : undefined,
      }}
      rightCardWrapperProps={{ className: performanceListRow.rightCard }}
      isBelowBreakPoint={smallerThanLargeScreen}
      data-testid="performance-list-row"
    />
  )
}

LeftCard.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    address: PropTypes.object.isRequired,
    bedrooms: PropTypes.number.isRequired,
    advertisedSince: PropTypes.string.isRequired,
    advertisedUntil: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    isListed: PropTypes.bool.isRequired,
    hasPhoto: PropTypes.bool.isRequired,
    photoUrl: PropTypes.string.isRequired,
  }).isRequired,
}

LeftCardFeeds.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    address: PropTypes.object.isRequired,
    bedrooms: PropTypes.number.isRequired,
    hasPhoto: PropTypes.bool.isRequired,
    photoUrl: PropTypes.string.isRequired,
  }).isRequired,
}

PerformanceListRow.propTypes = {
  className: PropTypes.string,
  property: PropTypes.object.isRequired,
  smallerThanLargeScreen: PropTypes.bool,
  isWaitingForAnalytics: PropTypes.bool,
  isWaitingForEnquiries: PropTypes.bool,
  showError: PropTypes.bool,
  isFeedAccount: PropTypes.bool,
}

export default PerformanceListRow
