import React from 'react'
import PropTypes from 'prop-types'
import InfoTooltip from '@afs/components/InfoTooltip'
import useMedia from '../../../hooks/useMedia'

import TwoColumnPageTemplate from '../../templates/TwoColumnPageTemplate'
import Header from '../../organisms/Header'
import BackLink from '../../molecules/BackLink'
import Heading from '../../atoms/Heading'
import Button from '../../atoms/Button'
import LinkButton from '../../atoms/LinkButton'
import RemoveBillsIncludedExplainerOverlay from '../../organisms/RemoveBillsIncludedExplainerOverlay'

import FusedLogo from './svgs/fused-logo.svg'
import CheckMark from './svgs/checkmark.svg'
import TooltipIcon from './svgs/tooltip-icon.svg'

import styles from './styles.module.scss'

const LeftColumn = () => {
  return (
    <>
      <BackLink className={styles.backLink} to="/landlord-admin" />
      <Heading className={styles.leftColumnPrimaryHeader} level={1}>
        Add zero-risk bills package to your properties
      </Heading>
      <div className={styles.leftColumnCopy}>
        <p>
          Improve your property listings with ‘bills optional’ packages from by our trusted partners
          Fused.
        </p>
        <p>
          Potential tenants can see a separate quote price for a bills package for your property.
          They get the convenience of a bills-inclusive rental, including handy bill splitting, with
          no risk or admin for you. The arrangement is directly between Fused and the student.
        </p>
      </div>
      <Button
        className={styles.leftColumnCta}
        to="/landlord-admin/bills-optional/select-properties"
        primary
      >
        Let's get started
      </Button>

      <div className={styles.callOut}>
        <strong className={styles.callOutHeading}>
          70% of students prefer properties that include bills
        </strong>
        <p className={styles.callOutText}>
          Properties with a bills package generate <strong>twice as many enquiries</strong> as those
          without a bills package.
        </p>
      </div>

      <Heading className={styles.leftColumnSecondaryHeader} level={2}>
        How does it work?
      </Heading>

      <ol className={styles.leftColumnList} type="1">
        <li>
          <p className={styles.paragraphWithTooltip}>
            Select from your{' '}
            <InfoTooltip
              className={styles.tooltip}
              tooltipContent="Only properties that have ‘No bills’ included are eligible for a ‘Bills optional’ quote."
              icon={<TooltipIcon className={styles.tooltipIcon} />}
              wide
            >
              <strong>eligible properties</strong>
            </InfoTooltip>{' '}
            to enhance with a bills optional quote.
          </p>
        </li>
        <li>Review up-to-date quote prices that will be added to each property you select.</li>
        <li>
          Your properties will now appear in both bills included and bills not included searches.
        </li>
        <li>
          Once your tenants have signed up they will deal directly with Fused for their utilities.
        </li>
      </ol>

      <p className={styles.note}>
        Please note: only properties excluding bills are eligible to add bills optional. Should your
        properties already include bills, you’ll need to remove them from the property listing and
        update your rental price to a pricing excluding bills.{' '}
        <RemoveBillsIncludedExplainerOverlay
          renderButton={(onClick) => (
            <LinkButton className={styles.noteButton} onClick={onClick}>
              Learn how
            </LinkButton>
          )}
        />
      </p>
    </>
  )
}

const RightColumnListItem = ({ heading, text }) => {
  return (
    <li>
      <CheckMark className={styles.rightColumnListIcon} />
      <div>
        <p className={styles.rightColumnListItemHeading}>{heading}</p>
        <p className={styles.rightColumnListItemText}>{text}</p>
      </div>
    </li>
  )
}

RightColumnListItem.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
}

const rightColumnListItems = [
  {
    heading: 'Attract more enquiries',
    text: 'Your properties will be seen by more students as your listings will appear in both No bills and Bills inclusive searches.',
  },
  {
    heading: 'Zero risk to you',
    text: 'Your tenants contract directly with Fused and pay their bills directly to them. Get the marketing benefit of bills inclusive with none of the risks.',
  },
  {
    heading: 'One simple payment for tenants',
    text: 'Each housemate will be given their own portal log in to pay their share of the bills each month.',
  },
  {
    heading: '100% unlimited energy',
    text: 'Tenants can use as much energy as they need with no surprise over-usage fees! There’s none of this extra charge nonsense, just stress-free energy for you and your tenants.',
  },
]

const RightColumn = () => {
  const isMobile = useMedia(['(max-width: 768px)'], [true], false)

  return (
    <>
      <p className={styles.partnershipText}>
        In partnership with our friends at: <span className={styles.hiddenText}>Fused</span>
      </p>
      <FusedLogo className={styles.fusedLogo} />
      <Heading className={styles.rightColumnHeader} level={2}>
        What are the benefits?
      </Heading>
      <div className={styles.rightColumnList}>
        <ul>
          {rightColumnListItems.map((item) => (
            <RightColumnListItem key={item.heading} heading={item.heading} text={item.text} />
          ))}
        </ul>
      </div>

      {isMobile ? (
        <Button
          className={styles.rightColumnCta}
          to="/landlord-admin/bills-optional/select-properties"
          primary
        >
          Let's get started
        </Button>
      ) : null}
    </>
  )
}

const BillsOptionalAddUtilityBillsPage = () => {
  return (
    <TwoColumnPageTemplate
      header={<Header />}
      title="Add Utility Bills To Your Properties | Bills Optional | AFS"
      leftColumn={<LeftColumn />}
      leftColumnInnerClassName={styles.leftColumnInner}
      rightColumn={<RightColumn />}
      rightColumnClassName={styles.rightColumn}
      rightColumnInnerClassName={styles.rightColumnInner}
      stack
    />
  )
}

export default BillsOptionalAddUtilityBillsPage
