import moment from 'moment'
import dateFormat from './dateFormat'

const isUndefined = date => {
  return date === '' || typeof date === 'undefined'
}

class FormattedDate {
  constructor(today, format) {
    this.dateFormat = isUndefined(format) ? dateFormat.DDMMYYYY : format
    this.today = isUndefined(today)
      ? moment()
      : moment(today, isUndefined(format) ? dateFormat.DDMMYYYY : format, true)
  }

  todaysDate() {
    return this.today.clone().format(this.dateFormat)
  }

  yesterdaysDate() {
    return this.today
      .clone()
      .subtract(1, 'days')
      .format(this.dateFormat)
  }

  tomorrowsDate() {
    return this.today
      .clone()
      .add(1, 'days')
      .format(this.dateFormat)
  }

  sevenDaysAgo() {
    return this.today
      .clone()
      .subtract(7, 'days')
      .format(this.dateFormat)
  }

  thirtyDaysAgo() {
    return this.today
      .clone()
      .subtract(30, 'days')
      .format(this.dateFormat)
  }

  ninetyDaysAgo() {
    return this.today
      .clone()
      .subtract(90, 'days')
      .format(this.dateFormat)
  }

  firstDayOfThisWeek() {
    return this.today
      .clone()
      .startOf('isoWeek')
      .format(this.dateFormat)
  }

  firstDayOfThisMonth() {
    return this.today
      .clone()
      .startOf('month')
      .format(this.dateFormat)
  }

  firstDayOfThisYear() {
    return this.today
      .clone()
      .startOf('year')
      .format(this.dateFormat)
  }

  isThisYear(date) {
    const yearOfTheGivenDate = moment(date, this.dateFormat, true).year()
    const thisYear = this.today.clone().year()

    return yearOfTheGivenDate === thisYear
  }

  asDMmm(date) {
    return moment(date, this.dateFormat, true).format('D MMM')
  }

  asDMmmm(date) {
    return moment(date, this.dateFormat, true).format('D MMMM')
  }

  asDMmmYyyy(date) {
    return moment(date, this.dateFormat, true).format('D MMM YYYY')
  }

  isWithinAfsOpeningHours() {
    const today = this.today.clone()
    const day = today.day()
    const hour = today.hour()
    const minute = today.minute()

    const isSaturday = day === 6
    const isSunday = day === 0
    const isBefore8Am = hour < 8
    const isAfter430Pm = (hour === 16 && minute > 30) || hour >= 17

    if (isSaturday || isSunday) return false
    if (isBefore8Am || isAfter430Pm) return false
    return true
  }

  isWeekend() {
    const today = this.today.clone()
    const day = today.day()
    const hour = today.hour()
    const minute = today.minute()

    const isFriday = day === 5
    const isSaturday = day === 6
    const isSunday = day === 0
    const isAfter430Pm = (hour === 16 && minute > 30) || hour >= 17

    const isFridayAfter430pm = isFriday && isAfter430Pm
    const isWeekend = isSaturday || isSunday

    return isFridayAfter430pm || isWeekend
  }

  isWeekdayOutsideOfAfsOpeningHours() {
    const today = this.today.clone()
    const day = today.day()
    const hour = today.hour()
    const minute = today.minute()

    const isFriday = day === 5
    const isSaturday = day === 6
    const isSunday = day === 0
    const isBefore8Am = hour < 8
    const isAfter430Pm = (hour === 16 && minute > 30) || hour >= 17
    const isFridayAfter430pm = isFriday && isAfter430Pm

    if (isFridayAfter430pm || isSaturday || isSunday) return false
    if (isBefore8Am || isAfter430Pm) return true
    return false
  }
}

export default FormattedDate
