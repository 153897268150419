import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

const YouTubeVideoEmbed = ({ className, videoId }) => {
  const [iframeNode, setIframeNode] = useState(undefined)

  const videoPreviewIframeRef = useCallback(node => {
    if (node !== null) {
      setIframeNode(node)
    }
  }, [])

  const updateVideoPreviewIframeUrl = url => {
    if (typeof iframeNode === 'undefined' || iframeNode === null) return
    if (iframeNode.contentWindow === null) return
    iframeNode.contentWindow.location.replace(url)
  }

  return (
    <>
      {updateVideoPreviewIframeUrl(`https://www.youtube.com/embed/${videoId}`)}
      <iframe
        ref={videoPreviewIframeRef}
        title="video tour preview"
        className={className}
        width="420"
        height="315"
        data-testid="video-tour-embed"
      />
    </>
  )
}

YouTubeVideoEmbed.propTypes = {
  className: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
}

export default YouTubeVideoEmbed
