import React from 'react'
import PropTypes from 'prop-types'

// turn word wrap off
const icons = {
  logo: 'M46.007 19.962c1.28 1.21 1.33 3.226.108 4.498-.628.656-1.472.987-2.317.987-.794 0-1.588-.292-2.208-.88L23.5 7.438 5.41 24.566c-.62.588-1.415.88-2.21.88-.843 0-1.687-.33-2.317-.987-1.22-1.272-1.17-3.286.11-4.498l4.764-4.512V3.188C5.757 1.446 7.18.032 8.934.032c1.756 0 3.178 1.414 3.178 3.156V9.43L21.148.874c.657-.622 1.51-.906 2.35-.87.84-.037 1.696.247 2.353.87l20.157 19.09zm-12.505 16.75c.894.77 1.028 2.164.298 3.11C30.638 43.92 27.192 46 23.552 46c-.12 0-.237-.003-.354-.007-5.965-.22-9.92-6.044-10.087-6.292-.663-.997-.44-2.377.502-3.08.944-.704 2.243-.47 2.91.523.06.087 2.977 4.303 6.84 4.43 2.37.1 4.81-1.45 7.2-4.546.73-.946 2.048-1.09 2.94-.315zM16.646 30.34c-2.164 0-3.917-1.753-3.917-3.914 0-2.162 1.752-3.915 3.916-3.915 2.164 0 3.916 1.754 3.916 3.916 0 2.16-1.752 3.914-3.916 3.914zm18.604-3.914c0 2.162-1.754 3.914-3.916 3.914-2.163 0-3.917-1.752-3.917-3.914 0-2.163 1.754-3.915 3.917-3.915 2.162 0 3.916 1.753 3.916 3.916z',
  'plus-circle':
    'M11 11H8c-.552 0-1 .448-1 1s.448 1 1 1h3v3c0 .552.448 1 1 1s1-.448 1-1v-3h3c.552 0 1-.448 1-1s-.448-1-1-1h-3V8c0-.552-.448-1-1-1s-1 .448-1 1v3zm1 13C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z',
  'minus-circle':
    'M12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zM8 13h8c.552 0 1-.448 1-1s-.448-1-1-1H8c-.552 0-1 .448-1 1s.448 1 1 1z',
  plus: 'M6 6H1c-.552 0-1 .448-1 1s.448 1 1 1h5v5c0 .552.448 1 1 1s1-.448 1-1V8h5c.552 0 1-.448 1-1s-.448-1-1-1H8V1c0-.552-.448-1-1-1S6 .448 6 1v5z',
  minus: 'M1 2h12c.552 0 1-.448 1-1s-.448-1-1-1H1C.448 0 0 .448 0 1s.448 1 1 1z',
  filter:
    'M17.173 6.65V.728c0-.4-.327-.727-.73-.727-.404 0-.73.326-.73.727V6.65c-1.057.314-1.828 1.288-1.828 2.44s.77 2.127 1.827 2.44v3.743c0 .4.327.727.73.727.404 0 .73-.326.73-.727V11.53C18.23 11.218 19 10.245 19 9.09c0-1.15-.77-2.125-1.827-2.44zM10.23 1.198v-.47c0-.4-.326-.727-.73-.727-.404 0-.73.326-.73.727v.47C7.713 1.51 6.94 2.484 6.94 3.637c0 1.15.77 2.126 1.827 2.44v9.196c0 .4.326.727.73.727.404 0 .73-.326.73-.727V6.076c1.056-.313 1.828-1.287 1.828-2.44 0-1.15-.77-2.126-1.827-2.44zM3.29 9.924V.727c0-.4-.327-.727-.73-.727-.404 0-.73.326-.73.727v9.197C.77 10.237 0 11.21 0 12.364c0 1.15.77 2.126 1.827 2.44v.47c0 .4.327.726.73.726.404 0 .73-.326.73-.727v-.47c1.057-.313 1.828-1.287 1.828-2.44 0-1.15-.77-2.126-1.827-2.44z',
  phone:
    'M2.455 1.627c-.452 0-.82.364-.82.814v19.12c0 .45.368.813.82.813h13.09c.452 0 .82-.364.82-.814V2.44c0-.45-.368-.813-.82-.813H2.455zm0-1.627h13.09C16.9 0 18 1.093 18 2.44v19.12c0 1.347-1.1 2.44-2.455 2.44H2.455C1.1 24 0 22.907 0 21.56V2.44C0 1.094 1.1 0 2.455 0zM9 21.588c.904 0 1.636-.715 1.636-1.598 0-.882-.732-1.598-1.636-1.598-.904 0-1.636.716-1.636 1.598 0 .883.732 1.598 1.636 1.598zM4.09 2.398c-.903 0-1.635.728-1.635 1.626V15.98c0 .9.732 1.628 1.636 1.628h9.82c.903 0 1.635-.73 1.635-1.627V4.025c0-.898-.732-1.627-1.636-1.627H4.09zm0 1.626h9.82V15.98H4.09V4.025z',
  views:
    'M15.895 4.808C15.752 4.61 12.345 0 8 0 3.656 0 .25 4.61.105 4.808a.55.55 0 0 0 0 .647c.145.196 3.551 4.806 7.896 4.806 4.344 0 7.75-4.61 7.894-4.806a.55.55 0 0 0 0-.647zM8 9.162c-3.166 0-5.92-3.022-6.75-4.031C2.078 4.119 4.826 1.099 8 1.099s5.92 3.022 6.75 4.032c-.828 1.011-3.576 4.03-6.75 4.03zM8 2a3 3 0 1 0 0 6 3 3 0 0 0 0-6z',
  star: 'M6.55 11.462l-2.84 1.43c-.494.25-1.095.05-1.344-.442-.097-.193-.13-.413-.09-.626l.52-2.914c.06-.334-.053-.675-.3-.907L.315 5.968C-.085 5.59-.107 4.958.27 4.555c.156-.167.365-.276.592-.308l3.086-.43c.322-.044.602-.243.75-.532L6.112.542c.254-.49.857-.684 1.348-.43.185.094.336.245.43.43l1.413 2.743c.15.29.43.488.752.533l3.086.43c.547.076.928.58.852 1.128-.032.227-.14.436-.308.592l-2.177 2.035c-.248.232-.362.573-.302.907l.52 2.914c.1.544-.263 1.063-.807 1.16-.213.038-.433.006-.626-.09L7.45 11.46c-.283-.143-.617-.143-.9 0z',
  studio:
    'M3.55 10.91C2.006 9.822 1 8.03 1 6c0-3.314 2.686-6 6-6s6 2.686 6 6c0 2.03-1.007 3.823-2.55 4.91C12.57 12.11 14 14.39 14 17v8c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1v-8c0-2.61 1.43-4.888 3.55-6.09zM7 10c2.21 0 4-1.79 4-4S9.21 2 7 2 3 3.79 3 6s1.79 4 4 4zm5 7c0-2.76-2.24-5-5-5s-5 2.24-5 5v7h10v-7z',
  halls:
    'M13 26h16c.552 0 1-.448 1-1V6.862c0-1.013-.757-1.866-1.763-1.986l-24-2.862C4.157 2.004 4.08 2 4 2c-1.105 0-2 .895-2 2v21c0 .552.448 1 1 1h4v-3c0-1.104.895-2 2-2h2c1.105 0 2 .896 2 2v3zM28.474 2.89C30.484 3.13 32 4.836 32 6.862V25c0 1.657-1.343 3-3 3H3c-1.657 0-3-1.343-3-3V4c0-2.21 1.79-4 4-4 .158 0 .316.01.474.028l24 2.862zM5 16h2v2H5v-2zm0-4h2v2H5v-2zm0-4h2v2H5V8zm8 8h2v2h-2v-2zm0-4h2v2h-2v-2zm0-4h2v2h-2V8zm-4 8h2v2H9v-2zm0-4h2v2H9v-2zm0-4h2v2H9V8zm8 8h2v2h-2v-2zm0 4h2v2h-2v-2zm0-8h2v2h-2v-2zm0-4h2v2h-2V8zm8 8h2v2h-2v-2zm0 4h2v2h-2v-2zm0-8h2v2h-2v-2zm0-4h2v2h-2V8zm-4 8h2v2h-2v-2zm0 4h2v2h-2v-2zm0-8h2v2h-2v-2zm0-4h2v2h-2V8zM11 26v-3H9v3h2z',
  flat: 'M3 2c-.552 0-1 .448-1 1v22h12V3c0-.552-.448-1-1-1H3zm0-2h10c1.657 0 3 1.343 3 3v22c0 1.105-.895 2-2 2H2c-1.105 0-2-.895-2-2V3c0-1.657 1.343-3 3-3zm2 25h6v-3c0-1.105-.895-2-2-2H7c-1.105 0-2 .895-2 2v3zm4 0H7v-3h2v3zm-6-9v2h2v-2H3zm0-4v2h2v-2H3zm0-4v2h2V8H3zm0-4v2h2V4H3zm8 12v2h2v-2h-2zm0-4v2h2v-2h-2zm0-4v2h2V8h-2zm0-4v2h2V4h-2zM7 16v2h2v-2H7zm0-4v2h2v-2H7zm0-4v2h2V8H7zm0-4v2h2V4H7z',
  house:
    'M20 20v-9.847c0-.16-.07-.32-.207-.444l-8.25-7.507c-.297-.27-.79-.27-1.086 0L2.207 9.71C2.07 9.832 2 9.99 2 10.152V20h18zm2-9.847v10.524c0 .73-.616 1.323-1.375 1.323H1.375C.615 22 0 21.407 0 20.677V10.153C0 9.426.31 8.73.86 8.23L9.11.723c1.06-.964 2.72-.964 3.78 0l8.25 7.507c.55.5.86 1.196.86 1.923zM8 20h6v-3c0-1.105-.895-2-2-2h-2c-1.105 0-2 .895-2 2v3zm4 0h-2v-3h2v3z',
  'house-small':
    'M15.5 16.4V8.3c0-.1-.1-.3-.2-.4L8.9 1.8c-.2-.2-.6-.2-.8 0L1.7 7.9c-.1.1-.2.3-.2.4v8.1h14zM17 8.3v8.6c0 .6-.5 1.1-1.1 1.1H1.1C.5 18 0 17.5 0 16.9V8.3c0-.6.2-1.2.7-1.6L7 .6c.8-.8 2.1-.8 2.9 0l6.4 6.1c.5.4.7 1 .7 1.6zM6.2 16.4h4.6v-2.5c0-.9-.7-1.6-1.5-1.6H7.7c-.9 0-1.5.7-1.5 1.6v2.5zm3.1 0H7.7v-2.5h1.5v2.5z',
  arrow:
    'M.293 1.707l5.88 5.88c.78.78.78 2.046 0 2.827l-5.88 5.88c-.39.39-.39 1.023 0 1.413.39.39 1.024.39 1.414 0l5.88-5.88c1.56-1.56 1.56-4.093 0-5.655L1.706.292c-.39-.39-1.024-.39-1.414 0-.39.39-.39 1.025 0 1.415z',
  'arrow-back':
    'M2.414 7l5.293-5.293A1 1 0 1 0 6.293.293l-6 6a1 1 0 0 0 0 1.414l6 6a1 1 0 1 0 1.414-1.414L2.414 7z',
  'map-pin':
    'M9.237 1.293C8.29.463 6.953 0 5.5 0 4.047 0 2.71.463 1.763 1.293.603 2.298 0 3.83 0 5.713c0 4.1 4.815 8.888 5.027 9.096.13.126.293.19.473.19s.343-.064.473-.19C6.185 14.6 11 9.813 11 5.712c0-1.883-.604-3.415-1.763-4.42zM5.5 8.09c-1.47 0-2.66-1.18-2.66-2.633 0-1.452 1.19-2.633 2.66-2.633 1.47 0 2.66 1.18 2.66 2.633S6.97 8.09 5.5 8.09z',
  bed: 'M4.75 6C3.784 6 3 5.216 3 4.25S3.784 2.5 4.75 2.5s1.75.784 1.75 1.75c0 .464-.184.91-.513 1.237C5.66 5.817 5.214 6 4.75 6zM0 11c0 .552.448 1 1 1s1-.448 1-1V9h12v2c0 .552.448 1 1 1s1-.448 1-1V3.75c0-1.243-1.007-2.25-2.25-2.25H8.5c-.552 0-1 .448-1 1V7H2V1c0-.552-.448-1-1-1S0 .448 0 1v10z',
  tick: 'M2.6 5.6c-.4-.4-1.1-.4-1.5 0C.7 6 .7 6.6 1 7l2.4 2.7c.4.4 1.1.5 1.5.1l5.9-6c.4-.4.4-1.1 0-1.5s-1.1-.4-1.5 0l-5 5.2-1.7-1.9z',
  'cross-small':
    'M4.6 6L1.3 2.7c-.4-.4-.4-1 0-1.4s1-.4 1.4 0L6 4.6l3.3-3.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L7.4 6l3.3 3.3c.4.4.4 1 0 1.4s-1 .4-1.4 0L6 7.4l-3.3 3.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L4.6 6z',
  cross:
    'M8 6.586L1.707.293c-.39-.39-1.024-.39-1.414 0-.39.39-.39 1.024 0 1.414L6.586 8 .293 14.293c-.39.39-.39 1.024 0 1.414.39.39 1.024.39 1.414 0L8 9.414l6.293 6.293c.39.39 1.024.39 1.414 0 .39-.39.39-1.024 0-1.414L9.414 8l6.293-6.293c.39-.39.39-1.024 0-1.414-.39-.39-1.024-.39-1.414 0L8 6.586z',
  alert: 'M3 7H1V0h2v7zm-1 4.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z',
  delete:
    'M3.143 3.516l.921 12.23c.088 1.143 1.04 2.043 2.134 2.043h5.6c1.094 0 2.047-.9 2.134-2.043l.925-12.23H3.143zM6.683 16.2a.42.42 0 0 1-.422.447.471.471 0 0 1-.46-.447L5.288 5.773c-.018-.338.2-.612.485-.612s.524.274.538.612L6.683 16.2zm2.757 0c0 .25-.2.447-.44.447-.239 0-.44-.2-.44-.447L8.49 5.773c-.003-.338.225-.612.51-.612.285 0 .513.274.51.612L9.44 16.2zm2.756 0a.466.466 0 0 1-.46.447.425.425 0 0 1-.423-.447l.373-10.427c.01-.338.253-.612.538-.612.285 0 .503.274.485.612L12.196 16.2zM15.655 2.911H2.345c-.063-.742.376-1.35.98-1.35h11.353c.6 0 1.04.612.977 1.35zM11.401 2.06H6.6l-.007-.274C6.564.925 7.105.21 7.802.21h2.4c.693 0 1.234.714 1.21 1.571l-.01.278z',
  favourite:
    'M7.838 15.694c.585.585 1.354.867 2.12.857.768.012 1.537-.27 2.123-.856l6.3-6.26c2.16-2.158 2.16-5.657 0-7.816-2.16-2.157-5.663-2.157-7.823 0l-.56.55-.554-.55C7.283-.54 3.78-.54 1.62 1.618c-2.16 2.16-2.16 5.657 0 7.816l6.218 6.26z',
  facebook:
    'M16 9.75v4.875h-5.333V26H5.333V14.625H0V9.75h5.333V7.71c0-1.93.665-4.372 1.988-5.706C8.645.666 10.296 0 12.275 0H16v4.875h-3.733c-.886 0-1.6.653-1.6 1.46V9.75H16z',
  twitter:
    'M25.895 3.835c1.317-.823 2.188-1.945 2.615-3.372-1.28.69-2.556 1.165-3.83 1.427C23.533.637 22.083 0 20.344 0c-1.7 0-3.135.607-4.307 1.787-1.165 1.19-1.753 2.633-1.753 4.32 0 .51.063.97.19 1.357-5.012-.174-9.16-2.29-12.43-6.362-.554.997-.83 2.012-.83 3.06 0 2.158.894 3.867 2.68 5.122-1.02-.088-1.917-.35-2.68-.78 0 1.54.444 2.828 1.335 3.96.895 1.12 2.044 1.835 3.447 2.14-.51.133-1.045.196-1.596.196-.507 0-.867-.043-1.08-.138.374 1.255 1.08 2.273 2.103 3.053 1.02.782 2.19 1.195 3.507 1.23-2.17 1.734-4.652 2.59-7.46 2.59-.688 0-1.175-.01-1.47-.062 2.765 1.827 5.844 2.734 9.25 2.734 3.485 0 6.565-.9 9.245-2.696 2.68-1.786 4.66-3.99 5.93-6.587 1.278-2.593 1.91-5.292 1.91-8.11v-.777c1.235-.945 2.234-2.01 2.998-3.184-1.108.485-2.253.815-3.438.983z',
  instagram:
    'M13 0c3.53 0 3.973.015 5.36.078 1.384.063 2.33.283 3.155.605.855.332 1.58.776 2.303 1.5.723.722 1.167 1.447 1.5 2.302.32.826.54 1.77.604 3.155C25.985 9.027 26 9.47 26 13c0 3.53-.015 3.973-.078 5.36-.063 1.384-.283 2.33-.604 3.155-.333.855-.777 1.58-1.5 2.303-.723.723-1.448 1.167-2.303 1.5-.826.32-1.77.54-3.155.604-1.387.063-1.83.078-5.36.078-3.53 0-3.973-.015-5.36-.078-1.384-.063-2.33-.283-3.155-.604-.855-.333-1.58-.777-2.303-1.5-.723-.723-1.167-1.448-1.5-2.303-.32-.826-.54-1.77-.604-3.155C.015 16.973 0 16.53 0 13c0-3.53.015-3.973.078-5.36C.14 6.256.36 5.31.683 4.485c.332-.855.776-1.58 1.5-2.303.722-.723 1.447-1.167 2.302-1.5C5.31.363 6.255.143 7.64.08 9.027.015 9.47 0 13 0zm0 2.294c-3.487 0-3.9.013-5.277.076-1.273.058-1.965.27-2.425.45-.61.237-1.044.52-1.5.977-.458.457-.74.892-.978 1.5-.18.46-.392 1.153-.45 2.426C2.307 9.1 2.294 9.513 2.294 13s.013 3.9.076 5.277c.058 1.273.27 1.965.45 2.425.237.61.52 1.044.977 1.5.457.458.892.74 1.5.978.46.18 1.153.392 2.426.45 1.377.063 1.79.076 5.277.076s3.9-.013 5.277-.076c1.273-.058 1.965-.27 2.425-.45.61-.237 1.044-.52 1.5-.977.458-.457.74-.892.978-1.5.18-.46.392-1.153.45-2.426.063-1.377.076-1.79.076-5.277s-.013-3.9-.076-5.277c-.058-1.273-.27-1.965-.45-2.425-.237-.61-.52-1.044-.977-1.5-.457-.458-.892-.74-1.5-.978-.46-.18-1.153-.392-2.426-.45-1.377-.063-1.79-.076-5.277-.076zm-.382 3.824c3.59 0 6.5 2.91 6.5 6.5s-2.91 6.5-6.5 6.5-6.5-2.91-6.5-6.5 2.91-6.5 6.5-6.5zm0 10.706c2.323 0 4.206-1.883 4.206-4.206 0-2.323-1.883-4.206-4.206-4.206-2.323 0-4.206 1.883-4.206 4.206 0 2.323 1.883 4.206 4.206 4.206zm8.794-10.706c0 .844-.685 1.53-1.53 1.53-.844 0-1.53-.686-1.53-1.53 0-.845.686-1.53 1.53-1.53.845 0 1.53.685 1.53 1.53z',
  lock: 'M 12.3347 16.0005 H 1.67601 C 0.940009 16.0005 0.344009 15.4032 0.344009 14.6665 V 7.99588 C 0.344009 7.25921 0.940676 6.66121 1.67601 6.66121 H 2.34201 V 4.66054 C 2.34201 2.08188 4.43001 -0.00878906 7.00534 -0.00878906 C 9.58068 -0.00878906 11.6687 2.08188 11.6687 4.66054 V 6.66121 H 12.3347 C 13.0707 6.66121 13.6667 7.25854 13.6667 7.99588 V 14.6665 C 13.6667 15.4025 13.07 16.0005 12.3347 16.0005 Z M 9.67001 4.66054 C 9.67001 3.18721 8.47668 1.99254 7.00534 1.99254 C 5.53401 1.99254 4.34068 3.18721 4.34068 4.66054 V 6.66121 H 9.67001 V 4.66054 Z',
  open: 'M12.444 12.444H1.556V1.556H7V0H1.556C.696 0 0 .696 0 1.556v10.888C0 13.304.696 14 1.556 14h10.888c.86 0 1.556-.696 1.556-1.556V7h-1.556v5.444zM8.556 0v1.556h2.788L3.698 9.2l1.1 1.1 7.646-7.645v2.788H14V0H8.556z',
  email:
    'M7.99 9.167C6.686 9.167-.02 3.76-.02 3.76v-.594c0-.736.597-1.334 1.334-1.334h13.35c.739 0 1.336.598 1.336 1.334l-.01.666s-6.636 5.334-8 5.334zm0 1.833c1.427 0 8-5.167 8-5.167l.01 8c0 .736-.597 1.334-1.335 1.334H1.314a1.335 1.335 0 01-1.335-1.334l.011-8S6.687 11 7.99 11z',
}

const Icon = ({ className, icon, width, height, fill, viewBox, ...props }) => (
  <svg
    className={className || undefined}
    width={width || undefined}
    height={height || undefined}
    viewBox={viewBox || undefined}
    focusable="false" // IE11 focus fix
  >
    <path d={icons[icon]} fill={fill || undefined} {...props} />
  </svg>
)

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  viewBox: PropTypes.string,
}

export default Icon
