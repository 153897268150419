import React from 'react'
import PropTypes from 'prop-types'
import { SuccessPill, ErrorPill } from '@afs/components/Pill'

import AnnounceKit from 'announcekit-react'
import ManagePropertiesPageTemplate from '../../templates/ManagePropertiesPageTemplate'
import ManagePropertiesHero from '../../atoms/ManagePropertiesHero'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Heading from '../../atoms/Heading'
import Link from '../../atoms/Link'
import AccountCardGridFeeds from '../../organisms/AccountCardGridFeeds'

import { useAuthContext } from '../../../authContext'

import EditAccountIcon from '../../../svgs/icons/edit-account.svg'

import hero from './modules/hero.module.scss'
import home from './modules/home.module.scss'
import TreePlantingMessage from '../../atoms/TreePlantingMessage'

const HomePageFeeds = ({ name, hasActiveFeed }) => {
  const {
    user: {
      user: { sub },
    },
    accountType,
  } = useAuthContext()

  const renderHero = () => {
    return (
      <ManagePropertiesHero hasPatternBackgroundOnMobile paddingType="large">
        <div className={hero.row}>
          <div className={hero.details}>
            <Heading className={hero.heading} data-testid="home-page-heading">
              {name && (
                <>
                  <span className={hero.welcome}>Welcome</span>
                  <span>{` ${name}`}</span>
                </>
              )}
            </Heading>
            <Link
              className={hero.editAccount}
              to="/landlord-admin/feeds/account"
              data-testid="edit-account-link"
            >
              <EditAccountIcon className={hero.icon} />
              <span>Edit my account</span>
            </Link>
          </div>
        </div>
      </ManagePropertiesHero>
    )
  }

  return (
    <ManagePropertiesPageTemplate
      title="Dashboard | AFS"
      header={<Header />}
      footer={<Footer dark />}
      hero={renderHero()}
    >
      <div className={home.contentWrapper}>
        <div className={`${home.notifications} hide-in-percy`} data-testid="announce-kit">
          <AnnounceKit
            widget="https://announcekit.co/widgets/v2/38fX5S"
            user={{
              id: sub,
              accountType,
            }}
            data={{
              account_type: accountType,
            }}
          >
            <span className={home.notificationsLabel}>Latest updates</span>
          </AnnounceKit>
        </div>
        <AccountCardGridFeeds className={home.accountCardGrid} hasActiveFeed={hasActiveFeed} />
        <section className={home.feedsBanner} data-testid="feeds-banner">
          <span className={home.feedsHeadingWrapper}>
            <Heading className={home.feedsHeading} level={4}>
              Automated feed
            </Heading>
            {hasActiveFeed ? (
              <SuccessPill data-testid="active-pill">Active</SuccessPill>
            ) : (
              <ErrorPill data-testid="inactive-pill">Inactive</ErrorPill>
            )}
          </span>
          {hasActiveFeed ? (
            <p className={home.feedsStatusMessage} data-testid="feed-status-message-active">
              Your feed is up and running. There is nothing you need to do at this time.
            </p>
          ) : (
            <p className={home.feedsStatusMessage} data-testid="feed-status-message-inactive">
              Your feed is <strong>not currently running.</strong> If you’d like to re-activate it,
              please reach out to us and let us know.
            </p>
          )}
          <div className={home.feedsContactCard}>
            {hasActiveFeed ? (
              <p className={home.feedsContactParagraph}>
                If you have any questions, you can call us on{' '}
                <Link className={home.feedsContactNumber} href="tel:+448000789659">
                  0800 078 9659
                </Link>
              </p>
            ) : (
              <p className={home.feedsContactParagraph}>
                You can call us on{' '}
                <Link className={home.feedsContactNumber} href="tel:+448000789659">
                  0800 078 9659
                </Link>
              </p>
            )}
            <p className={home.feedsContactParagraph}>
              <strong>Our normal office hours are:</strong>
              <span className={home.feedsOfficeHoursRow}>Monday to Friday</span>
              <span className={home.feedsOfficeHoursRow}>9am - 5.30pm</span>
            </p>
          </div>
        </section>
        <div className={home.treePlantingMessage}>
          <TreePlantingMessage />
        </div>
      </div>
    </ManagePropertiesPageTemplate>
  )
}

HomePageFeeds.propTypes = {
  name: PropTypes.string.isRequired,
  hasActiveFeed: PropTypes.bool.isRequired,
}

export default HomePageFeeds
