import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'

import { getPropertyById, boostPropertyById } from '../../api/propertyApi'
import { getAccountBoostCredits } from '../../api/accountApi'
import { PropertyContextProvider } from '../../propertyContext'

import PropertyDetailsPage from '../pages/PropertyDetailsPage'
import LoadingPage from '../pages/LoadingPage'

const PropertyDetailsContainer = ({ match, history }) => {
  const propertyId = match.params.id

  const getProperty = (propertyId, callbacks) => {
    Auth.currentSession().then(data => {
      const { jwtToken } = data.idToken
      getPropertyById(propertyId, jwtToken).then(response => {
        callbacks(response)
      })
    })
  }

  const boostProperty = (propertyId, callbacks) => {
    Auth.currentSession().then(data => {
      const { jwtToken } = data.idToken

      boostPropertyById(propertyId, jwtToken).then(response => {
        callbacks(response)
      })
    })
  }

  const [loading, setLoading] = useState(true)
  const [boostCredits, setBoostCredits] = useState(null)

  useEffect(() => {
    Auth.currentSession().then(data => {
      const { jwtToken } = data.idToken
      getAccountBoostCredits(jwtToken).then(response => {
        setLoading(false)
        setBoostCredits(response.boostCredits)
      })
    })
  }, [])

  if (loading) return <LoadingPage />

  return (
    <PropertyContextProvider propertyId={propertyId} getProperty={getProperty}>
      <PropertyDetailsPage
        history={history}
        landlordBoostCredits={boostCredits}
        boostProperty={boostProperty}
      />
    </PropertyContextProvider>
  )
}

PropertyDetailsContainer.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
}

export default PropertyDetailsContainer
