import React from 'react'
import PropTypes from 'prop-types'

import Tick from '../../../../svgs/icons/tick.svg'
import Cross from '../../../../svgs/icons/cross.svg'

import publicPhoneNumberValue from './publicPhoneNumberValue.module.scss'

const PublicPhoneNumberValue = ({ landlord }) => {
  return (
    <>
      <p>{landlord.publicPhoneNumberString() || '-'}</p>
      <p className={publicPhoneNumberValue.enquiries}>
        {landlord.receiveEnquiriesToMobile() ? (
          <Tick className={publicPhoneNumberValue.tick} data-testid="tick-icon" />
        ) : (
          <Cross className={publicPhoneNumberValue.cross} data-testid="cross-icon" />
        )}
        Receive enquiry messages to your mobile
      </p>
    </>
  )
}

PublicPhoneNumberValue.propTypes = {
  landlord: PropTypes.object.isRequired,
}

export default PublicPhoneNumberValue
