import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Overlay from '@afs/components/Overlay'
import Spinner from '@afs/components/Spinner'

import './styles.scss'

const virtualTourFrame = (url, paddingTop, width) => {
  return (
    <div
      className="virtual-tour-overlay__iframe-wrapper"
      style={width > 768 ? { paddingTop: '56.25%' } : { paddingTop: `${paddingTop}px` }}
    >
      <iframe
        className="virtual-tour-overlay__iframe"
        title="virtual-tour-frame"
        src={url}
        sandbox="allow-same-origin allow-scripts"
        frameBorder="0"
        allowFullScreen
      />
    </div>
  )
}

const calculatePaddingTop = () => {
  const aboveMedium = window.innerWidth > 768
  const betweenSmallAndMedium = window.innerWidth >= 660 && window.innerWidth <= 768
  const betweenXsmallAndSmall = window.innerWidth >= 480 && window.innerWidth < 660
  const belowXsmall = window.innerWidth < 480

  if (aboveMedium) {
    return 769
  }
  if (betweenSmallAndMedium) {
    return window.innerHeight - 118
  }

  if (betweenXsmallAndSmall) {
    return window.innerHeight - 114
  }

  if (belowXsmall < 480) {
    return window.innerHeight - 110
  }

  return ''
}

const calculateWindowWidth = () => {
  return window.innerWidth
}

const VirtualTourOverlay = ({ className, virtualTourUrl, isActive, onClose }) => {
  const [frameHeight, setFrameHeight] = useState(null)
  const [frameWidth, setFrameWidth] = useState(null)
  const [ticking, setTicking] = useState(false)

  const setWidthAndHeight = () => {
    setFrameWidth(calculateWindowWidth())

    if (!ticking) {
      window.requestAnimationFrame(() => {
        setFrameHeight(calculatePaddingTop())
        setTicking(false)
      })
      setTicking(true)
    }
  }

  useEffect(() => {
    setWidthAndHeight()
    window.addEventListener('resize', setWidthAndHeight)

    return () => window.removeEventListener('resize', setWidthAndHeight)
  }, [])

  const overlayClasses = classNames('virtual-tour-overlay', className)

  return (
    <Overlay
      className={overlayClasses}
      id="virtual-tour"
      heading="360° Virtual tour"
      isActive={isActive}
      onClose={onClose}
      closeLabel="close"
      preserveScrollPositionOnClose
      data-testid="virtual-tour-overlay"
    >
      <Spinner className="virtual-tour-overlay__spinner" primary />
      {virtualTourFrame(virtualTourUrl, frameHeight, frameWidth)}
    </Overlay>
  )
}

VirtualTourOverlay.propTypes = {
  className: PropTypes.string,
  virtualTourUrl: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default VirtualTourOverlay
