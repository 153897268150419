class Account {
  constructor(account) {
    Object.assign(this, account)
  }

  isFeedAccount() {
    return this.accountType === 'Feed'
  }

  accountTypeString() {
    return this.accountType
  }

  initials() {
    const firstInitial = this.firstName.length > 0 ? this.firstName[0] : ''
    const secondInitial = this.lastName.length > 0 ? this.lastName[0] : ''

    return firstInitial + secondInitial
  }

  fullNameString() {
    return this.fullName
  }

  companyNameString() {
    return this.companyName
  }

  descriptionString() {
    return this.description
  }

  personalPhoneNumberString() {
    return this.personalPhoneNumber
  }

  publicPhoneNumberString() {
    return this.publicPhoneNumber
  }

  receiveEnquiriesToMobile() {
    return this.receiveEnquiryMessagesToMobile
  }

  receiveOffers() {
    return this.receiveExclusiveOffers
  }

  emailAddressString() {
    return this.emailAddress
  }

  invoicingAddressLines() {
    return Object.keys(this.invoicingAddress)
      .map(key => this.invoicingAddress[key])
      .filter(value => value !== '')
  }

  hasActivePropertyFeed() {
    return this.hasActiveFeed
  }

  hasAnyBillsOptionalProperties() {
    return this.hasBillsOptionalProperties
  }

  hasAlreadyAgreedBillsPackageProviderTerms() {
    return this.hasAgreedBillsPackageProviderTerms
  }
}

export default Account
