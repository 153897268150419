const getMatterportVirtualTourIdFrom = path => {
  const id = path.replace('?m=', '').split('&')[0]
  return id
}

const urlDoesNotContainPath = url => {
  const urlWithoutProtocol = url.replace('http://', '').replace('https://', '')
  return !urlWithoutProtocol.includes('/')
}

const urlDoesNotContainAValidUrlDomain = url => {
  return !url.includes('my.matterport.com')
}

const pathDoesNotIncludeShowSegment = segment => {
  return segment !== 'show'
}

const pathDoesNotContainVirtualTourIdParameter = path => !path.includes('?m=')

const matterportVirtualTourIdLength = 11

const matterportUrlValidator = url => {
  if (typeof url === 'undefined' || urlDoesNotContainAValidUrlDomain(url))
    return {
      isValid: false,
      reason: 'URL is not valid. It must be a Matterport virtual tour.',
    }

  if (urlDoesNotContainPath(url)) {
    return {
      isValid: false,
      reason: "URL is not valid. Please make sure that you've copied it right.",
    }
  }

  const urlParts = url
    .replace('http://', '')
    .replace('https://', '')
    .replace('www.', '')
    .split('/')

  const pathSegmentShow = urlParts[1]
  const pathSegmentTour = urlParts[2]

  if (pathDoesNotIncludeShowSegment(pathSegmentShow)) {
    return {
      isValid: false,
      reason: "URL is not valid. Please make sure that you've copied it right.",
    }
  }

  if (pathDoesNotContainVirtualTourIdParameter(pathSegmentTour)) {
    return {
      isValid: false,
      reason: "URL is not valid. Please make sure that you've copied it right.",
    }
  }

  const virtualTourId = getMatterportVirtualTourIdFrom(pathSegmentTour)

  if (virtualTourId === '')
    return {
      isValid: false,
      reason: "URL is not valid. Please make sure that you've copied it right.",
    }

  if (virtualTourId.length !== matterportVirtualTourIdLength)
    return {
      isValid: false,
      reason: "URL is not valid. Please make sure that you've copied it right.",
    }

  return {
    isValid: true,
    virtualTourUrl: url,
  }
}

export default matterportUrlValidator
