import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Logo from '../../atoms/Logo'
import Portal from '../../atoms/Portal'
import CloseButton from '../../molecules/CloseButton'
import PrimaryNavigation from '../../molecules/PrimaryNavigation'

import useMedia from '../../../hooks/useMedia'

import './styles.scss'

const MobileMenu = ({
  openButton: OpenButton,
  logoLinkHref,
  signOut,
  userInitials,
  isAuthenticated,
  useStateContext,
  isFeedAccount,
  hasActiveFeed,
}) => {
  const [isOpen, setOpen] = useState(false)
  const isMobile = useMedia(['(max-width: 914px)'], [true], false)

  useEffect(() => {
    if (!isMobile) {
      setOpen(false)
    }

    if (isOpen) {
      document.body.classList.add('no-scroll')
    }

    return () => document.body.classList.remove('no-scroll')
  })

  const menuClasses = classNames('mobile-menu', {
    'mobile-menu--open': isOpen,
  })

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <OpenButton onClick={handleOpen} />
      <Portal id="mobile-menu">
        <div className={menuClasses}>
          <div className="mobile-menu__header">
            <Logo
              className="mobile-menu__logo"
              href={logoLinkHref}
              renderLinkComponent={(props) => <a {...props} />}
              data-testid="mobile-logo-link"
            />
            <CloseButton
              className="mobile-menu__close"
              onClick={handleClose}
              aria-expanded={isOpen ? 'true' : false}
              aria-controls="navigation"
            />
          </div>
          <div className="mobile-menu__content">
            <PrimaryNavigation
              id="mobile-nav"
              userInitials={userInitials}
              isAuthenticated={isAuthenticated}
              className="mobile-menu__nav"
              handleCloseMobileMenu={handleClose}
              signOut={signOut}
              useStateContext={useStateContext}
              isFeedAccount={isFeedAccount}
              hasActiveFeed={hasActiveFeed}
              aria-label="mobile navigation"
              role="navigation"
              data-testid="mobile-nav"
            />
          </div>
        </div>
      </Portal>
    </>
  )
}

MobileMenu.propTypes = {
  userInitials: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
  logoLinkHref: PropTypes.string.isRequired,
  openButton: PropTypes.any.isRequired,
  signOut: PropTypes.func.isRequired,
  useStateContext: PropTypes.func.isRequired,
  isFeedAccount: PropTypes.bool,
  hasActiveFeed: PropTypes.bool,
}

export default MobileMenu
