import React from 'react'
import PropTypes from 'prop-types'
import Input from '@afs/components/Input'
import Label from '@afs/components/Label'

import './styles.scss'

const FieldLettingTypeOption = ({ name, type, title, body, selected, onSelect }) => {
  const fieldId = type.replace(/\s+/g, '-').toLowerCase()

  return (
    <Label className="letting-type" htmlFor={fieldId}>
      <Input
        id={fieldId}
        type="radio"
        name={name}
        onChange={() => onSelect(type)}
        checked={selected}
        data-testid={fieldId}
      />
      <div className="letting-type__details">
        <p className="letting-type__title">{title}</p>
        <p className="letting-type__body">{body}</p>
      </div>
    </Label>
  )
}

FieldLettingTypeOption.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
}

export default FieldLettingTypeOption
