import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ManagePropertiesPageTemplate from '../../templates/ManagePropertiesPageTemplate'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import SelectedPropertiesMenu from '../../molecules/SelectedPropertiesMenu'
import Button from '../../atoms/Button'
import Heading from '../../atoms/Heading'
import SelectablePropertyAdminCard from '../../molecules/SelectablePropertyAdminCard'
import NotFoundAnnouncement from '../../molecules/NotFoundAnnouncement'

import Advertise from '../../../svgs/illustrations/advertised-properties.svg'

import advertisePropertiesPage from './advertisePropertiesPage.module.scss'

const AdvertisePropertiesPage = ({
  properties,
  selectedPropertyIds,
  toggleSelectProperty,
  deselectAllProperties,
  selectAllProperties,
  history,
}) => {
  const [showShakeAnimation, setShowShakeAnimation] = useState(false)

  const noPropertiesSelected = selectedPropertyIds.length === 0
  const landlordHasUnadvertisedProperties = properties.length > 0
  const allPropertiesSelected = properties.length === selectedPropertyIds.length
  const numberOfPropertiesSelected = selectedPropertyIds.length

  const gridClasses = classNames(advertisePropertiesPage.grid, {
    [advertisePropertiesPage.shakeAnimation]: showShakeAnimation,
  })

  const confirmAdvertiseProperties = e => {
    e.preventDefault()

    if (noPropertiesSelected) {
      setShowShakeAnimation(true)
      setTimeout(() => setShowShakeAnimation(false), 600)
    } else {
      history.push('/landlord-admin/use-advertise-credits')
    }
  }

  const renderNoUnadvertisedPropertiesAnnouncement = () => {
    return (
      <NotFoundAnnouncement
        className={advertisePropertiesPage.notFoundAnnouncement}
        icon={Advertise}
        heading="No properties to advertise"
        subHeading="At the moment, all your properties are already being advertised."
        button={
          <Button to="/landlord-admin/properties" primary data-testid="manage-properties-button">
            Manage properties
          </Button>
        }
        data-testid="no-unadvertised-properties"
      />
    )
  }

  return (
    <ManagePropertiesPageTemplate
      title="Advertise Properties | AFS"
      className={advertisePropertiesPage.layout}
      header={<Header />}
      footer={
        <>
          <SelectedPropertiesMenu
            className={advertisePropertiesPage.menu}
            isActive={landlordHasUnadvertisedProperties}
            allPropertiesSelected={allPropertiesSelected}
            numberOfSelectedProperties={numberOfPropertiesSelected}
            renderButton={() => {
              return (
                <Button
                  className={advertisePropertiesPage.button}
                  onClick={confirmAdvertiseProperties}
                  violet
                  data-testid="confirm-advertise-button"
                >
                  Advertise properties
                </Button>
              )
            }}
            handleDeselectAll={deselectAllProperties}
            handleSelectAll={selectAllProperties}
          />
          <Footer />
        </>
      }
    >
      {landlordHasUnadvertisedProperties ? (
        <>
          <div className={advertisePropertiesPage.hero} data-testid="advertise-properties-hero">
            <Heading className={advertisePropertiesPage.heading} level={1}>
              Advertise properties
            </Heading>
            <p className={advertisePropertiesPage.subheading}>
              Select the properties you’d like to advertise. Only the properties that are not
              already advertised will show up on this list.
            </p>
          </div>
          <div className={gridClasses} data-testid="advertise-properties-grid">
            {properties.map(property => {
              const propertyIsSelected = selectedPropertyIds.includes(property.id)

              return (
                <div
                  className={advertisePropertiesPage.gridItem}
                  key={property.id}
                  data-testid="property-grid-item"
                  data-propertyid={property.id}
                >
                  <SelectablePropertyAdminCard
                    className={advertisePropertiesPage.card}
                    property={property}
                    selected={propertyIsSelected}
                    userIsSelecting
                    handleSelection={toggleSelectProperty}
                  />
                </div>
              )
            })}
          </div>
        </>
      ) : (
        renderNoUnadvertisedPropertiesAnnouncement()
      )}
    </ManagePropertiesPageTemplate>
  )
}

AdvertisePropertiesPage.propTypes = {
  properties: PropTypes.array.isRequired,
  selectedPropertyIds: PropTypes.array.isRequired,
  toggleSelectProperty: PropTypes.func.isRequired,
  deselectAllProperties: PropTypes.func.isRequired,
  selectAllProperties: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

export default AdvertisePropertiesPage
