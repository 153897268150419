import React from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import LazyImage from '../LazyImage'

import styles from './styles.module.scss'

const treeAnimation =
  'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/landlord-admin/tree.gif'

const TreePlantingMessage = ({ className }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <LazyImage
        className={styles.animation}
        src={treeAnimation}
        dataSrc={treeAnimation}
        alt="Tree animation"
      />
      <div className={styles.message}>
        <span className={styles.title}>We plant a tree for every property listed</span>
        <span className={styles.subTitle}>
          This offsets over 1 ton of CO² over its lifetime growth
        </span>
      </div>
    </div>
  )
}

TreePlantingMessage.propTypes = {
  className: PropTypes.string,
}

export default TreePlantingMessage
