import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'
import Field from '@afs/components/Field'

import dateFormat from '../../../models/dateFormat'
import { useStateContext } from '../../../appStateContext'
import ValidatedDate from '../../../models/validatedDate'
import { logEvent } from '../../../logger'
import { getAdvertOptions } from '../../../api/paymentApi'
import { useAuthContext } from '../../../authContext'

import FormPageHeading from '../../molecules/FormPageHeading'
import FieldAdDurationGroup from '../../molecules/FieldAdDurationGroup'
import ProceedToPaymentConfirmation from '../../molecules/ProceedToPaymentConfirmation'
import ToLetBoardField from '../../molecules/ToLetBoardField'

import './styles.scss'

const AdvertisementOptions = ({ history, today, onSetDiscountApplied, handleError }) => {
  const defaultAdOptions = [
    {
      numberOfMonths: 1,
      price: 23.0,
      discountedPrice: 20.7,
      currency: '£',
    },
    {
      numberOfMonths: 3,
      price: 59.0,
      discountedPrice: 53.1,
      currency: '£',
    },
  ]

  const [selectedAdvertisingPeriod, setSelectedAdvertisingPeriod] = useState({})
  const [orderSubtotal, setOrderSubtotal] = useState('0')
  const [orderTotalWithVat, setOrderTotalWithVat] = useState('0')
  const [nonDiscountedOrderSubtotal, setNonDiscountedOrderSubtotal] = useState('0')
  const [hasDiscountApplied, setHasDiscountApplied] = useState(false)
  const [discount, setDiscount] = useState({})
  const [adOptions, setAdOptions] = useState(defaultAdOptions)
  const [advertisedFromDate, setAdvertisedFromDate] = useState(today.format(dateFormat.DDMMYYYY))
  const [toLetBoardsAdded, setToLetBoardsAdded] = useState(false)
  const [advertisedFromErrorMessage, setAdvertisedFromErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const { token } = useAuthContext()
  const { propertyIds, basket } = useStateContext()

  useEffect(() => {
    basket.clear()

    return () => {
      propertyIds.deselectAll()
    }
  }, [])

  const getDiscount = async () => {
    basket.removeDeselectedProperties(propertyIds.selected())

    const getAdvertOptionsResponse = await getAdvertOptions(
      { propertyIds: propertyIds.selected() },
      token
    )

    const discountResponse = await basket.getDiscount()

    if (!discountResponse.success || !getAdvertOptionsResponse.success) {
      handleError()
    } else {
      const subtotalWithoutVat = await basket.subtotalWithoutVat()
      const totalWithVat = await basket.totalWithVat()

      const { hasDiscountApplied, advertOptions, discount } = getAdvertOptionsResponse.data

      setAdOptions(advertOptions)

      setOrderSubtotal(subtotalWithoutVat.toFixed(2))
      setOrderTotalWithVat(totalWithVat.toFixed(2))
      setNonDiscountedOrderSubtotal(basket.nonDiscountedSubtotalWithoutVat().toFixed(2))
      setHasDiscountApplied(hasDiscountApplied)
      onSetDiscountApplied(hasDiscountApplied)
      setDiscount(discount)
    }
  }

  useEffect(() => {
    getDiscount()
      .then(() => setLoading(false))
      .catch(console.error)
  }, [propertyIds.selected()])

  const moreThanOnePropertySelected = propertyIds.selected().length > 1

  const handleSubmit = (event) => {
    event.preventDefault()

    if (basket.advertisementSummary) {
      const today = moment()
      const adFromDate = moment(advertisedFromDate, 'DD/MM/YYYY')
      const dateDifference = adFromDate.diff(today, 'days')
      const datesAreEqual = today.format('DD/MM/YYYY') === adFromDate.format('DD/MM/YYYY')
      const advertisedFromDateDelta = datesAreEqual ? 0 : dateDifference + 1
      const { id, name, amount } = discount

      const advertisementDescription =
        basket.advertisementSummary().length > 0
          ? basket.advertisementSummary()[0].description
          : undefined

      const advertisementQuantity =
        basket.advertisementSummary().length > 0
          ? basket.advertisementSummary()[0].quantity
          : undefined

      const boostCreditDescription =
        basket.boostCreditSummary().length > 0
          ? basket.boostCreditSummary()[0].description
          : undefined

      const boostCreditQuantity =
        basket.boostCreditSummary().length > 0 ? basket.boostCreditSummary()[0].quantity : undefined

      const toLetBoardDescription =
        basket.toLetBoardSummary().length > 0
          ? basket.toLetBoardSummary()[0].description
          : undefined

      const toLetBoardQuantity =
        basket.toLetBoardSummary().length > 0 ? basket.toLetBoardSummary()[0].quantity : undefined

      logEvent('Proceed to Payment', {
        'Advertisement Line Item Description': advertisementDescription,
        'Advertisement Line Item Quantity': advertisementQuantity,
        'Boost Credit Line Item Description': boostCreditDescription,
        'Boost Credit Line Item Quantity': boostCreditQuantity,
        'To Let Board Line Item Description': toLetBoardDescription,
        'To Let Board Line Item Quantity': toLetBoardQuantity,
        'Display Advert From Date': advertisedFromDate,
        'Display Advert From Date Delta (Days)': advertisedFromDateDelta,
        'Basket Total': orderTotalWithVat,
        'Discount Id': id,
        'Discount Name': name,
        'Discount Amount': amount,
      })
    }

    history.push('/landlord-admin/checkout')
  }

  const validateAdvertisedFromDate = (event) => {
    const advertisedFromDateString = event.target.value
    const todayDateString = today.format(dateFormat.DDMMYYYY)
    const advertisedFrom = ValidatedDate.forDate(advertisedFromDateString)

    if (advertisedFrom.isAnInvalidDate()) {
      setAdvertisedFromErrorMessage('Date must be in the format: DD/MM/YYYY')
    }

    if (advertisedFrom.isAValidDate()) {
      setAdvertisedFromErrorMessage('')
    }

    if (advertisedFrom.isBefore(todayDateString)) {
      setAdvertisedFromErrorMessage("Date can't be in the past.")
    }

    basket.updateAdvertisedFromDateForAllAdvertisements(advertisedFromDateString)
    setAdvertisedFromDate(advertisedFromDateString)
  }

  const isAdvertisedFromDateInvalid = () => advertisedFromErrorMessage !== ''
  const isAdvertisementOptionInvalid = () => !selectedAdvertisingPeriod.price
  const isFormInvalid = () => isAdvertisementOptionInvalid() || isAdvertisedFromDateInvalid()
  const advertText = moreThanOnePropertySelected ? 'adverts' : 'advert'

  const addAdvertisingPeriodToBasket = async (selectedAdvertisingPeriod) => {
    setSelectedAdvertisingPeriod(selectedAdvertisingPeriod)

    const addAdvertisingPeriodToBasketForEachSelectedProperty = (addAdvertFor) => {
      propertyIds.selected().forEach((id) => {
        addAdvertFor(id, advertisedFromDate)
      })
    }

    switch (selectedAdvertisingPeriod.numberOfMonths) {
      case 1:
        addAdvertisingPeriodToBasketForEachSelectedProperty(basket.addOneMonthAdvertisement)
        break
      case 3:
        addAdvertisingPeriodToBasketForEachSelectedProperty(basket.addThreeMonthAdvertisement)
        break
      case 6:
        addAdvertisingPeriodToBasketForEachSelectedProperty(basket.addSixMonthAdvertisement)
        break
      case 12:
        addAdvertisingPeriodToBasketForEachSelectedProperty(basket.addTwelveMonthAdvertisement)
        break
      default:
    }

    setLoading(true)

    getDiscount().then(() => setLoading(false))
  }

  const addToLetBoardsToBasket = () => {
    propertyIds.selected().forEach((id) => {
      basket.addToLetBoard(id)
    })
  }

  const removeToLetBoardsFromBasket = () => {
    propertyIds.selected().forEach((id) => {
      basket.removeToLetBoard(id)
    })
  }

  const onToLetBoardFieldChange = () => {
    setToLetBoardsAdded((currentlyAdded) => {
      if (currentlyAdded) {
        removeToLetBoardsFromBasket()
      } else {
        addToLetBoardsToBasket()
      }

      return !currentlyAdded
    })
  }

  return (
    <div className="advertisement-options__container">
      <FormPageHeading
        heading={`Property ${advertText}`}
        subheading={`Select a duration and start date from when you want the ${advertText} to appear on the website.`}
      />
      <form className="advertisement-options__form" onSubmit={handleSubmit}>
        <FieldAdDurationGroup
          adDurationOptions={adOptions}
          selectedOption={selectedAdvertisingPeriod}
          handleSelectOption={addAdvertisingPeriodToBasket}
          hasDiscountApplied={hasDiscountApplied}
        />
        <Field
          className={classNames('advertisement-options__advertised-from', {
            'advertisement-options__advertised-from--with-discount': hasDiscountApplied,
          })}
          name="advertisedFrom"
          label={`Display ${advertText} from`}
          value={advertisedFromDate}
          placeholder="DD/MM/YYYY"
          onChange={(event) => validateAdvertisedFromDate(event)}
          error={advertisedFromErrorMessage}
          invalid={isAdvertisedFromDateInvalid()}
        />
        {/*<ToLetBoardField*/}
        {/*  className={classNames('advertisement-options__to-let-board-field', {*/}
        {/*    'advertisement-options__to-let-board-field--with-discount': hasDiscountApplied,*/}
        {/*  })}*/}
        {/*  checked={toLetBoardsAdded}*/}
        {/*  onChange={onToLetBoardFieldChange}*/}
        {/*  numberOfBoards={propertyIds.selected().length}*/}
        {/*/>*/}
        <ProceedToPaymentConfirmation
          className="advertisement-options__proceed-confirmation"
          hasDiscountApplied={hasDiscountApplied}
          discount={discount}
          nonDiscountedSubtotal={nonDiscountedOrderSubtotal}
          subtotal={orderSubtotal}
          disabled={isFormInvalid()}
          inactive={loading}
        >
          Proceed to Payment
        </ProceedToPaymentConfirmation>
      </form>
    </div>
  )
}

AdvertisementOptions.propTypes = {
  history: PropTypes.object,
  today: PropTypes.object,
  onSetDiscountApplied: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
}

export default AdvertisementOptions
