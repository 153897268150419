import React, { useState } from 'react'
import PropTypes from 'prop-types'
import OverlaySlideUp from '@afs/components/OverlaySlideUp'
import Notification from '@afs/components/Notification'
import SpinnerButton from '@afs/components/SpinnerButton'
import { useOverlayContext } from '@afs/components/OverlayContext'

import requestStatus from '../../../constants/requestStatus'

import Button from '../../atoms/Button'
import PropertyCardWithImageAndAddress from '../../molecules/PropertyCardWithImageAndAddress'
import BillsOptionalBreakdown from '../../molecules/BillsOptionalBreakdown'

import styles from './styles.module.scss'

const QuoteDetailsOverlay = ({
  property,
  initialIsActive,
  quoteDetailsOverlayHeading,
  quoteDetailsOverlayButtonText,
  removeOverlayHeading,
  removeOverlaySubheading,
  removeOverlayButtonText,
  renderButton,
  handleRemoveProperty,
}) => {
  const [removePropertyRequestStatus, setRemovePropertyRequestStatus] = useState(requestStatus.idle)

  const { hasPhoto, photo, address, quote, rentFrom } = property

  const onRemovePropertySuccess = (closeOverlay) => {
    setRemovePropertyRequestStatus(requestStatus.idle)

    closeOverlay()
  }

  const onRemovePropertyError = () => {
    setRemovePropertyRequestStatus(requestStatus.error)
  }

  const handleRemoveButtonClick = (closeOverlay) => {
    setRemovePropertyRequestStatus(requestStatus.waiting)

    handleRemoveProperty(
      property.propertyId,
      () => onRemovePropertySuccess(closeOverlay),
      onRemovePropertyError
    )
  }

  return (
    <OverlaySlideUp
      overlayUniqueId={`quote-${property.propertyId}`}
      initialIsActive={initialIsActive}
      heading={quoteDetailsOverlayHeading}
      useOverlayContext={useOverlayContext}
      renderButton={renderButton}
      renderFooter={({
        openOverlay: openQuoteDetailsOverlay,
        closeOverlay: closeQuoteDetailsOverlay,
      }) => (
        <div className={styles.buttons}>
          <Button onClick={closeQuoteDetailsOverlay} className={styles.button} violet>
            Done
          </Button>

          <OverlaySlideUp
            overlayUniqueId={`remove-quote-${property.propertyId}`}
            data-testid="overlay"
            heading={removeOverlayHeading}
            useOverlayContext={useOverlayContext}
            closeOnClickOutside={false}
            onClose={() => {
              openQuoteDetailsOverlay()
              setRemovePropertyRequestStatus(requestStatus.idle)
            }}
            renderButton={(onClick) => (
              <Button
                onClick={() => {
                  closeQuoteDetailsOverlay()
                  onClick()
                }}
                className={styles.button}
              >
                {quoteDetailsOverlayButtonText}
              </Button>
            )}
            renderFooter={({
              closeOverlay: closeRemoveOverlay,
              closeOverlayWithoutOnCloseCallback: closeRemoveOverlayWithoutOpeningQuoteOverlay,
            }) => (
              <div className={styles.buttons}>
                <SpinnerButton
                  className={styles.button}
                  onClick={() =>
                    handleRemoveButtonClick(closeRemoveOverlayWithoutOpeningQuoteOverlay)
                  }
                  deletion
                  inactive={removePropertyRequestStatus === requestStatus.waiting}
                >
                  {removeOverlayButtonText}
                </SpinnerButton>
                <Button
                  className={styles.button}
                  onClick={() => {
                    closeRemoveOverlay()
                    openQuoteDetailsOverlay()
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          >
            {removePropertyRequestStatus === requestStatus.error ? (
              <Notification
                className={styles.notification}
                type="error"
                heading="Sorry!"
                text="We couldn’t remove this property. Please try again."
              />
            ) : null}

            <p className={styles.subheading}>{removeOverlaySubheading}</p>

            <PropertyCardWithImageAndAddress hasPhoto={hasPhoto} photo={photo} address={address} />
          </OverlaySlideUp>
        </div>
      )}
    >
      <PropertyCardWithImageAndAddress
        className={styles.propertyCard}
        hasPhoto={hasPhoto}
        photo={photo}
        address={address}
      />
      <BillsOptionalBreakdown className={styles.billsBreakdown} rentFrom={rentFrom} quote={quote} />
    </OverlaySlideUp>
  )
}

QuoteDetailsOverlay.propTypes = {
  property: PropTypes.shape({
    propertyId: PropTypes.number.isRequired,
    photo: PropTypes.string.isRequired,
    hasPhoto: PropTypes.bool.isRequired,
    address: PropTypes.shape({
      line1: PropTypes.string.isRequired,
      line2: PropTypes.string.isRequired,
    }),
    rentFrom: PropTypes.string.isRequired,
    quote: PropTypes.shape({
      totalPricePppw: PropTypes.string.isRequired,
      lastUpdated: PropTypes.string,
      services: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          pricePppw: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          available: PropTypes.bool.isRequired,
        })
      ),
    }).isRequired,
  }).isRequired,
  initialIsActive: PropTypes.bool,
  renderButton: PropTypes.func.isRequired,
  handleRemoveProperty: PropTypes.func.isRequired,
  quoteDetailsOverlayHeading: PropTypes.string.isRequired,
  quoteDetailsOverlayButtonText: PropTypes.string.isRequired,
  removeOverlayHeading: PropTypes.string.isRequired,
  removeOverlaySubheading: PropTypes.string.isRequired,
  removeOverlayButtonText: PropTypes.string.isRequired,
}

export default QuoteDetailsOverlay
