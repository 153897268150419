import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SpinnerButton from '@afs/components/SpinnerButton'

import Button from '../../atoms/Button'
import Overlay from '../Overlay'
import PropertyCard from '../../molecules/PropertyCard'

import deleteOverlay from './deleteOverlay.module.scss'

const DeletePropertyOverlay = ({
  propertyId,
  history,
  deletePropertyById,
  getPropertyById,
  notificationService,
  useAuthContext,
  useStateContext,
}) => {
  const [overlay, setOverlay] = useState(false)
  const [deletingProperty, setDeletingProperty] = useState(false)
  const [property, setProperty] = useState(null)

  const { token } = useAuthContext()
  const { propertyIds } = useStateContext()

  const handleDeleteProperty = event => {
    event.preventDefault()

    setDeletingProperty(true)

    deletePropertyById(propertyId, token).then(response => {
      if (response.success) {
        propertyIds.deselectAll()

        notificationService.addDeferredSuccessNotification(
          '',
          'Your property was successfully deleted.'
        )

        history.push('/landlord-admin/properties')
      } else {
        setOverlay(false)
        setDeletingProperty(false)

        notificationService.showErrorNotification('', 'There was a problem deleting your property.')
      }
    })
  }

  const handleOverlayOpen = event => {
    event.preventDefault()

    notificationService.clearNotifications()

    getPropertyById(propertyId, token).then(response => {
      if (response.success) {
        setProperty(response.property)
        setOverlay(true)
      }
    })
  }

  const handleOverlayClose = () => {
    setOverlay(false)
    setDeletingProperty(false)
  }

  const renderDeletePropertyOverlay = () => {
    return (
      <Overlay
        id="delete-property"
        heading="Delete property"
        closeLabel="Cancel"
        isActive={overlay}
        onClose={handleOverlayClose}
      >
        <div>
          <p
            dangerouslySetInnerHTML={{
              __html:
                'Are you sure you want to delete this property? This action <strong>can not be undone</strong>.',
            }}
          />
          {property && (
            <PropertyCard
              className={deleteOverlay.property}
              property={property}
              withoutLink
              withBorder
            />
          )}
          <div className={deleteOverlay.actions}>
            <SpinnerButton
              className={deleteOverlay.button}
              inactive={deletingProperty}
              onClick={handleDeleteProperty}
              data-testid="button-delete-property"
              deletion
            >
              Delete property
            </SpinnerButton>
            <Button
              className={deleteOverlay.button}
              onClick={handleOverlayClose}
              data-testid="button-cancel"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Overlay>
    )
  }

  return (
    <>
      {renderDeletePropertyOverlay()}
      <Button onClick={handleOverlayOpen} deletion data-testid="button-open-delete-overlay">
        Delete property
      </Button>
    </>
  )
}

DeletePropertyOverlay.propTypes = {
  propertyId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  deletePropertyById: PropTypes.func.isRequired,
  getPropertyById: PropTypes.func.isRequired,
  notificationService: PropTypes.object.isRequired,
  useAuthContext: PropTypes.func.isRequired,
  useStateContext: PropTypes.func.isRequired,
}

export default DeletePropertyOverlay
