import React, { useRef, useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Downshift from 'downshift'
import Label from '@afs/components/Label'

import Menu from './Menu'

import styles from './styles.module.scss'

const getSelectedItem = (options, selectedItem) => {
  const result = options.find((item) => item.value === selectedItem)

  return result || { value: '', label: '' }
}

const getInputValueFromSelectedItem = (options, selectedItem) =>
  selectedItem ? getSelectedItem(options, selectedItem).label : ''

const Error = ({ children, ...props }) => <span {...props}>{children}</span>

const Dropdown = ({
  className,
  label,
  name,
  options,
  selectedItem,
  handleChange,
  handleFocus,
  handleBlur,
  handleSubmit,
  error,
  valid,
  invalid,
  disabled,
  required,
  placeholder = '',
  notFoundText = 'No options found',
  ...props
}) => {
  const inputRef = useRef(null)
  const dirtyRef = useRef(false)
  const [dropdownState, setDropdownState] = useState({
    inputValue: getInputValueFromSelectedItem(options, selectedItem),
    selectedItem,
    isOpen: false,
  })

  useLayoutEffect(() => {
    if (!selectedItem) {
      setDropdownState((prevState) => ({
        ...prevState,
        inputValue: '',
      }))
    }
  }, [selectedItem])

  useLayoutEffect(() => {
    const { isOpen, inputValue } = dropdownState

    if (!isOpen && !inputValue) {
      setDropdownState((prevState) => ({
        ...prevState,
        inputValue: getInputValueFromSelectedItem(options, selectedItem),
      }))
    }
  }, [dropdownState.isOpen])

  const getInputClasses = (isOpen) =>
    classNames(styles.input, {
      [styles.valid]: valid,
      [styles.invalid]: invalid,
      [styles.disabled]: disabled,
      [styles.expanded]: isOpen,
    })

  const handleStateChange = (changes) => {
    const clickItem = changes.type === Downshift.stateChangeTypes.clickItem
    const keyDownEnter = changes.type === Downshift.stateChangeTypes.keyDownEnter
    const keyDownEscape = changes.type === Downshift.stateChangeTypes.keyDownEscape

    // Selected item changed
    if (changes.hasOwnProperty('selectedItem')) {
      const { selectedItem } = changes

      if (selectedItem) {
        setDropdownState((prevState) => ({
          ...prevState,
          selectedItem: selectedItem.value,
          inputValue: selectedItem.label,
        }))

        dirtyRef.current = false

        // Submit on enter if handleSubmit provided
        if (keyDownEnter && handleSubmit) {
          handleSubmit(selectedItem.value)
        } else {
          handleChange(selectedItem.value)
        }
      }

      inputRef.current.blur()
    }

    // Click/press enter on already selected item
    if ((clickItem || keyDownEnter) && !changes.hasOwnProperty('selectedItem')) {
      // Submit on enter if handleSubmit provided
      if (keyDownEnter && handleSubmit) {
        handleSubmit(dropdownState.selectedItem)
      } else {
        handleChange(dropdownState.selectedItem)
      }
      inputRef.current.blur()
    }

    // Press escape while typing and nothing selected
    if (keyDownEscape && !changes.selectedItem) {
      setDropdownState((prevState) => ({
        ...prevState,
        inputValue: '',
      }))

      dirtyRef.current = false
    }
  }

  const handleInputChange = (event) => {
    const { value } = event.target
    const selectedItem = getSelectedItem(options, value)

    if (selectedItem.value) {
      setDropdownState((prevState) => ({
        ...prevState,
        selectedItem,
        inputValue: value,
        isOpen: false,
      }))

      handleChange(value)
      inputRef.current.blur()
    } else {
      setDropdownState((prevState) => ({
        ...prevState,
        inputValue: value || '',
      }))

      dirtyRef.current = true
    }
  }

  const handleInputFocus = (event) => {
    setDropdownState((prevState) => ({
      ...prevState,
      inputValue: '',
      isOpen: true,
    }))

    if (handleFocus) {
      handleFocus(event)
    }
  }

  const handleInputBlur = (event) => {
    const { selectedItem } = dropdownState

    if (dirtyRef.current) {
      setDropdownState((prevState) => ({
        ...prevState,
        inputValue: getInputValueFromSelectedItem(options, selectedItem),
        isOpen: false,
      }))

      dirtyRef.current = false
    } else {
      setDropdownState((prevState) => ({
        ...prevState,
        isOpen: false,
      }))
    }

    if (handleBlur) {
      handleBlur(event)
    }
  }

  return (
    <div className={classNames(styles.field, className)} {...props}>
      <Downshift
        isOpen={dropdownState.isOpen}
        onStateChange={handleStateChange}
        selectedItem={getSelectedItem(options, selectedItem) || ''}
        itemToString={(item) => (item ? item.label : '')}
        defaultHighlightedIndex={0}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          isOpen,
          highlightedIndex,
        }) => {
          return (
            <div className={styles.field}>
              {label && (
                <Label className={styles.label} {...getLabelProps({ htmlFor: name })}>
                  {label}
                </Label>
              )}
              <input
                {...getInputProps({
                  ref: inputRef,
                  id: name,
                  className: getInputClasses(isOpen),
                  name,
                  value: dropdownState.inputValue,
                  type: 'text',
                  placeholder,
                  onChange: handleInputChange,
                  onFocus: (event) => handleInputFocus(event),
                  onBlur: (event) => handleInputBlur(event),
                  disabled,
                  required,
                  'data-testid': `field-${name}`,
                  'aria-describedby': `${name}-error`,
                  autoComplete: 'on',
                })}
              />
              <Menu
                isOpen={isOpen}
                options={options}
                inputValue={dropdownState.inputValue}
                selectedItem={getSelectedItem(options, selectedItem)}
                highlightedIndex={highlightedIndex}
                getMenuProps={getMenuProps}
                getItemProps={getItemProps}
                notFoundText={notFoundText}
              />
            </div>
          )
        }}
      </Downshift>
      {invalid && error && (
        <Error
          id={`${name}-error`}
          className={styles.description}
          role="alert"
          data-testid={`field-${name}-error`}
        >
          {error}
        </Error>
      )}
    </div>
  )
}

Error.propTypes = {
  children: PropTypes.any,
}

Dropdown.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selectedItem: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleFocus: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  error: PropTypes.string,
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  notFoundText: PropTypes.string,
}

export default Dropdown
