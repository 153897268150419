import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SpinnerButton from '@afs/components/SpinnerButton'
import Field from '@afs/components/Field'

import dateFormat from '../../../models/dateFormat'
import ValidatedDate from '../../../models/validatedDate'
import formatDateForApi from '../../../utils/formatDateForApi'

import Link from '../../atoms/Link'
import FormPageHeading from '../../molecules/FormPageHeading'
import TwoColumnPageTemplate from '../../templates/TwoColumnPageTemplate'
import Header from '../../organisms/Header'
import MobileContextMenu from '../../organisms/MobileContextMenu'
import SelectedPropertiesInBasket from '../../organisms/SelectedPropertiesInBasket'
import Button from '../../atoms/Button'
import CreditCounter from '../../molecules/CreditCounter'
import Notification from '../../molecules/Notification'

import styles from './useAdvertiseCreditsPage.module.scss'

const UseAdvertiseCreditsPage = ({
  selectedProperties,
  advertiseCredits,
  advertiseProperties,
  deselectProperty,
  notification,
  today,
}) => {
  const [advertisedFrom, setAdvertisedFrom] = useState({
    date: today.format(dateFormat.DDMMYYYY),
    error: '',
    submitted: false,
  })
  const [loading, setLoading] = useState(false)

  const numberOfSelectedProperties = selectedProperties.length
  const atLeastOneSelected = numberOfSelectedProperties > 0
  const moreThanOneSelected = numberOfSelectedProperties > 1

  const insufficientCredits =
    !advertiseCredits.advertiseCredits.unlimited &&
    numberOfSelectedProperties > advertiseCredits.advertiseCredits.amount

  const handleChangeAdvertiseFromDate = event => {
    const advertisedFromDateString = event.target.value
    const todayDateString = today.format(dateFormat.DDMMYYYY)
    const advertisedFrom = ValidatedDate.forDate(advertisedFromDateString)

    if (advertisedFrom.isAnInvalidDate()) {
      setAdvertisedFrom(prevState => ({
        ...prevState,
        error: 'Date must be in the format: DD/MM/YYYY.',
      }))
    }

    if (advertisedFrom.isAValidDate()) {
      setAdvertisedFrom(prevState => ({
        ...prevState,
        error: '',
      }))
    }

    if (advertisedFrom.isBefore(todayDateString)) {
      setAdvertisedFrom(prevState => ({
        ...prevState,
        error: "Date can't be in the past.",
      }))
    }

    setAdvertisedFrom(prevState => ({
      ...prevState,
      date: advertisedFromDateString,
    }))
  }

  const advertisedFromDateIsInvalid = advertisedFrom.error !== '' && advertisedFrom.submitted

  const handleUseAdvertiseCredits = async () => {
    setAdvertisedFrom(prevState => ({
      ...prevState,
      submitted: true,
    }))

    if (!advertisedFromDateIsInvalid) {
      setLoading(true)
      const startingDate = formatDateForApi(advertisedFrom.date)
      const propertyIds = selectedProperties.map(property => property.id)

      const payload = {
        startingDate,
        propertyIds,
      }

      const response = await advertiseProperties(payload)
      if (response) {
        setLoading(false)
      } else {
        setLoading(false)
      }
    }
  }

  const advertText = moreThanOneSelected ? 'adverts' : 'advert'

  const renderLeftColumn = () => {
    return (
      <>
        <FormPageHeading
          heading={`Advertise ${moreThanOneSelected ? 'properties' : 'property'}`}
          subheading={`Select a start date from when you want the ${advertText} to appear on the website.`}
        />
        <Field
          className={styles.advertiseFrom}
          name="advertisedFrom"
          label={`Display ${advertText} from`}
          value={advertisedFrom.date}
          placeholder="DD/MM/YYYY"
          onChange={handleChangeAdvertiseFromDate}
          error={advertisedFrom.error}
          invalid={advertisedFromDateIsInvalid}
          data-testid="advertise-from-field"
        />
        <span className={styles.label}>Your credits</span>
        <CreditCounter
          creditType={
            advertiseCredits.advertiseCredits.unlimited ? 'unlimitedAdvertise' : 'advertise'
          }
          credits={advertiseCredits.advertiseCredits.amount}
          subheading={advertiseCredits.advertiseCredits.advertisingPeriod}
          creditCost={numberOfSelectedProperties}
        />
        {insufficientCredits && (
          <div className={styles.insufficientCredits} data-testid="insufficient-credits">
            <p className={styles.insufficientCreditsHeading}>Insufficient credits</p>
            <p className={styles.insufficientCreditsText}>
              You don’t have enough advertise credits for this transaction.
            </p>
            <p className={styles.insufficientCreditsText}>
              <Link to="/landlord-admin/properties" className={styles.insufficientCreditsLink}>
                Remove 2 properties
              </Link>{' '}
              from your selection in order to continue, or{' '}
              <Link
                to="/landlord-admin/advertisement-options"
                className={styles.insufficientCreditsLink}
              >
                use your card to pay{' '}
              </Link>
              for this transaction.
            </p>
          </div>
        )}
        {notification && (
          <Notification
            className={styles.notification}
            heading="Oops!"
            text={`Something went wrong and we could not advertise your ${
              moreThanOneSelected ? 'properties' : 'property'
            }. Please try again later.`}
          />
        )}

        <div className={styles.buttonWrapper}>
          {insufficientCredits ? (
            <Button
              className={styles.button}
              onClick={handleUseAdvertiseCredits}
              primary
              inactive={insufficientCredits}
              data-testid="advertise-properties-button-inactive"
            >
              {`Advertise ${moreThanOneSelected ? 'properties' : 'property'}`}
            </Button>
          ) : (
            <SpinnerButton
              className={styles.button}
              onClick={handleUseAdvertiseCredits}
              primary
              inactive={loading}
              data-testid="advertise-properties-button"
            >
              {`Advertise ${moreThanOneSelected ? 'properties' : 'property'}`}
            </SpinnerButton>
          )}
        </div>
      </>
    )
  }

  return (
    <TwoColumnPageTemplate
      header={<Header />}
      title="Advertise properties | AFS"
      className={styles.page}
      contextMenu={
        atLeastOneSelected && (
          <MobileContextMenu
            className={styles.mobileMenu}
            overview={
              moreThanOneSelected
                ? `${numberOfSelectedProperties} properties selected`
                : '1 property selected'
            }
          >
            <SelectedPropertiesInBasket
              selectedProperties={selectedProperties}
              deselectProperty={deselectProperty}
            />
          </MobileContextMenu>
        )
      }
      leftColumn={renderLeftColumn()}
      rightColumn={
        <SelectedPropertiesInBasket
          heading={`Selected ${moreThanOneSelected ? 'properties' : 'property'}`}
          selectedProperties={selectedProperties}
          deselectProperty={deselectProperty}
        />
      }
    />
  )
}

UseAdvertiseCreditsPage.propTypes = {
  selectedProperties: PropTypes.array.isRequired,
  advertiseCredits: PropTypes.object.isRequired,
  advertiseProperties: PropTypes.func.isRequired,
  deselectProperty: PropTypes.func.isRequired,
  notification: PropTypes.string,
  today: PropTypes.object.isRequired,
}

export default UseAdvertiseCreditsPage
