import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Spinner from '@afs/components/Spinner'

import InfoTooltip from '@afs/components/InfoTooltip'
import ValueWithLabel from '../ValueWithLabel'

import PerformanceEnquiries from '../../../svgs/icons/performance-enquiries.svg'
import PerformanceImpressions from '../../../svgs/icons/performance-impressions.svg'

import performanceCard from './performanceCard.module.scss'

const icons = [
  { name: 'enquiries', svg: <PerformanceEnquiries className={performanceCard.icon} /> },
  { name: 'impressions', svg: <PerformanceImpressions className={performanceCard.icon} /> },
]

const PerformanceOverviewCard = ({
  className,
  label,
  value,
  loading,
  showWarning,
  showError,
  tooltipMessage,
}) => {
  const componentClasses = classNames(performanceCard.card, className)
  const valueClasses = classNames(performanceCard.value, {
    [performanceCard.valueWarning]: showWarning,
  })

  const icon = icons.find((icon) => icon.name === label).svg

  const renderValue = () => {
    if (loading) {
      return (
        <Spinner
          className={performanceCard.spinner}
          data-testid="performance-overview-card-spinner"
        />
      )
    }
    if (showError) {
      return '—'
    }
    return value
  }

  return (
    <div className={componentClasses} data-testid={`performance-overview-card-${label}`}>
      <ValueWithLabel
        className={performanceCard.valueWithLabel}
        value={renderValue()}
        valueClass={valueClasses}
        label={label}
        labelClass={performanceCard.label}
        innerClass={performanceCard.inner}
      >
        {tooltipMessage && <InfoTooltip medium tooltipContent={tooltipMessage} />}
      </ValueWithLabel>
      {icon}
    </div>
  )
}

PerformanceOverviewCard.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  loading: PropTypes.bool,
  showWarning: PropTypes.bool,
  showError: PropTypes.bool,
  tooltipMessage: PropTypes.element,
}

export default PerformanceOverviewCard
