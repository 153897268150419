import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { notificationService } from '../../../container'

import BackLink from '../../molecules/BackLink'
import TwoColumnPageTemplate from '../../templates/TwoColumnPageTemplate'
import Header from '../../organisms/Header'
import Heading from '../../atoms/Heading'
import Button from '../../atoms/Button'
import LinkButton from '../../atoms/LinkButton'
import SavedCard from '../../molecules/SavedCard'
import Notifications from '../../organisms/Notifications'
import PaymentSettingsOverlay from './PaymentSettingsOverlay'
import SubscriptionCard from '../../molecules/SubscriptionCard'

import paymentSettingsPage from './paymentSettingsPage.module.scss'

const PaymentSettingsPage = ({
  history,
  accountHasCards,
  accountHasSubscriptions,
  cards,
  subscriptions,
  handleRemoveSavedCard,
  loadingForm,
  showNotifications,
}) => {
  const [overlay, setOverlay] = useState({ active: false, action: '', selectedCard: null })
  const handleOverlayClose = () => {
    setOverlay({ active: false, action: '', selectedCard: null })
  }

  const showRemoveCardOverlay = selectedCard => {
    notificationService.clearNotifications()
    setOverlay({ active: true, action: 'removeCard', selectedCard })
  }

  const renderLeftColumn = () => (
    <>
      <BackLink
        className="form-navigation__back-link"
        onClick={event => {
          event.preventDefault()
          history.goBack()
        }}
      />
      <Heading level={2}>Payments</Heading>
      <section className={paymentSettingsPage.savedCards}>
        <Heading className={paymentSettingsPage.savedCardsHeading} level={4}>
          Saved cards
        </Heading>
        {showNotifications && <Notifications className={paymentSettingsPage.notifications} />}
        {accountHasCards ? (
          <div className={paymentSettingsPage.savedCardsList} data-testid="saved-cards-list">
            {cards.map(card => (
              <div
                key={card.paymentMethodId}
                className={paymentSettingsPage.savedCardsListRow}
                data-testid={`card-${card.lastDigits}`}
              >
                <SavedCard card={card} />
                <LinkButton
                  className={paymentSettingsPage.removeButton}
                  onClick={() => showRemoveCardOverlay(card)}
                  data-testid="remove-card"
                >
                  Remove
                </LinkButton>
              </div>
            ))}
          </div>
        ) : (
          <div className={paymentSettingsPage.noSavedCards} data-testid="no-saved-cards-text">
            No saved cards
          </div>
        )}
      </section>

      {accountHasSubscriptions && (
        <section className={paymentSettingsPage.subscriptions}>
          <Heading className={paymentSettingsPage.subscriptionsHeading} level={4}>
            Subscriptions
          </Heading>
          {subscriptions.map(subscription => {
            return (
              <SubscriptionCard
                key={subscription.id}
                className={paymentSettingsPage.subscriptionCard}
                subscription={subscription}
                paymentMethod={subscription.paymentMethod}
              />
            )
          })}
        </section>
      )}

      <section className={paymentSettingsPage.invoices}>
        <Heading className={paymentSettingsPage.invoicesHeading} level={4}>
          Invoices
        </Heading>
        <p className={paymentSettingsPage.text}>
          You can download invoices and view a complete list of your transaction history from the
          Invoices page.
        </p>
        <Button to="/landlord-admin/invoices" data-testid="view-invoices-button">
          View Invoices
        </Button>
      </section>
      <PaymentSettingsOverlay
        action={overlay.action}
        active={overlay.active}
        card={overlay.selectedCard}
        handleClose={handleOverlayClose}
        handleRemoveCard={handleRemoveSavedCard}
        loading={loadingForm}
        data-testid="payment-settings-overlay"
      />
    </>
  )

  return (
    <TwoColumnPageTemplate
      header={<Header />}
      title="Account Settings - Payments | AFS"
      leftColumn={renderLeftColumn()}
    />
  )
}

PaymentSettingsPage.propTypes = {
  history: PropTypes.object.isRequired,
  accountHasCards: PropTypes.bool,
  accountHasSubscriptions: PropTypes.bool,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.string.isRequired,
      lastDigits: PropTypes.string.isRequired,
      expiryMonth: PropTypes.string.isRequired,
      expiryYear: PropTypes.string.isRequired,
      paymentMethodId: PropTypes.string.isRequired,
    })
  ),
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      productName: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      pricePerMonth: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
      }).isRequired,
      nextPaymentDate: PropTypes.string.isRequired,
      cancelsAt: PropTypes.string.isRequired,
      paymentMethod: PropTypes.shape({
        brand: PropTypes.string.isRequired,
        lastDigits: PropTypes.string.isRequired,
        expiryMonth: PropTypes.string.isRequired,
        expiryYear: PropTypes.string.isRequired,
        paymentMethodId: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
  handleRemoveSavedCard: PropTypes.func.isRequired,
  loadingForm: PropTypes.bool.isRequired,
  showNotifications: PropTypes.bool.isRequired,
}

export default PaymentSettingsPage
