import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.scss'

import CloseIcon from '../../../svgs/icons/close.svg'

const CloseButton = ({ className, children = 'Close', onClose, ...props }) => {
  const buttonClasses = classNames(className, 'close-button')

  return (
    <button className={buttonClasses} type="button" onClick={onClose} {...props}>
      {children}
      <CloseIcon className="close-button__icon" />
    </button>
  )
}

CloseButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

export default CloseButton
