import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { getAccountAdvertiseCredits } from '../../api/accountApi'
import {
  getSelectedProperties,
  advertisePropertiesUsingAdvertiseCredits,
} from '../../api/propertyApi'
import { useAuthContext } from '../../authContext'
import { useStateContext } from '../../appStateContext'
import { notificationService } from '../../container'

import UseAdvertiseCreditsPage from '../pages/UseAdvertiseCreditsPage/index'
import LoadingPage from '../pages/LoadingPage'
import ErrorPage from '../pages/ErrorPage'

export const UseAdvertiseCreditsContainer = ({
  useAdvertiseCreditsPage,
  getLandlordAdvertiseCredits,
  getSelectedProperties,
  advertisePropertiesUsingAdvertiseCredits,
  useAuthContext,
  useStateContext,
  notificationService,
  errorPage,
  history,
  today,
}) => {
  const [loading, setLoading] = useState(true)
  const [serverError, setServerError] = useState(false)
  const [advertiseCredits, setAdvertiseCredits] = useState(null)
  const [selectedProperties, setSelectedProperties] = useState([])
  const [notification, setNotifcation] = useState(false)

  const { token } = useAuthContext()
  const { propertyIds } = useStateContext()

  useEffect(() => {
    if (propertyIds.noneSelected()) {
      history.push('/landlord-admin/properties')
    }

    getLandlordAdvertiseCredits(token).then(response => {
      if (response.success) {
        setAdvertiseCredits(response.advertiseCreditsData)

        getSelectedProperties(propertyIds.selected(), token).then(response => {
          if (response.success) {
            setSelectedProperties(response.properties)
            setLoading(false)
          } else {
            setServerError(true)
          }
        })
      } else {
        setServerError(true)
      }
    })
  }, [])

  const handleDeselectProperty = propertyId => {
    propertyIds.deselect(propertyId)
    const propertiesWithTheDeselectedPropertyRemoved = selectedProperties.filter(
      property => property.id !== propertyId
    )

    setSelectedProperties(propertiesWithTheDeselectedPropertyRemoved)
  }

  const handleAdvertiseProperties = payload => {
    advertisePropertiesUsingAdvertiseCredits(payload, token).then(response => {
      if (response.success) {
        notificationService.addDeferredSuccessNotification(
          'Success!',
          `Your ${
            selectedProperties.length > 1
              ? `${selectedProperties.length} properties are`
              : 'property is'
          } now being advertised.`
        )
        propertyIds.deselectAll()
        history.push('/landlord-admin/properties')
      } else {
        setNotifcation(true)
      }
      return response
    })
  }

  if (serverError) return errorPage()
  if (loading) return <LoadingPage />

  return useAdvertiseCreditsPage(
    selectedProperties,
    advertiseCredits,
    handleAdvertiseProperties,
    handleDeselectProperty,
    notification,
    today
  )
}

UseAdvertiseCreditsContainer.propTypes = {
  useAdvertiseCreditsPage: PropTypes.func.isRequired,
  getLandlordAdvertiseCredits: PropTypes.func.isRequired,
  getSelectedProperties: PropTypes.func.isRequired,
  advertisePropertiesUsingAdvertiseCredits: PropTypes.func.isRequired,
  errorPage: PropTypes.func.isRequired,
  today: PropTypes.object.isRequired,
  useAuthContext: PropTypes.func.isRequired,
  useStateContext: PropTypes.func.isRequired,
  notificationService: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export const UseAdvertiseCreditsContainerCompositionRoot = ({ history }) => {
  return (
    <UseAdvertiseCreditsContainer
      useAdvertiseCreditsPage={(
        selectedProperties,
        advertiseCredits,
        advertiseProperties,
        deselectProperty,
        notification,
        today
      ) => (
        <UseAdvertiseCreditsPage
          selectedProperties={selectedProperties}
          advertiseCredits={advertiseCredits}
          advertiseProperties={advertiseProperties}
          deselectProperty={deselectProperty}
          notification={notification}
          today={today}
        />
      )}
      getLandlordAdvertiseCredits={getAccountAdvertiseCredits}
      getSelectedProperties={getSelectedProperties}
      advertisePropertiesUsingAdvertiseCredits={advertisePropertiesUsingAdvertiseCredits}
      useAuthContext={useAuthContext}
      useStateContext={useStateContext}
      notificationService={notificationService}
      errorPage={() => <ErrorPage />}
      history={history}
      today={moment()}
    />
  )
}

UseAdvertiseCreditsContainerCompositionRoot.propTypes = {
  history: PropTypes.object.isRequired,
}
