import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Overlay from '../../../../molecules/Overlay'
import Toggle from '../../../../molecules/TinyToggle'
import Button from '../../../../atoms/Button'
import Notification from '../../../../molecules/Notification'

import styles from './viewLandlordTestimonialOverlay.module.scss'

const ViewLandlordTestimonialOverlay = ({
  testimonial,
  toggleDisplayTestimonial,
  isActive,
  handleCloseOverlay,
}) => {
  if (testimonial === null) return null

  const [toggling, setToggling] = useState(false)
  const [showNotification, setShowNotification] = useState(false)

  const toggleText = {
    enabled: <span className={styles.toggleText}>Displayed</span>,
    disabled: <span className={styles.toggleText}>Not displayed</span>,
  }

  const toggleDisplay = () => {
    setToggling(true)
    toggleDisplayTestimonial(testimonial.id, testimonial.displayed).then(response => {
      if (!response.success) {
        setShowNotification(true)
      }
      setTimeout(() => setToggling(false), 600) // Delay toggle animation
    })
  }

  return (
    <Overlay
      id="view-testimonial"
      heading="Testimonial"
      isActive={isActive}
      onClose={handleCloseOverlay}
      closeLabel="Close"
      preserveScrollPositionOnClose
    >
      {showNotification && (
        <Notification
          className={styles.notification}
          heading="Oops!"
          text="We couldn't change the display state of this testimonial. Please try again later."
        />
      )}
      <article>
        <section className={styles.sender}>
          <div>
            <strong className={styles.name} data-testid="testimonial-tenant-name">
              {testimonial.tenantName}
            </strong>
            <span className={styles.email} data-testid="testimonial-email">
              {testimonial.tenantEmail}
            </span>
          </div>
          <span className={styles.date} data-testid="testimonial-date">
            {testimonial.date}
          </span>
        </section>
        <section className={styles.body} data-testid="testimonial-body">
          {testimonial.text}
        </section>
      </article>
      <Toggle
        className={styles.toggle}
        enabled={testimonial.displayed}
        toggleText={toggleText}
        handleOnClick={toggleDisplay}
        inactive={toggling}
        data-testid="testimonial-toggle"
      />
      <Button
        className={styles.closeButton}
        onClick={handleCloseOverlay}
        data-testid="testimonial-close-button"
      >
        Close
      </Button>
    </Overlay>
  )
}

ViewLandlordTestimonialOverlay.propTypes = {
  testimonial: PropTypes.shape({
    id: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    new: PropTypes.bool,
    displayed: PropTypes.bool.isRequired,
    tenantName: PropTypes.string.isRequired,
    tenantEmail: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  toggleDisplayTestimonial: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  handleCloseOverlay: PropTypes.func.isRequired,
}

export default ViewLandlordTestimonialOverlay
