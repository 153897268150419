import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Heading from '../../atoms/Heading'

import Stripes from '../../../svgs/illustrations/stripes.svg'
import Rocket from '../../../svgs/illustrations/rocket.svg'
import House from '../../../svgs/illustrations/house.svg'

import creditCounter from './creditCounter.module.scss'

const CreditCounter = ({
  className,
  subheading,
  creditType,
  credits,
  creditCost,
  isHoverable,
  compact,
}) => {
  const notEnoughCredits =
    creditType !== 'unlimitedAdvertise' && creditCost ? creditCost > credits : false

  const heading = () => {
    if (creditType === 'boost') return 'Boost credits'
    if (creditType === 'advertise' || creditType === 'unlimitedAdvertise')
      return 'Advertise credits'
    return ''
  }

  const wrapperClasses = classNames(
    creditCounter.wrapper,
    { [creditCounter.wrapperHoverable]: isHoverable },
    className
  )

  const topCardClasses = classNames(
    creditCounter.topCard,
    { [creditCounter.topCardCompact]: compact },
    { [creditCounter.topCardWithBottomCard]: creditCost },
    { [creditCounter.topCardBoost]: creditType === 'boost' },
    {
      [creditCounter.topCardAdvertise]:
        creditType === 'advertise' || creditType === 'unlimitedAdvertise',
    },
    { [creditCounter.topCardNotEnoughCredits]: notEnoughCredits }
  )

  const headingClasses = classNames(creditCounter.heading, {
    [creditCounter.headingWithoutSubheading]: !subheading,
  })

  const numberOfCreditsWithCommaSeparatedThousands = number => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  const creditLabel = number => {
    if (number === 1) return 'credit'
    if (creditType === 'unlimitedAdvertise') return 'credits'
    return 'credits'
  }

  const costLabel = number => (number === 1 ? 'credit' : 'credits')

  return (
    <div className={wrapperClasses} data-testid="credit-wrapper">
      <div className={topCardClasses}>
        <span className={creditCounter.description}>
          <Heading className={headingClasses} level={5} data-testid="credit-heading">
            {heading()}
          </Heading>
          {subheading && (
            <span
              className={creditCounter.subheading}
              id="subheading"
              data-testid="credit-subheading"
            >
              {subheading}
            </span>
          )}
        </span>
        <span className={creditCounter.credits} data-testid="credit-counter">
          {creditType === 'unlimitedAdvertise' ? (
            <span className={creditCounter.creditsUnlimited}>Unlimited</span>
          ) : (
            <span className={creditCounter.creditsNumber}>
              {numberOfCreditsWithCommaSeparatedThousands(credits)}
            </span>
          )}
          <span className={creditCounter.creditsLabel}>{creditLabel(credits)}</span>
        </span>
        <Stripes className={creditCounter.waveIllustration} />
        {creditType === 'boost' ? (
          <Rocket className={creditCounter.rocketIllustration} />
        ) : (
          <House className={creditCounter.houseIllustration} />
        )}
      </div>
      {creditCost && (
        <div className={creditCounter.bottomCard}>
          <span className={creditCounter.cost}>Cost for this transaction</span>
          <span data-testid="cost-counter">
            <span className={creditCounter.costNumber}>{creditCost} </span>
            <span className={creditCounter.costLabel}>{costLabel(creditCost)}</span>
          </span>
        </div>
      )}
    </div>
  )
}

CreditCounter.propTypes = {
  className: PropTypes.string,
  subheading: PropTypes.string,
  creditType: PropTypes.string.isRequired,
  credits: PropTypes.number.isRequired,
  creditCost: PropTypes.number,
  isHoverable: PropTypes.bool,
  compact: PropTypes.bool,
}

export default CreditCounter
