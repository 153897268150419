import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'

import { notificationService } from '../../../container'

import BackLink from '../../molecules/BackLink'
import FormPageHeading from '../../molecules/FormPageHeading'

import './styles.scss'

const FormNavigation = ({ pages, initialValues, onSubmit, history, className }) => {
  const [activePageId, setActivePageId] = useState(pages.getIdByRoute(history.location.pathname))
  const [values, setValues] = useState(initialValues || {})

  useEffect(() => {
    const nextRouteId = pages.getIdByRoute(history.location.pathname)

    if (activePageId !== nextRouteId) setActivePageId(nextRouteId)
  })

  const next = values => {
    notificationService.clearNotifications()

    const nextPageId = activePageId + 1

    setActivePageId(Math.min(nextPageId, pages.lastPageId()))
    setValues(values)

    const nextRoute = pages.getRouteById(Math.min(nextPageId, pages.lastPageId()))

    const nextPageAlreadyValidated = pages.isPageValid(nextPageId)
    if (!nextPageAlreadyValidated) {
      pages.notifySubscribersOfPageValidity()
    }

    history.push(nextRoute)
  }

  const previous = event => {
    event.preventDefault()

    notificationService.clearNotifications()

    const previousPageId = activePageId - 1

    setActivePageId(Math.max(previousPageId, 0))

    const previousRoute = pages.getRouteById(Math.max(previousPageId, 0))
    history.push(previousRoute)
  }

  const validate = values => {
    const activePage = pages.getById(activePageId)
    return activePage.validate ? activePage.validate(values) : {}
  }

  const handleSubmit = values => {
    const isLastPage = pages.isLastPage(activePageId)
    if (isLastPage) {
      return onSubmit(values)
    } else {
      next(values)
    }
    return {}
  }

  const updatePageValidity = values => {
    pages.updatePageValidity(activePageId, values)
  }

  const activePage = pages.getById(activePageId)

  const redirectToFirstPageIfNotStartedFromFirstPage = (values, initialValues) => {
    const currentRoute = pages.getRouteById(activePageId)
    const firstPageRoute = pages.getRouteById(0)

    const stringyValues = JSON.stringify(values, null, 4)
    const stringyInitialValues = JSON.stringify(initialValues, null, 4)
    const formIsInItsInitialState = stringyValues === stringyInitialValues
    const notStartingOnFirstPage = currentRoute !== firstPageRoute

    if (formIsInItsInitialState && notStartingOnFirstPage) {
      history.push(firstPageRoute)
    }
  }

  return (
    <Form className={className} initialValues={values} validate={validate} onSubmit={handleSubmit}>
      {({ handleSubmit, values }) => {
        updatePageValidity(values)

        redirectToFirstPageIfNotStartedFromFirstPage(values, initialValues)

        return (
          <div className="form-navigation">
            <form className={className} onSubmit={handleSubmit}>
              {!activePage.noBackLink && (
                <BackLink
                  className="form-navigation__back-link"
                  onClick={event => previous(event)}
                />
              )}
              <FormPageHeading
                className="form-navigation__heading"
                heading={activePage.heading}
                headingComponent={
                  activePage.headingComponent && activePage.headingComponent(values)
                }
                subheading={activePage.subheading}
                optional={activePage.optional}
                subheadingComponent={
                  activePage.subheadingComponent && activePage.subheadingComponent(values)
                }
              />
              {activePage.component(values)}
              <div className="form-navigation__buttons">{activePage.button(values)}</div>
            </form>
          </div>
        )
      }}
    </Form>
  )
}

FormNavigation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  pages: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
}

export default FormNavigation
