import React from 'react'

import ManagePropertiesPageTemplate from '../../templates/ManagePropertiesPageTemplate'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Button from '../../atoms/Button'
import NotFoundAnnouncement from '../../molecules/NotFoundAnnouncement'

import NotFound from '../../../svgs/illustrations/not-found.svg'

import notFoundPage from './notFoundPage.module.scss'

const NotFoundPage = () => {
  return (
    <ManagePropertiesPageTemplate
      title="Page Not Found | AFS"
      header={<Header />}
      footer={<Footer dark />}
    >
      <div className={notFoundPage.layout}>
        <NotFoundAnnouncement
          icon={NotFound}
          heading="Page not found"
          subHeading="We can’t seem to find the page you’re looking for. Please check the URL or return to the Dashboard page."
          button={
            <Button to="/landlord-admin" primary data-testid="overview-button">
              Back to Dashboard
            </Button>
          }
          data-testid="not-found"
        />
      </div>
    </ManagePropertiesPageTemplate>
  )
}

export default NotFoundPage
