import React from 'react'
import SpinnerButton from '@afs/components/SpinnerButton'

import { config } from '../../../../config'

import SubmitButton from '../../../atoms/SubmitButton'
import VirtualTourPreview from '../../../molecules/VirtualTourPreview'

import {
  VirtualTourPage,
  virtualTourPageValidation,
} from '../../FormPages/VirtualTourPage/VirtualTourPage'

const virtualTourPageConfig = (propertyId, updatePropertyVirtualTour) => ({
  id: 13,
  component: values => {
    const getThumbnailImageUrl = () => {
      const { items, order } = values.photos.images
      if (items.length > 0) {
        const idOfLastImageInCarousel = order[order.length - 1]
        return items.find(item => item.id === idOfLastImageInCarousel).src
      }
      return config.placeholderImageSrc
    }

    return (
      <VirtualTourPage
        virtualTourUrl={values.virtualTourUrl}
        thumbnailImageUrl={getThumbnailImageUrl()}
        virtualTourPreviewComponent={(virtualTourUrl, thumbnailImageUrl) => (
          <VirtualTourPreview
            virtualTourUrl={virtualTourUrl}
            thumbnailImageUrl={thumbnailImageUrl}
          />
        )}
      />
    )
  },
  route: `/landlord-admin/property/${propertyId}/edit/virtual-tour`,
  validate: values => virtualTourPageValidation(values),
  isValid: false,
  heading: '360° virtual tour',
  subheading: 'Copy your Matterport virtual tour link and paste it in the field below.',
  button: (values, loading) => {
    const urlIsInvalid = !!virtualTourPageValidation(values).virtualTourUrl

    if (urlIsInvalid) return null

    if (loading)
      return (
        <SpinnerButton primary inactive data-testid="spinner-button">
          Continue
        </SpinnerButton>
      )

    return (
      <SubmitButton primary data-testid="save-button">
        Save changes
      </SubmitButton>
    )
  },
  onSubmit: async (values, token) => {
    const payload = {
      propertyId,
      virtualTourUrl: values.virtualTourUrl || '',
    }
    return updatePropertyVirtualTour(payload, token)
  },
})

export default virtualTourPageConfig
