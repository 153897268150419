import React from 'react'
import Button from '../../../../atoms/Button'
import Overlay from '../../../../molecules/Overlay'
import CreditCounter from '../../../../molecules/CreditCounter'

import advertiseCreditsOverlay from './advertiseCreditsOverlay.module.scss'

const renderAdvertiseCreditsOverlay = (overlay, handleOverlayClose) => {
  const { advertiseCreditsData } = overlay

  return (
    <Overlay
      id="advertise-credits"
      heading="Advertise credits"
      closeLabel="Close"
      isActive={overlay.active}
      onClose={handleOverlayClose}
    >
      <div data-testid="advertise-credits-overlay">
        <p className={advertiseCreditsOverlay.text}>
          Use advertise credits to pay for your property adverts.
        </p>
        <CreditCounter
          className={advertiseCreditsOverlay.creditCounter}
          creditType={advertiseCreditsData.unlimited ? 'unlimitedAdvertise' : 'advertise'}
          credits={advertiseCreditsData.amount}
          subheading={advertiseCreditsData.advertisingPeriod}
        />
        <Button
          to="/landlord-admin/advertise-properties"
          primary
          data-testid="use-advertise-credits-overlay-button"
        >
          Use advertise credits
        </Button>
      </div>
    </Overlay>
  )
}

export default renderAdvertiseCreditsOverlay
