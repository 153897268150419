import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Heading from '@afs/components/Heading'
import { MostPopularPill as Pill } from '@afs/components/Pill'

import { notificationService } from '../../../container'
import { GBP } from '../../../models/currency'
import useMedia from '../../../hooks/useMedia'
import Property from '../../../models/property'
import propertyAdvertisementStatus from '../../../constants/propertyAdvertisementStatus'

import TwoColumnPageTemplate from '../../templates/TwoColumnPageTemplate'
import Header from '../../organisms/Header'
import NewPropertyDiscountAdvertisement from '../../organisms/NewPropertyDiscountAdvertisement'
import Notifications from '../../organisms/Notifications'
import FormPageHeading from '../../molecules/FormPageHeading'
import Button from '../../atoms/Button'
import PropertyCard from '../../molecules/PropertyCard'
import BoostCreditsPurchasedCard from '../../molecules/BoostCreditsPurchasedCard'

import './styles.scss'
import Link from '../../atoms/Link'

const Subheading = ({ currency, lastOrder }) => {
  const [totalWithVat, setTotalWithVat] = useState(0)

  useEffect(() => {
    const getBasketDetails = async () => {
      const orderTotalWithVat = await lastOrder.totalWithVat()

      setTotalWithVat(orderTotalWithVat)
    }

    getBasketDetails().catch(console.error)
  }, [])

  return (
    <p className="payment-success__subheading" data-testid="payment-success-subheading">
      Your payment of <strong>{`${currency.display}${totalWithVat.toFixed(2)}`}</strong> has been
      successfully completed and your order has been processed.
    </p>
  )
}

const PaymentSuccessPage = ({
  properties,
  lastOrder,
  newPropertyDiscount,
  landlordHasSelectedProperties,
  isFeedAccount,
}) => {
  const boostCredits = lastOrder.boostCredits()

  const landlordHasSelectedPropertiesToBoost = landlordHasSelectedProperties && boostCredits
  const orderIsForPropertyAdvertisements = lastOrder.containsAdvertisements()
  const orderIsForToLetBoards = lastOrder.containsToLetBoards()
  const numberOfToLetBoardsOrdered = lastOrder.toLetBoards().length

  const isDesktop = useMedia(['(min-width: 769px)'], [true], false)

  useEffect(() => {
    notificationService.showSuccessNotification('', 'Your payment has been successful.')
  }, [])

  const renderProperties = () => {
    if (properties.length === 0) return null

    const propertyModel = new Property(properties[0])

    return (
      <>
        <Heading className="payment-success__property-listings-heading" level={2}>
          {properties.length > 1 ? 'Property listings' : 'Property listing'}
        </Heading>

        {propertyModel.status === propertyAdvertisementStatus.currentlyAdvertised ? (
          <>
            <p className="payment-success__property-listings-subheading">
              {properties.length > 1 ? 'These properties are' : 'This property is'} being currently
              advertised on the website.
            </p>
            <Pill className="payment-success__property-listings-pill">
              Advertised until {propertyModel.expiryDateWithYear()}
            </Pill>
          </>
        ) : (
          <p className="payment-success__property-listings-subheading payment-success__property-listings-subheading--advertised-in-the-future">
            {properties.length > 1 ? 'These properties' : 'This property'} will be advertised on the
            website from <strong>{propertyModel.startDateWithFullMonth()}</strong>.
          </p>
        )}

        {properties.map(property => (
          <PropertyCard
            className="payment-success__property-card"
            property={property}
            openLinkInNewTab
            withBorder
            key={property.id}
            data-propertyid={property.id}
            data-propertypath={property.path}
          />
        ))}

        <Link className="payment-success__fused-promo__link" to="/landlord-admin/bills-optional">
          <div className="payment-success__fused-promo">
            <p className="payment-success__fused-promo__text">
              Advertise your properties with a zero risk <strong>bills optional</strong> package.
            </p>
            <p className="payment-success__fused-promo__link-text">Find out more</p>
          </div>
        </Link>
      </>
    )
  }

  const renderLeftColumnForPropertyAdvertisements = () => {
    return (
      <>
        <Notifications className="payment-success__notification" />
        <div className="payment-success__container" data-testid="payment-success">
          <FormPageHeading
            className="payment-success"
            heading="Thank you"
            subheadingComponent={<Subheading currency={GBP} lastOrder={lastOrder} />}
          />
          <Button
            className="payment-success__properties-button"
            to="/landlord-admin/properties"
            secondary
            data-testid="payment-success-properties-button"
          >
            {orderIsForPropertyAdvertisements ? 'Go to Properties' : 'Back to Properties'}
          </Button>

          {orderIsForToLetBoards && (
            <div className="payment-success__to-let-boards-card">
              <Heading className="payment-success__to-let-boards-heading" level={2}>
                Processing your free ‘To Let’{' '}
                {numberOfToLetBoardsOrdered === 1 ? 'board' : 'boards'}
              </Heading>
              <p className="payment-success__to-let-boards-text">
                <strong>We’ll be in touch shortly to confirm your order.</strong> Boards usually
                take 3-5 working days to be erected.
              </p>
            </div>
          )}

          {!orderIsForToLetBoards && <hr className="payment-success__divider" />}

          {newPropertyDiscount.eligible && (
            <NewPropertyDiscountAdvertisement
              className="payment-success__new-property-discount"
              expires={newPropertyDiscount.expires}
            />
          )}

          {!isDesktop ? renderProperties() : null}
        </div>
      </>
    )
  }

  const renderLeftColumnForBoostCredits = () => {
    const baseLink = isFeedAccount ? '/landlord-admin/feeds' : '/landlord-admin'

    return (
      <>
        <Notifications className="payment-success__notification" />
        <div className="payment-success__container" data-testid="payment-success">
          <FormPageHeading
            className="payment-success"
            heading="Thank you"
            subheadingComponent={<Subheading currency={GBP} lastOrder={lastOrder} />}
          />
          <Button
            className="payment-success__properties-button"
            to={
              landlordHasSelectedPropertiesToBoost
                ? `${baseLink}/boost-properties`
                : `${baseLink}/properties`
            }
            secondary
            data-testid="payment-success-properties-button"
          >
            {landlordHasSelectedPropertiesToBoost ? 'Boost your properties' : 'Go to Properties'}
          </Button>

          {boostCredits && (
            <BoostCreditsPurchasedCard
              className="payment-success__boosts-card"
              boostCount={boostCredits.quantity}
              isFeedAccount={isFeedAccount}
            />
          )}

          {newPropertyDiscount.eligible && (
            <NewPropertyDiscountAdvertisement
              className="payment-success__new-property-discount"
              expires={newPropertyDiscount.expires}
            />
          )}
        </div>
      </>
    )
  }

  const renderRightColumnForPropertyAdvertisements = () => {
    if (isDesktop) {
      return renderProperties()
    }

    return null
  }

  return (
    <TwoColumnPageTemplate
      header={<Header />}
      title="Payments | AFS"
      className={classNames('payment-success-page', {
        'payment-success-page--with-new-property-discount': newPropertyDiscount.eligible,
      })}
      leftColumn={
        orderIsForPropertyAdvertisements
          ? renderLeftColumnForPropertyAdvertisements()
          : renderLeftColumnForBoostCredits()
      }
      rightColumn={
        orderIsForPropertyAdvertisements ? renderRightColumnForPropertyAdvertisements() : null
      }
    />
  )
}

Subheading.propTypes = {
  currency: PropTypes.object,
  lastOrder: PropTypes.object,
}

PaymentSuccessPage.propTypes = {
  lastOrder: PropTypes.object.isRequired,
  newPropertyDiscount: PropTypes.shape({
    eligible: PropTypes.bool.isRequired,
    expires: PropTypes.string.isRequired,
  }).isRequired,
  properties: PropTypes.arrayOf(PropTypes.object),
  landlordHasSelectedProperties: PropTypes.bool.isRequired,
  isFeedAccount: PropTypes.bool,
}

export default PaymentSuccessPage
