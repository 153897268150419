import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import xss from 'xss'
import axios from 'axios'

import './styles.scss'

import { config } from '../../../config'
import { notificationService } from '../../../container'

import PageTemplate from '../../templates/PageTemplate'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import VerificationLinkExpired from '../../molecules/VerificationLinkExpired'
import GeneralError from '../../molecules/GeneralError'

const parseQueryString = queryString => {
  const query = {}
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
  pairs.forEach(pair => {
    const splitPair = pair.split('=')
    const queryPropertyName = decodeURIComponent(splitPair[0])
    query[queryPropertyName] = xss(decodeURIComponent(splitPair[1] || ''))
  })
  return query
}

const VerifyEmailPage = ({ ...props }) => {
  const { history } = props
  const { code, id } = parseQueryString(props.location.search)

  const [displayVerificationLinkExpired, setDisplayVerificationLinkExpired] = useState(false)
  const [displayError, setDisplayError] = useState(false)

  useEffect(() => {
    axios
      .post(`${config.apiBaseUrl}/accounts/verify-email`, {
        confirmationCode: code,
        id,
      })
      .then(() => {
        notificationService.addDeferredSuccessNotification(
          'Success!',
          'Your email is verified. You can now login.'
        )
        history.push('/landlord-admin/login')
      })
      .catch(error => {
        const { code } = error.response.data
        if (code === 'AFS4003') {
          notificationService.showWarningNotification(
            'Oops!',
            'Your verification link has expired.'
          )
          setDisplayVerificationLinkExpired(true)
        }

        if (code === 'AFS4004') {
          notificationService.addDeferredWarningNotification(
            'Oops!',
            'Your email address has already been verified. You can login.'
          )
          history.push('/landlord-admin/login')
        } else {
          notificationService.showErrorNotification('Oops!', "We've encountered an error.")
          setDisplayError(true)
        }
      })
  })

  return (
    <PageTemplate header={<Header />} footer={<Footer />} title="Email Verification | AFS">
      {displayVerificationLinkExpired && (
        <VerificationLinkExpired className="verify-email-page__content" />
      )}
      {displayError && <GeneralError className="verify-email-page__content" />}
      {!displayError && !displayVerificationLinkExpired && (
        <p className="verify-email-page__text">Verifying your email...</p>
      )}
    </PageTemplate>
  )
}

VerifyEmailPage.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.any,
}

export default withRouter(VerifyEmailPage)
