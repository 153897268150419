import React from 'react'
import PropTypes from 'prop-types'
import FieldMultiSelect from '../molecules/FieldMultiSelect'

const FieldMultiSelectAdapter = ({ input: { onChange, value }, options, ...props }) => {
  return (
    <FieldMultiSelect
      options={options}
      selectedOptions={value}
      onSelectOption={onChange}
      {...props}
    />
  )
}

FieldMultiSelectAdapter.propTypes = {
  input: PropTypes.object,
  options: PropTypes.array,
}

export default FieldMultiSelectAdapter
