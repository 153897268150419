import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Money from '../../atoms/Money'

import BillsOptional from './svgs/bills-optional.svg'
import AllBills from './svgs/all-bills.svg'
import SomeBills from './svgs/some-bills.svg'

import './styles.scss'

const billsComponent = billsIncluded => {
  switch (billsIncluded) {
    case 'all':
      return (
        <span className="property-terms__extras property-terms__extras--all-bills">
          <AllBills className="property-terms__bills-icon" />
          <p className="property-terms__bills-label">Bills included</p>
        </span>
      )
    case 'optional':
      return (
        <span className="property-terms__extras property-terms__extras--bills-optional">
          <BillsOptional className="property-terms__bills-icon" />
          <p className="property-terms__bills-label">Bills optional</p>
        </span>
      )
    case 'some':
      return (
        <span className="property-terms__extras property-terms__extras--some-bills">
          <SomeBills className="property-terms__bills-icon" />
          <p className="property-terms__bills-label">Some bills</p>
        </span>
      )
    case 'none':
    default:
      return (
        <span className="property-terms__extras property-terms__extras--no-bills">
          <p className="property-terms__bills-label">No bills</p>
        </span>
      )
  }
}

const PropertyTermsCard = ({ className, terms, roundedUp, withBorder, ...props }) => {
  const componentClasses = classNames(
    'property-terms',
    { 'property-terms--with-border': withBorder },
    className
  )
  const { billsIncluded, rentPpw } = terms

  return (
    <div className={componentClasses} {...props}>
      <span className="property-terms__costs">
        <span className="property-terms__from">From</span>
        <span className="property-terms__price">
          <Money value={rentPpw} roundedUp={roundedUp} />
        </span>
        <span className="property-terms__duration">pp/pw</span>
      </span>
      {billsComponent(billsIncluded)}
    </div>
  )
}

PropertyTermsCard.propTypes = {
  className: PropTypes.string,
  terms: PropTypes.shape({
    billsIncluded: PropTypes.string.isRequired,
    rentPpw: PropTypes.shape({
      value: PropTypes.number.isRequired,
      currency: PropTypes.string,
    }),
  }),
  roundedUp: PropTypes.bool,
  withBorder: PropTypes.bool,
}

export default PropertyTermsCard
