import { performApiGet, performLongApiGet } from './requests'
import formatDateForApi from './../utils/formatDateForApi'

const formatDate = (originalDate) => formatDateForApi(originalDate)

export const getEnquiries = async (dateRange, token) => {
  const { startDate, endDate } = dateRange
  try {
    const response = await performApiGet(
      `/performance/enquiries?startDate=${formatDate(startDate)}&endDate=${formatDate(endDate)}`,
      token
    )

    return {
      success: response.status === 200,
      enquiries: response.data,
    }
  } catch (error) {
    return {
      success: false,
    }
  }
}

export const getImpressions = async (dateRange, token) => {
  const { startDate, endDate } = dateRange
  try {
    const response = await performLongApiGet(
      `/performance/impressions?startDate=${formatDate(startDate)}&endDate=${formatDate(endDate)}`,
      token
    )

    return {
      success: response.status === 200,
      impressions: response.data,
    }
  } catch (error) {
    return {
      success: false,
    }
  }
}

export const getFeedImpressions = async (dateRange, token) => {
  const { startDate, endDate } = dateRange
  try {
    const response = await performLongApiGet(
      `/feeds/performance/impressions?startDate=${formatDate(startDate)}&endDate=${formatDate(
        endDate
      )}`,
      token
    )

    return {
      success: response.status === 200,
      impressions: response.data,
    }
  } catch (error) {
    return {
      success: false,
    }
  }
}

export const getFeedEnquiries = async (dateRange, token) => {
  const { startDate, endDate } = dateRange
  try {
    const response = await performApiGet(
      `/feeds/performance/enquiries?startDate=${formatDate(startDate)}&endDate=${formatDate(
        endDate
      )}`,
      token
    )

    return {
      success: response.status === 200,
      enquiries: response.data,
    }
  } catch (error) {
    return {
      success: false,
    }
  }
}
