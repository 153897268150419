import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Link from '../../atoms/Link'

import editCard from './editCard.module.scss'

const isTruthy = (value) => !!value

const LinkOrDiv = ({ className, to, children, showLink, ...props }) =>
  showLink ? (
    <Link className={className} to={to} {...props}>
      {children}
    </Link>
  ) : (
    <div className={className} {...props}>
      {children}
    </div>
  )

const EditCard = ({
  className,
  title,
  children,
  link,
  linkPosition = 'middle',
  disabled,
  ...props
}) => {
  const showLink = isTruthy(link && !disabled)

  const cardClasses = classNames(
    editCard.card,
    {
      [editCard.alignMiddle]: linkPosition === 'middle',
      [editCard.withLink]: showLink,
      [editCard.cantEdit]: disabled,
    },
    className
  )

  const headerClasses = classNames(editCard.header, {
    [editCard.alignMiddle]: linkPosition === 'top',
  })

  return (
    <LinkOrDiv
      className={cardClasses}
      to={link}
      showLink={showLink}
      data-testid="edit-card"
      {...props}
    >
      {linkPosition === 'middle' ? (
        <>
          <div className={editCard.content}>
            <strong>{title}</strong>
            <div className={editCard.children} data-testid="edit-card-children">
              {children}
            </div>
          </div>
          {showLink && <span className={editCard.fauxLink}>Change</span>}
        </>
      ) : (
        <>
          <div className={headerClasses}>
            <strong>{title}</strong>
            {showLink && <span className={editCard.fauxLink}>Change</span>}
          </div>
          <div className={editCard.children} data-testid="edit-card-children">
            {children}
          </div>
        </>
      )}
    </LinkOrDiv>
  )
}

LinkOrDiv.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.any.isRequired,
  showLink: PropTypes.bool.isRequired,
}

EditCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  link: PropTypes.string,
  linkPosition: PropTypes.oneOf(['top', 'middle']),
  disabled: PropTypes.bool,
}

export default EditCard
