import React from 'react'
import PropTypes from 'prop-types'

import AnnounceKit from 'announcekit-react'
import ManagePropertiesPageTemplate from '../../templates/ManagePropertiesPageTemplate'
import ManagePropertiesHero from '../../atoms/ManagePropertiesHero'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Heading from '../../atoms/Heading'
import Link from '../../atoms/Link'
import AccountCardGrid from '../../organisms/AccountCardGrid'
import Banner from '../../molecules/Banner'
import Button from '../../atoms/Button'
import OverlayWithLaunchButton from '../../molecules/OverlayWithLaunchButton'

import { useAuthContext } from '../../../authContext'

import EditAccountIcon from '../../../svgs/icons/edit-account.svg'
import PropertiesIcon from '../../../svgs/icons/feeds-properties.svg'
import EnquiriesIcon from '../../../svgs/icons/feeds-enquiries.svg'
import LettingAgentIcon from '../../../svgs/icons/feeds-letting-agent.svg'
import PriceIcon from '../../../svgs/icons/feeds-price.svg'

import hero from './modules/hero.module.scss'
import home from './modules/home.module.scss'

import TreePlantingMessage from '../../atoms/TreePlantingMessage'

const HomePage = ({
  name,
  accountHasAdvertised,
  isLettingAgent,
  hasBillsOptionalProperties,
  billsOptionalSettings,
}) => {
  const {
    user: {
      user: { sub },
    },
    accountType,
  } = useAuthContext()

  const renderHero = () => {
    return (
      <ManagePropertiesHero hasPatternBackgroundOnMobile paddingType="large">
        <div className={hero.row}>
          <div className={hero.details}>
            <Heading className={hero.heading} data-testid="home-page-heading">
              {name && (
                <>
                  <span className={hero.welcome}>Welcome</span>
                  <span>{` ${name}`}</span>
                </>
              )}
            </Heading>
            <Link
              className={hero.editAccount}
              to="/landlord-admin/account"
              data-testid="edit-account-link"
            >
              <EditAccountIcon className={hero.icon} />
              <span>Edit my account</span>
            </Link>
          </div>
        </div>
      </ManagePropertiesHero>
    )
  }

  return (
    <ManagePropertiesPageTemplate
      title="Dashboard | AFS"
      header={<Header />}
      footer={<Footer dark />}
      hero={renderHero()}
    >
      <div className={home.contentWrapper}>
        <div className={`${home.notifications} hide-in-percy`} data-testid="announce-kit">
          <AnnounceKit
            widget="https://announcekit.co/widgets/v2/38fX5S"
            user={{
              id: sub,
            }}
            data={{
              account_type: accountType,
            }}
          >
            <span className={home.notificationsLabel}>{`Latest updates`}</span>
          </AnnounceKit>
        </div>
        <AccountCardGrid
          className={home.accountCardGrid}
          accountHasAdvertised={accountHasAdvertised}
          hasBillsOptionalProperties={hasBillsOptionalProperties}
        />
        {isLettingAgent && (
          <Banner className={home.feedsBanner} data-testid="feeds-banner">
            <Heading className={home.feedsHeading} level={4}>
              Automated Feeds Service
            </Heading>
            <p className={home.feedsText}>
              Did you know that we have an automated direct feed service? This is great for letting
              agents with medium or large property portfolios who are looking for an automated way
              to add, remove, and manage their properties.
            </p>
            <OverlayWithLaunchButton
              id="automated-feeds-service"
              launchButton={({ openOverlay }) => (
                <Button onClick={openOverlay} teal>
                  Find out more
                </Button>
              )}
              heading="Automated Feeds Service"
            >
              {() => (
                <>
                  <ul className={home.feedsList}>
                    <li className={home.feedsListItem}>
                      <PropertiesIcon className={home.feedsIcon} />
                      <span className={home.listItemText}>
                        Automatically add and remove properties
                      </span>
                    </li>
                    <li className={home.feedsListItem}>
                      <EnquiriesIcon className={home.feedsIcon} />
                      <span className={home.listItemText}>
                        Receive enquiries and tenant information
                      </span>
                    </li>
                    <li className={home.feedsListItem}>
                      <LettingAgentIcon className={home.feedsIcon} />
                      <span className={home.listItemText}>
                        Appear as Letting Agent with your company information
                      </span>
                    </li>
                    <li className={home.feedsListItem}>
                      <PriceIcon className={home.feedsIcon} />
                      <span className={home.listItemText}>
                        Prices from £150/month for unlimited properties
                      </span>
                    </li>
                  </ul>
                  <div className={home.feedsContactCard}>
                    <p className={home.feedsContactParagraph}>
                      If you’d like to switch to an automated feed account, please give us a call
                      on:{' '}
                      <span className={home.feedsContactNumber}>
                        <Link href="tel:+448000789659">0800 078 9659</Link>
                      </span>
                    </p>
                    <p className={home.feedsContactParagraph}>
                      <strong>Our normal office hours are:</strong>
                      <span className={home.feedsOfficeHoursRow}>Monday to Friday</span>
                      <span className={home.feedsOfficeHoursRow}>9am - 5.30pm</span>
                    </p>
                  </div>
                </>
              )}
            </OverlayWithLaunchButton>
          </Banner>
        )}
        <div className={home.treePlantingMessage}>
          <TreePlantingMessage />
        </div>
      </div>
    </ManagePropertiesPageTemplate>
  )
}

HomePage.propTypes = {
  name: PropTypes.string,
  accountHasAdvertised: PropTypes.bool,
  isLettingAgent: PropTypes.bool.isRequired,
  hasBillsOptionalProperties: PropTypes.bool.isRequired,
}

export default HomePage
