import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Card from '../../atoms/Card'
import Heading from '../../atoms/Heading'
import Button from '../../atoms/Button'

import boost from './boost.module.scss'

const PropertyDetailsBoost = ({ className, property, landlordBoostCredits, openOverlay }) => {
  const creditCountText = classNames(boost.text, boost.creditCount)

  const creditsRemaining = credits => {
    if (credits === 1) {
      return ` ${credits} credit `
    }
    return ` ${credits} credits `
  }

  return (
    <Card className={className} noBorder morePadding tightPadding data-testid="boost-card">
      <Heading className={boost.heading} level={5} data-testid="boost-heading">
        Boost to the top
      </Heading>
      <p className={boost.text}>
        Improve the visibility of your listing by boosting it to the top of the results pages.
      </p>
      <div className={boost.buttonWrapper}>
        <Button
          className={boost.button}
          onClick={() => openOverlay(property.id)}
          yellow
          data-testid="boost-button"
        >
          Boost listing
        </Button>
        {landlordBoostCredits > 0 && (
          <p className={creditCountText}>
            You have
            <strong className={boost.strong} data-testid="boost-credits-remaining">
              {creditsRemaining(landlordBoostCredits)}
            </strong>
            left.
          </p>
        )}
      </div>
    </Card>
  )
}

PropertyDetailsBoost.propTypes = {
  className: PropTypes.string,
  landlordBoostCredits: PropTypes.number.isRequired,
  openOverlay: PropTypes.func.isRequired,
  property: PropTypes.object.isRequired,
}

export default PropertyDetailsBoost
