function getConfig() {
  const developmentClientConfig = {
    mainSiteBaseUrl: 'https://localhost:5001/',
    baseUrl: 'https://localhost:5001/landlord-admin/',
    apiBaseUrl: 'https://localhost:5001/landlord-admin/api',
    intercomID: 'ig4mre9s',
    imgApiUrl: 'https://accommodationforstudents.com/cdn-cgi/image/f=auto,q=85,',
    placeholderImageSrc: 'https://afs-assets-public.s3.eu-west-2.amazonaws.com/no-photos.png',
    cookieDomain: 'localhost',
    cognitoUserPoolId: 'eu-west-2_iT2K6VFAg',
    cognitoUserPoolClientId: '4tpk4ig156nefh2l9f42kecmes',
    propertyImagesS3Bucket: 'afs-property-images',
    assetsPublicS3Bucket: 'afs-assets-public',
    identityPoolId: 'eu-west-2:700b0150-64b5-4284-9136-c70ca40885f7',
    stripeApiKey:
      'pk_test_51EDxACKmY1jRfzRkDTVlaTnFQNmsChBQYOuaeTC1waWEhiUzqSfisLGfCUvRVuiAJr8ipxXqHtQpHpGZwnMUlnN200S2dNvWz2',
    disableBrowserBanner: false,
    appInsightsEnabled: false,
    showAccountSettingsNotifications: true,
    showPerformanceFilters: true,
    showEditVirtualTour: true,
    intercomActive: false,
    datadogEnv: 'local',
    environment: 'local',
  }

  const developmentClientConfigForIE = {
    ...developmentClientConfig,
    mainSiteBaseUrl: 'https://afs.local:5001/',
    baseUrl: 'https://afs.local:5001/landlord-admin/',
    apiBaseUrl: 'https://afs.local:5001/landlord-admin/api',
    cookieDomain: 'afs.local',
  }

  const acceptanceTestClientConfig = {
    ...developmentClientConfig,
    mainSiteBaseUrl: 'https://www-test.accommodationforstudents.com/',
    apiBaseUrl:
      'http://internal-landlord-admin-test-loadbalancer-1333946318.eu-west-2.elb.amazonaws.com/landlord-admin/api',
    cookieDomain:
      'internal-landlord-admin-test-loadbalancer-1333946318.eu-west-2.elb.amazonaws.com',
    cognitoUserPoolId: 'eu-west-2_9yezYOQwT',
    cognitoUserPoolClientId: '3nhp47ujd7gedvu3h35h7lfk5r',
    propertyImagesS3Bucket: 'afs-property-images-test',
    identityPoolId: 'eu-west-2:e70597e2-7c63-4f4a-86af-67984fa4281b',
    disableBrowserBanner: true,
    appInsightsEnabled: false,
    appInsightsInstrumentationKey: '9fce43df-d7fb-424f-8396-5fe15a10d3b5',
  }

  const testClientConfig = {
    ...acceptanceTestClientConfig,
    mainSiteBaseUrl: 'https://www-test.accommodationforstudents.com/',
    baseUrl: 'https://www-test.accommodationforstudents.com/landlord-admin/',
    apiBaseUrl: 'https://www-test.accommodationforstudents.com/landlord-admin/api',
    cookieDomain: 'www-test.accommodationforstudents.com',
    disableBrowserBanner: false,
    datadogEnv: 'test',
    environment: 'test',
    intercomActive: true,
  }

  const prodClientConfig = {
    mainSiteBaseUrl: 'https://www.accommodationforstudents.com/',
    baseUrl: 'https://www.accommodationforstudents.com/landlord-admin/',
    apiBaseUrl: 'https://www.accommodationforstudents.com/landlord-admin/api',
    intercomID: 'ttyjrwql',
    imgApiUrl: 'https://accommodationforstudents.com/cdn-cgi/image/f=auto,q=85,',
    placeholderImageSrc: 'https://afs-assets-public.s3.eu-west-2.amazonaws.com/no-photos.png',
    cookieDomain: 'accommodationforstudents.com',
    cognitoUserPoolId: 'eu-west-2_f2t6A6QJW',
    cognitoUserPoolClientId: '337jtsua973oo2ake8kujmiqdo',
    propertyImagesS3Bucket: 'afs-property-images-prod',
    assetsPublicS3Bucket: 'afs-assets-public',
    identityPoolId: 'eu-west-2:33bcafe5-6918-4296-8958-35e3bc65cbd1',
    stripeApiKey: 'pk_live_kcfkgXpynZsEy19kylyKb0AD',
    disableBrowserBanner: false,
    appInsightsEnabled: true,
    appInsightsInstrumentationKey: '8a573660-eace-4b96-adbb-2d5b2e91fd1d',
    showAccountSettingsNotifications: true,
    showPerformanceFilters: true,
    showEditVirtualTour: true,
    intercomActive: true,
    datadogEnv: 'prod',
    environment: 'production',
  }

  const windowExists = typeof window !== 'undefined'

  const runningDevelopmentOnClient = windowExists && window.location.hostname === 'localhost'

  const runningDevelopmentOnClientInIE = windowExists && window.location.hostname === 'afs.local'

  const runningAcceptanceTestsOnClient =
    windowExists && window.location.hostname.includes('internal-landlord-admin-test-loadbalancer')

  const runningTestOnClient = windowExists && window.location.hostname.includes('www-test')

  const runningProdOnClient =
    windowExists && window.location.hostname.includes('www.accommodationforstudents.com')

  if (runningDevelopmentOnClient) return developmentClientConfig
  if (runningDevelopmentOnClientInIE) return developmentClientConfigForIE

  if (runningTestOnClient) return testClientConfig

  if (runningAcceptanceTestsOnClient) return acceptanceTestClientConfig

  if (runningProdOnClient) return prodClientConfig

  return developmentClientConfig
}

export const config = getConfig()
