import React from 'react'
import { Field } from 'react-final-form'

import FieldMultiSelectAdapter from '../../adapters/FieldMultiSelectAdapter'

const bills = ['Water', 'Electricity', 'Gas', 'Internet']

const BillsPage = () => {
  return (
    <Field name="bills" component={FieldMultiSelectAdapter}>
      {props => (
        <FieldMultiSelectAdapter
          legend="Choose which bills are included in the rent"
          hideLegend
          options={bills}
          {...props}
        />
      )}
    </Field>
  )
}

export default BillsPage
