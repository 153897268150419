import React from 'react'

import LoginRegisterPageTemplate from '../../templates/LoginRegisterPageTemplate'
import LoginCard from '../../molecules/LoginCard'

const RegisterPage = () => (
  <LoginRegisterPageTemplate title="Register | AFS">
    <LoginCard className="login-register__login-card" pageType="register" />
  </LoginRegisterPageTemplate>
)

export default RegisterPage
