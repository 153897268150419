import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import FormPageHeading from '../../molecules/FormPageHeading'
import Notifications from '../../organisms/Notifications'
import TwoColumnPageTemplate from '../../templates/TwoColumnPageTemplate'
import Header from '../../organisms/Header'
import MobileContextMenu from '../../organisms/MobileContextMenu'
import SelectedPropertiesInBasket from '../../organisms/SelectedPropertiesInBasket'
import FieldPaymentMethodGroup from '../../molecules/FieldPaymentMethodGroup'
import Button from '../../atoms/Button'

import styles from './paymentMethodPage.module.scss'

const PaymentMethodPage = ({ selectedProperties, deselectProperty, advertiseCredits }) => {
  const numberOfSelectedProperties = selectedProperties.length
  const atLeastOneSelected = numberOfSelectedProperties > 0
  const moreThanOneSelected = numberOfSelectedProperties > 1
  const notEnoughCredits =
    !advertiseCredits.unlimited && numberOfSelectedProperties > advertiseCredits.amount

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('advertiseCredits')

  useEffect(() => {
    if (notEnoughCredits) {
      setSelectedPaymentMethod('cardPayment')
    }
  }, [selectedProperties])

  const buttonLink =
    selectedPaymentMethod === 'advertiseCredits'
      ? '/landlord-admin/use-advertise-credits'
      : '/landlord-admin/advertisement-options'

  const buttonText =
    selectedPaymentMethod === 'advertiseCredits' ? 'Use advertise credits' : 'Use card payment'

  const renderLeftColumn = () => {
    return (
      <>
        <FormPageHeading
          className={styles.heading}
          heading="Payment method"
          subheading="How would you like to pay? You can pay by card, or use one/some of your advertise credits."
          data-testid="payment-method-heading"
        />
        <Notifications className={styles.notifications} />
        <FieldPaymentMethodGroup
          name="paymentMethod"
          selectedOption={selectedPaymentMethod}
          handleSelectOption={setSelectedPaymentMethod}
          advertiseCredits={advertiseCredits}
          notEnoughCredits={notEnoughCredits}
        />
        <div className={styles.component}>
          <div className={styles.inner}>
            <Button
              className={styles.button}
              to={buttonLink}
              primary
              data-testid="payment-method-button"
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </>
    )
  }

  return (
    <TwoColumnPageTemplate
      header={<Header />}
      className={styles.page}
      title="Payment Method | AFS"
      contextMenu={
        atLeastOneSelected && (
          <MobileContextMenu
            className={styles.mobileContextMenu}
            overview={
              moreThanOneSelected
                ? `${numberOfSelectedProperties} properties selected`
                : '1 property selected'
            }
          >
            <SelectedPropertiesInBasket
              selectedProperties={selectedProperties}
              deselectProperty={deselectProperty}
            />
          </MobileContextMenu>
        )
      }
      leftColumn={renderLeftColumn()}
      rightColumn={
        <SelectedPropertiesInBasket
          heading={`Selecting ${moreThanOneSelected ? 'adverts' : 'advert'} for`}
          selectedProperties={selectedProperties}
          deselectProperty={deselectProperty}
        />
      }
    />
  )
}

PaymentMethodPage.propTypes = {
  selectedProperties: PropTypes.array.isRequired,
  deselectProperty: PropTypes.func.isRequired,
  advertiseCredits: PropTypes.object.isRequired,
}

export default PaymentMethodPage
