import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, useLocation, useHistory, useParams } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import { AuthStateProvider } from './authContext'
import { useStateContext } from './appStateContext'
import { getImpressions, getFeedImpressions } from './api/performanceApi'
import { getAccount } from './api/accountApi'
import { fromThirtyDaysAgoTo } from './services/performanceDatesService'
import FormattedDate from './models/formattedDate'
import CognitoUser from './models/cognitoUser'
import { identifyUser, setUserProperty, logVisitPageInLandlordAdmin } from './logger'
import BillsOptionalAuthSettings from './models/billsOptionalAuthSettings'

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation()
    const history = useHistory()
    const params = useParams()

    return <Component {...props} location={location} history={history} params={params} />
  }

  return ComponentWithRouterProp
}

export const onUserFirstAuthenticated = async (
  today,
  getAccount,
  getImpressions,
  getFeedImpressions,
  token,
  propertyIds
) => {
  propertyIds.deselectAll()

  const { landlord: account } = await getAccount(token)

  const from30DaysAgoToToday = fromThirtyDaysAgoTo(today)

  if (account.isFeedAccount()) {
    getFeedImpressions(from30DaysAgoToToday, token)
  } else {
    getImpressions(from30DaysAgoToToday, token)
  }

  return account
}

const PrivateRouteCompositionRoot = ({ history, ...props }) => {
  return (
    <PrivateRoute
      auth={Auth}
      useLocation={useLocation}
      history={history}
      onUserFirstAuthenticated={onUserFirstAuthenticated}
      AuthStateProvider={AuthStateProvider}
      props={props}
      useStateContext={useStateContext}
    />
  )
}

PrivateRouteCompositionRoot.propTypes = {
  props: PropTypes.object,
  history: PropTypes.object,
}

export const PrivateRoute = ({
  auth,
  useLocation,
  history,
  onUserFirstAuthenticated,
  AuthStateProvider,
  props,
  useStateContext,
}) => {
  const [state, setState] = useState({
    loaded: false,
    isAuthenticated: false,
    user: CognitoUser.default(),
    token: '',
    userInitials: '',
    accountType: '',
    hasActiveFeed: false,
    hasBillsOptionalProperties: false,
    hasAgreedBillsPackageProviderTerms: false,
    onUserFirstAuthenticatedCallbackInvoked: false,
  })

  const { propertyIds, basket } = useStateContext()

  const currentLocation = useLocation()

  const cleanUp = () =>
    history.listen(() => {
      auth
        .currentAuthenticatedUser()
        .then()
        .catch(() => {
          if (state.isAuthenticated) {
            setState((prevState) => ({
              ...prevState,
              isAuthenticated: false,
              onUserFirstAuthenticatedCallbackInvoked: false,
            }))
          }
        })
    })

  const redirectUnauthenticatedUserToLoginScreenWithRequestedPath = () => {
    const requestedPath = `${currentLocation.pathname}${currentLocation.search}`
    history.push(`/landlord-admin/login?redirect=${requestedPath}`)
  }

  const authenticate = () => {
    auth
      .currentSession()
      .then(({ idToken }) => {
        const newJwtToken = idToken.getJwtToken()
        if (state.token === newJwtToken) return

        basket.setAuthToken(newJwtToken)

        if (!state.onUserFirstAuthenticatedCallbackInvoked) {
          const today = new FormattedDate()

          onUserFirstAuthenticated(
            today,
            getAccount,
            getImpressions,
            getFeedImpressions,
            newJwtToken,
            propertyIds
          ).then((account) => {
            identifyUser(idToken.payload.sub)
            setUserProperty('Account Type', account.accountTypeString())

            setState((previousState) => {
              return {
                ...previousState,
                loaded: true,
                isAuthenticated: true,
                token: newJwtToken,
                user: new CognitoUser(idToken.payload),
                userInitials: account.initials(),
                accountType: account.accountTypeString(),
                hasActiveFeed: account.hasActivePropertyFeed(),
                billsOptionalSettings: new BillsOptionalAuthSettings(
                  account.hasAnyBillsOptionalProperties(),
                  account.hasAlreadyAgreedBillsPackageProviderTerms()
                ),
                onUserFirstAuthenticatedCallbackInvoked: true,
              }
            })
          })
        } else {
          setState((previousState) => {
            return {
              ...previousState,
              loaded: true,
              isAuthenticated: true,
              token: newJwtToken,
              user: new CognitoUser(idToken.payload),
              onUserFirstAuthenticatedCallbackInvoked: true,
            }
          })
        }
      })
      .catch(() => {
        redirectUnauthenticatedUserToLoginScreenWithRequestedPath()
      })
  }

  useEffect(() => {
    authenticate()
    return () => cleanUp()
  }, [currentLocation])

  const { component: Component, ...rest } = props

  const {
    loaded,
    isAuthenticated,
    user,
    token,
    userInitials,
    accountType,
    hasActiveFeed,
    billsOptionalSettings,
  } = state

  if (!loaded) return null

  const pagePath = currentLocation.pathname
  const query = new URLSearchParams(currentLocation.search)
  const campaignFromQuery = query.get('campaign') || ''

  if (!user.isAdmin()) {
    logVisitPageInLandlordAdmin(pagePath, campaignFromQuery, accountType)
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated ? (
          <AuthStateProvider
            state={{
              user,
              token,
              isAuthenticated,
              userInitials,
              accountType,
              hasActiveFeed,
              billsOptionalSettings,
            }}
          >
            <Component {...props} user={user} />
          </AuthStateProvider>
        ) : (
          <Redirect to="/landlord-admin/login" />
        )
      }}
    />
  )
}

PrivateRoute.propTypes = {
  props: PropTypes.object,
  auth: PropTypes.object,
  history: PropTypes.object,
  component: PropTypes.func,
  useLocation: PropTypes.func,
  onUserFirstAuthenticated: PropTypes.func,
  AuthStateProvider: PropTypes.func,
  useStateContext: PropTypes.func,
}

export default withRouter(PrivateRouteCompositionRoot)
