import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SpinnerButton from '@afs/components/SpinnerButton'
import Field from '@afs/components/Field'

import FieldOptionGroup from '../../molecules/FieldOptionGroup'
import IconText from '../../molecules/IconText'

import './styles.scss'

const EditBedroomsForm = ({ bedroom, handleSaveBedroom, isStudio, isSavingBedrooms }) => {
  const roomSizeOptions = ['Small', 'Medium', 'Large']
  const bedSizeOptions = ['Single', 'Double', 'King']
  const yesNoOptions = ['Yes', 'No']

  const [roomSize, setRoomSize] = useState({
    value: bedroom.roomSize || '',
    valid: true,
    error: '',
  })
  const [bedSize, setBedSize] = useState({
    value: bedroom.bedSize || '',
    valid: true,
    error: '',
  })
  const [enSuite, setEnSuite] = useState({
    value: isStudio ? false : bedroom.enSuite,
    valid: true,
    error: '',
  })
  const [rent, setRent] = useState({
    value: bedroom.rent.amount,
    valid: true,
    error: '',
  })
  const [deposit, setDeposit] = useState({
    value: bedroom.deposit.amount,
    valid: true,
    error: '',
  })
  const [available, setAvailable] = useState({
    value: isStudio ? true : bedroom.available,
    valid: true,
    error: '',
  })

  const [isValid, setIsValid] = useState(bedroom.valid)

  const mappedEnSuiteValue = enSuite.value ? 'Yes' : 'No'
  const enSuiteSelectedValue = typeof enSuite.value === 'undefined' ? undefined : mappedEnSuiteValue

  const mappedAvailableValue = available.value ? 'Yes' : 'No'
  const availableSelectedValue =
    typeof available.value === 'undefined' ? undefined : mappedAvailableValue

  const validateNumberValue = numberValueObject => {
    const amountNumber = parseFloat(numberValueObject.value)
    const isValid = !Number.isNaN(amountNumber) && amountNumber > 0
    return {
      ...numberValueObject,
      valid: isValid,
      error: isValid ? '' : 'Invalid amount.',
    }
  }

  const numberNotRequiredButMustBePositiveIfSet = numberValueObject => {
    const amountNumber = parseFloat(numberValueObject.value)
    if (Number.isNaN(amountNumber)) {
      return {
        value: 0,
        valid: true,
        error: '',
      }
    }
    const isValid = !Number.isNaN(amountNumber) && amountNumber >= 0
    return {
      ...numberValueObject,
      valid: isValid,
      error: isValid ? '' : 'Invalid amount.',
    }
  }

  const checkBedroomValidity = () => {
    const hasRoomSize = roomSize.value !== ''
    const hasBedSize = bedSize.value !== ''
    const hasEnsuite = typeof enSuite.value !== 'undefined'
    const hasAvailability = typeof available.value !== 'undefined'
    const hasValidRent = validateNumberValue(rent).valid
    const hasValidDeposit = numberNotRequiredButMustBePositiveIfSet(deposit).valid

    const isBedroomValid =
      hasRoomSize && hasBedSize && hasValidRent && hasValidDeposit && hasEnsuite && hasAvailability

    setIsValid(isBedroomValid)
  }

  const saveBedroom = () => {
    if (isValid) {
      handleSaveBedroom({
        ...bedroom,
        roomSize: roomSize.value,
        bedSize: bedSize.value,
        enSuite: enSuite.value,
        rent: {
          amount: parseFloat(rent.value),
          currency: 'GBP',
        },
        deposit: {
          amount: parseFloat(deposit.value),
          currency: 'GBP',
        },
        valid: true,
        available: available.value,
      })
    }
  }

  useEffect(() => {
    checkBedroomValidity()
  })

  return (
    <div className="edit-bedrooms-form__container">
      <div className="edit-bedrooms-form__bedroom-size-info">
        <FieldOptionGroup
          className="edit-bedrooms-form__option-group"
          legend={<IconText icon="roomSize" text="Room Size" />}
          name="roomSize"
          options={roomSizeOptions}
          selectedOption={roomSize.value}
          onSelectOption={size => setRoomSize({ ...roomSize, value: size })}
          invalid={!roomSize.valid}
          error={roomSize.error}
          required
        />

        <FieldOptionGroup
          className="edit-bedrooms-form__option-group"
          legend={<IconText icon="bedSize" text="Bed Size" />}
          name="bedSize"
          options={bedSizeOptions}
          selectedOption={bedSize.value}
          onSelectOption={size => setBedSize({ ...bedSize, value: size })}
          invalid={!bedSize.valid}
          error={bedSize.error}
        />

        {!isStudio && (
          <FieldOptionGroup
            className="edit-bedrooms-form__option-group"
            legend={<IconText icon="enSuite" text="En-suite" />}
            name="enSuite"
            options={yesNoOptions}
            selectedOption={enSuiteSelectedValue}
            onSelectOption={newValue => setEnSuite({ ...enSuite, value: newValue === 'Yes' })}
            invalid={!enSuite.valid}
            error={enSuite.error}
          />
        )}
      </div>

      <div className="edit-bedrooms-form__bedrooms-money-info">
        <Field
          label="Rent per week"
          className="edit-bedrooms-form__money-field"
          name="rent"
          placeholder="0"
          type="number"
          min={0}
          value={rent.value || ''} // prevents uncontrolled to controlled component error
          onChange={event => {
            const newRent = validateNumberValue({ ...rent, value: event.target.value })
            setRent(newRent)
          }}
          data-testid="edit-bedrooms-rent"
          invalid={!rent.valid}
          error={rent.error}
          pattern="\d*"
        />
        <Field
          label="Deposit"
          className="edit-bedrooms-form__money-field"
          name="deposit"
          type="number"
          placeholder="0"
          value={deposit.value || ''}
          onChange={event => {
            const newDeposit = numberNotRequiredButMustBePositiveIfSet({
              ...deposit,
              value: event.target.value,
            })
            setDeposit(newDeposit)
          }}
          data-testid="edit-bedrooms-deposit"
          invalid={!deposit.valid}
          error={deposit.error}
          pattern="\d*"
          tooltip="Deposit amount for this room."
        />
      </div>

      {!isStudio && (
        <FieldOptionGroup
          legend="Is it available?"
          label="Is it available?"
          className="edit-bedrooms-form__bedrooms-availability"
          name="available"
          options={yesNoOptions}
          selectedOption={availableSelectedValue}
          onSelectOption={newValue => setAvailable({ ...available, value: newValue === 'Yes' })}
        />
      )}

      <SpinnerButton
        primary
        type="submit"
        className="edit-bedrooms-form__submit"
        onClick={saveBedroom}
        disabled={!isValid}
        inactive={isSavingBedrooms}
        data-testid="submit-button"
      >
        Save {isStudio ? 'studio' : 'bedroom'}
      </SpinnerButton>
    </div>
  )
}

EditBedroomsForm.propTypes = {
  bedroom: PropTypes.shape({
    index: PropTypes.number,
    valid: PropTypes.bool,
    roomSize: PropTypes.string,
    bedSize: PropTypes.string,
    enSuite: PropTypes.bool,
    available: PropTypes.bool,
    rent: PropTypes.shape({
      currency: PropTypes.string,
      amount: PropTypes.number,
    }),
    deposit: PropTypes.shape({
      currency: PropTypes.string,
      amount: PropTypes.number,
    }),
  }),
  handleSaveBedroom: PropTypes.func,
  isStudio: PropTypes.bool,
  isSavingBedrooms: PropTypes.bool,
}

export default EditBedroomsForm
