import React from 'react'
import PropTypes from 'prop-types'
import Overlay from '@afs/components/Overlay'

import Button from '../../../atoms/Button'
import Notification from '../../../molecules/Notification'

import enquiryCard from '../enquiryCard.module.scss'

const DeleteEnquiryOverlay = ({ isActive, handleCloseOverlay, handleDeleteEnquiry, showError }) => {
  return (
    <Overlay
      id="delete-enquiry"
      heading="Delete enquiry"
      isActive={isActive}
      onClose={handleCloseOverlay}
      closeLabel="Close"
      preserveScrollPositionOnClose
      dontDisplayNotifications
    >
      <p className={enquiryCard.overlayText}>Are you sure you want to delete this eqnuiry?</p>
      {showError && (
        <Notification
          className={enquiryCard.errorNotification}
          type="error"
          heading="Oops!"
          text="Something went wrong and we could not delete your enquiry. Please try again later."
        />
      )}
      <div>
        <Button
          className={enquiryCard.deleteEnquiry}
          onClick={handleDeleteEnquiry}
          deletion
          data-testid="delete-enquiry"
        >
          Delete enquiry
        </Button>
        <Button onClick={handleCloseOverlay} data-testid="cancel-button">
          Cancel
        </Button>
      </div>
    </Overlay>
  )
}

DeleteEnquiryOverlay.propTypes = {
  isActive: PropTypes.bool.isRequired,
  handleCloseOverlay: PropTypes.func.isRequired,
  handleDeleteEnquiry: PropTypes.func.isRequired,
  showError: PropTypes.bool.isRequired,
}

export default DeleteEnquiryOverlay
