import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import DateRange from '@afs/components/DateRange'

import { config } from '../../../config'

import ManagePropertiesPageTemplate from '../../templates/ManagePropertiesPageTemplate'
import ManagePropertiesHero from '../../atoms/ManagePropertiesHero'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Heading from '../../atoms/Heading'
import NotFoundAnnouncement from '../../molecules/NotFoundAnnouncement'
import IconButton from '../../molecules/IconButton'
import PerformanceOverviewCard from '../../molecules/PerformanceOverviewCard'
import PerformanceGrid from '../../organisms/PerformanceGrid'
import Notification from '../../molecules/Notification'

import NewProperty from '../../../svgs/illustrations/new-property.svg'

import hero from '../PropertiesPage/modules/hero.module.scss'
import layout from '../PropertiesPage/modules/layout.module.scss'
import noProperties from '../PropertiesPage/modules/noProperties.module.scss'
import performancePage from './performancePage.module.scss'

const PerformancePage = ({
  performance,
  getPerformance,
  date,
  initialDateRange,
  analyticsLoaderStatus,
  enquiriesLoaderStatus,
}) => {
  const renderHero = () => {
    return (
      <ManagePropertiesHero hasPatternBackgroundOnMobile pattern="green">
        <div className={hero.row}>
          <Heading className={hero.heading}>Performance</Heading>
        </div>
      </ManagePropertiesHero>
    )
  }

  const renderNoPropertiesAddedAnnouncement = () => {
    return (
      <NotFoundAnnouncement
        className={noProperties.wrapper}
        icon={NewProperty}
        heading="You haven't added any properties yet"
        subHeading="Adding a property is super easy and it only takes a minute."
        button={
          <IconButton
            className={`${hero.button} ${noProperties.button}`}
            to="/landlord-admin/add-property"
            icon={{
              icon: 'plus-circle',
              width: '24',
              height: '24',
            }}
            primary
            data-testid="no-properties-button"
          >
            Add property
          </IconButton>
        }
        data-testid="no-properties"
      />
    )
  }

  const { enquiries, impressions } = performance.overview
  const { properties } = performance
  const accountHasProperties = properties.length > 0
  const layoutClasses = classNames(layout.inner, performancePage.layout)

  return (
    <ManagePropertiesPageTemplate
      className={performancePage.page}
      title="Performance | AFS"
      header={<Header />}
      footer={<Footer dark />}
      hero={renderHero()}
    >
      <div className={layoutClasses}>
        {performance.hasIncompleteData && (
          <Notification
            className={performancePage.notification}
            type="warning"
            heading="Incomplete information."
            text="Failed to retrieve some data and the information displayed is either missing or incomplete."
          />
        )}
        {analyticsLoaderStatus.failed && (
          <Notification
            className={performancePage.notification}
            type="error"
            heading="Error."
            text="Failed to retrieve impressions. Please try again later."
          />
        )}
        {accountHasProperties ? (
          <>
            {config.showPerformanceFilters && (
              <DateRange
                className={performancePage.dateFilterButton}
                date={date}
                initialDateRange={initialDateRange}
                applyFilterCallback={(dateRange) => {
                  getPerformance(dateRange)
                }}
              />
            )}
            <section className={performancePage.section}>
              <Heading className={performancePage.heading} level={5}>
                Overview
              </Heading>
              <div className={performancePage.overviewGrid}>
                <PerformanceOverviewCard
                  className={performancePage.impressionsCard}
                  label="impressions"
                  value={impressions}
                  loading={analyticsLoaderStatus.waiting}
                  showWarning={performance.hasIncompleteData}
                  showError={analyticsLoaderStatus.failed}
                  tooltipMessage={
                    <>
                      The number of times students have{' '}
                      <span className={performancePage.tooltipMessage}>
                        viewed your property listing
                      </span>{' '}
                      pages.
                    </>
                  }
                />
                <PerformanceOverviewCard
                  className={classNames(performancePage.enquiryCard)}
                  label="enquiries"
                  value={enquiries}
                  loading={enquiriesLoaderStatus.waiting}
                  tooltipMessage={
                    <>
                      The number of times students have{' '}
                      <span className={performancePage.tooltipMessage}>sent an enquiry</span> about
                      one of your properties.
                    </>
                  }
                />
              </div>
            </section>

            <section className={performancePage.section}>
              <Heading className={performancePage.heading} level={5}>
                Properties
              </Heading>
              <PerformanceGrid
                properties={properties}
                analyticsLoaderStatus={analyticsLoaderStatus}
                enquiriesLoaderStatus={enquiriesLoaderStatus}
              />
            </section>
          </>
        ) : (
          renderNoPropertiesAddedAnnouncement()
        )}
      </div>
    </ManagePropertiesPageTemplate>
  )
}

PerformancePage.propTypes = {
  performance: PropTypes.object.isRequired,
  getPerformance: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
  initialDateRange: PropTypes.object.isRequired,
  analyticsLoaderStatus: PropTypes.object.isRequired,
  enquiriesLoaderStatus: PropTypes.object.isRequired,
}

export default PerformancePage
