import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.scss'

import Icon from '../../atoms/Icon'

const Address = ({ className, address }) => {
  const componentClasses = classNames('address', className)
  const locationClasses = classNames('address__location', `${className}-location`)

  return (
    <div className={componentClasses}>
      <p className={locationClasses}>
        <Icon icon="map-pin" width="11" height="15" viewBox="0 0 11 15" className="address__pin" />
        <span className="address__text" data-testid="property-address">
          <span>{address.streetAreaAndCity().join(', ')},</span>
          <span className="address__postcode"> {address.postcodeOnly()}</span>
        </span>
      </p>
    </div>
  )
}

Address.propTypes = {
  className: PropTypes.string,
  address: PropTypes.object.isRequired,
}

export default Address
