import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'
import { useStripe } from '@stripe/react-stripe-js'

import { notificationService } from '../../../container'

import Notifications from '../Notifications'
import PaymentSubmit from '../../molecules/PaymentSubmit'
import SavedCard from '../../molecules/SavedCard'

import savedCardsForm from './savedCardsForm.module.scss'

const SavedCardsForm = ({ savedCards, paymentSecret, onPaymentSuccess, onPaymentFailure }) => {
  const [loading, setLoading] = useState(false)

  const stripe = useStripe()

  const handleSavedCardPayment = (paymentMethodId) => {
    notificationService.clearNotifications()

    Auth.currentSession().then((data) => {
      const paymentData = {
        payment_method: paymentMethodId,
        receipt_email: data.idToken.payload.email,
      }

      stripe.handleCardPayment(paymentSecret, paymentData).then((payload) => {
        if (payload.error) {
          setLoading(false)
          onPaymentFailure(payload)
        } else {
          onPaymentSuccess({
            paidWith: 'Saved Card',
            savedNewCard: false,
          })
        }
      })
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setLoading(true)

    const selectedCard = Array.from(event.target.elements).filter((radio) => radio.checked)
    const paymentMethodId = selectedCard[0].value

    handleSavedCardPayment(paymentMethodId)
  }

  return (
    <>
      <Notifications className={savedCardsForm.notifications} />
      <form onSubmit={handleSubmit} data-testid="saved-cards-form">
        <fieldset className={savedCardsForm.fieldset}>
          <legend className={savedCardsForm.legend}>Choose a saved card</legend>
          {savedCards.map((card, i) => {
            const checked = i === 0
            return (
              <div key={card.paymentMethodId} className={savedCardsForm.row}>
                <SavedCard card={card} isSelectable checked={checked} />
              </div>
            )
          })}
        </fieldset>
        <PaymentSubmit loading={loading} />
      </form>
    </>
  )
}

SavedCardsForm.propTypes = {
  savedCards: PropTypes.array.isRequired,
  paymentSecret: PropTypes.string.isRequired,
  onPaymentSuccess: PropTypes.func.isRequired,
  onPaymentFailure: PropTypes.func.isRequired,
}

export default SavedCardsForm
