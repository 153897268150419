import React from 'react'
import PropTypes from 'prop-types'
import SpinnerButton from '@afs/components/SpinnerButton'

import CreditCounter from '../../../molecules/CreditCounter'
import Button from '../../../atoms/Button'

import styles from '../boostPage.module.scss'

const BoostOverview = ({ boostCredits, boostCost, loading, onBoost }) => {
  const notEnoughBoostCredits = boostCost > boostCredits

  return (
    <>
      <CreditCounter creditType="boost" credits={boostCredits} creditCost={boostCost} />
      {notEnoughBoostCredits && (
        <div className={styles.buyCreditsAnnouncement} data-testid="buy-credits-announcement">
          <p className={styles.buyCreditsText}>You don't have enough boost credits.</p>
          <Button primary to="/landlord-admin/buy-boost-credits" data-testid="buy-credits-link">
            Buy more credits
          </Button>
        </div>
      )}
      <div className={styles.mobileButtonContainer}>
        <SpinnerButton
          className={styles.boostButton}
          primary
          inactive={loading}
          disabled={notEnoughBoostCredits}
          onClick={onBoost}
          data-testid="boost-button"
        >
          Boost to the top
        </SpinnerButton>
      </div>
    </>
  )
}

BoostOverview.propTypes = {
  boostCredits: PropTypes.number.isRequired,
  boostCost: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  onBoost: PropTypes.func.isRequired,
}

export default BoostOverview
