import React from 'react'
import PropTypes from 'prop-types'

import RegisterFormPageTemplate from '../../templates/RegisterFormPageTemplate'
import LettingAgentRegisterForm from '../../organisms/LettingAgentRegisterForm'

import styles from './styles.module.scss'

const LettingAgentRegisterPage = ({ createAccount }) => {
  return (
    <RegisterFormPageTemplate title="Create Letting Agent Account | AFS">
      <div className={styles.formWrapper}>
        <LettingAgentRegisterForm createAccount={createAccount} />
      </div>
    </RegisterFormPageTemplate>
  )
}

LettingAgentRegisterPage.propTypes = {
  createAccount: PropTypes.func.isRequired,
}

export default LettingAgentRegisterPage
