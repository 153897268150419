import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Auth } from 'aws-amplify'

import { notificationService } from '../../../container'

import FormNavigation from '../FormNavigation'
import TwoColumnPageTemplate from '../../templates/TwoColumnPageTemplate'
import Header from '../../organisms/Header'
import ProgressBar from '../../atoms/ProgressBar'

import pages from '../../../models/pages'
import Address from '../../../models/address'
import PropertyDto from '../../../models/propertyDto'

const initialFormValues = {
  address: new Address({
    flat: '',
    buildingName: '',
    buildingNumber: '',
    street: '',
    townCity: '',
    postcode: '',
    latitude: '',
    longitude: '',
  }),
  accommodationType: '',
  lettingType: '',
  bedrooms: [],
  bathrooms: 0,
  photos: {
    images: {
      items: [],
      order: [],
    },
    loading: false,
  },
  videoTourUrl: '',
  availableFrom: moment().format('DD/MM/YYYY'),
  availableUntil: '',
  amenities: [
    {
      title: 'Appliances',
      options: [],
    },
    {
      title: 'Interior',
      options: [],
    },
    {
      title: 'Exterior',
      options: [],
    },
    {
      title: 'Services',
      options: [],
    },
  ],
  bills: [],
  description: '',
  referenceNumber: '',
}

const AddPropertyFormPage = ({ history, useStateContext, addProperty, pageConfig }) => {
  const [addPropertyFormPages, setAddPropertyFormPages] = useState(pages(pageConfig))
  const { propertyIds } = useStateContext()

  useEffect(() => {
    setAddPropertyFormPages(pages(pageConfig))
    propertyIds.deselectAll()

    return () => {
      setAddPropertyFormPages()
    }
  }, [])

  const onSubmit = formState => {
    notificationService.clearNotifications()
    let publishPropertyUrl

    Auth.currentSession().then(async ({ idToken }) => {
      const { jwtToken } = idToken
      const property = PropertyDto(formState)

      const result = await addProperty(property, jwtToken)

      if (result.success) {
        publishPropertyUrl = `/landlord-admin/add-property/advertise/${result.propertyId}`
      } else {
        publishPropertyUrl = `/landlord-admin/add-property/error`
      }

      history.push(publishPropertyUrl)
    })
  }

  const form = (
    <FormNavigation
      className="add-property__form"
      initialValues={initialFormValues}
      onSubmit={onSubmit}
      pages={addPropertyFormPages}
      history={history}
    />
  )

  const progressBar = <ProgressBar pages={addPropertyFormPages} />

  return (
    <TwoColumnPageTemplate
      header={<Header />}
      progressBar={progressBar}
      title="Add a property | AFS"
      leftColumn={form}
    />
  )
}

AddPropertyFormPage.propTypes = {
  history: PropTypes.object.isRequired,
  useStateContext: PropTypes.func.isRequired,
  addProperty: PropTypes.func.isRequired,
  pageConfig: PropTypes.object.isRequired,
}

export default AddPropertyFormPage
