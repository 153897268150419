import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const pageWeights = [7, 6, 5, 4, 3, 2, 2, 2, 1, 1, 1]
const totalWeight = pageWeights.reduce((acc, val) => acc + val)

const calculateProgress = (pageList) => {
  if (pageList.length === 0) return 0

  const validPageCount = pageList.filter((page) => page.isValid === true).length

  const percentCompleted = Math.round(
    (pageWeights.slice(0, validPageCount).reduce((acc, val) => acc + val, 0) / totalWeight) * 100
  )

  return percentCompleted
}

const Filler = ({ percentage }) => {
  return <div className="filler" style={{ width: `${percentage}%` }} data-testid="filler" />
}

const ProgressBar = ({ pages }) => {
  const [progress, setProgress] = useState(0)
  const subscriberId = 'progress-bar'

  useEffect(() => {
    pages.addPageValiditySubscriber({
      id: subscriberId,
      callback: (pageList) => {
        setProgress(calculateProgress(pageList))
      },
    })

    return () => {
      pages.removeSubscriberById(subscriberId)
    }
  })

  return (
    <div
      className="progress-bar"
      role="progressbar"
      aria-valuenow={progress}
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <Filler percentage={progress} />
    </div>
  )
}

ProgressBar.propTypes = {
  pages: PropTypes.object.isRequired,
}

Filler.propTypes = {
  percentage: PropTypes.number.isRequired,
}

export default ProgressBar
