import React from 'react'
import SpinnerButton from '@afs/components/SpinnerButton'

import { updatePropertyBedrooms } from '../../../../api/propertyApi'
import bedroomApi from '../../../../api/bedroomApi'

import {
  BedroomsPage,
  bedroomsPageValidation,
  renderBedroomPageSubHeading,
} from '../../FormPages/BedroomsPage'
import SubmitButton from '../../../atoms/SubmitButton'

const bedroomsPageConfig = propertyId => ({
  id: 5,
  component: values => (
    <BedroomsPage
      propertyId={propertyId}
      propertyType={values.accommodationType}
      bedroomApi={bedroomApi}
      updatePropertyBedrooms={updatePropertyBedrooms}
    />
  ),
  route: `/landlord-admin/property/${propertyId}/edit/bedrooms`,
  validate: values => bedroomsPageValidation(values),
  isValid: false,
  headingComponent: values =>
    values.accommodationType === 'Studio' ? 'Setup studio' : 'Number of bedrooms',
  subheadingComponent: values => renderBedroomPageSubHeading(values.accommodationType),
  button: (values, loading) => {
    const totalBedrooms = values.bedrooms.length
    const totalValidBedrooms = values.bedrooms.filter(x => x.valid === true).length
    if (totalBedrooms === 0 || totalValidBedrooms !== totalBedrooms) return null
    if (loading)
      return (
        <SpinnerButton primary inactive>
          Continue
        </SpinnerButton>
      )
    return <SubmitButton data-testid="save-button">Save changes</SubmitButton>
  },
  onSubmit: async (values, token) => {
    const payload = {
      propertyId,
      bedrooms: values.bedrooms,
    }
    return updatePropertyBedrooms(payload, token)
  },
})

export default bedroomsPageConfig
