import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.scss'

const LinkButton = ({ className, children, type = 'button', ...props }) => {
  const componentClasses = classNames('link-button', className)

  return (
    <button className={componentClasses} type={type} {...props}>
      {children}
    </button>
  )
}

LinkButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

export default LinkButton
