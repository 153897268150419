import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { SuccessPill, ErrorPill } from '@afs/components/Pill'
import SpinnerButton from '@afs/components/SpinnerButton'

import bedroomAdapterMode from '../../adapters/bedroomAdapterMode'

import Button from '../../atoms/Button/index'
import LinkButton from '../../atoms/LinkButton/index'
import IconText from '../../molecules/IconText/index'

import './styles.scss'

const BedroomCard = ({
  active,
  bedroomNumber,
  bedroomFormValues,
  handleBedroomChange,
  copyPreviousBedroom,
  showEditOverlay,
  isStudio,
  saveCopiedBedroom,
  mode,
  notificationService,
}) => {
  const [bedroom, setBedroom] = useState(bedroomFormValues)
  const [isValid, setIsValid] = useState(bedroomFormValues.valid)
  const [isCopyingBedroom, setIsCopyingBedroom] = useState(false)

  const activeButInvalid = active && !isValid
  const inactiveAndInvalid = !active && !isValid

  const componentClass = classNames('bedroom-card__container', {
    'bedroom-card__container--first-card': bedroomFormValues.position === 1,
    'bedroom-card__container--with-content': !inactiveAndInvalid,
  })

  const headingClassName = classNames('bedroom-card__heading', {
    'bedroom-card__heading--inactive-invalid': inactiveAndInvalid,
  })

  const currenciesAndSymbols = {
    GBP: '£',
    EUR: '€',
  }

  const updateBedroomCardState = updatedBedroom => {
    setBedroom(updatedBedroom)
    setIsValid(true)
  }

  const editBedroom = event => {
    event.preventDefault()

    showEditOverlay(bedroom, updateBedroomCardState)
  }

  const handleCopyPrevious = async event => {
    event.preventDefault()

    if (mode === bedroomAdapterMode.ADD) {
      const copiedBedroom = copyPreviousBedroom(bedroom.position)

      setBedroom(copiedBedroom)
      setIsValid(true)
      handleBedroomChange(copiedBedroom)
    }

    if (mode === bedroomAdapterMode.EDIT) {
      const copiedBedroom = copyPreviousBedroom(bedroom.position)

      setIsCopyingBedroom(true)

      const result = await saveCopiedBedroom(copiedBedroom)

      const copiedBedroomWithIdFromApi = {
        ...copiedBedroom,
        id: result.id,
      }

      setIsCopyingBedroom(false)

      if (!result.success) {
        notificationService.showErrorNotification(
          'Sorry.',
          'Something went wrong on our system and the bedroom could not be copied.'
        )
        return
      }

      setBedroom(copiedBedroomWithIdFromApi)
      setIsValid(true)
      handleBedroomChange(copiedBedroomWithIdFromApi)
    }
  }

  const renderAvailabilityPill = availability => {
    return availability === true ? (
      <SuccessPill className="bedroom-card__available-pill" data-testid="available-bedroom-pill">
        Available
      </SuccessPill>
    ) : (
      <ErrorPill className="bedroom-card__unavailable-pill" data-testid="unavailable-bedroom-pill">
        Unavailable
      </ErrorPill>
    )
  }

  return (
    <div className={componentClass} data-testid="bedroom-card">
      <div className="bedroom-card__header">
        <strong className={headingClassName} data-testid="bedroom-card-heading">
          {isStudio ? 'Studio' : `Bedroom ${bedroomNumber}`}
        </strong>
        {isValid && (
          <LinkButton
            className="bedroom-card__edit-link"
            onClick={editBedroom}
            data-testid="bedroom-card-edit-link"
          >
            Edit {isStudio ? 'studio' : 'bedroom'}
          </LinkButton>
        )}
      </div>
      {activeButInvalid && (
        <span data-testid="bedroom-buttons">
          <Button
            dark
            onClick={editBedroom}
            className="bedroom-card__setup-button"
            data-testid="bedroom-card-setup-button"
          >
            Setup {isStudio ? 'studio' : 'bedroom'}
          </Button>
          {bedroomNumber > 1 && (
            <SpinnerButton
              secondary
              onClick={handleCopyPrevious}
              inactive={isCopyingBedroom}
              data-testid="bedroom-card-copy-button"
            >
              Copy previous
            </SpinnerButton>
          )}
        </span>
      )}
      {isValid && (
        <div className="bedroom-card__valid-container">
          <IconText className="bedroom-card__icon-text" icon="roomSize" text={bedroom.roomSize} />
          <IconText className="bedroom-card__icon-text" icon="bedSize" text={bedroom.bedSize} />
          {bedroom.enSuite && (
            <IconText className="bedroom-card__icon-text" icon="enSuite" text="En-suite" />
          )}
          <IconText
            className="bedroom-card__icon-text"
            icon="rent"
            text={`${currenciesAndSymbols[bedroom.rent.currency]}${bedroom.rent.amount} pw rent`}
          />
          <IconText
            className="bedroom-card__icon-text"
            icon="deposit"
            text={`${currenciesAndSymbols[bedroom.deposit.currency]}${
              bedroom.deposit.amount
            } deposit`}
          />
        </div>
      )}
      {isValid && renderAvailabilityPill(bedroom.available)}
    </div>
  )
}

BedroomCard.propTypes = {
  active: PropTypes.bool,
  bedroomNumber: PropTypes.number,
  bedroomFormValues: PropTypes.shape({
    position: PropTypes.number,
    valid: PropTypes.bool,
    roomSize: PropTypes.string,
    bedSize: PropTypes.string,
    enSuite: PropTypes.bool,
    available: PropTypes.bool,
    rent: PropTypes.shape({
      currency: PropTypes.string,
      amount: PropTypes.number,
    }),
    deposit: PropTypes.shape({
      currency: PropTypes.string,
      amount: PropTypes.number,
    }),
  }),
  handleBedroomChange: PropTypes.func,
  copyPreviousBedroom: PropTypes.func,
  showEditOverlay: PropTypes.func,
  isStudio: PropTypes.bool,
  saveCopiedBedroom: PropTypes.func,
  mode: PropTypes.string,
  notificationService: PropTypes.object,
}

export default BedroomCard
