import React from 'react'
import PropTypes from 'prop-types'

import Property from '../../../models/property'

import PropertyRoomCount from '../../atoms/PropertyRoomCount'
import AdStatusTag from '../../atoms/AdStatusTag'
import AvatarImage from '../../atoms/AvatarImage'

import styles from './styles.module.scss'

const PropertyCardListView = ({ property }) => {
  const propertyModel = new Property(property)

  return (
    <article className={styles.wrapper}>
      <div className={styles.leftSideInner}>
        <div className={styles.property} data-testid="property-card-list-view-property">
          <AvatarImage
            className={styles.propertyImage}
            hasPhoto={propertyModel.photos.length > 0}
            photoUrl={propertyModel.photos[0]?.src}
            alt={propertyModel.address.firstTwoLines()}
          />
          <div className={styles.propertyAddress}>
            <p className={styles.addressTitle}>{propertyModel.address.firstTwoLines()}</p>
            <p className={styles.addressSubtitle}>
              {propertyModel.address.withoutFirstTwoLines().join(', ')}
            </p>
          </div>
        </div>
        <PropertyRoomCount
          className={styles.roomCountMobile}
          count={`${propertyModel.numberOfAvailableBedrooms()}/${propertyModel.totalBedrooms()}`}
        />
        <div className={styles.rooms}>
          <PropertyRoomCount
            className={styles.roomCount}
            count={`${propertyModel.numberOfAvailableBedrooms()}/${propertyModel.totalBedrooms()}`}
          />
          <span className={styles.roomsAvailableText}>available</span>
        </div>
        <span className={styles.adStatus}>
          <AdStatusTag
            status={propertyModel.status}
            isListed={propertyModel.isListed}
            startDate={propertyModel.startDate()}
            expiryDate={propertyModel.expiryDate()}
          />
        </span>
      </div>
      <div className={styles.rightSideInner}>
        <div className={styles.price}>
          <p className={styles.priceValue}>{`${propertyModel.rentCurrencySymbol()}${
            propertyModel.averageRentPerWeek
          }`}</p>
          <p className={styles.priceText}>rent pp/pw</p>
        </div>
        <div className={styles.price}>
          <p className={styles.priceValue}>{`${propertyModel.rentCurrencySymbol()}${
            propertyModel.averageDeposit
          }`}</p>
          <p className={styles.priceText}>deposit pp</p>
        </div>
      </div>
    </article>
  )
}

PropertyCardListView.propTypes = {
  property: PropTypes.object.isRequired,
}

export default PropertyCardListView
