import { performApiPost } from './requests'

const addBedroom = async (payload, token) => {
  try {
    const response = await performApiPost('/property/bedroom', payload, token)
    return {
      success: response.status === 200,
      id: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export default {
  addBedroom,
}
