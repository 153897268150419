import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import SubmitTestimonialPage from '../pages/SubmitTestimonialPage'
import LoadingPage from '../pages/LoadingPage'
import TestimonialErrorPage from '../pages/TestimonialErrorPage'

import { getTestimonialRequestData, submitTestimonial } from '../../api/testimonialsApi'

export const SubmitTestimonialContainer = ({
  submitTestimonialPage,
  queryString,
  getTestimonialRequestData,
  submitTestimonial,
}) => {
  const [testimonialRequestData, setTestimonialRequestData] = useState({})
  const [loading, setLoading] = useState(true)
  const [serverError, setServerError] = useState(false)

  useEffect(() => {
    const getTestimonialIdFromQueryString = queryString => {
      const query = new URLSearchParams(queryString)
      const testimonialId = query.get('id')
      return testimonialId
    }

    const testimonialId = getTestimonialIdFromQueryString(queryString)

    getTestimonialRequestData(testimonialId).then(response => {
      if (response.success) {
        setTestimonialRequestData(response.testimonialRequestData)
        setLoading(false)
      } else {
        setLoading(false)
        setServerError(true)
      }
    })
  }, [])

  if (loading) return <LoadingPage />

  if (serverError)
    return (
      <TestimonialErrorPage
        type="testimonial does not exist"
        heading="Sorry, it looks like this testimonial request no longer exists."
      />
    )

  if (testimonialRequestData.tenantHasResponded)
    return (
      <TestimonialErrorPage
        type="already left testimonial"
        heading="Sorry, it looks like you've already left a testimonial for this property."
      />
    )

  return submitTestimonialPage(testimonialRequestData, submitTestimonial)
}

SubmitTestimonialContainer.propTypes = {
  submitTestimonialPage: PropTypes.func.isRequired,
  getTestimonialRequestData: PropTypes.func.isRequired,
  submitTestimonial: PropTypes.func.isRequired,
}

export const SubmitTestimonialContainerCompositionRoot = ({ history }) => {
  return (
    <SubmitTestimonialContainer
      submitTestimonialPage={(testimonialRequestData, submitTestimonial) => (
        <SubmitTestimonialPage
          testimonialRequestData={testimonialRequestData}
          submitTestimonial={submitTestimonial}
        />
      )}
      queryString={history.location.search}
      getTestimonialRequestData={getTestimonialRequestData}
      submitTestimonial={submitTestimonial}
    />
  )
}

SubmitTestimonialContainerCompositionRoot.propTypes = {
  history: PropTypes.object.isRequired,
}
