import React from 'react'
import PropTypes from 'prop-types'

import TwoColumnPageTemplate from '../../templates/TwoColumnPageTemplate'
import FormPageHeading from '../../molecules/FormPageHeading'
import Notifications from '../../organisms/Notifications'
import Header from '../../organisms/Header'
import MobileContextMenu from '../../organisms/MobileContextMenu'
import SelectedFeedPropertiesInBasket from '../../organisms/SelectedFeedPropertiesInBasket'
import BackLink from '../../molecules/BackLink'
import BoostOverview from './BoostOverview'

import styles from './boostPage.module.scss'

const MultipleFeedPropertiesBoostPage = ({
  history,
  loading,
  selectedProperties,
  boostCredits,
  onBoost,
  deselectProperty,
}) => {
  const numberOfSelectedProperties = selectedProperties.length
  const atLeastOneSelected = numberOfSelectedProperties > 0
  const moreThanOneSelected = numberOfSelectedProperties > 1

  const subheadingText = moreThanOneSelected
    ? 'Are you sure you want to boost these property listings to the top of the results pages?'
    : 'Are you sure you want to boost this property listing to the top of the results pages?'

  const renderLeftColumn = () => {
    return (
      <>
        <BackLink
          className="form-navigation__back-link"
          onClick={event => {
            event.preventDefault()
            history.goBack()
          }}
        />
        <FormPageHeading
          className={styles.heading}
          heading={moreThanOneSelected ? 'Boost listings' : 'Boost listing'}
          subheading={subheadingText}
          data-testid="boost-header"
        />
        <Notifications className={styles.notifications} />
        <BoostOverview
          boostCredits={boostCredits}
          boostCost={numberOfSelectedProperties}
          loading={loading}
          onBoost={onBoost}
        />
      </>
    )
  }

  return (
    <TwoColumnPageTemplate
      header={<Header />}
      title="Boost Listings | AFS"
      className={styles.page}
      contextMenu={
        atLeastOneSelected && (
          <MobileContextMenu
            className={styles.mobileContextMenu}
            overview={
              moreThanOneSelected
                ? `${numberOfSelectedProperties} properties selected`
                : '1 property selected'
            }
          >
            <SelectedFeedPropertiesInBasket
              selectedProperties={selectedProperties}
              deselectProperty={deselectProperty}
            />
          </MobileContextMenu>
        )
      }
      leftColumn={renderLeftColumn()}
      rightColumn={
        <SelectedFeedPropertiesInBasket
          heading={`Selected ${moreThanOneSelected ? 'properties' : 'property'}`}
          selectedProperties={selectedProperties}
          deselectProperty={deselectProperty}
        />
      }
    />
  )
}

MultipleFeedPropertiesBoostPage.propTypes = {
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedProperties: PropTypes.array.isRequired,
  boostCredits: PropTypes.number.isRequired,
  onBoost: PropTypes.func.isRequired,
  deselectProperty: PropTypes.func.isRequired,
}

export default MultipleFeedPropertiesBoostPage
