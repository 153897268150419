import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { MostPopularPill, BestValuePill } from '@afs/components/Pill'

import TickCircle from '../../../svgs/icons/tick-circle.svg'

import './styles.scss'

const AdDurationOption = ({
  name,
  numberOfMonths,
  price,
  discountedPrice,
  currency,
  selectOption,
  checked,
  hasDiscountApplied,
}) => {
  const priceToUse = hasDiscountApplied ? discountedPrice.toFixed(2) : price

  const duration = `${numberOfMonths} month${numberOfMonths > 1 ? 's' : ''}`

  const formattedTotalCost = `${currency}${priceToUse}`

  const costHasDecimals = (priceToUse / numberOfMonths) % 1 !== 0
  const formattedCostPerMonth = costHasDecimals
    ? `${currency}${(priceToUse / numberOfMonths).toFixed(2)}pm`
    : `${currency}${priceToUse / numberOfMonths}pm`

  return (
    <div className="ad-duration-group__field">
      <input
        id={numberOfMonths}
        className="ad-duration-group__input"
        name={name}
        type="radio"
        value={numberOfMonths}
        onChange={() => selectOption({ numberOfMonths, price: priceToUse })}
        checked={checked}
        data-testid="ad-duration-input"
      />
      <label
        htmlFor={numberOfMonths}
        className="ad-duration-group__label"
        data-testid="ad-duration-label"
      >
        <div className="ad-duration-group__inner">
          <TickCircle className="ad-duration-group__icon" />
          <div className="ad-duration-group__details">
            <div>
              {numberOfMonths === 3 && (
                <MostPopularPill className="ad-duration-group__pill">
                  <span className="ad-duration-group__pill-text--no-mobile">Most</span> Popular
                </MostPopularPill>
              )}
              {numberOfMonths === 12 && (
                <BestValuePill className="ad-duration-group__pill">Best Value</BestValuePill>
              )}
              <p className="ad-duration-group__duration">{duration}</p>
            </div>
            <div className="ad-duration-group__cost">
              <strong
                className={classNames('ad-duration-group__total', {
                  'ad-duration-group__total--discount': hasDiscountApplied,
                })}
              >
                {formattedTotalCost}
              </strong>
              {hasDiscountApplied ? (
                <span className="ad-duration-group__per-month--original">{`${currency}${price}`}</span>
              ) : (
                <span className="ad-duration-group__per-month">{formattedCostPerMonth}</span>
              )}
            </div>
          </div>
        </div>
      </label>
    </div>
  )
}

const FieldAdDurationGroup = ({
  name,
  adDurationOptions = [
    { numberOfMonths: 1, totalCost: 23, currency: '£' },
    { numberOfMonths: 3, totalCost: 59, currency: '£' },
  ],
  selectedOption,
  handleSelectOption,
  hasDiscountApplied,
}) => {
  return (
    <div className="ad-duration-group" data-testid="ad-duration-group">
      <p className="label ad-duration-group__title">Select advert duration</p>
      <div className="ad-duration-group__fields">
        {adDurationOptions.map((option) => {
          return (
            <AdDurationOption
              name={name}
              numberOfMonths={option.numberOfMonths}
              price={option.price}
              discountedPrice={option.discountedPrice}
              currency={option.currency}
              checked={option.numberOfMonths === selectedOption.numberOfMonths}
              selectOption={handleSelectOption}
              key={option.numberOfMonths}
              hasDiscountApplied={hasDiscountApplied}
            />
          )
        })}
      </div>
    </div>
  )
}

AdDurationOption.propTypes = {
  name: PropTypes.string,
  numberOfMonths: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  discountedPrice: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  selectOption: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  hasDiscountApplied: PropTypes.bool,
}

FieldAdDurationGroup.propTypes = {
  name: PropTypes.string,
  adDurationOptions: PropTypes.array.isRequired,
  selectedOption: PropTypes.object.isRequired,
  handleSelectOption: PropTypes.func.isRequired,
  hasDiscountApplied: PropTypes.bool,
}

export default FieldAdDurationGroup
