class ManagedAccount {
  constructor() {
    this.fullName = ''
    this.emailAddress = ''
  }

  setAccountDetails(fullName, emailAddress) {
    this.fullName = fullName
    this.emailAddress = emailAddress
  }

  fullNameString() {
    return this.fullName
  }

  emailAddressString() {
    return this.emailAddress
  }
}

export default ManagedAccount
