import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'

import { getPropertyById } from '../../api/propertyApi'

import LoadingPage from '../pages/LoadingPage'
import PublishPropertyPage from '../pages/PublishPropertyPage'

const PublishPropertyContainer = ({ match, history }) => {
  const [loading, setLoading] = useState(true)
  const [property, setProperty] = useState()
  const [displayWarningNotification, setDisplayWarningNotification] = useState()

  useEffect(() => {
    if (property === undefined) {
      const propertyId = match.params.id
      Auth.currentSession().then(data => {
        const { jwtToken } = data.idToken

        getPropertyById(propertyId, jwtToken).then(response => {
          if (response.success) {
            setProperty(response.property)

            const notificationToShow = response.property.hasCoordinates ? 'success' : 'warning'
            setDisplayWarningNotification(notificationToShow)

            setLoading(false)
          }
        })
      })
    }
  }, [property])

  if (loading) return <LoadingPage />

  return (
    <PublishPropertyPage
      history={history}
      property={property}
      displayWarningNotification={displayWarningNotification}
    />
  )
}

PublishPropertyContainer.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
}

export default PublishPropertyContainer
