import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router'

import { getSelectedUnadvertisedProperties } from '../../api/propertyApi'
import { getAccountAdvertiseCredits, getIsAccountVerified } from '../../api/accountApi'
import { useAuthContext } from '../../authContext'
import { useStateContext } from '../../appStateContext'
import { notificationService } from '../../container'

import PaymentMethodPage from '../pages/PaymentMethodPage'
import LoadingPage from '../pages/LoadingPage'
import ErrorPage from '../pages/ErrorPage'

export const PaymentMethodContainer = ({
  paymentMethodPage,
  getLandlordAdvertiseCredits,
  getProperties,
  useAuthContext,
  useStateContext,
  notificationService,
  history,
  getIsAccountVerified,
}) => {
  const [loading, setLoading] = useState(true)
  const [serverError, setServerError] = useState(false)
  const [advertiseCredits, setAdvertiseCredits] = useState({})
  const [selectedProperties, setSelectedProperties] = useState([])

  const { propertyIds } = useStateContext()
  const { token } = useAuthContext()

  const getAdvertiseCredits = () => {
    getLandlordAdvertiseCredits(token).then(advertiseCreditsResponse => {
      if (advertiseCreditsResponse.success) {
        const { advertiseCreditsData } = advertiseCreditsResponse

        if (!advertiseCreditsData.hasAdvertiseCredits) {
          history.push('/landlord-admin/advertisement-options')
          return
        }

        if (advertiseCreditsData.hasAdvertiseCredits) {
          setAdvertiseCredits(advertiseCreditsData.advertiseCredits)

          getProperties(propertyIds.selected(), token).then(response => {
            if (!response.success) {
              propertyIds.deselectAll()
              notificationService.addDeferredErrorNotification(
                'Oops!',
                'Something unexpected happened on our end. Please try again in a few minutes. If this error persists, please contact us.'
              )
              history.push('/landlord-admin/properties')
            }

            setLoading(false)

            const allPropertiesNotFound =
              response.notFound &&
              response.notFound.length > 0 &&
              response.properties.length === 0 &&
              response.alreadyAdvertisedPropertyIds.length === 0

            const somePropertiesNotFound =
              response.notFound && response.notFound.length > 0 && response.properties.length > 0

            const somePropertiesAlreadyAdvertised =
              !response.allPropertiesAreAdvertised &&
              response.alreadyAdvertisedPropertyIds &&
              response.alreadyAdvertisedPropertyIds.length > 0 &&
              response.properties.length > 0

            if (allPropertiesNotFound) {
              propertyIds.deselectAll()

              const notificationText =
                response.notFound.length > 1
                  ? 'These properties could not be found. Please try again in a few minutes. If this error persists, please contact us.'
                  : 'This property could not be found. Please try again in a few minutes. If this error persists, please contact us.'
              notificationService.addDeferredErrorNotification('Oops!', notificationText)
              history.push('/landlord-admin/properties')

              return
            }

            if (somePropertiesNotFound && somePropertiesAlreadyAdvertised) {
              const { notFound, alreadyAdvertisedPropertyIds } = response
              notFound.map(id => propertyIds.deselect(id))
              alreadyAdvertisedPropertyIds.map(id => propertyIds.deselect(id))

              const numberOfOmittedProperties =
                notFound.length + alreadyAdvertisedPropertyIds.length

              const notificationText = `${numberOfOmittedProperties} of these properties are already advertised or could not be found. They have been omitted from this transaction.`

              setSelectedProperties(response.properties)
              notificationService.showWarningNotification('', notificationText)

              return
            }

            if (somePropertiesNotFound) {
              const { notFound } = response
              notFound.map(id => propertyIds.deselect(id))

              const notificationText =
                notFound.length > 1
                  ? `${notFound.length} of these properties could not be found. They have been omitted from this transaction.`
                  : `1 of these properties could not be found. It has been omitted from this transaction.`

              setSelectedProperties(response.properties)
              notificationService.showErrorNotification('', notificationText)

              return
            }

            if (response.allPropertiesAreAdvertised) {
              propertyIds.deselectAll()

              const numberOfAlreadyAdvertisedProperties =
                response.alreadyAdvertisedPropertyIds.length
              notificationService.addDeferredWarningNotification(
                '',
                numberOfAlreadyAdvertisedProperties > 1
                  ? 'These properties are already being advertised.'
                  : 'This property is already being advertised.'
              )
              history.push('/landlord-admin/properties')

              return
            }

            if (somePropertiesAlreadyAdvertised) {
              const { alreadyAdvertisedPropertyIds } = response
              alreadyAdvertisedPropertyIds.map(id => propertyIds.deselect(id))

              const notificationText =
                alreadyAdvertisedPropertyIds.length > 1
                  ? `${alreadyAdvertisedPropertyIds.length} of these properties are already advertised. They have been omitted from this transaction.`
                  : `1 of these properties is already advertised. It has been omitted from this transaction.`

              setSelectedProperties(response.properties)
              notificationService.showWarningNotification('', notificationText)

              return
            }

            if (response.properties && response.properties.length === 0) {
              propertyIds.deselectAll()

              notificationService.addDeferredErrorNotification(
                'Oops!',
                'None of these properties can be advertised right now. Please check your selection and try again.'
              )
              history.push('/landlord-admin/properties')
            } else {
              setSelectedProperties(response.properties)
            }
          })
        }
      } else {
        setServerError(true)
      }
    })
  }

  useEffect(() => {
    if (propertyIds.noneSelected()) {
      history.push('/landlord-admin/properties')
      return
    }

    getIsAccountVerified(token).then(response => {
      if (!response.success) {
        setServerError(true)
      }
      if (!response.isAccountVerified) {
        history.push('/landlord-admin/not-verified')
      } else {
        getAdvertiseCredits()
      }
    })
  }, [])

  const handleDeselectProperty = propertyId => {
    propertyIds.deselect(propertyId)
    const propertiesWithTheDeselectedPropertyRemoved = selectedProperties.filter(
      property => property.id !== propertyId
    )

    setSelectedProperties(propertiesWithTheDeselectedPropertyRemoved)
  }

  if (serverError) return <ErrorPage />
  if (loading) return <LoadingPage />

  return paymentMethodPage(selectedProperties, handleDeselectProperty, advertiseCredits)
}

PaymentMethodContainer.propTypes = {
  paymentMethodPage: PropTypes.func.isRequired,
  getLandlordAdvertiseCredits: PropTypes.func.isRequired,
  getProperties: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
  useStateContext: PropTypes.func.isRequired,
  notificationService: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export const PaymentMethodContainerCompositionRoot = ({ history }) => {
  return (
    <PaymentMethodContainer
      paymentMethodPage={(selectedProperties, deselectProperty, advertiseCredits) => (
        <PaymentMethodPage
          selectedProperties={selectedProperties}
          deselectProperty={deselectProperty}
          advertiseCredits={advertiseCredits}
        />
      )}
      getLandlordAdvertiseCredits={getAccountAdvertiseCredits}
      getProperties={getSelectedUnadvertisedProperties}
      getIsAccountVerified={getIsAccountVerified}
      useAuthContext={useAuthContext}
      useStateContext={useStateContext}
      notificationService={notificationService}
      history={history}
    />
  )
}

PaymentMethodContainerCompositionRoot.propTypes = {
  history: PropTypes.object.isRequired,
}

// Readvertise properties - from email reminder

export const SelectPropertiesFromQueryString = (
  container,
  queryString,
  useStateContext,
  notificationService,
  redirect
) => {
  if (!queryString) return redirect('/landlord-admin/properties')

  const query = new URLSearchParams(queryString)

  const idStrings = query
    .get('ids')
    .split(',')
    .map(string => {
      const numberFromIdString = Number(string)
      const validPropertyId = !(
        typeof string === 'undefined' ||
        string === '' ||
        isNaN(numberFromIdString)
      )

      if (validPropertyId) return numberFromIdString
      else return null
    })
    .filter(idString => idString !== null)

  if (idStrings.length === 0) {
    notificationService.addDeferredErrorNotification(
      'Oops!',
      'This property could not be found. Please try again in a few minutes. If this error persists, please contact us.'
    )
    return redirect('/landlord-admin/properties')
  } else {
    const { propertyIds } = useStateContext()
    const ids = [...new Set(idStrings)]
    ids.forEach(id => propertyIds.select(id))
    return container
  }
}

export const ReadvertisePropertiesContainerCompositionRoot = ({ history, location }) => {
  const paymentMethodCompositionRoot = PaymentMethodContainerCompositionRoot({
    history,
  })

  const redirect = path => <Redirect to={path} />

  return SelectPropertiesFromQueryString(
    paymentMethodCompositionRoot,
    location.search,
    useStateContext,
    notificationService,
    redirect
  )
}
