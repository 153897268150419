import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import {
  getBillsOptionalProperties,
  removeBillsOptionalFromProperty,
} from '../../api/billsOptionalApi'
import { useAuthContext } from '../../authContext'
import requestStatus from '../../constants/requestStatus'
import { notificationService } from '../../container'

import LoadingPage from '../pages/LoadingPage'
import ErrorPage from '../pages/ErrorPage'
import BillsOptionalPropertiesPage from '../pages/BillsOptionalPropertiesPage'
import { logEvent } from '../../logger'

export const BillsOptionalPropertiesPageContainer = ({
  propertiesPage,
  loadingPage,
  errorPage,
  getBillsOptionalProperties,
  removeBillsOptionalFromProperty,
  useAuthContext,
  notificationService,
}) => {
  const [apiStatus, setApiStatus] = useState(requestStatus.waiting)
  const [properties, setProperties] = useState([])

  const { token } = useAuthContext()

  const handleRemoveProperty = (propertyId, onSuccess, onError) => {
    notificationService.clearNotifications()

    removeBillsOptionalFromProperty(propertyId, token).then(response => {
      if (response.success) {
        const removedProperty = properties.find(property => property.propertyId === propertyId)

        const waterService = removedProperty.quote.services.find(x => x.name === 'Water')
        const energyService = removedProperty.quote.services.find(x => x.name === 'Energy')
        const internetService = removedProperty.quote.services.find(x => x.name === 'Internet')

        logEvent('Fused Property Quote Removed', {
          'Property ID': propertyId,
          'Quote Price': removedProperty.quote.totalPricePppw,
          'Water Available': waterService.available,
          'Water Price': waterService.pricePppw,
          'Energy Price': energyService.pricePppw,
          'Energy Available': energyService.available,
          'Internet Price': internetService.pricePppw,
          'Internet Available': internetService.available,
        })

        const propertiesWithPropertyRemoved = properties.filter(
          property => property.propertyId !== propertyId
        )

        onSuccess()

        setProperties(propertiesWithPropertyRemoved)

        notificationService.showSuccessNotification(
          'Success!',
          'Property removed from bills optional.'
        )
      } else {
        onError()
      }
    })
  }

  useEffect(() => {
    getBillsOptionalProperties(token).then(response => {
      if (response.success) {
        setApiStatus(requestStatus.success)
        setProperties(response.properties)
      } else {
        setApiStatus(requestStatus.error)
      }
    })
  }, [])

  if (apiStatus === requestStatus.success) {
    return propertiesPage(properties, handleRemoveProperty)
  }

  if (apiStatus === requestStatus.error) {
    return errorPage()
  }

  return loadingPage()
}

BillsOptionalPropertiesPageContainer.propTypes = {
  propertiesPage: PropTypes.func.isRequired,
  loadingPage: PropTypes.func.isRequired,
  errorPage: PropTypes.func.isRequired,
  getBillsOptionalProperties: PropTypes.func.isRequired,
  removeBillsOptionalFromProperty: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
}

export const BillsOptionalPropertiesPageContainerCompositionRoot = () => {
  return (
    <BillsOptionalPropertiesPageContainer
      propertiesPage={(properties, handleRemoveProperty) => (
        <BillsOptionalPropertiesPage
          properties={properties}
          handleRemoveProperty={handleRemoveProperty}
        />
      )}
      loadingPage={() => <LoadingPage />}
      errorPage={() => <ErrorPage />}
      getBillsOptionalProperties={getBillsOptionalProperties}
      removeBillsOptionalFromProperty={removeBillsOptionalFromProperty}
      useAuthContext={useAuthContext}
      notificationService={notificationService}
    />
  )
}
