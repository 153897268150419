import React from 'react'
import PropTypes from 'prop-types'

import PerformanceListRow from '../../molecules/PerformanceListRow'
import useMedia from '../../../hooks/useMedia'
import performanceGrid from './performanceGrid.module.scss'

const PerformanceGrid = ({
  properties,
  analyticsLoaderStatus,
  enquiriesLoaderStatus,
  isFeedAccount,
}) => {
  const smallerThanLargeScreen = useMedia(['(max-width: 1280px)'], [true], false)

  return (
    <div className={performanceGrid.grid} data-testid="performance-grid">
      {properties.map(property => {
        return (
          <PerformanceListRow
            key={property.id}
            property={property}
            isWaitingForAnalytics={analyticsLoaderStatus.waiting}
            isWaitingForEnquiries={enquiriesLoaderStatus.waiting}
            showError={analyticsLoaderStatus.failed}
            smallerThanLargeScreen={smallerThanLargeScreen}
            isFeedAccount={isFeedAccount}
          />
        )
      })}
    </div>
  )
}

PerformanceGrid.propTypes = {
  properties: PropTypes.array.isRequired,
  analyticsLoaderStatus: PropTypes.object.isRequired,
  enquiriesLoaderStatus: PropTypes.object.isRequired,
  isFeedAccount: PropTypes.bool,
}

export default PerformanceGrid
