import React from 'react'
import PropTypes from 'prop-types'

import { useStateContext } from '../../appStateContext'
import { addProperty } from '../../api/propertyApi'
import { pageConfig } from '../pages/AddPropertyFormPage/config/addPropertyPageConfig'
import AddPropertyFormPage from '../pages/AddPropertyFormPage'

const AddPropertyFormPageContainer = ({ history }) => {
  return (
    <AddPropertyFormPage
      history={history}
      useStateContext={useStateContext}
      addProperty={addProperty}
      pageConfig={pageConfig}
    />
  )
}

AddPropertyFormPageContainer.propTypes = {
  history: PropTypes.object.isRequired,
}

export default AddPropertyFormPageContainer
