import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Link from '../../atoms/Link'

import Arrow from '../../../svgs/icons/chevron-right.svg'

import './styles.scss'

const ArrowLink = ({ className, children, ...props }) => {
  const linkClasses = classNames('arrow-link', className)

  return (
    <Link className={linkClasses} {...props}>
      {children}
      <Arrow className="arrow-link__icon" />
    </Link>
  )
}

ArrowLink.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node,
}

export default ArrowLink
