export const errorCodes = {
  passwordResetSuccess: 'AFS2001',
  verifyEmailSuccess: 'AFS2002',
  badRequestUnknownUserInput: 'AFS4000',
  userAlreadyExists: 'AFS4001',
  badRequestButUnknownReason: 'AFS4002',
  expiredConfirmationCode: 'AFS4003',
  incorrectConfirmationCode: 'AFS4004',
  passwordResetInvalidIdentityProviderRequestConfiguration: 'AFS4005',
  passwordResetInvalidPasswordSupplied: 'AFS4006',
  passwordResetRequestLimitExceeded: 'AFS4007',
  passwordResetUserNotAuthorized: 'AFS4008',
  passwordResetTooManyFailedAttempts: 'AFS4009',
  passwordResetTooManyRequests: 'AFS4010',
  passwordResetUserNotConfirmed: 'AFS4011',
  passwordResetUserAccountNotFound: 'AFS4012',
  invalidToken: 'AFS4013',
  internalErrorInIdentityProvider: 'AFS5000',
  passwordResetInvalidLambdaResponse: 'AFS4014',
  passwordResetUnexpectedLambdaException: 'AFS4015',
  passwordResetLambdaValidationException: 'AFS4016',
}

export default (Auth, authApi) => {
  const signIn = (username, password) => {
    const lowerCasedEmail = username.toLowerCase()

    const validationData = {
      email: lowerCasedEmail,
    }

    const authDetails = {
      username: lowerCasedEmail,
      password,
      validationData,
    }

    return Auth.signIn({ ...authDetails })
      .then(user => {
        return {
          success: true,
          user,
        }
      })
      .catch(error => {
        return {
          success: false,
          reason: error.message,
          code: error.code,
        }
      })
  }

  const signOut = () => {
    return Auth.signOut()
      .then(() => ({
        success: true,
      }))
      .catch(error => {
        return {
          success: false,
          reason: error.message,
          code: error.code,
        }
      })
  }

  const forgotPassword = async username => {
    const clientMetadata = {
      isFromMigration: 'false',
    }
    return Auth.forgotPassword(username, clientMetadata)
      .then(() => ({
        success: true,
      }))
      .catch(error => {
        return {
          success: false,
          code: error.code,
        }
      })
  }

  const resetPasswordAfterMigration = async username => {
    const clientMetadata = {
      isFromMigration: 'true',
    }
    return Auth.forgotPassword(username, clientMetadata)
      .then(() => ({
        success: true,
      }))
      .catch(error => {
        return {
          success: false,
          code: error.code,
        }
      })
  }

  const forgotPasswordConfirmation = async (confirmationCode, id, password) => {
    return authApi.forgotPasswordConfirmation(confirmationCode, id, password).then(response => ({
      success: response.success,
      code: response.code,
    }))
  }

  const resendVerificationLink = email => {
    Auth.resendSignUp(email)
      .then()
      .catch()
  }

  const changePassword = (oldPassword, newPassword) => {
    return Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, oldPassword, newPassword)
      })
      .then(() => ({
        success: true,
      }))
      .catch(error => {
        return {
          success: false,
          code: error.code,
        }
      })
  }

  return {
    signIn,
    signOut,
    changePassword,
    forgotPassword,
    forgotPasswordConfirmation,
    resendVerificationLink,
    resetPasswordAfterMigration,
  }
}
