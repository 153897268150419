import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Field from '@afs/components/Field'

import './styles.scss'

const FieldMultiSelect = ({
  className,
  legend,
  hideLegend,
  options,
  onClick,
  onSelectOption,
  selectedOptions,
}) => {
  const defaultClickHandler = event => {
    const bill = event.target.value
    let newOptions
    const { checked } = event.target

    if (checked) {
      newOptions = [...selectedOptions, bill]
    } else if (!checked) {
      newOptions = selectedOptions.filter(v => v !== bill)
    }

    onSelectOption(newOptions)
  }

  const componentClasses = classNames('field-multi-select', className).trim()
  const legendClasses = classNames('field-multi-select__legend', {
    'field-multi-select__legend--hidden': hideLegend,
  })

  return (
    <fieldset className={componentClasses}>
      <legend className={legendClasses}>{legend}</legend>
      {options.map(option => {
        const isChecked = selectedOptions.includes(option)
        const testId = option.replace(/\s+/g, '-').toLowerCase()
        return (
          <Field
            key={option}
            name={option}
            className="field-multi-select__checkbox"
            type="checkbox"
            label={option}
            value={option}
            data-testid={`multi-select-option-${testId}`}
            onChange={event => {
              onClick ? onClick(event) : defaultClickHandler(event)
            }}
            checked={isChecked}
          />
        )
      })}
    </fieldset>
  )
}

FieldMultiSelect.propTypes = {
  className: PropTypes.string,
  legend: PropTypes.string.isRequired,
  hideLegend: PropTypes.bool,
  options: PropTypes.array,
  onClick: PropTypes.func,
  onSelectOption: PropTypes.func,
  selectedOptions: PropTypes.array,
}

export default FieldMultiSelect
