export const inputStyles = {
  base: {
    color: '#3c3a4a',
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    fontVariant: 'common-ligatures tabular-nums',
    lineHeight: '28px',
    '::placeholder': {
      color: '#9997a3',
    },
  },
  invalid: {
    color: '#3c3a4a',
    iconColor: '#ec2e2e',
  },
}
