import Address from './address'
import { symbolForIsoCode } from './currency'

const formatBedroomStrings = (bedroom, currencySymbol) => [
  `${bedroom.roomSize} room, ${bedroom.bedSize} bed${bedroom.enSuite ? ', with en-suite' : ''}`,
  `${currencySymbol}${bedroom.rent.amount}pw rent, ${currencySymbol}${
    bedroom.deposit.amount
  } deposit, ${bedroom.available ? 'Available' : 'Unavailable'}`,
]

class ReviewPageViewModel {
  constructor(property) {
    Object.assign(this, property)

    this.address = new Address(property.address)
  }

  formattedBedrooms() {
    const validBedrooms = this.bedrooms.filter(bedroom => bedroom.valid)
    return validBedrooms.map(bedroom => {
      return {
        position: bedroom.position,
        formattedStrings: formatBedroomStrings(bedroom, symbolForIsoCode(bedroom.rent.currency)),
      }
    })
  }

  formattedBillsIncludedString() {
    const billsCopy = this.bills.slice()

    if (billsCopy.length > 1) {
      const lastBill = billsCopy.length - 1
      billsCopy[lastBill] = `and ${billsCopy[lastBill]}`
    }

    return billsCopy.join(', ')
  }

  truncatedDescription(limit) {
    if (!this.description) return ''

    return this.description.length > limit
      ? `${this.description.substring(0, limit)}…`
      : this.description
  }

  uploadedPhotosCount() {
    const photosArray = this.photos.images.items
    const photosCount = photosArray.length
    const hasPhotos = photosArray.length > 0

    return hasPhotos ? photosCount : 0
  }
}

export default ReviewPageViewModel
