import React from 'react'
import PropTypes from 'prop-types'

import ManagePropertiesPageTemplate from '../../templates/ManagePropertiesPageTemplate'
import ManagePropertiesHero from '../../atoms/ManagePropertiesHero'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Heading from '../../atoms/Heading'
import EnquiryGrid from '../../organisms/EnquiryGrid'
import NotFoundAnnouncement from '../../molecules/NotFoundAnnouncement'
import IconButton from '../../molecules/IconButton'

import NewProperty from '../../../svgs/illustrations/new-property.svg'
import NoEnquiries from '../../../svgs/illustrations/no-enquiries.svg'

import hero from '../PropertiesPage/modules/hero.module.scss'
import layout from '../PropertiesPage/modules/layout.module.scss'
import noProperties from '../PropertiesPage/modules/noProperties.module.scss'
import enquiriesPage from './enquiriesPage.module.scss'

const EnquiriesPage = ({ enquiries, landlordHasProperties, deleteEnquiry }) => {
  const renderHero = () => {
    return (
      <ManagePropertiesHero
        className={enquiriesPage.hero}
        hasPatternBackgroundOnMobile
        pattern="green"
      >
        <div className={hero.row}>
          <Heading className={hero.heading}>Enquiries</Heading>
        </div>
      </ManagePropertiesHero>
    )
  }

  return (
    <ManagePropertiesPageTemplate
      title="Your Enquiries | AFS"
      header={<Header />}
      footer={<Footer dark />}
      hero={renderHero()}
    >
      <div className={layout.inner}>
        {!landlordHasProperties && (
          <NotFoundAnnouncement
            className={noProperties.wrapper}
            icon={NewProperty}
            heading="You haven't added any properties yet"
            subHeading="Adding a property is super easy and it only takes a minute."
            button={
              <IconButton
                className={`${hero.button} ${noProperties.button}`}
                to="/landlord-admin/add-property"
                icon={{
                  icon: 'plus-circle',
                  width: '24',
                  height: '24',
                }}
                primary
                data-testid="no-properties-button"
              >
                Add property
              </IconButton>
            }
            data-testid="no-properties"
          />
        )}
        {landlordHasProperties && enquiries.length === 0 && (
          <NotFoundAnnouncement
            className={noProperties.wrapper}
            icon={NoEnquiries}
            heading="No enquiries"
            subHeading="You haven’t received any enquiries just yet."
            data-testid="no-enquiries"
          />
        )}
        {enquiries.length > 0 && (
          <EnquiryGrid
            data-testid="enquiry-grid"
            enquiries={enquiries}
            deleteEnquiry={deleteEnquiry}
          />
        )}
      </div>
    </ManagePropertiesPageTemplate>
  )
}

EnquiriesPage.propTypes = {
  enquiries: PropTypes.array.isRequired,
  landlordHasProperties: PropTypes.bool.isRequired,
  deleteEnquiry: PropTypes.func.isRequired,
}

export default EnquiriesPage
