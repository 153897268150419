import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import InfoTooltip from '@afs/components/InfoTooltip'

import InfoIcon from '../../../svgs/icons/info-square.svg'

import styles from './infoBox.module.scss'
import './styles.scss'

const InfoBox = ({ className, text, toolTipText }) => {
  const infoClasses = classNames(styles.wrapper, className)

  return (
    <div className={infoClasses} data-testid="info-box">
      <InfoIcon className={styles.icon} />
      <div className={styles.textWrapper}>
        <span>{text}</span>
        <InfoTooltip className="info-box__info-tooltip" medium tooltipContent={toolTipText} />
      </div>
    </div>
  )
}

InfoBox.propTypes = {
  text: PropTypes.object.isRequired,
  toolTipText: PropTypes.any.isRequired,
  className: PropTypes.string,
}

export default InfoBox
