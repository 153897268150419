import React from 'react'
import {
  updateAccountName,
  updateAccountCompanyName,
  updateAccountPublicPhoneSettings,
  updateAccountPersonalPhone,
  updateAccountInvoicingAddress,
} from '../../../api/accountApi'

import NameForm from '../../organisms/AccountSettingsForms/NameForm'
import CompanyNameForm from '../../organisms/AccountSettingsForms/CompanyNameForm'
import PublicPhoneNumberForm from '../../organisms/AccountSettingsForms/PublicPhoneNumberForm'
import PublicPhoneNumberValue from '../../organisms/AccountSettingsValues/PublicPhoneNumberValue'
import PersonalPhoneNumberForm from '../../organisms/AccountSettingsForms/PersonalPhoneNumberForm'
import InvoicingAddressForm from '../../organisms/AccountSettingsForms/InvoicingAddressForm'
import InvoicingAddressValue from '../../atoms/InvoicingAddressValue'

const countries = require('./countries.json')

export default [
  {
    name: 'name',
    heading: 'Name',
    renderForm: ({ ...props }) => <NameForm {...props} />,
    renderValue: landlord => <p>{landlord.fullNameString() || '-'}</p>,
    saveAction: updateAccountName,
    expanded: false,
    disabled: false,
    loading: false,
  },
  {
    name: 'personalPhoneNumber',
    heading: 'Personal phone number',
    renderForm: ({ ...props }) => <PersonalPhoneNumberForm {...props} />,
    renderValue: landlord => <p>{landlord.personalPhoneNumberString() || '-'}</p>,
    saveAction: updateAccountPersonalPhone,
    expanded: false,
    disabled: false,
    loading: false,
  },
  {
    name: 'publicPhoneNumber',
    heading: 'Public enquiries phone number',
    renderForm: ({ ...props }) => <PublicPhoneNumberForm {...props} />,
    renderValue: landlord => <PublicPhoneNumberValue landlord={landlord} />,
    saveAction: updateAccountPublicPhoneSettings,
    expanded: false,
    disabled: false,
    loading: false,
  },
  {
    name: 'companyName',
    heading: 'Company name',
    renderForm: ({ ...props }) => <CompanyNameForm {...props} />,
    renderValue: landlord => <p>{landlord.companyNameString() || '-'}</p>,
    saveAction: updateAccountCompanyName,
    expanded: false,
    disabled: false,
    loading: false,
  },
  {
    name: 'invoicingAddress',
    heading: 'Invoicing address',
    renderForm: ({ ...props }) => <InvoicingAddressForm countries={countries} {...props} />,
    renderValue: landlord => <InvoicingAddressValue account={landlord} />,
    saveAction: updateAccountInvoicingAddress,
    expanded: false,
    disabled: false,
    loading: false,
  },
]
