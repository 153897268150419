import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useStateContext } from '../../../appStateContext'
import { usePropertyContext } from '../../../propertyContext'
import { config } from '../../../config'
import { relistProperty } from '../../../api/propertyApi'

import { notificationService } from '../../../container'
import { NotificationSubscriberIds } from '../../organisms/Notifications'
import { useAuthContext } from '../../../authContext'
import { logEvent } from '../../../logger'

import ManagePropertiesPageTemplate from '../../templates/ManagePropertiesPageTemplate'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import PropertyDetailsOverview from '../../molecules/PropertyDetailsOverview'
import PropertyDetailsBoost from '../../molecules/PropertyDetailsBoost'
import PropertyDetailsAvailability from '../../molecules/PropertyDetailsAvailability'
import renderHero from './propertyDetailsHero'
import renderBoostOverlay from './overlays/BoostOverlay/boostOverlay'
import { renderSuccessNotification } from './boostNotifications'
import RelistPropertyOverlay from '../../organisms/RelistPropertyOverlay'

import layout from './modules/layout.module.scss'

const PropertyDetailsPage = ({ history, landlordBoostCredits, boostProperty }) => {
  const { token } = useAuthContext()
  const { propertyIds } = useStateContext()
  const { property, refreshProperty } = usePropertyContext()

  const [boostCreditOverlayConfig, setBoostCreditOverlayConfig] = useState({
    active: false,
  })

  const [boostCredits, setBoostCredits] = useState(landlordBoostCredits)
  const boostCost = 1

  const [updatingBoost, setUpdatingBoost] = useState(false)
  const [updatingListing, setUpdatingListing] = useState(false)
  const [boostResponse, setBoostResponse] = useState(null)

  const [propertyRelistError, setPropertyRelistError] = useState(false)

  const [relistPropertyOverlayConfig, setRelistPropertyOverlayConfig] = useState({
    active: false,
  })

  const propertyBoostSuccess = boostResponse && boostResponse.success
  const propertyBoostError = boostResponse && !boostResponse.success

  const propertyIsCurrentlyAdvertisedAndListed =
    property.status === 'CurrentlyAdvertised' && property.isListed

  const propertyIsCurrentlyAdvertisedButNotListed =
    property.status === 'CurrentlyAdvertised' && !property.isListed

  const handleBackClick = event => {
    event.preventDefault()
    history.push('/landlord-admin/properties')
  }

  const handleAddToBasket = () => {
    propertyIds.select(property.id)
    history.push('/landlord-admin/payment-method')
  }

  const handleOverlayClose = () => {
    setBoostCreditOverlayConfig({
      active: false,
    })
  }

  const showBoostOverlay = () => {
    setBoostCreditOverlayConfig({
      active: true,
    })
    setBoostResponse(null)
  }

  const onBoostProperty = event => {
    event.preventDefault()
    const propertyId = property.id

    setBoostResponse(null)
    setUpdatingBoost(true)

    boostProperty(propertyId, response => {
      setBoostResponse(response)

      if (response.success) {
        setBoostCredits(boostCredits - boostCost)
        handleOverlayClose()
      }

      setUpdatingBoost(false)
    })
  }

  const onRelistProperty = async () => {
    const propertyId = property.id
    setUpdatingListing(true)

    const response = await relistProperty(propertyId, token)

    if (response.success) {
      setPropertyRelistError(false)
      setUpdatingListing(false)
      setRelistPropertyOverlayConfig({ active: false })
      refreshProperty()
      notificationService.showSuccessNotificationForSubscriber(
        NotificationSubscriberIds.ManagePropertyListingsTemplate,
        'Success!',
        'This property has been relisted.'
      )
      logEvent('Relist Property', {
        Source: 'Hero',
        'Property Id': property.id,
        'Property Type': property.accommodationType,
      })
    } else {
      setPropertyRelistError(true)
      setUpdatingListing(false)
    }
  }

  return (
    <ManagePropertiesPageTemplate
      title="Property Details | AFS"
      header={<Header />}
      footer={<Footer dark />}
      hero={renderHero(
        property,
        handleBackClick,
        propertyIsCurrentlyAdvertisedAndListed,
        propertyIsCurrentlyAdvertisedButNotListed,
        () => setRelistPropertyOverlayConfig({ active: true }),
        updatingListing,
        handleAddToBasket
      )}
    >
      <div className={layout.inner}>
        {propertyBoostSuccess && renderSuccessNotification()}
        <div className={layout.grid}>
          <div className={layout.column}>
            <PropertyDetailsOverview
              className={layout.card}
              property={property}
              onAddToBasket={handleAddToBasket}
              imageApiUrl={config.imgApiUrl}
            />
          </div>
          <div className={layout.column}>
            <PropertyDetailsAvailability className={layout.card} property={property} />
          </div>
          {propertyIsCurrentlyAdvertisedAndListed && (
            <div className={layout.column}>
              <PropertyDetailsBoost
                className={layout.card}
                property={property}
                landlordBoostCredits={boostCredits}
                openOverlay={() => showBoostOverlay()}
              />
            </div>
          )}
        </div>
      </div>
      {renderBoostOverlay(
        boostCreditOverlayConfig,
        boostCredits,
        boostCost,
        onBoostProperty,
        updatingBoost,
        handleOverlayClose,
        propertyBoostError
      )}
      <RelistPropertyOverlay
        handleOverlayClose={() => setRelistPropertyOverlayConfig({ active: false })}
        handleListProperty={onRelistProperty}
        config={relistPropertyOverlayConfig}
        property={property}
        updating={updatingListing}
        propertyRelistError={propertyRelistError}
      />
    </ManagePropertiesPageTemplate>
  )
}

PropertyDetailsPage.propTypes = {
  history: PropTypes.object,
  landlordBoostCredits: PropTypes.number.isRequired,
  boostProperty: PropTypes.func.isRequired,
}

export default PropertyDetailsPage
