import { performApiGet, performApiPost, performApiDelete } from './requests'

export const getNonBillsOptionalProperties = async token => {
  try {
    const response = await performApiGet('/property/bills-optional?isBillsOptional=false', token)

    return {
      success: response.status === 200,
      properties: response.data.properties,
      statusCode: response.data.statusCode,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getBillsOptionalProperties = async token => {
  try {
    const response = await performApiGet('/property/bills-optional?isBillsOptional=true', token)
    return {
      success: response.status === 200,
      properties: response.data.properties,
      statusCode: response.data.statusCode,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const createBillsPackageQuoteForProperty = async (payload, token) => {
  try {
    const response = await performApiPost('/property/bills-package-quote', payload, token)
    return {
      success: response.status === 200,
      quote: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const confirmPropertiesAsBillsOptional = async (payload, token) => {
  try {
    const response = await performApiPost('/property/confirm-bills-optional', payload, token)
    return {
      success: response.status === 200,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const removeBillsOptionalFromProperty = async (propertyId, token) => {
  try {
    const response = await performApiDelete(`/property/bills-optional/${propertyId}`, token)
    return {
      success: response.status === 200,
    }
  } catch (exception) {
    return { success: false }
  }
}
