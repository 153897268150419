import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import Notification from '../../../molecules/Notification'
import FieldAdapter from '../../../adapters/FieldAdapter'
import youtubeUrlValidator from '../../../../utils/youtubeUrlValidator'

import PlayButtonBackground from '../../../../svgs/illustrations/play-button-background.svg'

import videoTourPage from './videoTourPage.module.scss'

export const VideoTourPage = ({ videoTourUrl, videoEmbedComponent, showNotification }) => {
  const urlValidationResult = youtubeUrlValidator(videoTourUrl)

  return (
    <>
      {showNotification && (
        <Notification
          className={videoTourPage.notification}
          type="info"
          heading="Don't have a YouTube video ready?"
          text={[
            <PlayButtonBackground key={0} className={videoTourPage.playButtonBackground} />,
            <span className={videoTourPage.notificationText} key={1}>
              You can add one later, or even email your video to our support team and we’ll create
              one for you.
            </span>,
          ]}
          nonDismissible
        />
      )}
      <Field className={videoTourPage.field} name="videoTourUrl" component={FieldAdapter}>
        {props => (
          <FieldAdapter label="YouTube link" placeholder="https://..." optional {...props} />
        )}
      </Field>
      {urlValidationResult.isValid && (
        <div className={videoTourPage.video}>
          <div className={videoTourPage.iframeWrapper}>
            {videoEmbedComponent(videoTourPage.embed, urlValidationResult.videoId)}
          </div>
        </div>
      )}
    </>
  )
}

export const videoTourPageValidation = videoTourUrl => {
  const errors = {}

  const result = youtubeUrlValidator(videoTourUrl)

  if (videoTourUrl && !result.isValid) {
    errors.videoTourUrl = result.reason
  }

  return errors
}

VideoTourPage.propTypes = {
  videoTourUrl: PropTypes.string,
  videoEmbedComponent: PropTypes.func.isRequired,
  showNotification: PropTypes.bool,
}
