import { performApiGet, performApiPost } from './requests'

export const createPayment = async (payload, token) => {
  try {
    const response = await performApiPost('/order/', payload, token)

    return {
      success: response.status === 200,
      paymentInfo: response.data,
    }
  } catch (exception) {
    return { success: false, data: exception }
  }
}

export const getAdvertOptions = async (payload, token) => {
  try {
    const response = await performApiPost('/order/ad-options', payload, token)

    return {
      success: response.status === 200,
      data: response.data,
    }
  } catch (exception) {
    return { success: false, data: exception }
  }
}

export const getDiscount = async (payload, token) => {
  try {
    const response = await performApiPost('/order/discount', payload, token)

    return {
      success: response.status === 200,
      discount: response.data,
    }
  } catch (exception) {
    return { success: false, data: exception }
  }
}

export const getNewPropertyDiscountEligibility = async token => {
  try {
    const response = await performApiGet('/order/discount/new-property-eligibility', token)

    return {
      success: response.status === 200,
      discount: response.data,
    }
  } catch (exception) {
    return { success: false, data: exception }
  }
}

export const checkIfOrderHasBeenProcessed = async (paymentIntentId, token) => {
  try {
    const response = await performApiGet(
      `/order/check-if-order-processed/${paymentIntentId}`,
      token
    )

    return {
      success: response.status === 200,
      ...response.data,
    }
  } catch (exception) {
    return { success: false, error: exception }
  }
}
