import React from 'react'
import classNames from 'classnames'
import SpinnerButton from '@afs/components/SpinnerButton'

import useMedia from '../../../hooks/useMedia'
import { config } from '../../../config'
import Products from '../../../models/products'

import Button from '../../atoms/Button'
import IconButton from '../../molecules/IconButton'
import ManagePropertiesHero from '../../atoms/ManagePropertiesHero'
import BackLink from '../../molecules/BackLink'
import Heading from '../../atoms/Heading'
import AdStatusTag from '../../atoms/AdStatusTag'

import hero from './modules/hero.module.scss'
import actionsBanner from './modules/actionsBanner.module.scss'
import advertiseProperty from './modules/advertiseProperty.module.scss'

const { cheapestAvailableAd } = Products()

const renderActionsBanner = (
  propertyUrl,
  editPropertyUrl,
  propertyIsCurrentlyAdvertisedAndListed,
  propertyIsCurrentlyAdvertisedButNotListed,
  onTogglePropertyListing,
  updatingListing
) => {
  const isMobile = useMedia(['(max-width: 768px)'], [true], false)

  if (!isMobile) return false

  return (
    <div className={actionsBanner.wrapper}>
      <Button className={actionsBanner.button} to={editPropertyUrl}>
        Edit property details
      </Button>
      {propertyIsCurrentlyAdvertisedAndListed && (
        <IconButton
          className={actionsBanner.button}
          href={propertyUrl}
          target="_blank"
          icon={{
            icon: 'open',
            width: '14',
            height: '14',
          }}
          iconPosition="right"
        >
          View listing
        </IconButton>
      )}
      {propertyIsCurrentlyAdvertisedButNotListed && (
        <SpinnerButton
          className={actionsBanner.button}
          primary
          onClick={onTogglePropertyListing}
          inactive={updatingListing}
          data-testid="relist-property-button"
        >
          Relist property
        </SpinnerButton>
      )}
    </div>
  )
}

const AdvertisePropertyBanner = handleAddToBasket => {
  return (
    <div className={advertiseProperty.wrapper}>
      <div className={advertiseProperty.inner}>
        <div className={advertiseProperty.text}>
          <strong className={advertiseProperty.heading}>Advertise this property</strong>
          <p className={advertiseProperty.subheading}>
            Make this listing public on the website from as little as{' '}
            <strong>£{cheapestAvailableAd()}/month</strong>.
          </p>
        </div>
        <Button
          className={advertiseProperty.button}
          primary
          onClick={() => handleAddToBasket()}
          data-testid="advertise-button"
        >
          Advertise property
        </Button>
      </div>
    </div>
  )
}

const renderHero = (
  property,
  handleBackClick,
  propertyIsCurrentlyAdvertisedAndListed,
  propertyIsCurrentlyAdvertisedButNotListed,
  onTogglePropertyListing,
  updatingListing,
  handleAddToBasket
) => {
  const { id, path, address } = property

  const propertyUrl = `${config.mainSiteBaseUrl}property/${path}`
  const editPropertyUrl = `/landlord-admin/property/${id}/edit/review`

  if (!address.firstLine()) return false

  return (
    <ManagePropertiesHero
      hasPatternBackgroundOnMobile={property.status === 'CurrentlyAdvertised'}
      banner={
        <>
          {property.status === 'NotAdvertised' && AdvertisePropertyBanner(handleAddToBasket)}
          {renderActionsBanner(
            propertyUrl,
            editPropertyUrl,
            propertyIsCurrentlyAdvertisedAndListed,
            propertyIsCurrentlyAdvertisedButNotListed,
            onTogglePropertyListing,
            updatingListing
          )}
        </>
      }
      paddingType="forBacklink"
    >
      <BackLink className={hero.backLink} onClick={event => handleBackClick(event)}>
        Back to properties
      </BackLink>
      <div className={hero.row}>
        <div className={hero.details}>
          <Heading className={hero.heading} data-testid="hero-heading">
            {address.firstTwoLines()}
            {address.area && <b className={hero.subHeading}>, {address.area}</b>}
          </Heading>
          <AdStatusTag
            status={property.status}
            isListed={property.isListed}
            startDate={property.startDate()}
            expiryDate={property.expiryDate()}
          />
        </div>
        <Button
          className={classNames(hero.whiteButton, hero.whiteButtonFirst)}
          to={editPropertyUrl}
          data-testid="edit-button"
        >
          Edit property details
        </Button>
        {propertyIsCurrentlyAdvertisedAndListed && (
          <IconButton
            className={hero.whiteButton}
            href={propertyUrl}
            target="_blank"
            icon={{
              icon: 'open',
              width: '14',
              height: '14',
            }}
            iconPosition="right"
          >
            View listing
          </IconButton>
        )}
        {propertyIsCurrentlyAdvertisedButNotListed && (
          <SpinnerButton
            className={hero.button}
            primary
            onClick={onTogglePropertyListing}
            inactive={updatingListing}
            data-testid="relist-property-button-hero"
          >
            Relist property
          </SpinnerButton>
        )}
      </div>
    </ManagePropertiesHero>
  )
}

export default renderHero
