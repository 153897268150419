import React from 'react'

import LoginRegisterPageTemplate from '../../templates/LoginRegisterPageTemplate'
import SelectAccountCard from '../../molecules/SelectAccountCard'

import selectAccountPage from './selectAccountPage.module.scss'

const SelectAccoutPage = () => (
  <LoginRegisterPageTemplate title="Select account | AFS">
    <SelectAccountCard className={selectAccountPage.card} />
  </LoginRegisterPageTemplate>
)

export default SelectAccoutPage
