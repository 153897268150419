import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import valueWithLabel from './valueWithLabel.module.scss'

const ValueWithLabel = ({
  className,
  value,
  valueClass,
  label,
  labelClass,
  innerClass,
  children,
}) => {
  const valueWithLabelClassNames = classNames(valueWithLabel.wrapper, className)
  const valueClasses = classNames(valueWithLabel.value, valueClass)
  const labelClasses = classNames(valueWithLabel.label, labelClass)
  const innerClasses = classNames(valueWithLabel.inner, innerClass)

  return (
    <div className={valueWithLabelClassNames} data-testid="value-with-label">
      <span className={valueClasses} data-testid="value-with-label-value">
        {value}
      </span>
      <div className={innerClasses}>
        <span className={labelClasses}>{label}</span>
        {children}
      </div>
    </div>
  )
}

ValueWithLabel.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any.isRequired,
  valueClass: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  innerClass: PropTypes.string,
  children: PropTypes.any,
}

export default ValueWithLabel
