import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Input from '@afs/components/Input'
import Label from '@afs/components/Label'

import './styles.scss'

class FieldHoneypot extends Component {
  constructor() {
    super()

    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      value: false,
      autoComplete: 'off',
      tabindex: '-1',
    }
  }

  handleChange = (event) => {
    this.setState({
      value: event.target.checked,
    })
  }

  render() {
    const fieldClasses = classNames('form-field', 'form-field--faxonly', this.props.className)
    const inputProps = {
      id: this.props.name || 'contact_me_by_fax_only',
      name: this.props.name || 'contact_me_by_fax_only',
      type: this.props.type || 'checkbox',
      'aria-describedby': `${this.props.name || 'contact_me_by_fax_only'}Error`,
    }

    return (
      <div className={fieldClasses}>
        <Input
          key="honeypotInput"
          defaultChecked={this.state.value}
          onChange={(event) => this.handleChange(event)}
          autoComplete={this.state.autoComplete}
          tabIndex={this.state.tabindex}
          {...inputProps}
        />
        <Label key="label" htmlFor={inputProps.id}>
          {this.props.label || 'Spam protection, please leave this checkbox unchecked'}
        </Label>
      </div>
    )
  }
}

FieldHoneypot.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
}

export default FieldHoneypot
