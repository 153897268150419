import React from 'react'
import PropTypes from 'prop-types'
import FieldIncrementCount from '../molecules/FieldIncrementCount'

const FieldIncrementCountAdapter = ({ input: { onChange, value }, name, label, min, max }) => (
  <FieldIncrementCount
    name={name}
    value={value}
    label={label}
    handleIncrementClick={() => onChange(value + 1)}
    handleDecrementClick={() => onChange(value - 1)}
    currentNumber={value}
    min={min}
    max={max}
    handleOnKeyDown={(value, newNumber) => {
      if (newNumber >= min && newNumber <= max) onChange(newNumber)
    }}
  />
)

FieldIncrementCountAdapter.propTypes = {
  input: PropTypes.object,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  label: PropTypes.string,
}

export default FieldIncrementCountAdapter
