import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from '../../atoms/Button'
import Link from '../../atoms/Link'

import loginCard from './loginCard.module.scss'

const LoginCard = ({ className, pageType }) => {
  const cardClasses = classNames(loginCard.loginCard, className)

  const isLogin = pageType === 'login'

  const leftTabClasses = classNames(
    loginCard.leftTab,
    isLogin ? loginCard.activeTab : loginCard.inactiveTab
  )

  const rightTabClasses = classNames(
    loginCard.rightTab,
    isLogin ? loginCard.inactiveTab : loginCard.activeTab
  )

  const leftTabGapClasses = classNames(
    loginCard.tabGap,
    isLogin ? loginCard.leftTabGapActive : loginCard.leftTabGapInactive
  )

  const rightTabGapClasses = classNames(
    loginCard.tabGap,
    isLogin ? loginCard.rightTabGapInactive : loginCard.rightTabGapActive
  )

  const buttonClassesLandlord = classNames(loginCard.button, loginCard.chunkyButtonLandlord)
  const buttonClassesLettingAgent = classNames(loginCard.button, loginCard.chunkyButtonLettingAgent)

  return (
    <div className={cardClasses}>
      <div className={loginCard.tabs}>
        {isLogin ? (
          <div className={leftTabClasses}> Login </div>
        ) : (
          <Link className={leftTabClasses} to="/login">
            Login
          </Link>
        )}
        <div className={leftTabGapClasses} />
        <div className={rightTabGapClasses} />

        {isLogin ? (
          <Link className={rightTabClasses} to="/register">
            Register
          </Link>
        ) : (
          <div className={rightTabClasses}>Register</div>
        )}
      </div>
      <div className={loginCard.container}>
        <h5 className={loginCard.userHeading}>Landlord or Letting Agent</h5>

        {isLogin ? (
          <Button
            className={loginCard.loginButton}
            to="/landlord-admin/login"
            violet
            data-testid="account-login-button"
          >
            Login
          </Button>
        ) : (
          <>
            <Button
              className={buttonClassesLandlord}
              to="/landlord-admin/register"
              violet
              data-testid="landlord-button"
            >
              Create a Landlord account
            </Button>
            <Button
              className={buttonClassesLettingAgent}
              to="/landlord-admin/letting-agent/register"
              violet
              data-testid="letting-agent-button"
            >
              Create a Letting Agent account
            </Button>
          </>
        )}

        {isLogin ? (
          <p className={loginCard.textWrapper}>
            <span className={loginCard.text} data-testid="message-text">
              No account?{' '}
            </span>
            <Link className={loginCard.link} to="/register" data-testid="message-link">
              Register
            </Link>
          </p>
        ) : (
          <p className={loginCard.textWrapper}>
            <span className={loginCard.text} data-testid="message-text">
              Got an account?{' '}
            </span>
            <Link className={loginCard.link} to="/login" data-testid="message-link">
              Login
            </Link>
          </p>
        )}

        {!isLogin ? (
          <div className={loginCard.studentBanner}>
            <p className={loginCard.studentBannerText}>
              Are you a Student looking to advertise a spare room?
            </p>
            <Link
              className={loginCard.studentBannerLink}
              href="https://www.studenthousemates.com/advertise?campaign=lla-register-page"
            >
              Go to StudentHousemates.com
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  )
}

LoginCard.propTypes = {
  className: PropTypes.string,
  pageType: PropTypes.oneOf(['login', 'register']).isRequired,
}

export default LoginCard
