import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import SuccessIcon from '../../../svgs/icons/green-tick.svg'

import largeNotification from './largeNotification.module.scss'

const LargeNotification = ({ className, type, text, renderInteractiveComponent }) => {
  const largeNotificationClasses = classNames(largeNotification.wrapper, className)

  const icons = [
    {
      type: 'success',
      icon: <SuccessIcon className={largeNotification.icon} data-testid="success-icon" />,
    },
  ]

  const renderIcon = type => {
    const selectedIcon = icons.find(icon => icon.type === type)
    return selectedIcon.icon
  }

  return (
    <div className={largeNotificationClasses} data-testid="large-notification">
      <div className={largeNotification.inner}>
        {renderIcon(type)}
        <p className={largeNotification.text}>{text}</p>
        {renderInteractiveComponent && renderInteractiveComponent()}
      </div>
    </div>
  )
}

LargeNotification.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  renderInteractiveComponent: PropTypes.func,
}

export default LargeNotification
