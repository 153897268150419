export const containsEmail = string => {
  const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
  return string.match(emailRegex)
}

export const invalidEmail = string => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/gi

  return !string.match(emailRegex)
}

export const containsUrl = string => {
  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/gi
  return string.match(urlRegex)
}

export const containsPhone = string => {
  const phoneRegex = /\b[+]?[(]?[0-9]{3,6}[)]?[\s0-9]{4,16}\b/gi
  return string.match(phoneRegex)
}

export const includesHTML = string => {
  const htmlRegex = /<\/?\w+((\s+\w+(\s*=\s*(?:".*?"|'.*?'|[^'">\s]+))?)+\s*|\s*)\/?>/g
  return string.match(htmlRegex)
}

export const invalidCompanyName = string => {
  const companyNameRegex = /[^A-Za-z\d\s&@£$€¥#.,:;-]/g
  return string.match(companyNameRegex)
}

export const invalidPhoneNumber = string => {
  const phoneNumberRegex = /(((\+44)? ?(\(0\))? ?)|(0))( ?[0-9]{3,4}){3}/g
  return !string.match(phoneNumberRegex)
}

export const exceedsMaxLength = (string, maxLength) => string.length > maxLength

export const emptyRequiredField = string => {
  const stringWithoutWhitespace = string.trim()
  return !stringWithoutWhitespace
}

export const validateAccountSettingsPassword = (password, field) => {
  const emptyField = !password
  const passwordIsTooShort = password.length < 8
  const passwordIsMissingALowercaseLetter = !password.match(/[a-z]/g)
  const passwordIsMissingAnUppercaseLetter = !password.match(/[A-Z]/g)
  const passwordIsMissingADigit = !password.match(/[0-9]/g)
  const passwordsDoNotMatch = password != newPassword

  if (field === 'confirmNewPassword' && passwordsDoNotMatch) {
    return 'Password does not match.'
  }

  if (emptyField) {
    return 'Valid password required.'
  }

  if (passwordIsTooShort) {
    return 'Password length is less than 8 characters.'
  }

  if (passwordIsMissingALowercaseLetter) {
    return 'Password does not contain a lowercase letter (a-z).'
  }

  if (passwordIsMissingAnUppercaseLetter) {
    return 'Password does not contain an uppercase letter (A-Z).'
  }

  if (passwordIsMissingADigit) {
    return 'Password does not contain a digit (0-9).'
  }

  return ''
}
