import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import DateRange from '@afs/components/DateRange'

import { config } from '../../../config'
import { validOptions } from '../../../services/performanceDatesService'

import ManagePropertiesPageTemplate from '../../templates/ManagePropertiesPageTemplate'
import ManagePropertiesHero from '../../atoms/ManagePropertiesHero'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Heading from '../../atoms/Heading'
import PerformanceOverviewCard from '../../molecules/PerformanceOverviewCard'
import PerformanceGrid from '../../organisms/PerformanceGrid'
import Notification from '../../molecules/Notification'

import InfoIcon from '../../../svgs/icons/info-round.svg'

import hero from '../PropertiesPage/modules/hero.module.scss'
import layout from '../PropertiesPage/modules/layout.module.scss'
import performancePage from './performancePageFeeds.module.scss'

const getSelectedDatesHeadingText = (date, dateOptions, selectedDateRange) => {
  const { startDate, endDate } = selectedDateRange

  const predefinedDateRange = dateOptions.find(
    (range) => range.startDate === startDate && range.endDate === endDate
  )

  if (predefinedDateRange) {
    return predefinedDateRange.label.toLowerCase()
  } else {
    const startDateIsInThisYear = date.isThisYear(startDate)

    const startDateString = startDateIsInThisYear
      ? date.asDMmm(startDate)
      : date.asDMmmYyyy(startDate)

    const endDateString = startDateIsInThisYear ? date.asDMmm(endDate) : date.asDMmmYyyy(endDate)

    return `${startDateString} – ${endDateString}`
  }
}

const getSelectedDatesNotificationText = (date, dateOptions, selectedDateRange) => {
  const { startDate, endDate } = selectedDateRange

  const predefinedDateRange = dateOptions.find(
    (range) => range.startDate === startDate && range.endDate === endDate
  )

  if (predefinedDateRange) {
    return predefinedDateRange.text
  } else {
    const startDateIsInThisYear = date.isThisYear(startDate)

    const startDateString = startDateIsInThisYear
      ? date.asDMmmm(startDate)
      : date.asDMmmYyyy(startDate)

    const endDateString = startDateIsInThisYear ? date.asDMmmm(endDate) : date.asDMmmYyyy(endDate)

    return `from ${startDateString} to ${endDateString}`
  }
}

const PerformancePageFeeds = ({
  performance,
  getPerformance,
  date,
  initialDateRange,
  analyticsLoaderStatus,
  enquiriesLoaderStatus,
}) => {
  const renderHero = () => {
    return (
      <ManagePropertiesHero hasPatternBackgroundOnMobile pattern="green">
        <div className={hero.row}>
          <Heading className={hero.heading}>Performance</Heading>
        </div>
      </ManagePropertiesHero>
    )
  }

  const { enquiries, impressions } = performance.overview
  const { properties } = performance
  const hasLiveProperties = properties.length > 0
  const dateOptions = validOptions(date)

  const layoutClasses = classNames(layout.inner, performancePage.layout)

  const [selectedDateRange, setSelectedDateRange] = useState(initialDateRange)

  return (
    <ManagePropertiesPageTemplate
      className={performancePage.page}
      title="Performance | AFS"
      header={<Header />}
      footer={<Footer dark />}
      hero={renderHero()}
    >
      <div className={layoutClasses}>
        {performance.hasIncompleteData && (
          <Notification
            className={performancePage.notification}
            type="warning"
            heading="Incomplete information."
            text="Failed to retrieve some data and the information displayed is either missing or incomplete."
          />
        )}
        {analyticsLoaderStatus.failed && (
          <Notification
            className={performancePage.notification}
            type="error"
            heading="Error."
            text="Failed to retrieve impressions. Please try again later."
          />
        )}
        {config.showPerformanceFilters && (
          <DateRange
            className={performancePage.dateFilterButton}
            date={date}
            initialDateRange={initialDateRange}
            applyFilterCallback={(dateRange) => {
              setSelectedDateRange(dateRange)
              getPerformance(dateRange)
            }}
          />
        )}

        <section className={performancePage.section}>
          <Heading className={performancePage.heading} level={5} data-testid="overview-heading">
            {`Totals for ${getSelectedDatesHeadingText(date, dateOptions, selectedDateRange)}`}
          </Heading>
          <Notification
            className={performancePage.feedNotification}
            type="info"
            text={[
              <InfoIcon className={performancePage.feedNotificationIcon} key={1} />,
              <span key={2}>
                {`The totals shown include statistics from all the properties that were live ${getSelectedDatesNotificationText(
                  date,
                  dateOptions,
                  selectedDateRange
                )}.`}
              </span>,
            ]}
            nonDismissible
          />

          <div className={performancePage.overviewGrid}>
            <PerformanceOverviewCard
              className={performancePage.impressionsCard}
              label="impressions"
              value={impressions}
              loading={analyticsLoaderStatus.waiting}
              showWarning={performance.hasIncompleteData}
              showError={analyticsLoaderStatus.failed}
              tooltipMessage={
                <>
                  The number of times students have{' '}
                  <span className={performancePage.tooltipMessage}>
                    viewed your property listing
                  </span>{' '}
                  pages.
                </>
              }
            />
            <PerformanceOverviewCard
              className={classNames(performancePage.enquiryCard)}
              label="enquiries"
              value={enquiries}
              loading={enquiriesLoaderStatus.waiting}
              tooltipMessage={
                <>
                  The number of times students have{' '}
                  <span className={performancePage.tooltipMessage}>sent an enquiry</span> about one
                  of your properties.
                </>
              }
            />
          </div>
        </section>

        <section className={performancePage.section}>
          <Heading className={performancePage.heading} level={5}>
            Properties currently live
          </Heading>
          {hasLiveProperties ? (
            <PerformanceGrid
              properties={properties}
              analyticsLoaderStatus={analyticsLoaderStatus}
              enquiriesLoaderStatus={enquiriesLoaderStatus}
              isFeedAccount
            />
          ) : (
            <div className={performancePage.noPropertiesMessage}>
              <p className={performancePage.noPropertiesText}>
                There are no properties currently live.
              </p>
            </div>
          )}
        </section>
      </div>
    </ManagePropertiesPageTemplate>
  )
}

PerformancePageFeeds.propTypes = {
  performance: PropTypes.object.isRequired,
  getPerformance: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
  initialDateRange: PropTypes.object.isRequired,
  analyticsLoaderStatus: PropTypes.object.isRequired,
  enquiriesLoaderStatus: PropTypes.object.isRequired,
}

export default PerformancePageFeeds
