import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { getAllFeedProperties } from '../../api/propertyApi'
import { useAuthContext } from '../../authContext'
import { useStateContext } from '../../appStateContext'

import PropertiesPageFeeds from '../pages/PropertiesPageFeeds'
import LoadingPage from '../pages/LoadingPage'
import ErrorPage from '../pages/ErrorPage'
import { notificationService } from '../../container'

export const PropertiesContainerFeeds = ({
  getFeedProperties,
  propertiesPage,
  loadingPage,
  errorPage,
  useAuthContext,
  useStateContext,
}) => {
  const [properties, setProperties] = useState([])
  const [selectedPropertyIds, setSelectedPropertyIds] = useState([])
  const [loading, setLoading] = useState(true)
  const [showError, setShowError] = useState(false)
  const [updatingProperties, setUpdatingProperties] = useState({
    active: false,
    message: '',
  })

  const { token } = useAuthContext()
  const { propertyIds } = useStateContext()

  useEffect(() => {
    getFeedProperties(token).then(response => {
      if (response.success) {
        setProperties(response.properties)
        setSelectedPropertyIds(prevState => [...prevState, ...propertyIds.selected()])
        setLoading(false)
      } else {
        setShowError(true)
      }
    })
  }, [])

  const propertyIsAlreadySelected = propertyId => {
    return selectedPropertyIds.includes(propertyId)
  }

  const deselectProperty = propertyId => {
    propertyIds.deselect(propertyId)
    setSelectedPropertyIds(previousState => previousState.filter(id => id !== propertyId))
  }

  const selectProperty = propertyId => {
    propertyIds.select(propertyId)
    setSelectedPropertyIds(previousState => [...previousState, propertyId])
  }

  const handleSelectProperty = propertyId => {
    if (propertyIsAlreadySelected(propertyId)) {
      deselectProperty(propertyId)
    } else {
      selectProperty(propertyId)
    }
  }

  const showUpdatingOverlay = message => {
    setUpdatingProperties({
      active: true,
      message,
    })
  }

  const hideUpdatingOverlay = () => {
    setUpdatingProperties({
      active: false,
      message: '',
    })
  }

  const deselectAllProperties = () => {
    setSelectedPropertyIds([])
    propertyIds.deselectAll()
  }

  const selectAllProperties = () => {
    const allPropertyIds = properties.map(property => property.id)
    setSelectedPropertyIds(allPropertyIds)
    allPropertyIds.map(propertyId => propertyIds.select(propertyId))
  }

  const clearNotifications = () => {
    notificationService.clearNotifications()
  }

  if (showError) return errorPage()
  if (loading) return loadingPage()

  return propertiesPage(
    properties,
    selectedPropertyIds,
    handleSelectProperty,
    deselectAllProperties,
    selectAllProperties,
    clearNotifications,
    updatingProperties
  )
}

PropertiesContainerFeeds.propTypes = {
  getFeedProperties: PropTypes.func.isRequired,
  propertiesPage: PropTypes.func.isRequired,
  loadingPage: PropTypes.func.isRequired,
  errorPage: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
}

export const PropertiesContainerFeedsCompositionRoot = () => {
  return (
    <PropertiesContainerFeeds
      getFeedProperties={getAllFeedProperties}
      propertiesPage={(
        properties,
        selectedPropertyIds,
        onSelectProperty,
        deselectAllProperties,
        selectAllProperties,
        clearNotifications,
        updatingProperties
      ) => (
        <PropertiesPageFeeds
          properties={properties}
          selectedPropertyIds={selectedPropertyIds}
          onSelectProperty={onSelectProperty}
          deselectAllProperties={deselectAllProperties}
          selectAllProperties={selectAllProperties}
          clearNotifications={clearNotifications}
          updatingProperties={updatingProperties}
        />
      )}
      loadingPage={() => <LoadingPage />}
      errorPage={() => <ErrorPage />}
      useAuthContext={useAuthContext}
      useStateContext={useStateContext}
    />
  )
}
