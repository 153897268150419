import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import useMedia from '../../../hooks/useMedia'
import { config } from '../../../config'

import PageTemplate from '../../templates/PageTemplate'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'

import Picture from '../../atoms/Picture'
import Notification from '../../molecules/Notification'
import Heading from '../../atoms/Heading'
import Link from '../../atoms/Link'
import Button from '../../atoms/Button'

import FormattedDate from '../../../models/formattedDate'

import styles from './notVerifiedPage.module.scss'

import Lightbulb from '../../../svgs/icons/lightbulb.svg'

const imageApiUrl = config.imgApiUrl
const welcomeImageSrc = 'https://afs-assets-public.s3.eu-west-2.amazonaws.com/welcome.png'

export const NotVerifiedPage = ({ today }) => {
  const isTabletOrDesktop = useMedia(['(min-width: 769px)'], [true], false)

  const renderCallTimeMessage = dateTime => {
    if (dateTime.isWithinAfsOpeningHours()) return 'shortly'
    return 'next working day'
  }

  return (
    <PageTemplate
      className={styles.layout}
      header={<Header />}
      footer={<Footer />}
      title="Before You Can Advertise | AFS"
    >
      {isTabletOrDesktop && (
        <div className={styles.leftColumn}>
          <Picture
            alt="Welcome"
            src={welcomeImageSrc}
            lazyload={false}
            sources={[
              {
                id: 'tablet',
                src: `${imageApiUrl}w=400/${welcomeImageSrc}`,
                media: '(min-width: 769px) and (max-width: 960px)',
              },
              {
                id: 'desktop',
                src: `${imageApiUrl}w=522/${welcomeImageSrc}`,
                media: '(min-width: 961px)',
              },
            ]}
          />
        </div>
      )}
      <div className={styles.rightColumn}>
        <Heading className={styles.heading} level={1}>
          Before you can advertise
        </Heading>
        <p className={styles.subheading}>
          To ensure a high level of trustworthiness on our platform, we verify every account before
          properties can be advertised.
        </p>
        <p className={styles.text}>
          <strong>We’ll call you {renderCallTimeMessage(today)}</strong> to verify your account and
          get you set up – so you can get your properties in front of thousands of students as soon
          as possible.
        </p>

        <Notification
          className={styles.notification}
          type="info"
          text={[
            <Lightbulb className={styles.icon} key={0} />,
            <span className={styles.smallText} key={1}>
              On average, properties advertised with us receive <strong>16 enquiries.</strong>
            </span>,
          ]}
          nonDismissible
        />

        <p className={styles.boldText}>Don't want to wait? Call us now</p>
        <Link className={styles.phone} href="tel:+448000789659" target="_blank">
          0800 078 9659
        </Link>

        <p className={styles.boldText}>Office hours:</p>
        <p className={styles.normalText}>Monday to Friday, 09:00 – 17:00 </p>

        <div className={styles.buttons}>
          <Button className={styles.button} to="/landlord-admin/add-property" primary>
            Add another property
          </Button>
          <Button className={styles.button} to="/landlord-admin/properties">
            View all properties
          </Button>
        </div>
      </div>
    </PageTemplate>
  )
}

NotVerifiedPage.propTypes = {
  today: PropTypes.object.isRequired,
}

const NotVerifiedPageCompositionRoot = () => {
  const today = new FormattedDate(moment(), 'YYYY-MM-DD HH:mm:ss')
  return <NotVerifiedPage today={today} />
}

export default NotVerifiedPageCompositionRoot
