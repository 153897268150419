import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

import { config } from '../../../config'

import LinkButton from '../../atoms/LinkButton'
import Button from '../../atoms/Button'
import Heading from '../../atoms/Heading'

import styles from './testimonialRequestPreviewPage.module.scss'

const TestimonialRequestPreviewPage = ({ location }) => {
  const closePreview = () => {
    window.close()
  }

  return (
    <>
      <Helmet>
        <title>Preview Testimonial Request | AFS</title>
      </Helmet>
      <main className={styles.page}>
        <div className={styles.header}>
          <LinkButton className={styles.closeLink} onClick={closePreview}>
            Close this window
          </LinkButton>
          <Heading className={styles.heading} level={3}>
            The student will receive an email that looks like this:
          </Heading>
        </div>
        <iframe
          className={styles.iframe}
          frameBorder="0"
          title="Testimonial preview"
          src={`${config.mainSiteBaseUrl}static/testimonial-request-preview.html${location.search}`}
          data-testid="iframe"
        />
        <div className={styles.footer}>
          <Button className={styles.closeButton} secondaryOrange onClick={closePreview}>
            Close this window
          </Button>
        </div>
      </main>
    </>
  )
}

TestimonialRequestPreviewPage.propTypes = {
  location: PropTypes.object,
}

export default TestimonialRequestPreviewPage
