import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Bed from '../../../svgs/icons/bed-size.svg'
import Bath from '../../../svgs/icons/bath.svg'

import roomCount from './roomCount.module.scss'

const PropertyRoomCount = ({ className, count, bathrooms }) => (
  <div className={classNames(roomCount.wrapper, className)}>
    <span className={roomCount.icon}>{bathrooms ? <Bath /> : <Bed />}</span>
    <span
      className={roomCount.number}
      data-testid={bathrooms ? 'bathrooms-count' : 'bedrooms-count'}
    >
      {count}
    </span>
  </div>
)

PropertyRoomCount.propTypes = {
  className: PropTypes.string,
  count: PropTypes.string.isRequired,
  bathrooms: PropTypes.bool,
}

export default PropertyRoomCount
