import React from 'react'
import { updateAccountName, updateAccountPersonalPhone } from '../../../api/accountApi'

import NameForm from '../../organisms/AccountSettingsForms/NameForm'
import PersonalPhoneNumberForm from '../../organisms/AccountSettingsForms/PersonalPhoneNumberForm'

export default [
  {
    name: 'name',
    heading: 'Name',
    renderForm: ({ ...props }) => <NameForm {...props} />,
    renderValue: account => <p>{account.fullNameString() || '-'}</p>,
    saveAction: updateAccountName,
    expanded: false,
    disabled: false,
    loading: false,
  },
  {
    name: 'personalPhoneNumber',
    heading: 'Phone number',
    renderForm: ({ ...props }) => <PersonalPhoneNumberForm {...props} />,
    renderValue: account => <p>{account.personalPhoneNumberString() || '-'}</p>,
    saveAction: updateAccountPersonalPhone,
    expanded: false,
    disabled: false,
    loading: false,
  },
]
