import React from 'react'

import TwoColumnPageTemplate from '../../templates/TwoColumnPageTemplate'
import Header from '../../organisms/Header'
import FormPageHeading from '../../molecules/FormPageHeading'
import Button from '../../atoms/Button'
import Notification from '../../molecules/Notification'
import Link from '../../atoms/Link'

import './styles.scss'

const PublishErrorPage = () => {
  return (
    <TwoColumnPageTemplate
      header={<Header />}
      title="Landlord Admin | AFS"
      className="publish-error-page"
      leftColumn={
        <div>
          <Notification
            className="publish-error__notification"
            type="error"
            text="The property was not saved."
            heading="Error!"
          />
          <div className="publish-error">
            <FormPageHeading heading="Something is not right" className="publish-error__heading" />
            <div className="publish-error__subheading">
              <p>
                We have encountered an unexpected error on our end. Our engineers have been
                notified.
              </p>
              <p>
                {'Please try again in a few minutes. If this error persists, please '}
                <Link href="/info/contactus.asp">contact us</Link>.
              </p>
            </div>
            <Button to="/landlord-admin/add-property" primary dark>
              Add new property
            </Button>
            <Link className="publish-error__link" to="/landlord-admin">
              Go to Dashboard
            </Link>
          </div>
        </div>
      }
    />
  )
}

export default PublishErrorPage
