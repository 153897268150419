import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { useAuthContext } from '../../authContext'
import { getSelectedUnadvertisedProperties } from '../../api/propertyApi'
import { useStateContext } from '../../appStateContext'
import { notificationService } from '../../container'

import LoadingPage from '../pages/LoadingPage'
import AdvertisementOptionsPage from '../pages/AdvertisementOptionsPage'

export const AdvertisementOptionsPageContainer = ({
  advertisementOptionsPage,
  getProperties,
  today,
  history,
  useAuthContext,
  useStateContext,
  notificationService,
}) => {
  const [containerProperties, setContainerProperties] = useState([])
  const [loading, setLoading] = useState(true)

  const { token } = useAuthContext()
  const { propertyIds } = useStateContext()

  const deselectProperty = deselectedPropertyId => {
    propertyIds.deselect(deselectedPropertyId)
    const selectedProperties = containerProperties.filter(x => x.id !== deselectedPropertyId)
    setContainerProperties(selectedProperties)
    notificationService.clearNotifications()
  }

  const handleError = () => {
    propertyIds.deselectAll()
    notificationService.addDeferredErrorNotification(
      'Error.',
      'Something went wrong. Please try again later.'
    )
    history.push('/landlord-admin/properties')
  }

  useEffect(() => {
    if (propertyIds.noneSelected()) {
      history.push('/landlord-admin/properties')
      return
    }

    getProperties(propertyIds.selected(), token).then(response => {
      if (!response.success) {
        handleError()
      }

      setLoading(false)

      const allPropertiesNotFound =
        response.notFound &&
        response.notFound.length > 0 &&
        response.properties.length === 0 &&
        response.alreadyAdvertisedPropertyIds.length === 0

      const somePropertiesNotFound =
        response.notFound &&
        response.notFound.length > 0 &&
        (response.properties.length > 0 || response.alreadyAdvertisedPropertyIds.length > 0)

      const somePropertiesAlreadyAdvertised =
        !response.allPropertiesAreAdvertised &&
        response.alreadyAdvertisedPropertyIds &&
        response.alreadyAdvertisedPropertyIds.length > 0

      if (allPropertiesNotFound) {
        propertyIds.deselectAll()

        const notificationText =
          response.notFound.length > 1
            ? 'These properties could not be found.'
            : 'This property could not be found.'
        notificationService.addDeferredErrorNotification('Error.', notificationText)
        history.push('/landlord-admin/properties')
      }

      if (somePropertiesNotFound && somePropertiesAlreadyAdvertised) {
        const { notFound, alreadyAdvertisedPropertyIds } = response
        notFound.map(id => propertyIds.deselect(id))
        alreadyAdvertisedPropertyIds.map(id => propertyIds.deselect(id))

        const numberOfOmittedProperties = notFound.length + alreadyAdvertisedPropertyIds.length

        const notificationText = `${numberOfOmittedProperties} of these properties are already advertised or could not be found. They have been omitted from this transaction.`

        setContainerProperties(response.properties)
        notificationService.showWarningNotification('', notificationText)

        return
      }

      if (somePropertiesNotFound) {
        const { notFound } = response
        notFound.map(id => propertyIds.deselect(id))

        const notificationText =
          notFound.length > 1
            ? `${notFound.length} of these properties could not be found. They have been omitted from this transaction.`
            : `1 of these properties could not be found. It has been omitted from this transaction.`

        setContainerProperties(response.properties)
        notificationService.showErrorNotification('', notificationText)
      }

      if (response.allPropertiesAreAdvertised) {
        propertyIds.deselectAll()

        const numberOfAlreadyAdvertisedProperties = response.alreadyAdvertisedPropertyIds.length
        notificationService.addDeferredWarningNotification(
          '',
          numberOfAlreadyAdvertisedProperties > 1
            ? 'These properties are already being advertised.'
            : 'This property is already being advertised.'
        )
        history.push('/landlord-admin/properties')
      }

      if (somePropertiesAlreadyAdvertised) {
        const { alreadyAdvertisedPropertyIds } = response
        alreadyAdvertisedPropertyIds.map(id => propertyIds.deselect(id))

        const notificationText =
          alreadyAdvertisedPropertyIds.length > 1
            ? `${alreadyAdvertisedPropertyIds.length} of these properties are already advertised. They have been omitted from this transaction.`
            : `1 of these properties is already advertised. It has been omitted from this transaction.`

        setContainerProperties(response.properties)
        notificationService.showWarningNotification('', notificationText)
      } else {
        setContainerProperties(response.properties)
      }
    })
  }, [])

  if (loading) return <LoadingPage />

  return advertisementOptionsPage(
    history,
    today,
    containerProperties,
    deselectProperty,
    handleError
  )
}

AdvertisementOptionsPageContainer.propTypes = {
  advertisementOptionsPage: PropTypes.func.isRequired,
  getProperties: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
  notificationService: PropTypes.object.isRequired,
}

export const AdvertisementOptionsPageContainerCompositionRoot = ({ history }) => {
  return (
    <AdvertisementOptionsPageContainer
      advertisementOptionsPage={(
        history,
        today,
        selectedProperties,
        deselectProperty,
        handleError
      ) => (
        <AdvertisementOptionsPage
          history={history}
          today={today}
          selectedProperties={selectedProperties}
          deselectProperty={deselectProperty}
          handleError={handleError}
        />
      )}
      getProperties={getSelectedUnadvertisedProperties}
      today={moment()}
      history={history}
      useAuthContext={useAuthContext}
      useStateContext={useStateContext}
      notificationService={notificationService}
    />
  )
}

AdvertisementOptionsPageContainerCompositionRoot.propTypes = {
  history: PropTypes.object,
}
