import React from 'react'
import PropTypes from 'prop-types'
import Overlay from '@afs/components/Overlay'

import Button from '../../../../atoms/Button'
import Notification from '../../../../molecules/Notification'

import styles from './deleteTestimonialOverlay.module.scss'

const DeleteTestimonialOverlay = ({
  isActive,
  handleCloseOverlay,
  handleDeleteTestimonial,
  showError,
}) => {
  return (
    <Overlay
      id="delete-testimonial"
      heading="Delete testimonial"
      isActive={isActive}
      onClose={handleCloseOverlay}
      closeLabel="Close"
      preserveScrollPositionOnClose
      dontDisplayNotifications
    >
      <p className={styles.confirmationText}>Are you sure you want to delete this testimonial?</p>
      {showError && (
        <Notification
          type="error"
          heading="Error."
          text="This testimonial could not be deleted. Please try again later."
        />
      )}
      <div className={styles.buttons}>
        <Button
          className={styles.deleteButton}
          onClick={handleDeleteTestimonial}
          deletion
          data-testid="delete-button"
        >
          Delete testimonial
        </Button>
        <Button
          className={styles.cancelButton}
          onClick={handleCloseOverlay}
          data-testid="cancel-button"
        >
          Cancel
        </Button>
      </div>
    </Overlay>
  )
}

DeleteTestimonialOverlay.propTypes = {
  isActive: PropTypes.bool.isRequired,
  handleCloseOverlay: PropTypes.func.isRequired,
  handleDeleteTestimonial: PropTypes.func.isRequired,
  showError: PropTypes.bool.isRequired,
}

export default DeleteTestimonialOverlay
