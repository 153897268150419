import React from 'react'
import FieldMultiSelect from '../FieldMultiSelect'
import './styles.scss'

const FieldCategoryMultiSelect = ({ categories, onSelectOption, selectedOptions }) => {
  const addOptionToSelectedOptions = (selectedOption, alreadySelectedOptions) => {
    return [...alreadySelectedOptions, selectedOption]
  }

  const removeOptionFromSelectedOptions = (selectedOption, alreadySelectedOptions) => {
    return alreadySelectedOptions.filter(option => option !== selectedOption)
  }
  return categories.map(category => {
    return (
      <FieldMultiSelect
        key={`${category.title}-${category.options}`}
        className="field-category-multi-select__category"
        legend={category.title}
        options={category.options}
        selectedOptions={selectedOptions.find(c => c.title === category.title).options}
        onClick={event => {
          const { checked } = event.target
          const optionWasChecked = checked === true
          const optionWasUnchecked = checked === false
          const selectedOption = event.target.value
          const categoryForSelectedOption = selectedOptions.find(c => c.title === category.title)

          const optionsAlreadySelectedInCategory = categoryForSelectedOption
            ? categoryForSelectedOption.options
            : []

          let updatedOptionsForCategory

          if (optionWasChecked) {
            updatedOptionsForCategory = addOptionToSelectedOptions(
              selectedOption,
              optionsAlreadySelectedInCategory
            )
          }

          if (optionWasUnchecked) {
            updatedOptionsForCategory = removeOptionFromSelectedOptions(
              selectedOption,
              optionsAlreadySelectedInCategory
            )
          }

          const allCategoriesExceptOneForSelectedOption = selectedOptions.filter(
            c => c.title !== category.title
          )

          const updatedCategoryForSelectedOption = {
            ...category,
            options: updatedOptionsForCategory,
          }

          const updatedAllCategories = [
            ...allCategoriesExceptOneForSelectedOption,
            updatedCategoryForSelectedOption,
          ]

          onSelectOption(updatedAllCategories)
        }}
      />
    )
  })
}

export default FieldCategoryMultiSelect
