import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { getUnadvertisedProperties } from '../../api/propertyApi'
import { useAuthContext } from '../../authContext'
import { useStateContext } from '../../appStateContext'

import LoadingPage from '../pages/LoadingPage'
import ErrorPage from '../pages/ErrorPage'
import AdvertisePropertiesPage from '../pages/AdvertisePropertiesPage'

export const AdvertisePropertiesContainer = ({
  advertisePropertiesPage,
  getUnadvertiedProperties,
  useAuthContext,
  useStateContext,
  history,
}) => {
  const [loading, setLoading] = useState(true)
  const [properties, setProperties] = useState([])
  const [selectedPropertyIds, setSelectedPropertyIds] = useState([])
  const [serverError, setServerError] = useState(false)

  const { token } = useAuthContext()
  const { propertyIds } = useStateContext()

  useEffect(() => {
    getUnadvertiedProperties(token).then(response => {
      if (response.success) {
        setProperties(response.properties)
        setSelectedPropertyIds(propertyIds.selected())
        setLoading(false)
      } else {
        setServerError(true)
      }
    })
  }, [])

  const toggleSelectProperty = propertyId => {
    if (propertyIds.selected().includes(propertyId)) {
      propertyIds.deselect(propertyId)

      const updatedPropertyIds = [...propertyIds.selected()]
      setSelectedPropertyIds(updatedPropertyIds)
    } else {
      propertyIds.select(propertyId)

      const updatedPropertyIds = [...propertyIds.selected()]
      setSelectedPropertyIds(updatedPropertyIds)
    }
  }

  const deselectAllProperties = () => {
    setSelectedPropertyIds([])
    propertyIds.deselectAll()
  }

  const selectAllProperties = () => {
    const allPropertyIds = properties.map(property => property.id)
    setSelectedPropertyIds(allPropertyIds)
    allPropertyIds.map(propertyId => propertyIds.select(propertyId))
  }

  if (serverError) return <ErrorPage />
  if (loading) return <LoadingPage />

  return advertisePropertiesPage(
    properties,
    selectedPropertyIds,
    toggleSelectProperty,
    deselectAllProperties,
    selectAllProperties,
    history
  )
}

AdvertisePropertiesContainer.propTypes = {
  advertisePropertiesPage: PropTypes.func.isRequired,
  getUnadvertiedProperties: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
  useStateContext: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

export const AdvertisePropertiesContainerCompositionRoot = ({ history }) => {
  return (
    <AdvertisePropertiesContainer
      advertisePropertiesPage={(
        properties,
        selectedPropertyIds,
        toggleSelectProperty,
        deselectAllProperties,
        selectAllProperties,
        history
      ) => (
        <AdvertisePropertiesPage
          properties={properties}
          selectedPropertyIds={selectedPropertyIds}
          toggleSelectProperty={toggleSelectProperty}
          deselectAllProperties={deselectAllProperties}
          selectAllProperties={selectAllProperties}
          history={history}
        />
      )}
      getUnadvertiedProperties={getUnadvertisedProperties}
      useStateContext={useStateContext}
      useAuthContext={useAuthContext}
      history={history}
    />
  )
}

AdvertisePropertiesContainerCompositionRoot.propTypes = {
  history: PropTypes.object.isRequired,
}
