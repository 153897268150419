const isYoutubeUrlWithClassicDomain = (url, host) =>
  host === 'youtube.com' && !url.includes('/shorts/')

const isYoutubeUrlForVideoShort = (url, host) => host === 'youtube.com' && url.includes('/shorts/')

const isYoutubeUrlWithShortenedDomain = (host) => host === 'youtu.be'

const classicDomainPathDoesNotContainAValidVideoQuery = (path) => {
  return !path.includes('watch?v=')
}

const getYoutubeVideoIdFromQueryParameters = (path) => {
  return path.replace('watch?v=', '').replace('v=', '').split('&')[0]
}

const getYoutubeVideoIdFromSlashSeparatedUrl = (path) => path.split('?')[0]

const hostDoesNotContainAValidYoutubeDomain = (url, host) => {
  if (
    isYoutubeUrlForVideoShort(url, host) ||
    isYoutubeUrlWithClassicDomain(url, host) ||
    isYoutubeUrlWithShortenedDomain(host)
  )
    return false

  return true
}

const urlDoesNotContainPath = (url) => {
  const urlWithoutProtocol = url.replace('http://', '').replace('https://', '')
  return !urlWithoutProtocol.includes('/')
}

const urlDoesNotContainAValidUrlDomain = (url) => {
  return !url.includes('youtube.com') && !url.includes('youtu.be')
}

const youtubeVideoIdLength = 11

const getVideoId = (url, host, path) => {
  if (isYoutubeUrlForVideoShort(url, host) || isYoutubeUrlWithShortenedDomain(host)) {
    return getYoutubeVideoIdFromSlashSeparatedUrl(path)
  }

  return getYoutubeVideoIdFromQueryParameters(path)
}

const youtubeUrlValidator = (url) => {
  if (
    typeof url === 'undefined' ||
    urlDoesNotContainAValidUrlDomain(url) ||
    urlDoesNotContainPath(url)
  ) {
    return {
      isValid: false,
      reason: 'Invalid YouTube URL.',
    }
  }

  const urlParts = url.replace('http://', '').replace('https://', '').replace('www.', '').split('.')

  const domain = urlParts[0]
  const extension = urlParts[1].split('/')[0]
  const host = `${domain}.${extension}`

  const segments = urlParts[1].split('/')

  const path = segments[segments.length - 1]

  if (hostDoesNotContainAValidYoutubeDomain(url, host, path))
    return {
      isValid: false,
      reason: 'Invalid YouTube URL.',
    }

  if (
    isYoutubeUrlWithClassicDomain(url, host) &&
    classicDomainPathDoesNotContainAValidVideoQuery(path)
  )
    return {
      isValid: false,
      reason: 'Invalid YouTube URL.',
    }

  const videoId = getVideoId(url, host, path)

  if (videoId === '')
    return {
      isValid: false,
      reason: 'URL does not contain a video id.',
    }

  if (videoId.length !== youtubeVideoIdLength)
    return {
      isValid: false,
      reason: 'Invalid video id. A YouTube video id is 11 characters in length.',
    }

  return {
    isValid: true,
    videoId: videoId,
  }
}

export default youtubeUrlValidator
