import React from 'react'
import { Switch, Redirect } from 'react-router'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'

import { HomePageContainerCompositionRoot } from './components/containers/HomePageContainer'
import AdminLoginPageContainer from './components/containers/AdminLoginPageContainer'
import RegisterThankYouPage from './components/pages/RegisterThankYouPage'
import LandlordsLoginPageContainer from './components/containers/LandlordsLoginPageContainer'
import ForgottenPasswordPage from './components/pages/ForgottenPasswordPage'
import ResetPasswordPage from './components/pages/ResetPasswordPage'
import VerifyEmailPage from './components/pages/VerifyEmailPage'
import WelcomeToNewLandlordAdminPageContainer from './components/containers/WelcomeToNewLandlordAdminPageContainer'
import NewLandlordAdminPasswordResetPageContainer from './components/containers/NewLandlordAdminPasswordResetPageContainer'
import AddPropertyFormPageContainer from './components/containers/AddPropertyFormPageContainer'
import EditPropertyFormPage from './components/pages/EditPropertyFormPage'
import PropertyDetailsContainer from './components/containers/PropertyDetailsContainer'
import { PropertiesContainerCompositionRoot } from './components/containers/PropertiesContainer'
import PublishPropertyContainer from './components/containers/PublishPropertyContainer'
import { AdvertisementOptionsPageContainerCompositionRoot } from './components/containers/AdvertisementOptionsPageContainer'
import CheckoutPageContainer from './components/containers/CheckoutPageContainer'
import { PaymentSuccessPageContainerCompositionRoot } from './components/containers/PaymentSuccessPageContainer'
import RegisterLandingPage from './components/pages/RegisterLandingPage'
import LoginLandingPage from './components/pages/LoginLandingPage'
import SelectAccountPage from './components/pages/SelectAccountPage'
import PublishErrorPage from './components/pages/PublishErrorPage'
import EnquiriesContainer from './components/containers/EnquiriesContainer'
import { MultiplePropertiesAvailabilityContainerCompositionRoot } from './components/containers/MultiplePropertiesAvailabilityContainer'
import { MultiplePropertiesBoostContainerCompositionRoot } from './components/containers/MultiplePropertiesBoostContainer'
import { MultipleFeedPropertiesBoostContainerCompositionRoot } from './components/containers/MultipleFeedPropertiesBoostContainer'
import PropertyNotFoundPage from './components/pages/PropertyNotFoundPage'
import NotFoundPage from './components/pages/NotFoundPage'
import { AccountContainerCompositionRoot } from './components/containers/AccountContainer'
import PublicProfileSettingsPage from './components/pages/PublicProfileSettingsPage'
import PersonalInfoSettingsPage from './components/pages/PersonalInfoSettingsPage'
import LoginAndSecuritySettingsPage from './components/pages/LoginAndSecuritySettingsPage'
import { PaymentSettingsPageContainerCompositionRoot } from './components/containers/PaymentSettingsPageContainer'
import LettingAgencyDetailsSettingsPage from './components/pages/LettingAgencyDetailsSettingsPage'
import MainPersonOfContactSettingsPage from './components/pages/MainPersonOfContactSettingsPage'
import NotificationsSettingsPage from './components/pages/NotificationsSettingsPage'
import ErrorPage from './components/pages/ErrorPage'
import { PdfContainerCompositionRoot } from './components/containers/PdfContainer'
import { InvoicesContainerCompositionRoot } from './components/containers/InvoicesContainer'
import {
  PerformanceContainerCompositionRoot,
  PerformanceContainerFeedsCompositionRoot,
} from './components/containers/PerformanceContainer'
import { BuyBoostCreditsPage } from './components/pages/BuyBoostCreditsPage'
import { TestimonialsContainerCompositionRoot } from './components/containers/TestimonialsContainer'
import { SubmitTestimonialContainerCompositionRoot } from './components/containers/SubmitTestimonialContainer'
import TestimonialRequestPreviewPage from './components/pages/TestimonialRequestPreviewPage'
import { BoostPropertiesContainerCompositionRoot } from './components/containers/BoostPropertiesContainer'
import {
  PaymentMethodContainerCompositionRoot,
  ReadvertisePropertiesContainerCompositionRoot,
} from './components/containers/PaymentMethodContainer'
import { UseAdvertiseCreditsContainerCompositionRoot } from './components/containers/UseAdvertiseCreditsContainer'
import { AdvertisePropertiesContainerCompositionRoot } from './components/containers/AdvertisePropertiesContainer'
import {
  LandlordRegisterContainerCompositionRoot,
  LettingAgentRegisterContainerCompositionRoot,
} from './components/containers/AccountRegisterContainer'
import { PropertiesContainerFeedsCompositionRoot } from './components/containers/PropertiesContainerFeeds'
import { FeedAccountContainerCompositionRoot } from './components/containers/FeedAccountContainer'
import { EnquiriesContainerFeedsCompositionRoot } from './components/containers/EnquiriesContainerFeeds'
import NotVerifiedPageCompositionRoot from './components/pages/NotVerifiedPage'
import BillsOptionalAddUtilityBillsPage from './components/pages/BillsOptionalAddUtilityBillsPage'
import { BillsOptionalSelectPropertiesPageContainerCompositionRoot } from './components/containers/BillsOptionalSelectPropertiesPageContainer'
import { BillsOptionalYourQuotesPageContainerCompositionRoot } from './components/containers/BillsOptionalYourQuotesPageContainer'
import BillsOptionalThankYouPage from './components/pages/BillsOptionalThankYouPage'
import { BillsOptionalPropertiesPageContainerCompositionRoot } from './components/containers/BillsOptionalPropertiesPageContainer'

export default (
  <Switch>
    <PrivateRoute path="/" exact component={HomePageContainerCompositionRoot} />
    <PrivateRoute path="/landlord-admin" exact component={HomePageContainerCompositionRoot} />
    <PrivateRoute path="/landlord-admin/buy-boost-credits" component={BuyBoostCreditsPage} exact />
    <PrivateRoute
      path="/landlord-admin/advertisement-options"
      component={AdvertisementOptionsPageContainerCompositionRoot}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/readvertise-properties"
      component={ReadvertisePropertiesContainerCompositionRoot}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/invoices/:filename"
      component={PdfContainerCompositionRoot}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/invoices"
      component={InvoicesContainerCompositionRoot}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/not-verified"
      component={NotVerifiedPageCompositionRoot}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/payment-method"
      component={PaymentMethodContainerCompositionRoot}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/use-advertise-credits"
      component={UseAdvertiseCreditsContainerCompositionRoot}
      exact
    />
    <PrivateRoute path="/landlord-admin/checkout" component={CheckoutPageContainer} exact />
    <PrivateRoute
      path="/landlord-admin/payment-success"
      component={PaymentSuccessPageContainerCompositionRoot}
      exact
    />
    <PublicRoute path="/register" component={RegisterLandingPage} exact />
    <PublicRoute path="/login" component={LoginLandingPage} exact />
    <PublicRoute path="/landlord-admin/admin-login" component={AdminLoginPageContainer} exact />
    <PublicRoute path="/select-account" component={SelectAccountPage} exact />
    <PublicRoute path="/thank-you" component={RegisterThankYouPage} exact />
    <PublicRoute path="/forgotten-password" component={ForgottenPasswordPage} exact />
    <PublicRoute path="/reset-password" component={ResetPasswordPage} exact />
    <PublicRoute path="/verify-email" component={VerifyEmailPage} exact />
    <PublicRoute
      path="/set-new-password"
      component={NewLandlordAdminPasswordResetPageContainer}
      exact
    />
    <PublicRoute
      path="/welcome-to-new-landlord-admin"
      component={WelcomeToNewLandlordAdminPageContainer}
      exact
    />
    <PublicRoute
      path="/submit-testimonial"
      component={SubmitTestimonialContainerCompositionRoot}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/properties/availability"
      component={MultiplePropertiesAvailabilityContainerCompositionRoot}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/properties/boost"
      component={MultiplePropertiesBoostContainerCompositionRoot}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/feeds/properties/boost"
      component={MultipleFeedPropertiesBoostContainerCompositionRoot}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/boost-properties"
      component={BoostPropertiesContainerCompositionRoot}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/advertise-properties"
      component={AdvertisePropertiesContainerCompositionRoot}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/feeds/properties"
      component={PropertiesContainerFeedsCompositionRoot}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/properties"
      component={PropertiesContainerCompositionRoot}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/feeds/enquiries"
      component={EnquiriesContainerFeedsCompositionRoot}
      exact
    />
    <PrivateRoute path="/landlord-admin/enquiries" component={EnquiriesContainer} exact />
    <PrivateRoute
      path="/landlord-admin/feeds/performance"
      component={PerformanceContainerFeedsCompositionRoot}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/performance"
      component={PerformanceContainerCompositionRoot}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/testimonials"
      component={TestimonialsContainerCompositionRoot}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/preview-testimonial-request"
      component={TestimonialRequestPreviewPage}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/bills-optional/properties"
      component={BillsOptionalPropertiesPageContainerCompositionRoot}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/bills-optional/select-properties"
      component={BillsOptionalSelectPropertiesPageContainerCompositionRoot}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/bills-optional/quotes"
      component={BillsOptionalYourQuotesPageContainerCompositionRoot}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/bills-optional/thank-you"
      component={BillsOptionalThankYouPage}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/bills-optional"
      component={BillsOptionalAddUtilityBillsPage}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/property/:id/:action"
      component={PropertyDetailsContainer}
      exact
    />
    <PrivateRoute path="/landlord-admin/property/:id" component={PropertyDetailsContainer} exact />
    <PrivateRoute
      path="/landlord-admin/property/:id/edit/:path"
      component={EditPropertyFormPage}
      exact
    />
    <PrivateRoute path="/landlord-admin/property/:id/*" component={PropertyNotFoundPage} />
    <PrivateRoute
      path="/landlord-admin/add-property/advertise/:id"
      component={PublishPropertyContainer}
      exact
    />
    <PrivateRoute path="/landlord-admin/add-property/error" component={PublishErrorPage} exact />
    <PrivateRoute
      path="/landlord-admin/add-property/:path"
      component={AddPropertyFormPageContainer}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/add-property"
      component={() => <Redirect to="/landlord-admin/add-property/address" />}
    />
    <PrivateRoute
      path="/landlord-admin/account"
      component={AccountContainerCompositionRoot}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/account/public-profile"
      component={PublicProfileSettingsPage}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/account/personal-info"
      component={PersonalInfoSettingsPage}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/account/login-and-security"
      component={LoginAndSecuritySettingsPage}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/account/payments"
      component={PaymentSettingsPageContainerCompositionRoot}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/account/letting-agency-details"
      component={LettingAgencyDetailsSettingsPage}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/account/main-person-of-contact"
      component={MainPersonOfContactSettingsPage}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/account/notifications"
      component={NotificationsSettingsPage}
      exact
    />
    <PrivateRoute
      path="/landlord-admin/feeds/account"
      component={FeedAccountContainerCompositionRoot}
      exact
    />
    <PublicRoute
      path="/landlord-admin/register"
      component={LandlordRegisterContainerCompositionRoot}
      exact
    />
    <PublicRoute
      path="/landlord-admin/letting-agent/register"
      component={LettingAgentRegisterContainerCompositionRoot}
      exact
    />
    <PublicRoute path="/landlord-admin/login" component={LandlordsLoginPageContainer} exact />
    <PrivateRoute path="/landlord-admin/not-found" component={NotFoundPage} />
    <PrivateRoute path="/landlord-admin/error" component={ErrorPage} />
    <PublicRoute component={NotFoundPage} />
  </Switch>
)
