/* eslint-disable react/prop-types */
import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'
import SubmitButton from '../../../atoms/SubmitButton'

import { AccommodationTypePage } from '../../FormPages/AccommodationTypePage'
import { LettingTypePage } from '../../FormPages/LettingTypePage'
import { PhotosPage } from '../../FormPages/PhotosPage'

import { BathroomsPage, bathroomsPageValidation } from '../../FormPages/BathroomsPage'

import {
  AvailabilityPage,
  availabilityPageValidation,
} from '../../FormPages/AvailabilityPage/AvailabilityPage'
import BillsPage from '../../FormPages/BillsPage'
import AmenitiesPage from '../../FormPages/AmenitiesPage'
import {
  DescriptionPage,
  descriptionPageValidation,
} from '../../FormPages/DescriptionPage/DescriptionPage'
import {
  BedroomsPage,
  bedroomsPageValidation,
  renderBedroomPageSubHeading,
} from '../../FormPages/BedroomsPage'

import { AddressPage, addressPageValidation } from '../../FormPages/AddressPage'
import { VideoTourPage, videoTourPageValidation } from '../../FormPages/VideoTourPage/VideoTourPage'
import YouTubeVideoEmbed from '../../../atoms/YoutubeVideoEmbed'

const Error = ({ name }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) => (touched && error ? <span>{error}</span> : null)}
  />
)

Error.propTypes = {
  name: PropTypes.string,
}

const alwaysValidateToTrue = () => {
  return {}
}

export const pageIds = {
  address: 0,
  lettingType: 1,
  availability: 2,
  accommodationType: 3,
  bedrooms: 4,
  bathrooms: 5,
  amenities: 6,
  bills: 7,
  photos: 8,
  videoTour: 9,
  description: 10,
}

export const pageConfig = [
  {
    id: pageIds.accommodationType,
    component: () => <AccommodationTypePage />,
    route: '/landlord-admin/add-property/accommodation-type',
    validate: alwaysValidateToTrue,
    isValid: false,
    heading: 'Accommodation type',
    subheading: 'Please select the accommodation type for your property.',
    button: values => {
      const validAccommodationTypeSelected = ['Studio', 'House', 'Flat'].find(
        type => type === values.accommodationType
      )
      return validAccommodationTypeSelected ? (
        <SubmitButton data-testid="continue-button">Continue</SubmitButton>
      ) : null
    },
  },
  {
    id: pageIds.lettingType,
    component: () => <LettingTypePage />,
    route: '/landlord-admin/add-property/letting-type',
    validate: alwaysValidateToTrue,
    isValid: false,
    heading: 'How do you want to let the property?',
    subheading: '',
    button: values => {
      if (values.lettingType === '') return null
      return <SubmitButton data-testid="continue-button">Continue</SubmitButton>
    },
  },
  {
    id: pageIds.photos,
    component: () => <PhotosPage />,
    route: '/landlord-admin/add-property/photos',
    validate: alwaysValidateToTrue,
    isValid: false,
    heading: 'Photos',
    subheading:
      'Photos help students get a better idea about your property. Better photos lead to more enquiries.',
    button: ({ photos }) => {
      if (photos.loading) return null

      const hasPhotos = photos.images.items.length > 0

      const label = !hasPhotos ? 'Continue without photos' : 'Continue'
      const className = !hasPhotos ? 'form-navigation__link-button' : undefined

      return (
        <SubmitButton key="link-button" className={className} data-testid="continue-button">
          {label}
        </SubmitButton>
      )
    },
  },
  {
    id: pageIds.videoTour,
    component: values => (
      <VideoTourPage
        videoTourUrl={values.videoTourUrl}
        videoEmbedComponent={(className, videoId) => (
          <YouTubeVideoEmbed className={className} videoId={videoId} />
        )}
        showNotification
      />
    ),
    route: '/landlord-admin/add-property/video-tour',
    validate: ({ videoTourUrl }) => videoTourPageValidation(videoTourUrl),
    isValid: false,
    heading: 'Video tour',
    subheading:
      'If you have a video of your property on YouTube, you can simply paste the link in the field below.',
    button: ({ videoTourUrl }) => {
      const urlIsInvalid = !!videoTourPageValidation(videoTourUrl).videoTourUrl
      const urlIsEmpty = !videoTourUrl

      const label = urlIsEmpty ? 'Continue without' : 'Continue'

      if (urlIsInvalid && !urlIsEmpty) return null
      return <SubmitButton data-testid="continue-button">{label}</SubmitButton>
    },
  },
  {
    id: pageIds.availability,
    component: () => <AvailabilityPage />,
    route: '/landlord-admin/add-property/availability',
    validate: values => availabilityPageValidation(values),
    isValid: false,
    heading: 'Property availability',
    subheading: "Specify the 'Move in' and 'Move out' dates for this property.",
    button: () => <SubmitButton data-testid="continue-button">Continue</SubmitButton>,
  },
  {
    id: pageIds.bathrooms,
    component: values => <BathroomsPage propertyType={values.accommodationType} />,
    route: '/landlord-admin/add-property/bathrooms',
    validate: bathroomsPageValidation,
    isValid: false,
    heading: 'Number of bathrooms',
    subheading: 'Please include all the bathrooms, including half-bathrooms and en-suites.',
    button: values => {
      if (values.bathrooms === 0) return null
      return <SubmitButton data-testid="continue-button">Continue</SubmitButton>
    },
  },
  {
    id: pageIds.amenities,
    component: () => <AmenitiesPage />,
    route: '/landlord-admin/add-property/amenities',
    validate: alwaysValidateToTrue,
    isValid: false,
    heading: 'Amenities',
    subheading: 'Select the amenities that are available in this property.',
    button: () => <SubmitButton data-testid="continue-button">Continue</SubmitButton>,
  },
  {
    id: pageIds.bills,
    component: () => <BillsPage />,
    route: '/landlord-admin/add-property/bills',
    validate: alwaysValidateToTrue,
    isValid: false,
    heading: 'Bills included',
    subheading: 'If any bills are included in the rent, please specify which ones.',
    button: ({ bills }) => {
      if (bills.length > 0 && bills.length < 4) {
        return (
          <SubmitButton data-testid="continue-button">
            Continue with some bills included
          </SubmitButton>
        )
      }

      if (bills.length === 4) {
        return (
          <SubmitButton data-testid="continue-button">
            Continue with all bills included
          </SubmitButton>
        )
      }

      return (
        <SubmitButton data-testid="continue-button">Continue with no bills included</SubmitButton>
      )
    },
  },
  {
    id: pageIds.description,
    component: () => <DescriptionPage />,
    route: '/landlord-admin/add-property/description',
    validate: values => descriptionPageValidation(values),
    isValid: false,
    heading: 'Description',
    subheading:
      'Provide a quick summary about your property, any special features, proximity to shops, public transport, etc.',
    button: ({ description }) => {
      if (!description || description.length === 0) return null
      return <SubmitButton data-testid="continue-button">Save property</SubmitButton>
    },
  },
  {
    id: pageIds.bedrooms,
    component: values => <BedroomsPage propertyType={values.accommodationType} />,
    route: '/landlord-admin/add-property/bedrooms',
    validate: values => bedroomsPageValidation(values),
    isValid: false,
    headingComponent: values =>
      values.accommodationType === 'Studio' ? 'Setup studio' : 'Number of bedrooms',
    subheadingComponent: values => renderBedroomPageSubHeading(values.accommodationType),
    button: values => {
      const totalBedrooms = values.bedrooms.length
      const totalValidBedrooms = values.bedrooms.filter(x => x.valid === true).length
      if (totalBedrooms === 0 || totalValidBedrooms !== totalBedrooms) return null
      return <SubmitButton data-testid="continue-button">Continue</SubmitButton>
    },
  },
  {
    id: pageIds.address,
    component: () => <AddressPage />,
    route: '/landlord-admin/add-property/address',
    validate: values => addressPageValidation(values),
    isValid: false,
    headingComponent: ({ address }) => {
      if (!address || !address.postcode) return 'Property address'
      return 'Review address'
    },
    button: ({ address }) => {
      if (!address || !address.postcode) return null
      return <SubmitButton data-testid="continue-button">Continue</SubmitButton>
    },
    noBackLink: true,
  },
]
