import React from 'react'
import PropTypes from 'prop-types'

import { getAccount } from '../../../api/accountApi'
import { useAuthContext } from '../../../authContext'
import { landlordFieldConfig, lettingAgentFieldConfig } from './notificationsSettingsPageConfig'

import TwoColumnPageTemplate from '../../templates/TwoColumnPageTemplate'
import Header from '../../organisms/Header'
import BackLink from '../../molecules/BackLink'
import AccountSettingsContainer from '../../containers/AccountSettingsContainer'

const NotificationsSettingsPage = ({ history }) => {
  const { token, accountType } = useAuthContext()
  const isLettingAgent = accountType === 'LettingAgent'

  const renderLeftColumn = () => (
    <>
      <BackLink
        className="form-navigation__back-link"
        onClick={event => {
          event.preventDefault()
          history.goBack()
        }}
      />
      <AccountSettingsContainer
        heading="Notifications"
        fieldConfig={isLettingAgent ? lettingAgentFieldConfig : landlordFieldConfig}
        getLandlord={getAccount}
        token={token}
      />
    </>
  )

  return (
    <TwoColumnPageTemplate
      header={<Header />}
      title="Account Settings - Notifications | AFS"
      leftColumn={renderLeftColumn()}
    />
  )
}

NotificationsSettingsPage.propTypes = {
  history: PropTypes.object,
}

export default NotificationsSettingsPage
