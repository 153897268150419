import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import InfoTooltip from '@afs/components/InfoTooltip'
import LinkButton from '../../atoms/LinkButton'
import Link from '../../atoms/Link'
import Overlay from '../Overlay'

import AdvertiseIcon from '../../../svgs/icons/payment-card-small.svg'
import BoostIcon from '../../../svgs/icons/boost-to-top.svg'
import AvailabilityIcon from '../../../svgs/icons/availability.svg'
import RelistIcon from '../../../svgs/icons/relist-toggle.svg'
import DelistIcon from '../../../svgs/icons/delist-toggle.svg'
import EditIcon from '../../../svgs/icons/edit.svg'

import styles from './multiSelectActionOverlay.module.scss'

const MultiSelectActionOverlay = ({
  overlay,
  selectedProperties,
  handleOverlayClose,
  handleDelistProperties,
  handleListProperties,
}) => {
  const propertyText = selectedProperties.length === 1 ? 'property' : 'properties'

  const allPropertiesNotAdvertised = selectedProperties.every(
    property => property.status === 'NotAdvertised'
  )

  const allPropertiesAdvertised = selectedProperties.every(
    property => property.status === 'CurrentlyAdvertised'
  )

  const noPropertiesAreAdvertised = selectedProperties.every(
    property => property.status !== 'CurrentlyAdvertised'
  )

  const allPropertiesAdvertisedAndListed =
    allPropertiesAdvertised && selectedProperties.every(property => property.isListed === true)

  const singlePropertySelected = selectedProperties.length === 1

  const advertisedIconClasses = classNames(
    styles.iconLarge,
    allPropertiesNotAdvertised ? styles.iconEnabled : styles.iconDisabled
  )

  const relistAndDelistIconClasses = classNames(
    styles.iconLarge,
    !noPropertiesAreAdvertised ? styles.iconEnabled : styles.iconDisabled
  )

  const boostIconClasses = classNames(
    styles.iconMed,
    allPropertiesAdvertisedAndListed ? styles.iconEnabled : styles.iconDisabled
  )

  const editIconClasses = classNames(
    styles.iconMed,
    singlePropertySelected ? styles.iconEnabled : styles.iconDisabled
  )

  const actionListItems = [
    {
      enabledComponent: (
        <Link
          className={styles.actionListLink}
          to="payment-method"
          data-testid="payment-method-link"
        >
          <span className={styles.iconWrapper}>
            <AdvertiseIcon className={advertisedIconClasses} />
          </span>
          {`Advertise ${propertyText}`}
        </Link>
      ),
      disabledComponent: (
        <div className={styles.actionListLinkDisabled} data-testid="payment-method-link-disabled">
          <span className={styles.iconWrapper}>
            <AdvertiseIcon className={advertisedIconClasses} />
          </span>
          <span className={styles.disabledText}>{`Advertise ${propertyText}`}</span>
        </div>
      ),
      tooltipText: (
        <span className={styles.tooltipText} data-testid="advertise-tooltip-text">
          You can only advertise properties that are
          <span className={styles.tooltipHighlight}> not advertised</span>.
        </span>
      ),
      disabled: !allPropertiesNotAdvertised,
      id: 1,
    },
    {
      enabledComponent: (
        <Link
          className={styles.actionListLink}
          to="properties/boost"
          data-testid="boost-properties-link"
        >
          <span className={styles.iconWrapper}>
            <BoostIcon className={boostIconClasses} />
          </span>
          Boost to the top
        </Link>
      ),
      disabledComponent: (
        <div className={styles.actionListLinkDisabled} data-testid="boost-properties-link-disabled">
          <span className={styles.iconWrapper}>
            <BoostIcon className={boostIconClasses} />
          </span>
          <span className={styles.disabledText}>Boost to the top</span>
        </div>
      ),
      tooltipText: (
        <span className={styles.tooltipText} data-testid="boost-tooltip-text">
          You can only boost properties that are
          <span className={styles.tooltipHighlight}> advertised </span>and
          <span className={styles.tooltipHighlight}> not unlisted</span>.
        </span>
      ),
      disabled: !allPropertiesAdvertisedAndListed,
      id: 2,
    },
    {
      enabledComponent: (
        <Link
          className={styles.actionListLink}
          to={
            singlePropertySelected
              ? `/landlord-admin/property/${selectedProperties[0].id}/edit/review`
              : '/landlord-admin/properties'
          }
          data-testid="edit-property-link"
        >
          <span className={styles.iconWrapper}>
            <EditIcon className={editIconClasses} />
          </span>
          Edit property
        </Link>
      ),
      disabledComponent: (
        <div className={styles.actionListLinkDisabled} data-testid="edit-property-link-disabled">
          <span className={styles.iconWrapper}>
            <EditIcon className={editIconClasses} />
          </span>
          <span className={styles.disabledText}>Edit property</span>
        </div>
      ),
      tooltipText: (
        <span className={styles.tooltipText} data-testid="edit-tooltip-text">
          You can only edit
          <span className={styles.tooltipHighlight}> one property</span> at a time.
        </span>
      ),
      disabled: !singlePropertySelected,
      id: 3,
    },
    {
      enabledComponent: (
        <Link
          to="properties/availability"
          className={styles.actionListLink}
          data-testid="edit-availability-properties-link"
        >
          <span className={styles.iconWrapper}>
            <AvailabilityIcon className={styles.iconSmall} />
          </span>
          Change availability dates
        </Link>
      ),
      disabled: false,
      id: 4,
    },
    {
      enabledComponent: (
        <LinkButton
          className={styles.actionListLink}
          onClick={handleListProperties}
          data-testid="relist-properties-link"
        >
          <span className={styles.iconWrapper}>
            <RelistIcon className={relistAndDelistIconClasses} />
          </span>
          {`Relist ${propertyText}`}
        </LinkButton>
      ),
      disabledComponent: (
        <div
          className={styles.actionListLinkDisabled}
          data-testid="relist-properties-link-disabled"
        >
          <span className={styles.iconWrapper}>
            <RelistIcon className={relistAndDelistIconClasses} />
          </span>
          <span className={styles.disabledText}>{`Relist ${propertyText}`}</span>
        </div>
      ),
      tooltipText: (
        <span className={styles.tooltipText} data-testid="relist-tooltip-text">
          You can only relist properties that are
          <span className={styles.tooltipHighlight}> unlisted</span>.
        </span>
      ),
      disabled: noPropertiesAreAdvertised,
      id: 5,
    },
    {
      enabledComponent: (
        <LinkButton
          className={styles.actionListLink}
          onClick={handleDelistProperties}
          data-testid="delist-properties-link"
        >
          <span className={styles.iconWrapper}>
            <DelistIcon className={relistAndDelistIconClasses} />
          </span>
          {`Delist ${propertyText}`}
        </LinkButton>
      ),
      disabledComponent: (
        <div
          className={styles.actionListLinkDisabled}
          data-testid="delist-properties-link-disabled"
        >
          <span className={styles.iconWrapper}>
            <DelistIcon className={relistAndDelistIconClasses} />
          </span>
          <span className={styles.disabledText}>{`Delist ${propertyText}`}</span>
        </div>
      ),
      tooltipText: (
        <span className={styles.tooltipText} data-testid="delist-tooltip-text">
          You can only delist properties that are
          <span className={styles.tooltipHighlight}> currently advertised</span>.
        </span>
      ),
      disabled: noPropertiesAreAdvertised,
      id: 6,
    },
    // {
    //   component: (
    //     <LinkButton className={styles.actionListLink}>
    // <div className={styles.iconWrapper}><DeleteIcon className={styles.iconMed} /></div>
    //       Delete properties
    //     </LinkButton>
    //   ),
    //   disabled: false,
    //   icon: <DeleteIcon className={styles.iconMed} />,
    // id:7,
    // },
  ]

  const enabledComponents = actionListItems.filter(item => item.disabled === false)
  const disabledComponents = actionListItems.filter(item => item.disabled === true)

  return (
    <Overlay
      className={styles.overlay}
      id="multi-select-actions"
      heading={`${selectedProperties.length} ${propertyText} selected`}
      closeLabel="cancel"
      isActive={overlay.active}
      onClose={handleOverlayClose}
      preserveScrollPositionOnClose
    >
      <ul className={styles.actionsList}>
        {enabledComponents.map(item => {
          return (
            <li
              className={styles.actionListItem}
              key={item.id}
              data-testid={`enabled-item-${item.id}`}
            >
              {item.enabledComponent}
            </li>
          )
        })}

        {disabledComponents.map(item => {
          return (
            <li
              className={styles.actionListItem}
              key={item.id}
              data-testid={`disabled-item-${item.id}`}
            >
              <InfoTooltip
                tooltipContent={item.tooltipText}
                className={styles.tooltip}
                data-testid={`disabled-item-${item.id}`}
              >
                {item.disabledComponent}
              </InfoTooltip>
            </li>
          )
        })}
      </ul>
    </Overlay>
  )
}

MultiSelectActionOverlay.propTypes = {
  overlay: PropTypes.object,
  selectedProperties: PropTypes.array.isRequired,
  handleOverlayClose: PropTypes.func.isRequired,
  handleDelistProperties: PropTypes.func.isRequired,
  handleListProperties: PropTypes.func.isRequired,
}

export default MultiSelectActionOverlay
