import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useAuthContext } from '../../authContext'
import { notificationService } from '../../container'
import {
  getAccountHasEverAdvertisedAProperty,
  getAccountBoostCredits,
  getAccountAdvertiseCredits,
} from '../../api/accountApi'

import LoadingPage from '../pages/LoadingPage'
import AccountPage from '../pages/AccountPage'

export const AccountContainer = ({
  getLandlordHasEverAdvertisedAProperty,
  getLandlordBoostCredits,
  getLandlordAdvertiseCredits,
  accountPage,
  useAuthContext,
  notificationService,
}) => {
  const [loading, setLoading] = useState(true)
  const [accountHasAdvertised, setAccountHasAdvertised] = useState(null)
  const [boostCredits, setBoostCredits] = useState(null)
  const [advertiseCreditsAccount, setAdvertiseCreditsAccount] = useState(null)

  const { token, accountType } = useAuthContext()
  const isLettingAgent = accountType === 'LettingAgent'

  useEffect(() => {
    getLandlordAdvertiseCredits(token)
      .then(advertiseCreditsResponse => {
        if (advertiseCreditsResponse.success) {
          setAdvertiseCreditsAccount(advertiseCreditsResponse.advertiseCreditsData)
        } else {
          notificationService.addDeferredWarningNotification(
            'Oops!',
            "Our system couldn't retrieve your advertise credits. Please try again later. Our team has been notified and will investigate the issue."
          )
        }
      })
      .then(() => {
        getLandlordHasEverAdvertisedAProperty(token).then(hasAdvertisedResponse => {
          if (hasAdvertisedResponse.success) {
            setAccountHasAdvertised(hasAdvertisedResponse.hasAdvertisedProperties)

            if (hasAdvertisedResponse.hasAdvertisedProperties) {
              getLandlordBoostCredits(token).then(boostCreditsResponse => {
                if (boostCreditsResponse.success) {
                  setBoostCredits(boostCreditsResponse.boostCredits)
                } else {
                  notificationService.addDeferredWarningNotification(
                    'Oops!',
                    "Our system couldn't retrieve your boost credits. Please try again later. Our team has been notified and will investigate the issue."
                  )
                }
                setLoading(false)
              })
            } else {
              setLoading(false)
            }
          } else {
            notificationService.addDeferredWarningNotification(
              'Oops!',
              "Our system couldn't retrieve your advertising history. Please try again later. Our team has been notified and will investigate the issue."
            )

            setLoading(false)
          }
        })
      })
  }, [])

  return loading ? (
    <LoadingPage />
  ) : (
    accountPage(boostCredits, accountHasAdvertised, advertiseCreditsAccount, isLettingAgent)
  )
}

AccountContainer.propTypes = {
  getLandlordHasEverAdvertisedAProperty: PropTypes.func.isRequired,
  getLandlordBoostCredits: PropTypes.func.isRequired,
  getLandlordAdvertiseCredits: PropTypes.func.isRequired,
  accountPage: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
  notificationService: PropTypes.object.isRequired,
}

export const AccountContainerCompositionRoot = () => {
  return (
    <AccountContainer
      getLandlordHasEverAdvertisedAProperty={getAccountHasEverAdvertisedAProperty}
      getLandlordBoostCredits={getAccountBoostCredits}
      getLandlordAdvertiseCredits={getAccountAdvertiseCredits}
      accountPage={(
        boostCredits,
        accountHasAdvertised,
        advertiseCreditsAccount,
        isLettingAgent
      ) => (
        <AccountPage
          boostCredits={boostCredits}
          accountHasAdvertised={accountHasAdvertised}
          accountHasAdvertiseCredits={advertiseCreditsAccount.hasAdvertiseCredits}
          advertiseCredits={advertiseCreditsAccount.advertiseCredits}
          isLettingAgent={isLettingAgent}
        />
      )}
      useAuthContext={useAuthContext}
      notificationService={notificationService}
    />
  )
}
