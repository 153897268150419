import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SpinnerButton from '@afs/components/SpinnerButton'
import Field from '@afs/components/Field'

import Heading from '../../atoms/Heading'
import Link from '../../atoms/Link'
import FieldHoneypot from '../../molecules/FieldHoneypot'

import './styles.scss'

class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.loginFormViewModel = props.viewModel(this)
    this.notificationService = props.notificationService
    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      fields: { ...this.loginFormViewModel.getFields() },
      formSending: false,
    }
  }

  componentDidMount() {
    this.loginFormViewModel.registerHoneypot(this.honeypotField)
  }

  onFieldChange = (event) => {
    this.loginFormViewModel.updateTextField(event.target)
  }

  onFormSubmit = async (event) => {
    event.preventDefault()

    this.setState({
      formSending: true,
    })

    const response = await this.loginFormViewModel.postForm()

    this.setState({
      formSending: false,
    })

    if (response.success) {
      this.props.history.push(this.props.pathToRedirectToAfterLogin)
    } else if (response.reason.includes('Account Migrated')) {
      const email = this.loginFormViewModel.getFields().emailAddress.value
      this.props.redirectToWelcomePage(email)
    } else if (response.code === 'UserNotConfirmedException') {
      const email = this.loginFormViewModel.getFields().emailAddress.value
      this.props.verificationLinkForm(email)
    } else if (response.code === 'PasswordResetRequiredException') {
      this.notificationService.addDeferredWarningNotification(
        'Reset required.',
        'We found your account but for security reasons, a password reset is required.'
      )
      this.props.history.push('/forgotten-password')
    } else {
      this.notificationService.showErrorNotification(
        'Invalid credentials.',
        'The email or password provided are wrong.'
      )
    }
  }

  updateView = () => {
    this.setState({ fields: { ...this.loginFormViewModel.getFields() } })
  }

  render() {
    const componentClasses = classNames('login-form', this.props.className)
    const fieldProps = (fieldName) => {
      const field = this.state.fields[fieldName]
      return {
        key: fieldName,
        defaultValue: field.value,
      }
    }

    return (
      <div>
        <Heading className="login-form__heading">Login</Heading>
        <form
          className={componentClasses}
          action={this.props.action || '/'}
          method="post"
          onSubmit={this.onFormSubmit}
          data-testid="login-form"
          noValidate
        >
          <fieldset className="login-form__fieldset">
            <Field
              className="login-form__field"
              label="Email address"
              name="emailAddress"
              type="email"
              onChange={this.onFieldChange}
              autoComplete="username"
              {...fieldProps('emailAddress')}
            />
            <Field
              className="login-form__field last"
              label="Password"
              name="password"
              type="password"
              onChange={this.onFieldChange}
              autoComplete="current-password"
              {...fieldProps('password')}
            />
            <Link to="/forgotten-password" className="login-form__forgot-password__link">
              Forgot password?
            </Link>
          </fieldset>
          <FieldHoneypot
            key={6}
            ref={(input) => {
              this.honeypotField = input
            }}
          />
          <SpinnerButton
            className="login-form__submit"
            primary
            inactive={this.state.formSending}
            data-testid="login-form-submit"
          >
            Log in
          </SpinnerButton>
        </form>
        <p className="login-form__register-link">
          {"Don't have an account? "}
          <Link to="/register">Sign up now.</Link>
        </p>
      </div>
    )
  }
}

LoginForm.propTypes = {
  className: PropTypes.string,
  action: PropTypes.string,
  history: PropTypes.object,
  viewModel: PropTypes.func,
  verificationLinkForm: PropTypes.func,
  notificationService: PropTypes.object,
  redirectToWelcomePage: PropTypes.func,
  pathToRedirectToAfterLogin: PropTypes.string,
}

export default LoginForm
