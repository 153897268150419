import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { Filter } from '../../../molecules/Filtering'
import SearchField from '../../../molecules/SearchField'

export const matchPropertyAddressOrReference = (property, searchTerm) => {
  const propertyAddressString = property.address.addressFields().join(', ')
  const postcodeWithoutWhitespace = Array.from(property.address.postcodeOnly())
    .filter(char => char !== ' ')
    .join('')

  const addressMatch = propertyAddressString.toLowerCase().includes(searchTerm.toLowerCase())
  const postcodeWithoutWhitespaceMatch = postcodeWithoutWhitespace
    .toLowerCase()
    .includes(searchTerm.toLowerCase())
  const referenceMatch = property.referenceNumber.toLowerCase().includes(searchTerm.toLowerCase())

  return addressMatch || postcodeWithoutWhitespaceMatch || referenceMatch
}

const FilterByAddressOrReference = ({
  className,
  currentSearchTerm,
  setCurrentSearchTerm,
  tabIndex,
  ...props
}) => {
  const inputRef = useRef()

  return (
    <Filter
      alwaysApply
      condition={property => matchPropertyAddressOrReference(property, inputRef.current.value)}
      {...props}
    >
      {({ applyFilter }) => {
        return (
          <SearchField
            className={className}
            ref={inputRef}
            name="filter-by-address-or-reference"
            ariaLabel="Filter properties by address or reference number"
            value={currentSearchTerm}
            handleChange={searchTerm => {
              setCurrentSearchTerm(searchTerm)
              applyFilter()
            }}
            handleClearValue={() => {
              inputRef.current.value = ''
              setCurrentSearchTerm('')
              applyFilter()
            }}
            placeholder="Filter by address or reference"
            tabIndex={tabIndex}
          />
        )
      }}
    </Filter>
  )
}

FilterByAddressOrReference.propTypes = {
  className: PropTypes.string,
  currentSearchTerm: PropTypes.string.isRequired,
  setCurrentSearchTerm: PropTypes.func.isRequired,
  tabIndex: PropTypes.string,
}

export default FilterByAddressOrReference
