/* eslint-disable no-underscore-dangle */
class PropertyIds {
  _selectedPropertyIds

  constructor() {
    this._selectedPropertyIds = []
  }

  select(propertyId) {
    if (this._selectedPropertyIds.includes(propertyId)) return
    this._selectedPropertyIds.push(propertyId)
  }

  deselect(propertyId) {
    this._selectedPropertyIds = this._selectedPropertyIds.filter(id => id !== propertyId)
  }

  deselectAll() {
    this._selectedPropertyIds.forEach(propertyId => this.deselect(propertyId))
  }

  selected() {
    return this._selectedPropertyIds
  }

  noneSelected() {
    return this._selectedPropertyIds.length === 0
  }
}

export default PropertyIds
