import { Auth, Storage } from 'aws-amplify'
import * as DOMPurify from 'dompurify'
import Cookies from 'js-cookie'

import { config } from './config'

import authApi from './api/authApi'
import propertyApi from './api/propertyApi'

import AuthService from './services/authService'
import StorageService from './services/storageService'
import NotificationService from './services/notificationService'
import LoginFormViewModel from './components/organisms/LoginForm/LoginFormViewModel'
import ForgottenPasswordFormViewModel from './components/organisms/ForgottenPasswordForm/ForgottenPasswordFormViewModel'
import ResetPasswordFormViewModel from './components/organisms/ResetPasswordForm/ResetPasswordFormViewModel'
import AddressFinder from './services/addressFinder'
import locationMatcher from './services/locationMatcher'
import BrowserBannerService from './services/browserBannerService'
import supportedBrowsers from './supportedBrowsers'
import CookieService from './services/cookieService'

export const randomIdGenerator = {
  timeNowInMilliseconds: () => Date.now(),
}

const COOKIE_BANNER_VERSION_ID = 1

export const cookieService = CookieService(COOKIE_BANNER_VERSION_ID, config)

export const addressFinder = AddressFinder(locationMatcher)

export const notificationService = NotificationService(randomIdGenerator)

export const authService = AuthService(Auth, authApi)

export const storageService = StorageService(Storage, randomIdGenerator, propertyApi, Auth)

export const browserBannerService = BrowserBannerService(config, Cookies, () =>
  supportedBrowsers.test(navigator.userAgent)
)

export const loginFormViewModel = view => {
  return new LoginFormViewModel(view, DOMPurify, authService)
}

export const forgottenPasswordFormViewModel = view => {
  return new ForgottenPasswordFormViewModel(view, DOMPurify, authService)
}

export const resetPasswordFormViewModel = view => {
  return new ResetPasswordFormViewModel(
    view,
    DOMPurify,
    authService,
    new URLSearchParams(window.location.search)
  )
}
