/* eslint-disable no-underscore-dangle */
import Order from './order'

class Basket {
  _orderHistory
  _currency
  _products
  _vatRate
  _order

  constructor(orderHistory, currency, products) {
    this._orderHistory = orderHistory
    this._currency = currency
    this._products = products
    this._vatRate = 0.2
    this._order = new Order(this._vatRate, this._currency, products)

    this.addAdvertisement = this.addAdvertisement.bind(this)
    this.addOneMonthAdvertisement = this.addOneMonthAdvertisement.bind(this)
    this.addThreeMonthAdvertisement = this.addThreeMonthAdvertisement.bind(this)
    this.addSixMonthAdvertisement = this.addSixMonthAdvertisement.bind(this)
    this.addTwelveMonthAdvertisement = this.addTwelveMonthAdvertisement.bind(this)

    this.addBoostCredits = this.addBoostCredits.bind(this)
    this.addTenBoostCredits = this.addTenBoostCredits.bind(this)
    this.addTwentyFiveBoostCredits = this.addTwentyFiveBoostCredits.bind(this)
    this.addFiftyBoostCredits = this.addFiftyBoostCredits.bind(this)
    this.addOneHundredBoostCredits = this.addOneHundredBoostCredits.bind(this)

    this.addToLetBoard = this.addToLetBoard.bind(this)
    this.removeToLetBoard = this.removeToLetBoard.bind(this)
  }

  setAuthToken(authToken) {
    this._authToken = authToken
    this._order = new Order(this._vatRate, this._currency, this._products, this._authToken)
  }

  addAdvertisement(propertyId, startingDate, product) {
    this._order.addAdvertisement(propertyId, startingDate, product)
  }

  addOneMonthAdvertisement(propertyId, startingDate) {
    this.addAdvertisement(propertyId, startingDate, this._products.adOneMonth())
  }

  addThreeMonthAdvertisement(propertyId, startingDate) {
    this.addAdvertisement(propertyId, startingDate, this._products.adThreeMonths())
  }

  addSixMonthAdvertisement(propertyId, startingDate) {
    this.addAdvertisement(propertyId, startingDate, this._products.adSixMonths())
  }

  addTwelveMonthAdvertisement(propertyId, startingDate) {
    this.addAdvertisement(propertyId, startingDate, this._products.adTwelveMonths())
  }

  removeDeselectedProperties(selectedPropertyIds) {
    this._order.removeDeselectedProperties(selectedPropertyIds)
  }

  addBoostCredits(product) {
    this._order.addBoostCredits(product)
  }

  addTenBoostCredits() {
    this.addBoostCredits(this._products.boostCreditsTen)
  }

  addTwentyFiveBoostCredits() {
    this.addBoostCredits(this._products.boostCreditsTwentyFive)
  }

  addFiftyBoostCredits() {
    this.addBoostCredits(this._products.boostCreditsFifty)
  }

  addOneHundredBoostCredits() {
    this.addBoostCredits(this._products.boostCreditsOneHundred)
  }

  addToLetBoard(propertyId) {
    this._order.addToLetBoard(propertyId, this._products.toLetBoard)
  }

  removeToLetBoard(propertyId) {
    this._order.removeToLetBoard(propertyId)
  }

  async subtotalWithoutVat() {
    return this._order.subtotalWithoutVat()
  }

  nonDiscountedSubtotalWithoutVat() {
    return this._order.nonDiscountedSubtotalWithoutVat()
  }

  async vatOnly() {
    return this._order.vatOnly()
  }

  async totalWithVat() {
    return this._order.totalWithVat()
  }

  async getDiscount() {
    return this._order.getDiscount()
  }

  async hasDiscountApplied() {
    return this._order.hasDiscountApplied()
  }

  finalise() {
    this._orderHistory.addOrder(this._order)
    this.clear()
  }

  clear() {
    this._order = new Order(this._vatRate, this._currency, this._products, this._authToken)
  }

  orderSummary() {
    return this._order.summary()
  }

  advertisementSummary() {
    return this._order.advertisementSummary()
  }

  boostCreditSummary() {
    return this._order.boostCreditSummary()
  }

  toLetBoardSummary() {
    return this._order.toLetBoardSummary()
  }

  isEmpty() {
    return this._order.lineItemCount() === 0
  }

  containsAdvertisementOrderItems() {
    return this._order.containsAdvertisements()
  }

  updateAdvertisedFromDateForAllAdvertisements(advertisedFromDate) {
    this._order.updateAdvertisedFromDateForAllAdvertisements(advertisedFromDate)
  }

  async createPaymentModel() {
    const totalWithVat = await this.totalWithVat()
    const { discount } = await this._order.getDiscount()
    return {
      advertisementOrderItems: this._order.advertisementsToApiModel(),
      boostCreditOrderItem: this._order.boostCreditsToApiModel(),
      toLetBoardOrderItems: this._order.toLetBoardsToApiModel(),
      totalWithVat,
      discountId: discount.id,
      currency: this._currency.isoCode,
    }
  }
}

export default Basket
