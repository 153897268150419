import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ThorDefault from './ThorDefault'
import ThorPride from './ThorPride'
import ThorHalloween from './ThorHalloween'
import ThorChristmas from './ThorChristmas'

import styles from './styles.module.scss'

const getLogo = ({ isWinking, isJune, isHalloween, isDecember }) => {
  if (isJune)
    return <ThorPride className={styles.icon} isWinking={isWinking} data-testid="thor-pride" />

  if (isHalloween)
    return (
      <ThorHalloween className={styles.icon} isWinking={isWinking} data-testid="thor-halloween" />
    )

  if (isDecember) {
    return (
      <ThorChristmas className={styles.icon} isWinking={isWinking} data-testid="thor-christmas" />
    )
  }

  return <ThorDefault className={styles.icon} isWinking={isWinking} data-testid="thor" />
}

const Logo = ({ className, renderLinkComponent, ...props }) => {
  const [isWinking, setIsWinking] = useState(false)

  const handleEnter = () => setIsWinking(true)
  const handleLeave = () => setIsWinking(false)

  const month = new Date().getMonth()
  const date = new Date().getDate()

  const isJune = month === 5
  const isHalloween = month === 9 && date >= 17 && date <= 31
  const isDecember = month === 11

  return renderLinkComponent({
    className: classNames(
      styles.logo,
      {
        [styles.logoChristmas]: isDecember,
      },
      className
    ),
    onMouseEnter: handleEnter,
    onMouseLeave: handleLeave,
    onFocus: handleEnter,
    onBlur: handleLeave,
    children: (
      <>
        {getLogo({ isWinking, isJune, isHalloween, isDecember })}
        <span className={styles.text}>Accommodation for Students</span>
      </>
    ),
    ...props,
  })
}

Logo.propTypes = {
  className: PropTypes.string,
  renderLinkComponent: PropTypes.func.isRequired,
}

export default Logo
