import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Heading from '@afs/components/Heading'
import Icon from '../../atoms/Icon'

import './styles.scss'

const Notification = ({
  className,
  type = 'error',
  heading,
  text,
  nonDismissible,
  clearNotification,
  disableAnimation,
}) => {
  const [isDismissed, setIsDismissed] = useState(false)

  const otherClasses = disableAnimation
    ? 'notification fadeInDown'
    : 'notification fadeInDown animated'

  const componentClasses = classNames(className, otherClasses, {
    [`notification--${type}`]: type,
    'notification--non-dismissible': nonDismissible,
  })

  const handleOnClick = () => {
    setIsDismissed(true)
    if (clearNotification) {
      clearNotification()
    }
  }

  const iconClassName = classNames('notification__close-icon', type)
  const iconSize = '13'

  if (isDismissed) {
    return false
  }

  return (
    <div className={componentClasses} data-testid="notification">
      <div className="notification__content">
        {heading && (
          <Heading level={5} className="notification__heading">
            {heading}
          </Heading>
        )}
        <p className="notification__text" role="alert">
          {text}
        </p>
      </div>
      {!nonDismissible && (
        <button className="notification__close-button" onClick={handleOnClick}>
          <span className="notification__close-text">Close</span>
          <Icon
            className={iconClassName}
            icon="cross"
            width={iconSize}
            height={iconSize}
            viewBox="0 0 15.86 15.89"
          />
        </button>
      )}
    </div>
  )
}

Notification.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  heading: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  nonDismissible: PropTypes.bool,
  clearNotification: PropTypes.func,
  disableAnimation: PropTypes.bool,
}

export default Notification
