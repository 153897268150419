export const setItem = (id, value) => {
  let sessionValue = value

  if (typeof sessionValue === 'object') {
    sessionValue = JSON.stringify(sessionValue)
  }

  sessionStorage.setItem(id, sessionValue)
}

export const getItem = id => {
  const value = sessionStorage.getItem(id)

  try {
    return JSON.parse(value)
  } catch (e) {
    return value
  }
}

export const clear = () => {
  sessionStorage.clear()
}
