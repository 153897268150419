import React from 'react'
import PropTypes from 'prop-types'
import Card from '@afs/components/Card'
import InfoTooltip from '@afs/components/InfoTooltip'

import Button from '../../atoms/Button'
import PropertyImageCarousel from '../PropertyImageCarousel'
import PropertyRoomCount from '../../atoms/PropertyRoomCount'
import PropertyDetailsListing from '../PropertyDetailsListing'
import ImagePlaceholder from '../../atoms/ImagePlaceholder'

import PictureIcon from '../../../svgs/icons/picture.svg'

import details from './details.module.scss'

const PropertyDetailsOverview = ({ className, property, onAddToBasket, imageApiUrl }) => {
  const propertyUrl = `/landlord-admin/property/${property.id}`

  return (
    <Card
      className={className}
      topCard={
        <>
          {property.photos && property.photos.length > 0 ? (
            <PropertyImageCarousel photos={property.photos} imageApiUrl={imageApiUrl} />
          ) : (
            <div className={details.placeholder}>
              <ImagePlaceholder alt="No photos added" maxWidth={610} />
            </div>
          )}
          <Button
            className={details.editPhotosButton}
            to={`/landlord-admin/property/${property.id}/edit/photos`}
            violet
            data-testid="edit-photos-button"
          >
            <PictureIcon className={details.pictureIcon} />
            <span className={details.editPhotosButtonText}>Edit photos</span>
          </Button>
        </>
      }
      bottomCard={<PropertyDetailsListing property={property} onAddToBasket={onAddToBasket} />}
      morePadding
      tightPadding
      noBorder
      light
    >
      <strong className={details.heading} data-testid="details-heading">
        {property.address.firstTwoLines()}
      </strong>
      <p className={details.subHeading} data-testid="details-subheading">
        <span>{property.address.withoutFirstTwoLinesAndPostcode().join(', ')},</span>
        <span className={details.postcode}> {property.address.postcodeOnly()}</span>
      </p>
      <div className={details.rooms} data-testid="details-rooms">
        <PropertyRoomCount className={details.roomCount} count={property.bedrooms.length} />
        <PropertyRoomCount className={details.roomCount} count={property.bathrooms} bathrooms />
      </div>
      <div className={details.row}>
        <strong>Accommodation type</strong>
        <p data-testid="details-accommodation-type">{property.accommodationType}</p>
      </div>
      <div className={details.row}>
        <strong>Bills included</strong>
        <p data-testid="details-bills-included">{property.billsIncludedString()}</p>
      </div>
      <div className={details.row}>
        <strong>Let type</strong>
        <p data-testid="details-letting-type">{property.lettingType}</p>
      </div>
      {property.referenceNumber && (
        <div className={details.row}>
          <strong className={details.inlineLabel}>Reference no.</strong>
          <InfoTooltip
            className={details.tooltip}
            tooltipContent={
              <p>
                Used by landlord or letting agencies for their own records. It is{' '}
                <strong className={details.tooltipHighlight}>not displayed to the public</strong>.
              </p>
            }
          />
          <p data-testid="details-reference-no">{property.referenceNumber}</p>
        </div>
      )}
      <Button
        className={details.button}
        to={`${propertyUrl}/edit/review`}
        data-testid="edit-button"
      >
        Edit property details
      </Button>
    </Card>
  )
}

PropertyDetailsOverview.propTypes = {
  className: PropTypes.string,
  property: PropTypes.object.isRequired,
  onAddToBasket: PropTypes.func.isRequired,
  imageApiUrl: PropTypes.string,
}

export default PropertyDetailsOverview
