import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Field from '@afs/components/Field'

import {
  containsEmail,
  containsPhone,
  containsUrl,
  exceedsMaxLength,
  includesHTML,
  emptyRequiredField,
} from '../../../../utils/validation'

import AccountSettingsForm from '../../../molecules/AccountSettingsForm'

import name from './name.module.scss'

const NameForm = ({ account, onSave, loading, ...props }) => {
  const [fields, setFields] = useState({
    firstName: {
      value: account.firstName,
      touched: false,
      error: '',
    },
    lastName: {
      value: account.lastName,
      touched: false,
      error: '',
    },
  })

  const validateName = string => {
    if (containsEmail(string)) {
      return 'Email addresses are not allowed in name.'
    }

    if (containsUrl(string)) {
      return 'URLs are not allowed in name.'
    }

    if (containsPhone(string)) {
      return 'Phone numbers are not allowed in name.'
    }

    if (includesHTML(string)) {
      return 'HTML tags are not allowed in name.'
    }

    if (exceedsMaxLength(string, 50)) {
      return 'Name exceeds maximum length of 50 characters.'
    }

    if (emptyRequiredField(string)) {
      return 'Required.'
    }

    return ''
  }

  const handleChange = event => {
    const inputValue = event.target.value
    const inputName = event.target.name
    const error = validateName(inputValue)

    setFields(prevState => ({
      ...prevState,
      [inputName]: {
        value: inputValue,
        touched: true,
        error,
      },
    }))
  }

  const handleSave = event => {
    event.preventDefault()

    const fieldName = 'name'
    const payload = {
      firstName: fields.firstName.value,
      lastName: fields.lastName.value,
    }
    onSave(fieldName, payload)
  }

  const firstNameIsInvalid = fields.firstName.touched && fields.firstName.error !== ''
  const lastNameIsInvalid = fields.lastName.touched && fields.lastName.error !== ''

  const formIsInvalid = firstNameIsInvalid || lastNameIsInvalid

  return (
    <AccountSettingsForm onSubmit={handleSave} invalid={formIsInvalid} loading={loading} {...props}>
      <div className={name.wrapper}>
        <Field
          className={name.firstName}
          name="firstName"
          label="First name"
          aria-label="First name"
          value={fields.firstName.value}
          type="text"
          onChange={handleChange}
          onBlur={handleChange}
          invalid={firstNameIsInvalid}
          error={fields.firstName.error}
          data-testid="input-field-first-name"
        />

        <Field
          className={name.lastName}
          name="lastName"
          label="Last name"
          aria-label="Last name"
          type="text"
          value={fields.lastName.value}
          onChange={handleChange}
          onBlur={handleChange}
          invalid={lastNameIsInvalid}
          error={fields.lastName.error}
          data-testid="input-field-last-name"
        />
      </div>
    </AccountSettingsForm>
  )
}

NameForm.propTypes = {
  account: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default NameForm
