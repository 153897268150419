import React from 'react'
import SpinnerButton from '@afs/components/SpinnerButton'

import { updatePropertyLettingType } from '../../../../api/propertyApi'
import { LettingTypePage } from '../../FormPages/LettingTypePage'
import SubmitButton from '../../../atoms/SubmitButton'

const alwaysValidateToTrue = () => {
  return {}
}

const lettingTypePageConfig = propertyId => ({
  id: 2,
  component: () => <LettingTypePage />,
  route: `/landlord-admin/property/${propertyId}/edit/letting-type`,
  validate: alwaysValidateToTrue,
  isValid: false,
  heading: 'How do you want to let the property?',
  subheading: '',
  button: (values, loading) => {
    if (!values.lettingType) return null
    if (loading)
      return (
        <SpinnerButton primary inactive>
          Continue
        </SpinnerButton>
      )
    return <SubmitButton data-testid="save-button">Save changes</SubmitButton>
  },
  onSubmit: async (values, token) => {
    const payload = {
      propertyId,
      lettingType: values.lettingType,
    }
    return updatePropertyLettingType(payload, token)
  },
})

export default lettingTypePageConfig
