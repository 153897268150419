import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { config } from '../../../config'

import LazyImage from '../LazyImage'
import PictureIcon from '../../../svgs/icons/picture.svg'

import styles from './avatarImage.module.scss'

const AvatarImage = ({ className, hasPhoto, photoUrl, alt, large }) => {
  const imageSize = large ? 128 : 112

  return (
    <div
      className={classNames(styles.imageWrapper, { [styles.imageWrapperLarge]: large }, className)}
      data-testid="avatar"
    >
      {hasPhoto ? (
        <LazyImage
          className={styles.image}
          src={`${config.imgApiUrl}w=${imageSize}/${photoUrl}`}
          dataSrc={`${config.imgApiUrl}w=${imageSize}/${photoUrl}`}
          alt={alt}
          data-testid="image"
        />
      ) : (
        <PictureIcon className={styles.icon} data-testid="picture-icon" />
      )}
    </div>
  )
}

AvatarImage.propTypes = {
  className: PropTypes.string,
  hasPhoto: PropTypes.bool.isRequired,
  photoUrl: PropTypes.string,
  alt: PropTypes.string,
  large: PropTypes.bool,
}

export default AvatarImage
