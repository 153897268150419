class LoginFormViewModel {
  constructor(view, purifier, authService) {
    this.view = view
    this.purifier = purifier
    this.authService = authService
    this.fields = {
      emailAddress: {
        value: '',
      },
      password: {
        value: '',
      },
    }
  }

  getFields() {
    return this.fields
  }

  updateTextField = field => {
    const fieldName = field.name
    const fieldValue = this.purifier.sanitize(field.value)

    this.fields[fieldName] = {
      ...this.fields[fieldName],
      value: fieldValue,
    }

    this.view.updateView()
  }

  validateForm = form => {
    const allFieldsCompleted = form.checkValidity()
    const botNotSubmittingForm = !this.honeypotField.state.value

    return allFieldsCompleted && botNotSubmittingForm
  }

  registerHoneypot = field => {
    this.honeypotField = field
  }

  postForm = () => {
    return this.authService.signIn(this.fields.emailAddress.value, this.fields.password.value)
  }
}

export default LoginFormViewModel
