import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Field from '@afs/components/Field'

import { includesHTML } from '../../../../utils/validation'
import AccountSettingsForm from '../../../molecules/AccountSettingsForm'
import Dropdown from '../../../molecules/DropdownWithAutocomplete'

import address from './address.module.scss'

const InvoicingAddressForm = ({ account, countries, onSave, loading, ...props }) => {
  const [fields, setFields] = useState({
    addressLine1: {
      value: account.invoicingAddress.addressLine1,
      touched: false,
      error: '',
      invalid: false,
    },
    addressLine2: {
      value: account.invoicingAddress.addressLine2,
      touched: false,
      error: '',
      invalid: false,
    },
    townCity: {
      value: account.invoicingAddress.townCity,
      touched: false,
      error: '',
      invalid: false,
    },
    postcode: {
      value: account.invoicingAddress.postcode,
      touched: false,
      error: '',
      invalid: false,
    },
    country: {
      value: account.invoicingAddress.country || 'United Kingdom',
      touched: false,
      error: '',
      invalid: false,
    },
  })

  const { addressLine1, addressLine2, townCity, postcode, country } = fields

  const formIsInvalid = () =>
    Object.keys(fields)
      .map((key) => fields[key])
      .some((field) => field.invalid)

  const exceedsMaxCharacterCount = (string, maxNumberOfChars) => {
    return string.length > maxNumberOfChars
  }

  const errorMessageForPostcode = (postcode) => {
    if (includesHTML(postcode)) return 'HTML tags are not allowed.'
    if (exceedsMaxCharacterCount(postcode, 10)) return 'Maximum length of 10 characters allowed.'
    return ''
  }

  const errorMessageForOtherAddressLines = (addressLine) => {
    if (includesHTML(addressLine)) return 'HTML tags are not allowed.'
    if (exceedsMaxCharacterCount(addressLine, 100))
      return 'Maximum length of 100 characters allowed.'
    return ''
  }

  const handleChange = (event) => {
    const inputValue = event.target.value
    const inputName = event.target.name
    let error = ''
    let invalid = false

    if (inputName === 'postcode') {
      error = errorMessageForPostcode(inputValue)
      invalid = inputValue !== '' && error !== ''
    }

    if (inputName !== 'postcode') {
      error = errorMessageForOtherAddressLines(inputValue)
      invalid = inputValue !== '' && error !== ''
    }

    setFields((prevState) => ({
      ...prevState,
      [inputName]: {
        value: inputValue,
        touched: true,
        error,
        invalid,
      },
    }))
  }

  const countryOptions = countries.map((country) => ({
    value: country,
    label: country,
  }))

  const handleChangeCountry = (selection) => {
    if (!selection || typeof selection !== 'string') return

    const selectedCountry = selection
    const fieldIsEmpty = !selectedCountry

    if (!fieldIsEmpty) {
      setFields((prevState) => ({
        ...prevState,
        country: {
          value: selectedCountry,
          touched: true,
          error: '',
          invalid: fieldIsEmpty,
        },
      }))
    }
  }

  const handleSave = (event) => {
    event.preventDefault()

    const fieldName = 'invoicingAddress'
    const payload = {
      addressLine1: addressLine1.value,
      addressLine2: addressLine2.value,
      townCity: townCity.value,
      postcode: postcode.value,
      country: country.value,
    }
    onSave(fieldName, payload)
  }

  return (
    <AccountSettingsForm
      onSubmit={handleSave}
      invalid={formIsInvalid()}
      loading={loading}
      {...props}
    >
      <div className={address.wrapper}>
        <p className={address.text}>
          This information is required for invoicing and it is not made public or shared with
          anyone.
        </p>
        <Field
          className={address.line1}
          name="addressLine1"
          label="Street address"
          aria-label="Address line 1"
          value={addressLine1.value}
          placeholder="Street and number, P.O. box, c/o"
          type="text"
          onChange={handleChange}
          onBlur={handleChange}
          invalid={addressLine1.invalid}
          error={addressLine1.error}
          data-testid="input-field-address-line-1"
        />
        <Field
          className={address.input}
          name="addressLine2"
          aria-label="Address Line 2"
          type="text"
          value={addressLine2.value}
          placeholder="Flat, suite, unit, building, floor, etc."
          onChange={handleChange}
          onBlur={handleChange}
          invalid={addressLine2.invalid}
          error={addressLine2.error}
          data-testid="input-field-address-line-2"
        />
        <Field
          className={address.input}
          name="townCity"
          label="Town/City"
          aria-label="Town/City"
          value={townCity.value}
          type="text"
          onChange={handleChange}
          onBlur={handleChange}
          invalid={townCity.invalid}
          error={townCity.error}
          data-testid="input-field-town-city"
        />
        <Field
          className={address.input}
          name="postcode"
          label="Postcode/Zipcode"
          aria-label="Postcode/Zipcode"
          value={postcode.value}
          type="text"
          onChange={handleChange}
          onBlur={handleChange}
          invalid={postcode.invalid}
          error={postcode.error}
          data-testid="input-field-postcode"
        />
        <Dropdown
          className={address.input}
          name="country"
          label="Country"
          aria-label="Country"
          options={countryOptions}
          selectedItem={country.value}
          handleChange={(selection) => handleChangeCountry(selection)}
          handleBlur={(selection) => handleChangeCountry(selection)}
          invalid={country.invalid}
          error={country.error}
          notFoundText="Country not found"
          data-testid="input-field-country"
        />
      </div>
    </AccountSettingsForm>
  )
}

InvoicingAddressForm.propTypes = {
  account: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default InvoicingAddressForm
