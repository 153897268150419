import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'

import Button from '../../atoms/Button'

import PlusIcon from './plus.svg'

import styles from './styles.module.scss'

const NewPropertyDiscountAdvertisement = ({ className, expires }) => {
  const expiryDate = moment(expires)
  const time = expiryDate
    .format('LT')
    .replace(' ', '')
    .toLowerCase()
  const day = expiryDate.format('dddd')
  const date = expiryDate.format('D MMM YYYY')

  return (
    <article className={classNames(styles.container, className)}>
      <div className={styles.inner}>
        <div>
          <p className={styles.heading}>
            <strong>Save 10%</strong> when you add a new property on AFS and advertise it.
          </p>
          <time className={styles.expiryDateAndTimeTablet} dateTime={expires}>
            Expiry:{' '}
            <strong>
              {day}, {date}, {time}
            </strong>
          </time>
        </div>
        <Button className={styles.button} primary to="/landlord-admin/add-property/address">
          <PlusIcon className={styles.buttonIcon} /> Add a new property
        </Button>

        <time className={styles.expiryDateAndTimeMobile} dateTime={expires}>
          Expiry:{' '}
          <strong>
            {day}, {date}, {time}
          </strong>
        </time>

        <span className={styles.offerExpiryTextDesktop}>Offer expires</span>
        <div className={styles.expiryDateAndTimeDesktop}>
          <time className={styles.dateAndTimeBox} dateTime={expires} data-testid="day-and-date">
            <span className={styles.dateAndTimeHeading}>{day}</span>
            <span className={styles.dateAndTimeSubheading}>{date}</span>
          </time>
          <time className={styles.dateAndTimeBox} dateTime={expires} data-testid="time">
            <span className={styles.dateAndTimeHeading}>{time}</span>
          </time>
        </div>
      </div>
    </article>
  )
}

NewPropertyDiscountAdvertisement.propTypes = {
  className: PropTypes.string,
  expires: PropTypes.string.isRequired,
}

export default NewPropertyDiscountAdvertisement
