import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router'
import PropTypes from 'prop-types'

import { useAuthContext } from '../../authContext'
import { getAccountPaymentSettings, deleteAccountSavedCard } from '../../api/accountApi'
import { notificationService } from '../../container'

import PaymentSettingsPage from '../pages/PaymentSettingsPage'
import LoadingPage from '../pages/LoadingPage'

export const PaymentSettingsPageContainer = ({
  history,
  getPaymentSettings,
  removeSavedCard,
  useAuthContext,
  redirect,
  paymentSettingsPage,
}) => {
  const [accountHasCards, setAccountHasCards] = useState(null)
  const [accountHasSubscriptions, setAccountHasSubscriptions] = useState(null)
  const [cards, setCards] = useState([])
  const [subscriptions, setSubscriptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingForm, setLoadingForm] = useState(false)
  const [serverError, setServerError] = useState(false)
  const [showPageNotifications, setShowPageNotifications] = useState(false)

  const { token } = useAuthContext()

  useEffect(() => {
    setLoading(true)
    getPaymentSettings(token).then(response => {
      if (response.success) {
        setAccountHasCards(response.hasCards)
        setCards(response.cards)
        // Fake subscriptions
        setAccountHasSubscriptions(response.hasSubscriptions)
        setSubscriptions(response.subscriptions)
        setLoading(false)
      } else {
        setServerError(true)
      }
    })
  }, [])

  const refreshCards = token => {
    return getPaymentSettings(token).then(response => {
      if (response.success) {
        setAccountHasCards(response.hasCards)
        setCards(response.cards)
      } else {
        setServerError(true)
      }
      return response
    })
  }

  const updateOnSuccess = lastDigits => {
    setShowPageNotifications(true)
    setLoadingForm(false)
    notificationService.showSuccessNotification(
      '',
      `Your card ending in ${lastDigits} has been removed.`
    )
  }

  const updateOnFailure = lastDigits => {
    setShowPageNotifications(false)
    setLoadingForm(false)
    notificationService.showErrorNotification(
      'Error.',
      `Your card ending in ${lastDigits} could not be removed. Please try again later.`
    )
  }

  const removeCard = (id, lastDigits) => {
    notificationService.clearNotifications()
    setLoadingForm(true)

    return removeSavedCard(id, token).then(removeCardResponse => {
      if (removeCardResponse.success) {
        return refreshCards(token).then(refreshCardsResponse => {
          if (refreshCardsResponse.success) {
            updateOnSuccess(lastDigits)
          } else {
            setServerError(true)
          }
          return refreshCardsResponse
        })
      } else {
        updateOnFailure(lastDigits)
        return removeCardResponse
      }
    })
  }

  if (serverError) {
    return redirect('/landlord-admin/error')
  } else {
    return loading ? (
      <LoadingPage />
    ) : (
      paymentSettingsPage(
        history,
        accountHasCards,
        accountHasSubscriptions,
        cards,
        subscriptions,
        removeCard,
        loadingForm,
        showPageNotifications
      )
    )
  }
}

PaymentSettingsPageContainer.propTypes = {
  history: PropTypes.object.isRequired,
  getPaymentSettings: PropTypes.func.isRequired,
  removeSavedCard: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  paymentSettingsPage: PropTypes.func.isRequired,
}

export const PaymentSettingsPageContainerCompositionRoot = ({ history }) => {
  return (
    <PaymentSettingsPageContainer
      history={history}
      getPaymentSettings={getAccountPaymentSettings}
      removeSavedCard={deleteAccountSavedCard}
      useAuthContext={useAuthContext}
      redirect={path => <Redirect to={path} />}
      paymentSettingsPage={(
        history,
        accountHasCards,
        accountHasSubscriptions,
        cards,
        subscriptions,
        handleRemoveSavedCard,
        loadingForm,
        showNotifications
      ) => (
        <PaymentSettingsPage
          history={history}
          accountHasCards={accountHasCards}
          accountHasSubscriptions={accountHasSubscriptions}
          cards={cards}
          subscriptions={subscriptions}
          handleRemoveSavedCard={handleRemoveSavedCard}
          loadingForm={loadingForm}
          showNotifications={showNotifications}
        />
      )}
    />
  )
}

PaymentSettingsPageContainerCompositionRoot.propTypes = {
  history: PropTypes.object.isRequired,
}
