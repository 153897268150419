import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { config } from '../../../../config'
import ReviewPageViewModel from '../../../../models/reviewPageViewModel'

import EditCard from '../../../molecules/EditCard'
import Link from '../../../atoms/Link'

import reviewPage from './reviewPage.module.scss'

const ReviewPage = ({ values, propertyId }) => {
  const viewModel = new ReviewPageViewModel(values)
  const photoCount = viewModel.uploadedPhotosCount()
  const noVideoTour = viewModel.videoTourUrl === ''
  const noVirtualTour = viewModel.virtualTourUrl === ''

  const createLinkForPath = path => {
    return `/landlord-admin/property/${propertyId}/edit/${path}`
  }

  return (
    <div className={reviewPage.collection}>
      <EditCard className={reviewPage.card} title="Address" disabled>
        <p>
          {viewModel.address.firstTwoLines()}
          <br />
          <span>{viewModel.address.withoutFirstTwoLinesAndPostcode().join(', ')},</span>
          <span className={reviewPage.postcode}> {viewModel.address.postcodeOnly()}</span>
        </p>
      </EditCard>
      <EditCard
        className={reviewPage.card}
        title="Let type"
        link={createLinkForPath('letting-type')}
        data-testid="edit-letting-type"
      >
        <p>{viewModel.lettingType}</p>
      </EditCard>
      <EditCard
        className={reviewPage.card}
        title="Property availability"
        link={createLinkForPath('availability')}
        data-testid="edit-availability"
      >
        <p>Available from {viewModel.availableFrom}</p>
      </EditCard>
      <EditCard className={reviewPage.card} title="Accommodation type" disabled>
        <p>{viewModel.accommodationType}</p>
      </EditCard>
      <EditCard
        className={reviewPage.card}
        title="Bedrooms & Rent"
        link={createLinkForPath('bedrooms')}
        linkPosition="top"
        data-testid="edit-bedrooms"
      >
        <table className={reviewPage.table}>
          <tbody>
            {viewModel.formattedBedrooms().map((bedroomItem, i) => (
              // eslint-disable-next-line
              <tr key={i} className={reviewPage.tableRow} data-testid="bedroom-row">
                <td className={reviewPage.tablePosition}>{`${bedroomItem.position}.`}</td>
                <td data-testid="bedroom-description">
                  {bedroomItem.formattedStrings[0]}
                  <br />
                  {bedroomItem.formattedStrings[1]}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </EditCard>
      <EditCard
        className={reviewPage.card}
        title="Bathrooms"
        link={createLinkForPath('bathrooms')}
        data-testid="edit-bathrooms"
      >
        <p>{viewModel.bathrooms}</p>
      </EditCard>
      <EditCard
        className={reviewPage.card}
        title="Amenities"
        link={createLinkForPath('amenities')}
        linkPosition="top"
        data-testid="edit-amenities"
      >
        <table className={reviewPage.table}>
          <tbody>
            {viewModel.amenities.map((amenity, i) => (
              // eslint-disable-next-line
              <tr key={i} className={reviewPage.tableRow}>
                <td className={reviewPage.tableQuantity}>{`${amenity.options.length}x`}</td>
                <td>{amenity.title}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </EditCard>
      {viewModel.isBillsOptional ? (
        <EditCard
          className={classNames(reviewPage.card, reviewPage.cardBillsOptional)}
          title="Bills"
          showLink={false}
          linkPosition="top"
          data-testid="edit-bills"
        >
          <>
            <p>Bills optional quote enabled.</p>
            <p>
              If you want to remove the bills optional quote from this property, go to{' '}
              <Link to="/landlord-admin/bills-optional/properties">Bills optional.</Link>
            </p>
          </>
        </EditCard>
      ) : (
        <EditCard
          className={reviewPage.card}
          title="Bills"
          link={createLinkForPath('bills')}
          linkPosition="middle"
          data-testid="edit-bills"
        >
          <p>
            {viewModel.bills.length > 0
              ? viewModel.formattedBillsIncludedString()
              : 'No bills included'}
          </p>
        </EditCard>
      )}
      <EditCard
        className={reviewPage.card}
        title="Photos"
        link={createLinkForPath('photos')}
        data-testid="edit-photos"
      >
        <p>{photoCount > 0 ? `${photoCount}x Uploaded Photos` : 'No photos uploaded'}</p>
      </EditCard>
      <EditCard
        className={reviewPage.card}
        title="Video tour"
        link={createLinkForPath('video-tour')}
        data-testid="edit-video-tour"
      >
        <p>{noVideoTour ? 'No video tour' : 'YouTube link'}</p>
      </EditCard>
      {config.showEditVirtualTour && (
        <EditCard
          className={reviewPage.card}
          title="360° virtual tour"
          link={createLinkForPath('virtual-tour')}
          data-testid="edit-virtual-tour"
        >
          <p>{noVirtualTour ? 'No virtual tour' : 'Matterport virtual tour link'}</p>
        </EditCard>
      )}
      <EditCard
        className={reviewPage.card}
        title="Description"
        link={createLinkForPath('description')}
        data-testid="edit-description"
      >
        <p>{viewModel.truncatedDescription(70)}</p>
      </EditCard>
      <EditCard
        className={reviewPage.card}
        title="Reference number"
        link={createLinkForPath('reference-number')}
        data-testid="edit-reference-number"
      >
        <p>{viewModel.referenceNumber ? viewModel.referenceNumber : 'Not specified'}</p>
      </EditCard>
    </div>
  )
}

ReviewPage.propTypes = {
  values: PropTypes.object.isRequired,
  propertyId: PropTypes.string.isRequired,
}

export default ReviewPage
