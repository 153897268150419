export default config => {
  let pageConfig = config
  let subscribers = []

  const isValid = validationResult => {
    return validationResult && Object.keys(validationResult).length === 0
  }

  const pageById = id => {
    return pageConfig.find(page => page.id === id)
  }

  const lastPageId = () => {
    return pageConfig.length - 1
  }

  const getById = pageId => {
    const matchedPage = pageById(pageId)
    return {
      component: matchedPage.component,
      validate: matchedPage.validate,
      heading: matchedPage.heading,
      headingComponent: matchedPage.headingComponent,
      subheading: matchedPage.subheading,
      subheadingComponent: matchedPage.subheadingComponent,
      button: matchedPage.button,
      optional: matchedPage.optional,
      noBackLink: matchedPage.noBackLink || false,
      onSubmit: matchedPage.onSubmit,
    }
  }

  const getRouteById = pageId => {
    return pageById(pageId).route
  }

  const getIdByRoute = route => {
    const matchedPage = pageConfig.find(page => page.route === route)
    return matchedPage.id
  }

  const isLastPage = currentPageId => {
    return currentPageId === lastPageId()
  }

  const isPageValid = pageId => {
    return pageById(pageId).isValid
  }

  const updatePageValidity = (pageId, values) => {
    const page = pageById(pageId)
    const pageIndex = pageConfig.indexOf(page)
    const updatedPage = { ...page, isValid: isValid(page.validate(values)) }
    pageConfig = Object.assign([], pageConfig, { [pageIndex]: updatedPage })
  }

  const addPageValiditySubscriber = subscriber => {
    if (subscribers.find(s => s.id === subscriber.id)) {
      return
    }
    subscribers.push(subscriber)
  }

  const derivePageStateFromConfig = () => {
    return pageConfig.map(page => ({ id: page.id, isValid: page.isValid }))
  }

  const notifySubscribersOfPageValidityUpdate = pages => {
    subscribers.forEach(subscriber => {
      subscriber.callback(pages)
    })
  }

  const notifySubscribersOfPageValidity = () => {
    const pageState = derivePageStateFromConfig()
    notifySubscribersOfPageValidityUpdate(pageState)
  }

  const removeSubscriberById = subscriberId => {
    subscribers = subscribers.filter(subscriber => subscriber.id !== subscriberId)
  }

  return {
    lastPageId,
    getById,
    getRouteById,
    getIdByRoute,
    isLastPage,
    isPageValid,
    updatePageValidity,
    addPageValiditySubscriber,
    notifySubscribersOfPageValidity,
    removeSubscriberById,
  }
}
