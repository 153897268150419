import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

import { Helmet } from 'react-helmet-async'

import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'

const LoginRegisterPageTemplate = ({ children, title, ...props }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="login-register" {...props}>
        <Header />
        <main className="site-main login-register__inner">{children}</main>
        <Footer />
      </div>
    </>
  )
}

LoginRegisterPageTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export default LoginRegisterPageTemplate
