const BrowserBannerService = (config, cookies, browserIsSupported) => {
  const hasBeenDismissed = () => {
    const bannerDismissed = cookies.get('BBD')
    if (bannerDismissed === undefined) return false
    return JSON.parse(bannerDismissed)
  }

  const shouldShowBanner = () => {
    const browserBannerIsDisabled = config.disableBrowserBanner

    if (browserBannerIsDisabled) return false

    if (browserIsSupported()) return false

    return !browserIsSupported() && !hasBeenDismissed()
  }

  const dismissBanner = () => {
    cookies.set('BBD', true, { expires: 1, domain: config.cookieDomain, path: '/' })
  }

  return {
    shouldShowBanner,
    dismissBanner,
  }
}

export default BrowserBannerService
