import React from 'react'
import PropTypes from 'prop-types'

import { resetPasswordFormViewModel, notificationService } from '../../container'

import NewLandlordAdminPasswordResetPage from '../pages/NewLandlordAdminPasswordResetPage'

const NewLandlordAdminPasswordResetPageContainer = ({ history }) => {
  return (
    <NewLandlordAdminPasswordResetPage
      history={history}
      resetPasswordFormViewModel={resetPasswordFormViewModel}
      notificationService={notificationService}
    />
  )
}

NewLandlordAdminPasswordResetPageContainer.propTypes = {
  history: PropTypes.object.isRequired,
}

export default NewLandlordAdminPasswordResetPageContainer
