import React from 'react'

import ManagePropertiesPageTemplate from '../../templates/ManagePropertiesPageTemplate'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Button from '../../atoms/Button'
import NotFoundAnnouncement from '../../molecules/NotFoundAnnouncement'

import Error from '../../../svgs/illustrations/error.svg'

import errorPage from './errorPage.module.scss'

const ErrorPage = () => {
  return (
    <ManagePropertiesPageTemplate
      title="Something Went Wrong | AFS"
      header={<Header isError />}
      footer={<Footer dark />}
    >
      <div className={errorPage.layout} data-testid="error-page">
        <NotFoundAnnouncement
          className={errorPage.link}
          icon={Error}
          heading="Something went wrong"
          subHeading={`A technical issue has occurred. Please try again later. If this error persists, please <a href="mailto:support@accommodationforstudents.com" target="_blank"><u>contact support</u></a>.`}
          button={
            <Button to="/landlord-admin" primary data-testid="overview-button">
              Back to Dashboard
            </Button>
          }
          data-testid="server-error"
        />
      </div>
    </ManagePropertiesPageTemplate>
  )
}

export default ErrorPage
