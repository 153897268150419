import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Transition } from 'react-spring/renderprops.cjs'

import styles from './accordion.module.scss'

const Accordion = ({
  className,
  id,
  isExpanded,
  renderTitle,
  renderHeaderElement,
  items,
  ...props
}) => {
  const [expanded, setExpanded] = useState(isExpanded)

  const componentClasses = classNames(styles.accordion, className)
  const itemWrapperClasses = classNames(styles.items, {
    [styles.itemsCollapsed]: !expanded,
  })

  const handleOnClick = e => {
    e.preventDefault()
    setExpanded(!expanded)
  }

  return (
    <div className={componentClasses} {...props}>
      <div className={styles.header}>
        <button
          className={styles.button}
          onClick={handleOnClick}
          aria-expanded={expanded ? 'true' : 'false'}
          aria-controls={`${id}-dropdown`}
        >
          {renderTitle(expanded)}
        </button>
        {renderHeaderElement && renderHeaderElement(expanded)}
      </div>
      <div className={itemWrapperClasses} id={`${id}-dropdown`}>
        <Transition
          items={items}
          keys={item => item.key}
          from={{ marginTop: '0px', opacity: 1 }}
          enter={{ marginTop: '0px', opacity: 1 }}
          leave={{ marginTop: '0px', opacity: 0 }}
          update={expanded ? { marginTop: '0px', opacity: 1 } : { marginTop: '-200px', opacity: 0 }}
          config={(item, type) => {
            if (type === 'leave') return { duration: 500 }
            else return { duration: 300 }
          }}
        >
          {item => props => (
            <div
              style={{
                ...props,
                visibility: props.opacity === 0 ? 'hidden' : 'visible',
              }}
            >
              {item}
            </div>
          )}
        </Transition>
      </div>
    </div>
  )
}

Accordion.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  renderTitle: PropTypes.func.isRequired,
  renderHeaderElement: PropTypes.func,
  items: PropTypes.array.isRequired,
}

export default Accordion
