import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { config } from '../../../config'

import LazyImage from '../../atoms/LazyImage'

import NoImagesIcon from './svgs/no-image.svg'
import QuoteIcon from './svgs/quote-icon.svg'
import ChevronIcon from './svgs/forward-arrow.svg'

import styles from './styles.module.scss'

const BillsOptionalQuoteSuccessCard = forwardRef(
  ({ className, property, handleRemoveProperty, renderQuoteDetailsOverlay }, ref) => {
    const { photo, hasPhoto, address, quote } = property

    const renderButton = onClick => (
      <button
        ref={ref}
        className={classNames(styles.card, className)}
        onClick={onClick}
        data-testid="quote-success-card"
      >
        <div className={styles.propertyDetailsWrapper}>
          <div className={styles.imageWrapper}>
            {hasPhoto ? (
              <LazyImage
                className={styles.propertyImage}
                src={`${config.imgApiUrl}w=112,h=112,fit=cover/${photo}`}
                dataSrc={`${config.imgApiUrl}w=112,h=112,fit=cover/${photo}`}
                alt={address.line1}
                data-testid="property-image"
              />
            ) : (
              <NoImagesIcon className={styles.noImagesIcon} data-testid="no-property-image" />
            )}
          </div>
          <div className={styles.addressWrapper}>
            <p className={styles.line1}>{`${address.line1},`}</p>
            <p className={styles.line2}>{address.line2}</p>
          </div>
        </div>
        <div className={styles.quoteDetailsWrapper}>
          <div className={styles.quoteSummary}>
            <div className={styles.titleWrapper}>
              <QuoteIcon className={styles.quoteIcon} />
              <span className={styles.quoteTitle}>Bills optional</span>
            </div>
            <div className={styles.priceWrapper}>
              <span className={styles.price}>{quote.totalPricePppw}</span>
              <span className={styles.priceBreakdown}>per person/week</span>
            </div>
          </div>
        </div>
        <ChevronIcon className={styles.chevron} />
      </button>
    )

    return renderQuoteDetailsOverlay(property, renderButton, handleRemoveProperty)
  }
)

BillsOptionalQuoteSuccessCard.propTypes = {
  className: PropTypes.string,
  property: PropTypes.shape({
    propertyId: PropTypes.number.isRequired,
    photo: PropTypes.string.isRequired,
    hasPhoto: PropTypes.bool.isRequired,
    address: PropTypes.shape({
      line1: PropTypes.string.isRequired,
      line2: PropTypes.string.isRequired,
    }),
    rentFrom: PropTypes.string.isRequired,
    quote: PropTypes.shape({
      lastUpdated: PropTypes.string,
      totalPricePppw: PropTypes.string.isRequired,
      services: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          pricePppw: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          available: PropTypes.bool.isRequired,
        })
      ),
    }).isRequired,
  }).isRequired,
  handleRemoveProperty: PropTypes.func.isRequired,
  renderQuoteDetailsOverlay: PropTypes.func.isRequired,
}

export default BillsOptionalQuoteSuccessCard
