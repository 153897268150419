import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useStateContext } from '../../../appStateContext'
import Products from '../../../models/products'
import { GBP } from '../../../models/currency'

import TwoColumnPageTemplate from '../../templates/TwoColumnPageTemplate'
import Header from '../../organisms/Header'
import FormPageHeading from '../../molecules/FormPageHeading'
import FieldBoostQuantityGroup from '../../molecules/FieldBoostQuantityGroup'
import ProceedToPaymentConfirmation from '../../molecules/ProceedToPaymentConfirmation'

import styles from './buyBoostCreditsPage.module.scss'

export const BoostCreditOptions = ({ history }) => {
  const [boostQuantity, setBoostQuantity] = useState({})
  const { basket } = useStateContext()
  const products = Products()
  const currency = GBP

  useEffect(() => {
    basket.clear()
  }, [])

  const boostCreditOptions = [
    {
      numberOfCredits: 10,
      totalCost: products.boostCreditsTen.price,
      costPerCredit: products.boostCreditsTen.priceEach,
      currency: currency.display,
    },
    {
      numberOfCredits: 25,
      totalCost: products.boostCreditsTwentyFive.price,
      costPerCredit: products.boostCreditsTwentyFive.priceEach,
      currency: currency.display,
    },
    {
      numberOfCredits: 50,
      totalCost: products.boostCreditsFifty.price,
      costPerCredit: products.boostCreditsFifty.priceEach,
      currency: currency.display,
    },
    {
      numberOfCredits: 100,
      totalCost: products.boostCreditsOneHundred.price,
      costPerCredit: products.boostCreditsOneHundred.priceEach,
      currency: currency.display,
    },
  ]

  const handleSubmit = event => {
    event.preventDefault()

    switch (boostQuantity.numberOfCredits) {
      case 10:
        basket.addTenBoostCredits()
        break
      case 25:
        basket.addTwentyFiveBoostCredits()
        break
      case 50:
        basket.addFiftyBoostCredits()
        break
      case 100:
        basket.addOneHundredBoostCredits()
        break
      default:
    }

    history.push('/landlord-admin/checkout')
  }

  const isFormInvalid = !boostQuantity.totalCost

  return (
    <>
      <FormPageHeading
        heading="Buy boost credits"
        subheading="Select the amount of credits that works best for you."
      />
      <form className={styles.form} onSubmit={handleSubmit}>
        <FieldBoostQuantityGroup
          options={boostCreditOptions}
          selectedOption={boostQuantity}
          handleSelectOption={setBoostQuantity}
        />
        <ProceedToPaymentConfirmation
          className={styles.paymentConfirmation}
          subtotal={boostQuantity.totalCost}
          disabled={isFormInvalid}
        >
          Proceed to Payment
        </ProceedToPaymentConfirmation>
      </form>
    </>
  )
}

BoostCreditOptions.propTypes = {
  history: PropTypes.object,
}

export const BuyBoostCreditsPage = ({ history }) => (
  <TwoColumnPageTemplate
    header={<Header />}
    title="Buy boost credits | AFS"
    className={styles.page}
    leftColumn={<BoostCreditOptions history={history} />}
  />
)

BuyBoostCreditsPage.propTypes = {
  history: PropTypes.object,
}
