import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Spinner from '@afs/components/Spinner'

import Portal from '../../atoms/Portal'

import styles from './updatingOverlay.module.scss'

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

const UpdatingPropertiesOverlay = ({ children }) => {
  let yOffset

  const lockBodyElement = () => {
    if (canUseDOM) {
      yOffset = window.pageYOffset

      document.body.classList.add('no-scroll')
      document.body.style.top = `-${yOffset}px`
    }
  }

  const unlockBodyElement = () => {
    if (canUseDOM) {
      document.body.classList.remove('no-scroll')
    }
  }

  useEffect(() => {
    lockBodyElement()
    return () => unlockBodyElement()
  }, [])

  return (
    <Portal id="updating-overlay">
      <div className={styles.overlay} data-testid="updating-overlay">
        <Spinner className={styles.spinner} data-testid="updating-overlay-spinner" />
        <p className={styles.text} data-testid="updating-overlay-text">
          {children}
        </p>
      </div>
    </Portal>
  )
}

UpdatingPropertiesOverlay.propTypes = {
  children: PropTypes.any,
}

export default UpdatingPropertiesOverlay
