import React, { useState } from 'react'
import PropTypes from 'prop-types'

import AccountSettingsForm from '../../../molecules/AccountSettingsForm'
import FieldOptionGroup from '../../../molecules/FieldOptionGroup'

import styles from './styles.module.scss'

const ReceiveExclusiveOffersForm = ({ account, onSave, loading, ...props }) => {
  const [field, setField] = useState({
    value: account.receiveOffers(),
    touched: false,
    error: '',
  })

  const agree = 'Yes'
  const decline = 'No'

  const handleChange = selectedValue => {
    const value = selectedValue === agree

    setField(prevState => ({
      ...prevState,
      value,
      touched: true,
    }))
  }

  const handleSave = event => {
    event.preventDefault()

    const fieldName = 'receiveExclusiveOffers'
    const payload = {
      [fieldName]: field.value,
    }
    onSave(fieldName, payload)
  }

  return (
    <AccountSettingsForm onSubmit={handleSave} loading={loading} {...props}>
      <FieldOptionGroup
        className={styles.optionGroup}
        name="receiveExclusiveOffers"
        legend="Receive exclusive offers from AFS and/or other third parties"
        options={[agree, decline]}
        onSelectOption={handleChange}
        selectedOption={field.value ? agree : decline}
        data-testid="input-field-receive-exclusive-offers"
      />
    </AccountSettingsForm>
  )
}

ReceiveExclusiveOffersForm.propTypes = {
  account: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default ReceiveExclusiveOffersForm
