import React from 'react'
import PropTypes from 'prop-types'

import ManagePropertiesPageTemplate from '../../templates/ManagePropertiesPageTemplate'
import ManagePropertiesHero from '../../atoms/ManagePropertiesHero'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Heading from '../../atoms/Heading'
import EnquiryGrid from '../../organisms/EnquiryGrid'
import NotFoundAnnouncement from '../../molecules/NotFoundAnnouncement'

import NoEnquiries from '../../../svgs/illustrations/no-enquiries.svg'

import hero from '../PropertiesPage/modules/hero.module.scss'
import layout from '../PropertiesPage/modules/layout.module.scss'
import noProperties from '../PropertiesPage/modules/noProperties.module.scss'

import enquiriesPage from './enquiriesPage.module.scss'

const EnquiriesPageFeeds = ({ enquiries, deleteEnquiry }) => {
  const renderHero = () => {
    return (
      <ManagePropertiesHero
        className={enquiriesPage.hero}
        hasPatternBackgroundOnMobile
        pattern="green"
      >
        <div className={hero.row}>
          <Heading className={hero.heading}>Enquiries</Heading>
        </div>
      </ManagePropertiesHero>
    )
  }

  return (
    <ManagePropertiesPageTemplate
      title="Your Enquiries | AFS"
      header={<Header />}
      footer={<Footer dark />}
      hero={renderHero()}
    >
      <div className={layout.inner}>
        {enquiries.length === 0 && (
          <NotFoundAnnouncement
            className={noProperties.wrapper}
            icon={NoEnquiries}
            heading="No enquiries"
            subHeading="You haven’t received any enquiries just yet."
            data-testid="no-enquiries"
          />
        )}
        {enquiries.length > 0 && (
          <EnquiryGrid
            data-testid="enquiry-grid"
            enquiries={enquiries}
            deleteEnquiry={deleteEnquiry}
            isFeedAccount
          />
        )}
      </div>
    </ManagePropertiesPageTemplate>
  )
}

EnquiriesPageFeeds.propTypes = {
  enquiries: PropTypes.array.isRequired,
  deleteEnquiry: PropTypes.func.isRequired,
}

export default EnquiriesPageFeeds
