import React from 'react'
import PropTypes from 'prop-types'
import FieldLettingType from '../molecules/FieldLettingType'

const FieldLettingTypeAdapter = ({ input: { name, onChange, value }, label }) => (
  <FieldLettingType
    name={name}
    selectedLettingType={value}
    label={label}
    onSelectLettingType={selectedType => onChange(selectedType)}
  />
)

FieldLettingTypeAdapter.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
}

export default FieldLettingTypeAdapter
