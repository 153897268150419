import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { notificationService, randomIdGenerator } from '../../../container'

import Notification from '../../molecules/Notification'

export const NotificationSubscriberIds = {
  ManagePropertyListingsTemplate: 'manage-properties-page-template',
}

const Notifications = ({ className, id, preventDoubleAnimation }) => {
  const [notifications, setNotifications] = useState([])
  const [renderCount, setRenderCount] = useState(0)
  const subscriberId = id || randomIdGenerator.timeNowInMilliseconds()

  const disableAnimationOnRerenderToPreventDoubleAnimation = () =>
    preventDoubleAnimation && renderCount === 1

  const updateNotifications = notifications => {
    setRenderCount(renderCount + 1)
    setNotifications(notifications)
  }

  useEffect(() => {
    notificationService.addSubscriber({ id: subscriberId, callback: updateNotifications })

    return () => {
      notificationService.removeSubscriberById(subscriberId)
    }
  }, [])

  const deferredNotifications = notificationService.showDeferredNotifications()

  const hasNotifications = notifications.length > 0 || deferredNotifications.length > 0

  // eslint-disable-next-line
  const Wrapper = ({ children }) => {
    return hasNotifications ? <div className={className}>{children}</div> : <>{children}</>
  }

  const hasNotExpiredAndShouldShow = notification => {
    const timeNotificationShouldBeVisibleForAfterCreationInMilliseconds = 500
    return (
      notification.created + timeNotificationShouldBeVisibleForAfterCreationInMilliseconds >=
      Date.now()
    )
  }

  return (
    <Wrapper>
      {deferredNotifications.map(notification => (
        <Notification
          key={`${notification.id}-${notification.heading}-${notification.text}`}
          type={notification.type}
          heading={notification.heading}
          text={notification.text}
        />
      ))}
      {notifications
        .filter(notification => hasNotExpiredAndShouldShow(notification))
        .map(notification => (
          <Notification
            disableAnimation={disableAnimationOnRerenderToPreventDoubleAnimation()}
            key={`${notification.id}-${notification.heading}-${notification.text}`}
            type={notification.type}
            heading={notification.heading}
            text={notification.text}
          />
        ))}
    </Wrapper>
  )
}

Notifications.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  preventDoubleAnimation: PropTypes.bool,
}

export default Notifications
