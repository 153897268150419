import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Label from '@afs/components/Label'

import useMedia from '../../../hooks/useMedia'

import TickIcon from '../../../svgs/icons/tick-circle.svg'
import CardVisa from '../../../svgs/card-logos/visa.svg'
import CardMastercard from '../../../svgs/card-logos/mastercard.svg'
import CardAmex from '../../../svgs/card-logos/amex.svg'
import CardUnknown from '../../../svgs/card-logos/default.svg'

import styles from './savedCard.module.scss'

const BrandIcon = ({ brand, ...props }) => {
  switch (brand) {
    case 'visa':
      return <CardVisa {...props} />

    case 'mastercard':
      return <CardMastercard {...props} />

    case 'amex':
      return <CardAmex {...props} />

    default:
      return <CardUnknown {...props} />
  }
}

const SavedCard = ({ className, card, isSelectable, checked, ...props }) => {
  const isMobile = useMedia(['(max-width: 810px)'], [true], false)

  const { brand, lastDigits, expiryMonth, expiryYear, paymentMethodId, hasExpired } = card
  const cardId = `card-${paymentMethodId}`

  const hasExpiryDate = expiryMonth && expiryYear
  const expiresText = isMobile ? 'Exp' : 'Expires'

  const componentClasses = classNames(
    styles.label,
    { [styles.labelExpired]: hasExpired },
    className
  )

  return isSelectable ? (
    <>
      <input
        id={cardId}
        className={styles.input}
        type="radio"
        name="payment_method"
        value={paymentMethodId}
        defaultChecked={checked}
        data-testid="saved-card-radio"
      />
      <Label htmlFor={cardId} className={componentClasses} {...props}>
        <span className={styles.leftSide}>
          <TickIcon className={styles.tick} />
          <BrandIcon className={styles.brand} brand={brand} />
          <span className={styles.hiddenDigits}>····</span>{' '}
          <span className={styles.lastDigits}>{lastDigits}</span>
        </span>
        {hasExpired && <span className={styles.expired}>Expired</span>}
        {hasExpiryDate && !hasExpired && (
          <span className={styles.expiry}>{`${expiresText} ${expiryMonth} / ${expiryYear}`}</span>
        )}
      </Label>
    </>
  ) : (
    <div className={componentClasses} {...props}>
      <span className={styles.leftSide}>
        <BrandIcon className={styles.brand} brand={brand} />
        <span className={styles.hiddenDigits}>····</span>{' '}
        <span className={styles.lastDigits} data-lastdigits={lastDigits}>
          {lastDigits}
        </span>
      </span>
      {hasExpired && <span className={styles.expired}>Expired</span>}
      {hasExpiryDate && !hasExpired && (
        <span className={styles.expiry}>{`${expiresText} ${expiryMonth} / ${expiryYear}`}</span>
      )}
    </div>
  )
}

BrandIcon.propTypes = {
  brand: PropTypes.string.isRequired,
}

SavedCard.propTypes = {
  className: PropTypes.string,
  card: PropTypes.shape({
    brand: PropTypes.string,
    lastDigits: PropTypes.string,
    hasExpired: PropTypes.bool,
    expiryMonth: PropTypes.string,
    expiryYear: PropTypes.string,
    paymentMethodId: PropTypes.string,
  }),
  isSelectable: PropTypes.bool,
  checked: PropTypes.bool,
}

export default SavedCard
