import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import InvoicesPage from '../pages/InvoicesPage'
import LoadingPage from '../pages/LoadingPage'
import { useAuthContext } from '../../authContext'
import { getAllInvoices } from '../../api/invoicesApi'

export const InvoicesContainer = ({ invoicesPage, getAllInvoices, useAuthContext }) => {
  const [invoices, setInvoices] = useState({})
  const [loading, setLoading] = useState(true)
  const { token } = useAuthContext()

  useEffect(() => {
    getAllInvoices(token).then(response => {
      if (response.success) {
        setInvoices(response.invoices)
        setLoading(false)
      }
    })
  }, [])

  if (loading) return <LoadingPage />

  return invoicesPage(invoices)
}

InvoicesContainer.propTypes = {
  invoicesPage: PropTypes.func.isRequired,
  getAllInvoices: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
}

export const InvoicesContainerCompositionRoot = () => {
  return (
    <InvoicesContainer
      invoicesPage={invoices => <InvoicesPage invoices={invoices} />}
      useAuthContext={useAuthContext}
      getAllInvoices={getAllInvoices}
    />
  )
}
