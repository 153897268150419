import React from 'react'
import SpinnerButton from '@afs/components/SpinnerButton'

import {
  ReferenceNumberPage,
  referenceNumberPageValidation,
} from '../../FormPages/ReferenceNumberPage/ReferenceNumberPage'
import SubmitButton from '../../../atoms/SubmitButton'

const descriptionPageConfig = (propertyId, updatePropertyReferenceNumber) => ({
  id: 11,
  component: () => <ReferenceNumberPage />,
  route: `/landlord-admin/property/${propertyId}/edit/reference-number`,
  validate: values => referenceNumberPageValidation(values),
  isValid: false,
  heading: 'Reference number',
  subheading:
    'Commonly used by landlord or letting agencies for their own records. It is not displayed on the listing page.',
  button: (values, loading) => {
    if (loading)
      return (
        <SpinnerButton primary inactive>
          Continue
        </SpinnerButton>
      )

    return <SubmitButton data-testid="save-button">Save changes</SubmitButton>
  },
  onSubmit: async (values, token) => {
    const payload = {
      propertyId,
      referenceNumber: values.referenceNumber || '',
    }
    return updatePropertyReferenceNumber(payload, token)
  },
})

export default descriptionPageConfig
