const TabbingFocusEventListeners = (window, tabbingActionEventHandler, mouseDownEventHandler) => {
  const addTabbingFocusListener = () => {
    window.addEventListener('keydown', tabbingActionEventHandler)
  }

  const removeTabbingFocusListeners = () => {
    window.removeEventListener('keydown', tabbingActionEventHandler)
    window.removeEventListener('mousedown', mouseDownEventHandler)
  }

  return {
    addTabbingFocusListener,
    removeTabbingFocusListeners,
  }
}

export default TabbingFocusEventListeners
