import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

import Button from '../Button'

const SubmitButton = ({ children, ...props }) => (
  <Button className="submit-button" type="submit" primary {...props}>
    {children}
  </Button>
)

SubmitButton.propTypes = {
  children: PropTypes.node,
}

export default SubmitButton
