import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import './styles.scss'

import FieldTextDateAdapter from '../../../adapters/FieldTextDateAdapter'
import FieldTextDateOptionalAdapter from '../../../adapters/FieldTextDateOptionalAdapter'
import ValidatedDate from '../../../../models/validatedDate'

export const AvailabilityPage = () => (
  <div className="availability-page__container">
    <div className="availability-page__field">
      <Field name="availableFrom" component={FieldTextDateAdapter}>
        {props => <FieldTextDateAdapter {...props} label="Available from" />}
      </Field>
      <p className="availability-page__field-note">
        The <strong>earliest date</strong> from which the tenancy agreement can start; the 'Move in'
        date.
      </p>
    </div>
    <div className="availability-page__field">
      <Field name="availableUntil" component={FieldTextDateOptionalAdapter}>
        {props => <FieldTextDateOptionalAdapter {...props} label="Move out by" />}
      </Field>
    </div>
  </div>
)

export const availabilityPageValidation = values => {
  const errors = {}
  const invalidDateMessage = 'Date must be in the format: DD/MM/YYYY'

  const availableFromDateString = values.availableFrom
  const availableUntilDateString = values.availableUntil

  const availableFrom = ValidatedDate.forDate(availableFromDateString)
  const availableUntil = ValidatedDate.forDate(availableUntilDateString)

  const availableUntilIsInvalid = availableUntilDateString && availableUntil.isAnInvalidDate()
  const bothFromAndUntilDateAreValid = availableFrom.isAValidDate() && availableUntil.isAValidDate()

  if (availableFrom.isAnInvalidDate()) {
    errors.availableFrom = invalidDateMessage
  }

  if (availableFrom.isUndefined()) {
    errors.availableFrom = `'Available from' date is required.`
  }

  if (availableUntilIsInvalid) {
    errors.availableUntil = invalidDateMessage
  }

  if (bothFromAndUntilDateAreValid && availableUntil.isOnOrBefore(availableFromDateString)) {
    errors.availableUntil = "Date must be after the 'Available from' date."
  }

  return errors
}

AvailabilityPage.propTypes = {
  meta: PropTypes.object,
}
