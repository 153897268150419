import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.scss'

import Heading from '../../atoms/Heading'

const SummaryRow = ({
  description,
  price,
  quantity,
  isTotal,
  isDiscount,
  pricePrefix = '',
  ...props
}) => {
  const labelClasses = classNames('order-summary__label', {
    'order-summary__label--total': isTotal,
  })
  const priceClasses = classNames('order-summary__price', {
    'order-summary__price--total': isTotal,
    'order-summary__price--discount': isDiscount,
  })

  return (
    <div className="order-summary__row" {...props} data-testid="summary-row">
      <span className={labelClasses}>{` ${description} `}</span>

      <span className="order-summary__price-label">
        {quantity && (
          <span className="order-summary__quantity" data-testid="summary-row-quantity">
            <i className="order-summary__multiplier" data-testid="summary-row-multiplier">
              x
            </i>

            {quantity}
          </span>
        )}
        <span className={priceClasses}>{`${pricePrefix}£${Number.parseFloat(price).toFixed(
          2
        )}`}</span>
      </span>
    </div>
  )
}

const OrderSummary = ({
  className,
  heading,
  lineItems,
  subtotal,
  vat,
  total,
  discount,
  darkMode,
  ...props
}) => {
  const sectionClasses = classNames(
    'order-summary__section',
    {
      'order-summary__section--dark': darkMode,
    },
    className
  )

  return (
    <div className="order-summary" {...props}>
      {heading && (
        <Heading className="order-summary__heading" level={6}>
          {heading}
        </Heading>
      )}
      <div className={sectionClasses}>
        {lineItems.map((item) => (
          <SummaryRow key={item.description} {...item} />
        ))}
      </div>
      <div className={sectionClasses}>
        {discount.amount > 0 && (
          <SummaryRow
            description={discount.name}
            price={discount.amount}
            pricePrefix="- "
            isDiscount
          />
        )}
        <SummaryRow description="Subtotal (excl. VAT)" price={subtotal} />
        <SummaryRow description="VAT" price={vat} />
      </div>
      <div className={sectionClasses}>
        <SummaryRow description="Total" price={total} isTotal data-testid="order-summary-total" />
      </div>
    </div>
  )
}

SummaryRow.propTypes = {
  description: PropTypes.string.isRequired,
  pricePrefix: PropTypes.string,
  price: PropTypes.number.isRequired,
  quantity: PropTypes.number,
  isTotal: PropTypes.bool,
  isDiscount: PropTypes.bool,
}

OrderSummary.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  lineItems: PropTypes.array.isRequired,
  subtotal: PropTypes.number.isRequired,
  vat: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  discount: PropTypes.shape({
    name: PropTypes.string,
    amount: PropTypes.number,
  }),
  darkMode: PropTypes.bool,
}

export default OrderSummary
