import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'

import useMedia from '../../../hooks/useMedia'

import Notifications, { NotificationSubscriberIds } from '../../organisms/Notifications'

import page from './page.module.scss'

const ManagePropertiesPageTemplate = ({
  className,
  header,
  footer,
  title,
  hero,
  children,
  multiSelectMenu,
}) => {
  const mainClasses = classNames(page.main, className)

  const isMobile = useMedia(['(max-width: 768px)'], [true], false)
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {header}
      {hero}
      <main className={mainClasses}>
        {!isMobile && multiSelectMenu ? multiSelectMenu : null}
        <div className={page.content}>
          <Notifications
            id={NotificationSubscriberIds.ManagePropertyListingsTemplate}
            className={page.notifications}
            preventDoubleAnimation
          />
          {children}
        </div>
        {isMobile && multiSelectMenu ? multiSelectMenu : null}
      </main>
      {footer}
    </>
  )
}

ManagePropertiesPageTemplate.propTypes = {
  className: PropTypes.string,
  header: PropTypes.node,
  footer: PropTypes.node,
  title: PropTypes.string,
  hero: PropTypes.node,
  children: PropTypes.any,
  multiSelectMenu: PropTypes.node,
}

export default ManagePropertiesPageTemplate
