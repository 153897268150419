import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useAuthContext } from '../../authContext'
import {
  getTestimonials,
  getLandlordTestimonials,
  requestTestimonial,
  markTestimonialAsNotNew,
  toggleDisplayTestimonial,
  toggleDisplayLandlordTestimonial,
  deleteTestimonial,
} from '../../api/testimonialsApi'
import { notificationService } from '../../container'
import scrollToNotification from '../../utils/scrollToNotification'

import TestimonialsPage from '../pages/TestimonialsPage'
import LoadingPage from '../pages/LoadingPage'
import ErrorPage from '../pages/ErrorPage'

export const TestimonialsContainer = ({
  queryString,
  testimonialsPage,
  errorPage,
  useAuthContext,
  getTestimonials,
  getLandlordTestimonials,
  requestTestimonial,
  markTestimonialAsNotNew,
  toggleDisplayTestimonial,
  toggleDisplayLandlordTestimonial,
  deleteTestimonial,
  notificationService,
}) => {
  const [testimonials, setTestimonials] = useState({})
  const [landlordTestimonials, setLandlordTestimonials] = useState([])
  const [viewTestimonialParams, setViewTestimonialParams] = useState(null)
  const [loading, setLoading] = useState(true)
  const [serverError, setServerError] = useState(false)
  const { token, accountType } = useAuthContext()
  const isLettingAgent = accountType === 'LettingAgent'
  const windowElement = window

  const findPropertyByTestimonialId = (testimonials, testimonialId) => {
    return testimonials.properties.find(property =>
      property.testimonials.map(testimonial => testimonial.id).includes(testimonialId)
    )
  }

  const findPropertyIndexByTestimonialId = (testimonials, testimonialId) => {
    return testimonials.properties.findIndex(property =>
      property.testimonials.map(testimonial => testimonial.id).includes(testimonialId)
    )
  }

  const findTestimonialById = (property, testimonialId) => {
    return property.testimonials.find(testimonial => testimonial.id === testimonialId)
  }

  const requestTenantTestimonial = async payload => {
    const response = await requestTestimonial(payload, token)
    return response
  }

  const deleteTenantTestimonial = async testimonialId => {
    const response = await deleteTestimonial(testimonialId, token)

    if (response.success) {
      const property = findPropertyByTestimonialId(testimonials, testimonialId)
      if (!property) return

      const indexOfProperty = findPropertyIndexByTestimonialId(testimonials, testimonialId)

      property.testimonials = property.testimonials.filter(
        testimonial => testimonial.id !== testimonialId
      )
      property.testimonialsCount = property.testimonials.length

      const copyOfPropertiesInState = testimonials.properties
      copyOfPropertiesInState.splice(indexOfProperty, 1, property)

      setTestimonials(prevState => ({
        ...prevState,
        properties: copyOfPropertiesInState,
      }))
    }

    return response
  }

  const markTenantTestimonialAsNotNew = testimonialId => {
    const payload = {
      testimonialId,
    }

    return markTestimonialAsNotNew(payload, token)
  }

  const toggleDisplayTenantTestimonial = async (testimonialId, isDisplayed, overlayOpen) => {
    notificationService.clearNotifications()
    const payload = { testimonialId }
    const response = await toggleDisplayTestimonial(payload, token)

    if (response.success) {
      const updatedProperties = [...testimonials.properties]

      const propertyIndexToUpdate = findPropertyIndexByTestimonialId(testimonials, testimonialId)

      const testimonialIndexToUpdate = updatedProperties[
        propertyIndexToUpdate
      ].testimonials.findIndex(testimonial => testimonial.id === testimonialId)

      updatedProperties[propertyIndexToUpdate].testimonials[
        testimonialIndexToUpdate
      ].displayed = !isDisplayed

      setTestimonials(prevState => ({
        ...prevState,
        properties: updatedProperties,
      }))
    }

    if (!response.success && !overlayOpen) {
      notificationService.showErrorNotification(
        'Error.',
        "We couldn't change the display state of this testimonial. Please try again later."
      )
      scrollToNotification()
    }
    return response
  }

  const toggleDisplayTenantLandlordTestimonial = async (
    testimonialId,
    isDisplayed,
    overlayOpen
  ) => {
    notificationService.clearNotifications()
    const payload = { testimonialId }
    const response = await toggleDisplayLandlordTestimonial(payload, token)
    if (response.success) {
      const updatedTestimonials = [...landlordTestimonials]

      const testimonialToUpdate = updatedTestimonials.findIndex(
        testimonial => testimonial.id === testimonialId
      )

      updatedTestimonials[testimonialToUpdate].displayed = !isDisplayed

      setLandlordTestimonials(updatedTestimonials)
    }
    if (!response.success && !overlayOpen) {
      notificationService.showErrorNotification(
        'Error.',
        "We couldn't change the display state of this testimonial. Please try again later."
      )
      scrollToNotification()
    }
    return response
  }

  const getPropertyAndTestimonialFromQueryString = (queryString, testimonials) => {
    const query = new URLSearchParams(queryString)
    const testimonialIdString = query.get('id')
    const testimonialId = Number(testimonialIdString)

    const idIsNotValid =
      typeof testimonialIdString === 'undefined' ||
      testimonialIdString === '' ||
      isNaN(testimonialId)

    if (idIsNotValid) {
      notificationService.addDeferredErrorNotification(
        'Error.',
        'This testimonial could not be found. Please try again later.'
      )

      return
    }

    const property = findPropertyByTestimonialId(testimonials, testimonialId)

    if (!property) {
      notificationService.addDeferredErrorNotification(
        'Error.',
        'This testimonial could not be found. Please try again later.'
      )

      return
    }

    const testimonial = findTestimonialById(property, testimonialId)

    setViewTestimonialParams({
      property,
      testimonial,
    })
  }

  const clearNotifications = () => {
    notificationService.clearNotifications()
  }

  useEffect(() => {
    getTestimonials(token).then(response => {
      if (response.success) {
        setTestimonials(response.testimonials)

        getLandlordTestimonials(token).then(response => {
          if (response.success) {
            setLandlordTestimonials(response.testimonials)
          } else {
            notificationService.addDeferredErrorNotification(
              'Oops!',
              'Something went wrong and we could not retrieve your legacy testimonials. Please try again later.'
            )
          }
          setLoading(false)
        })
        if (queryString) {
          getPropertyAndTestimonialFromQueryString(queryString, response.testimonials)
        }
      } else {
        setServerError(true)
      }
    })
  }, [])

  if (serverError) return errorPage()
  if (loading) return <LoadingPage />
  return testimonialsPage(
    testimonials,
    landlordTestimonials,
    requestTenantTestimonial,
    deleteTenantTestimonial,
    markTenantTestimonialAsNotNew,
    toggleDisplayTenantTestimonial,
    toggleDisplayTenantLandlordTestimonial,
    viewTestimonialParams,
    isLettingAgent,
    clearNotifications,
    windowElement
  )
}

TestimonialsContainer.propTypes = {
  queryString: PropTypes.string,
  testimonialsPage: PropTypes.func.isRequired,
  errorPage: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
  getTestimonials: PropTypes.func.isRequired,
  requestTestimonial: PropTypes.func.isRequired,
  markTestimonialAsNotNew: PropTypes.func.isRequired,
  toggleDisplayTestimonial: PropTypes.func.isRequired,
  deleteTestimonial: PropTypes.func.isRequired,
  notificationService: PropTypes.object.isRequired,
}

export const TestimonialsContainerCompositionRoot = ({ location }) => {
  return (
    <TestimonialsContainer
      queryString={location.search}
      testimonialsPage={(
        testimonials,
        landlordTestimonials,
        requestTestimonial,
        deleteTestimonial,
        markTestimonialAsNotNew,
        toggleDisplayTestimonial,
        toggleDisplayLandlordTestimonial,
        viewTestimonialParams,
        isLettingAgent,
        clearNotifications,
        window
      ) => (
        <TestimonialsPage
          testimonials={testimonials}
          landlordTestimonials={landlordTestimonials}
          requestTestimonial={requestTestimonial}
          deleteTestimonial={deleteTestimonial}
          markTestimonialAsNotNew={markTestimonialAsNotNew}
          toggleDisplayTestimonial={toggleDisplayTestimonial}
          toggleDisplayLandlordTestimonial={toggleDisplayLandlordTestimonial}
          viewTestimonialParams={viewTestimonialParams}
          isLettingAgent={isLettingAgent}
          clearNotifications={clearNotifications}
          window={window}
        />
      )}
      errorPage={() => <ErrorPage />}
      useAuthContext={useAuthContext}
      getTestimonials={getTestimonials}
      getLandlordTestimonials={getLandlordTestimonials}
      requestTestimonial={requestTestimonial}
      markTestimonialAsNotNew={markTestimonialAsNotNew}
      toggleDisplayTestimonial={toggleDisplayTestimonial}
      toggleDisplayLandlordTestimonial={toggleDisplayLandlordTestimonial}
      deleteTestimonial={deleteTestimonial}
      notificationService={notificationService}
    />
  )
}

TestimonialsContainerCompositionRoot.propTypes = {
  location: PropTypes.object,
}
