import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { config } from '../../../config.js'

import LazyImage from '../LazyImage'

import './styles.scss'

const ImagePlaceholder = ({ className, alt, maxWidth }) => {
  const componentClasses = classNames('image-placeholder', className)
  return (
    <div className={componentClasses}>
      <LazyImage
        src={`${config.imgApiUrl}w=${maxWidth}/${config.placeholderImageSrc}`}
        dataSrc={`${config.imgApiUrl}w=${maxWidth}/${config.placeholderImageSrc}`}
        alt={alt}
      />
    </div>
  )
}

ImagePlaceholder.propTypes = {
  className: PropTypes.string,
  alt: PropTypes.string.isRequired,
  maxWidth: PropTypes.number.isRequired,
}

export default ImagePlaceholder
