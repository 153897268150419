import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SpinnerButton from '@afs/components/SpinnerButton'
import Field from '@afs/components/Field'

import ValidatedDate from '../../../../models/validatedDate'

import styles from '../availabilityPage.module.scss'

const validateDates = (availableFromDate, availableUntilDate) => {
  const errors = {
    availableFrom: '',
    availableUntil: '',
  }
  const invalidDateMessage = 'Date must be in the format: DD/MM/YYYY'

  const availableFrom = ValidatedDate.forDate(availableFromDate)
  const availableUntil = ValidatedDate.forDate(availableUntilDate)

  const availableUntilIsInvalid = availableUntilDate && availableUntil.isAnInvalidDate()
  const bothFromAndUntilDateAreValid = availableFrom.isAValidDate() && availableUntil.isAValidDate()

  if (availableFrom.isAnInvalidDate()) {
    errors.availableFrom = invalidDateMessage
  }

  if (availableFrom.isUndefined()) {
    errors.availableFrom = `'Available from' date is required.`
  }

  if (availableUntilIsInvalid) {
    errors.availableUntil = invalidDateMessage
  }

  if (bothFromAndUntilDateAreValid && availableUntil.isOnOrBefore(availableFromDate)) {
    errors.availableUntil = "Date must be after the 'Available from' date."
  }

  return errors
}

const ChangeAvailabilityForm = ({ loading, onSave }) => {
  const [fields, setFields] = useState({
    availableFrom: {
      value: '',
      placeholder: 'DD/MM/YYYY',
      touched: false,
      error: '',
    },
    availableUntil: {
      value: '',
      placeholder: '',
      touched: false,
      error: '',
    },
  })

  const handleChange = event => {
    const inputValue = event.target.value
    const inputName = event.target.name

    setFields(prevState => {
      if (inputName === 'availableFrom') {
        return {
          ...prevState,
          availableFrom: {
            value: inputValue,
            placeholder: 'DD/MM/YYYY',
            touched: true,
            error: validateDates(inputValue, prevState.availableUntil.value).availableFrom,
          },
        }
      }
      return {
        ...prevState,
        availableUntil: {
          value: inputValue,
          placeholder: '',
          touched: true,
          error: validateDates(prevState.availableFrom.value, inputValue).availableUntil,
        },
      }
    })
  }

  const handleAvailableUntilFocus = event => {
    event.preventDefault()

    setFields(prevState => ({
      ...prevState,
      availableUntil: {
        ...prevState.availableUntil,
        placeholder: 'DD/MM/YYYY',
      },
    }))
  }

  const handleSave = event => {
    event.preventDefault()
    onSave(fields.availableFrom.value, fields.availableUntil.value)
  }

  const availableFromIsEmpty = !fields.availableFrom.touched
  const availableFromIsInvalid = fields.availableFrom.touched && fields.availableFrom.error !== ''
  const availableUntilIsInvalid =
    fields.availableUntil.touched && fields.availableUntil.error !== ''

  const formIsInvalid = availableFromIsEmpty || availableFromIsInvalid || availableUntilIsInvalid

  return (
    <form onSubmit={handleSave}>
      <Field
        className={styles.availableFromField}
        name="availableFrom"
        label="Available from"
        aria-label="Available from"
        value={fields.availableFrom.value}
        placeholder={fields.availableFrom.placeholder}
        onChange={handleChange}
        onBlur={handleChange}
        invalid={availableFromIsInvalid}
        error={fields.availableFrom.error}
        data-testid="available-from-field"
      />
      <Field
        className={styles.moveOutByField}
        name="availableUntil"
        label="Move out by"
        aria-label="Move out by"
        optional
        value={fields.availableUntil.value}
        placeholder={fields.availableUntil.placeholder}
        onChange={handleChange}
        onBlur={handleChange}
        onFocus={handleAvailableUntilFocus}
        invalid={availableUntilIsInvalid}
        error={fields.availableUntil.error}
        data-testid="available-until-field"
      />
      <div className={styles.mobileButtonContainer}>
        <SpinnerButton
          className={styles.button}
          primary
          disabled={formIsInvalid}
          inactive={loading}
          data-testid="change-dates-button"
        >
          Change dates
        </SpinnerButton>
      </div>
    </form>
  )
}

ChangeAvailabilityForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default ChangeAvailabilityForm
