import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const FeedsHomePageContainer = ({
  getAccount,
  homePage,
  loadingPage,
  useAuthContext,
  useStateContext,
  redirect,
}) => {
  const [account, setAccount] = useState({})
  const [loading, setLoading] = useState(true)
  const [serverError, setServerError] = useState(false)

  const { token, user } = useAuthContext()
  const { managedAccount } = useStateContext()

  useEffect(() => {
    getAccount(token).then(response => {
      if (response.success) {
        setAccount(response.landlord)

        if (user.isAdmin()) {
          managedAccount.setAccountDetails(
            response.landlord.fullNameString(),
            response.landlord.emailAddressString()
          )
        }
        setLoading(false)
      } else {
        setServerError(true)
      }
    })
  }, [])

  if (serverError) return redirect('/landlord-admin/error')
  if (loading) return loadingPage()

  return homePage(account.fullNameString(), account.hasActivePropertyFeed())
}

FeedsHomePageContainer.propTypes = {
  getAccount: PropTypes.func.isRequired,
  homePage: PropTypes.func.isRequired,
  loadingPage: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
  useStateContext: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
}

export default FeedsHomePageContainer
