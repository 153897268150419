import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.scss'

const AddressReview = ({ className, address, callback, ...props }) => {
  const addressReviewClasses = classNames('address-review', className)

  const addressFields = address.withoutFirstLine()
  const hasAddressFields = addressFields && addressFields.length > 0

  const handleKeyDown = event => {
    const enterKey = 13

    if (event.code === enterKey || event.keyCode === enterKey) {
      callback(event, address)
    }
  }

  return (
    <div
      className={addressReviewClasses}
      onKeyDown={handleKeyDown}
      onClick={event => callback(event, address)}
      role="button"
      tabIndex="0"
      data-testid="address-review"
      {...props}
    >
      <div data-testid="address-review-text">
        <strong>{address.firstLine()}</strong>
        {hasAddressFields && addressFields.map(field => <p key={field}>{field}</p>)}
      </div>
      <span className="address-review__faux-link">Edit address</span>
    </div>
  )
}

AddressReview.propTypes = {
  className: PropTypes.string,
  address: PropTypes.object,
  callback: PropTypes.func.isRequired,
}

export default AddressReview
