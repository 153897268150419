import React from 'react'

import RegisterFormPageTemplate from '../../templates/RegisterFormPageTemplate'
import Notification from '../../molecules/Notification'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Heading from '../../atoms/Heading'

import VerifyEmail from '../../../svgs/illustrations/verify-email.svg'

import './styles.scss'

const RegisterThankYouPage = () => {
  return (
    <RegisterFormPageTemplate header={<Header />} footer={<Footer />} title="Thank You | AFS">
      <div className="register-thanks__wrapper">
        <Notification
          className="register-thanks__notification"
          type="success"
          text="Your account has been created!"
        />
        <VerifyEmail className="register-thanks__illustration" />
        <Heading className="register-thanks__heading">Verify your email address</Heading>
        <p className="register-thanks__large-text">
          We’ve sent you an email with a link to verify your address.
        </p>
        <p className="register-thanks__small-text">
          <strong>Please check your Inbox</strong> (and Spam folder — just in case) for an email
          from AFS Support and follow the instructions.
        </p>
      </div>
    </RegisterFormPageTemplate>
  )
}

export default RegisterThankYouPage
