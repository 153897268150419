class ForgottenPasswordFormViewModel {
  constructor(view, purifier, authService) {
    this.view = view
    this.purifier = purifier
    this.authService = authService
    this.fields = {
      email: {
        value: null,
        valid: false,
        showError: false,
        error: 'Must be a valid email address.',
      },
    }
    this.formSending = false
  }

  getFields() {
    return this.fields
  }

  updateTextField = field => {
    this.validateField(field)
    this.view.updateView()
  }

  isFieldValid = fieldName => {
    const field = this.fields[fieldName]
    const isValid = field.valid && !!field.value
    return isValid
  }

  isFormValid() {
    this.validateAllFields()
    return this.validateForm()
  }

  validateForm = () => {
    const allFieldsCompleted = this.checkValidity()
    const botNotSubmittingForm = !this.honeypotField.state.value

    return allFieldsCompleted && botNotSubmittingForm
  }

  checkValidity = () => {
    return Object.keys(this.fields)
      .map(fieldName => this.fields[fieldName].valid)
      .every(fieldsValidity => fieldsValidity === true)
  }

  validateField = field => {
    const fieldName = field.name
    const fieldValue = this.purifier.sanitize(field.value)
    const isFieldValid = field.validity.valid

    this.fields[fieldName] = {
      ...this.fields[fieldName],
      value: fieldValue,
      valid: isFieldValid,
      showError: !isFieldValid,
    }
  }

  validateAllFields = () => {
    const validatedFields = Object.keys(this.fields).reduce((validatedFields, fieldName) => {
      const field = this.fields[fieldName]
      const isFieldValid = field.valid

      validatedFields[fieldName] = {
        ...field,
        showError: !isFieldValid,
      }

      return validatedFields
    }, {})

    this.fields = validatedFields
    this.view.updateView()
  }

  invalidateFields = fields => {
    fields.forEach(invalidField => {
      const savedField = this.fields[invalidField.fieldName]
      this.fields[invalidField.fieldName] = {
        ...savedField,
        valid: false,
        error: invalidField.error,
        showError: true,
      }
    })
    this.view.updateView()
  }

  registerHoneypot = field => {
    this.honeypotField = field
  }

  postForm = async () => {
    return this.authService.forgotPassword(this.fields.email.value)
  }
}

export default ForgottenPasswordFormViewModel
