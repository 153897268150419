import React from 'react'
import PropTypes from 'prop-types'
import Field from '@afs/components/Field'

const FieldAdapter = ({ input, label, meta, optional, ...props }) => {
  return (
    <Field
      {...input}
      label={label}
      optional={optional}
      error={meta.error}
      invalid={meta.error && meta.touched}
      validated={!meta.error && meta.touched}
      {...props}
    />
  )
}

FieldAdapter.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  optional: PropTypes.bool,
}

export default FieldAdapter
