const StorageService = (storage, idGenerator, propertyApi, auth) => {
  const createFilenamesFor = files => {
    return files.map(
      (file, uploadNumber) =>
        `${uploadNumber}-${idGenerator.timeNowInMilliseconds()}.${file.name.split('.').pop()}`
    )
  }

  const savePhoto = async (file, filename) => {
    return storage
      .put(filename, file, {
        contentType: file.type,
        level: 'protected',
      })
      .then(() => {
        return storage.get(filename, { level: 'protected' }).then(fileUrl => ({
          success: true,
          fileUrl: fileUrl.split('?')[0],
          filename,
          originalFilename: file.name,
        }))
      })
      .catch(() => ({
        success: false,
        originalFilename: file.name,
      }))
  }

  const savePhotos = async files => {
    const filenames = createFilenamesFor(files)

    return Promise.all(
      files.map(async (file, index) => {
        return savePhoto(file, filenames[index])
      })
    )
  }

  const removePhoto = async photoSrc => {
    return auth.currentSession().then(async data => {
      return propertyApi.deletePropertyPhoto(data.idToken.jwtToken, photoSrc)
    })
  }

  return {
    savePhotos,
    removePhoto,
  }
}

export default StorageService
