import React from 'react'
import PropTypes from 'prop-types'
import FieldCategoryMultiSelect from '../molecules/FieldCategoryMultiSelect'

const FieldCategoryMultiSelectAdapter = ({ input: { onChange, value }, categories }) => {
  return (
    <FieldCategoryMultiSelect
      categories={categories}
      selectedOptions={value}
      onSelectOption={onChange}
    />
  )
}

FieldCategoryMultiSelectAdapter.propTypes = {
  input: PropTypes.object,
  categories: PropTypes.array,
}

export default FieldCategoryMultiSelectAdapter
