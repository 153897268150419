import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Downshift from 'downshift'
import Label from '@afs/components/Label'

import './styles.scss'

const Error = ({ children, ...props }) => <span {...props}>{children}</span>

const FieldLocation = ({
  className,
  label,
  name,
  locations,
  selectedLocation,
  handleChange,
  error,
  invalid,
  validated,
  disabled,
  ...props
}) => {
  const fieldClasses = classNames('form-field', className)
  const labelClasses = classNames('form-field__label', `${className}__label`)
  const hasLabel = !!label

  const getInputClasses = isOpen => {
    return classNames('form-input form-input--autocomplete', {
      'form-input--invalid': invalid,
      'form-input--validated': validated,
      'form-input--disabled': disabled,
      'form-input--expanded': isOpen,
    })
  }

  const renderItems = (items, inputValue, highlightedIndex, getItemProps) =>
    items.map((item, index) => (
      <li
        {...getItemProps({
          key: item.name,
          className: 'autocomplete-field__item',
          index,
          item,
          style: {
            backgroundColor: highlightedIndex === index ? '#f3f3f3' : 'transparent',
          },
        })}
      >
        {item.displayName}
      </li>
    ))

  const renderMenu = (
    isOpen,
    locations,
    inputValue,
    highlightedIndex,
    getMenuProps,
    getItemProps
  ) => {
    if (!isOpen) return false

    const itemsToShow = locations.filter(
      item => !inputValue || item.name.toLowerCase().startsWith(inputValue.toLowerCase())
    )
    const hasItemsToShow = itemsToShow.length > 0
    const menuClasses = classNames('autocomplete-field__dropdown', {
      'autocomplete-field__dropdown--no-results': !hasItemsToShow,
    })
    const menuInnerClasses = 'autocomplete-field__dropdown-inner'

    return (
      <div className={menuClasses}>
        <ul {...getMenuProps({ className: menuInnerClasses })}>
          {hasItemsToShow ? (
            renderItems(itemsToShow, inputValue, highlightedIndex, getItemProps)
          ) : (
            <li className="autocomplete-field__item">No locations found</li>
          )}
        </ul>
      </div>
    )
  }

  return (
    <div className={fieldClasses} {...props}>
      <Downshift
        onChange={selection => handleChange(selection ? selection.name : '')}
        itemToString={item => (item ? item.displayName : '')}
        initialInputValue={selectedLocation}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          isOpen,
          inputValue,
          highlightedIndex,
          openMenu,
        }) => (
          <div className="autocomplete-field">
            {hasLabel && (
              <Label
                {...getLabelProps({
                  className: labelClasses,
                })}
              >
                {label}
              </Label>
            )}
            <input
              {...getInputProps({
                id: name,
                className: getInputClasses(isOpen),
                name,
                value: inputValue.replace(/\b\w/g, l => l.toUpperCase()),
                type: 'text',
                placeholder: 'Enter a location',
                onFocus: () => openMenu(),
                disabled: disabled || undefined,
                'data-testid': `field-${name}`,
                'aria-describedby': `${name}Error`,
                autoComplete: 'new-password', // hack to hide autocomplete
              })}
            />
            {renderMenu(
              isOpen,
              locations,
              inputValue,
              highlightedIndex,
              getMenuProps,
              getItemProps
            )}
          </div>
        )}
      </Downshift>
      {invalid && error && (
        <Error
          id={`${name}Error`}
          className="autocomplete-field__description"
          role="alert"
          data-testid={`field-${name}-error`}
        >
          {error}
        </Error>
      )}
    </div>
  )
}

Error.propTypes = {
  children: PropTypes.any,
}

FieldLocation.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  locations: PropTypes.array.isRequired,
  selectedLocation: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  invalid: PropTypes.bool,
  validated: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default FieldLocation
