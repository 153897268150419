import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SpinnerButton from '@afs/components/SpinnerButton'

import { useParams } from 'react-router-dom'
import { relistProperty, delistProperty } from '../../../api/propertyApi'
import { usePropertyContext } from '../../../propertyContext'
import { config } from '../../../config'
import { notificationService } from '../../../container'

import Button from '../../atoms/Button'
import AdStatusTag from '../../atoms/AdStatusTag'
import IconButton from '../IconButton'
import Products from '../../../models/products'
import Heading from '../../atoms/Heading'

import detailsListing from './detailsListing.module.scss'
import DelistPropertyOverlay from '../../organisms/DelistPropertyOverlay'
import RelistPropertyOverlay from '../../organisms/RelistPropertyOverlay'

import { useAuthContext } from '../../../authContext'
import { NotificationSubscriberIds } from '../../organisms/Notifications'
import { logEvent } from '../../../logger'
import scrollToNotification from '../../../utils/scrollToNotification'

const { cheapestAvailableAd } = Products()

const PropertyDetailsListing = ({ onAddToBasket }) => {
  const { token } = useAuthContext()
  const { action } = useParams()
  const { property, refreshProperty } = usePropertyContext()
  const [updating, setUpdating] = useState(false)
  const expiryDate = property.expiryDateWithYear()
  const startDate = property.startDate()
  const startDateWithFullMonth = property.startDateWithFullMonth()

  const propertyUrl = `${config.mainSiteBaseUrl}property/${property.path}`

  const [propertyDelistError, setPropertyDelistError] = useState(false)
  const [propertyRelistError, setPropertyRelistError] = useState(false)

  const [delistPropertyOverlayConfig, setDelistPropertyOverlayConfig] = useState({
    active: action !== null && action === 'delist',
  })

  const [relistPropertyOverlayConfig, setRelistPropertyOverlayConfig] = useState({
    active: false,
  })

  useEffect(() => {
    notificationService.clearNotifications()
  }, [])

  const onRelistProperty = async () => {
    const propertyId = property.id
    setUpdating(true)

    const response = await relistProperty(propertyId, token)

    if (response.success) {
      setPropertyRelistError(false)
      setUpdating(false)
      setRelistPropertyOverlayConfig({ active: false })
      refreshProperty()
      notificationService.showSuccessNotificationForSubscriber(
        NotificationSubscriberIds.ManagePropertyListingsTemplate,
        'Success!',
        'This property has been relisted.'
      )
      logEvent('Relist Property', {
        Source: 'Property listing',
        'Property Id': property.id,
        'Property Type': property.accommodationType,
      })
      scrollToNotification()
    } else {
      setPropertyRelistError(true)
      setUpdating(false)
    }
  }

  const onDelistProperty = async () => {
    const propertyId = property.id
    setUpdating(true)

    const response = await delistProperty(propertyId, token)

    if (response.success) {
      setPropertyDelistError(false)
      setUpdating(false)
      setDelistPropertyOverlayConfig({ active: false })
      refreshProperty()
      notificationService.showSuccessNotificationForSubscriber(
        NotificationSubscriberIds.ManagePropertyListingsTemplate,
        'Success!',
        'This property has been delisted.'
      )
      logEvent('Delist Property', {
        Source: 'Property listing',
        'Property Id': property.id,
        'Property Type': property.accommodationType,
      })
      scrollToNotification()
    } else {
      setPropertyDelistError(true)
      setUpdating(false)
    }
  }

  const PropertyIsAdvertised = () => {
    return (
      <>
        <div className={detailsListing.row}>
          <strong>Advertised since</strong>
          <span>{startDateWithFullMonth}</span>
        </div>

        <p className={detailsListing.info} data-testid="property-details-info">
          This property is currently being advertised on the website.
        </p>

        <div className={detailsListing.flexButtons}>
          <SpinnerButton
            className={`${detailsListing.button} ${detailsListing.flexButton}`}
            dark
            onClick={() => setDelistPropertyOverlayConfig({ active: true })}
            inactive={updating}
            data-testid="delist-property-button"
          >
            Delist property
          </SpinnerButton>

          <IconButton
            className={`${detailsListing.button} ${detailsListing.flexButton}`}
            href={propertyUrl}
            target="_blank"
            icon={{
              icon: 'open',
              width: '14',
              height: '14',
            }}
            iconPosition="right"
            data-testid="view-listing-button"
          >
            View listing
          </IconButton>
        </div>
      </>
    )
  }

  const PropertyIsAdvertisedInFuture = () => {
    return (
      <p className={detailsListing.info} data-testid="property-details-info">
        This property has been paid for and it will automatically be listed on the website from{' '}
        <strong className={detailsListing.emphasis}>{startDateWithFullMonth}</strong>.
      </p>
    )
  }

  const PropertyIsNotAdvertised = () => {
    return (
      <>
        <p className={detailsListing.info} data-testid="property-details-info">
          Make this listing public on the website from as little as{' '}
          <strong>£{cheapestAvailableAd()}/month</strong>.
        </p>
        <Button
          className={detailsListing.button}
          onClick={onAddToBasket}
          primary
          data-testid="publish-advert-button"
        >
          Advertise property
        </Button>
      </>
    )
  }

  const PropertyIsUnlisted = () => {
    return (
      <>
        <div className={detailsListing.row}>
          <strong>Advertised since</strong>
          <span>{startDateWithFullMonth}</span>
        </div>
        <p className={detailsListing.info} data-testid="property-details-info">
          This property has been paid for, but it is currently unlisted from the website.
        </p>
        <SpinnerButton
          className={detailsListing.button}
          primary
          onClick={() => setRelistPropertyOverlayConfig({ active: true })}
          inactive={updating}
          data-testid="relist-property-button"
        >
          Relist property
        </SpinnerButton>
      </>
    )
  }

  const PropertyListing = () => {
    const { status, isListed } = property

    if (status === 'CurrentlyAdvertised' && !isListed) {
      return <PropertyIsUnlisted />
    } else if (status === 'AdvertisedInTheFuture') {
      return <PropertyIsAdvertisedInFuture />
    } else if (status === 'CurrentlyAdvertised') {
      return <PropertyIsAdvertised />
    } else {
      return <PropertyIsNotAdvertised />
    }
  }

  return (
    <>
      <Heading className={detailsListing.heading} level={5}>
        Property listing
      </Heading>

      <div className={detailsListing.row} data-testid="property-details-status">
        <strong>Status</strong>
        <AdStatusTag
          className={detailsListing.adStatus}
          status={property.status}
          isListed={property.isListed}
          startDate={startDate}
          expiryDate={expiryDate}
          alignRight
        />
      </div>
      {PropertyListing()}
      {delistPropertyOverlayConfig.active && (
        <DelistPropertyOverlay
          handleOverlayClose={() => setDelistPropertyOverlayConfig({ active: false })}
          handleDelistProperty={onDelistProperty}
          config={delistPropertyOverlayConfig}
          property={property}
          updating={updating}
          propertyDelistError={propertyDelistError}
        />
      )}
      {relistPropertyOverlayConfig.active && (
        <RelistPropertyOverlay
          handleOverlayClose={() => setRelistPropertyOverlayConfig({ active: false })}
          handleListProperty={onRelistProperty}
          config={relistPropertyOverlayConfig}
          property={property}
          updating={updating}
          propertyRelistError={propertyRelistError}
        />
      )}
    </>
  )
}

PropertyDetailsListing.propTypes = {
  onAddToBasket: PropTypes.func.isRequired,
}

export default PropertyDetailsListing
