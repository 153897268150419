import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import {
  includesHTML,
  containsPhone,
  containsUrl,
  containsEmail,
} from '../../../../utils/validation'

import './styles.scss'

import InfoBox from '../../../molecules/InfoBox'
import FieldTextAreaAdapter from '../../../adapters/FieldTextAreaAdapter'

export const DescriptionPage = () => {
  const infoBoxText = (
    <span>
      Please <strong>do not</strong> include any of the following information in the description
      field:
    </span>
  )

  const toolTipText = (
    <span>
      Listings containing these details{' '}
      <span className="description-page__tooltip-text--gold">will be removed:</span>
      <ul className="description-page__tooltip-list">
        <li>multiple properties</li>
        <li>blocks of apartments</li>
        <li>web addresses</li>
        <li>email addresses</li>
        <li>phone numbers</li>
      </ul>
    </span>
  )

  return (
    <>
      <Field name="description" component={FieldTextAreaAdapter}>
        {props => (
          <FieldTextAreaAdapter className="field-description" label="Description" {...props} />
        )}
      </Field>
      <InfoBox
        className="description-page__info-box"
        text={infoBoxText}
        toolTipText={toolTipText}
      />
    </>
  )
}

export const descriptionPageValidation = values => {
  const errors = {}

  if (values.description && containsEmail(values.description)) {
    errors.description = 'Email addresses are not allowed in the property description.'
  }

  if (values.description && containsUrl(values.description)) {
    errors.description = 'URLs are not allowed in the property description.'
  }

  if (values.description && containsPhone(values.description)) {
    errors.description = 'Phone numbers are not allowed in the property description.'
  }

  if (values.description && includesHTML(values.description)) {
    errors.description = 'HTML is not allowed in the property description'
  }

  return errors
}

DescriptionPage.propTypes = {
  meta: PropTypes.object,
}
