import Cookies from 'js-cookie'

const oneYearFromToday = new Date(new Date().setFullYear(new Date().getFullYear() + 1))

const subscribers = []

const addSubscriber = subscriber => {
  subscribers.push(subscriber)
}

const notifySubscribers = cookieConsent => {
  subscribers.forEach(subscriber => subscriber(cookieConsent))
}

const performanceCookiesEnabled = bannerVersionId => {
  const consent = Cookies.getJSON('CookieConsent')

  const consentGiven =
    consent !== undefined &&
    consent.bannerVersionId === bannerVersionId &&
    consent.performanceCookies

  return consentGiven
}

const shouldShowCookieBanner = bannerVersionId => {
  const consent = Cookies.getJSON('CookieConsent')

  const consentHasNotBeenGiven =
    consent === undefined || consent.bannerVersionId !== bannerVersionId

  return consentHasNotBeenGiven
}

const cookieConsentForAllCookiesAccepted = bannerVersionId => {
  return {
    bannerVersionId,
    basicFunctionalityCookies: true,
    performanceCookies: true,
  }
}

const updateCookies = (consent, config) => {
  Cookies.set('CookieConsent', consent, {
    expires: oneYearFromToday,
    domain: config.cookieDomain,
  })

  notifySubscribers(consent)
}

const setCookieConsent = (cookieConsent, bannerVersionId, config) => {
  const consent = {
    ...cookieConsentForAllCookiesAccepted(bannerVersionId),
    ...cookieConsent,
  }

  updateCookies(consent, config)
}

const setCookieConsentAllAccepted = (bannerVersionId, config) => {
  const consent = cookieConsentForAllCookiesAccepted(bannerVersionId)

  updateCookies(consent, config)
}

export default (bannerVersionId, config) => ({
  addSubscriber,
  shouldShowCookieBanner: () => shouldShowCookieBanner(bannerVersionId),
  setCookieConsent: cookieConsent => setCookieConsent(cookieConsent, bannerVersionId, config),
  setCookieConsentAllAccepted: () => setCookieConsentAllAccepted(bannerVersionId, config),
  performanceCookiesEnabled: () => performanceCookiesEnabled(bannerVersionId),
})
