import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ManagePropertiesPageTemplate from '../../templates/ManagePropertiesPageTemplate'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import SelectedPropertiesMenu from '../../molecules/SelectedPropertiesMenu'
import Button from '../../atoms/Button'
import Heading from '../../atoms/Heading'
import SelectablePropertyAdminCard from '../../molecules/SelectablePropertyAdminCard'
import NotFoundAnnouncement from '../../molecules/NotFoundAnnouncement'

import Boosting from '../../../svgs/illustrations/boosting.svg'

import styles from './boostPropertiesPage.module.scss'

const renderNoBoostablePropertiesAnnouncement = () => {
  return (
    <NotFoundAnnouncement
      className={styles.notFoundAnnouncement}
      icon={Boosting}
      heading="No properties to boost"
      subHeading="Only properties that are <strong>currently advertised</strong> and <strong>listed</strong> can be boosted."
      button={
        <Button to="/landlord-admin/properties" primary data-testid="manage-properties-button">
          Manage properties
        </Button>
      }
      data-testid="no-boostable-properties"
    />
  )
}

const BoostPropertiesPage = ({
  properties,
  selectedPropertyIds,
  toggleSelectProperty,
  deselectAllProperties,
  selectAllProperties,
  history,
}) => {
  const landlordHasBoostableProperties = properties.length > 0
  const noPropertiesSelected = selectedPropertyIds.length === 0
  const allPropertiesSelected = properties.length === selectedPropertyIds.length
  const numberOfPropertiesSelected = selectedPropertyIds.length
  const [showShakeAnimation, setShowShakeAnimation] = useState(false)

  const gridClasses = classNames(styles.grid, { [styles.shakeAnimation]: showShakeAnimation })

  const confirmBoostProperties = (e) => {
    e.preventDefault()

    if (noPropertiesSelected) {
      setShowShakeAnimation(true)
      setTimeout(() => setShowShakeAnimation(false), 600)
    } else {
      history.push('/landlord-admin/properties/boost')
    }
  }

  return (
    <ManagePropertiesPageTemplate
      title="Boost Properties | AFS"
      className={styles.layout}
      header={<Header />}
      footer={
        <>
          <SelectedPropertiesMenu
            className={styles.menu}
            isActive={landlordHasBoostableProperties}
            allPropertiesSelected={allPropertiesSelected}
            numberOfSelectedProperties={numberOfPropertiesSelected}
            renderButton={() => {
              return (
                <Button
                  className={styles.button}
                  violet
                  onClick={confirmBoostProperties}
                  data-testid="confirm-boost-button"
                >
                  Boost properties
                </Button>
              )
            }}
            handleDeselectAll={deselectAllProperties}
            handleSelectAll={selectAllProperties}
          />
          <Footer />
        </>
      }
    >
      {landlordHasBoostableProperties ? (
        <>
          <div className={styles.hero} data-testid="boost-properties-hero">
            <Heading className={styles.heading} level={1}>
              Boost properties
            </Heading>
            <p className={styles.subheading}>
              Select the properties you&apos;d like to boost to the top of the search results. Only
              the properties that are advertised and listed will show up on this list.
            </p>
          </div>
          <div className={gridClasses} data-testid="boost-properties-grid">
            {properties.map((property) => {
              const propertyIsSelected = selectedPropertyIds.includes(property.id)

              return (
                <div
                  className={styles.gridItem}
                  key={property.id}
                  data-testid="property-grid-item"
                  data-propertyid={property.id}
                >
                  <SelectablePropertyAdminCard
                    className={styles.card}
                    property={property}
                    selected={propertyIsSelected}
                    userIsSelecting
                    handleSelection={toggleSelectProperty}
                  />
                </div>
              )
            })}
          </div>
        </>
      ) : (
        renderNoBoostablePropertiesAnnouncement()
      )}
    </ManagePropertiesPageTemplate>
  )
}

BoostPropertiesPage.propTypes = {
  properties: PropTypes.array.isRequired,
  selectedPropertyIds: PropTypes.array.isRequired,
  toggleSelectProperty: PropTypes.func.isRequired,
  deselectAllProperties: PropTypes.func.isRequired,
  selectAllProperties: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

export default BoostPropertiesPage
