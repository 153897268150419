import React from 'react'
import SpinnerButton from '@afs/components/SpinnerButton'

import { updatePropertyBillsIncluded } from '../../../../api/propertyApi'

import BillsPage from '../../FormPages/BillsPage'
import SubmitButton from '../../../atoms/SubmitButton'

const alwaysValidateToTrue = () => {
  return {}
}

const billsPageConfig = propertyId => ({
  id: 8,
  component: () => <BillsPage />,
  route: `/landlord-admin/property/${propertyId}/edit/bills`,
  validate: alwaysValidateToTrue,
  isValid: false,
  heading: 'Bills included',
  subheading: 'If any bills are included in the rent, please specify which ones.',
  button: (values, loading) => {
    const { bills } = values

    if (bills.length > 0 && bills.length < 4) {
      return <SubmitButton data-testid="save-button">Save with some bills included</SubmitButton>
    }

    if (bills.length === 4) {
      return <SubmitButton data-testid="save-button">Save with all bills included</SubmitButton>
    }

    if (loading)
      return (
        <SpinnerButton primary inactive>
          Continue
        </SpinnerButton>
      )

    return <SubmitButton data-testid="save-button">Save with no bills included</SubmitButton>
  },
  onSubmit: async (values, token) => {
    const payload = {
      propertyId,
      bills: values.bills,
    }
    return updatePropertyBillsIncluded(payload, token)
  },
})

export default billsPageConfig
