import React from 'react'
import PropTypes from 'prop-types'

import Button from '../../atoms/Button'
import Overlay from '../../molecules/Overlay'
import CreditCounter from '../../molecules/CreditCounter'

import boostOverlay from './boostOverlay.module.scss'

const BoostCreditsOverlay = ({ isActive, credits, handleOverlayClose, useBoostCreditsLink }) => {
  const landlordHasBoostCredits = credits > 0

  return (
    <Overlay
      className={boostOverlay.overlay}
      id="boost-credits"
      heading="Boost credits"
      closeLabel="Close"
      isActive={isActive}
      onClose={handleOverlayClose}
    >
      <div data-testid="boost-credits-overlay">
        <p className={boostOverlay.text} data-testid="boost-overlay-text">
          Improve the visibility of your listings by boosting them to the top of the results pages.
        </p>
        <CreditCounter creditType="boost" credits={credits} />
        {landlordHasBoostCredits ? (
          <div className={boostOverlay.buttonWrapper}>
            <Button
              className={boostOverlay.button}
              to={useBoostCreditsLink}
              violet
              data-testid="use-credits"
            >
              Use boost credits
            </Button>
            <Button to="/landlord-admin/buy-boost-credits" data-testid="buy-more-credits">
              Buy more credits
            </Button>
          </div>
        ) : (
          <Button
            className={boostOverlay.buttonWrapper}
            to="/landlord-admin/buy-boost-credits"
            primary
            data-testid="buy-credits-button"
          >
            Buy boost credits
          </Button>
        )}
      </div>
    </Overlay>
  )
}

BoostCreditsOverlay.propTypes = {
  isActive: PropTypes.bool,
  credits: PropTypes.number,
  handleOverlayClose: PropTypes.func,
  useBoostCreditsLink: PropTypes.string,
}

export default BoostCreditsOverlay
