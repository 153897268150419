import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router'

import { useAuthContext } from '../../authContext'
import { getInvoiceByNumber } from '../../api/invoicesApi'

import PdfPage from '../pages/PdfPage'
import LoadingPage from '../pages/LoadingPage'

export const PdfContainer = ({ match, getInvoiceByNumber, useAuthContext, pdfPage, redirect }) => {
  const [invoice, setInvoice] = useState({})
  const [invoiceNotFound, setInvoiceNotFound] = useState(false)
  const [serverError, setServerError] = useState(false)
  const [loading, setLoading] = useState(true)
  const { token, accountType } = useAuthContext()
  const isLettingAgentAccount = accountType === 'LettingAgent'

  const { filename } = match.params
  const invoiceNumber = filename.replace('.pdf', '')

  useEffect(() => {
    getInvoiceByNumber(invoiceNumber, token).then(response => {
      if (response.success) {
        setInvoice(response.invoice)
        setLoading(false)
      } else if (response.notFound) {
        setInvoiceNotFound(true)
      } else if (response.serverError) {
        setServerError(true)
      }
    })
  }, [])

  if (invoiceNotFound) {
    return redirect('/landlord-admin/not-found')
  } else if (serverError) {
    return redirect('/landlord-admin/error')
  } else {
    return loading ? <LoadingPage /> : pdfPage(invoice, isLettingAgentAccount)
  }
}

PdfContainer.propTypes = {
  match: PropTypes.object.isRequired,
  getInvoiceByNumber: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
  pdfPage: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
}

export const PdfContainerCompositionRoot = ({ match }) => {
  return (
    <PdfContainer
      match={match}
      useAuthContext={useAuthContext}
      getInvoiceByNumber={getInvoiceByNumber}
      pdfPage={(invoice, isLettingAgentAccount) => (
        <PdfPage invoice={invoice} isLettingAgentAccount={isLettingAgentAccount} />
      )}
      redirect={path => <Redirect to={path} />}
    />
  )
}

PdfContainerCompositionRoot.propTypes = {
  match: PropTypes.object.isRequired,
}
