import React from 'react'
import PropTypes from 'prop-types'

import useMedia from '../../../hooks/useMedia'
import InvoiceListRow from '../../molecules/InvoiceListRow'

import invoicesList from './invoicesList.module.scss'

const InvoicesList = ({ invoices }) => {
  const isDesktop = useMedia(['(min-width: 960px)'], [true], false)
  const isMobile = useMedia(['(max-width: 660px)'], [true], false)

  if (!invoices) return null

  return (
    <>
      {invoices.map(invoice => (
        <InvoiceListRow
          key={invoice.id}
          className={invoicesList.item}
          invoice={invoice}
          isMobile={isMobile}
          isDesktop={isDesktop}
        />
      ))}
    </>
  )
}

InvoicesList.propTypes = {
  invoices: PropTypes.array.isRequired,
}

export default InvoicesList
