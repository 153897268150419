import React from 'react'
import {
  updateAccountCompanyName,
  updateAccountDescription,
  updateAccountInvoicingAddress,
  updateAccountPublicEnquiriesPhoneNumber,
} from '../../../api/accountApi'
import { truncateStringByCharacters } from '../../../utils/truncate'

import CompanyNameForm from '../../organisms/AccountSettingsForms/CompanyNameForm'
import DescriptionForm from '../../organisms/AccountSettingsForms/DescriptionForm'
import EnquiriesPhoneNumberForm from '../../organisms/AccountSettingsForms/EnquiriesPhoneNumberForm'
import InvoicingAddressForm from '../../organisms/AccountSettingsForms/InvoicingAddressForm'
import InvoicingAddressValue from '../../atoms/InvoicingAddressValue'

const countries = require('./countries.json')

export default [
  {
    name: 'companyName',
    heading: 'Agency name',
    renderForm: ({ ...props }) => <CompanyNameForm {...props} />,
    renderValue: account => <p>{account.companyNameString() || '-'}</p>,
    saveAction: updateAccountCompanyName,
    expanded: false,
    disabled: false,
    loading: false,
  },
  {
    name: 'description',
    heading: 'About the agency',
    renderForm: ({ ...props }) => <DescriptionForm {...props} />,
    renderValue: account => (
      <p>{truncateStringByCharacters(account.descriptionString(), 100) || '-'}</p>
    ),
    saveAction: updateAccountDescription,
    expanded: false,
    disabled: false,
    loading: false,
  },
  {
    name: 'publicPhoneNumber',
    heading: 'Enquiries phone number',
    renderForm: ({ ...props }) => <EnquiriesPhoneNumberForm {...props} />,
    renderValue: account => account.publicPhoneNumberString(),
    saveAction: updateAccountPublicEnquiriesPhoneNumber,
    expanded: false,
    disabled: false,
    loading: false,
  },
  {
    name: 'invoicingAddress',
    heading: 'Branch address',
    renderForm: ({ ...props }) => <InvoicingAddressForm countries={countries} {...props} />,
    renderValue: account => <InvoicingAddressValue account={account} />,
    saveAction: updateAccountInvoicingAddress,
    expanded: false,
    disabled: false,
    loading: false,
  },
]
