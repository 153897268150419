import React from 'react'
import PropTypes from 'prop-types'

import { config } from '../../../config'

import ManagePropertiesPageTemplate from '../../templates/ManagePropertiesPageTemplate'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Button from '../../atoms/Button'
import NotFoundAnnouncement from '../../molecules/NotFoundAnnouncement'

import ThumbsUp from '../../../svgs/illustrations/thumbs-up.svg'
import NoLongerExists from '../../../svgs/illustrations/404.svg'

import errorPage from './testimonialErrorPage.module.scss'

const TestimonialErrorPage = ({ heading, type }) => {
  return (
    <ManagePropertiesPageTemplate
      className={errorPage.main}
      title="Something Went Wrong | AFS"
      header={<Header isError />}
      footer={<Footer noDecoration noMarginTop />}
    >
      <div className={errorPage.layout} data-testid="testimonial-request-error-page">
        <NotFoundAnnouncement
          className={errorPage.notFoundAnnouncement}
          icon={type === 'already left testimonial' ? ThumbsUp : NoLongerExists}
          heading={heading}
          button={
            <Button href={config.mainSiteBaseUrl} secondaryViolet>
              Go to Homepage
            </Button>
          }
          data-testid="not-found"
        />
      </div>
    </ManagePropertiesPageTemplate>
  )
}

TestimonialErrorPage.propTypes = {
  heading: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

export default TestimonialErrorPage
