import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Overlay from '@afs/components/Overlay'
import Notifications from '@afs/components/Notifications'

import { notificationService, randomIdGenerator } from '../../../container'

const OverlayWithLaunchButton = ({
  className,
  id,
  launchButton: LaunchButton,
  heading,
  children,
  onCloseButtonClick,
}) => {
  const [isOverlayActive, setIsOverlayActive] = useState(false)

  const openOverlay = () => {
    setIsOverlayActive(true)
  }

  const closeOverlay = () => {
    setIsOverlayActive(false)
  }

  return (
    <>
      <LaunchButton openOverlay={openOverlay} isOverlayActive={isOverlayActive} />
      <Overlay
        className={className}
        id={id}
        heading={heading}
        isActive={isOverlayActive}
        onClose={() => {
          if (onCloseButtonClick) onCloseButtonClick()
          closeOverlay()
        }}
        closeLabel="Close"
        notifications={props => {
          return (
            <Notifications
              {...props}
              notificationService={notificationService}
              randomIdGenerator={randomIdGenerator}
            />
          )
        }}
        preserveScrollPositionOnClose
      >
        {children({ closeOverlay })}
      </Overlay>
    </>
  )
}

OverlayWithLaunchButton.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  launchButton: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  onCloseButtonClick: PropTypes.func,
}

export default OverlayWithLaunchButton
