import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Auth } from 'aws-amplify'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import InfoTooltip from '@afs/components/InfoTooltip'
import Input from '@afs/components/Input'
import Label from '@afs/components/Label'
import Field from '@afs/components/Field'

import { validatePostcode } from '../../../models/address'
import { notificationService } from '../../../container'

import Notifications from '../Notifications'
import PaymentSubmit from '../../molecules/PaymentSubmit'

import { inputStyles } from './inputStyles'
import addCardForm from './addCardForm.module.scss'

const AddCardForm = ({ className, paymentSecret, onPaymentSuccess, onPaymentFailure }) => {
  const [brand, setBrand] = useState('unknown')
  const [saveCard, setSaveCard] = useState(false)
  const [postcode, setPostcode] = useState({
    value: '',
    isValid: false,
    error: 'Invalid postcode.',
    showError: false,
  })
  const [loading, setLoading] = useState(false)

  const stripe = useStripe()
  const elements = useElements()

  const handleCardNumberChange = (changeObject) => {
    const { brand } = changeObject

    if (brand) {
      setBrand(brand)
    }
  }

  const handlePostcodeChange = (event) => {
    const field = event.target
    const { value } = field
    const isPostcodeValid = validatePostcode(value)

    setPostcode((prevValue) => ({
      ...prevValue,
      value,
      isValid: isPostcodeValid,
      showError: !isPostcodeValid,
    }))

    notificationService.clearNotifications()
  }

  const handleSaveCardChange = (event) => {
    setSaveCard(event.target.checked)
  }

  const handleInvalidForm = () => {
    setLoading(false)

    setPostcode((prevValue) => ({
      ...prevValue,
      isValid: false,
      showError: true,
    }))

    notificationService.showErrorNotification('Error.', 'Please enter a valid postcode.')
  }

  const handleNewCardPayment = () => {
    notificationService.clearNotifications()

    Auth.currentSession().then((data) => {
      const paymentData = {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            address: {
              postal_code: postcode.value,
            },
          },
        },
        receipt_email: data.idToken.payload.email,
      }

      if (saveCard) {
        paymentData.save_payment_method = true
        paymentData.setup_future_usage = 'off_session'
      }

      stripe.confirmCardPayment(paymentSecret, paymentData).then((payload) => {
        if (payload.error) {
          setLoading(false)
          onPaymentFailure(payload)
        } else {
          onPaymentSuccess({
            paidWith: 'New Card',
            savedNewCard: saveCard,
          })
        }
      })
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setLoading(true)

    if (postcode.isValid) {
      handleNewCardPayment()
    } else {
      handleInvalidForm()
    }
  }

  const cardElementClasses = classNames(
    addCardForm.element,
    addCardForm.elementWithBackgroundImage,
    addCardForm.cardNumber,
    {
      [addCardForm.cardUnknown]: brand === 'unknown',
      [addCardForm.cardVisa]: brand === 'visa',
      [addCardForm.cardMastercard]: brand === 'mastercard',
      [addCardForm.cardAmex]: brand === 'amex',
    }
  )

  return (
    <>
      <Notifications className={addCardForm.notifications} />
      <form className={className} onSubmit={handleSubmit}>
        <div className={addCardForm.row}>
          <div className={addCardForm.numberField}>
            <Label className={addCardForm.label} htmlFor="card-number">
              Card number
            </Label>
            <CardNumberElement
              id="card-number"
              className={cardElementClasses}
              onChange={handleCardNumberChange}
              options={{ style: inputStyles }}
            />
          </div>
        </div>
        <div className={addCardForm.row}>
          <div className={addCardForm.expiryField}>
            <Label className={addCardForm.label} htmlFor="card-exp">
              Exp. date
            </Label>
            <CardExpiryElement
              id="card-exp"
              className={addCardForm.element}
              options={{ style: inputStyles }}
            />
          </div>
          <div className={addCardForm.cvcField}>
            <div className={addCardForm.labelWrapper}>
              <Label
                className={classNames(addCardForm.label, addCardForm.cvcLabel)}
                htmlFor="card-cvc"
              >
                CVC
              </Label>
              <InfoTooltip tooltipContent="3-digit security code usually found on the back of your card. American Express cards have a 4-digit code located on the front." />
            </div>
            <CardCvcElement
              id="card-cvc"
              className={addCardForm.element}
              options={{ style: inputStyles }}
            />
          </div>
          <div className={addCardForm.postcodeField}>
            <Field
              label="Postcode"
              name="card-postcode"
              onChange={handlePostcodeChange}
              value={postcode.value}
              invalid={postcode.showError}
              error={postcode.showError ? postcode.error : undefined}
              validated={postcode && postcode.isValid}
              data-testid="postcode-input"
            />
          </div>
        </div>
        <div className={classNames(addCardForm.row, addCardForm.lastRow)}>
          <div className={addCardForm.saveCardField}>
            <Label className={addCardForm.saveCardLabel} htmlFor="save-card">
              Save card for future transactions
            </Label>
            <Input
              id="save-card"
              data-testid="save-card"
              className={addCardForm.saveCardInput}
              onChange={handleSaveCardChange}
              name="save-card"
              type="checkbox"
            />
          </div>
        </div>
        <PaymentSubmit loading={loading} />
      </form>
    </>
  )
}

AddCardForm.propTypes = {
  className: PropTypes.string,
  paymentSecret: PropTypes.string.isRequired,
  onPaymentSuccess: PropTypes.func.isRequired,
  onPaymentFailure: PropTypes.func.isRequired,
}

export default AddCardForm
