import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import RoomSize from '../../../svgs/icons/room-size.svg'
import BedSize from '../../../svgs/icons/bed-size.svg'
import EnSuite from '../../../svgs/icons/bath.svg'
import Rent from '../../../svgs/icons/money-gbp.svg'
import Deposit from '../../../svgs/icons/deposit.svg'
import Calendar from '../../../svgs/icons/calendar.svg'

import './styles.scss'

const icons = [
  {
    name: 'roomSize',
    component: <RoomSize className="icon-text__icon" />,
  },
  {
    name: 'bedSize',
    component: <BedSize className="icon-text__icon" />,
  },
  {
    name: 'enSuite',
    component: <EnSuite className="icon-text__icon" />,
  },
  {
    name: 'rent',
    component: <Rent className="icon-text__icon" />,
  },
  {
    name: 'deposit',
    component: <Deposit className="icon-text__icon" />,
  },
  {
    name: 'calendar',
    component: <Calendar className="icon-text__icon" />,
  },
]

const IconText = ({ icon, text, className }) => {
  const componentClasses = classNames('icon-text', className)
  const selectedIcon = icons.find(item => item.name === icon)
  return (
    <span className={componentClasses} data-testid={`icon-text-${icon}`}>
      {icon && selectedIcon.component}
      {text}
    </span>
  )
}

IconText.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.any.isRequired,
  className: PropTypes.string,
}

export default IconText
