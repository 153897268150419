import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Field from '@afs/components/Field'

import { invalidEmail } from '../../../../utils/validation'

import AccountSettingsForm from '../../../molecules/AccountSettingsForm'

import email from './email.module.scss'

const EmailForm = ({ account, authService, onSave, loading, ...props }) => {
  const currentEmailAddress = account.emailAddressString()
  const [fields, setFields] = useState({
    password: {
      value: '',
      touched: false,
      error: '',
    },
    emailAddress: {
      value: '',
      touched: false,
      error: '',
    },
    confirmEmailAddress: {
      value: '',
      touched: false,
      error: '',
    },
  })

  const { password, emailAddress, confirmEmailAddress } = fields

  const loginToConfirmPassword = (email, password) => {
    return authService.signIn(email, password)
  }

  const validateEmail = email => {
    if (invalidEmail(email)) {
      return 'Valid email address required.'
    }
    return ''
  }

  const returnFieldErrorOnChange = (name, value) => {
    if (name === 'emailAddress') {
      return validateEmail(value)
    }
    if (name === 'confirmEmailAddress') {
      return value !== emailAddress.value ? 'Email addresses do not match' : ''
    } else {
      return ''
    }
  }
  const handleChange = event => {
    const inputName = event.target.name
    const inputValue = event.target.value

    setFields(prevState => ({
      ...prevState,
      [inputName]: {
        value: inputValue,
        touched: true,
        error: returnFieldErrorOnChange(inputName, inputValue),
      },
    }))
  }

  const handleChangeConfirmEmail = event => {
    const inputValue = event.target.value
    setFields(prevState => ({
      ...prevState,
      confirmEmailAddress: {
        value: inputValue,
        touched: false,
      },
    }))
  }

  const handleSave = async event => {
    event.preventDefault()

    const fieldName = 'emailAddress'
    const payload = {
      [fieldName]: emailAddress.value,
    }

    const response = await loginToConfirmPassword(currentEmailAddress, password.value)

    if (response.success) {
      onSave(fieldName, payload)
    } else {
      setFields(prevState => ({
        ...prevState,
        password: {
          value: '',
          touched: true,
          error: 'Please enter your current account password.',
        },
      }))
    }
  }

  const passwordIsInvalid = password.touched && password.error !== ''
  const emailAddressIsInvalid = emailAddress.touched && emailAddress.error !== ''
  const confirmEmailAddressIsInvalid =
    confirmEmailAddress.touched && confirmEmailAddress.value !== emailAddress.value
  const formIsInvalid = passwordIsInvalid || emailAddressIsInvalid || confirmEmailAddressIsInvalid

  return (
    <AccountSettingsForm
      description={
        <>
          Your email address is used to log into your account and it is{' '}
          <strong>not made public</strong> or displayed anywhere on your property listings.
        </>
      }
      onSubmit={handleSave}
      invalid={formIsInvalid}
      loading={loading}
      {...props}
    >
      <Field
        className={email.field}
        name="emailAddress"
        label="New email address"
        aria-label="New email address"
        value={emailAddress.value}
        type="email"
        onChange={handleChange}
        onBlur={handleChange}
        invalid={emailAddressIsInvalid}
        error={emailAddress.error}
        data-testid="input-field-email"
        autoComplete="username"
      />

      <Field
        className={email.field}
        name="confirmEmailAddress"
        label="Confirm new email address"
        aria-label="Confirm new email address"
        value={confirmEmailAddress.value}
        type="email"
        onChange={handleChangeConfirmEmail}
        onBlur={handleChange}
        invalid={confirmEmailAddressIsInvalid}
        error={confirmEmailAddress.error}
        data-testid="input-field-confirm-email"
        autoComplete="off"
      />

      <Field
        name="password"
        label="Password"
        aria-label="Password"
        value={password.value}
        type="password"
        onChange={handleChange}
        onBlur={handleChange}
        invalid={passwordIsInvalid}
        error={password.error}
        data-testid="input-field-password"
        autoComplete="password"
      />
    </AccountSettingsForm>
  )
}

EmailForm.propTypes = {
  account: PropTypes.object.isRequired,
  authService: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default EmailForm
