import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import useMedia from '../../../hooks/useMedia'

import Link from '../../atoms/Link'
import Button from '../../atoms/Button'

import DownArrow from '../../../svgs/icons/primary-nav-down-arrow.svg'
import UserIcon from '../../../svgs/icons/user.svg'

import './styles.scss'

const loggedOutNavigationLinks = [
  {
    url: '/student-guides',
    text: 'Guides',
  },
  {
    url: '/student-blog',
    text: 'Blog',
  },
  {
    url: '/utilities',
    text: 'Utilities',
  },
  {
    url: '/advertise',
    text: 'Advertise',
  },
]

const loggedInNavigationLinks = (isFeedAccount, hasActiveFeed) => {
  if (isFeedAccount && hasActiveFeed) {
    return [
      { url: '/landlord-admin', text: 'Dashboard' },
      { url: '/landlord-admin/feeds/properties', text: 'Properties' },
      { url: '/landlord-admin/feeds/enquiries', text: 'Enquiries' },
      { url: '/landlord-admin/feeds/performance', text: 'Performance' },
    ]
  }

  if (isFeedAccount && !hasActiveFeed) {
    return [{ url: '/landlord-admin', text: 'Dashboard' }]
  }

  return [
    { url: '/landlord-admin', text: 'Dashboard' },
    { url: '/landlord-admin/properties', text: 'Properties' },
    { url: '/landlord-admin/enquiries', text: 'Enquiries' },
    { url: '/landlord-admin/performance', text: 'Performance' },
    { url: '/landlord-admin/testimonials', text: 'Testimonials' },
  ]
}

const loggedInDropdownLinks = (isFeedAccount) => {
  if (isFeedAccount) {
    return [
      { url: '/landlord-admin/invoices', text: 'Invoices' },
      { url: '/landlord-admin/feeds/account', text: 'Account' },
    ]
  }

  return [
    { url: '/landlord-admin/invoices', text: 'Invoices' },
    { url: '/landlord-admin/account', text: 'Account' },
  ]
}

const PrimaryNavigation = ({
  id,
  className,
  userInitials,
  isAuthenticated,
  handleCloseMobileMenu,
  signOut,
  useStateContext,
  isFeedAccount,
  hasActiveFeed,
  propWindow,
  ...props
}) => {
  const [dropdown, setDropdown] = useState(false)
  const isMobile = useMedia(['(max-width: 914px)'], [true], false)

  const windowObject = propWindow || window
  const dropdownMenu = useRef(null)
  const dropdownButton = useRef(null)

  const { propertyIds } = useStateContext()

  const toggleDropdown = () => {
    setDropdown((prevState) => !prevState)
  }

  const closeMobileMenu = () => {
    if (isMobile) handleCloseMobileMenu()
  }

  const clearSelectedPropertyIdsInState = () => {
    if (!isAuthenticated) return

    propertyIds.deselectAll()
  }

  const handleNavLinkOnClick = () => {
    clearSelectedPropertyIdsInState()
    closeMobileMenu()
  }

  const closeDropdownOnClickOutside = (event) => {
    if (
      dropdownMenu.current.contains(event.target) ||
      dropdownButton.current.contains(event.target)
    ) {
      return
    }

    setDropdown(false)
  }

  useEffect(() => {
    if (isMobile) {
      setDropdown(false)
    }

    if (dropdown) {
      windowObject.addEventListener('click', closeDropdownOnClickOutside)
    } else {
      windowObject.removeEventListener('click', closeDropdownOnClickOutside)
    }

    return () => {
      windowObject.removeEventListener('click', closeDropdownOnClickOutside)
    }
  })

  const navClasses = classNames('primary-nav', className)
  const dropdownClasses = classNames('primary-nav__dropdown', {
    'primary-nav__dropdown--open': dropdown && !isMobile,
    'primary-nav__dropdown--mobile': isMobile,
  })
  const navLinks = isAuthenticated
    ? loggedInNavigationLinks(isFeedAccount, hasActiveFeed)
    : loggedOutNavigationLinks

  function createNavLink(navLink) {
    const linkClass = `primary-nav__link ${!isAuthenticated ? 'primary-nav--logged-out__link' : ''}`

    const loggedInLinkComponent = (
      <Link className={linkClass} to={navLink.url} onClick={() => handleNavLinkOnClick()}>
        {navLink.text}
      </Link>
    )

    const loggedOutLinkComponent = (
      <Link className={linkClass} href={navLink.url} onClick={() => handleNavLinkOnClick()}>
        {navLink.text}
      </Link>
    )

    return isAuthenticated ? loggedInLinkComponent : loggedOutLinkComponent
  }

  return (
    <nav className={navClasses} {...props}>
      <ul
        className="primary-nav__list"
        data-testid={isAuthenticated ? 'logged-in-nav-links' : 'logged-out-nav-links'}
      >
        {navLinks.map((navLink) => {
          return (
            <li
              className={`primary-nav__item ${
                !isAuthenticated ? 'primary-nav--logged-out__item' : ''
              }`}
              key={navLink.text}
            >
              {createNavLink(navLink)}
            </li>
          )
        })}

        {isAuthenticated ? (
          <li className="primary-nav__item">
            <button
              ref={dropdownButton}
              className="primary-nav__item--dropdown"
              onClick={toggleDropdown}
              data-testid="dropdown-button"
              aria-expanded={dropdown ? 'true' : 'false'}
              aria-controls={`${id}-dropdown`}
            >
              {userInitials ? (
                <figure className="primary-nav__initials" data-testid="nav-initials">
                  {userInitials}
                </figure>
              ) : (
                <UserIcon className="primary-nav__user-icon" data-testid="nav-icon" />
              )}
              <span className="primary-nav__dropdown-arrow">
                <DownArrow className="primary-nav__dropdown-icon" />
              </span>
            </button>
          </li>
        ) : (
          <li className="primary-nav__item primary-nav--logged-out__item">
            <Button
              className="primary-nav__button"
              to="/login"
              secondary
              data-testid="login-button"
            >
              Landlords / Agents
            </Button>
          </li>
        )}
      </ul>
      {isAuthenticated && (
        <ul
          id={`${id}-dropdown`}
          ref={dropdownMenu}
          className={dropdownClasses}
          data-testid="dropdown-menu"
        >
          {loggedInDropdownLinks(isFeedAccount).length > 0 &&
            loggedInDropdownLinks(isFeedAccount).map((dropdownLink) => {
              return (
                <li className="dropdown__item" key={dropdownLink.text}>
                  <Link
                    className="dropdown__link"
                    to={dropdownLink.url}
                    onClick={() => handleNavLinkOnClick()}
                    tabIndex={!dropdown && !isMobile ? '-1' : '0'}
                    data-testid="dropdown-link"
                  >
                    {dropdownLink.text}
                  </Link>
                </li>
              )
            })}
          <li className="dropdown__item">
            <Link
              to="/landlord-admin/login"
              className="dropdown__link dropdown__logout-button"
              onClick={() => signOut()}
              tabIndex={!dropdown && !isMobile ? '-1' : '0'}
              data-testid="logout-link"
            >
              Logout
            </Link>
          </li>
        </ul>
      )}
    </nav>
  )
}

PrimaryNavigation.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  userInitials: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  handleCloseMobileMenu: PropTypes.func,
  signOut: PropTypes.func.isRequired,
  useStateContext: PropTypes.func.isRequired,
  isFeedAccount: PropTypes.bool,
  hasActiveFeed: PropTypes.bool,
  propWindow: PropTypes.object,
}

export default PrimaryNavigation
