import axios from 'axios'
import { config } from '../config'

export const forgotPasswordConfirmation = (confirmationCode, id, newPassword) => {
  return axios
    .post(`${config.apiBaseUrl}/accounts/password`, {
      confirmationCode,
      id,
      newPassword,
    })
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
}

export default {
  forgotPasswordConfirmation,
}
