/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import { useStateContext } from '../../appStateContext'
import { logEvent } from '../../logger'
import { useAuthContext } from '../../authContext'
import { checkIfOrderHasBeenProcessed } from '../../api/paymentApi'
import { notificationService } from '../../container'
import { config } from '../../config'

import PaymentOptions from '../organisms/PaymentOptions'
import AddCardForm from '../organisms/AddCardForm'
import SavedCardsForm from '../organisms/SavedCardsForm'

const PaymentContainer = ({
  history,
  discount,
  paymentSecret,
  paymentIntentId,
  savedCards,
  shouldPollInitialState = false,
}) => {
  const savedCardsPanel = 'saved-cards'
  const newCardPanel = 'new-card'

  const stripe = loadStripe(config.stripeApiKey)

  const [shouldPoll, setShouldPoll] = useState(shouldPollInitialState)
  const [currentPanel, setCurrentPanel] = useState(savedCardsPanel)

  const { basket, propertyIds } = useStateContext()
  const { token } = useAuthContext()

  useEffect(() => {
    const timer = setInterval(() => {
      if (shouldPoll) {
        checkIfOrderHasBeenProcessed(paymentIntentId, token).then((result) => {
          const { processed, customerId, chargeId, totalAmount, eligibleForAffiliateCommission } =
            result
          if (processed) {
            clearInterval(timer)

            if (eligibleForAffiliateCommission) {
              tap('create', '31753-64217a', { integration: 'stripe' })
              tap('conversion', chargeId, totalAmount, { customer_id: customerId })
            }

            history.push('/landlord-admin/payment-success')
          }
        })
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [shouldPoll])

  const handleChangePanel = (name) => {
    notificationService.clearNotifications()
    setCurrentPanel(name)
  }

  const onPaymentSuccess = async (paymentCardEventProperties) => {
    if (basket.advertisementSummary) {
      const { id, name, amount } = discount

      const advertisementDescription =
        basket.advertisementSummary().length > 0
          ? basket.advertisementSummary()[0].description
          : undefined

      const advertisementQuantity =
        basket.advertisementSummary().length > 0
          ? basket.advertisementSummary()[0].quantity
          : undefined

      const boostCreditDescription =
        basket.boostCreditSummary().length > 0
          ? basket.boostCreditSummary()[0].description
          : undefined

      const boostCreditQuantity =
        basket.boostCreditSummary().length > 0 ? basket.boostCreditSummary()[0].quantity : undefined

      const toLetBoardDescription =
        basket.toLetBoardSummary().length > 0
          ? basket.toLetBoardSummary()[0].description
          : undefined

      const toLetBoardQuantity =
        basket.toLetBoardSummary().length > 0 ? basket.toLetBoardSummary()[0].quantity : undefined

      logEvent('Checkout Complete', {
        'Saved New Card': paymentCardEventProperties.savedNewCard,
        'Paid With': paymentCardEventProperties.paidWith,
        'Advertisement Line Item Description': advertisementDescription,
        'Advertisement Line Item Quantity': advertisementQuantity,
        'Boost Credit Line Item Description': boostCreditDescription,
        'Boost Credit Line Item Quantity': boostCreditQuantity,
        'To Let Board Line Item Description': toLetBoardDescription,
        'To Let Board Line Item Quantity': toLetBoardQuantity,
        'Basket Total': await basket.totalWithVat(),
        'Discount Id': id,
        'Discount Name': name,
        'Discount Amount': amount,
      })
    }

    const selectedPropertyIdsAreForAdvertisements = basket.containsAdvertisementOrderItems()
    basket.finalise()

    if (selectedPropertyIdsAreForAdvertisements) {
      propertyIds.deselectAll()
    }

    setShouldPoll(true)
  }

  const onPaymentFailure = (payload) => {
    notificationService.showErrorNotification('Error.', payload.error.message)
  }

  const renderNewCardForm = () => (
    <Elements stripe={stripe} key={newCardPanel}>
      <AddCardForm
        paymentSecret={paymentSecret}
        onPaymentSuccess={onPaymentSuccess}
        onPaymentFailure={onPaymentFailure}
      />
    </Elements>
  )

  return (
    <>
      {savedCards.length > 0 ? (
        <PaymentOptions
          panels={[
            {
              name: savedCardsPanel,
              heading: 'Your saved cards',
              subHeading: 'All transactions are secure and encrypted.',
              content: (
                <Elements stripe={stripe} key={savedCardsPanel}>
                  <SavedCardsForm
                    savedCards={savedCards}
                    paymentSecret={paymentSecret}
                    onPaymentSuccess={onPaymentSuccess}
                    onPaymentFailure={onPaymentFailure}
                  />
                </Elements>
              ),
            },
            {
              name: newCardPanel,
              heading: 'New credit or debit card',
              subHeading: 'We accept all major credit and debit cards.',
              content: renderNewCardForm(),
            },
          ]}
          currentPanel={currentPanel}
          showPanel={handleChangePanel}
        />
      ) : (
        renderNewCardForm()
      )}
    </>
  )
}

PaymentContainer.propTypes = {
  history: PropTypes.object,
  discount: PropTypes.object.isRequired,
  paymentSecret: PropTypes.string.isRequired,
  paymentIntentId: PropTypes.string.isRequired,
  savedCards: PropTypes.array.isRequired,
  shouldPollInitialState: PropTypes.bool.isRequired,
}

export default PaymentContainer
