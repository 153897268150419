import React from 'react'
import PropTypes from 'prop-types'

import { getAccount } from '../../../api/accountApi'
import { useAuthContext } from '../../../authContext'
import fieldConfig from './personalInfoSettingsPageConfig'

import TwoColumnPageTemplate from '../../templates/TwoColumnPageTemplate'
import Header from '../../organisms/Header'
import BackLink from '../../molecules/BackLink'
import AccountSettingsContainer from '../../containers/AccountSettingsContainer'

const PersonalInfoSettingsPage = ({ history }) => {
  const { token } = useAuthContext()

  const renderLeftColumn = () => (
    <>
      <BackLink
        className="form-navigation__back-link"
        onClick={event => {
          event.preventDefault()
          history.goBack()
        }}
      />
      <AccountSettingsContainer
        heading="Personal info"
        fieldConfig={fieldConfig}
        getLandlord={getAccount}
        token={token}
      />
    </>
  )

  return (
    <TwoColumnPageTemplate
      header={<Header />}
      title="Account Settings - Personal Info | AFS"
      leftColumn={renderLeftColumn()}
    />
  )
}

PersonalInfoSettingsPage.propTypes = {
  history: PropTypes.object,
}

export default PersonalInfoSettingsPage
