import { createPortal } from 'react-dom'
import usePortal from '../../../hooks/usePortal'

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

const Portal = ({ id, children }) => {
  const target = usePortal(id)

  if (!canUseDOM) {
    return null
  }

  return createPortal(children, target)
}

export default Portal
