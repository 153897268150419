import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import FieldCheckbox from '@afs/components/FieldCheckbox'

import LazyImage from '../../atoms/LazyImage'

import NoImagesIcon from './no-image.svg'

import styles from './styles.module.scss'

const BillsOptionalPropertySelectionCard = ({ className, property, checked, onChange }) => {
  const { photo, address, propertyId } = property

  const propertyHasNoImages = photo === ''

  const handleOnChange = () => {
    onChange(propertyId)
  }

  return (
    <FieldCheckbox
      className={classNames(styles.card, className)}
      name={propertyId}
      value={propertyId}
      checked={checked}
      onChange={handleOnChange}
      label={
        <div className={styles.propertyDetails}>
          <div className={styles.imageWrapper}>
            {propertyHasNoImages ? (
              <NoImagesIcon className={styles.noImagesIcon} data-testid="no-property-image" />
            ) : (
              <LazyImage
                className={styles.propertyImage}
                src={photo}
                dataSrc={photo}
                alt="Property image"
                data-testid="property-image"
              />
            )}
          </div>
          <div className={styles.addressWrapper}>
            <p className={styles.line1}>{`${address.line1},`}</p>
            <p className={styles.line2}>{address.line2}</p>
          </div>
        </div>
      }
      data-testid="label-for-checkbox"
    />
  )
}

BillsOptionalPropertySelectionCard.propTypes = {
  className: PropTypes.string,
  property: PropTypes.object.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default BillsOptionalPropertySelectionCard
