import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import classNames from 'classnames'

import useMedia from '../../../hooks/useMedia'
import Address from '../../../models/address'

import SelectablePropertyCardListView from '../../molecules/SelectablePropertyCardListView'
import ManagePropertiesPageTemplate from '../../templates/ManagePropertiesPageTemplate'
import ManagePropertiesHero from '../../atoms/ManagePropertiesHero'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Heading from '../../atoms/Heading'
import { Filtering } from '../../molecules/Filtering'
import FilterByAddress from './filters/FilterByAddress'
import NotFoundAnnouncement from '../../molecules/NotFoundAnnouncement'
import Button from '../../atoms/Button'
import Link from '../../atoms/Link'
import PropertyCardFeedsListView from '../../molecules/PropertyCardFeedsListView'
import SelectedPropertiesMenu from '../../molecules/SelectedPropertiesMenu'

import NoMatches from '../../../svgs/illustrations/no-matches-found.svg'
import PropertyNotFound from '../../../svgs/illustrations/property-not-found.svg'

import styles from './propertiesPageFeeds.module.scss'
import layout from '../PropertiesPage/modules/layout.module.scss'
import selectedPropertiesMenu from '../PropertiesPage/modules/selectedPropertiesMenu.module.scss'

const renderNoPropertiesMatchingFiltersFoundAnnouncement = (removeAllFilters) => {
  return (
    <NotFoundAnnouncement
      className={styles.notFound}
      icon={NoMatches}
      heading="Couldn't match any properties"
      subHeading="Try changing the search term."
      button={
        <Button violet onClick={removeAllFilters}>
          View all properties
        </Button>
      }
      data-testid="no-matching-properties"
    />
  )
}

const renderNoPropertiesAddedAnnouncement = () => {
  return (
    <NotFoundAnnouncement
      className={styles.notFound}
      icon={PropertyNotFound}
      heading="No properties"
      subHeading="There are no properties coming through the feed at the moment. If you believe this is an error, please contact support."
      data-testid="no-properties"
    >
      <Link
        className={styles.notFoundLink}
        href="mailto:support@accommodationforstudents.com"
        target="_blank"
      >
        support@accommodationforstudents.com
      </Link>
      <Link className={styles.notFoundLink} href="tel:+448000789659">
        0800 078 9659
      </Link>
    </NotFoundAnnouncement>
  )
}

const renderProperties = (properties, selectedPropertyIds, onSelectProperty) => {
  return (
    <>
      <Heading className={styles.propertiesHeading} level={5}>
        Properties currently live
      </Heading>
      {properties.map((property) => {
        const propertyIsSelected = selectedPropertyIds.includes(property.id)

        const propertyAddressString = new Address(property.address).addressFields().join(',')

        return (
          <SelectablePropertyCardListView
            key={property.id}
            className={layout.card}
            href={`/property/${property.id}`}
            checkboxName={property.id.toString()}
            checkboxValue={propertyAddressString}
            checkboxLabel={propertyAddressString}
            checked={propertyIsSelected}
            checkboxOnChange={() => onSelectProperty(property.id)}
            data-testid="property-grid-item"
            data-propertyid={property.id}
          >
            <PropertyCardFeedsListView property={property} />
          </SelectablePropertyCardListView>
        )
      })}
    </>
  )
}

const PropertiesPageFeeds = ({
  properties,
  selectedPropertyIds,
  onSelectProperty,
  deselectAllProperties,
  selectAllProperties,
}) => {
  const [currentSearchTerm, setCurrentSearchTerm] = useState('')
  const [filteredProperties, setFilteredProperties] = useState(properties)

  const history = useHistory()

  const isMobile = useMedia(['(max-width: 768px)'], [true], false)

  const allPropertiesSelected = properties.length === selectedPropertyIds.length
  const hideSelectOrDeselectAllButton = filteredProperties.length !== properties.length
  const userHasSelectedAProperty = selectedPropertyIds.length > 0

  const renderSelectedPropertiesMenu = () => {
    return (
      <SelectedPropertiesMenu
        className={selectedPropertiesMenu.menu}
        isActive={userHasSelectedAProperty}
        allPropertiesSelected={allPropertiesSelected}
        numberOfSelectedProperties={selectedPropertyIds.length}
        renderButton={() => {
          return (
            <Button
              className={selectedPropertiesMenu.button}
              violet
              onClick={() => {
                history.push('/landlord-admin/feeds/properties/boost')
              }}
              data-testid="available-actions-button"
            >
              <span>Boost properties</span>
            </Button>
          )
        }}
        handleDeselectAll={deselectAllProperties}
        handleSelectAll={selectAllProperties}
        hideAllPropertiesButton={hideSelectOrDeselectAllButton}
      />
    )
  }

  const renderHero = () => {
    return (
      <ManagePropertiesHero
        className={classNames({
          [styles.noBoxShadow]: userHasSelectedAProperty,
        })}
        pattern="green"
        hasPatternBackgroundOnMobile
      >
        <Heading className={styles.heroHeading}>Properties</Heading>
      </ManagePropertiesHero>
    )
  }

  return (
    <ManagePropertiesPageTemplate
      className={styles.page}
      title="Your Properties | AFS"
      header={<Header />}
      footer={<Footer dark />}
      hero={renderHero()}
      multiSelectMenu={renderSelectedPropertiesMenu()}
    >
      <div className={styles.inner}>
        {properties.length > 0 && (
          <Filtering
            originalData={properties}
            setFilteredData={setFilteredProperties}
            onRemoveAll={[() => setCurrentSearchTerm('')]}
            applyTogether
          >
            {({ removeAll, ...props }) => {
              return (
                <>
                  <FilterByAddress
                    className={styles.searchField}
                    currentSearchTerm={currentSearchTerm}
                    setCurrentSearchTerm={setCurrentSearchTerm}
                    tabIndex={userHasSelectedAProperty && !isMobile ? '-1' : '0'}
                    {...props}
                  />

                  {filteredProperties.length > 0
                    ? renderProperties(filteredProperties, selectedPropertyIds, onSelectProperty)
                    : renderNoPropertiesMatchingFiltersFoundAnnouncement(removeAll)}
                </>
              )
            }}
          </Filtering>
        )}
        {properties.length === 0 && renderNoPropertiesAddedAnnouncement()}
      </div>
    </ManagePropertiesPageTemplate>
  )
}

PropertiesPageFeeds.propTypes = {
  properties: PropTypes.array.isRequired,
  selectedPropertyIds: PropTypes.array.isRequired,
  onSelectProperty: PropTypes.func.isRequired,
  deselectAllProperties: PropTypes.func.isRequired,
  selectAllProperties: PropTypes.func.isRequired,
  clearNotifications: PropTypes.func.isRequired,
  updatingProperties: PropTypes.shape({
    active: PropTypes.bool,
    message: PropTypes.string,
  }),
}

export default PropertiesPageFeeds
