import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SpinnerButton from '@afs/components/SpinnerButton'
import Field from '@afs/components/Field'

import FieldHoneypot from '../../molecules/FieldHoneypot'

import './styles.scss'

class ForgottenPasswordForm extends Component {
  constructor(props) {
    super(props)
    this.viewModel = props.viewModel(this)
    this.notificationService = this.props.notificationService
    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      formSending: false,
      fields: { ...this.viewModel.getFields() },
    }
  }

  componentDidMount() {
    this.viewModel.registerHoneypot(this.honeypotField)
  }

  onFieldChange = (event) => {
    this.viewModel.updateTextField(event.target)
  }

  onFormSubmit = async (event) => {
    event.preventDefault()

    const canSubmitForm = this.viewModel.isFormValid()

    if (canSubmitForm) {
      this.setState({
        formSending: true,
      })

      const result = await this.viewModel.postForm()

      this.setState({
        formSending: false,
      })

      if (result.success) {
        this.notificationService.showSuccessNotification(
          'Reset link sent.',
          'Please check your inbox for instructions.'
        )
      } else {
        switch (result.code) {
          case 'UserExistsInOW':
            const form = document.createElement('form')
            form.action = 'https://www.accommodationforstudents.com/landlord/forgotpassword.asp'
            form.method = 'post'

            const emailInput = document.createElement('input')
            emailInput.name = 'email'
            emailInput.value = this.viewModel.getFields().email.value
            form.appendChild(emailInput)

            const passCheckInput = document.createElement('input')
            passCheckInput.name = 'PassCheck'
            passCheckInput.value = 'PassCheck'
            form.appendChild(passCheckInput)

            document.body.appendChild(form)
            form.submit()
            break
          case 'InternalErrorException':
          case 'InvalidEmailRoleAccessPolicyException':
          case 'InvalidLambdaResponseException':
          case 'InvalidParameterException':
          case 'InvalidSmsRoleAccessPolicyException':
          case 'InvalidSmsRoleTrustRelationshipException':
          case 'ResourceNotFoundException':
          case 'LimitExceededException':
          case 'UnexpectedLambdaException':
          case 'UserLambdaValidationException':
          case 'NotAuthorizedException':
          case 'CodeDeliveryFailureException':
            this.notificationService.showErrorNotification(
              'We’ve encountered an error.',
              'Something unexpected happened on our end. Please try again in a few minutes. If this error persists, please contact us.'
            )
            break
          case 'UserNotFoundException':
            this.notificationService.showErrorNotification(
              'Can’t find account.',
              'This account does not exist or it has been removed.'
            )
            break
          case 'UserNotConfirmedException':
            this.notificationService.showWarningNotification(
              'Unverified email address.',
              'You have to verify your email address first.'
            )
            break
          case 'TooManyRequestsException':
            this.notificationService.showWarningNotification(
              'Too many requests.',
              'Please wait for a few minutes and then try again.'
            )
            break
          default:
            this.notificationService.showErrorNotification(
              'We’ve encountered an error.',
              'Something unexpected happened on our end. Please try again in a few minutes. If this error persists, please contact us.'
            )
        }
      }
    } else {
      this.viewModel.validateAllFields()
    }
  }

  updateView = () => {
    this.setState({ fields: { ...this.viewModel.getFields() } })
  }

  render() {
    const componentClasses = classNames('password-form', this.props.className)
    const fieldProps = (fieldName) => {
      const field = this.state.fields[fieldName]
      return {
        key: fieldName,
        defaultValue: field.value,
        invalid: field.showError,
        validated: this.viewModel.isFieldValid(fieldName),
        error: field.showError ? field.error : undefined,
      }
    }

    return (
      <form
        className={componentClasses}
        action={this.props.action || '/'}
        method="post"
        onSubmit={this.onFormSubmit}
        noValidate
      >
        <fieldset className="password-form__fieldset">
          <legend className="password-form__legend password-form__legend--hidden">
            Forgotten password
          </legend>
          <Field
            className="password-form__field"
            label="Email address"
            name="email"
            type="email"
            maxLength={320}
            placeholder="Please enter your email address"
            onBlur={this.onFieldChange}
            onChange={this.onFieldChange}
            autoComplete="email"
            {...fieldProps('email')}
          />
        </fieldset>
        <FieldHoneypot
          key={6}
          ref={(input) => {
            this.honeypotField = input
          }}
        />
        <SpinnerButton className="password-form__submit" primary inactive={this.state.formSending}>
          Reset your password
        </SpinnerButton>
      </form>
    )
  }
}

ForgottenPasswordForm.propTypes = {
  className: PropTypes.string,
  action: PropTypes.string,
  viewModel: PropTypes.func,
  notificationService: PropTypes.object,
}

export default ForgottenPasswordForm
