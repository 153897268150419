import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import FieldPropertyAddressAdapter from '../../adapters/FieldPropertyAddressAdapter'
import { notificationService } from '../../../container'

export const AddressPage = () => (
  <Field name="address" component={FieldPropertyAddressAdapter}>
    {props => {
      return (
        <div>
          <FieldPropertyAddressAdapter notificationService={notificationService} {...props} />
        </div>
      )
    }}
  </Field>
)

export const addressPageValidation = () => {
  return {}
}

AddressPage.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  propertyType: PropTypes.string,
}
