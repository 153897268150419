import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, withRouter } from 'react-router'

import { loginFormViewModel, authService, notificationService } from '../../container'
import parseQueryString from '../../utils/parseQueryString'

import LandlordsLoginPage from '../pages/LandlordsLoginPage'

const redirect = (path, userEmailAddress) => {
  return (
    <Redirect
      to={{
        pathname: path,
        state: { email: userEmailAddress },
      }}
    />
  )
}

const LandlordsLoginPageContainer = ({ history, location }) => {
  const parsedQueryString = parseQueryString(location.search)
  const requestedPath = parsedQueryString.redirect
  const pathToRedirectToAfterLogin = requestedPath ?? '/landlord-admin'

  return (
    <LandlordsLoginPage
      history={history}
      loginFormViewModel={loginFormViewModel}
      authService={authService}
      notificationService={notificationService}
      redirect={redirect}
      pathToRedirectToAfterLogin={pathToRedirectToAfterLogin}
    />
  )
}

LandlordsLoginPageContainer.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(LandlordsLoginPageContainer)
