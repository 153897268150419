import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import './styles.scss'

import Link from '../../atoms/Link'

const GeneralError = ({ className }) => {
  const componentClass = classNames(className, 'general-error')

  return (
    <div className={componentClass}>
      <h1 className="general-error__heading">Something is not right</h1>
      <p>We have encountered an unexpected error on our end. Our engineers have been notified.</p>
      <p>
        Please try again in a few minutes. If this error persists, please{' '}
        <Link href="mailto:support@accommodationforstudents.com">contact us</Link>.
      </p>
      <Link to="/">Back to homepage</Link>
    </div>
  )
}

GeneralError.propTypes = {
  className: PropTypes.string,
}

export default GeneralError
