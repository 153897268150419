import React from 'react'
import { Redirect } from 'react-router'
import PropTypes from 'prop-types'

import { authService } from '../../container'

import WelcomeToNewLandlordAdminPage from '../pages/WelcomeToNewLandlordAdminPage'

const WelcomeToNewLandlordAdminPageContainer = ({ location }) => {
  const redirect = path => <Redirect to={path} />

  return (
    <WelcomeToNewLandlordAdminPage
      location={location}
      redirect={redirect}
      authService={authService}
    />
  )
}

WelcomeToNewLandlordAdminPageContainer.propTypes = {
  location: PropTypes.object,
}

export default WelcomeToNewLandlordAdminPageContainer
