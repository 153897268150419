/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import FieldLettingTypeOption from './FieldLettingTypeOption'

const FieldLettingTypeList = ({ name, className, selectedLettingType, onSelectLettingType }) => {
  const componentClasses = classNames('letting-type-options', className)

  return (
    <fieldset className={componentClasses} tabIndex="0">
      <legend className="letting-type-options__legend">Choose a letting type</legend>
      <FieldLettingTypeOption
        type="Whole property"
        name={name}
        title="Whole property"
        body="Most commonly offered to a group of students on a joint tenancy agreement. If the property is for a single dweller, an individual tenancy applies."
        selected={selectedLettingType === 'Whole property'}
        onSelect={onSelectLettingType}
      />
      <FieldLettingTypeOption
        type="Individual rooms"
        name={name}
        title="Individual rooms"
        body="Rooms in this property can be let by individual students on an individual tenancy agreement."
        selected={selectedLettingType === 'Individual rooms'}
        onSelect={onSelectLettingType}
      />
      <FieldLettingTypeOption
        type="Both"
        name={name}
        title="Either whole property or individual rooms"
        body="Offered to groups and individual students, on a joint or individual tenancy agreement."
        selected={selectedLettingType === 'Both'}
        onSelect={onSelectLettingType}
      />
    </fieldset>
  )
}

FieldLettingTypeList.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  selectedLettingType: PropTypes.string.isRequired,
  onSelectLettingType: PropTypes.func.isRequired,
}

export default FieldLettingTypeList
