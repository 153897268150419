import { performApiDelete, performApiGet, performApiPatch, performApiPost } from './requests'
import { authService } from '../container'
import Account from '../models/account'

export const createAccount = async payload => {
  try {
    const response = await performApiPost('/accounts', payload)

    return {
      success: response.status === 204,
    }
  } catch (exception) {
    return { success: false, data: exception }
  }
}

export const getAccount = async token => {
  try {
    const response = await performApiGet('/accounts', token)

    return {
      success: response.status === 200,
      landlord: new Account(response.data),
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getIsAccountVerified = async token => {
  try {
    const response = await performApiGet('/accounts/verified', token)
    return {
      success: response.status === 200,
      isAccountVerified: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getAccountEmailForResetPassword = async accountId => {
  try {
    const response = await performApiGet(`/accounts/reset/${accountId}`)
    return {
      success: response.status === 200,
      email: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getAccountBoostCredits = async token => {
  try {
    const response = await performApiGet('/accounts/boost-credits', token)
    return {
      success: response.status === 200,
      boostCredits: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getAccountAdvertiseCredits = async token => {
  try {
    const response = await performApiGet('/ad-credits', token)

    return {
      success: response.status === 200,
      advertiseCreditsData: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getAccountHasEverAdvertisedAProperty = async token => {
  try {
    const response = await performApiGet('/accounts/has-advertised-properties', token)
    return {
      success: response.status === 200,
      hasAdvertisedProperties: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getAccountPaymentSettings = async token => {
  try {
    const response = await performApiGet('/accounts/payment-settings', token)

    return {
      success: response.status === 200,
      hasCards: response.data.hasCards,
      hasSubscriptions: response.data.hasSubscriptions,
      cards: response.data.cards,
      subscriptions: response.data.subscriptions,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const deleteAccountSavedCard = async (cardId, token) => {
  try {
    const response = await performApiDelete(`/accounts/payment-settings/${cardId}`, token)
    return {
      success: response.status === 200,
    }
  } catch (exception) {
    return { success: false }
  }
}

const updateAccount = async (url, payload, token) => {
  try {
    const response = await performApiPatch(url, payload, token)
    return {
      success: response.status === 200,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const updateAccountName = async (payload, token) =>
  updateAccount('/accounts/name', payload, token)

export const updateAccountCompanyName = async (payload, token) =>
  updateAccount('/accounts/company-name', payload, token)

export const updateAccountDescription = async (payload, token) =>
  updateAccount('/accounts/description', payload, token)

export const updateAccountPersonalPhone = async (payload, token) =>
  updateAccount('/accounts/personal-phone', payload, token)

export const updateAccountPublicPhoneSettings = async (payload, token) =>
  updateAccount('/accounts/public-phone-settings', payload, token)

export const updateAccountPublicEnquiriesPhoneNumber = async (payload, token) =>
  updateAccount('/accounts/public-enquiries-phone-number', payload, token)

export const updateAccountEmailAddress = async (payload, token) =>
  updateAccount('/accounts/email-address', payload, token)

export const updateAccountInvoicingAddress = async (payload, token) =>
  updateAccount('/accounts/invoicing-address', payload, token)

export const updateAccountReceiveExclusiveOffers = async (payload, token) =>
  updateAccount('/accounts/receive-exclusive-offers', payload, token)

export const updateAccountReceiveEnquiryMessagesToMobile = async (payload, token) =>
  updateAccount('/accounts/receive-enquiries-to-mobile', payload, token)

export const updateAccountPassword = payload => {
  const { currentPassword, newPassword } = payload
  return authService.changePassword(currentPassword, newPassword)
}
