import moment from 'moment'
import dateFormat from './dateFormat'

const isUndefined = date => {
  return date === '' || typeof date === 'undefined'
}

class ValidatedDate {
  constructor(date, format, dateToday) {
    this.date = isUndefined(date) ? date : moment(date, 'DD/MM/YYYY', true)
    this.dateToday = dateToday
    this.format = format
  }

  isUndefined() {
    return isUndefined(this.date)
  }

  isAValidDate() {
    return !this.isUndefined() && this.date.isValid()
  }

  isAnInvalidDate() {
    return !this.isAValidDate()
  }

  isOnOrBefore(otherDate) {
    const other = moment(otherDate, this.format, true)
    return this.date <= other
  }

  isBefore(otherDate) {
    const other = moment(otherDate, this.format, true)
    if (!this.isAValidDate()) return false
    if (!other.isValid()) return false

    return this.date < other
  }

  static forDate(date) {
    const dateToday = moment().startOf('day')
    return new ValidatedDate(date, dateFormat.DDMMYYYY, dateToday)
  }
}

export default ValidatedDate
