import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import FieldCheckbox from '@afs/components/FieldCheckbox'

import styles from './styles.module.scss'

const ToLetBoardField = ({ className, checked, onChange, numberOfBoards }) => {
  return (
    <div className={className}>
      <legend className={styles.legend}>Add extras</legend>

      <FieldCheckbox
        className={styles.field}
        name="toLetBoardsAdded"
        checked={checked}
        onChange={onChange}
        aria-describedby="disclaimer"
        label={
          <div className={styles.labelContent}>
            <strong className={styles.heading}>
              Free ‘To Let’ {numberOfBoards === 1 ? 'board' : 'boards'}
            </strong>
            <p className={styles.timeline}>Boards usually take 3-5 working days to be erected.</p>
            <p className={styles.services}>
              Also includes free <span>mounting & dismounting.</span>
            </p>
          </div>
        }
      />
      <div
        id="disclaimer"
        role="alert"
        className={classNames(styles.disclaimerCard, { [styles.disclaimerCardVisible]: checked })}
      >
        <p className={styles.disclaimerText}>
          <strong>Please note:</strong> some councils have specific rules and regulations for
          displaying ‘To Let’ boards. Please check before ordering.
        </p>
      </div>
    </div>
  )
}

ToLetBoardField.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  numberOfBoards: PropTypes.number.isRequired,
}

export default ToLetBoardField
