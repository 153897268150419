import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.scss'

import ChevronDownIcon from '../../../svgs/icons/chevron-down.svg'

const MobileContextMenu = ({ className, context, overview, children, ...props }) => {
  const [open, setOpen] = useState(false)

  let yOffset

  const removeScrollFromBodyElement = () => {
    yOffset = window.pageYOffset

    document.body.classList.add('no-scroll')
    document.body.style.top = `-${yOffset}px`
  }

  const addScrollToBodyElement = () => {
    document.body.classList.remove('no-scroll')
    window.scroll({ top: yOffset })
  }

  useEffect(() => {
    if (open) removeScrollFromBodyElement()
    else addScrollToBodyElement()

    return () => addScrollToBodyElement()
  }, [open])

  const handleClick = event => {
    event.preventDefault()
    setOpen(!open)
  }

  const componentClasses = classNames('context-menu', {
    'context-menu--open': open,
  })
  const innerClasses = classNames('context-menu__inner', className)
  const panelClasses = classNames('context-menu__panel', {
    'context-menu__panel--open': open,
  })

  return (
    <div className={componentClasses}>
      <div className={innerClasses} {...props}>
        <button className="context-menu__toggle" onClick={handleClick}>
          <span className="context-menu__toggle-wrapper">
            <span className="context-menu__toggle-overview">{overview}</span>
            {context && (
              <>
                <span className="context-menu__toggle-dot">·</span>
                <strong className="context-menu__toggle-context">{context}</strong>
              </>
            )}
          </span>

          {open ? (
            <ChevronDownIcon className="context-menu__toggle-icon context-menu__toggle-icon--chevron-up" />
          ) : (
            <ChevronDownIcon className="context-menu__toggle-icon context-menu__toggle-icon--chevron" />
          )}
        </button>
        <div className={panelClasses}>
          <div className="content-menu__panel-inner">{children}</div>
        </div>
      </div>
    </div>
  )
}

MobileContextMenu.propTypes = {
  className: PropTypes.string,
  context: PropTypes.string,
  overview: PropTypes.string,
  children: PropTypes.any,
}

export default MobileContextMenu
