import React from 'react'
import PropTypes from 'prop-types'
import ImageGallery from 'react-image-gallery'

import { config } from '../../../config.js'

import '../../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss'
import './styles.scss'

const PropertyImageCarousel = ({ photos, imageApiUrl }) => {
  const galleryOptions = {
    showThumbnails: false,
    showBullets: false,
    showFullscreenButton: false,
    showPlayButton: false,
    showIndex: true,
    indexSeparator: ' of ',
    lazyLoad: true,
  }

  const placeholderPhoto = [{ original: config.placeholderImageSrc }]

  const imageGalleryPhotos = photos.map((photo, i) => {
    return {
      original: imageApiUrl ? `${imageApiUrl}w=405/${photo.src}` : photo.src,
      originalAlt: `Property image ${i + 1}`,
    }
  })

  return (
    <ImageGallery
      items={photos.length > 0 ? imageGalleryPhotos : placeholderPhoto}
      {...galleryOptions}
    />
  )
}

PropertyImageCarousel.propTypes = {
  photos: PropTypes.array.isRequired,
  imageApiUrl: PropTypes.string,
}

export default PropertyImageCarousel
