import React from 'react'
import PropTypes from 'prop-types'
import FieldOptionGroup from '../molecules/FieldOptionGroup'

const accommodationTypeOptions = ['House', 'Flat', 'Studio']

const FieldAccommodationTypeAdapter = ({ input: { name, onChange, value }, label }) => (
  <FieldOptionGroup
    name={name}
    options={accommodationTypeOptions}
    selectedOption={value}
    label={label}
    legend="Select the accommodation type"
    hideLegend
    showIcons
    onSelectOption={type => onChange(type)}
  />
)

FieldAccommodationTypeAdapter.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
}

export default FieldAccommodationTypeAdapter
