import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import Address from '../../../../models/address'

import { Filter } from '../../../molecules/Filtering'
import SearchField from '../../../molecules/SearchField'

export const matchPropertyAddress = (property, searchTerm) => {
  const address = new Address(property.address)
  const propertyAddressString = address.addressFields().join(', ')
  const postcodeWithoutWhitespace = Array.from(address.postcodeOnly())
    .filter(char => char !== ' ')
    .join('')

  const addressMatch = propertyAddressString.toLowerCase().includes(searchTerm.toLowerCase())
  const postcodeWithoutWhitespaceMatch = postcodeWithoutWhitespace
    .toLowerCase()
    .includes(searchTerm.toLowerCase())

  return addressMatch || postcodeWithoutWhitespaceMatch
}

const FilterByAddress = ({
  className,
  currentSearchTerm,
  setCurrentSearchTerm,
  tabIndex,
  ...props
}) => {
  const inputRef = useRef()

  return (
    <Filter
      alwaysApply
      condition={property => matchPropertyAddress(property, inputRef.current.value)}
      {...props}
    >
      {({ applyFilter }) => {
        return (
          <SearchField
            className={className}
            ref={inputRef}
            name="filter-by-address"
            ariaLabel="Filter properties by address"
            value={currentSearchTerm}
            handleChange={searchTerm => {
              setCurrentSearchTerm(searchTerm)
              applyFilter()
            }}
            handleClearValue={() => {
              inputRef.current.value = ''
              setCurrentSearchTerm('')
              applyFilter()
            }}
            placeholder="Filter by address"
            tabIndex={tabIndex}
          />
        )
      }}
    </Filter>
  )
}

FilterByAddress.propTypes = {
  className: PropTypes.string,
  currentSearchTerm: PropTypes.string.isRequired,
  setCurrentSearchTerm: PropTypes.func.isRequired,
  tabIndex: PropTypes.string,
}

export default FilterByAddress
