import locations from './locations'

const isValidLocation = locationName => {
  if (!locationName) return false
  return locations.some(location => location.name.toLowerCase() === locationName.toLowerCase())
}

export default {
  isValidLocation,
}
