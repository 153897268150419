import React from 'react'
import PropTypes from 'prop-types'
import SpinnerButton from '@afs/components/SpinnerButton'

import { config } from '../../../config'
import scrollToNotification from '../../../utils/scrollToNotification'

import Heading from '../../atoms/Heading'
import Link from '../../atoms/Link'
import LinkButton from '../../atoms/LinkButton'
import Picture from '../../atoms/Picture'
import Notification from '../../molecules/Notification'

import styles from './welcomeToNewLandlordAdmin.module.scss'

const imageApiUrl = config.imgApiUrl
const welcomeImageSrc = 'https://afs-assets-public.s3.eu-west-2.amazonaws.com/welcome.png'

const WelcomeToNewLandlordAdmin = ({
  isTabletOrDesktop,
  handleResetPassword,
  handleShowIntercom,
  resettingPassword,
  notification,
}) => {
  const handleOnClick = async () => {
    await handleResetPassword()
    scrollToNotification()
  }

  const handleContactButtonClick = event => {
    event.preventDefault()
    handleShowIntercom()
  }

  const showErrorNotification = notification.show && notification.type === 'error'
  const showSuccessNotification = notification.show && notification.type === 'success'

  return (
    <div className={styles.layout} data-testid="welcome">
      {isTabletOrDesktop && (
        <div className={styles.leftColumn}>
          <Picture
            alt="Welcome"
            src={welcomeImageSrc}
            lazyload={false}
            sources={[
              {
                id: 'tablet',
                src: `${imageApiUrl}w=400/${welcomeImageSrc}`,
                media: '(min-width: 769px) and (max-width: 960px)',
              },
              {
                id: 'desktop',
                src: `${imageApiUrl}w=522/${welcomeImageSrc}`,
                media: '(min-width: 961px)',
              },
            ]}
          />
        </div>
      )}
      <div className={styles.rightColumn}>
        {showErrorNotification && (
          <Notification
            className={styles.notification}
            type="error"
            heading="Error."
            text="Your password could not be reset. Please try again in a few minutes. If this error persists, please contact us."
          />
        )}
        <Heading className={styles.heading} level={1} data-testid="welcome-heading">
          <span className={styles.headingLine}>Welcome to the all-new </span>
          <span className={styles.headingLine}>Landlord Admin</span>
        </Heading>
        <p className={styles.subheading} data-testid="welcome-subheading">
          We’ve listened to your feedback and are delighted to let you know your new Landlord Admin
          area is ready!
        </p>
        <p className={styles.text} data-testid="welcome-text">
          Before you can access your account, we will need you to{' '}
          <strong>reset your password.</strong>
        </p>
        {showSuccessNotification ? (
          <Notification
            className={styles.notification}
            type="success"
            heading="Check your email inbox"
            text="We’ve sent you an email with instructions on how to reset your password. It could take a few minutes to arrive."
            nonDismissible
          />
        ) : (
          <SpinnerButton
            className={styles.resetPasswordButton}
            primary
            inactive={resettingPassword}
            onClick={handleOnClick}
            data-testid="reset-password-button"
          >
            Send reset password link
          </SpinnerButton>
        )}
        <hr className={styles.divider} />
        <p className={styles.contactSupport} data-testid="contact-support">
          If you’re having trouble,{' '}
          {isTabletOrDesktop ? (
            <LinkButton onClick={handleContactButtonClick} data-testid="contact-support-button">
              contact our support team.
            </LinkButton>
          ) : (
            <Link
              href="mailto:support@accommodationforstudents.com"
              target="_blank"
              data-testid="contact-support-link"
            >
              contact our support team.
            </Link>
          )}
        </p>
        <Link className={styles.loginLink} to="/login" data-testid="login-page-link">
          Back to login
        </Link>
      </div>
    </div>
  )
}

WelcomeToNewLandlordAdmin.propTypes = {
  isTabletOrDesktop: PropTypes.bool.isRequired,
  handleResetPassword: PropTypes.func.isRequired,
  handleShowIntercom: PropTypes.func.isRequired,
  resettingPassword: PropTypes.bool.isRequired,
  notification: PropTypes.shape({
    show: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
}

export default WelcomeToNewLandlordAdmin
