import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'

import { getPropertyCount } from '../../api/propertyApi'
import { getAllEnquiries, deleteEnquiryById } from '../../api/enquiryApi'
import { useAuthContext } from '../../authContext'

import EnquiriesPage from '../pages/EnquiriesPage'
import LoadingPage from '../pages/LoadingPage'
import ErrorPage from '../pages/ErrorPage'

const EnquiriesContainer = ({ history }) => {
  const [properties, setProperties] = useState(false)
  const [enquiries, setEnquiries] = useState([])
  const [loading, setLoading] = useState(true)
  const [serverError, setServerError] = useState(false)

  const { token } = useAuthContext()

  useEffect(() => {
    Auth.currentSession().then(({ idToken }) => {
      const { jwtToken } = idToken

      getPropertyCount(jwtToken).then(count => {
        if (count > 0) {
          setProperties(true)
          getAllEnquiries(jwtToken).then(response => {
            if (response.success) {
              setEnquiries(response.enquiries)
              setLoading(false)
            } else {
              setServerError(true)
            }
          })
        } else {
          setServerError(true)
        }
      })
    })
  }, [])

  const deleteEnquiry = async id => {
    const response = await deleteEnquiryById(id, token)

    return response
  }

  if (serverError) return <ErrorPage />
  if (loading) return <LoadingPage />

  return (
    <EnquiriesPage
      history={history}
      landlordHasProperties={properties}
      enquiries={enquiries}
      deleteEnquiry={deleteEnquiry}
    />
  )
}

EnquiriesContainer.propTypes = {
  history: PropTypes.object,
}

export default EnquiriesContainer
