import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'
import FieldLettingTypeAdapter from '../../adapters/FieldLettingTypeAdapter'

export const LettingTypePage = () => (
  <Field name="lettingType" component={FieldLettingTypeAdapter}>
    {props => {
      return (
        <div>
          {props.meta.error && <span>{props.meta.error}</span>}
          <FieldLettingTypeAdapter {...props} />
        </div>
      )
    }}
  </Field>
)

export const LettingTypePageValidation = () => {
  const errors = {}
  return errors
}

LettingTypePage.propTypes = {
  meta: PropTypes.object,
}
