import React from 'react'
import PropTypes from 'prop-types'

import SpinnerButton from '@afs/components/SpinnerButton'
import Overlay from '../../molecules/Overlay'

import Heading from '../../atoms/Heading'
import AvatarImage from '../../atoms/AvatarImage'
import Address from '../../../models/address'

import styles from './index.module.scss'
import Button from '../../atoms/Button'
import Card from '../../atoms/Card'
import overlayStyles from '../../pages/PropertyDetailsPage/modules/overlay.module.scss'
import Notification from '../../molecules/Notification'

const formatPropertyAddress = propertyAddress => {
  const address = new Address(propertyAddress)
  const addressFirstLine = address.firstTwoLines()
  const addressSecondLine = `${address
    .withoutFirstTwoLinesAndPostcode()
    .join(', ')}, ${address.postcodeOnly()}`

  return {
    addressFirstLine,
    addressSecondLine,
  }
}

const PropertyCard = ({ property }) => {
  const { addressFirstLine, addressSecondLine } = formatPropertyAddress(property.address)

  const hasPhoto = property.photos.length > 0
  const photoUrl = property.photos.length > 0 ? property.photos[0].src : ''

  return (
    <Card className={styles.propertyCard} middleCardClass={styles.propertyCardMiddle}>
      <div className={styles.propertyCardInner}>
        <div className={styles.property} data-testid="performance-list-row-property">
          <AvatarImage
            className={styles.propertyImage}
            hasPhoto={hasPhoto}
            photoUrl={photoUrl}
            alt={addressFirstLine}
          />
          <div className={styles.propertyAddress}>
            <p className={styles.addressTitle}>{addressFirstLine}</p>
            <p className={styles.addressSubtitle}>{addressSecondLine}</p>
          </div>
        </div>
      </div>
    </Card>
  )
}

PropertyCard.propTypes = {
  property: PropTypes.shape({
    address: PropTypes.object,
    photos: PropTypes.array,
  }),
}

const DelistPropertyOverlay = ({
  config,
  property,
  handleDelistProperty,
  handleOverlayClose,
  updating,
  propertyDelistError,
}) => {
  return (
    <Overlay
      id="delist-property-overlay"
      dataTestId="delist-property-overlay"
      closeLabel="Close"
      classNameOverlayInner={styles.overlayInner}
      isActive={config.active}
      onClose={handleOverlayClose}
      preserveScrollPositionOnClose
    >
      {property.isListed && (
        <>
          <Heading level={3}>
            <span>Are you sure you want to</span>
            <span className={styles.headingSecondLine}>delist this property?</span>
          </Heading>
          {propertyDelistError && (
            <Notification
              className={overlayStyles.errorNotification}
              heading="Oops!"
              text="We weren’t able to delist your property. Please try again."
              type="error"
            />
          )}
          <PropertyCard property={property} />
          <SpinnerButton
            dark
            className={styles.buttonLeft}
            onClick={handleDelistProperty}
            inactive={updating}
            data-testid="overlay-delist-property-button"
          >
            Delist property
          </SpinnerButton>
          <Button className={styles.buttonRight} onClick={handleOverlayClose} secondary>
            Cancel
          </Button>
        </>
      )}
      {!property.isListed && (
        <>
          <Heading className={styles.heading} level={3}>
            This property has already been delisted.
          </Heading>
          <PropertyCard property={property} />
          <Button onClick={handleOverlayClose} secondary>
            View property page
          </Button>
        </>
      )}
    </Overlay>
  )
}

DelistPropertyOverlay.propTypes = {
  config: PropTypes.object.isRequired,
  handleDelistProperty: PropTypes.func.isRequired,
  handleOverlayClose: PropTypes.func.isRequired,
  property: PropTypes.shape({ isListed: PropTypes.bool, photos: PropTypes.array }),
  updating: PropTypes.bool.isRequired,
  propertyDelistError: PropTypes.bool.isRequired,
}

export default DelistPropertyOverlay
