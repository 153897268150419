import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { config } from '../../../config.js'
import Property from '../../../models/property'

import Link from '../../atoms/Link'
import Card from '../../atoms/Card'
import ImagePlaceholder from '../../atoms/ImagePlaceholder'
import LazyImage from '../../atoms/LazyImage'
import IconText from '../IconText'
import AdStatusTag from '../../atoms/AdStatusTag'

import admin from './admin.module.scss'

const PropertyAdminCard = ({ className, imageClassName, property, ...props }) => {
  const propertyModel = new Property(property)
  const propertyUrl = `/landlord-admin/property/${property.id}`
  const cardClasses = classNames(className, admin.card)
  const imageClasses = classNames(imageClassName, admin.placeholder)
  const lazyImageClasses = classNames(imageClassName, admin.imgContainer)

  return (
    <Link className={admin.link} to={propertyUrl} {...props}>
      <Card
        className={cardClasses}
        topCard={
          property.photos && property.photos.length > 0 ? (
            <div className={lazyImageClasses} data-testid="admin-image-container">
              <LazyImage
                className={admin.img}
                alt={propertyModel.address.firstTwoLines()}
                src={`${config.imgApiUrl}w=472/${property.photos[0].src}`}
                dataSrc={`${config.imgApiUrl}w=472/${property.photos[0].src}`}
              />
            </div>
          ) : (
            <ImagePlaceholder
              className={imageClasses}
              alt={`${propertyModel.address.firstTwoLines()} - No photos added`}
              maxWidth={472}
            />
          )
        }
        middleCardClass={admin.content}
        topCardBelow
        noBorder
        noShadow
        light
      >
        <strong className={admin.heading} data-testid="admin-heading">
          {propertyModel.address.firstTwoLines()}
        </strong>
        <p className={admin.subHeading} data-testid="admin-subheading">
          <span>{propertyModel.address.withoutFirstTwoLinesAndPostcode().join(', ')},</span>
          <span className={admin.subHeadingPostcode}> {propertyModel.address.postcodeOnly()}</span>
        </p>
        <IconText
          className={admin.bedrooms}
          icon="bedSize"
          text={
            <span data-testid="admin-rooms">
              <strong>
                {propertyModel.numberOfAvailableBedrooms()} / {propertyModel.totalBedrooms()}
              </strong>{' '}
              beds available
            </span>
          }
        />
        <AdStatusTag
          className={admin.adStatus}
          status={propertyModel.status}
          isListed={propertyModel.isListed}
          startDate={propertyModel.startDate()}
          expiryDate={propertyModel.expiryDate()}
        />
      </Card>
    </Link>
  )
}

PropertyAdminCard.propTypes = {
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  property: PropTypes.object.isRequired,
}

export default PropertyAdminCard
