class ResetPasswordFormViewModel {
  constructor(view, purifier, authService, urlParams) {
    this.view = view
    this.purifier = purifier
    this.authService = authService
    this.urlParams = urlParams
    this.fields = {
      password: {
        value: '',
        valid: false,
        error: '',
        showError: false,
      },
      confirmPassword: {
        value: '',
        valid: false,
        error: "Passwords don't match. Please double check what you've entered.",
        showError: false,
      },
    }
    this.formSending = false
  }

  getFields() {
    return this.fields
  }

  landlordId() {
    return this.urlParams.get('id')
  }

  updatePasswordField = field => {
    this.validatePasswordField(field)
    this.view.updateView()
  }

  updatePasswordConfirmField = field => {
    this.validateConfirmPasswordField(field)
    this.view.updateView()
  }

  isFieldValid = fieldName => {
    const field = this.fields[fieldName]
    const isValid = field.valid && !!field.value
    return isValid
  }

  isFormValid(form) {
    return this.validateForm(form)
  }

  validateForm = () => {
    const allFieldsCompleted = this.checkValidity()
    const botNotSubmittingForm = !this.honeypotField.state.value

    return allFieldsCompleted && botNotSubmittingForm
  }

  checkValidity = () => {
    return Object.keys(this.fields)
      .map(fieldName => this.fields[fieldName].valid)
      .every(fieldsValidity => fieldsValidity === true)
  }

  validateField = field => {
    const fieldName = field.name
    const fieldValue = this.purifier.sanitize(field.value)
    const isFieldValid = field.validity.valid

    if (fieldName === 'password') {
      this.validatePasswordCharacters(fieldValue)
    } else {
      this.fields[fieldName] = {
        ...this.fields[fieldName],
        value: fieldValue,
        valid: isFieldValid,
        showError: !isFieldValid,
      }
    }
  }

  validatePasswordCharacters = fieldValue => {
    const passwordLengthCorrect = fieldValue.length >= 8
    const passwordHasLowerCaseLetter = !!fieldValue.match(/[a-z]/g)
    const passwordHasUppercaseLetter = !!fieldValue.match(/[A-Z]/g)
    const passwordHasDigit = !!fieldValue.match(/[0-9]/g)
    const passwordIsValid =
      passwordLengthCorrect &&
      passwordHasLowerCaseLetter &&
      passwordHasUppercaseLetter &&
      passwordHasDigit

    this.fields.password = {
      ...this.fields.password,
      value: fieldValue,
      valid: passwordIsValid,
      showError: !passwordIsValid,
    }
  }

  validatePasswordField = field => {
    this.validateField(field)

    const hasPassword = !!this.fields.confirmPassword.value

    if (hasPassword) {
      const isFieldValid = field.value === this.fields.confirmPassword.value

      this.fields.confirmPassword = {
        ...this.fields.confirmPassword,
        value: this.purifier.sanitize(field.value),
        valid: isFieldValid,
        showError: !isFieldValid,
      }
    }
  }

  validateConfirmPasswordField = field => {
    const fieldName = field.name
    const fieldValue = this.purifier.sanitize(field.value)

    const passwordsMatch = field.value === this.fields.password.value

    this.fields[fieldName] = {
      ...this.fields[fieldName],
      value: fieldValue,
      valid: passwordsMatch,
      showError: !passwordsMatch,
    }
  }

  validateAllFields = () => {
    const validatedFields = Object.keys(this.fields).reduce((validatedFields, fieldName) => {
      const field = this.fields[fieldName]
      const isFieldValid = field.valid

      validatedFields[fieldName] = {
        ...field,
        showError: !isFieldValid,
      }

      return validatedFields
    }, {})

    this.fields = validatedFields
    this.view.updateView()
  }

  invalidateFields = fields => {
    fields.forEach(invalidField => {
      const savedField = this.fields[invalidField.fieldName]
      this.fields[invalidField.fieldName] = {
        ...savedField,
        valid: false,
        error: invalidField.error,
        showError: true,
      }
    })
    this.view.updateView()
  }

  registerHoneypot = field => {
    this.honeypotField = field
  }

  postForm = async () => {
    const password = this.fields.password.value
    const code = this.urlParams.get('code')
    const id = this.urlParams.get('id')
    return this.authService.forgotPasswordConfirmation(code, id, password)
  }
}

export default ResetPasswordFormViewModel
