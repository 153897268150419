import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SpinnerButton from '@afs/components/SpinnerButton'
import Field from '@afs/components/Field'

import scrollToNotification from '../../../utils/scrollToNotification'
import { errorCodes } from '../../../services/authService'

import FieldHoneypot from '../../molecules/FieldHoneypot'
import PasswordRequirements from '../../atoms/PasswordRequirements'

import './styles.scss'

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props)
    this.resetPasswordFormViewModel = props.viewModel(this)
    this.history = props.history
    this.notificationService = props.notificationService
    this.getLandlordEmailForResetPassword = props.getLandlordEmailForResetPassword
    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      fields: { ...this.resetPasswordFormViewModel.getFields() },
      landlordEmail: '',
      formSending: false,
      updatingPassword: true,
    }
  }

  componentDidMount() {
    this.getLandlordEmail().then((landlordEmail) => {
      this.setState({
        landlordEmail,
      })
    })

    this.resetPasswordFormViewModel.registerHoneypot(this.honeypotField)
  }

  getLandlordEmail = async () => {
    const landlordId = this.resetPasswordFormViewModel.landlordId()
    const response = await this.getLandlordEmailForResetPassword(landlordId)

    if (response.success) {
      return response.email
    }

    return ''
  }

  onFormSubmit = async (event) => {
    event.preventDefault()

    const canSubmitForm = this.resetPasswordFormViewModel.isFormValid(event.target)

    if (canSubmitForm) {
      this.setState({
        formSending: true,
      })

      const response = await this.resetPasswordFormViewModel.postForm()

      this.setState({
        formSending: false,
      })

      if (response.success) {
        this.notificationService.addDeferredSuccessNotification(
          'Success!',
          'Your password was reset successfully. You can login now.'
        )
        this.history.push('/landlord-admin/login')
      } else {
        switch (response.code) {
          case errorCodes.internalErrorInIdentityProvider:
          case errorCodes.passwordResetInvalidIdentityProviderRequestConfiguration:
          case errorCodes.passwordResetRequestLimitExceeded:
          case errorCodes.passwordResetUserNotAuthorized:
          case errorCodes.passwordResetInvalidLambdaResponse:
          case errorCodes.passwordResetUnexpectedLambdaException:
          case errorCodes.passwordResetLambdaValidationException:
            this.props.notificationService.showErrorNotification(
              'We’ve encountered an error.',
              'Something unexpected happened on our end. Please try again in a few minutes. If this error persists, please contact us.'
            )
            scrollToNotification()
            break
          case errorCodes.passwordResetInvalidPasswordSupplied:
            this.props.notificationService.showErrorNotification(
              'Invalid password.',
              'Your password does not meet the required criteria.'
            )
            scrollToNotification()
            break
          case errorCodes.passwordResetTooManyFailedAttempts:
            this.props.notificationService.showErrorNotification(
              'Too many failed attempts.',
              'Please wait for a few minutes and then try again.'
            )
            scrollToNotification()
            break
          case errorCodes.passwordResetUserAccountNotFound:
            this.props.notificationService.showErrorNotification(
              'Can’t find account.',
              'This account does not exist or it has been removed.'
            )
            scrollToNotification()
            break
          case errorCodes.invalidToken:
            this.props.notificationService.showErrorNotification(
              'Invalid token.',
              'The password token is invalid. If this error persists, please contact us.'
            )
            scrollToNotification()
            break
          case errorCodes.expiredConfirmationCode:
            this.props.notificationService.showWarningNotification('This link has expired.', [
              <span key="notification-1">{`If you're trying to reset your password, you can `}</span>,
              <a href="/forgotten-password" key="notification-2">
                request a new one here.
              </a>,
              <span key="notification-3">{` If you've already reset your password, you can `}</span>,
              <a href="/landlord-admin/login" key="notification-4">
                login here
              </a>,
            ])
            scrollToNotification()
            break
          case errorCodes.passwordResetTooManyRequests:
            this.props.notificationService.showWarningNotification(
              'Too many requests.',
              'Please wait for a few minutes and then try again.'
            )
            scrollToNotification()
            break
          case errorCodes.passwordResetUserNotConfirmed:
            this.props.notificationService.showWarningNotification(
              'Unverified email address.',
              'You have to verify your email address first.'
            )
            break
          case errorCodes.incorrectConfirmationCode:
            this.props.notificationService.showWarningNotification('This link has expired.', [
              <span key="notification-1">
                For security reasons, this link expired as soon as a newer one was requested. Please
                check your inbox for a more recent reset email, or{' '}
              </span>,
              <a href="/forgotten-password" key="notification-2">
                request a brand new one here.
              </a>,
            ])
            scrollToNotification()
            break
          default:
            this.props.notificationService.showErrorNotification(
              'We’ve encountered an error.',
              'Something unexpected happened on our end. Please try again in a few minutes. If this error persists, please contact us.'
            )
            scrollToNotification()
        }
      }
    } else {
      this.resetPasswordFormViewModel.validateAllFields()
    }
  }

  handlePasswordChange = (event) => {
    this.setState({ updatingPassword: true })
    this.resetPasswordFormViewModel.updatePasswordField(event.target)
  }

  handlePasswordConfirmChange = (event) => {
    this.resetPasswordFormViewModel.updatePasswordConfirmField(event.target)
  }

  updateView = () => {
    this.setState({
      fields: { ...this.resetPasswordFormViewModel.getFields() },
    })
  }

  render() {
    const componentClasses = classNames('reset-password-form', this.props.className)
    const fieldProps = (fieldName) => {
      const field = this.state.fields[fieldName]
      return {
        key: fieldName,
        defaultValue: field.value,
        invalid: field.showError,
        validated: this.resetPasswordFormViewModel.isFieldValid(fieldName),
        error: field.showError ? field.error : undefined,
      }
    }
    const buttonIsDisabled = this.state.formSending
    const buttonText = this.props.buttonText ? this.props.buttonText : 'Reset your password'

    const passwordIsValid = this.state.fields.password.valid
    const passwordFieldIsValid = !this.state.updatingPassword && passwordIsValid
    const passwordFieldIsInvalid = !this.state.updatingPassword && !passwordIsValid
    const passwordFieldIsEmpty =
      !this.state.updatingPassword && this.state.fields.password.value === ''

    return (
      <form
        className={componentClasses}
        action={this.props.action || '/'}
        method="post"
        onSubmit={this.onFormSubmit}
        noValidate
      >
        <fieldset className="reset-password-form__fieldset">
          <legend className="reset-password-form__legend reset-password-form__legend--hidden">
            Reset your password
          </legend>
          <Field
            className="reset-password-form__landlord-email-field"
            name="emailAddress"
            type="email"
            label="Email address"
            value={this.state.landlordEmail}
            disabled
            autoComplete="username"
          />
          <Field
            className="reset-password-form__field"
            name="password"
            label="New password"
            type="password"
            onChange={this.handlePasswordChange}
            onBlur={() => this.setState({ updatingPassword: false })}
            invalid={passwordFieldIsInvalid || passwordFieldIsEmpty}
            validated={passwordFieldIsValid}
            autoComplete="new-password"
          />
          <PasswordRequirements
            password={this.state.fields.password.value}
            userIsUpdating={this.state.updatingPassword}
          />
          <Field
            className="reset-password-form__field"
            label="Confirm new password"
            name="confirmPassword"
            type="password"
            onChange={this.handlePasswordConfirmChange}
            onBlur={this.handlePasswordConfirmChange}
            autoComplete="new-password"
            {...fieldProps('confirmPassword')}
          />
        </fieldset>
        <FieldHoneypot
          key={6}
          ref={(input) => {
            this.honeypotField = input
          }}
        />
        <SpinnerButton
          className="reset-password-form__submit"
          primary
          inactive={buttonIsDisabled}
          data-testid="reset-password-button"
        >
          {buttonText}
        </SpinnerButton>
      </form>
    )
  }
}

ResetPasswordForm.propTypes = {
  className: PropTypes.string,
  action: PropTypes.string,
  history: PropTypes.object,
  notificationService: PropTypes.object,
  getLandlordEmailForResetPassword: PropTypes.func.isRequired,
  viewModel: PropTypes.func,
  buttonText: PropTypes.string,
}

export default ResetPasswordForm
