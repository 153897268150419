import React from 'react'
import PropTypes from 'prop-types'

import TwoColumnPageTemplate from '../../templates/TwoColumnPageTemplate'
import Header from '../../organisms/Header'
import MobileContextMenu from '../../organisms/MobileContextMenu'
import SelectedPropertiesInBasket from '../../organisms/SelectedPropertiesInBasket'
import BackLink from '../../molecules/BackLink'
import FormPageHeading from '../../molecules/FormPageHeading'
import ChangeAvailabilityForm from './Form/ChangeAvailabilityForm'
import Notifications from '../../organisms/Notifications'

import styles from './availabilityPage.module.scss'

const MultiplePropertiesAvailabilityPage = ({
  history,
  loading,
  selectedProperties,
  onSave,
  deselectProperty,
}) => {
  const numberOfSelectedProperties = selectedProperties.length
  const atLeastOneSelected = numberOfSelectedProperties > 0
  const moreThanOneSelected = numberOfSelectedProperties > 1

  const subheadingText = moreThanOneSelected
    ? 'Change the ’Move in’ and ‘Move out’ dates for these properties.'
    : 'Change the ’Move in’ and ‘Move out’ dates for this property.'

  const renderLeftColumn = () => {
    return (
      <>
        <BackLink
          className="form-navigation__back-link"
          onClick={event => {
            event.preventDefault()
            history.goBack()
          }}
        />
        <FormPageHeading
          className={styles.heading}
          heading="Change availability dates"
          subheading={subheadingText}
          data-testid="availability-heading"
        />
        <Notifications className={styles.notifications} />
        <ChangeAvailabilityForm loading={loading} onSave={onSave} />
      </>
    )
  }

  return (
    <TwoColumnPageTemplate
      className={styles.page}
      header={<Header />}
      title="Change Availability Dates | AFS"
      contextMenu={
        atLeastOneSelected && (
          <MobileContextMenu
            className={styles.mobileContextMenu}
            overview={
              moreThanOneSelected
                ? `${numberOfSelectedProperties} properties selected`
                : '1 property selected'
            }
          >
            <SelectedPropertiesInBasket
              selectedProperties={selectedProperties}
              deselectProperty={deselectProperty}
            />
          </MobileContextMenu>
        )
      }
      leftColumn={renderLeftColumn()}
      rightColumn={
        <SelectedPropertiesInBasket
          heading={`Selected ${moreThanOneSelected ? 'properties' : 'property'}`}
          selectedProperties={selectedProperties}
          deselectProperty={deselectProperty}
        />
      }
    />
  )
}

MultiplePropertiesAvailabilityPage.propTypes = {
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedProperties: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  deselectProperty: PropTypes.func.isRequired,
}

export default MultiplePropertiesAvailabilityPage
