const Products = () => {
  const advertisementOneMonth = {
    sku: 'AFS-AD-1MONTH',
    price: 23.0,
    description: '1 month advert',
  }

  const advertisementThreeMonths = {
    sku: 'AFS-AD-3MONTHS',
    price: 59.0,
    description: '3 month advert',
  }

  const advertisementSixMonths = {
    sku: 'AFS-AD-6MONTHS',
    price: 80.0,
    description: '6 month advert',
  }

  const advertisementTwelveMonths = {
    sku: 'AFS-AD-12MONTHS',
    price: 99.0,
    description: '12 month advert',
  }

  const boostCreditsTen = {
    sku: 'AFS-BOOST-CREDITS-10',
    price: 10.0,
    priceEach: 1.0,
    description: '10 Boost credits',
    quantity: 10,
  }

  const boostCreditsTwentyFive = {
    sku: 'AFS-BOOST-CREDITS-25',
    price: 19.0,
    priceEach: 0.76,
    description: '25 Boost credits',
    quantity: 25,
  }

  const boostCreditsFifty = {
    sku: 'AFS-BOOST-CREDITS-50',
    price: 25.0,
    priceEach: 0.5,
    description: '50 Boost credits',
    quantity: 50,
  }

  const boostCreditsOneHundred = {
    sku: 'AFS-BOOST-CREDITS-100',
    price: 35.0,
    priceEach: 0.35,
    description: '100 Boost credits',
    quantity: 100,
  }

  const toLetBoard = {
    sku: 'AFS-TO-LET-BOARD',
    price: 0.0,
    description: '‘To Let’ Board',
  }

  const adverts = [
    advertisementOneMonth,
    advertisementThreeMonths,
    advertisementSixMonths,
    advertisementTwelveMonths,
  ]

  const boostPackages = [
    boostCreditsTen,
    boostCreditsTwentyFive,
    boostCreditsFifty,
    boostCreditsOneHundred,
  ]

  const adOneMonth = () => advertisementOneMonth
  const adThreeMonths = () => advertisementThreeMonths
  const adSixMonths = () => advertisementSixMonths
  const adTwelveMonths = () => advertisementTwelveMonths

  const isAdvert = sku => {
    return adverts.find(advert => advert.sku === sku)
  }

  const isBoostCredits = sku => {
    return boostPackages.find(credits => credits.sku === sku)
  }

  const isToLetBoard = sku => {
    return toLetBoard.sku === sku
  }

  const cheapestAvailableAd = () => {
    return advertisementOneMonth.price
  }

  return {
    adOneMonth,
    adThreeMonths,
    adSixMonths,
    adTwelveMonths,
    boostCreditsTen,
    boostCreditsTwentyFive,
    boostCreditsFifty,
    boostCreditsOneHundred,
    toLetBoard,
    isAdvert,
    isBoostCredits,
    isToLetBoard,
    cheapestAvailableAd,
  }
}

export default Products
