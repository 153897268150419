import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'
import FieldIncrementCountAdapter from '../../adapters/FieldIncrementCountAdapter'

export const BathroomsPage = ({ propertyType }) => {
  const isStudio = propertyType === 'Studio'
  const min = 0
  const max = isStudio ? 1 : 14
  return (
    <Field name="bathrooms" component={FieldIncrementCountAdapter}>
      {(props) => {
        return (
          <div>
            <FieldIncrementCountAdapter
              {...props}
              name="numberOfRooms"
              label="Bathrooms"
              min={min}
              max={max}
              error={props.meta.error}
            />
          </div>
        )
      }}
    </Field>
  )
}

export const bathroomsPageValidation = () => {
  return {}
}

BathroomsPage.propTypes = {
  meta: PropTypes.object,
  propertyType: PropTypes.string,
}
