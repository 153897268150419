import { config } from './config'

export default {
  Auth: {
    region: 'eu-west-2',
    userPoolId: config.cognitoUserPoolId,
    userPoolWebClientId: config.cognitoUserPoolClientId,
    mandatorySignIn: false,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    cookieStorage: {
      domain: config.cookieDomain,
      path: '/',
      expires: 30,
    },
    identityPoolId: config.identityPoolId,
  },
  Storage: {
    AWSS3: {
      bucket: config.propertyImagesS3Bucket,
      region: 'eu-west-2',
    },
  },
}
