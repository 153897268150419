import React from 'react'
import SpinnerButton from '@afs/components/SpinnerButton'

import SubmitButton from '../../../atoms/SubmitButton'
import YouTubeVideoEmbed from '../../../atoms/YoutubeVideoEmbed'

import { VideoTourPage, videoTourPageValidation } from '../../FormPages/VideoTourPage/VideoTourPage'

const videoTourPageConfig = (propertyId, updatePropertyVideoTour) => ({
  id: 12,
  component: values => (
    <VideoTourPage
      videoTourUrl={values.videoTourUrl}
      videoEmbedComponent={(className, videoId) => (
        <YouTubeVideoEmbed className={className} videoId={videoId} />
      )}
    />
  ),
  route: `/landlord-admin/property/${propertyId}/edit/video-tour`,
  validate: values => videoTourPageValidation(values.videoTourUrl),
  isValid: false,
  heading: 'Video tour',
  subheading: 'Copy your YouTube video link and paste it in the field below.',
  button: (values, loading) => {
    const urlIsInvalid = !!videoTourPageValidation(values.videoTourUrl).videoTourUrl

    if (urlIsInvalid) return null

    if (loading)
      return (
        <SpinnerButton primary inactive data-testid="spinner-button">
          Continue
        </SpinnerButton>
      )

    return (
      <SubmitButton primary data-testid="save-button">
        Save changes
      </SubmitButton>
    )
  },
  onSubmit: async (values, token) => {
    const payload = {
      propertyId,
      videoTourUrl: values.videoTourUrl || '',
    }
    return updatePropertyVideoTour(payload, token)
  },
})

export default videoTourPageConfig
