import React from 'react'
import PropTypes from 'prop-types'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import './styles.scss'

export const FadeInOut = ({ children, key, ...props }) => {
  return (
    <CSSTransition classNames="fade-in-out" key={key} timeout={200} {...props}>
      {children}
    </CSSTransition>
  )
}

FadeInOut.propTypes = {
  children: PropTypes.any.isRequired,
}

export const FadeInOutGroup = ({ renderItems, ...props }) => {
  const fadeInOutItem = (item, key) => <FadeInOut key={key}>{item}</FadeInOut>

  return (
    <TransitionGroup component={null} {...props}>
      {renderItems(fadeInOutItem)}
    </TransitionGroup>
  )
}

FadeInOutGroup.propTypes = {
  renderItems: PropTypes.func.isRequired,
}
