import React from 'react'
import PropTypes from 'prop-types'
import FieldTextarea from '@afs/components/FieldTextarea'

const FieldTextAreaAdapter = ({ input: { name, onChange, value }, meta, ...props }) => {
  return (
    <FieldTextarea
      name={name}
      value={value}
      onChange={onChange}
      maxLength={2000}
      characterLimit={2000}
      error={meta.error}
      invalid={!!meta.error}
      {...props}
    />
  )
}

FieldTextAreaAdapter.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
}

export default FieldTextAreaAdapter
