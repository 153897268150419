import React, { useState } from 'react'
import PropTypes from 'prop-types'

import useMedia from '../../../hooks/useMedia'
import { config } from '../../../config'

import TwoColumnPageTemplate from '../../templates/TwoColumnPageTemplate'
import Header from '../../organisms/Header'
import FormPageHeading from '../../molecules/FormPageHeading'
import AccountLink from '../../molecules/AccountLink'
import CreditCounter from '../../molecules/CreditCounter'
import BoostCreditsOverlay from '../../organisms/BoostCreditsOverlay'
import renderAdvertiseCreditsOverlay from './overlays/advertiseCreditsOverlay/advertiseCreditsOverlay'
import Heading from '../../atoms/Heading'

import PublicProfileIcon from '../../../svgs/icons/public-profile.svg'
import PersonalInfoIcon from '../../../svgs/icons/personal-info.svg'
import LoginSecurityIcon from '../../../svgs/icons/login-security.svg'
import NotificationsIcon from '../../../svgs/icons/notifications.svg'
import PaymentIcon from '../../../svgs/icons/payment.svg'

import account from './account.module.scss'

export const Account = ({
  boostCredits,
  accountHasAdvertised,
  accountHasAdvertiseCredits,
  advertiseCredits,
  isLettingAgent,
}) => {
  const [boostOverlay, setBoostOverlay] = useState({ active: false })
  const [advertiseCreditsOverlay, setAdvertiseCreditsOverlay] = useState({
    active: false,
    advertiseCreditsData: advertiseCredits,
  })

  const isMobile = useMedia(['(max-width: 660px)'], [true], false)

  const handleBoostOverlayClose = () => {
    setBoostOverlay({
      active: false,
    })
  }

  const showBoostOverlay = () => {
    setBoostOverlay({
      active: true,
    })
  }

  const handleAdvertiseCreditsOverlayClose = () => {
    setAdvertiseCreditsOverlay(prevState => ({
      ...prevState,
      active: false,
    }))
  }

  const showAdvertiseCreditsOverlay = () => {
    setAdvertiseCreditsOverlay(prevState => ({
      ...prevState,
      active: true,
    }))
  }

  const renderLandlordAccountLinks = () => (
    <>
      <AccountLink
        className={account.link}
        icon={PublicProfileIcon}
        to="/landlord-admin/account/public-profile"
        data-testid="public-profile-link"
      >
        <span className={account.linkTitle}>Public profile</span>
      </AccountLink>

      <AccountLink
        className={account.link}
        icon={PersonalInfoIcon}
        to="/landlord-admin/account/personal-info"
        data-testid="personal-info-link"
      >
        <span className={account.linkTitle}>Personal info</span>
      </AccountLink>

      <AccountLink
        className={account.link}
        icon={LoginSecurityIcon}
        to="/landlord-admin/account/login-and-security"
        data-testid="login-and-security-link"
      >
        <span className={account.linkTitle}>Login & security</span>
      </AccountLink>

      {config.showAccountSettingsNotifications && (
        <AccountLink
          className={account.link}
          icon={NotificationsIcon}
          to="/landlord-admin/account/notifications"
          data-testid="notifications-link"
        >
          <span className={account.linkTitle}>Notifications</span>
        </AccountLink>
      )}

      <AccountLink
        className={account.link}
        icon={PaymentIcon}
        to="/landlord-admin/account/payments"
        data-testid="payment-link"
      >
        <span className={account.linkTitle}>Payments</span>
      </AccountLink>
    </>
  )

  const renderLettingAgentAccountLinks = () => (
    <>
      <AccountLink
        className={account.link}
        icon={PublicProfileIcon}
        to="/landlord-admin/account/letting-agency-details"
        data-testid="letting-agency-details-link"
      >
        <span className={account.linkTitle}>Letting agency details</span>
      </AccountLink>

      <AccountLink
        className={account.link}
        icon={PersonalInfoIcon}
        to="/landlord-admin/account/main-person-of-contact"
        data-testid="main-person-of-contact-link"
      >
        <span className={account.linkTitle}>Main person of contact</span>
      </AccountLink>

      <AccountLink
        className={account.link}
        icon={LoginSecurityIcon}
        to="/landlord-admin/account/login-and-security"
        data-testid="login-and-security-link"
      >
        <span className={account.linkTitle}>Login & security</span>
      </AccountLink>

      {config.showAccountSettingsNotifications && (
        <AccountLink
          className={account.link}
          icon={NotificationsIcon}
          to="/landlord-admin/account/notifications"
          data-testid="notifications-link"
        >
          <span className={account.linkTitle}>Notifications</span>
        </AccountLink>
      )}

      <AccountLink
        className={account.link}
        icon={PaymentIcon}
        to="/landlord-admin/account/payments"
        data-testid="payment-link"
      >
        <span className={account.linkTitle}>Payments</span>
      </AccountLink>
    </>
  )

  return (
    <>
      <FormPageHeading className={account.heading} heading="Account" />
      {accountHasAdvertised && accountHasAdvertiseCredits && (
        <Heading className={account.subHeading} level={5} data-testid="credits-subheading">
          Credits
        </Heading>
      )}

      {accountHasAdvertised && (
        <button
          className={account.creditButton}
          onClick={showBoostOverlay}
          data-testid="account-boost-button"
        >
          <CreditCounter creditType="boost" credits={boostCredits} compact={isMobile} isHoverable />
        </button>
      )}

      {accountHasAdvertiseCredits && (
        <button
          className={accountHasAdvertised ? account.advertiseCreditButton : account.creditButton}
          onClick={showAdvertiseCreditsOverlay}
          data-testid="advertise-credits-button"
        >
          <CreditCounter
            creditType={advertiseCredits.unlimited ? 'unlimitedAdvertise' : 'advertise'}
            credits={advertiseCredits.amount}
            subheading={advertiseCredits.advertisingPeriod}
            compact={isMobile}
            isHoverable
          />
        </button>
      )}

      <div className={account.settingsLinks}>
        <Heading className={account.subHeading} level={5}>
          Settings
        </Heading>
        {isLettingAgent ? renderLettingAgentAccountLinks() : renderLandlordAccountLinks()}

        {accountHasAdvertised && (
          <BoostCreditsOverlay
            isActive={boostOverlay.active}
            credits={boostCredits}
            handleOverlayClose={handleBoostOverlayClose}
            useBoostCreditsLink="/landlord-admin/boost-properties"
          />
        )}

        {advertiseCredits &&
          renderAdvertiseCreditsOverlay(
            advertiseCreditsOverlay,
            handleAdvertiseCreditsOverlayClose
          )}
      </div>
    </>
  )
}

Account.propTypes = {
  boostCredits: PropTypes.number,
  accountHasAdvertised: PropTypes.bool,
  accountHasAdvertiseCredits: PropTypes.bool,
  advertiseCredits: PropTypes.object,
  isLettingAgent: PropTypes.bool.isRequired,
}

const AccountPage = ({
  boostCredits,
  accountHasAdvertised,
  accountHasAdvertiseCredits,
  advertiseCredits,
  isLettingAgent,
}) => {
  return (
    <TwoColumnPageTemplate
      header={<Header />}
      title="Account | AFS"
      leftColumn={
        <Account
          boostCredits={boostCredits}
          accountHasAdvertised={accountHasAdvertised}
          accountHasAdvertiseCredits={accountHasAdvertiseCredits}
          advertiseCredits={advertiseCredits}
          isLettingAgent={isLettingAgent}
        />
      }
    />
  )
}

AccountPage.propTypes = {
  boostCredits: PropTypes.number,
  accountHasAdvertised: PropTypes.bool,
  accountHasAdvertiseCredits: PropTypes.bool,
  advertiseCredits: PropTypes.object,
  isLettingAgent: PropTypes.bool.isRequired,
}

export default AccountPage
