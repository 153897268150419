import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.scss'

import PropertyTitleAndAddress from '../PropertyTitleAndAddress'
import AdStatusTag from '../../atoms/AdStatusTag'

const PublishPropertyCard = ({ className, property, ...props }) => {
  const componentClasses = classNames(className, 'publish-property-card')

  const { bedrooms, accommodationType, address } = property

  return (
    <div className={componentClasses} {...props}>
      <div className="publish-property-card__details">
        <PropertyTitleAndAddress
          className="publish-property-card"
          numberOfBedrooms={bedrooms.length}
          accommodationType={accommodationType}
          address={address}
        />
        <AdStatusTag className="publish-property-card__details__ad-status" />
      </div>
    </div>
  )
}

PublishPropertyCard.propTypes = {
  className: PropTypes.string,
  property: PropTypes.object.isRequired,
}

export default PublishPropertyCard
