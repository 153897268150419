import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useAuthContext } from '../../authContext'
import { getPropertiesForPaymentSuccessPage } from '../../api/propertyApi'
import { getNewPropertyDiscountEligibility } from '../../api/paymentApi'
import { useStateContext } from '../../appStateContext'

import LoadingPage from '../pages/LoadingPage'
import PaymentSuccessPage from '../pages/PaymentSuccessPage'

export const PaymentSuccessPageContainer = ({
  paymentSuccessPage,
  getProperties,
  getDiscountEligibility,
  history,
  useAuthContext,
  useStateContext,
}) => {
  const [containerProperties, setContainerProperties] = useState([])
  const [newPropertyDiscount, setNewPropertyDiscount] = useState({ eligible: false, expires: '' })
  const [loading, setLoading] = useState(true)

  const { token } = useAuthContext()
  const { orderHistory, propertyIds } = useStateContext()

  const landlordHasSelectedProperties = !propertyIds.noneSelected()

  useEffect(() => {
    if (!orderHistory.hasOrders()) {
      history.push('/landlord-admin')
      return
    }

    const propertyIds = orderHistory.propertyIdsFromAdvertisementsInLastOrder()

    getProperties(propertyIds, token).then(propertiesResponse => {
      if (propertiesResponse.success) {
        setContainerProperties(propertiesResponse.properties)
      }

      getDiscountEligibility(token).then(discountResponse => {
        if (discountResponse.success) {
          setNewPropertyDiscount(discountResponse.discount)
        }

        setLoading(false)
      })
    })
  }, [])

  if (loading) return <LoadingPage />

  return paymentSuccessPage(
    containerProperties,
    orderHistory.lastOrder(),
    landlordHasSelectedProperties,
    newPropertyDiscount
  )
}

PaymentSuccessPageContainer.propTypes = {
  paymentSuccessPage: PropTypes.func.isRequired,
  getProperties: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
}

export const PaymentSuccessPageContainerCompositionRoot = ({ history }) => {
  return (
    <PaymentSuccessPageContainer
      paymentSuccessPage={(
        properties,
        lastOrder,
        landlordHasSelectedProperties,
        newPropertyDiscount,
        isFeedAccount
      ) => (
        <PaymentSuccessPage
          properties={properties}
          lastOrder={lastOrder}
          newPropertyDiscount={newPropertyDiscount}
          landlordHasSelectedProperties={landlordHasSelectedProperties}
          isFeedAccount={isFeedAccount}
        />
      )}
      getProperties={getPropertiesForPaymentSuccessPage}
      getDiscountEligibility={getNewPropertyDiscountEligibility}
      history={history}
      useAuthContext={useAuthContext}
      useStateContext={useStateContext}
    />
  )
}

PaymentSuccessPageContainerCompositionRoot.propTypes = {
  history: PropTypes.object,
}
