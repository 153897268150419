import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import InfoTooltip from '@afs/components/InfoTooltip'

import BillsOptionalIcon from './svgs/bills-optional.svg'
import InfoIcon from './svgs/info.svg'

import styles from './styles.module.scss'

const BillsOptionalBreakdown = ({ className, rentFrom, quote }) => {
  const availableServices = quote.services.filter(service => service.available)

  const unavailableServices = quote.services.filter(service => !service.available)

  return (
    <article className={classNames(styles.wrapper, className)}>
      <div className={styles.rows}>
        <span className={styles.ppwHeading}>per person/week</span>
        <div className={classNames(styles.row, styles.rowRentFrom)}>
          <span className={styles.rentFromLabel}>Rent from</span>
          <span className={styles.rentFromAmount} data-testid="rent-from">
            {rentFrom}
          </span>
        </div>

        <div className={classNames(styles.row, styles.billsTotalRow)}>
          <span className={styles.billsTotalLabel}>
            <BillsOptionalIcon className={styles.billsOptionalIcon} /> Bills optional quote
          </span>
          <span className={styles.billsTotalAmount} data-testid="bills-total-price">
            {quote.totalPricePppw}
          </span>
        </div>

        {availableServices.map(service => (
          <div key={service.name} className={classNames(styles.row, styles.utilityRow)}>
            <span className={styles.utilityLabel}>
              {service.name}{' '}
              <InfoTooltip
                icon={<InfoIcon className={styles.utilityInfoIcon} />}
                tooltipContent={service.description}
                wide
              />
            </span>
            <span data-testid="utility-price">{service.pricePppw}</span>
          </div>
        ))}

        {unavailableServices.map(service => (
          <div key={service.name} className={classNames(styles.row, styles.utilityRow)}>
            <div>
              <span
                className={styles.utilityLabelUnavailable}
                data-testid="unavailable-utility-name"
              >
                {service.name}
              </span>
              <p
                className={styles.utilityDescriptionUnavailable}
                data-testid="unavailable-utility-description"
              >
                {service.description}
              </p>
            </div>
          </div>
        ))}
      </div>

      {quote.lastUpdated ? (
        <span className={styles.lastUpdatedPill}>
          Quote last updated: <strong>{quote.lastUpdated}</strong>
        </span>
      ) : null}
    </article>
  )
}

BillsOptionalBreakdown.propTypes = {
  className: PropTypes.string,
  rentFrom: PropTypes.string.isRequired,
  quote: PropTypes.shape({
    totalPricePppw: PropTypes.string.isRequired,
    lastUpdated: PropTypes.string,
    services: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        pricePppw: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        available: PropTypes.bool.isRequired,
      })
    ),
  }).isRequired,
}

export default BillsOptionalBreakdown
