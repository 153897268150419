import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Field from '@afs/components/Field'

import AccountSettingsForm from '../../../molecules/AccountSettingsForm'

import {
  containsEmail,
  containsPhone,
  containsUrl,
  exceedsMaxLength,
  includesHTML,
  invalidCompanyName,
} from '../../../../utils/validation'

const CompanyNameForm = ({ account, onSave, loading, ...props }) => {
  const [field, setField] = useState({
    value: account.companyNameString(),
    touched: false,
    error: '',
  })

  const validateCompanyName = string => {
    if (containsEmail(string)) {
      return 'Email addresses are not allowed in the company name.'
    }

    if (containsUrl(string)) {
      return 'URLs are not allowed in the company name.'
    }

    if (containsPhone(string)) {
      return 'Phone numbers are not allowed in the company name.'
    }

    if (includesHTML(string)) {
      return 'HTML tags are not allowed in the company name.'
    }

    if (invalidCompanyName(string)) {
      return 'Company name can only contain letters, numbers, punctuation, and only certain special characters (& @ £ $ € ¥ #).'
    }

    if (exceedsMaxLength(string, 160)) {
      return 'Company name exceeds maximum length of 160 characters.'
    }

    return ''
  }

  const handleChange = event => {
    const inputValue = event.target.value
    const error = validateCompanyName(inputValue)

    setField(prevState => ({
      ...prevState,
      value: inputValue,
      touched: true,
      error,
    }))
  }

  const handleSave = event => {
    event.preventDefault()

    const fieldName = 'companyName'
    const payload = {
      [fieldName]: field.value,
    }

    onSave(fieldName, payload)
  }

  const formIsInvalid = field.touched && field.error !== ''

  return (
    <AccountSettingsForm onSubmit={handleSave} invalid={formIsInvalid} loading={loading} {...props}>
      <Field
        name="companyName"
        aria-label="Company name"
        value={field.value}
        onChange={handleChange}
        onBlur={handleChange}
        invalid={formIsInvalid}
        error={field.error}
        data-testid="input-field"
      />
    </AccountSettingsForm>
  )
}

CompanyNameForm.propTypes = {
  account: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default CompanyNameForm
