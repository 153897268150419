import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import './styles.scss'

import Heading from '../../atoms/Heading'
import Link from '../../atoms/Link'

const VerificationLinkExpired = ({ className }) => {
  const componentClass = classNames(className, 'verification-expired')

  return (
    <div className={componentClass}>
      <Heading level={1} className="verification-expired__heading">
        Expired Link
      </Heading>
      <p>{"The link you've used has expired. We have just sent you a new one."}</p>
      <p>
        <strong>Please check your inbox</strong> for a new email with instructions on how to verify
        your email address.
      </p>
      <Link to="/landlord-admin/login">Back to login.</Link>
    </div>
  )
}

VerificationLinkExpired.propTypes = {
  className: PropTypes.string,
}

export default VerificationLinkExpired
