import React from 'react'
import SpinnerButton from '@afs/components/SpinnerButton'

import { updatePropertyAmenities } from '../../../../api/propertyApi'

import AmenitiesPage from '../../FormPages/AmenitiesPage'
import SubmitButton from '../../../atoms/SubmitButton'

const alwaysValidateToTrue = () => {
  return {}
}

const amenitiesPageConfig = propertyId => ({
  id: 7,
  component: () => <AmenitiesPage />,
  route: `/landlord-admin/property/${propertyId}/edit/amenities`,
  validate: alwaysValidateToTrue,
  isValid: false,
  heading: 'Amenities',
  subheading: 'Select the amenities that are available in this property.',
  button: (values, loading) => {
    if (loading)
      return (
        <SpinnerButton primary inactive>
          Continue
        </SpinnerButton>
      )
    return <SubmitButton data-testid="save-button">Save changes</SubmitButton>
  },
  onSubmit: async (values, token) => {
    const payload = {
      propertyId,
      amenities: values.amenities,
    }
    return updatePropertyAmenities(payload, token)
  },
})

export default amenitiesPageConfig
