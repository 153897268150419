import React from 'react'
import PropTypes from 'prop-types'

import ManagePropertiesPageTemplate from '../../templates/ManagePropertiesPageTemplate'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import ManagePropertiesHero from '../../atoms/ManagePropertiesHero'
import Heading from '../../atoms/Heading'
import Button from '../../atoms/Button'
import NotFoundAnnouncement from '../../molecules/NotFoundAnnouncement'
import InvoicesList from '../../organisms/InvoicesList'

import NoEnquiries from '../../../svgs/illustrations/no-enquiries.svg'

import hero from '../PropertiesPage/modules/hero.module.scss'
import layout from '../PropertiesPage/modules/layout.module.scss'

const InvoicesPage = ({ invoices }) => {
  const renderHero = () => {
    return (
      <ManagePropertiesHero hasPatternBackgroundOnMobile pattern="green">
        <div className={hero.row}>
          <Heading className={hero.heading}>Invoices</Heading>
        </div>
      </ManagePropertiesHero>
    )
  }

  const landlordHasInvoices = invoices && invoices.length > 0

  return (
    <ManagePropertiesPageTemplate
      title="Invoices | AFS"
      header={<Header />}
      footer={<Footer dark />}
      hero={renderHero()}
    >
      <div className={layout.inner}>
        {landlordHasInvoices ? (
          <InvoicesList invoices={invoices} />
        ) : (
          <NotFoundAnnouncement
            icon={NoEnquiries}
            heading="You don't have any invoices just yet"
            subHeading="Once you've made a purchase, your invoice will show up here."
            button={
              <Button to="/landlord-admin/properties" primary data-testid="no-invoices-button">
                Manage properties
              </Button>
            }
            data-testid="no-invoices"
          />
        )}
      </div>
    </ManagePropertiesPageTemplate>
  )
}

InvoicesPage.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default InvoicesPage
