/* eslint-disable no-underscore-dangle */

class BillsOptional {
  _properties
  _propertiesToConfirm

  constructor() {
    this._properties = []
    this._propertiesToConfirm = []
  }

  selectProperties(properties) {
    this._properties = properties
  }

  properties() {
    return this._properties
  }

  clearProperties() {
    this._properties = []
  }

  selectPropertiesToConfirm(properties) {
    this._propertiesToConfirm = properties
  }

  propertiesToConfirm() {
    return this._propertiesToConfirm
  }

  hasPropertiesToConfirm() {
    return this._propertiesToConfirm.length > 0
  }

  clearPropertiesToConfirm() {
    this._propertiesToConfirm = []
  }

  viewModelsOfPropertiesToConfirm() {
    return this._properties.filter(property =>
      this._propertiesToConfirm.includes(property.propertyId)
    )
  }
}

export default BillsOptional
