import React from 'react'
import Notification from '../../molecules/Notification'

import boostOverlay from './modules/overlay.module.scss'
import layout from './modules/layout.module.scss'

export const renderErrorNotification = () => {
  return (
    <Notification
      className={boostOverlay.errorNotification}
      heading="Oops!"
      text="We weren’t able to boost your property. Please try again."
      type="error"
    />
  )
}

export const renderSuccessNotification = () => {
  return (
    <Notification
      className={layout.successNotification}
      heading="Success!"
      text="This listing has been boosted to the top of the results page."
      type="success"
    />
  )
}
