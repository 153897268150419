import React, { useState } from 'react'
import PropTypes from 'prop-types'

import useMedia from '../../../hooks/useMedia'

import CreditCounter from '../../molecules/CreditCounter'
import TwoColumnPageTemplate from '../../templates/TwoColumnPageTemplate'
import Header from '../../organisms/Header'
import FormPageHeading from '../../molecules/FormPageHeading'
import AccountLink from '../../molecules/AccountLink'
import BoostCreditsOverlay from '../../organisms/BoostCreditsOverlay'
import Heading from '../../atoms/Heading'

import LoginSecurityIcon from '../../../svgs/icons/login-security.svg'
import PaymentIcon from '../../../svgs/icons/payment.svg'

import styles from './accountPageFeeds.module.scss'

const AccountPageFeeds = ({ boostCredits }) => {
  const [boostOverlay, setBoostOverlay] = useState({ active: false })

  const isMobile = useMedia(['(max-width: 660px)'], [true], false)

  const handleBoostOverlayClose = () => {
    setBoostOverlay({
      active: false,
    })
  }

  const showBoostOverlay = () => {
    setBoostOverlay({
      active: true,
    })
  }

  return (
    <TwoColumnPageTemplate
      header={<Header />}
      title="Account | AFS"
      leftColumn={
        <>
          <FormPageHeading className={styles.heading} heading="Account" />
          <button
            className={styles.creditButton}
            onClick={showBoostOverlay}
            data-testid="account-boost-button"
          >
            <CreditCounter
              creditType="boost"
              credits={boostCredits}
              compact={isMobile}
              isHoverable
            />
          </button>

          <div className={styles.settingsLinks}>
            <Heading className={styles.subHeading} level={5}>
              Settings
            </Heading>

            <AccountLink
              className={styles.link}
              icon={LoginSecurityIcon}
              to="/landlord-admin/account/login-and-security"
              data-testid="login-and-security-link"
            >
              <span className={styles.linkTitle}>Login & security</span>
            </AccountLink>
            <AccountLink
              className={styles.link}
              icon={PaymentIcon}
              to="/landlord-admin/account/payments"
              data-testid="payment-link"
            >
              <span className={styles.linkTitle}>Payments</span>
            </AccountLink>

            <BoostCreditsOverlay
              isActive={boostOverlay.active}
              credits={boostCredits}
              handleOverlayClose={handleBoostOverlayClose}
              useBoostCreditsLink="/landlord-admin/feeds/properties"
            />
          </div>
        </>
      }
    />
  )
}

AccountPageFeeds.propTypes = {
  boostCredits: PropTypes.number.isRequired,
}

export default AccountPageFeeds
