import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import hero from './hero.module.scss'

const ManagePropertiesHero = ({
  className,
  children,
  banner,
  hasPatternBackgroundOnMobile,
  paddingType = 'regular',
  pattern = 'yellow',
  ...props
}) => {
  const heroClasses = classNames(hero.hero, className)
  const wrapperClasses = classNames(hero.wrapper, {
    [hero.withPatternOnMobile]: hasPatternBackgroundOnMobile,
    [hero.withYellowPattern]: pattern === 'yellow',
    [hero.withGreenPattern]: pattern === 'green',
  })
  const innerClasses = classNames(hero.inner, {
    [hero.withPaddingForBacklink]: paddingType === 'forBacklink',
    [hero.withRegularPadding]: paddingType === 'regular',
    [hero.withLargePadding]: paddingType === 'large',
    [hero.withSmallPadding]: paddingType === 'small',
  })

  return (
    <section className={heroClasses} {...props} data-testid="hero">
      <div className={wrapperClasses}>
        <div className={innerClasses}>{children}</div>
      </div>
      {banner}
    </section>
  )
}

ManagePropertiesHero.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  banner: PropTypes.object,
  hasPatternBackgroundOnMobile: PropTypes.bool,
  paddingType: PropTypes.oneOf(['regular', 'forBacklink', 'large', 'small']),
  pattern: PropTypes.oneOf(['yellow', 'green', 'none']),
}

export default ManagePropertiesHero
