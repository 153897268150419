/* eslint-disable no-underscore-dangle */
class OrderHistory {
  _orders
  _products

  constructor(products) {
    this._products = products
    this._orders = []
  }

  addOrder(order) {
    this._orders.push(order)
  }

  hasOrders() {
    return this._orders.length > 0
  }

  lastOrder() {
    return this._orders[this._orders.length - 1]
  }

  advertisementsFromLastOrder() {
    return this.lastOrder().advertisements()
  }

  propertyIdsFromAdvertisementsInLastOrder() {
    return this.lastOrder()
      .advertisements()
      .map(advertisement => advertisement.propertyId)
  }

  boostCreditsFromLastOrder() {
    return this.lastOrder().boostCredits()
  }

  toLetBoardsFromLastOrder() {
    return this.lastOrder().toLetBoards()
  }
}

export default OrderHistory
