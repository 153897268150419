import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SpinnerButton from '@afs/components/SpinnerButton'

import Overlay from '../../molecules/Overlay'
import Button from '../../atoms/Button'
import Notification from '../../molecules/Notification'

import confirmationOverlay from './confirmationOverlay.module.scss'

const ConfirmationOverlay = ({
  className,
  renderOverlayContent,
  renderButton,
  onConfirm,
  overlayHeading,
  buttonText,
  notificationText,
  isAsync,
  removeNotifications,
  preserveScrollPositionOnClose,
}) => {
  const [overlay, setOverlay] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showNotification, setShowNotification] = useState(false)

  const handleOverlayOpen = () => {
    if (removeNotifications) {
      removeNotifications()
      setOverlay(true)
    }
    setOverlay(true)
  }

  const handleOverlayClose = () => {
    setOverlay(false)
    setLoading(false)
  }

  const handleConfirm = async event => {
    event.preventDefault()
    if (isAsync) {
      setLoading(true)
      const response = await onConfirm()
      if (response.success) {
        handleOverlayClose()
        setLoading(false)
      } else {
        setShowNotification(true)
        setLoading(false)
      }
    } else {
      onConfirm()
      handleOverlayClose()
    }
  }

  const renderOverlay = () => {
    return (
      <Overlay
        className={className}
        id="delete-property"
        heading={overlayHeading}
        closeLabel="Cancel"
        isActive={overlay}
        onClose={handleOverlayClose}
        preserveScrollPositionOnClose={preserveScrollPositionOnClose}
      >
        {renderOverlayContent()}
        {showNotification && (
          <Notification
            className={confirmationOverlay.notification}
            type="error"
            heading="Oops!"
            text={notificationText}
          />
        )}
        <SpinnerButton
          className={confirmationOverlay.confirmButton}
          onClick={handleConfirm}
          inactive={loading}
          deletion
          data-testid="confirm-action-button"
        >
          {buttonText}
        </SpinnerButton>
        <Button className={confirmationOverlay.cancelButton} onClick={handleOverlayClose}>
          Cancel
        </Button>
      </Overlay>
    )
  }

  return (
    <>
      {renderOverlay()}
      {renderButton(handleOverlayOpen)}
    </>
  )
}

ConfirmationOverlay.propTypes = {
  className: PropTypes.string,
  renderOverlayContent: PropTypes.func.isRequired,
  renderButton: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  overlayHeading: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  notificationText: PropTypes.string,
  isAsync: PropTypes.bool,
  removeNotifications: PropTypes.func,
  preserveScrollPositionOnClose: PropTypes.bool,
}

export default ConfirmationOverlay
