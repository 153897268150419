import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router'
import PropTypes from 'prop-types'

import { useStateContext } from '../../appStateContext'
import { useAuthContext } from '../../authContext'
import {
  changeAvailabilityForMultipleProperties,
  getSelectedProperties,
} from '../../api/propertyApi'
import { notificationService } from '../../container'
import formatDateForApi from '../../utils/formatDateForApi'

import MultiplePropertiesAvailabilityPage from '../pages/MultiplePropertiesAvailabilityPage'
import LoadingPage from '../pages/LoadingPage'

const MultiplePropertiesAvailabilityContainer = ({
  availabilityPage,
  useAuthContext,
  useStateContext,
  getProperties,
  changeAvailability,
  history,
  redirect,
}) => {
  const [serverError, setServerError] = useState(false)
  const [containerProperties, setContainerProperties] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingForm, setLoadingForm] = useState(false)

  const { token } = useAuthContext()
  const { propertyIds } = useStateContext()

  useEffect(() => {
    setLoading(true)

    getProperties(propertyIds.selected(), token).then(response => {
      if (response.success) {
        setContainerProperties(response.properties)
        setLoading(false)
      } else {
        setServerError(true)
      }
    })
  }, [])

  const deselectProperty = deselectedPropertyId => {
    propertyIds.deselect(deselectedPropertyId)

    const selectedProperties = containerProperties.filter(x => x.id !== deselectedPropertyId)
    setContainerProperties(selectedProperties)
  }

  const changeAvailabilityDates = (availableFromDate, availableUntilDate) => {
    const selectedPropertyIds = propertyIds.selected()

    const formattedAvailableFromDate = formatDateForApi(availableFromDate)
    const formattedAvailableUntilDate = availableUntilDate
      ? formatDateForApi(availableUntilDate)
      : ''

    setLoadingForm(true)

    changeAvailability(
      selectedPropertyIds,
      formattedAvailableFromDate,
      formattedAvailableUntilDate,
      token
    ).then(response => {
      const moreThanOneSelected = selectedPropertyIds.length > 1
      const propertyText = moreThanOneSelected ? 'properties' : 'property'

      if (response.success) {
        notificationService.addDeferredSuccessNotification(
          'Success!',
          `Availability dates for the ${propertyText} you selected have been updated.`
        )
        propertyIds.deselectAll()
        setLoadingForm(false)
        history.push('/landlord-admin/properties')
      } else {
        setLoadingForm(false)
        notificationService.showErrorNotification(
          'Error.',
          `Availability dates for the ${propertyText} you selected have not been updated. Please try again later.`
        )
      }
    })
  }

  if (serverError) return redirect('/landlord-admin/error')
  else if (propertyIds.noneSelected()) return redirect('/landlord-admin/properties')
  else if (loading) return <LoadingPage />
  return availabilityPage(
    history,
    loadingForm,
    containerProperties,
    changeAvailabilityDates,
    deselectProperty
  )
}

MultiplePropertiesAvailabilityContainer.propTypes = {
  availabilityPage: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
  useStateContext: PropTypes.func.isRequired,
  getProperties: PropTypes.func.isRequired,
  changeAvailability: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
}

export const MultiplePropertiesAvailabilityContainerCompositionRoot = ({ history }) => {
  return (
    <MultiplePropertiesAvailabilityContainer
      availabilityPage={(history, loading, selectedProperties, onSave, deselectProperty) => (
        <MultiplePropertiesAvailabilityPage
          history={history}
          loading={loading}
          selectedProperties={selectedProperties}
          onSave={onSave}
          deselectProperty={deselectProperty}
        />
      )}
      useAuthContext={useAuthContext}
      useStateContext={useStateContext}
      getProperties={getSelectedProperties}
      changeAvailability={changeAvailabilityForMultipleProperties}
      history={history}
      redirect={path => <Redirect to={path} />}
    />
  )
}

MultiplePropertiesAvailabilityContainerCompositionRoot.propTypes = {
  history: PropTypes.object,
}

export default MultiplePropertiesAvailabilityContainer
