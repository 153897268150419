import React from 'react'
import PropTypes from 'prop-types'

import { createAccount } from '../../api/accountApi'

import { notificationService } from '../../container'
import scrollToNotification from '../../utils/scrollToNotification'

import LandlordRegisterPage from '../pages/LandlordRegisterPage'
import LettingAgentRegisterPage from '../pages/LettingAgentRegisterPage'

export const AccountRegisterContainer = ({
  accountRegisterPage,
  createAccount,
  history,
  notificationService,
}) => {
  const handleCreateLandlordAccount = async formData => {
    const response = await createAccount(formData)

    if (response.success) {
      history.push('/thank-you')
      return response
    }
    if (!response.success && response.data) {
      return response.data.response.data.code
    } else {
      notificationService.showErrorNotification(
        'Oops!',
        'Something went wrong and we could not create your account. Please try again later.'
      )
      scrollToNotification()
      return response
    }
  }

  return accountRegisterPage(handleCreateLandlordAccount)
}

AccountRegisterContainer.propTypes = {
  accountRegisterPage: PropTypes.func.isRequired,
  createAccount: PropTypes.func.isRequired,
  notificationService: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export const LandlordRegisterContainerCompositionRoot = ({ history }) => {
  return (
    <AccountRegisterContainer
      accountRegisterPage={createAccount => <LandlordRegisterPage createAccount={createAccount} />}
      createAccount={createAccount}
      notificationService={notificationService}
      history={history}
    />
  )
}

LandlordRegisterContainerCompositionRoot.propTypes = {
  history: PropTypes.object.isRequired,
}

export const LettingAgentRegisterContainerCompositionRoot = ({ history }) => {
  return (
    <AccountRegisterContainer
      accountRegisterPage={createAccount => (
        <LettingAgentRegisterPage createAccount={createAccount} />
      )}
      createAccount={createAccount}
      notificationService={notificationService}
      history={history}
    />
  )
}

LettingAgentRegisterContainerCompositionRoot.propTypes = {
  history: PropTypes.object.isRequired,
}
