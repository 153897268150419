import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import LinkButton from '../../atoms/LinkButton'
import expandableField from './expandableField.module.scss'

const ExpandableField = ({
  className,
  name,
  heading,
  renderForm,
  renderValue,
  onOpen,
  onClose,
  expanded,
  disabled,
  loading,
  ...props
}) => {
  const handleToggleExpand = name => {
    const action = expanded ? onClose : onOpen
    action(name)
  }

  return (
    <div
      className={classNames(expandableField.wrapper, className)}
      data-testid={`expandable-field-${name}`}
      {...props}
    >
      <div className={expandableField.header}>
        <strong className={expandableField.label}>{heading}</strong>
        <LinkButton
          className={expandableField.changeButton}
          onClick={() => handleToggleExpand(name)}
          disabled={disabled}
          data-testid="change-button"
          aria-expanded={expanded}
        >
          {expanded ? 'Cancel' : 'Change'}
        </LinkButton>
      </div>
      {expanded ? (
        <>{renderForm({ className: expandableField.form, loading })}</>
      ) : (
        <div className={expandableField.content} data-testid="expandable-field-content">
          {renderValue()}
        </div>
      )}
    </div>
  )
}

ExpandableField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  renderForm: PropTypes.func.isRequired,
  renderValue: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  expanded: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
}

export default ExpandableField
