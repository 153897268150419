import React from 'react'
import PropTypes from 'prop-types'

import TwoColumnPageTemplate from '../../templates/TwoColumnPageTemplate'
import Header from '../../organisms/Header'
import PaymentContainer from '../../containers/PaymentContainer'
import MobileContextMenu from '../../organisms/MobileContextMenu'
import OrderSummary from '../../molecules/OrderSummary'
import FormPageHeading from '../../molecules/FormPageHeading'

import checkoutPage from './checkoutPage.module.scss'

const CheckoutPage = ({
  history,
  lineItems,
  subtotalWithoutVat,
  vatAmount,
  totalWithVat,
  discount,
  paymentSecret,
  paymentIntentId,
  savedCards,
}) => {
  const renderCheckoutContent = () => (
    <div className={checkoutPage.container}>
      <FormPageHeading heading="Payment" />
      <PaymentContainer
        history={history}
        discount={discount}
        paymentSecret={paymentSecret}
        paymentIntentId={paymentIntentId}
        savedCards={savedCards}
      />
    </div>
  )

  const BasketSummary = ({ heading, darkMode }) => (
    <OrderSummary
      className={checkoutPage.orderSummary}
      heading={heading}
      lineItems={lineItems}
      subtotal={subtotalWithoutVat}
      vat={vatAmount}
      total={totalWithVat}
      discount={discount}
      darkMode={darkMode}
      data-testid="checkout-summary"
    />
  )

  BasketSummary.propTypes = {
    heading: PropTypes.string,
    darkMode: PropTypes.bool,
  }

  const renderForm = () => {
    return lineItems.length > 0 ? <BasketSummary heading="Order Summary" /> : <></>
  }

  return (
    <TwoColumnPageTemplate
      header={<Header />}
      title="Payments | AFS"
      className={checkoutPage.page}
      contextMenu={
        lineItems.length > 0 && (
          <MobileContextMenu
            className={checkoutPage.mobileMenu}
            overview="Order summary"
            context={`£${Number.parseFloat(totalWithVat).toFixed(2)}`}
          >
            <BasketSummary darkMode />
          </MobileContextMenu>
        )
      }
      leftColumn={renderCheckoutContent()}
      rightColumn={renderForm()}
    />
  )
}

CheckoutPage.propTypes = {
  history: PropTypes.object,
  lineItems: PropTypes.array.isRequired,
  subtotalWithoutVat: PropTypes.number.isRequired,
  vatAmount: PropTypes.number.isRequired,
  totalWithVat: PropTypes.number.isRequired,
  discount: PropTypes.object.isRequired,
  paymentSecret: PropTypes.string.isRequired,
  paymentIntentId: PropTypes.string.isRequired,
  savedCards: PropTypes.array.isRequired,
}

export default CheckoutPage
