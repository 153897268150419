const validOptions = date => {
  return [
    {
      label: 'Custom',
      text: '',
      startDate: '',
      endDate: '',
    },
    {
      label: 'Today',
      text: 'today',
      startDate: date.todaysDate(),
      endDate: date.todaysDate(),
    },
    {
      label: 'Yesterday',
      text: 'yesterday',
      startDate: date.yesterdaysDate(),
      endDate: date.yesterdaysDate(),
    },
    {
      label: 'Last 7 days',
      text: 'during the last 7 days',
      startDate: date.sevenDaysAgo(),
      endDate: date.todaysDate(),
    },
    {
      label: 'Last 30 days',
      text: 'during the last 30 days',
      startDate: date.thirtyDaysAgo(),
      endDate: date.todaysDate(),
    },
    {
      label: 'Last 90 days',
      text: 'during the last 90 days',
      startDate: date.ninetyDaysAgo(),
      endDate: date.todaysDate(),
    },
    {
      label: 'Week to date',
      text: 'this week',
      startDate: date.firstDayOfThisWeek(),
      endDate: date.todaysDate(),
    },
    {
      label: 'Month to date',
      text: 'this month',
      startDate: date.firstDayOfThisMonth(),
      endDate: date.todaysDate(),
    },
    {
      label: 'Year to date',
      text: 'this year',
      startDate: date.firstDayOfThisYear(),
      endDate: date.todaysDate(),
    },
  ]
}

const fromThirtyDaysAgoTo = date => {
  return {
    startDate: date.thirtyDaysAgo(),
    endDate: date.todaysDate(),
  }
}

export { validOptions, fromThirtyDaysAgoTo }
