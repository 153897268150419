import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Search from '../../../svgs/icons/search.svg'
import CrossCircle from '../../../svgs/icons/cross-circle.svg'

import styles from './searchField.module.scss'

const SearchField = React.forwardRef(
  (
    { className, name, value, ariaLabel, handleChange, handleClearValue, placeholder, ...props },
    ref
  ) => {
    const inputRef = useRef()

    const onClearValue = () => {
      handleClearValue()

      const input = ref || inputRef
      input.current.focus()
    }

    const searchFieldClasses = classNames(styles.searchField, className)
    const clearButtonClasses = classNames(styles.clearButton, { [styles.clearButtonActive]: value })

    return (
      <div className={searchFieldClasses}>
        <Search className={styles.searchIcon} />
        <input
          ref={ref || inputRef}
          name={name}
          value={value}
          onChange={e => handleChange(e.target.value)}
          placeholder={placeholder}
          type="text"
          aria-label={ariaLabel}
          {...props}
        />
        <button
          className={clearButtonClasses}
          disabled={!value}
          type="button"
          aria-label="Clear search"
          onClick={onClearValue}
          data-testid="clear-search-button"
        >
          <CrossCircle className={styles.clearIcon} />
        </button>
      </div>
    )
  }
)

SearchField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleClearValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

export default SearchField
