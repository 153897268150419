import { useRef, useState, useLayoutEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

const useMeasure = () => {
  const ref = useRef()
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 })
  const [resizeObserver] = useState(() => new ResizeObserver(([entry]) => set(entry.contentRect)))
  useLayoutEffect(() => {
    resizeObserver.observe(ref.current)

    return () => resizeObserver.disconnect()
  }, [])

  return [{ ref }, bounds]
}

export default useMeasure
