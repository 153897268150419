import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Heading from '../../atoms/Heading'

import './styles.scss'

const FormPageHeading = ({
  className,
  heading,
  headingComponent,
  subheading,
  optional,
  subheadingComponent,
  ...props
}) => {
  const noSubHeading = typeof subheading === 'undefined' || subheading === ''

  const headingClasses = classNames(
    'form-page-heading__heading',
    {
      [`${className}__heading`]: className,
    },
    {
      'form-page-heading__heading--no-sub-heading': noSubHeading,
    },
    { [`${className}__heading--no-sub-heading`]: className && noSubHeading }
  )

  const subHeadingClasses = classNames('form-page-heading__sub-heading', {
    [`${className}__sub-heading`]: className,
  })

  return (
    <header className={className} {...props}>
      {heading && (
        <Heading className={headingClasses} level={1}>
          {heading}
        </Heading>
      )}
      {headingComponent && (
        <Heading className={headingClasses} level={1}>
          {headingComponent}
        </Heading>
      )}
      {optional && (
        <Heading level={5} className="form-page-heading__optional">
          Optional
        </Heading>
      )}
      {subheading && <p className={subHeadingClasses}>{subheading}</p>}
      {subheadingComponent && subheadingComponent}
    </header>
  )
}

FormPageHeading.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  headingComponent: PropTypes.string,
  subheading: PropTypes.string,
  optional: PropTypes.bool,
  subheadingComponent: PropTypes.element,
}

export default FormPageHeading
