import React from 'react'
import PropTypes from 'prop-types'
import FieldImageUpload from '../molecules/FieldImageUpload'
import { storageService } from '../../container'
import useMeasure from '../../hooks/useMeasure'

import { config } from '../../config'

const FieldImageUploadAdapter = ({ input: { name, onChange, value } }) => {
  return (
    <FieldImageUpload
      name={name}
      uploadedImages={value.images}
      onSyncState={result => onChange(result)}
      storageService={storageService}
      maximumUploads={15}
      imageApiUrl={config.imgApiUrl}
      useMeasure={useMeasure}
    />
  )
}

FieldImageUploadAdapter.propTypes = {
  input: PropTypes.object,
}

export default FieldImageUploadAdapter
