import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Field from '@afs/components/Field'

import { validateAccountSettingsPassword } from '../../../../utils/validation'
import AccountSettingsForm from '../../../molecules/AccountSettingsForm'
import PasswordRequirements from '../../../atoms/PasswordRequirements'

import password from './password.module.scss'

const PasswordForm = ({ onSave, loading, ...props }) => {
  const [fields, setFields] = useState({
    currentPassword: {
      value: '',
      updated: false,
      error: '',
    },
    newPassword: {
      value: '',
      updated: false,
      error: '',
    },
    confirmNewPassword: {
      value: '',
      updated: false,
      error: '',
    },
  })

  const { currentPassword, newPassword, confirmNewPassword } = fields

  const handleChange = (event) => {
    const inputName = event.target.name
    const inputValue = event.target.value
    const error = validateAccountSettingsPassword(inputValue, inputName)

    setFields((prevState) => ({
      ...prevState,
      [inputName]: {
        value: inputValue,
        updated: false,
        error,
      },
    }))
  }

  const handleSave = (event) => {
    event.preventDefault()
    const payload = {
      currentPassword: currentPassword.value,
      newPassword: newPassword.value,
    }
    onSave(payload).then((response) => {
      if (response.code && response.code === 'NotAuthorizedException') {
        setFields((prevState) => ({
          ...prevState,
          currentPassword: {
            value: '',
            updated: true,
            error: 'Please enter your current account password.',
          },
        }))
      }
    })
  }

  const currentPasswordIsInvalid = currentPassword.updated && currentPassword.error !== ''
  const newPasswordIsInvalid = newPassword.updated && newPassword.error !== ''
  const newPasswordFieldIsEmpty = newPassword.updated && newPassword.value === ''
  const confirmNewPasswordIsInvalid =
    confirmNewPassword.updated && confirmNewPassword.value !== newPassword.value

  const formIsInvalid =
    currentPasswordIsInvalid || newPasswordIsInvalid || confirmNewPasswordIsInvalid

  return (
    <AccountSettingsForm
      onSubmit={handleSave}
      invalid={formIsInvalid}
      loading={loading}
      formName="password"
      {...props}
    >
      <Field
        className={password.passwordField}
        name="currentPassword"
        label="Current password"
        aria-label="Current password"
        value={currentPassword.value}
        type="password"
        onChange={handleChange}
        onBlur={() =>
          setFields((prevState) => ({
            ...prevState,
            currentPassword: { ...prevState.currentPassword, updated: true },
          }))
        }
        invalid={currentPasswordIsInvalid}
        error={fields.currentPassword.error}
        data-testid="input-field-current-password"
        autoComplete="off"
      />
      <Field
        className={password.passwordField}
        name="newPassword"
        label="New password"
        aria-label="New password"
        value={newPassword.value}
        type="password"
        onChange={handleChange}
        onBlur={() =>
          setFields((prevState) => ({
            ...prevState,
            newPassword: { ...prevState.newPassword, updated: true },
          }))
        }
        invalid={newPasswordIsInvalid || newPasswordFieldIsEmpty}
        data-testid="input-field-new-password"
        autoComplete="new-password"
      />
      <PasswordRequirements
        className={password.requirements}
        password={newPassword.value}
        userIsUpdating={!newPassword.updated}
      />
      <Field
        name="confirmNewPassword"
        label="Confirm new password"
        aria-label="Confirm new password"
        value={confirmNewPassword.value}
        type="password"
        onChange={handleChange}
        onBlur={() =>
          setFields((prevState) => ({
            ...prevState,
            confirmNewPassword: { ...prevState.confirmNewPassword, updated: true },
          }))
        }
        invalid={confirmNewPasswordIsInvalid}
        error={confirmNewPassword.error}
        data-testid="input-field-confirm-new-password"
        autoComplete="off"
      />
    </AccountSettingsForm>
  )
}

PasswordForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default PasswordForm
