import axios from 'axios'
import { config } from '../config'

export const performApiGet = async (url, token) => {
  const axiosInstance = axios.create({
    baseURL: config.apiBaseUrl,
    timeout: 30000,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  return axiosInstance.get(url)
}

export const performLongApiGet = async (url, token) => {
  const axiosInstance = axios.create({
    baseURL: config.apiBaseUrl,
    timeout: 60000,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  return axiosInstance.get(url)
}

export const performApiPost = async (url, data, token) => {
  const axiosInstance = axios.create({
    baseURL: config.apiBaseUrl,
    timeout: 30000,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })

  return axiosInstance.post(url, data)
}

export const performApiPatch = async (url, data, token) => {
  const axiosInstance = axios.create({
    baseURL: config.apiBaseUrl,
    timeout: 30000,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })

  return axiosInstance.patch(url, data)
}

export const performApiDelete = async (url, token) => {
  const axiosInstance = axios.create({
    baseURL: config.apiBaseUrl,
    timeout: 30000,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
  return axiosInstance.delete(url)
}
