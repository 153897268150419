import React from 'react'
import SpinnerButton from '@afs/components/SpinnerButton'
import Button from '../../../../atoms/Button'
import Overlay from '../../../../molecules/Overlay'
import { renderErrorNotification } from '../../boostNotifications'
import CreditCounter from '../../../../molecules/CreditCounter'

import boostOverlay from '../../modules/overlay.module.scss'

const renderOverlayButton = (credits, onBoostProperty, updatingBoost) => {
  return credits > 0 ? (
    <SpinnerButton
      className={boostOverlay.button}
      primary
      onClick={onBoostProperty}
      inactive={updatingBoost}
      data-testid="boost-listing-button"
    >
      Boost to the top
    </SpinnerButton>
  ) : (
    <Button
      to="/landlord-admin/buy-boost-credits"
      className={boostOverlay.button}
      primary
      data-testid="buy-credits-button"
    >
      Buy boost credits
    </Button>
  )
}

const renderBoostOverlay = (
  overlay,
  credits,
  boostCost,
  onBoostProperty,
  updatingBoost,
  handleOverlayClose,
  propertyBoostError
) => {
  const landlordHasCredits = credits > 0

  return (
    <Overlay
      id="boost-credits"
      heading="Boost listing"
      closeLabel="Close"
      isActive={overlay.active}
      onClose={handleOverlayClose}
    >
      <p className={boostOverlay.text} data-testid="boost-overlay-text">
        {landlordHasCredits
          ? 'Are you sure you want to boost this property listing and improve its visibility?'
          : 'Improve the visibility of your listings by boosting them to the top of the results pages.'}
      </p>

      {propertyBoostError && renderErrorNotification()}
      {landlordHasCredits ? (
        <CreditCounter creditType="boost" credits={credits} creditCost={boostCost} />
      ) : (
        <CreditCounter creditType="boost" credits={credits} />
      )}
      {renderOverlayButton(credits, onBoostProperty, updatingBoost)}
    </Overlay>
  )
}

export default renderBoostOverlay
