import React from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated } from 'react-spring'
import classNames from 'classnames'

import useMedia from '../../../hooks/useMedia'

import LinkButton from '../../atoms/LinkButton'

import TickBox from '../../../svgs/icons/tickbox-orange.svg'
import Deselect from '../../../svgs/icons/deselect.svg'

import selectedPropertiesMenu from './selectedPropertiesMenu.module.scss'

const SelectedPropertiesMenu = ({
  className,
  isActive,
  allPropertiesSelected,
  renderButton,
  handleSelectAll,
  handleDeselectAll,
  hideAllPropertiesButton,
  numberOfSelectedProperties,
}) => {
  const isMobile = useMedia(['(max-width: 768px)'], [true], false)
  const isLargerThan480px = useMedia(['(min-width: 481px)'], [true], false)
  const isLargeMobile = isMobile && isLargerThan480px

  const { display, opacity, y } = useSpring({
    config: { duration: 200 },
    display: isActive ? 'block' : 'none',
    opacity: isActive ? 1 : 0,
    y: isActive ? 0 : 110,
  })

  const componentClasses = classNames(selectedPropertiesMenu.wrapper, className)

  return (
    <animated.div
      className={componentClasses}
      style={{
        display,
        opacity: opacity.interpolate(o => 0 + o),
        transform: y.interpolate(y => (isMobile ? `translateY(${y}%)` : `translateY(-${y}%)`)),
      }}
      data-testid="selected-properties-menu"
      aria-label="selected-properties-menu"
      role="complementary"
    >
      <div className={selectedPropertiesMenu.inner}>
        <span className={selectedPropertiesMenu.field}>
          {!hideAllPropertiesButton && allPropertiesSelected && (
            <LinkButton
              className={selectedPropertiesMenu.linkButton}
              onClick={handleDeselectAll}
              data-testid="deselect-all-button"
            >
              <Deselect className={selectedPropertiesMenu.icon} />
              {isLargeMobile ? 'Deselect all' : 'Deselect all properties'}
            </LinkButton>
          )}
          {!hideAllPropertiesButton && !allPropertiesSelected && (
            <LinkButton
              className={selectedPropertiesMenu.linkButton}
              onClick={handleSelectAll}
              data-testid="select-all-button"
            >
              <TickBox className={selectedPropertiesMenu.icon} />
              <span>{isLargeMobile ? 'Select all' : 'Select all properties'}</span>
            </LinkButton>
          )}
        </span>
        <span
          className={selectedPropertiesMenu.selectedPropertiesCount}
          data-testid="selected-properties-count"
        >
          {numberOfSelectedProperties ?? 0} selected
        </span>
        <span className={selectedPropertiesMenu.buttonWrapper}>{renderButton()}</span>
      </div>
    </animated.div>
  )
}

SelectedPropertiesMenu.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  allPropertiesSelected: PropTypes.bool.isRequired,
  renderButton: PropTypes.func.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  handleDeselectAll: PropTypes.func.isRequired,
  hideAllPropertiesButton: PropTypes.bool,
  numberOfSelectedProperties: PropTypes.number,
}

export default SelectedPropertiesMenu
