import React from 'react'
import PropTypes from 'prop-types'
import { BlobProvider } from '@react-pdf/renderer'
import InvoicePdf from './InvoicePdf'
import { config } from '../../../config'
import LoadingPage from '../LoadingPage/index'

import styles from './pdfPage.module.scss'

const s3BucketUrl = `https://${config.assetsPublicS3Bucket}.s3.eu-west-2.amazonaws.com/invoice-pdf`

const PdfPage = ({ invoice, isLettingAgentAccount }) => {
  const isIeOrEdge = typeof window.navigator.msSaveBlob !== 'undefined'

  const isSafari =
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1

  return (
    <BlobProvider
      document={
        <InvoicePdf
          invoice={invoice}
          assetsPath={s3BucketUrl}
          isLettingAgentInvoice={isLettingAgentAccount}
        />
      }
    >
      {({ blob, url, loading }) => {
        if (loading) {
          return <LoadingPage />
        } else {
          if (isIeOrEdge && blob) {
            window.navigator.msSaveBlob(blob, 'invoice.pdf')
            return <></>
          }

          if (isSafari && url) {
            window.location = url
            return <></>
          }

          if (url) {
            return (
              <object className={styles.object} data={url} type="application/pdf" height="100%">
                <embed src={url} type="application/pdf" />
              </object>
            )
          }
        }
      }}
    </BlobProvider>
  )
}

PdfPage.propTypes = {
  invoice: PropTypes.object,
  isLettingAgentAccount: PropTypes.bool.isRequired,
}

export default PdfPage
