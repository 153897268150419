import React, { memo, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import swap from 'lodash-move'
import { useGesture } from 'react-use-gesture'
import { useSprings, animated, interpolate } from 'react-spring'
import Spinner from '@afs/components/Spinner'

import useMedia from '../../../hooks/useMedia'
import clamp from '../../../utils/clamp'
import getPosition from '../../../utils/grid'

const DraggableList = ({ className, images, containerWidth, onDrop, onClick, imageApiUrl }) => {
  const { items, order } = images

  const columns = useMedia(['(min-width: 660px)'], [3], 2)
  const dragDelta = useRef(0)
  const gridRef = useRef(null)

  const getTileDimensions = () => {
    const container = Math.round(containerWidth)
    const gutter = container * (16 / container)
    const width = Math.round((container - gutter * (columns - 1)) / columns)
    const height = Math.round(width * 0.75)
    const widthPlusGutter = width + gutter
    const heightPlusGutter = height + gutter

    return {
      gutter,
      width: widthPlusGutter,
      height: heightPlusGutter,
    }
  }

  const calculateLayout = (order, down, originalIndex, curIndex, x, y) => index => {
    const isDown = down && index === originalIndex
    const { width: tileWidth, height: tileHeight } = getTileDimensions()

    if (isDown) {
      const curPosition = getPosition(curIndex, columns)
      return {
        xy: [(curPosition.col - 1) * tileWidth + x, (curPosition.row - 1) * tileHeight + y],
        scale: 1.1,
        zIndex: '1',
        shadow: 15,
        immediate: n => n === 'xy' || n === 'zIndex',
      }
    }

    const position = getPosition(order.indexOf(items[index].id), columns)

    return {
      xy: [(position.col - 1) * tileWidth, (position.row - 1) * tileHeight],
      scale: 1,
      zIndex: '0',
      shadow: 1,
      immediate: false,
    }
  }

  const [springs, setSprings] = useSprings(items.length, calculateLayout(order)) // eslint-disable-line

  const bindGestures = useGesture({
    onDrag: ({ args: [originalIndex], down, first, last, time, delta: [x, y] }) => {
      if (first) {
        dragDelta.current = time
      } else if (last) {
        dragDelta.current = time - dragDelta.current
      }

      const curIndex = order.indexOf(items[originalIndex].id)

      const maxRows = Math.ceil(items.length / columns)

      const { width: tileWidth, height: tileHeight } = getTileDimensions()
      const { col: curCol, row: curRow } = getPosition(curIndex, columns)

      const newCurCol = clamp(curCol + Math.round(x / tileWidth), 1, columns)
      const newCurRow = clamp(curRow + Math.round(y / tileHeight), 1, maxRows)

      const newPosition = columns * (newCurRow - 1) + newCurCol - 1
      const newOrder = swap(order, curIndex, newPosition)

      setSprings(calculateLayout(newOrder, down, originalIndex, curIndex, x, y))

      if (!down) {
        onDrop(newOrder)
      }
    },
    onClick: e => {
      e.stopPropagation()

      if (dragDelta.current < 100) {
        const target = e.target.dataset.photoId ? e.target : e.target.parentNode
        const { photoId } = target.dataset

        onClick(items[photoId])
      } else {
        dragDelta.current = 0
      }
    },
  })

  const disableAction = event => event.preventDefault()

  useEffect(() => {
    gridRef.current.addEventListener('touchmove', disableAction, {
      passive: false,
    })

    return gridRef.current.removeEventListener('touchmove', disableAction, {
      passive: false,
    })
  }, [])

  useEffect(() => {
    setSprings(calculateLayout(order))
  }, [columns, containerWidth])

  const { height: tileHeight, gutter } = getTileDimensions()

  return (
    <div
      data-testid="draggable-list"
      ref={gridRef}
      className={className}
      style={{
        height: Math.ceil(items.length / columns) * Math.round(tileHeight) - gutter,
      }}
    >
      {springs.map(({ zIndex, shadow, xy, scale }, i) => {
        const image = items[i]

        const currentPosition = order.indexOf(image.id)
        const isCoverItem = currentPosition === 0
        const itemClasses = classNames('field-upload__item', {
          'field-upload__item--cover': isCoverItem,
        })

        const label = isCoverItem ? 'Cover Image' : currentPosition + 1

        return (
          <animated.div
            {...bindGestures(i)}
            key={image.id}
            className={itemClasses}
            style={{
              zIndex,
              boxShadow: shadow.interpolate(s => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`),
              transform: interpolate(
                [xy, scale],
                (xy, s) => `translate3d(${xy[0]}px, ${xy[1]}px, 0) scale(${s})`
              ),
            }}
            data-photo-id={i}
            data-testid={`draggable-tile-${i}`}
          >
            <span className="field-upload__item-label">{label}</span>
            <img
              key={image.name}
              src={`${imageApiUrl ? `${imageApiUrl}w=228/${image.src}` : image.src}`}
              alt={image.name}
            />
            <Spinner className="field-upload__item-spinner" />
          </animated.div>
        )
      })}
    </div>
  )
}

DraggableList.propTypes = {
  className: PropTypes.string,
  images: PropTypes.shape({
    items: PropTypes.array,
    order: PropTypes.array,
  }),
  containerWidth: PropTypes.number.isRequired,
  onDrop: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  imageApiUrl: PropTypes.string,
}

export default memo(DraggableList)
