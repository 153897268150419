import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Heading from '../../atoms/Heading'
import Button from '../../atoms/Button'
import Link from '../../atoms/Link'

import selectAccountCard from './selectAccountCard.module.scss'

const SelectAccountCard = ({ className }) => {
  const cardClasses = classNames(selectAccountCard.wrapper, className)
  return (
    <div className={cardClasses}>
      <Heading className={selectAccountCard.heading} level={3}>
        Select account type
      </Heading>
      <div className={selectAccountCard.buttonWrapper}>
        <Button className={selectAccountCard.button} href="landlord-admin/register">
          Landlord
        </Button>
        <Button className={selectAccountCard.button} href="/landlord-admin/letting-agent/register">
          Letting Agent
        </Button>
      </div>
      <p className={selectAccountCard.text}>
        Already have an account?{' '}
        <Link className={selectAccountCard.link} href="/login">
          Log in.
        </Link>
      </p>
    </div>
  )
}

SelectAccountCard.propTypes = {
  className: PropTypes.string,
}

export default SelectAccountCard
