import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Link from '../Link'

import BackIcon from './back.svg'

import styles from './styles.module.scss'

const IconBackLink = ({ className, ...props }) => {
  return (
    <Link className={classNames(styles.link, className)} {...props} aria-label="Back">
      <BackIcon className={styles.icon} />
    </Link>
  )
}

IconBackLink.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
}

export default IconBackLink
