import React from 'react'
import SpinnerButton from '@afs/components/SpinnerButton'

import { updatePropertyAvailability } from '../../../../api/propertyApi'
import formatDateForApi from '../../../../utils/formatDateForApi'

import {
  AvailabilityPage,
  availabilityPageValidation,
} from '../../FormPages/AvailabilityPage/AvailabilityPage'
import SubmitButton from '../../../atoms/SubmitButton'

const availabilityPageConfig = propertyId => ({
  id: 3,
  component: () => <AvailabilityPage />,
  route: `/landlord-admin/property/${propertyId}/edit/availability`,
  validate: values => availabilityPageValidation(values),
  isValid: false,
  heading: 'Property availability',
  subheading: "Specify the 'Move in' and 'Move out' dates for this property.",
  button: (values, loading) => {
    if (loading)
      return (
        <SpinnerButton primary inactive>
          Continue
        </SpinnerButton>
      )
    return <SubmitButton data-testid="save-button">Save changes</SubmitButton>
  },
  onSubmit: async (values, token) => {
    const payload = {
      propertyId,
      availableFrom: formatDateForApi(values.availableFrom),
      availableUntil: values.availableUntil ? formatDateForApi(values.availableUntil) : null,
    }
    return updatePropertyAvailability(payload, token)
  },
})

export default availabilityPageConfig
