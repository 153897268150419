import moment from 'moment'

import Address from './address'

class Enquiry {
  constructor(enquiry, config) {
    Object.assign(this, enquiry)

    this.config = config
    this.property.address = new Address(enquiry.property.address)
    this.dateTime = moment(enquiry.created)
  }

  studentName() {
    const { student } = this
    return [student.firstName, student.lastName].join(' ')
  }

  studentEmail() {
    const { student } = this
    return student.email
  }

  studentPhone() {
    const { student } = this
    return student.phone
  }

  propertyAddressFirstLine() {
    const { property } = this
    return property.address.firstLine()
  }

  propertyAddressWithoutFirstLine() {
    const { property } = this
    return property.address.withoutFirstLine().join(', ')
  }

  propertyAddressWithoutFirstLineAndPostcode() {
    const { property } = this
    return property.address.withoutFirstLineAndPostcode().join(', ')
  }

  propertyPostcode() {
    const { property } = this
    return property.address.postcodeOnly()
  }

  propertyId() {
    const { property } = this
    return property.id
  }

  propertyUrl() {
    const { property, config } = this

    return `${config.mainSiteBaseUrl}property/${property.path}`
  }

  postedToday() {
    return moment(this.dateTime).isSame(moment(), 'day')
  }

  dateString() {
    const currentYear = moment().format('YYYY')

    if (this.dateTime.format('YYYY') === currentYear) {
      return this.dateTime.format('D MMM')
    } else {
      return this.dateTime.format('D MMM YYYY')
    }
  }

  timeString() {
    return this.dateTime.format('h:mm')
  }

  timePeriodString() {
    return this.dateTime.format('a')
  }

  emailSubject() {
    const { property } = this
    return `RE: New enquiry for ${property.address.streetAreaCityAndPostcode().join(', ')}`
  }

  emailBody() {
    const { property } = this
    const lineBreak = '%0D%0A'

    const studentDetails = this.isMigrated
      ? `Student details:${lineBreak}${this.studentEmail()}`
      : `Student details:${lineBreak}${this.studentName()}${lineBreak}${this.studentEmail()}${lineBreak}${this.studentPhone()}`

    return `${lineBreak}${lineBreak}—————————————————${lineBreak}${lineBreak}You have a new enquiry for your property at ${property.address
      .streetAreaCityAndPostcode()
      .join(', ')}.${lineBreak}${this.propertyUrl()}${lineBreak}${lineBreak}Message:${lineBreak}${
      this.message
    }${lineBreak}${lineBreak}${studentDetails}`
  }
}

export default Enquiry
