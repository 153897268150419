import React from 'react'
import { Field } from 'react-final-form'

import FieldAdapter from '../../../adapters/FieldAdapter'

export const ReferenceNumberPage = () => {
  return (
    <Field name="referenceNumber" component={FieldAdapter}>
      {props => <FieldAdapter label="Reference number" optional {...props} />}
    </Field>
  )
}

export const referenceNumberPageValidation = values => {
  const errors = {}
  const htmlRegex = /<\/?\w+((\s+\w+(\s*=\s*(?:".*?"|'.*?'|[^'">\s]+))?)+\s*|\s*)\/?>/g

  if (values.referenceNumber && values.referenceNumber.match(htmlRegex)) {
    errors.referenceNumber = 'HTML tags are not allowed in the reference number.'
  }

  return errors
}
