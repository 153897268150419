import React from 'react'
import { Field } from 'react-final-form'

import FieldCategoryMultiSelectAdapter from '../../adapters/FieldCategoryMultiSelectAdapter'

const allAmenities = [
  {
    title: 'Appliances',
    options: ['Fridge', 'Freezer', 'Oven', 'Microwave', 'Dishwasher', 'Washing machine', 'Dryer'],
  },
  {
    title: 'Interior',
    options: [
      'Furnished',
      'Double glazing',
      'Separate lounge',
      'Bath',
      'Shower',
      'Alarm',
      'Fire alarm',
    ],
  },
  {
    title: 'Exterior',
    options: ['Garden', 'Off road parking', 'Garage'],
  },
  {
    title: 'Services',
    options: [
      'Wifi',
      'Sky/Cable package',
      'Energy performance certificate',
      'Gas safe registered',
      'Electrical safety certificate',
      'NRLA registered',
    ],
  },
]

const AmenitiesPage = () => {
  return (
    <Field name="amenities" component={FieldCategoryMultiSelectAdapter}>
      {props => <FieldCategoryMultiSelectAdapter {...props} categories={allAmenities} />}
    </Field>
  )
}

export default AmenitiesPage
