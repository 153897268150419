import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from '../../../atoms/Button'
import LinkButton from '../../../atoms/LinkButton'
import FieldAddressList from '../../FieldAddressList'

const SelectAddressForm = ({ addresses, existingAddress, onSelectAddress, onEditManually }) => {
  const [selectedAddress, setSelectedAddress] = useState(existingAddress)

  const handleContinue = event => {
    if (selectedAddress.isValid()) {
      onSelectAddress(selectedAddress)
    } else {
      onEditManually(event, selectedAddress)
    }
  }

  const hasSelectedAddress = selectedAddress && selectedAddress.postcode
  const actionClasses = classNames('select-address-form__actions', {
    'select-address-form__actions--sticky': hasSelectedAddress,
  })

  return (
    <div className="select-address-form">
      <FieldAddressList
        className="select-address-form__address-list"
        name="address"
        addresses={addresses}
        selectedAddress={selectedAddress}
        onSelectAddress={setSelectedAddress}
      />

      <LinkButton
        className="select-address-form__missing-button"
        onClick={onEditManually}
        data-testid="address-missing-button"
      >
        {"I can't find my address in this list"}
      </LinkButton>

      <div className={actionClasses}>
        {hasSelectedAddress && (
          <Button
            primary
            className="select-address-form__continue-button overlay__continue"
            onClick={handleContinue}
            data-testid="select-continue-button"
            wide
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  )
}

SelectAddressForm.propTypes = {
  addresses: PropTypes.array.isRequired,
  existingAddress: PropTypes.object.isRequired,
  onSelectAddress: PropTypes.func.isRequired,
  onEditManually: PropTypes.func.isRequired,
}

export default SelectAddressForm
