import { StyleSheet } from '@react-pdf/renderer'

const convertToPt = px => px * 0.62
const gutter = x => convertToPt(x * 8)

const colours = {
  base: {
    charlie: '#1c304a',
  },
  gray: {
    '50': '#FBFBFB',
    '100': '#f4f4f4',
    '700': '#636171',
  },
}

// ==========================================================================
// PDF Invoice
// ==========================================================================

const page = {
  backgroundColor: '#fff',
  padding: convertToPt(48),
}

const text = {
  fontSize: convertToPt(15),
  fontFamily: 'Apax Three',
  color: colours.gray['700'],
  lineHeight: 25 / 15,
}

const textSmall = {
  ...text,
  fontSize: convertToPt(13),
  lineHeight: 23 / 13,
}

const label = {
  fontFamily: 'Apax Three',
  fontSize: convertToPt(17),
  fontWeight: 500,
  lineHeight: 27 / 17,
  color: colours.base.charlie,
}

const lineItemTitle = {
  fontFamily: 'Apax Three',
  fontSize: convertToPt(17),
  fontWeight: 500,
  lineHeight: 27 / 17,
  color: colours.base.charlie,
  wordBreak: 'break-all',
  width: 260,
}

// Header
// ==========================================================================

const header = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: convertToPt(40),
}

const invoiceNumber = {
  fontFamily: 'Apax Three',
  fontSize: convertToPt(25.6),
  fontWeight: 600,
  lineHeight: 1.34,
  color: colours.base.charlie,
}

const logo = {
  display: 'block',
  width: convertToPt(58),
}

const paidStamp = {
  position: 'absolute',
  top: convertToPt(90),
  left: '40%',
  marginLeft: convertToPt(-80),
  width: convertToPt(280),
}

// Details
// ==========================================================================

const details = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginBottom: gutter(1.75),
  paddingBottom: gutter(8),
  borderBottom: `1pt solid ${colours.gray['100']}`,
}

const detailsColumnLeft = {
  width: '42vw',
}

const detailsColumnRight = {
  ...detailsColumnLeft,
  textAlign: 'right',
}

const invoiceDate = {
  marginBottom: gutter(2.5),
}

const afsTitle = {
  fontFamily: 'Apax Three',
  fontSize: convertToPt(19),
  fontWeight: 600,
  color: colours.base.charlie,
  marginBottom: gutter(1.5),
}

const afsText = {
  marginBottom: gutter(1.5),
}

// Advertisement orders
// ==========================================================================

const order = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: gutter(2),
  paddingTop: gutter(3),
  paddingBottom: gutter(1.5),
  paddingHorizontal: gutter(3),
  backgroundColor: colours.gray['50'],
  borderRadius: convertToPt(6),
}

const orderDetails = {
  marginRight: 'auto',
}

const orderBreakdown = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
}

const orderPrice = {
  ...label,
  lineHeight: 1.45,
}

const priceBox = {
  display: 'flex',
  textAlign: 'right',
  width: convertToPt(100),
}

const orderUnitTotal = {
  textAlign: 'right',
  width: convertToPt(100),
}

// Total
// ==========================================================================

const total = {
  marginTop: gutter(3.5),
  borderTop: `1pt solid ${colours.gray['100']}`,
  paddingTop: gutter(3.5),
  paddingLeft: 320,
  paddingRight: 15,
  width: '100%',
}

const netDiscountSection = {
  display: 'flex',
  flexDirection: 'column',
  marginTop: gutter(1),
  paddingBottom: gutter(1.25),
  borderBottom: `1pt solid ${colours.gray['100']}`,
}

const subTotalVatSection = {
  display: 'flex',
  flexDirection: 'column',
  marginTop: gutter(2.5),
  paddingBottom: gutter(1),
}

const netVatSection = {
  display: 'flex',
  flexDirection: 'column',
  marginTop: gutter(2.5),
  marginBottom: gutter(2.5),
}

const totalBlocks = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
}

const totalGrossBlocks = {
  ...totalBlocks,
  marginLeft: 'auto',
  paddingTop: gutter(4.5),
  borderTop: `1pt solid ${colours.gray['100']}`,
}

const totalVat = {
  ...totalBlocks,
  marginTop: gutter(1.5),
}

const totalSmall = {
  ...label,
  fontWeight: 'normal',
}

const totalAmount = {
  ...label,
  width: gutter(13),
  textAlign: 'right',
}

const totalMed = {
  fontFamily: 'Apax Three',
  fontWeight: 'normal',
  fontSize: convertToPt(20),
  color: colours.base.charlie,
}

const totalLarge = {
  ...totalMed,
  fontWeight: 600,
  width: gutter(13),
  textAlign: 'right',
}

export default StyleSheet.create({
  page,
  text,
  textSmall,
  label,
  lineItemTitle,
  header,
  invoiceNumber,
  logo,
  paidStamp,
  details,
  detailsColumnLeft,
  detailsColumnRight,
  invoiceDate,
  afsTitle,
  afsText,
  order,
  orderDetails,
  orderBreakdown,
  orderPrice,
  priceBox,
  orderUnitTotal,
  total,
  netDiscountSection,
  netVatSection,
  subTotalVatSection,
  totalBlocks,
  totalGrossBlocks,
  totalVat,
  totalSmall,
  totalAmount,
  totalMed,
  totalLarge,
})
