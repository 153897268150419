import React from 'react'
import SpinnerButton from '@afs/components/SpinnerButton'

import { updatePropertyBathrooms } from '../../../../api/propertyApi'

import { BathroomsPage, bathroomsPageValidation } from '../../FormPages/BathroomsPage'
import SubmitButton from '../../../atoms/SubmitButton'

const bathroomPageConfig = propertyId => ({
  id: 6,
  component: values => <BathroomsPage propertyType={values.accommodationType} />,
  route: `/landlord-admin/property/${propertyId}/edit/bathrooms`,
  validate: bathroomsPageValidation,
  isValid: false,
  heading: 'Number of bathrooms',
  subheading: 'Please include all the bathrooms, including half-bathrooms and en-suites.',
  button: (values, loading) => {
    if (values.bathrooms === 0) return null
    if (loading)
      return (
        <SpinnerButton primary inactive>
          Continue
        </SpinnerButton>
      )
    return <SubmitButton data-testid="save-button">Save changes</SubmitButton>
  },
  onSubmit: async (values, token) => {
    const payload = {
      propertyId,
      bathrooms: values.bathrooms,
    }
    return updatePropertyBathrooms(payload, token)
  },
})

export default bathroomPageConfig
