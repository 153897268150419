import React from 'react'
import PropTypes from 'prop-types'
import SpinnerButton from '@afs/components/SpinnerButton'

import Address from '../../../models/address'

import Overlay from '../../molecules/Overlay'
import Heading from '../../atoms/Heading'
import AvatarImage from '../../atoms/AvatarImage'
import Button from '../../atoms/Button'
import Card from '../../atoms/Card'
import Notification from '../../molecules/Notification'

import styles from './index.module.scss'
import overlayStyles from '../../pages/PropertyDetailsPage/modules/overlay.module.scss'

const formatPropertyAddress = propertyAddress => {
  const address = new Address(propertyAddress)
  const addressFirstLine = address.firstTwoLines()
  const addressSecondLine = `${address
    .withoutFirstTwoLinesAndPostcode()
    .join(', ')}, ${address.postcodeOnly()}`

  return {
    addressFirstLine,
    addressSecondLine,
  }
}

const PropertyCard = ({ property }) => {
  const { addressFirstLine, addressSecondLine } = formatPropertyAddress(property.address)

  const hasPhoto = property.photos.length > 0
  const photoUrl = property.photos.length > 0 ? property.photos[0].src : ''

  return (
    <Card className={styles.propertyCard} middleCardClass={styles.propertyCardMiddle}>
      <div className={styles.propertyCardInner}>
        <div className={styles.property} data-testid="performance-list-row-property">
          <AvatarImage
            className={styles.propertyImage}
            hasPhoto={hasPhoto}
            photoUrl={photoUrl}
            alt={addressFirstLine}
          />
          <div className={styles.propertyAddress}>
            <p className={styles.addressTitle}>{addressFirstLine}</p>
            <p className={styles.addressSubtitle}>{addressSecondLine}</p>
          </div>
        </div>
      </div>
    </Card>
  )
}

PropertyCard.propTypes = {
  property: PropTypes.shape({
    address: PropTypes.object,
    photos: PropTypes.array,
  }),
}

const RelistPropertyOverlay = ({
  config,
  property,
  handleListProperty,
  handleOverlayClose,
  updating,
  propertyRelistError,
}) => {
  return (
    <Overlay
      id="relist-property-overlay"
      dataTestId="relist-property-overlay"
      closeLabel="Close"
      classNameOverlayInner={styles.overlayInner}
      isActive={config.active}
      onClose={handleOverlayClose}
      preserveScrollPositionOnClose
    >
      <Heading level={3}>
        <span>Are you sure you want to</span>
        <span className={styles.headingSecondLine}>relist this property?</span>
      </Heading>
      {propertyRelistError && (
        <Notification
          className={overlayStyles.errorNotification}
          heading="Oops!"
          text="We weren’t able to relist your property. Please try again."
          type="error"
        />
      )}
      <PropertyCard property={property} />
      <SpinnerButton
        primary
        className={styles.buttonLeft}
        onClick={handleListProperty}
        inactive={updating}
        data-testid="overlay-relist-property-button"
      >
        Relist property
      </SpinnerButton>
      <Button className={styles.buttonRight} onClick={handleOverlayClose} secondary>
        Cancel
      </Button>
    </Overlay>
  )
}

RelistPropertyOverlay.propTypes = {
  config: PropTypes.object.isRequired,
  handleListProperty: PropTypes.func.isRequired,
  handleOverlayClose: PropTypes.func.isRequired,
  property: PropTypes.shape({ isListed: PropTypes.bool, photos: PropTypes.array }),
  updating: PropTypes.bool.isRequired,
  propertyRelistError: PropTypes.bool.isRequired,
}

export default RelistPropertyOverlay
