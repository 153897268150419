export const truncateStringInTheMiddle = (fullStr, strLen, ellipsis = '…') => {
  if (fullStr.length <= strLen) return fullStr

  const charsToShow = strLen - ellipsis.length
  const frontChars = Math.ceil(charsToShow / 2)
  const backChars = Math.floor(charsToShow / 2)

  return fullStr.substr(0, frontChars) + ellipsis + fullStr.substr(fullStr.length - backChars)
}

export const truncateStringByCharacters = (stringToShorten, maxLength, ellipsis = '…') => {
  const dontTruncateString = stringToShorten.length <= maxLength

  if (dontTruncateString) return stringToShorten

  return `${stringToShorten.substr(0, stringToShorten.lastIndexOf(' ', maxLength))}${ellipsis}`
}

export const truncateStringByWords = (stringToShorten, maxWords, ellipsis = '…') => {
  const wordsInString = stringToShorten.split(' ')
  const wordCount = wordsInString.length

  if (wordCount <= maxWords) return stringToShorten

  return `${wordsInString.splice(0, maxWords).join(' ')}${ellipsis}`
}
