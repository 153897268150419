import { useEffect } from 'react'
import TabbingFocusEventListeners from './tabbingFocusEventListeners'
import TabbingFocusEventHandlers from './tabbingFocusEventHandlers'

export const useTabbingFocusListener = (window, handlers) => {
  useEffect(() => {
    const { tabbingActionEventHandler, mouseDownEventHandler } = handlers

    const eventListeners = TabbingFocusEventListeners(
      window,
      tabbingActionEventHandler,
      mouseDownEventHandler
    )
    const { addTabbingFocusListener, removeTabbingFocusListeners } = eventListeners

    addTabbingFocusListener()
    return () => removeTabbingFocusListeners()
  })
}

export default window => {
  return useTabbingFocusListener(window, new TabbingFocusEventHandlers(window))
}
