export default (randomIdGenerator) => {
  let deferredNotifications = []
  let subscribers = []

  const addSubscriber = (subscriber) => {
    subscribers.push(subscriber)
  }

  const removeSubscriberById = (id) => {
    const updatedSubscribers = subscribers.filter((subscriber) => subscriber.id !== id)
    subscribers = updatedSubscribers
  }

  const removeAllSubscribers = () => {
    subscribers = []
  }

  const notifySubscribers = (notifications) => {
    subscribers.forEach((subscriber) => {
      subscriber.callback(notifications)
    })
  }

  const notifySubscriber = (subscriberId, notifications) => {
    const subscriber = subscribers.find((x) => x.id === subscriberId)

    if (subscriber === undefined) return

    subscriber.callback(notifications)
  }

  const clearNotifications = () => {
    notifySubscribers([])
  }

  const showErrorNotification = (heading, text) => {
    const timeNow = randomIdGenerator.timeNowInMilliseconds()

    const errorNotification = {
      id: timeNow,
      created: timeNow,
      type: 'error',
      text,
      heading,
    }
    notifySubscribers([errorNotification])
  }

  const showWarningNotification = (heading, text) => {
    const timeNow = randomIdGenerator.timeNowInMilliseconds()

    const warningNotification = {
      id: timeNow,
      created: timeNow,
      type: 'warning',
      text,
      heading,
    }
    notifySubscribers([warningNotification])
  }

  const showSuccessNotification = (heading, text) => {
    const timeNow = randomIdGenerator.timeNowInMilliseconds()

    const successNotification = {
      id: timeNow,
      created: timeNow,
      type: 'success',
      text,
      heading,
    }
    notifySubscribers([successNotification])
  }

  const showSuccessNotificationForSubscriber = (subscriberId, heading, text) => {
    const timeNow = randomIdGenerator.timeNowInMilliseconds()

    const successNotification = {
      id: timeNow,
      created: timeNow,
      type: 'success',
      text,
      heading,
    }

    notifySubscriber(subscriberId, [successNotification])
  }

  const showDeferredNotifications = () => {
    const notificationsToReturn = [...deferredNotifications]
    deferredNotifications = []

    return notificationsToReturn
  }

  const addDeferredSuccessNotification = (heading, text) => {
    const timeNow = randomIdGenerator.timeNowInMilliseconds()

    const successNotification = {
      id: timeNow,
      created: timeNow,
      type: 'success',
      text,
      heading,
    }
    deferredNotifications.push(successNotification)
  }

  const addDeferredWarningNotification = (heading, text) => {
    const timeNow = randomIdGenerator.timeNowInMilliseconds()

    const successNotification = {
      id: timeNow,
      created: timeNow,
      type: 'warning',
      text,
      heading,
    }
    deferredNotifications.push(successNotification)
  }

  const addDeferredErrorNotification = (heading, text) => {
    const timeNow = randomIdGenerator.timeNowInMilliseconds()

    const errorNotification = {
      id: timeNow,
      created: timeNow,
      type: 'error',
      text,
      heading,
    }
    deferredNotifications.push(errorNotification)
  }

  return {
    addSubscriber,
    removeSubscriberById,
    removeAllSubscribers,
    clearNotifications,
    showSuccessNotification,
    showSuccessNotificationForSubscriber,
    showErrorNotification,
    showWarningNotification,
    showDeferredNotifications,
    addDeferredSuccessNotification,
    addDeferredWarningNotification,
    addDeferredErrorNotification,
  }
}
