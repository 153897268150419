import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useSpring, animated } from 'react-spring'

import './styles.scss'

import Portal from '../../atoms/Portal'
import CloseButton from '../../molecules/CloseButton'
import Notifications from '../../organisms/Notifications'

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

const Overlay = ({
  id,
  className,
  classNameOverlayInner,
  dataTestId,
  heading,
  children,
  isActive,
  onClose,
  closeLabel,
  preserveScrollPositionOnClose,
  dontDisplayNotifications,
}) => {
  const overlayClasses = classNames(className, 'overlay')
  const overlayInnerClasses = classNames(classNameOverlayInner, 'overlay__frame')

  const { opacity, display } = useSpring({
    config: { duration: 10 },
    opacity: isActive ? 1 : 0,
    display: isActive ? 'flex' : 'none',
  })

  const handleClose = () => {
    if (canUseDOM) {
      document.body.classList.remove('no-scroll-and-hidden')
    }

    onClose()
  }

  const handleKeyDown = (event) => {
    const escapeKey = 27

    if (event.code === escapeKey || event.keyCode === escapeKey) {
      handleClose()
    }
  }

  let yOffset

  const lockBodyElement = () => {
    if (canUseDOM) {
      yOffset = window.pageYOffset

      document.body.classList.add('no-scroll-and-hidden')
      document.body.style.top = `-${yOffset}px`
    }
  }

  const unlockBodyElement = () => {
    if (canUseDOM) {
      document.body.classList.remove('no-scroll-and-hidden')

      if (preserveScrollPositionOnClose) {
        window.scroll({ top: yOffset })
      }
    }
  }

  useEffect(() => {
    if (isActive) {
      lockBodyElement()
    }
    return () => unlockBodyElement()
  }, [isActive])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [])

  return (
    <Portal id={id}>
      <animated.div
        className={overlayClasses}
        style={{ display, opacity: opacity.interpolate((o) => 0 + o) }}
        data-testid="overlay-wrapper"
      >
        <div className={overlayInnerClasses} data-testid={dataTestId || 'overlay'}>
          <div className="overlay__header">
            {heading && <strong className="overlay__heading">{heading}</strong>}
            <CloseButton
              className="overlay__close"
              label={closeLabel}
              onClick={handleClose}
              data-testid="close-overlay"
            >
              Close
            </CloseButton>
          </div>
          {!dontDisplayNotifications && <Notifications className="overlay__notifications" />}
          <div className="overlay__content">{children}</div>
        </div>
      </animated.div>
    </Portal>
  )
}

Overlay.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  classNameOverlayInner: PropTypes.string,
  dataTestId: PropTypes.string,
  heading: PropTypes.string,
  children: PropTypes.node,
  isActive: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  closeLabel: PropTypes.string,
  preserveScrollPositionOnClose: PropTypes.bool,
  dontDisplayNotifications: PropTypes.bool,
}

export default Overlay
