import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Label from '@afs/components/Label'

import './styles.scss'

import Icon from '../../atoms/Icon'

const TypeField = (props) => {
  const label = props.option.charAt(0).toUpperCase() + props.option.slice(1)

  return (
    <div className="form-option-type__field">
      <input
        id={`${props.name}-${props.option}`}
        className="form-option-type__input"
        name={`${props.name}`}
        type="radio"
        value={`${props.option}`}
        onChange={() => props.handleClick(props.option)}
        checked={props.checked}
      />
      <Label
        className="form-option-type__label"
        htmlFor={`${props.name}-${props.option}`}
        data-testid={`field-option-group-option-${props.option}`}
      >
        <span className="form-option-type__label-inner">
          {props.showIcon && (
            <span
              className={`form-option-type__icon form-option-type__icon--${props.option.toLowerCase()}`}
            >
              <Icon icon={props.option.toLowerCase()} />
            </span>
          )}
          {label}
        </span>
      </Label>
    </div>
  )
}

const Error = ({ children, ...props }) => <span {...props}>{children}</span>

const FieldOptionGroup = ({
  className,
  error,
  legend,
  hideLegend,
  name = 'accommodationType',
  invalid,
  onSelectOption,
  selectedOption,
  showIcons,
  options = ['House', 'Flat', 'Studio'],
  ...props
}) => {
  const componentClasses = classNames('form-option-type', className)
  const legendClasses = classNames('form-option-type__legend', {
    'form-option-type__legend--hidden': hideLegend,
  })
  const fieldsWrapperClasses = classNames('form-option-type__fields', {
    'form-option-type__fields--invalid': invalid,
    'form-option-type__fields--has-icons': showIcons,
    'form-option-type__fields--selected': selectedOption,
    'form-option-type__fields-2-elements': options.length === 2,
    'form-option-type__fields-3-elements': options.length === 3,
  })

  return (
    <fieldset className={componentClasses} data-testid="field-option-group" {...props}>
      <legend className={legendClasses}>{legend}</legend>
      <div className={fieldsWrapperClasses}>
        {options.map((option) => (
          <TypeField
            key={option}
            option={option}
            name={name}
            handleClick={onSelectOption}
            checked={option === selectedOption}
            showIcon={showIcons}
          />
        ))}
      </div>
      {invalid && error && (
        <Error id={`${name}Error`} className="form-field__description" role="alert">
          {error}
        </Error>
      )}
    </fieldset>
  )
}

TypeField.propTypes = {
  option: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  checked: PropTypes.bool,
  showIcon: PropTypes.bool,
}

Error.propTypes = {
  children: PropTypes.any,
}

FieldOptionGroup.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  legend: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  hideLegend: PropTypes.bool,
  option: PropTypes.string,
  name: PropTypes.string,
  invalid: PropTypes.bool,
  onSelectOption: PropTypes.func,
  selectedOption: PropTypes.string,
  showIcons: PropTypes.bool,
  options: PropTypes.array,
}

export default FieldOptionGroup
