import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router'
import PropTypes from 'prop-types'

import { useStateContext } from '../../appStateContext'
import { useAuthContext } from '../../authContext'
import { boostMultipleProperties, getSelectedProperties } from '../../api/propertyApi'
import { getAccountBoostCredits } from '../../api/accountApi'
import { notificationService } from '../../container'

import MultiplePropertiesBoostPage from '../pages/MultiplePropertiesBoostPage'
import LoadingPage from '../pages/LoadingPage'

const MultiplePropertiesBoostContainer = ({
  boostPage,
  useAuthContext,
  useStateContext,
  getProperties,
  getBoostCredits,
  boostMultipleProperties,
  history,
  redirect,
}) => {
  const [serverError, setServerError] = useState(false)
  const [containerProperties, setContainerProperties] = useState([])
  const [boostCredits, setBoostCredits] = useState(0)
  const [loading, setLoading] = useState(false)
  const [loadingBoostProperties, setLoadingBoostProperties] = useState(false)

  const { token } = useAuthContext()
  const { propertyIds } = useStateContext()

  const deselectProperty = deselectedPropertyId => {
    propertyIds.deselect(deselectedPropertyId)

    const selectedProperties = containerProperties.filter(x => x.id !== deselectedPropertyId)
    setContainerProperties(selectedProperties)
  }

  useEffect(() => {
    setLoading(true)
    getProperties(propertyIds.selected(), token).then(response => {
      if (response.success) {
        setContainerProperties(response.properties)
      } else {
        setServerError(true)
      }
    })

    getBoostCredits(token).then(response => {
      if (response.success) {
        setBoostCredits(response.boostCredits)
        setLoading(false)
      } else {
        setServerError(true)
      }
    })
  }, [])

  const boostProperties = () => {
    setLoadingBoostProperties(true)

    const selectedPropertyIds = propertyIds.selected()

    boostMultipleProperties(selectedPropertyIds, token).then(response => {
      const moreThanOneSelected = selectedPropertyIds.length > 1
      const propertyText = moreThanOneSelected ? 'properties have' : 'property has'

      if (response.success) {
        notificationService.addDeferredSuccessNotification(
          'Success!',
          `Your selected ${propertyText} been boosted!`
        )
        propertyIds.deselectAll()
        history.push('/landlord-admin/properties')
      } else {
        notificationService.showErrorNotification(
          'Error.',
          `Your selected ${propertyText} not been boosted. Please try again later.`
        )
      }

      setLoadingBoostProperties(false)
    })
  }

  if (serverError) {
    return redirect('/landlord-admin/error')
  } else if (propertyIds.noneSelected()) {
    return redirect('/landlord-admin/properties')
  } else {
    return loading ? (
      <LoadingPage />
    ) : (
      boostPage(
        history,
        loadingBoostProperties,
        containerProperties,
        boostCredits,
        boostProperties,
        deselectProperty
      )
    )
  }
}

MultiplePropertiesBoostContainer.propTypes = {
  boostPage: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
  useStateContext: PropTypes.func.isRequired,
  getProperties: PropTypes.func.isRequired,
  getBoostCredits: PropTypes.func.isRequired,
  boostMultipleProperties: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
}

export const MultiplePropertiesBoostContainerCompositionRoot = ({ history }) => {
  return (
    <MultiplePropertiesBoostContainer
      boostPage={(
        history,
        loading,
        selectedProperties,
        boostCredits,
        onBoost,
        deselectProperty
      ) => (
        <MultiplePropertiesBoostPage
          history={history}
          loading={loading}
          selectedProperties={selectedProperties}
          boostCredits={boostCredits}
          onBoost={onBoost}
          deselectProperty={deselectProperty}
        />
      )}
      useAuthContext={useAuthContext}
      useStateContext={useStateContext}
      getProperties={getSelectedProperties}
      getBoostCredits={getAccountBoostCredits}
      boostMultipleProperties={boostMultipleProperties}
      history={history}
      redirect={path => <Redirect to={path} />}
    />
  )
}

MultiplePropertiesBoostContainerCompositionRoot.propTypes = {
  history: PropTypes.object,
}

export default MultiplePropertiesBoostContainer
