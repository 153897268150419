module.exports = [
  {
    name: 'Aberdeen',
    displayName: 'Aberdeen',
    linkPath: 'aberdeen',
  },
  {
    name: 'Aberystwyth',
    displayName: 'Aberystwyth',
    linkPath: 'aberystwyth',
  },
  {
    name: 'Aldershot',
    displayName: 'Aldershot',
    linkPath: 'aldershot',
  },
  {
    name: 'Alfreton',
    displayName: 'Alfreton',
    linkPath: 'alfreton',
  },
  {
    name: 'Ayr',
    displayName: 'Ayr',
    linkPath: 'ayr',
  },
  {
    name: 'Bangor',
    displayName: 'Bangor',
    linkPath: 'bangor',
  },
  {
    name: 'Barnsley',
    displayName: 'Barnsley',
    linkPath: 'barnsley',
  },
  {
    name: 'Basildon',
    displayName: 'Basildon',
    linkPath: 'basildon',
  },
  {
    name: 'Bath',
    displayName: 'Bath',
    linkPath: 'bath',
  },
  {
    name: 'Bedford',
    displayName: 'Bedford',
    linkPath: 'bedford',
  },
  {
    name: 'Belfast',
    displayName: 'Belfast',
    linkPath: 'belfast',
  },
  {
    name: 'Birmingham',
    displayName: 'Birmingham',
    linkPath: 'birmingham',
  },
  {
    name: 'Blackburn',
    displayName: 'Blackburn',
    linkPath: 'blackburn',
  },
  {
    name: 'Blackpool',
    displayName: 'Blackpool',
    linkPath: 'blackpool',
  },
  {
    name: 'Bognor Regis',
    displayName: 'Bognor Regis',
    linkPath: 'bognor-regis',
  },
  {
    name: 'Bolton',
    displayName: 'Bolton',
    linkPath: 'bolton',
  },
  {
    name: 'Bournemouth',
    displayName: 'Bournemouth',
    linkPath: 'bournemouth',
  },
  {
    name: 'Bradford',
    displayName: 'Bradford',
    linkPath: 'bradford',
  },
  {
    name: 'Bridgwater',
    displayName: 'Bridgwater',
    linkPath: 'bridgwater',
  },
  {
    name: 'Brighton',
    displayName: 'Brighton',
    linkPath: 'brighton',
  },
  {
    name: 'Bristol',
    displayName: 'Bristol',
    linkPath: 'bristol',
  },
  {
    name: 'Broadstairs',
    displayName: 'Broadstairs',
    linkPath: 'broadstairs',
  },
  {
    name: 'Buckingham',
    displayName: 'Buckingham',
    linkPath: 'buckingham',
  },
  {
    name: 'Burnley',
    displayName: 'Burnley',
    linkPath: 'burnley',
  },
  {
    name: 'Bury',
    displayName: 'Bury',
    linkPath: 'bury',
  },
  {
    name: 'Caerphilly',
    displayName: 'Caerphilly',
    linkPath: 'caerphilly',
  },
  {
    name: 'Callington',
    displayName: 'Callington',
    linkPath: 'callington',
  },
  {
    name: 'Camborne',
    displayName: 'Camborne',
    linkPath: 'camborne',
  },
  {
    name: 'Cambridge',
    displayName: 'Cambridge',
    linkPath: 'cambridge',
  },
  {
    name: 'Canterbury',
    displayName: 'Canterbury',
    linkPath: 'canterbury',
  },
  {
    name: 'Cardiff',
    displayName: 'Cardiff',
    linkPath: 'cardiff',
  },
  {
    name: 'Carlisle',
    displayName: 'Carlisle',
    linkPath: 'carlisle',
  },
  {
    name: 'Carmarthen',
    displayName: 'Carmarthen',
    linkPath: 'carmarthen',
  },
  {
    name: 'Chatham',
    displayName: 'Chatham',
    linkPath: 'chatham',
  },
  {
    name: 'Chelmsford',
    displayName: 'Chelmsford',
    linkPath: 'chelmsford',
  },
  {
    name: 'Cheltenham',
    displayName: 'Cheltenham',
    linkPath: 'cheltenham',
  },
  {
    name: 'Chester',
    displayName: 'Chester',
    linkPath: 'chester',
  },
  {
    name: 'Chichester',
    displayName: 'Chichester',
    linkPath: 'chichester',
  },
  {
    name: 'Colchester',
    displayName: 'Colchester',
    linkPath: 'colchester',
  },
  {
    name: 'Cork',
    displayName: 'Cork',
    linkPath: 'cork',
  },
  {
    name: 'Cornwall',
    displayName: 'Cornwall',
    linkPath: 'cornwall',
  },
  {
    name: 'Coventry',
    displayName: 'Coventry',
    linkPath: 'coventry',
  },
  {
    name: 'Cranfield',
    displayName: 'Cranfield',
    linkPath: 'cranfield',
  },
  {
    name: 'Crewe',
    displayName: 'Crewe',
    linkPath: 'crewe',
  },
  {
    name: 'Derby',
    displayName: 'Derby',
    linkPath: 'derby',
  },
  {
    name: 'Devon',
    displayName: 'Devon',
    linkPath: 'devon',
  },
  {
    name: 'Doncaster',
    displayName: 'Doncaster',
    linkPath: 'doncaster',
  },
  {
    name: 'Dover',
    displayName: 'Dover',
    linkPath: 'dover',
  },
  {
    name: 'Dublin',
    displayName: 'Dublin',
    linkPath: 'dublin',
  },
  {
    name: 'Dumfries',
    displayName: 'Dumfries',
    linkPath: 'dumfries',
  },
  {
    name: 'Dundee',
    displayName: 'Dundee',
    linkPath: 'dundee',
  },
  {
    name: 'Durham',
    displayName: 'Durham',
    linkPath: 'durham',
  },
  {
    name: 'Eastbourne',
    displayName: 'Eastbourne',
    linkPath: 'eastbourne',
  },
  {
    name: 'Edinburgh',
    displayName: 'Edinburgh',
    linkPath: 'edinburgh',
  },
  {
    name: 'Egham',
    displayName: 'Egham',
    linkPath: 'egham',
  },
  {
    name: 'Elgin',
    displayName: 'Elgin',
    linkPath: 'elgin',
  },
  {
    name: 'Epsom',
    displayName: 'Epsom',
    linkPath: 'epsom',
  },
  {
    name: 'Evesham',
    displayName: 'Evesham',
    linkPath: 'evesham',
  },
  {
    name: 'Exeter',
    displayName: 'Exeter',
    linkPath: 'exeter',
  },
  {
    name: 'Falmouth',
    displayName: 'Falmouth',
    linkPath: 'falmouth',
  },
  {
    name: 'Farnham',
    displayName: 'Farnham',
    linkPath: 'farnham',
  },
  {
    name: 'Folkestone',
    displayName: 'Folkestone',
    linkPath: 'folkestone',
  },
  {
    name: 'Gillingham',
    displayName: 'Gillingham',
    linkPath: 'gillingham',
  },
  {
    name: 'Glasgow',
    displayName: 'Glasgow',
    linkPath: 'glasgow',
  },
  {
    name: 'Gloucester',
    displayName: 'Gloucester',
    linkPath: 'gloucester',
  },
  {
    name: 'Guildford',
    displayName: 'Guildford',
    linkPath: 'guildford',
  },
  {
    name: 'Hartlepool',
    displayName: 'Hartlepool',
    linkPath: 'hartlepool',
  },
  {
    name: 'Hastings',
    displayName: 'Hastings',
    linkPath: 'hastings',
  },
  {
    name: 'Hatfield',
    displayName: 'Hatfield',
    linkPath: 'hatfield',
  },
  {
    name: 'Hertford',
    displayName: 'Hertford',
    linkPath: 'hertford',
  },
  {
    name: 'High Wycombe',
    displayName: 'High Wycombe',
    linkPath: 'high-wycombe',
  },
  {
    name: 'Huddersfield',
    displayName: 'Huddersfield',
    linkPath: 'huddersfield',
  },
  {
    name: 'Hull',
    displayName: 'Hull',
    linkPath: 'hull',
  },
  {
    name: 'Ipswich',
    displayName: 'Ipswich',
    linkPath: 'ipswich',
  },
  {
    name: 'Keele',
    displayName: 'Keele',
    linkPath: 'keele',
  },
  {
    name: 'Kent',
    displayName: 'Kent',
    linkPath: 'kent',
  },
  {
    name: 'Kettering',
    displayName: 'Kettering',
    linkPath: 'kettering',
  },
  {
    name: 'Kingston',
    displayName: 'Kingston',
    linkPath: 'kingston',
  },
  {
    name: 'Lanark',
    displayName: 'Lanark',
    linkPath: 'lanark',
  },
  {
    name: 'Lancaster',
    displayName: 'Lancaster',
    linkPath: 'lancaster',
  },
  {
    name: 'Leamington Spa',
    displayName: 'Leamington Spa',
    linkPath: 'leamington-spa',
  },
  {
    name: 'Leeds',
    displayName: 'Leeds',
    linkPath: 'leeds',
  },
  {
    name: 'Leicester',
    displayName: 'Leicester',
    linkPath: 'leicester',
  },
  {
    name: 'Lincoln',
    displayName: 'Lincoln',
    linkPath: 'lincoln',
  },
  {
    name: 'Liverpool',
    displayName: 'Liverpool',
    linkPath: 'liverpool',
  },
  {
    name: 'Livingston',
    displayName: 'Livingston',
    linkPath: 'livingston',
  },
  {
    name: 'London',
    displayName: 'London',
    linkPath: 'london',
  },
  {
    name: 'Loughborough',
    displayName: 'Loughborough',
    linkPath: 'loughborough',
  },
  {
    name: 'Loughton',
    displayName: 'Loughton',
    linkPath: 'loughton',
  },
  {
    name: 'Luton',
    displayName: 'Luton',
    linkPath: 'luton',
  },
  {
    name: 'Maidstone',
    displayName: 'Maidstone',
    linkPath: 'maidstone',
  },
  {
    name: 'Manchester',
    displayName: 'Manchester',
    linkPath: 'manchester',
  },
  {
    name: 'Mansfield',
    displayName: 'Mansfield',
    linkPath: 'mansfield',
  },
  {
    name: 'Medway',
    displayName: 'Medway',
    linkPath: 'medway',
  },
  {
    name: 'Middlesbrough',
    displayName: 'Middlesbrough',
    linkPath: 'middlesbrough',
  },
  {
    name: 'Middlesex',
    displayName: 'Middlesex',
    linkPath: 'middlesex',
  },
  {
    name: 'Milton Keynes',
    displayName: 'Milton Keynes',
    linkPath: 'milton-keynes',
  },
  {
    name: 'Mold',
    displayName: 'Mold',
    linkPath: 'mold',
  },
  {
    name: 'Newcastle',
    displayName: 'Newcastle',
    linkPath: 'newcastle',
  },
  {
    name: 'Newcastle under Lyme',
    displayName: 'Newcastle under Lyme',
    linkPath: 'newcastle-under-lyme',
  },
  {
    name: 'Newport',
    displayName: 'Newport',
    linkPath: 'newport',
  },
  {
    name: 'Newquay',
    displayName: 'Newquay',
    linkPath: 'newquay',
  },
  {
    name: 'Northampton',
    displayName: 'Northampton',
    linkPath: 'northampton',
  },
  {
    name: 'Norwich',
    displayName: 'Norwich',
    linkPath: 'norwich',
  },
  {
    name: 'Nottingham',
    displayName: 'Nottingham',
    linkPath: 'nottingham',
  },
  {
    name: 'Ormskirk',
    displayName: 'Ormskirk',
    linkPath: 'ormskirk',
  },
  {
    name: 'Oxford',
    displayName: 'Oxford',
    linkPath: 'oxford',
  },
  {
    name: 'Paisley',
    displayName: 'Paisley',
    linkPath: 'paisley',
  },
  {
    name: 'Peterborough',
    displayName: 'Peterborough',
    linkPath: 'peterborough',
  },
  {
    name: 'Plymouth',
    displayName: 'Plymouth',
    linkPath: 'plymouth',
  },
  {
    name: 'Pontypridd',
    displayName: 'Pontypridd',
    linkPath: 'pontypridd',
  },
  {
    name: 'Poole',
    displayName: 'Poole',
    linkPath: 'poole',
  },
  {
    name: 'Portrush',
    displayName: 'Portrush',
    linkPath: 'portrush',
  },
  {
    name: 'Portsmouth',
    displayName: 'Portsmouth',
    linkPath: 'portsmouth',
  },
  {
    name: 'Preston',
    displayName: 'Preston',
    linkPath: 'preston',
  },
  {
    name: 'Reading',
    displayName: 'Reading',
    linkPath: 'reading',
  },
  {
    name: 'Rochester',
    displayName: 'Rochester',
    linkPath: 'rochester',
  },
  {
    name: 'Salford',
    displayName: 'Salford',
    linkPath: 'salford',
  },
  {
    name: 'Salisbury',
    displayName: 'Salisbury',
    linkPath: 'salisbury',
  },
  {
    name: 'Scarborough',
    displayName: 'Scarborough',
    linkPath: 'scarborough',
  },
  {
    name: 'Sheffield',
    displayName: 'Sheffield',
    linkPath: 'sheffield',
  },
  {
    name: 'Shoreham-by-sea',
    displayName: 'Shoreham-by-sea',
    linkPath: 'shoreham-by-sea',
  },
  {
    name: 'Slough',
    displayName: 'Slough',
    linkPath: 'slough',
  },
  {
    name: 'Southampton',
    displayName: 'Southampton',
    linkPath: 'southampton',
  },
  {
    name: 'Southend on Sea',
    displayName: 'Southend on Sea',
    linkPath: 'southend-on-sea',
  },
  {
    name: 'Southport',
    displayName: 'Southport',
    linkPath: 'southport',
  },
  {
    name: 'St Albans',
    displayName: 'St Albans',
    linkPath: 'st-albans',
  },
  {
    name: 'St Andrews',
    displayName: 'St Andrews',
    linkPath: 'st-andrews',
  },
  {
    name: 'St Helens',
    displayName: 'St Helens',
    linkPath: 'st-helens',
  },
  {
    name: 'Stafford',
    displayName: 'Stafford',
    linkPath: 'stafford',
  },
  {
    name: 'Stevenage',
    displayName: 'Stevenage',
    linkPath: 'stevenage',
  },
  {
    name: 'Stirling',
    displayName: 'Stirling',
    linkPath: 'stirling',
  },
  {
    name: 'Stockton',
    displayName: 'Stockton',
    linkPath: 'stockton',
  },
  {
    name: 'Stoke',
    displayName: 'Stoke',
    linkPath: 'stoke',
  },
  {
    name: 'Stratford',
    displayName: 'Stratford',
    linkPath: 'stratford',
  },
  {
    name: 'Sunderland',
    displayName: 'Sunderland',
    linkPath: 'sunderland',
  },
  {
    name: 'Surbiton',
    displayName: 'Surbiton',
    linkPath: 'surbiton',
  },
  {
    name: 'Surrey',
    displayName: 'Surrey',
    linkPath: 'surrey',
  },
  {
    name: 'Swansea',
    displayName: 'Swansea',
    linkPath: 'swansea',
  },
  {
    name: 'Swindon',
    displayName: 'Swindon',
    linkPath: 'swindon',
  },
  {
    name: 'Tamworth',
    displayName: 'Tamworth',
    linkPath: 'tamworth',
  },
  {
    name: 'Taunton',
    displayName: 'Taunton',
    linkPath: 'taunton',
  },
  {
    name: 'Truro',
    displayName: 'Truro',
    linkPath: 'truro',
  },
  {
    name: 'Uxbridge',
    displayName: 'Uxbridge',
    linkPath: 'uxbridge',
  },
  {
    name: 'Wakefield',
    displayName: 'Wakefield',
    linkPath: 'wakefield',
  },
  {
    name: 'Walsall',
    displayName: 'Walsall',
    linkPath: 'walsall',
  },
  {
    name: 'Warwick',
    displayName: 'Warwick',
    linkPath: 'warwick',
  },
  {
    name: 'Watford',
    displayName: 'Watford',
    linkPath: 'watford',
  },
  {
    name: 'Welwyn Garden City',
    displayName: 'Welwyn Garden City',
    linkPath: 'welwyn-garden-city',
  },
  {
    name: 'Wick',
    displayName: 'Wick',
    linkPath: 'wick',
  },
  {
    name: 'Wigan',
    displayName: 'Wigan',
    linkPath: 'wigan',
  },
  {
    name: 'Winchester',
    displayName: 'Winchester',
    linkPath: 'winchester',
  },
  {
    name: 'Windsor',
    displayName: 'Windsor',
    linkPath: 'windsor',
  },
  {
    name: 'Wolverhampton',
    displayName: 'Wolverhampton',
    linkPath: 'wolverhampton',
  },
  {
    name: 'Worcester',
    displayName: 'Worcester',
    linkPath: 'worcester',
  },
  {
    name: 'Worthing',
    displayName: 'Worthing',
    linkPath: 'worthing',
  },
  {
    name: 'Wrexham',
    displayName: 'Wrexham',
    linkPath: 'wrexham',
  },
  {
    name: 'York',
    displayName: 'York',
    linkPath: 'york',
  },
]
