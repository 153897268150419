import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Auth } from 'aws-amplify'

import { getPropertyById } from '../../api/propertyApi'
import apiToFinalFormPropertyAdapter from '../adapters/apiToFinalFormPropertyAdapter'
import { pageConfig } from '../pages/EditPropertyFormPage/config/editPropertyPageConfig'
import pages from '../../models/pages'
import { useStateContext } from '../../appStateContext'

import LoadingPage from '../pages/LoadingPage'
import EditFormNavigation from '../pages/EditFormNavigation'

const EditFormContainer = ({ history, match }) => {
  const propertyId = match.params.id
  const editConfig = pageConfig(propertyId)

  const [property, setProperty] = useState({})
  const [loading, setLoading] = useState(true)
  const [addPropertyFormPages] = useState(pages(editConfig))

  const getProperty = () => {
    Auth.currentSession().then(data => {
      const { jwtToken } = data.idToken
      getPropertyById(propertyId, jwtToken).then(response => {
        const mappedProperty = apiToFinalFormPropertyAdapter(response.property)
        setProperty(mappedProperty)
        setLoading(false)
      })
    })
  }

  useEffect(() => {
    getProperty()
    const unListen = history.listen(location => {
      if (location.pathname.indexOf('review') > -1) {
        getProperty()
      }
    })

    return () => unListen()
  }, [])

  if (loading) return <LoadingPage />

  return (
    <EditFormNavigation
      className="add-property__form"
      initialValues={property}
      pages={addPropertyFormPages}
      history={history}
      propertyId={propertyId}
      auth={Auth}
      useStateContext={useStateContext}
    />
  )
}

EditFormContainer.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
}

export default EditFormContainer
