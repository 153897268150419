import React from 'react'
import PropTypes from 'prop-types'
import Field from '@afs/components/Field'

const FieldTextDateAdapter = ({
  input: { name, onChange, value, onBlur },
  label,
  className,
  meta,
}) => {
  const isInvalid = meta.error && meta.touched
  const validated = !meta.error && meta.touched
  return (
    <Field
      className={className}
      name={name}
      label={label}
      value={value}
      placeholder="DD/MM/YYYY"
      onChange={onChange}
      onBlur={event => onBlur(event)}
      invalid={isInvalid}
      error={meta.error}
      validated={validated}
    />
  )
}

FieldTextDateAdapter.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
  meta: PropTypes.object,
}

export default FieldTextDateAdapter
