import { useState } from 'react'

export const Filtering = ({
  originalData,
  setFilteredData,
  onRemoveAll,
  applyTogether,
  children,
}) => {
  const [filters, setFilters] = useState({})

  const removeAll = () => {
    const removedFilters = {
      ...filters,
    }

    Object.keys(removedFilters).forEach(filterId => {
      removedFilters[filterId].isApplied = false
      removedFilters[filterId].isSelected = false
    })

    setFilteredData(originalData)
    setFilters(removedFilters)

    onRemoveAll.forEach(callback => callback())
  }

  const filterDataWith = filters => {
    let filteredDataSet = originalData

    Object.keys(filters).forEach(filterId => {
      const filterToApply = filters[filterId]
      filteredDataSet = filteredDataSet.filter(filterToApply.condition)
    })

    setFilteredData(filteredDataSet)
  }

  const degroup = filters => {
    const groupSet = Object.keys(filters)
      .filter(key => filters[key].group !== undefined)
      .reduce((groups, key) => {
        return groups.add(filters[key].group)
      }, new Set())

    const uniqueGroups = [...groupSet]

    const filtersWithGroupAssigned = Object.keys(filters)
      .map(filterId => {
        const filter = filters[filterId]
        return filter.group ? filter : undefined
      })
      .filter(object => object !== undefined)

    const idsOfFiltersWithoutGroupAssigned = Object.keys(filters)
      .map(filterId => {
        const filter = filters[filterId]
        return filter.group === undefined ? filterId : undefined
      })
      .filter(object => object !== undefined)

    const filtersWithoutGroupAssigned = Object.keys(filters).reduce(
      (filtersAccumulator, filterId) => {
        if (idsOfFiltersWithoutGroupAssigned.includes(filterId)) {
          filtersAccumulator[filterId] = filters[filterId]
        }

        return filtersAccumulator
      },
      {}
    )

    const combinedGroupFilters = uniqueGroups.reduce((filtersAccumulator, groupName) => {
      const filtersAssignedToThisGroup = filtersWithGroupAssigned
        .map(filter => {
          return filter.group === groupName ? filter : undefined
        })
        .filter(object => object !== undefined)

      const conditionsInThisGroup = filtersAssignedToThisGroup.map(filter => filter.condition)

      filtersAccumulator[groupName] = {
        condition: input => {
          return conditionsInThisGroup.some(condition => condition(input))
        },
      }

      return filtersAccumulator
    }, {})

    return {
      ...combinedGroupFilters,
      ...filtersWithoutGroupAssigned,
    }
  }

  const applySelected = () => {
    const updatedFilters = {
      ...filters,
    }

    Object.keys(updatedFilters).forEach(filterId => {
      updatedFilters[filterId].isApplied = updatedFilters[filterId].isSelected
    })

    const appliedFilters = Object.keys(updatedFilters)
      .map(filterId => {
        return updatedFilters[filterId].isApplied ? updatedFilters[filterId] : undefined
      })
      .filter(object => object !== undefined)

    filterDataWith(degroup(appliedFilters))
    setFilters(updatedFilters)
  }

  const isFilterCurrentlyApplied = filterId => {
    return filters[filterId] ? filters[filterId].isApplied : false
  }

  const isFilterCurrentlySelected = filterId => {
    return filters[filterId] ? filters[filterId].isSelected : false
  }

  const apply = (filterId, group, condition, isAlwaysApplied = false) => {
    let updatedFilters = {
      ...filters,
    }

    if (!isAlwaysApplied && isFilterCurrentlyApplied(filterId)) {
      updatedFilters = {
        ...filters,
        [filterId]: {
          ...filters[filterId],
          isApplied: false,
          group,
          condition,
        },
      }
    } else {
      updatedFilters = {
        ...filters,
        [filterId]: {
          ...filters[filterId],
          isApplied: true,
          group,
          condition,
        },
      }
    }

    setFilters(updatedFilters)

    const appliedFilters = Object.keys(updatedFilters)
      .map(filterId => {
        return updatedFilters[filterId].isApplied ? updatedFilters[filterId] : undefined
      })
      .filter(object => object !== undefined)

    filterDataWith(degroup(appliedFilters))
  }

  const resetSelection = () => {
    const updatedFilters = {
      ...filters,
    }

    Object.keys(updatedFilters).forEach(filterId => {
      updatedFilters[filterId].isSelected = updatedFilters[filterId].isApplied
    })

    setFilters(updatedFilters)
  }

  const select = (filterId, group, condition, isAlwaysSelected = false) => {
    let updatedFilters

    if (!isAlwaysSelected && isFilterCurrentlySelected(filterId)) {
      updatedFilters = {
        ...filters,
        [filterId]: {
          ...filters[filterId],
          isSelected: false,
          group,
          condition,
        },
      }
    } else {
      updatedFilters = {
        ...filters,
        [filterId]: {
          ...filters[filterId],
          isSelected: true,
          group,
          condition,
        },
      }
    }

    setFilters(updatedFilters)
  }

  const alwaysApplyFilter = (filterId, condition) => {
    apply(filterId, undefined, condition, true)
    select(filterId, undefined, condition, true)
  }

  return children({
    apply,
    applySelected,
    alwaysApplyFilter,
    applyTogether,
    isApplied: isFilterCurrentlyApplied,
    isSelected: isFilterCurrentlySelected,
    removeAll,
    select,
    resetSelection,
  })
}

export const Filter = ({
  group,
  condition,
  apply,
  alwaysApply,
  alwaysApplyFilter,
  isApplied,
  applyTogether,
  isSelected,
  select,
  children,
}) => {
  const createFilterId = () => `
    ${Date.now().toString(36)}
    ${Math.random().toString(36)}
    ${Math.random().toString(36)}`

  const [filterId] = useState(createFilterId())

  const applyFilter = () => {
    if (alwaysApply) {
      alwaysApplyFilter(filterId, condition)
      return
    }
    if (applyTogether) {
      select(filterId, group, condition)
      return
    }
    apply(filterId, group, condition)
  }

  return children({
    filterId,
    isApplied: isApplied(filterId),
    isSelected: isSelected(filterId),
    applyFilter,
    applyTogether,
  })
}
