import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Input from '@afs/components/Input'
import Label from '@afs/components/Label'

import PlusIcon from './svgs/plus.svg'
import MinusIcon from './svgs/minus.svg'

import './styles.scss'

const Error = ({ children, ...props }) => <span {...props}>{children}</span>

const FieldIncrementCount = ({
  className,
  label,
  name,
  invalid,
  error,
  disabled,
  handleIncrementClick,
  handleDecrementClick,
  handleOnKeyDown,
  min,
  max,
  currentNumber,
}) => {
  const componentClasses = classNames('form-increment-count', className)
  const wrapperClasses = classNames('form-increment-count__inner', {
    'form-increment-count__inner--invalid': invalid,
    'form-increment-count__inner--disabled': disabled,
  })

  return (
    <div className={componentClasses}>
      <div className="form-increment-count__wrapper">
        <Label className="form-increment-count__label" htmlFor={name}>
          {label}
        </Label>

        <div className={wrapperClasses}>
          <button
            className="form-increment-count__button form-increment-count__button--minus"
            disabled={currentNumber === 0 ? true : undefined}
            onClick={() => {
              if (currentNumber > min) handleDecrementClick()
            }}
            type="button"
            tabIndex="-1"
            data-testid="decrement-count"
          >
            <MinusIcon className="form-increment-count__button__icon" />
            <span className="form-increment-count__button__text">Less rooms</span>
          </button>

          <Input
            id={name}
            className="form-increment-count__input"
            name={name}
            type="number"
            step={1}
            min={min}
            max={max}
            value={currentNumber.toString()}
            onChange={() => {}}
            disabled={disabled || undefined}
            onKeyDown={(event) => {
              const { keyCode, key } = event
              const qwertyNumberKeys = keyCode >= 48 && keyCode <= 57
              const numberPadKeys = keyCode >= 96 && keyCode <= 105
              const upArrowKeyPressed = keyCode === 38
              const downArrowKeyPressed = keyCode === 40
              const notAValidKey = !(
                qwertyNumberKeys ||
                numberPadKeys ||
                upArrowKeyPressed ||
                downArrowKeyPressed
              )

              let newNumber = Number(key)

              if (notAValidKey) return
              if (currentNumber === newNumber) return

              if (upArrowKeyPressed && currentNumber < max) {
                newNumber = currentNumber + 1
              }

              if (downArrowKeyPressed && currentNumber > 0) {
                newNumber = currentNumber - 1
              }

              handleOnKeyDown(currentNumber, newNumber)
            }}
            aria-describedby={invalid && error ? `${name}Error` : undefined}
          />

          <button
            className="form-increment-count__button form-increment-count__button--plus"
            onClick={() => {
              if (currentNumber < max) {
                handleIncrementClick()
              }
            }}
            type="button"
            tabIndex="-1"
            data-testid="increment-count"
            disabled={currentNumber === max ? true : undefined}
          >
            <PlusIcon className="form-increment-count__button__icon" />
            <span className="form-increment-count__button__text">More rooms</span>
          </button>
        </div>
      </div>

      {invalid && error && (
        <Error id={`${name}Error`} className="form-field__error" role="alert">
          {error}
        </Error>
      )}
    </div>
  )
}

Error.propTypes = {
  children: PropTypes.any,
}

FieldIncrementCount.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  invalid: PropTypes.bool,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  handleIncrementClick: PropTypes.func.isRequired,
  handleDecrementClick: PropTypes.func.isRequired,
  currentNumber: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number,
  handleOnKeyDown: PropTypes.func,
}

export default FieldIncrementCount
