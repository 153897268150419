import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import FieldIncrementCount from '../molecules/FieldIncrementCount'
import BedroomCard from '../molecules/BedroomCard'
import Overlay from '../molecules/Overlay'
import EditBedroomsForm from '../organisms/EditBedroomsForm'
import Notification from '../molecules/Notification'
import { FadeInOutGroup } from '../transitions/FadeInOut'

import './styles/fieldBedroomsAdapter.scss'

import bedroomAdapterMode from './bedroomAdapterMode'

const FieldBedroomsAdapter = ({
  input: { onChange, value },
  label,
  min,
  max,
  error,
  propertyId,
  propertyType,
  bedroomApi,
  updatePropertyBedrooms,
  notificationService,
  jwtToken,
}) => {
  const [overlay, setOverlay] = useState({
    active: false,
    bedroomFormInitialState: null,
    updateBedroomCardState: null,
  })
  const [isSavingBedrooms, setIsSavingBedrooms] = useState(false)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    return () => {
      const validBedrooms = value.filter((bedroom) => bedroom.valid)
      onChange(validBedrooms)
    }
  }, [])

  const mode = bedroomApi ? bedroomAdapterMode.EDIT : bedroomAdapterMode.ADD

  const currentNumber = value.length
  const propertyIsAStudio = propertyType === 'Studio'

  const nextBedroomState = (bedroom) => {
    return value.map((b) => (b.position === bedroom.position ? bedroom : b))
  }

  const saveBedroomChangesToFinalFormState = (bedroom) => {
    onChange(nextBedroomState(bedroom))
  }

  const addBedroom = (currentBedrooms) => {
    return [
      ...currentBedrooms,
      { position: currentBedrooms.length + 1, valid: false, rent: {}, deposit: { amount: 0 } },
    ]
  }

  const removeBedroom = (numberOfBedrooms) => {
    const copy = [...value]
    copy.splice(-numberOfBedrooms)
    return copy
  }

  const copyPreviousBedroom = (bedroomPosition) => {
    notificationService.clearNotifications()

    const previousBedroom = value.find((b) => b.position === bedroomPosition - 1)

    const { id, ...previousBedroomWithoutId } = previousBedroom

    return {
      ...previousBedroomWithoutId,
      position: bedroomPosition,
    }
  }

  const saveCopiedBedroom = async (bedroom) => {
    const payload = {
      propertyId,
      bedroom,
    }

    return bedroomApi.addBedroom(payload, jwtToken)
  }

  const handleOverlayClose = () => {
    setShowError(false)
    setOverlay({
      active: false,
      bedroomFormInitialState: null,
      updateBedroomCardState: null,
    })
  }

  const overlayHandleSaveBedroom = async (bedroomToSaveFromOverlay) => {
    if (mode === bedroomAdapterMode.ADD) {
      overlay.updateBedroomCardState(bedroomToSaveFromOverlay)
      saveBedroomChangesToFinalFormState(bedroomToSaveFromOverlay)

      setOverlay({
        active: false,
        bedroomFormInitialState: null,
        updateBedroomCardState: null,
      })
    }

    if (mode === bedroomAdapterMode.EDIT) {
      const nextState = nextBedroomState(bedroomToSaveFromOverlay)

      const validBedroomsInFinalFormState = nextState.filter((bedroom) => bedroom.valid)

      setIsSavingBedrooms(true)

      const payload = {
        propertyId,
        bedrooms: validBedroomsInFinalFormState,
      }

      const response = await updatePropertyBedrooms(payload, jwtToken)

      if (response.success) {
        overlay.updateBedroomCardState(bedroomToSaveFromOverlay)
        onChange(nextState)

        setIsSavingBedrooms(false)

        setOverlay({
          active: false,
          bedroomFormInitialState: null,
          updateBedroomCardState: null,
        })

        const notificationText = propertyIsAStudio
          ? 'Studio has been successfully updated.'
          : `Bedroom ${bedroomToSaveFromOverlay.position} has been successfully updated.`

        notificationService.showSuccessNotification('', notificationText)
      } else {
        setIsSavingBedrooms(false)
        setShowError(true)
      }
    }
  }

  const showEditOverlay = (bedroomFormInitialState, updateBedroomCardState) => {
    notificationService.clearNotifications()
    setShowError(false)
    setOverlay({
      active: true,
      bedroomFormInitialState,
      updateBedroomCardState,
    })
  }

  if (propertyIsAStudio && value.length === 0) {
    onChange(addBedroom(value))
  }

  if (propertyIsAStudio && value.length > 1) {
    const difference = value.length - 1
    const newBedrooms = removeBedroom(difference)
    onChange(newBedrooms)
  }

  const renderEditOverlay = () => {
    if (!overlay.bedroomFormInitialState) {
      return null
    }

    const notificationText = propertyIsAStudio
      ? 'Studio could not be updated right now. Please try again later.'
      : `Bedroom ${overlay.bedroomFormInitialState.position} could not be updated right now. Please try again later.`

    return (
      <Overlay
        id="edit-bedroom"
        heading={
          propertyIsAStudio ? 'Studio' : `Bedroom ${overlay.bedroomFormInitialState.position}`
        }
        closeLabel="Close"
        isActive={overlay.active}
        onClose={handleOverlayClose}
        preserveScrollPositionOnClose
        dontDisplayNotifications
      >
        {showError && (
          <Notification
            className="edit-bedrooms-overlay__notification"
            heading="Error."
            text={notificationText}
          />
        )}
        <EditBedroomsForm
          bedroom={overlay.bedroomFormInitialState}
          handleSaveBedroom={(newBedroom) => overlayHandleSaveBedroom(newBedroom)}
          isStudio={propertyIsAStudio}
          isSavingBedrooms={isSavingBedrooms}
        />
      </Overlay>
    )
  }

  return (
    <div>
      {!propertyIsAStudio && (
        <FieldIncrementCount
          className="field-bedrooms-adapter__counter"
          label={label}
          name="numberOfRooms"
          handleIncrementClick={() => onChange(addBedroom(value))}
          handleDecrementClick={() => onChange(removeBedroom(1))}
          currentNumber={currentNumber}
          min={min}
          max={max}
          error={error}
          handleOnKeyDown={(currentNumber, newNumber) => {
            let newBedrooms = value

            if (newNumber > currentNumber) {
              const difference = newNumber - currentNumber

              for (let i = 0; i < difference; i += 1) {
                newBedrooms = addBedroom(newBedrooms)
              }
            }

            if (newNumber < currentNumber) {
              const difference = currentNumber - newNumber
              newBedrooms = removeBedroom(difference)
            }

            onChange(newBedrooms)
          }}
        />
      )}
      <FadeInOutGroup
        renderItems={(fadeInOutItem) =>
          value.map((bedroom, index) => {
            const firstInvalidBedroom = value.find((bedroom) => !bedroom.valid)
            const isActive = value.indexOf(firstInvalidBedroom) === index

            return fadeInOutItem(
              <BedroomCard
                bedroomNumber={index + 1}
                active={isActive}
                bedroomFormValues={bedroom}
                handleBedroomChange={(bedroom) => saveBedroomChangesToFinalFormState(bedroom)}
                copyPreviousBedroom={(bedroomPosition) => copyPreviousBedroom(bedroomPosition)}
                showEditOverlay={showEditOverlay}
                isStudio={propertyIsAStudio}
                saveCopiedBedroom={saveCopiedBedroom}
                mode={mode}
                notificationService={notificationService}
              />,
              `bedroom-${index}`
            )
          })
        }
      />
      {renderEditOverlay()}
    </div>
  )
}

FieldBedroomsAdapter.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  error: PropTypes.string,
  propertyId: PropTypes.string,
  propertyType: PropTypes.string,
  bedroomApi: PropTypes.object,
  updatePropertyBedrooms: PropTypes.func,
  notificationService: PropTypes.object,
  jwtToken: PropTypes.string,
}

export default FieldBedroomsAdapter
