import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SpinnerButton from '@afs/components/SpinnerButton'
import Field from '@afs/components/Field'

import LinkButton from '../../atoms/LinkButton'
import Overlay from '../Overlay'
import Notification from '../Notification/index'

import AddressReview from '../AddressReview'
import SelectAddressForm from './forms/SelectAddressForm'
import EditAddressForm from './forms/EditAddressForm'
import { validatePostcode } from '../../../models/address'

import './styles.scss'

const FieldPropertyAddress = ({
  existingAddress,
  addressService,
  notificationService,
  onUpdateAddress,
}) => {
  const [method, setMethod] = useState('auto')
  const [postcodeNotFound, setPostcodeNotFound] = useState()
  const [postcode, setPostcode] = useState({
    value: existingAddress ? existingAddress.postcode : '',
    isValid: false,
    error: 'Please enter a valid postcode.',
    showError: false,
  })
  const [overlay, setOverlay] = useState({
    active: false,
    results: [],
    error: '',
  })
  const [loading, setLoading] = useState(false)

  const handleOverlayClose = () => {
    setOverlay({
      results: [],
      active: false,
      error: '',
    })
    notificationService.clearNotifications()
  }

  const handleChange = event => {
    const field = event.target
    const { value } = field
    const isPostcodeValid = validatePostcode(value)

    setPostcode(prevValue => ({
      ...prevValue,
      value,
      isValid: isPostcodeValid,
      showError: !isPostcodeValid,
    }))
  }

  const onFindAddress = async event => {
    event.preventDefault()
    setPostcodeNotFound()
    setLoading(true)

    if (postcode.isValid) {
      setMethod('auto')

      // remove focus from postcode field on opening the overlay
      document.activeElement.blur()

      const response = await addressService.getAddress(encodeURI(postcode.value.trim()))

      if (response) {
        if (response.success) {
          setMethod('auto')

          setOverlay({
            results: response.addresses,
            active: true,
            error: '',
          })
        }

        if (response.status === 400) {
          setPostcodeNotFound(response.message)
        }

        if (response.status === 404) {
          setMethod('manual')

          setOverlay({
            results: [],
            active: true,
            error: response.message,
          })
        }
      }

      setLoading(false)
    } else {
      setPostcode(prevValue => ({
        ...prevValue,
        showError: true,
      }))

      setLoading(false)
    }
  }

  const onEditManually = (event, address) => {
    event.preventDefault()

    if (address) {
      onUpdateAddress(address)
    }

    setMethod('manual')

    setOverlay(prevState => ({
      ...prevState,
      active: true,
    }))
  }

  const onSelectAddress = address => {
    onUpdateAddress(address)
    handleOverlayClose()
  }

  const renderSelectAddressForm = () => (
    <SelectAddressForm
      addresses={overlay.results}
      existingAddress={existingAddress}
      onSelectAddress={onSelectAddress}
      onEditManually={onEditManually}
    />
  )

  const renderEditAddressForm = () => (
    <EditAddressForm
      existingAddress={existingAddress}
      onSelectAddress={onSelectAddress}
      notificationService={notificationService}
      showError={overlay.error}
    />
  )

  const renderFindAddressForm = () => {
    return (
      <div className="field-property-address__find-form">
        <Field
          label="Postcode"
          name="find-postcode"
          onChange={handleChange}
          value={postcode.value || ''}
          invalid={postcode.showError}
          error={postcode.showError ? postcode.error : undefined}
          validated={postcode && postcode.isValid}
          data-testid="postcode-input"
        />
        <SpinnerButton
          className="field-property-address__find-button"
          onClick={onFindAddress}
          inactive={loading}
          dark
          data-testid="find-address-button"
        >
          Find address
        </SpinnerButton>
        <LinkButton onClick={event => onEditManually(event)} data-testid="enter-manually-button">
          Enter address manually
        </LinkButton>
      </div>
    )
  }

  const renderReviewAddress = () => (
    <AddressReview
      address={existingAddress}
      callback={onEditManually}
      data-testid="review-address"
    />
  )

  return (
    <>
      {postcodeNotFound && (
        <Notification
          text={postcodeNotFound}
          className="field-upload__notifications-item"
          data-testid="postcode-not-found-notification"
          heading="Postcode not found."
        />
      )}
      <div className="field-property-address">
        {existingAddress && existingAddress.isValid()
          ? renderReviewAddress()
          : renderFindAddressForm()}
      </div>
      <Overlay
        id="address"
        className={`${method === 'auto' ? 'field-property-address__sticky-overlay' : undefined}`}
        heading={`${method === 'auto' ? 'Select' : 'Enter'} your address`}
        isActive={overlay.active}
        onClose={handleOverlayClose}
      >
        {method === 'auto' ? renderSelectAddressForm() : renderEditAddressForm()}
      </Overlay>
    </>
  )
}

FieldPropertyAddress.propTypes = {
  existingAddress: PropTypes.object,
  addressService: PropTypes.shape({
    getAddress: PropTypes.func.isRequired,
  }),
  notificationService: PropTypes.shape({
    showErrorNotification: PropTypes.func.isRequired,
    clearNotifications: PropTypes.func,
  }),
  onUpdateAddress: PropTypes.func.isRequired,
}

export default FieldPropertyAddress
