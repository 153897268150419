import { performApiGet } from './requests'

export const getInvoiceByNumber = async (invoiceNumber, token) => {
  try {
    const response = await performApiGet(`/invoice/${invoiceNumber}`, token)
    return {
      success: response.status === 200,
      invoice: response.data,
    }
  } catch (error) {
    return {
      success: false,
      notFound: error.response.status === 404,
      serverError: error.response.status === 500,
    }
  }
}

export const getAllInvoices = async token => {
  try {
    const response = await performApiGet(`/invoice`, token)
    return {
      success: response.status === 200,
      invoices: response.data,
    }
  } catch ({ response }) {
    return {
      success: false,
    }
  }
}
