import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { getNonBillsOptionalProperties } from '../../api/billsOptionalApi'
import { useAuthContext } from '../../authContext'
import { useStateContext } from '../../appStateContext'
import { logEvent } from '../../logger'
import requestStatus from '../../constants/requestStatus'

import LoadingPage from '../pages/LoadingPage'
import ErrorPage from '../pages/ErrorPage'
import BillsOptionalSelectPropertiesPage from '../pages/BillsOptionalSelectPropertiesPage'

export const BillsOptionalSelectPropertiesPageContainer = ({
  history,
  selectPropertiesPage,
  loadingPage,
  errorPage,
  getNonBillsOptionalProperties,
  useAuthContext,
  useStateContext,
}) => {
  const { token } = useAuthContext()

  const { billsOptional } = useStateContext()

  const initialSelectedPropertyIds = billsOptional.properties().map(property => property.propertyId)

  const [apiStatus, setApiStatus] = useState(requestStatus.waiting)
  const [properties, setProperties] = useState([])
  const [statusCode, setStatusCode] = useState('')
  const [selectedPropertyIds, setSelectedPropertyIds] = useState(initialSelectedPropertyIds)

  useEffect(() => {
    getNonBillsOptionalProperties(token).then(response => {
      if (response.success) {
        setApiStatus(requestStatus.success)
        setProperties(response.properties)
        setStatusCode(response.statusCode)

        logEvent('Fused Properties Selection', {
          'Number of eligible properties': response.properties.length,
        })
      } else {
        setApiStatus(requestStatus.error)
      }
    })
  }, [])

  const toggleSelectProperty = propertyId => {
    if (selectedPropertyIds.includes(propertyId)) {
      const selectedPropertyIdsWithoutId = selectedPropertyIds.filter(id => id !== propertyId)

      setSelectedPropertyIds(selectedPropertyIdsWithoutId)
    } else {
      const selectedPropertyIdsWithId = [...selectedPropertyIds, propertyId]

      setSelectedPropertyIds(selectedPropertyIdsWithId)
    }
  }

  const selectAllProperties = () => {
    const allPropertyIds = properties.map(property => property.propertyId)

    setSelectedPropertyIds(allPropertyIds)
  }

  const deselectAllProperties = () => {
    setSelectedPropertyIds([])
  }

  const handleSubmit = () => {
    if (selectedPropertyIds.length === 0) {
      return
    }

    const propertiesFromSelectedIds = properties.filter(property =>
      selectedPropertyIds.includes(property.propertyId)
    )

    billsOptional.selectProperties(propertiesFromSelectedIds)

    history.push('/landlord-admin/bills-optional/quotes')
  }

  if (apiStatus === requestStatus.error) {
    return errorPage()
  }

  if (apiStatus === requestStatus.success) {
    return selectPropertiesPage(
      history,
      properties,
      statusCode,
      selectedPropertyIds,
      toggleSelectProperty,
      selectAllProperties,
      deselectAllProperties,
      handleSubmit
    )
  }

  return loadingPage()
}

BillsOptionalSelectPropertiesPageContainer.propTypes = {
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
  selectPropertiesPage: PropTypes.func.isRequired,
  loadingPage: PropTypes.func.isRequired,
  errorPage: PropTypes.func.isRequired,
  getNonBillsOptionalProperties: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
  useStateContext: PropTypes.func.isRequired,
}

export const BillsOptionalSelectPropertiesPageContainerCompositionRoot = ({ history }) => {
  return (
    <BillsOptionalSelectPropertiesPageContainer
      history={history}
      selectPropertiesPage={(
        history,
        properties,
        statusCode,
        selectedPropertyIds,
        toggleSelectProperty,
        selectAllProperties,
        deselectAllProperties,
        handleSubmit
      ) => (
        <BillsOptionalSelectPropertiesPage
          history={history}
          properties={properties}
          statusCode={statusCode}
          selectedPropertyIds={selectedPropertyIds}
          toggleSelectProperty={toggleSelectProperty}
          selectAllProperties={selectAllProperties}
          deselectAllProperties={deselectAllProperties}
          handleSubmit={handleSubmit}
        />
      )}
      loadingPage={() => <LoadingPage />}
      errorPage={() => <ErrorPage />}
      getNonBillsOptionalProperties={getNonBillsOptionalProperties}
      useAuthContext={useAuthContext}
      useStateContext={useStateContext}
    />
  )
}

BillsOptionalSelectPropertiesPageContainerCompositionRoot.propTypes = {
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired, push: PropTypes.func.isRequired })
    .isRequired,
}
