import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const LandlordAndLettingAgentHomePageContainer = ({
  getLandlord,
  getLandlordHasEverAdvertisedAProperty,
  homePage,
  loadingPage,
  useAuthContext,
  useStateContext,
  redirect,
}) => {
  const [serverError, setServerError] = useState(false)
  const [landlord, setLandlord] = useState({})
  const [loading, setLoading] = useState(true)
  const [accountHasAdvertised, setAccountHasAdvertised] = useState(null)

  const { token, user, accountType, billsOptionalSettings } = useAuthContext()
  const { propertyIds, managedAccount } = useStateContext()
  const isLettingAgent = accountType === 'LettingAgent'

  useEffect(() => {
    propertyIds.deselectAll()

    getLandlord(token).then(response => {
      if (response.success) {
        setLandlord(response.landlord)

        if (user.isAdmin()) {
          managedAccount.setAccountDetails(
            response.landlord.fullNameString(),
            response.landlord.emailAddressString()
          )
        }

        getLandlordHasEverAdvertisedAProperty(token).then(response => {
          if (response.success) {
            setAccountHasAdvertised(response.hasAdvertisedProperties)
            setLoading(false)
          } else {
            setServerError(true)
          }
        })
      } else {
        setServerError(true)
      }
    })
  }, [])

  if (serverError) return redirect('/landlord-admin/error')
  if (loading) return loadingPage()

  return homePage(
    landlord.fullNameString(),
    accountHasAdvertised,
    isLettingAgent,
    billsOptionalSettings.hasBillsOptionalProperties()
  )
}

LandlordAndLettingAgentHomePageContainer.propTypes = {
  getLandlord: PropTypes.func.isRequired,
  getLandlordHasEverAdvertisedAProperty: PropTypes.func.isRequired,
  homePage: PropTypes.func.isRequired,
  loadingPage: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
  useStateContext: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
}

export default LandlordAndLettingAgentHomePageContainer
