import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Auth } from 'aws-amplify'

import PageTemplate from '../../templates/PageTemplate'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import LoginForm from '../../organisms/LoginForm'

import './styles.scss'

const AdminLoginPage = ({ className, history, loginFormViewModel, notificationService }) => {
  const componentClasses = classNames('login', className)

  useEffect(() => {
    Auth.currentSession()
      .then(session => {
        if (session.idToken.payload.master_admin_sub) {
          Auth.signOut()
        }
      })
      .catch(() => {})
  })

  return (
    <PageTemplate
      className={componentClasses}
      header={<Header />}
      footer={<Footer />}
      title="Login | AFS"
    >
      <div className="login__row">
        <div className="login__inner">
          <div className="login__content">
            <LoginForm
              className="login__form"
              history={history}
              viewModel={loginFormViewModel}
              verificationLinkForm={() => {}}
              notificationService={notificationService}
              redirectToWelcomePage={() => {}}
              data-testid="admin-login-form"
              pathToRedirectToAfterLogin={''}
            />
          </div>
        </div>
      </div>
    </PageTemplate>
  )
}

AdminLoginPage.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object.isRequired,
  loginFormViewModel: PropTypes.func.isRequired,
  notificationService: PropTypes.object.isRequired,
}

export default AdminLoginPage
