import React from 'react'

import { useAuthContext } from '../../../../authContext'
import { useStateContext } from '../../../../appStateContext'
import { notificationService } from '../../../../container'
import { deletePropertyById, getPropertyById } from '../../../../api/propertyApi'

import ReviewPage from '../../FormPages/ReviewPage'
import DeletePropertyOverlay from '../../../molecules/DeletePropertyOverlay'

const alwaysValidateToTrue = () => {
  return {}
}

const reviewPageConfig = propertyId => ({
  id: 0,
  component: values => <ReviewPage values={values} propertyId={propertyId} />,
  route: `/landlord-admin/property/${propertyId}/edit/review`,
  validate: alwaysValidateToTrue,
  isValid: false,
  heading: 'Edit property details',
  subheading: '',
  button: (values, loading, history) => (
    <DeletePropertyOverlay
      propertyId={propertyId}
      history={history}
      deletePropertyById={deletePropertyById}
      getPropertyById={getPropertyById}
      notificationService={notificationService}
      useAuthContext={useAuthContext}
      useStateContext={useStateContext}
    />
  ),
  onSubmit: () => {},
})

export default reviewPageConfig
