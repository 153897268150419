import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { SuccessPill } from '@afs/components/Pill'

import Toggle from '../TinyToggle'

import styles from './testimonialListRow.module.scss'

const TestimonialListRow = ({
  className,
  testimonial,
  viewTestimonial,
  markAsNotNew,
  toggleDisplay,
  ...props
}) => {
  const [toggling, setToggling] = useState(false)

  const { tenantName, tenantEmail, text, displayed } = testimonial
  const newTestimonial = !!testimonial.new

  const handleOnClick = e => {
    e.preventDefault()
    viewTestimonial()
  }

  const toggleDisplayTestimonial = () => {
    if (newTestimonial) {
      markAsNotNew(testimonial.id)
    }

    setToggling(true)
    toggleDisplay(testimonial.id, displayed).then(() => {
      setTimeout(() => setToggling(false), 600) // Delay toggle animation
    })
  }

  const testimonialClasses = classNames(styles.wrapper, className)

  const toggleText = {
    enabled: <span className={styles.toggleText}>Displayed</span>,
    disabled: <span className={styles.toggleText}>Not displayed</span>,
  }

  return (
    <div className={testimonialClasses} {...props}>
      <button
        className={styles.leftSideWrapper}
        onClick={handleOnClick}
        data-testid="view-testimonial-button"
      >
        <div className={styles.tenantDetails}>
          {newTestimonial && (
            <SuccessPill className={styles.newPill} data-testid="new-testimonial-pill">
              New testimonial
            </SuccessPill>
          )}
          <p className={styles.name}>{tenantName}</p>
          <p className={styles.email}>{tenantEmail}</p>
        </div>
        <div className={styles.preview}>{text}</div>
      </button>
      <Toggle
        className={styles.toggle}
        toggleText={toggleText}
        enabled={displayed}
        handleOnClick={toggleDisplayTestimonial}
        inactive={toggling}
      />
    </div>
  )
}

TestimonialListRow.propTypes = {
  className: PropTypes.string,
  testimonial: PropTypes.object.isRequired,
  viewTestimonial: PropTypes.func.isRequired,
  markAsNotNew: PropTypes.func.isRequired,
  toggleDisplay: PropTypes.func.isRequired,
}

export default TestimonialListRow
