import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import PropertyTitle from '../../atoms/PropertyTitle'
import Address from '../Address'

const PropertyTitleAndAddress = ({ className, numberOfBedrooms, accommodationType, address }) => {
  const titleClasses = className ? classNames(`${className}__title`) : undefined
  const addressClasses = className ? classNames(`${className}__address`) : undefined

  return (
    <div>
      <PropertyTitle
        className={titleClasses}
        rooms={numberOfBedrooms}
        type={accommodationType.toLowerCase()}
        area={address.area}
      />
      <Address className={addressClasses} address={address} />
    </div>
  )
}

PropertyTitleAndAddress.propTypes = {
  className: PropTypes.string,
  numberOfBedrooms: PropTypes.number.isRequired,
  accommodationType: PropTypes.string.isRequired,
  address: PropTypes.object.isRequired,
}

export default PropertyTitleAndAddress
