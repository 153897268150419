import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import DefaultIcon from '../../../svgs/icons/small-cross.svg'
import TickIcon from '../../../svgs/icons/small-tick.svg'

import styles from './styles.module.scss'

const PasswordRequirements = ({ className, password, userIsUpdating, ...props }) => {
  const componentClasses = classNames(styles.passwordRequirements, className)

  const passwordLengthCorrect = password.length >= 8
  const passwordHasLowerCaseLetter = password.match(/[a-z]/g)
  const passwordHasUppercaseLetter = password.match(/[A-Z]/g)
  const passwordHasDigit = password.match(/[0-9]/g)

  const requirements = [
    {
      criteria: 'length',
      text: '8 characters',
      state: passwordLengthCorrect ? 'criteriaMet' : 'default',
    },
    {
      criteria: 'lowercase',
      text: 'one lowercase letter (a-z)',
      state: passwordHasLowerCaseLetter ? 'criteriaMet' : 'default',
    },
    {
      criteria: 'uppercase',
      text: 'one uppercase letter (A-Z)',
      state: passwordHasUppercaseLetter ? 'criteriaMet' : 'default',
    },
    {
      criteria: 'digit',
      text: 'one digit (0-9)',
      state: passwordHasDigit ? 'criteriaMet' : 'default',
    },
  ]

  return (
    <div className={componentClasses} {...props}>
      Passwords must contain at least:
      <ul className={styles.list}>
        {requirements.map(requirement => {
          const criteriaMet = requirement.state === 'criteriaMet'
          const criteriaNotMet = requirement.state === 'default' && !userIsUpdating

          if (criteriaMet) {
            return (
              <li
                className={styles.green}
                key={requirement.criteria}
                data-testid={`list-item-${requirement.criteria}-criteria-met`}
              >
                <span>
                  <TickIcon className={classNames(styles.icon, styles.tickIcon)} />
                </span>
                <span>{requirement.text}</span>
              </li>
            )
          }
          if (criteriaNotMet) {
            return (
              <li
                className={styles.red}
                key={requirement.criteria}
                data-testid={`list-item-${requirement.criteria}-criteria-not-met`}
              >
                <span>
                  <DefaultIcon className={classNames(styles.icon, styles.redIcon)} />
                </span>
                <span>{requirement.text}</span>
              </li>
            )
          } else {
            return (
              <li
                className={styles.listItem}
                key={requirement.criteria}
                data-testid={`default-list-item-${requirement.criteria}`}
              >
                <span data-testid="default-icon">
                  <DefaultIcon className={styles.icon} />
                </span>
                <span>{requirement.text}</span>
              </li>
            )
          }
        })}
      </ul>
    </div>
  )
}

PasswordRequirements.propTypes = {
  className: PropTypes.string,
  password: PropTypes.string,
  userIsUpdating: PropTypes.bool,
}

export default PasswordRequirements
