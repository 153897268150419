import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useAuthContext } from '../../authContext'
import {
  getEnquiries,
  getImpressions,
  getFeedEnquiries,
  getFeedImpressions,
} from '../../api/performanceApi'
import FormattedDate from '../../models/formattedDate'
import { fromThirtyDaysAgoTo } from '../../services/performanceDatesService'

import PerformancePage from '../pages/PerformancePage'
import PerformancePageFeeds from '../pages/PerformancePageFeeds'
import LoadingPage from '../pages/LoadingPage'
import ErrorPage from '../pages/ErrorPage'

const date = new FormattedDate()

export const PerformanceContainer = ({
  performancePage,
  getEnquiries,
  getImpressions,
  useAuthContext,
  errorPage,
  date,
}) => {
  const [performance, setPerformance] = useState({})
  const [serverError, setServerError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [analyticsLoaderStatus, setAnalyticsLoaderStatus] = useState({
    waiting: true,
    failed: false,
  })
  const [enquiriesLoaderStatus, setEnquiriesLoaderStatus] = useState({
    waiting: true,
  })

  const { token } = useAuthContext()

  const initialFilterDateRange = fromThirtyDaysAgoTo(date)

  const mergeEnquiriesWithImpressions = (enquiries, impressions) => {
    const { overview, properties } = enquiries

    const overviewWithImpressions = {
      ...overview,
      ...impressions.overview,
    }

    const propertiesWithImpressions = properties.map((property) => {
      const propertyWithImpressions = impressions.properties.find((p) => p.id === property.id)
      return {
        ...property,
        ...propertyWithImpressions,
      }
    })

    return {
      overview: overviewWithImpressions,
      properties: propertiesWithImpressions,
      hasIncompleteData: impressions.hasIncompleteData,
    }
  }

  const getPerformance = (dateRange) => {
    setAnalyticsLoaderStatus({
      waiting: true,
      failed: false,
    })
    setEnquiriesLoaderStatus({ waiting: true })

    getEnquiries(dateRange, token).then((enquiriesResponse) => {
      if (enquiriesResponse.success) {
        const { enquiries } = enquiriesResponse
        setPerformance(enquiries)
        setEnquiriesLoaderStatus({ waiting: false })
        setLoading(false)

        getImpressions(dateRange, token).then((impressionsResponse) => {
          if (impressionsResponse.success) {
            const { impressions } = impressionsResponse
            setPerformance(mergeEnquiriesWithImpressions(enquiries, impressions))
            setAnalyticsLoaderStatus({
              waiting: false,
              failed: false,
            })
          } else {
            setAnalyticsLoaderStatus({
              waiting: false,
              failed: true,
            })
          }
        })
      } else {
        setServerError(true)
      }
    })
  }

  useEffect(() => {
    getPerformance(initialFilterDateRange)
  }, [])

  if (serverError) {
    return errorPage()
  } else {
    return loading ? (
      <LoadingPage />
    ) : (
      performancePage(
        performance,
        getPerformance,
        date,
        initialFilterDateRange,
        analyticsLoaderStatus,
        enquiriesLoaderStatus
      )
    )
  }
}

PerformanceContainer.propTypes = {
  getEnquiries: PropTypes.func.isRequired,
  getImpressions: PropTypes.func.isRequired,
  performancePage: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
  errorPage: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
}

export const PerformanceContainerCompositionRoot = () => (
  <PerformanceContainer
    performancePage={(
      performance,
      getPerformance,
      date,
      initialDateRange,
      analyticsLoaderStatus,
      enquiriesLoaderStatus
    ) => (
      <PerformancePage
        performance={performance}
        getPerformance={getPerformance}
        date={date}
        initialDateRange={initialDateRange}
        analyticsLoaderStatus={analyticsLoaderStatus}
        enquiriesLoaderStatus={enquiriesLoaderStatus}
      />
    )}
    getEnquiries={getEnquiries}
    getImpressions={getImpressions}
    useAuthContext={useAuthContext}
    errorPage={() => <ErrorPage />}
    date={date}
  />
)

export const PerformanceContainerFeedsCompositionRoot = () => (
  <PerformanceContainer
    performancePage={(
      performance,
      getPerformance,
      date,
      initialDateRange,
      analyticsLoaderStatus,
      enquiriesLoaderStatus
    ) => (
      <PerformancePageFeeds
        performance={performance}
        getPerformance={getPerformance}
        date={date}
        initialDateRange={initialDateRange}
        analyticsLoaderStatus={analyticsLoaderStatus}
        enquiriesLoaderStatus={enquiriesLoaderStatus}
      />
    )}
    getEnquiries={getFeedEnquiries}
    getImpressions={getFeedImpressions}
    useAuthContext={useAuthContext}
    errorPage={() => <ErrorPage />}
    date={date}
  />
)
