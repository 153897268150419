import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Dropdown from '@afs/components/Dropdown'
import SpinnerButton from '@afs/components/SpinnerButton'
import Field from '@afs/components/Field'

import { invalidEmail } from '../../../../../utils/validation'

import Overlay from '../../../../molecules/Overlay'
import LinkButton from '../../../../atoms/LinkButton'
import Button from '../../../../atoms/Button'
import LargeNotification from '../../../../molecules/LargeNotification'
import Notification from '../../../../molecules/Notification'

import OpenIcon from '../../../../../svgs/icons/corner-arrow.svg'

import requestOverlay from './requestOverlay.module.scss'
import './styles.scss'

const RequestOverlay = ({
  isActive,
  handleOverlayClose,
  overlayData,
  propertyAddresses,
  handleRequestTestimonial,
  openTestimonialPreview,
  window,
}) => {
  const preselectedAddressIsEmpty =
    overlayData.preselectedAddress && !overlayData.selectedProperty.value

  if (preselectedAddressIsEmpty) return null

  const initialFieldsState = {
    property: {
      value: overlayData.selectedProperty.value || '',
      error: '',
    },
    tenantFirstName: { value: '', error: '' },
    tenantLastName: { value: '', error: '' },
    tenantEmail: { value: '', error: '' },
  }

  const [fields, setFields] = useState(initialFieldsState)
  const [requestState, setRequestState] = useState({ sent: false, loading: false, success: false })
  const [formState, setFormState] = useState({ open: true, invalid: false })

  const { tenantFirstName, tenantLastName, tenantEmail, property } = fields

  const tenantFirstNameIsEmpty = tenantFirstName.value === ''
  const tenantLastNameIsEmpty = tenantLastName.value === ''
  const tenantEmailIsEmpty = tenantEmail.value === ''
  const propertyFieldIsEmpty = property.value === ''

  const tenantEmailHasError = tenantEmail.error !== '' && formState.invalid

  const tenantFirstNameIsInvalid = tenantFirstNameIsEmpty && formState.invalid
  const tenantLastNameIsInvalid = tenantLastNameIsEmpty && formState.invalid
  const tenantEmailIsInvalid = (tenantEmailIsEmpty && formState.invalid) || tenantEmailHasError
  const propertyFieldIsInvalid = propertyFieldIsEmpty && formState.invalid

  const formIsInvalid =
    tenantFirstNameIsEmpty || tenantLastNameIsEmpty || tenantEmailIsEmpty || propertyFieldIsEmpty

  const showErrorNotification = !requestState.success && requestState.sent
  const showSuccessNotification = !formState.open && requestState.sent && requestState.success

  const validateNameAndSurname = value => {
    if (value === '') return 'Required.'
    return ''
  }

  const validateEmail = email => {
    if (invalidEmail(email)) {
      return 'Valid email address required.'
    }
    return ''
  }

  const validateProperty = property => {
    if (!property || !property.propertyId) return 'Please select a property.'
    return ''
  }

  const scrollToFirstInvalidField = fields => {
    let fieldPosition

    const formFields = Object.keys(fields).map(field => {
      return { name: field, ...fields[field] }
    })

    const formFieldsWithErrors = formFields.filter(field => field.error !== '')

    if (formFieldsWithErrors.length > 0) {
      const requestTestimonialOverlay = window.document.getElementsByClassName(
        'request-testimonial-overlay'
      )[0]

      if (formFieldsWithErrors[0].name === 'property') {
        const topEdgeOfPropertyError = window.document
          .getElementById('property-error')
          .getBoundingClientRect().top

        const offset = 200
        fieldPosition = requestTestimonialOverlay.scrollTop + topEdgeOfPropertyError - offset
      } else {
        const topEdgeOfFirstErrorInput = window.document
          .getElementsByName(formFieldsWithErrors[0].name)[0]
          .getBoundingClientRect().top

        const offset = 100
        fieldPosition = requestTestimonialOverlay.scrollTop + topEdgeOfFirstErrorInput - offset
      }

      requestTestimonialOverlay.scroll({ behavior: 'smooth', left: 0, top: fieldPosition })
    }
  }

  useEffect(() => {
    if (window && formIsInvalid) {
      scrollToFirstInvalidField(fields)
    }
  }, [fields])

  const handleInputChange = event => {
    const inputValue = event.target.value
    const inputName = event.target.name

    if (inputName === 'tenantEmail') {
      setFields(prevState => ({
        ...prevState,
        tenantEmail: { value: inputValue, error: validateEmail(inputValue) },
      }))
    } else {
      setFields(prevState => ({
        ...prevState,
        [inputName]: { value: inputValue, error: validateNameAndSurname(inputValue) },
      }))
    }
  }

  const handleDropdownChange = selection => {
    const fieldIsEmpty = !selection

    if (!fieldIsEmpty) {
      setFields(prevState => ({
        ...prevState,
        property: {
          value: selection,
          error: '',
        },
      }))
    } else {
      setFields(prevState => ({
        ...prevState,
        property: {
          value: '',
          error: 'Please select a property.',
        },
      }))
    }
  }

  const findPropertyByAddress = (property, inputValue) => {
    return property.address.toLowerCase().includes(inputValue.toLowerCase())
  }

  const handleSendRequest = event => {
    event.preventDefault()
    if (formIsInvalid || tenantEmail.error !== '') {
      setFields(prevState => ({
        ...prevState,
        property: {
          ...prevState.property,
          error: validateProperty(prevState.property.value),
        },
        tenantFirstName: {
          ...prevState.tenantFirstName,
          error: validateNameAndSurname(prevState.tenantFirstName.value),
        },
        tenantLastName: {
          ...prevState.tenantLastName,
          error: validateNameAndSurname(prevState.tenantLastName.value),
        },
        tenantEmail: {
          ...prevState.tenantEmail,
          error: validateEmail(prevState.tenantEmail.value),
        },
      }))

      setFormState({ open: true, invalid: true })
    } else {
      setRequestState(prevState => ({ ...prevState, loading: true }))
      const payload = {
        propertyId: property.value.propertyId,
        tenantFirstName: tenantFirstName.value,
        tenantLastName: tenantLastName.value,
        tenantEmail: tenantEmail.value,
      }

      handleRequestTestimonial(payload).then(response => {
        if (response.success) {
          setFormState({ open: false, invalid: false })
          setRequestState({ sent: true, success: true, loading: false })
        } else {
          setFormState({ open: true, invalid: false })
          setRequestState({ sent: true, success: false, loading: false })
        }
      })
    }
  }

  const handleSendAnotherRequest = () => {
    setFields(initialFieldsState)
    setFormState({ open: true, invalid: false })
    setRequestState({ sent: false, success: false, loading: false })
  }

  const handleOpenTestimonialPreview = () => {
    openTestimonialPreview(fields.tenantFirstName.value, fields.property.value)
  }

  return (
    <Overlay
      className="request-testimonial-overlay"
      id="request-testimonial"
      heading="Request a testimonial"
      closeLabel="Close"
      isActive={isActive}
      onClose={handleOverlayClose}
      preserveScrollPositionOnClose
    >
      {formState.open && (
        <>
          <p className={requestOverlay.text}>
            Testimonials won’t show up on your property listings until you have reviewed and enabled
            them.
          </p>
          <LinkButton onClick={handleOpenTestimonialPreview} data-testid="open-preview-button">
            See what the student will receive
            <OpenIcon className={requestOverlay.icon} />
          </LinkButton>
          <form onSubmit={handleSendRequest}>
            <Dropdown
              className={requestOverlay.dropdown}
              name="property"
              label="For property"
              aria-label="For property"
              placeholder="Select a property"
              options={propertyAddresses.map(({ value, label, displayLabel }) => ({
                value,
                label,
                displayLabel,
                renderInner: item => (
                  <>
                    <span>{item.displayLabel.firstLineOfAddress}, </span>
                    <span className={requestOverlay.dropdownOption}>
                      {item.displayLabel.restOfAddress}
                    </span>
                  </>
                ),
              }))}
              selectedItem={property.value}
              handleChange={handleDropdownChange}
              customInputMatcher={findPropertyByAddress}
              notFoundText="Property not found"
              data-testid="property-dropdown"
              invalid={propertyFieldIsInvalid}
              error={fields.property.error}
            />

            <Field
              className={requestOverlay.field}
              name="tenantFirstName"
              label="Student's first name"
              aria-label="Student's first name"
              value={tenantFirstName.value}
              type="text"
              onChange={handleInputChange}
              onBlur={handleInputChange}
              data-testid="input-field-first-name"
              invalid={tenantFirstNameIsInvalid}
              error={fields.tenantFirstName.error}
            />

            <Field
              className={requestOverlay.field}
              name="tenantLastName"
              label="Student's last name"
              aria-label="Student's last name"
              value={tenantLastName.value}
              type="text"
              onChange={handleInputChange}
              onBlur={handleInputChange}
              data-testid="input-field-last-name"
              invalid={tenantLastNameIsInvalid}
              error={fields.tenantLastName.error}
            />

            <Field
              className={requestOverlay.field}
              name="tenantEmail"
              label="Student's email address"
              aria-label="Student's email address"
              value={tenantEmail.value}
              type="text"
              onChange={handleInputChange}
              onBlur={handleInputChange}
              data-testid="input-field-email"
              invalid={tenantEmailIsInvalid}
              error={fields.tenantEmail.error}
            />
            {showErrorNotification && (
              <Notification
                className={requestOverlay.error}
                heading="Oops!"
                text="Something went wrong and we could not send your request. Please try again later."
              />
            )}
            <SpinnerButton
              primary
              inactive={requestState.loading}
              data-testid="send-request-button"
              onClick={handleSendRequest}
            >
              Send request
            </SpinnerButton>
          </form>
        </>
      )}

      {showSuccessNotification && (
        <>
          <LargeNotification
            className={requestOverlay.notification}
            type="success"
            text={`Your request to ${tenantFirstName.value} has been sent`}
            renderInteractiveComponent={() => (
              <LinkButton onClick={handleOpenTestimonialPreview} data-testid="open-preview-button">
                See what the email looks like <OpenIcon className={requestOverlay.openIcon} />
              </LinkButton>
            )}
          />
          <div className={requestOverlay.buttonWrapper}>
            <Button
              className={requestOverlay.sendAnotherButton}
              violet
              onClick={handleSendAnotherRequest}
              data-testid="send-another-button"
            >
              Send another
            </Button>
            <Button className={requestOverlay.closeButton} onClick={handleOverlayClose}>
              Close
            </Button>
          </div>
        </>
      )}
    </Overlay>
  )
}

RequestOverlay.propTypes = {
  isActive: PropTypes.bool.isRequired,
  handleOverlayClose: PropTypes.func.isRequired,
  overlayData: PropTypes.object.isRequired,
  propertyAddresses: PropTypes.array.isRequired,
  handleRequestTestimonial: PropTypes.func.isRequired,
  openTestimonialPreview: PropTypes.func.isRequired,
  window: PropTypes.object,
}

export default RequestOverlay
