import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Field from '@afs/components/Field'

import { invalidPhoneNumber } from '../../../../utils/validation'

import AccountSettingsForm from '../../../molecules/AccountSettingsForm'

const EnquiriesPhoneNumberForm = ({ account, onSave, loading, ...props }) => {
  const [publicPhoneNumber, setPublicPhoneNumber] = useState({
    value: account.publicPhoneNumber,
    touched: false,
    error: '',
  })

  const validatePublicPhoneNumber = string => {
    if (invalidPhoneNumber(string)) {
      return 'Valid phone number required.'
    }
    return ''
  }

  const handleChange = event => {
    const inputValue = event.target.value
    const inputName = event.target.name
    const error = validatePublicPhoneNumber(inputValue)
    const { checked } = event.target

    setPublicPhoneNumber(prevState => ({
      ...prevState,
      value: inputValue,
      touched: true,
      error,
    }))
  }

  const handleSave = event => {
    event.preventDefault()

    const fieldName = 'publicPhoneNumber'
    const payload = {
      publicPhoneNumber: publicPhoneNumber.value,
    }

    onSave(fieldName, payload)
  }

  const formIsValid = publicPhoneNumber.touched && publicPhoneNumber.error !== ''

  return (
    <AccountSettingsForm onSubmit={handleSave} invalid={formIsValid} loading={loading} {...props}>
      <Field
        name="publicPhoneNumber"
        aria-label="Enquiries phone number"
        value={publicPhoneNumber.value}
        type="tel"
        onChange={handleChange}
        onBlur={handleChange}
        invalid={formIsValid}
        error={publicPhoneNumber.error}
        data-testid="input-field-phone-number"
      />
    </AccountSettingsForm>
  )
}

EnquiriesPhoneNumberForm.propTypes = {
  account: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default EnquiriesPhoneNumberForm
