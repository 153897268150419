import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Field from '@afs/components/Field'

import AccountSettingsForm from '../../../molecules/AccountSettingsForm'

import {
  containsEmail,
  containsPhone,
  containsUrl,
  exceedsMaxLength,
  includesHTML,
} from '../../../../utils/validation'

const DescriptionForm = ({ account, onSave, loading, ...props }) => {
  const [field, setField] = useState({
    value: account.description,
    touched: false,
    error: '',
  })

  const validateDescription = string => {
    if (containsEmail(string)) {
      return 'Email addresses are not allowed in the description.'
    }

    if (containsUrl(string)) {
      return 'URLs are not allowed in the description.'
    }

    if (containsPhone(string)) {
      return 'Phone numbers are not allowed in the description.'
    }

    if (includesHTML(string)) {
      return 'HTML tags are not allowed in the description.'
    }

    if (exceedsMaxLength(string, 2000)) {
      return 'Description exceeds maximum length of 2000 characters.'
    }

    return ''
  }

  const handleChange = event => {
    const inputValue = event.target.value
    const error = validateDescription(inputValue)

    setField(prevState => ({
      ...prevState,
      value: inputValue,
      touched: true,
      error,
    }))
  }

  const handleSave = event => {
    event.preventDefault()

    const fieldName = 'description'
    const payload = {
      [fieldName]: field.value,
    }

    onSave(fieldName, payload)
  }

  const formIsInvalid = field.touched && field.error !== ''

  return (
    <AccountSettingsForm onSubmit={handleSave} invalid={formIsInvalid} loading={loading} {...props}>
      <Field
        name="description"
        aria-label="description"
        type="textarea"
        value={field.value}
        onChange={handleChange}
        onBlur={handleChange}
        invalid={formIsInvalid}
        error={field.error}
        data-testid="input-field"
      />
    </AccountSettingsForm>
  )
}

DescriptionForm.propTypes = {
  account: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default DescriptionForm
