import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import TwoColumnPageTemplate from '../../templates/TwoColumnPageTemplate'
import Header from '../../organisms/Header'
import MobileContextMenu from '../../organisms/MobileContextMenu'
import SelectedPropertiesInBasket from '../../organisms/SelectedPropertiesInBasket'
import AdvertisementOptions from '../../organisms/AdvertisementOptions'
import Notifications from '../../organisms/Notifications'

import './styles.scss'

const AdvertisementOptionsPage = ({
  history,
  today,
  selectedProperties,
  deselectProperty,
  handleError,
}) => {
  const numberOfSelectedProperties = selectedProperties.length
  const atLeastOneSelected = numberOfSelectedProperties > 0
  const [discountApplied, setDiscountApplied] = useState(false)

  return (
    <TwoColumnPageTemplate
      header={<Header />}
      title="Advertisement Options | AFS"
      className="advertisement-options-page"
      contextMenu={
        atLeastOneSelected && (
          <MobileContextMenu
            className={classNames('advertisement-options-page__mobile-context-menu', {
              'advertisement-options-page__mobile-context-menu--with-discount': discountApplied,
            })}
            overview={
              numberOfSelectedProperties > 1
                ? `${numberOfSelectedProperties} properties selected`
                : '1 property selected'
            }
          >
            <SelectedPropertiesInBasket
              selectedProperties={selectedProperties}
              deselectProperty={deselectProperty}
            />
          </MobileContextMenu>
        )
      }
      leftColumn={
        <>
          <Notifications className="notifications" />
          <AdvertisementOptions
            history={history}
            today={today}
            onSetDiscountApplied={setDiscountApplied}
            handleError={handleError}
          />
        </>
      }
      rightColumn={
        atLeastOneSelected ? (
          <SelectedPropertiesInBasket
            heading={`Selecting ${numberOfSelectedProperties > 1 ? 'adverts' : 'advert'} for`}
            selectedProperties={selectedProperties}
            deselectProperty={deselectProperty}
          />
        ) : (
          <></>
        )
      }
    />
  )
}

AdvertisementOptionsPage.propTypes = {
  history: PropTypes.object,
  today: PropTypes.object,
  selectedProperties: PropTypes.array,
  deselectProperty: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
}

export default AdvertisementOptionsPage
