import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Field from '@afs/components/Field'

const FieldTextDateOptionalAdapter = ({
  input: { name, onChange, value, onBlur },
  label,
  className,
  meta,
}) => {
  const [placeholder, setPlaceholder] = useState('')
  const isInvalid = meta.error && meta.touched
  const validated = !meta.error && meta.touched && value !== ''
  return (
    <Field
      className={className}
      name={name}
      label={label}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      optional
      onFocus={() => setPlaceholder('DD/MM/YYYY')}
      onBlur={event => {
        setPlaceholder('')
        onBlur(event)
      }}
      invalid={isInvalid}
      error={meta.error}
      validated={validated}
    />
  )
}

FieldTextDateOptionalAdapter.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
  meta: PropTypes.object,
}

export default FieldTextDateOptionalAdapter
