import React from 'react'
import SpinnerButton from '@afs/components/SpinnerButton'

import { updatePropertyPhotos } from '../../../../api/propertyApi'

import { PhotosPage } from '../../FormPages/PhotosPage'
import SubmitButton from '../../../atoms/SubmitButton'

const alwaysValidateToTrue = () => {
  return {}
}

const photoPageConfig = propertyId => ({
  id: 9,
  component: () => <PhotosPage />,
  route: `/landlord-admin/property/${propertyId}/edit/photos`,
  validate: alwaysValidateToTrue,
  isValid: false,
  heading: 'Photos',
  subheading:
    'Photos help students get a better idea about your property. Better photos lead to more enquiries.',
  button: (values, loading) => {
    const { photos } = values

    if (photos.loading) return null

    const hasPhotos = photos.images.items.length > 0

    const label = !hasPhotos ? 'Save without photos' : 'Save changes'
    const className = !hasPhotos ? 'form-navigation__link-button' : undefined

    if (loading)
      return (
        <SpinnerButton primary inactive>
          Continue
        </SpinnerButton>
      )

    return (
      <SubmitButton className={className} data-testid="save-button">
        {label}
      </SubmitButton>
    )
  },
  onSubmit: async (values, token) => {
    const payload = {
      propertyId,
      photos: values.photos,
    }
    return updatePropertyPhotos(payload, token)
  },
})

export default photoPageConfig
