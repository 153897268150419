import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withRouter } from 'react-router'

import { resetPasswordFormViewModel, notificationService } from '../../../container'
import { getAccountEmailForResetPassword } from '../../../api/accountApi'

import PageTemplate from '../../templates/PageTemplate'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Heading from '../../atoms/Heading'
import ResetPasswordForm from '../../organisms/ResetPasswordForm'

import './styles.scss'

const ResetPasswordPage = ({ className, ...props }) => {
  const componentClasses = classNames('reset-password', className)
  const { history } = props

  return (
    <PageTemplate
      className={componentClasses}
      header={<Header />}
      footer={<Footer />}
      title="Reset Password | AFS"
    >
      <div className="reset-password__row">
        <div className="reset-password__inner">
          <div className="reset-password__content">
            <Heading className="reset-password__heading">Reset Your Password</Heading>
            <ResetPasswordForm
              className="reset-password__form"
              history={history}
              viewModel={resetPasswordFormViewModel}
              notificationService={notificationService}
              getLandlordEmailForResetPassword={getAccountEmailForResetPassword}
            />
          </div>
        </div>
      </div>
    </PageTemplate>
  )
}

ResetPasswordPage.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(ResetPasswordPage)
