import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { config } from '../../../config'

import BillsOptionalQuoteSuccessCard from '../BillsOptionalQuoteSuccessCard'
import LazyImage from '../../atoms/LazyImage'

import NoImagesIcon from './svgs/no-image.svg'
import ErrorIcon from './svgs/error-icon.svg'
import RefreshIcon from './svgs/refresh.svg'
import QuoteIcon from './svgs/quote-icon.svg'
import ChevronIcon from './svgs/forward-arrow.svg'
import SpinnerIcon from './svgs/spinner.svg'

import styles from './styles.module.scss'

const PropertyDetails = ({ property }) => {
  const { photo, hasPhoto, address } = property

  return (
    <>
      <div className={styles.imageWrapper}>
        {hasPhoto ? (
          <LazyImage
            className={styles.propertyImage}
            src={`${config.imgApiUrl}w=112,h=112,fit=cover/${photo}`}
            dataSrc={`${config.imgApiUrl}w=112,h=112,fit=cover/${photo}`}
            alt={address.line1}
            data-testid="property-image"
          />
        ) : (
          <NoImagesIcon className={styles.noImagesIcon} data-testid="no-property-image" />
        )}
      </div>
      <div className={styles.addressWrapper}>
        <p className={styles.line1}>{`${address.line1},`}</p>
        <p className={styles.line2}>{address.line2}</p>
      </div>
    </>
  )
}

const BillsOptionalQuoteFailureCard = forwardRef(
  ({ className, property, handleRetryQuote }, ref) => {
    return (
      <div
        ref={ref}
        className={classNames(styles.cardFailed, className)}
        data-testid="quote-error-card"
      >
        <div className={styles.propertyDetailsWrapperFailed}>
          <PropertyDetails property={property} />
        </div>
        <div className={styles.errorWrapper}>
          <div className={styles.errorMessage}>
            <ErrorIcon className={styles.errorMessageIcon} />
            <span className={styles.errorMessageText}>Hmm, something went wrong.</span>
          </div>
          <button
            className={styles.errorButton}
            type="button"
            onClick={() => handleRetryQuote(property.propertyId)}
          >
            <span className={styles.errorButtonText}>Click to retry</span>
            <RefreshIcon className={styles.refreshIcon} />
          </button>
        </div>
      </div>
    )
  }
)

const BillsOptionalQuotePendingCard = forwardRef(({ className, property }, ref) => {
  return (
    <div ref={ref} className={classNames(styles.card, className)} data-testid="quote-pending-card">
      <div className={styles.propertyDetailsWrapper}>
        <PropertyDetails property={property} />
      </div>
      <div className={styles.quoteDetailsWrapper}>
        <div className={styles.quoteSummary}>
          <div className={styles.titleWrapper}>
            <QuoteIcon className={styles.quoteIcon} />
            <span className={styles.quoteTitle}>Bills optional</span>
          </div>
          <div className={styles.priceWrapper}>
            <SpinnerIcon className={styles.spinnerIcon} data-testid="spinner" />
          </div>
        </div>
      </div>
      <ChevronIcon className={styles.chevronPending} />
    </div>
  )
})

const BillsOptionalQuoteCard = forwardRef(
  (
    { className, property, handleRetryQuote, handleRemoveProperty, renderQuoteDetailsOverlay },
    ref
  ) => {
    const { quote } = property

    if (quote.status === 'success') {
      return (
        <BillsOptionalQuoteSuccessCard
          ref={ref}
          className={className}
          property={property}
          handleRemoveProperty={handleRemoveProperty}
          renderQuoteDetailsOverlay={renderQuoteDetailsOverlay}
        />
      )
    }

    if (quote.status === 'failed') {
      return (
        <BillsOptionalQuoteFailureCard
          ref={ref}
          className={className}
          property={property}
          handleRetryQuote={handleRetryQuote}
        />
      )
    }

    return <BillsOptionalQuotePendingCard ref={ref} className={className} property={property} />
  }
)

PropertyDetails.propTypes = {
  property: PropTypes.shape({
    propertyId: PropTypes.number.isRequired,
    photo: PropTypes.string.isRequired,
    hasPhoto: PropTypes.bool.isRequired,
    address: PropTypes.shape({
      line1: PropTypes.string.isRequired,
      line2: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

BillsOptionalQuoteFailureCard.propTypes = {
  className: PropTypes.string,
  property: PropTypes.shape({
    propertyId: PropTypes.number.isRequired,
    photo: PropTypes.string.isRequired,
    hasPhoto: PropTypes.bool.isRequired,
    address: PropTypes.shape({
      line1: PropTypes.string.isRequired,
      line2: PropTypes.string.isRequired,
    }),
  }).isRequired,
  handleRetryQuote: PropTypes.func.isRequired,
}

BillsOptionalQuotePendingCard.propTypes = {
  className: PropTypes.string,
  property: PropTypes.shape({
    propertyId: PropTypes.number.isRequired,
    photo: PropTypes.string.isRequired,
    hasPhoto: PropTypes.bool.isRequired,
    address: PropTypes.shape({
      line1: PropTypes.string.isRequired,
      line2: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

BillsOptionalQuoteCard.propTypes = {
  className: PropTypes.string,
  property: PropTypes.shape({
    propertyId: PropTypes.number.isRequired,
    photo: PropTypes.string.isRequired,
    hasPhoto: PropTypes.bool.isRequired,
    address: PropTypes.shape({
      line1: PropTypes.string.isRequired,
      line2: PropTypes.string.isRequired,
    }),
    rentFrom: PropTypes.string.isRequired,
    quote: PropTypes.shape({
      status: PropTypes.oneOf(['success', 'failed', 'pending']).isRequired,
      lastUpdated: PropTypes.string,
      totalPricePppw: PropTypes.string,
      services: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          pricePppw: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          available: PropTypes.bool.isRequired,
        })
      ),
    }),
  }).isRequired,
  handleRetryQuote: PropTypes.func.isRequired,
  handleRemoveProperty: PropTypes.func.isRequired,
  renderQuoteDetailsOverlay: PropTypes.func.isRequired,
}

export default BillsOptionalQuoteCard
