import { includesHTML, invalidEmail } from '../../../utils/validation'

export const validateFieldDoesNotIncludeHTML = fieldValue => {
  if (includesHTML(fieldValue)) return 'Fields must not contain HTML.'
  return true
}

export const validateEmailAddress = emailAddress => {
  if (invalidEmail(emailAddress)) return 'Please enter a valid email address.'
  return true
}

export const validateFieldIsEmptyOrANumber = (value, min, max) => {
  const parsed = parseFloat(value)

  if (value === '') return true
  if (isNaN(parsed)) return 'Please enter a number.'
  if (parsed < min) return `Please enter a number greater than ${min}.`
  if (parsed > max) return `Please enter a number smaller than ${max}.`
  return true
}

export const validatePassword = password => {
  const passwordIsTooShort = password.length < 8
  const passwordIsMissingALowercaseLetter = !password.match(/[a-z]/g)
  const passwordIsMissingAnUppercaseLetter = !password.match(/[A-Z]/g)
  const passwordIsMissingADigit = !password.match(/[0-9]/g)

  if (passwordIsTooShort) return 'Password length is less than 8 characters.'
  if (passwordIsMissingALowercaseLetter)
    return 'Password does not contain a lowercase letter (a-z).'
  if (passwordIsMissingAnUppercaseLetter)
    return 'Password does not contain an uppercase letter (A-Z).'
  if (passwordIsMissingADigit) return 'Password does not contain a digit (0-9).'

  return true
}

export const validateConfirmPasswordMatchesPassword = (confirmPassword, password) => {
  if (confirmPassword !== password) {
    return "Passwords don't match. Please double check what you've entered."
  } else {
    return true
  }
}
