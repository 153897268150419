import { performApiGet, performApiPatch } from './requests'

export const getAllFeedProperties = async token => {
  try {
    const response = await performApiGet('/feeds/property', token)

    return {
      success: response.status === 200,
      properties: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getSelectedFeedProperties = async (propertyIds, token) => {
  try {
    const response = await getAllFeedProperties(token)

    const selectedProperties = response.properties.filter(property =>
      propertyIds.includes(property.id)
    )

    return {
      success: response.success,
      properties: selectedProperties,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const boostMultipleFeedProperties = async (properties, token) => {
  try {
    const response = await performApiPatch('/feeds/property/boost', { properties }, token)
    return {
      success: response.status === 200,
    }
  } catch (exception) {
    return { success: false }
  }
}
