const mapBedrooms = apiProperty => {
  const { bedrooms } = apiProperty
  const mappedBedrooms = []

  if (!bedrooms.length > 0) {
    return mappedBedrooms
  }

  bedrooms.map((bedroom, i) => {
    const { rentPerWeek, ...rest } = bedroom

    return mappedBedrooms.push({
      position: i + 1,
      valid: true,
      rent: rentPerWeek,
      ...rest,
    })
  })

  return mappedBedrooms
}

const mapPhotos = apiProperty => {
  const { photos } = apiProperty

  const initialPhotosObject = {
    images: {
      items: [],
      order: [],
    },
    loading: false,
  }

  if (!photos.length > 0) {
    return initialPhotosObject
  }

  return photos.reduce((accumulator, current) => {
    return {
      images: {
        items: [
          ...accumulator.images.items,
          {
            id: current.position,
            filename: current.filename,
            src: current.src,
          },
        ],
        order: [...accumulator.images.order, current.position],
      },
      loading: false,
    }
  }, initialPhotosObject)
}

const mapAmenities = apiProperty => {
  const { amenities } = apiProperty
  const mappedAmenities = []

  Object.keys(amenities).map(key => {
    return mappedAmenities.push({
      title: key.charAt(0).toUpperCase() + key.slice(1),
      options: amenities[key],
    })
  }, {})

  return mappedAmenities
}

const apiToFinalFormPropertyAdapter = apiProperty => {
  const mappedBedrooms = mapBedrooms(apiProperty)
  const mappedPhotos = mapPhotos(apiProperty)
  const mappedAmenities = mapAmenities(apiProperty)

  const availableUntil =
    apiProperty.availableUntil === 'Not specified' ? '' : apiProperty.availableUntil

  return {
    address: {
      flat: apiProperty.address.flat,
      buildingName: apiProperty.address.buildingName,
      buildingNumber: apiProperty.address.buildingNumber,
      street: apiProperty.address.street,
      townCity: apiProperty.address.townCity,
      postcode: apiProperty.address.postcode,
      area: apiProperty.address.area,
      hasValidLocation: true,
    },
    accommodationType: apiProperty.accommodationType,
    lettingType: apiProperty.lettingType,
    bedrooms: mappedBedrooms,
    bathrooms: apiProperty.bathrooms,
    photos: mappedPhotos,
    availableFrom: apiProperty.availableFrom,
    availableUntil,
    amenities: mappedAmenities,
    bills: apiProperty.bills,
    isBillsOptional: apiProperty.isBillsOptional,
    description: apiProperty.description,
    referenceNumber: apiProperty.referenceNumber,
    videoTourUrl: apiProperty.videoTour.url,
    virtualTourUrl: apiProperty.virtualTourUrl,
  }
}

export default apiToFinalFormPropertyAdapter
