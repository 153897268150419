import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import InfoTooltip from '@afs/components/InfoTooltip'
import matterportUrlValidator from '../../../../utils/matterportUrlValidator'
import FieldAdapter from '../../../adapters/FieldAdapter'

import styles from './virtualTourPage.module.scss'

export const VirtualTourPage = ({
  virtualTourUrl,
  thumbnailImageUrl,
  virtualTourPreviewComponent,
}) => {
  const urlValidationResult = matterportUrlValidator(virtualTourUrl)

  return (
    <>
      <Field name="virtualTourUrl" component={FieldAdapter}>
        {props => (
          <FieldAdapter label="Virtual tour link" placeholder="https://..." optional {...props} />
        )}
      </Field>
      <div className={styles.textSmall}>
        Must be a Matterport virtual tour.{' '}
        <InfoTooltip
          className={styles.tooltip}
          tooltipContent={
            <>
              <p className={styles.tooltipText}>
                At the moment, we <strong>only support virtual tours from Matterport.</strong>
              </p>
              <p className={styles.tooltipText}>
                If you’d like support for other virtual tour providers, please let us know.
              </p>
            </>
          }
        />
      </div>
      {urlValidationResult.isValid &&
        virtualTourPreviewComponent(virtualTourUrl, thumbnailImageUrl)}
    </>
  )
}

export const virtualTourPageValidation = values => {
  const errors = {}

  const result = matterportUrlValidator(values.virtualTourUrl)

  if (values.virtualTourUrl && !result.isValid) {
    errors.virtualTourUrl = result.reason
  }

  return errors
}

VirtualTourPage.propTypes = {
  virtualTourUrl: PropTypes.string,
  thumbnailImageUrl: PropTypes.string.isRequired,
  virtualTourPreviewComponent: PropTypes.func.isRequired,
}
