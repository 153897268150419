import React from 'react'
import PropTypes from 'prop-types'

import { authService } from '../../../container'
import { useAuthContext } from '../../../authContext'
import { useStateContext } from '../../../appStateContext'

import Logo from '../../atoms/Logo'
import PrimaryNavigation from '../../molecules/PrimaryNavigation'
import MobileNavigation from '../../molecules/MobileNavigation'
import BurgerButton from '../../molecules/BurgerButton'
import AdminUserBanner from '../../atoms/AdminUserBanner'

import './styles.scss'

const MobileMenuButton = (props) => <BurgerButton className="site-header__burger" {...props} />

const Header = ({ showNavigation = true, isError, ...props }) => {
  const { user, isAuthenticated, userInitials, accountType, hasActiveFeed } = useAuthContext()
  const { managedAccount } = useStateContext()

  const logoProps = isError ? { emotion: 'sad' } : {}
  const logoLinkHref = '/'
  const showAdminUserBanner = isAuthenticated && user.isAdmin()

  const isFeedAccount = accountType === 'Feed'

  return (
    <div className="site-header__wrapper">
      <header className="site-header" {...props}>
        {showAdminUserBanner && (
          <AdminUserBanner
            managedAccount={{
              fullName: managedAccount.fullNameString(),
              email: managedAccount.emailAddressString(),
            }}
          />
        )}
        <div className="site-header__inner">
          <Logo
            className="site-header__logo"
            href={logoLinkHref}
            renderLinkComponent={(props) => <a {...props} />}
            {...logoProps}
            data-testid="header-logo-link"
          />
          {showNavigation && (
            <>
              <PrimaryNavigation
                id="primary-nav"
                className="site-header__nav"
                userInitials={userInitials}
                isAuthenticated={isAuthenticated}
                signOut={authService.signOut}
                useStateContext={useStateContext}
                isFeedAccount={isFeedAccount}
                hasActiveFeed={hasActiveFeed}
                aria-label="primary navigation"
                role="navigation"
                data-testid="primary-nav"
              />
              <MobileNavigation
                userInitials={userInitials}
                isAuthenticated={isAuthenticated}
                logoLinkHref={logoLinkHref}
                openButton={MobileMenuButton}
                signOut={authService.signOut}
                useStateContext={useStateContext}
                isFeedAccount={isFeedAccount}
                hasActiveFeed={hasActiveFeed}
              />
            </>
          )}
        </div>
      </header>
    </div>
  )
}

Header.propTypes = {
  showNavigation: PropTypes.bool,
  isError: PropTypes.bool,
}

export default Header
