import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import ListRow from '../../atoms/ListRow'
import invoiceListRow from './invoiceListRow.module.scss'

const addressToString = address => {
  return address.filter(line => line !== '').join(', ')
}

const LeftCard = ({ number, lineItems, isDesktop }) => {
  return (
    <div className={invoiceListRow.invoiceDetails}>
      <strong className={invoiceListRow.invoiceNumber} data-testid="invoice-number">
        {`Invoice #${number}`}
      </strong>

      {lineItems.map((item, i) => {
        const propertyAdLineItem = item.address.length > 0
        const titleClasses = classNames(
          propertyAdLineItem ? invoiceListRow.propertyAdTitle : invoiceListRow.title
        )

        return (
          <div
            // eslint-disable-next-line
            key={i}
            className={invoiceListRow.lineItem}
            data-testid="invoice-line-item"
          >
            <p className={titleClasses}>{isDesktop ? item.longTitle : item.shortTitle}</p>
            {propertyAdLineItem && (
              <>
                <span className={invoiceListRow.separator}>·</span>
                <p className={invoiceListRow.address}>{addressToString(item.address)}</p>
              </>
            )}
          </div>
        )
      })}
    </div>
  )
}

const RightCard = ({ date, totalGrossPaid }) => {
  return (
    <>
      <div className={invoiceListRow.dataColumn} data-testid="performance-data-column">
        <strong className={invoiceListRow.value} data-testid="invoice-date">
          {date}
        </strong>
        <p className={invoiceListRow.label}>Date</p>
      </div>
      <div className={invoiceListRow.dataColumn} data-testid="performance-data-column">
        <strong className={invoiceListRow.value} data-testid="invoice-total">
          {totalGrossPaid}
        </strong>
        <p className={invoiceListRow.label}>Total paid</p>
      </div>
    </>
  )
}

const InvoiceListRow = ({ className, invoice, isMobile, isDesktop, ...props }) => {
  const { number, lineItems, date, totalGrossPaid } = invoice

  return (
    <Link
      className={classNames(invoiceListRow.link, className)}
      to={`/landlord-admin/invoices/${number}.pdf`}
      target="_blank"
      rel="noreferrer"
      data-testid="invoice-preview"
      {...props}
    >
      <ListRow
        className={invoiceListRow.wrapper}
        singleCard
        leftCard={<LeftCard number={number} lineItems={lineItems} isDesktop={isDesktop} />}
        rightCard={<RightCard date={date} totalGrossPaid={totalGrossPaid} />}
        isBelowBreakPoint={isMobile}
        leftCardWrapperProps={{ className: invoiceListRow.leftCard }}
        rightCardWrapperProps={{ className: invoiceListRow.rightCard }}
      />
    </Link>
  )
}

LeftCard.propTypes = {
  number: PropTypes.string.isRequired,
  lineItems: PropTypes.array.isRequired,
  isDesktop: PropTypes.bool.isRequired,
}

RightCard.propTypes = {
  date: PropTypes.string.isRequired,
  totalGrossPaid: PropTypes.string.isRequired,
}

InvoiceListRow.propTypes = {
  className: PropTypes.string,
  invoice: PropTypes.object.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
}
export default InvoiceListRow
