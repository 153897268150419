import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { emptyDiscount } from '../../models/order'
import { useStateContext } from '../../appStateContext'
import { useAuthContext } from '../../authContext'
import { createPayment } from '../../api/paymentApi'

import CheckoutPage from '../pages/CheckoutPage'
import LoadingPage from '../pages/LoadingPage'
import ErrorPage from '../pages/ErrorPage'

const CheckoutPageContainer = ({ history }) => {
  const [lineItems, setLineItems] = useState([])
  const [subtotalWithoutVat, setSubtotalWithoutVat] = useState(0)
  const [vatAmount, setVatAmount] = useState(0)
  const [totalWithVat, setTotalWithVat] = useState(0)
  const [discount, setDiscount] = useState(emptyDiscount)
  const [paymentSecret, setPaymentSecret] = useState('')
  const [paymentIntentId, setPaymentIntentId] = useState('')
  const [savedCards, setSavedCards] = useState([])
  const [loading, setLoading] = useState(true)
  const [showError, setShowError] = useState(false)

  const { basket } = useStateContext()
  const { token } = useAuthContext()

  const createPaymentInStripe = async () => {
    const payload = await basket.createPaymentModel()
    const paymentResult = await createPayment(payload, token)

    const { clientSecret, savedCards, paymentIntentId } = paymentResult.paymentInfo

    setPaymentSecret(clientSecret)
    setSavedCards(savedCards)
    setPaymentIntentId(paymentIntentId)
  }

  const getBasketDetails = async () => {
    const discountResponse = await basket.getDiscount()

    if (!discountResponse.success) {
      setShowError(true)
      return
    }

    const orderSubtotalWithoutVat = await basket.subtotalWithoutVat()
    const orderTotalWithVat = await basket.totalWithVat()
    const orderVat = await basket.vatOnly()

    setLineItems(basket.orderSummary())
    setSubtotalWithoutVat(orderSubtotalWithoutVat)
    setVatAmount(orderVat)
    setTotalWithVat(orderTotalWithVat)
    setDiscount(discountResponse.discount)
  }

  useEffect(() => {
    if (basket.isEmpty()) {
      history.push('/landlord-admin')
    }

    Promise.all([createPaymentInStripe(), getBasketDetails()])
      .then(() => {
        setLoading(false)
      })
      .catch(console.error)
  }, [])

  if (showError) return <ErrorPage />

  if (loading) return <LoadingPage />

  return (
    <CheckoutPage
      history={history}
      lineItems={lineItems}
      subtotalWithoutVat={subtotalWithoutVat}
      vatAmount={vatAmount}
      totalWithVat={totalWithVat}
      discount={discount}
      paymentSecret={paymentSecret}
      paymentIntentId={paymentIntentId}
      savedCards={savedCards}
    />
  )
}

CheckoutPageContainer.propTypes = {
  history: PropTypes.object.isRequired,
}

export default CheckoutPageContainer
