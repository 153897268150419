import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useAuthContext } from '../../authContext'
import { notificationService } from '../../container'
import { getAccountBoostCredits } from '../../api/accountApi'

import LoadingPage from '../pages/LoadingPage'
import AccountPageFeeds from '../pages/AccountPageFeeds'

export const FeedAccountContainer = ({
  getAccountBoostCredits,
  accountPage,
  useAuthContext,
  notificationService,
}) => {
  const [loading, setLoading] = useState(true)
  const [boostCredits, setBoostCredits] = useState(null)

  const { token } = useAuthContext()

  useEffect(() => {
    getAccountBoostCredits(token).then(boostCreditsResponse => {
      if (boostCreditsResponse.success) {
        setBoostCredits(boostCreditsResponse.boostCredits)
      } else {
        notificationService.addDeferredWarningNotification(
          'Oops!',
          "Our system couldn't retrieve your boost credits. Please try again later. Our team has been notified and will investigate the issue."
        )
      }
      setLoading(false)
    })
  }, [])

  return loading ? <LoadingPage /> : accountPage(boostCredits)
}

FeedAccountContainer.propTypes = {
  getAccountBoostCredits: PropTypes.func.isRequired,
  accountPage: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
  notificationService: PropTypes.object.isRequired,
}

export const FeedAccountContainerCompositionRoot = () => {
  return (
    <FeedAccountContainer
      getAccountBoostCredits={getAccountBoostCredits}
      accountPage={boostCredits => <AccountPageFeeds boostCredits={boostCredits} />}
      useAuthContext={useAuthContext}
      notificationService={notificationService}
    />
  )
}
