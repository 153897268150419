import { trimLineBreaks } from '../utils/trimLineBreaks'
import { capitaliseFirstWord } from '../utils/capitaliseFirstWord'
import { sanitizeText } from '../utils/sanitizeText'
import formatDateForApi from '../utils/formatDateForApi'

const toApiModel = address => ({
  flat: address.flat,
  buildingName: address.buildingName,
  buildingNumber: address.buildingNumber,
  street: address.street,
  townCity: address.townCity,
  postcode: address.postcode,
  latitude: address.latitude,
  longitude: address.longitude,
  hasValidLocation: address.hasValidLocation,
})

const PropertyDto = formState => {
  const availableFrom = formatDateForApi(formState.availableFrom)

  const availableUntil = formState.availableUntil ? formatDateForApi(formState.availableUntil) : ''

  const description = sanitizeText(capitaliseFirstWord(trimLineBreaks(formState.description)))

  const address = formState.address ? toApiModel(formState.address) : {}

  const videoTourUrl = formState.videoTourUrl || ''

  return {
    ...formState,
    availableFrom,
    availableUntil,
    description,
    address,
    videoTourUrl,
  }
}

export default PropertyDto
