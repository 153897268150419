import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import PropTypes from 'prop-types'

import {
  getAllProperties,
  delistMultipleProperties,
  listMultipleProperties,
} from '../../api/propertyApi'
import { useStateContext } from '../../appStateContext'
import { useAuthContext } from '../../authContext'
import { notificationService } from '../../container'
import { config } from '../../config'

import PropertiesPage from '../pages/PropertiesPage'
import LoadingPage from '../pages/LoadingPage'
import { useIntercom } from 'react-use-intercom'

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

const intercomActive = config.intercomActive && window.innerWidth >= 768

export const PropertiesContainer = ({
  propertiesPage,
  getAllProperties,
  delistMultipleProperties,
  listMultipleProperties,
  useStateContext,
  useAuthContext,
  redirect,
}) => {
  const [serverError, setServerError] = useState(false)
  const [properties, setProperties] = useState([])
  const [filteredProperties, setFilteredProperties] = useState([])
  const [selectedPropertyIds, setSelectedPropertyIds] = useState([])
  const [loading, setLoading] = useState(true)
  const [updatingProperties, setUpdatingProperties] = useState({
    active: false,
    message: '',
  })

  const { token } = useAuthContext()
  const { propertyIds } = useStateContext()

  const { hide, update } = useIntercom()

  const hideIntercomLauncher = () => {
    if (canUseDOM && intercomActive) {
      hide()
      update({ hideDefaultLauncher: true })
    }
  }

  const showIntercomLauncher = () => {
    if (canUseDOM && intercomActive) {
      update({ hideDefaultLauncher: false })
    }
  }

  const syncFilteredPropertiesWithPropertyChanges = (propertiesFromApiResponse) => {
    if (filteredProperties.length > 0) {
      const filteredPropertyIds = filteredProperties.map((property) => property.id)
      const refreshedFilteredProperties = propertiesFromApiResponse.filter((property) =>
        filteredPropertyIds.includes(property.id)
      )

      setFilteredProperties(refreshedFilteredProperties)
    } else {
      setFilteredProperties(propertiesFromApiResponse)
    }
  }

  // useEffect(() => {
  //   setFilteredProperties(properties)
  // }, [JSON.stringify(properties)])

  useEffect(() => {
    getAllProperties(token).then((response) => {
      if (response.success) {
        setProperties(response.properties)
        setFilteredProperties(response.properties)
        setSelectedPropertyIds((prevState) => [...prevState, ...propertyIds.selected()])
        syncFilteredPropertiesWithPropertyChanges(response.properties)
        setLoading(false)
      } else {
        setServerError(true)
      }
    })
  }, [])

  useEffect(() => {
    if (selectedPropertyIds.length === 0) {
      showIntercomLauncher()
    } else {
      hideIntercomLauncher()
    }
    return () => showIntercomLauncher()
  }, [JSON.stringify(selectedPropertyIds)])

  const propertyIsAlreadySelected = (propertyId) => {
    return selectedPropertyIds.includes(propertyId)
  }

  const deselectProperty = (propertyId) => {
    propertyIds.deselect(propertyId)
    setSelectedPropertyIds((previousState) => previousState.filter((id) => id !== propertyId))
  }

  const selectProperty = (propertyId) => {
    propertyIds.select(propertyId)
    setSelectedPropertyIds((previousState) => [...previousState, propertyId])
  }

  const handleSelectProperty = (propertyId) => {
    if (propertyIsAlreadySelected(propertyId)) {
      deselectProperty(propertyId)
    } else {
      selectProperty(propertyId)
    }
  }

  const showUpdatingOverlay = (message) => {
    setUpdatingProperties({
      active: true,
      message,
    })
  }

  const hideUpdatingOverlay = () => {
    setUpdatingProperties({
      active: false,
      message: '',
    })
  }

  const deselectAllProperties = () => {
    setSelectedPropertyIds([])
    propertyIds.deselectAll()
  }

  const selectAllProperties = () => {
    const allPropertyIds = properties.map((property) => property.id)
    setSelectedPropertyIds(allPropertyIds)
    allPropertyIds.map((propertyId) => propertyIds.select(propertyId))
  }

  const delistProperties = (propertyIds) => {
    const propertyText = propertyIds.length > 1 ? 'properties' : 'property'
    showUpdatingOverlay(`Delisting ${propertyText}...`)

    delistMultipleProperties(propertyIds, token).then((response) => {
      const propertyText = propertyIds.length > 1 ? 'properties have' : 'property has'

      if (response.success) {
        deselectAllProperties()
        getAllProperties(token).then((response) => {
          if (response.success) {
            setProperties(response.properties)
            setFilteredProperties(response.properties)
          }

          notificationService.addDeferredSuccessNotification(
            'Success!',
            `Your selected ${propertyText} been delisted.`
          )
        })
      } else {
        notificationService.addDeferredErrorNotification(
          'Error.',
          `Your selected ${propertyText} not been delisted. Please try again later.`
        )
      }

      hideUpdatingOverlay()
    })
  }

  const listProperties = (propertyIds) => {
    const propertyText = propertyIds.length > 1 ? 'properties' : 'property'
    showUpdatingOverlay(`Listing ${propertyText}...`)

    listMultipleProperties(propertyIds, token).then((response) => {
      const propertyText = propertyIds.length > 1 ? 'properties have' : 'property has'

      if (response.success) {
        deselectAllProperties()
        getAllProperties(token).then((response) => {
          if (response.success) {
            setProperties(response.properties)

            setFilteredProperties(response.properties)
          }

          notificationService.addDeferredSuccessNotification(
            'Success!',
            `Your selected ${propertyText} been relisted.`
          )
        })
      } else {
        notificationService.addDeferredErrorNotification(
          'Error.',
          `Your selected ${propertyText} not been relisted. Please try again later.`
        )
      }

      hideUpdatingOverlay()
    })
  }

  const clearNotifications = () => {
    notificationService.clearNotifications()
  }

  if (serverError) {
    return redirect('/landlord-admin/error')
  } else {
    return loading ? (
      <LoadingPage />
    ) : (
      propertiesPage(
        properties,
        filteredProperties,
        setFilteredProperties,
        selectedPropertyIds,
        handleSelectProperty,
        deselectAllProperties,
        selectAllProperties,
        delistProperties,
        listProperties,
        clearNotifications,
        updatingProperties
      )
    )
  }
}

PropertiesContainer.propTypes = {
  propertiesPage: PropTypes.func.isRequired,
  getAllProperties: PropTypes.func.isRequired,
  delistMultipleProperties: PropTypes.func.isRequired,
  listMultipleProperties: PropTypes.func.isRequired,
  useStateContext: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
}

export const PropertiesContainerCompositionRoot = () => {
  return (
    <PropertiesContainer
      propertiesPage={(
        properties,
        filteredProperties,
        setFilteredProperties,
        selectedPropertyIds,
        handleSelectProperty,
        deselectAllProperties,
        selectAllProperties,
        delistProperties,
        listProperties,
        clearNotifications,
        updatingProperties
      ) => (
        <PropertiesPage
          properties={properties}
          filteredProperties={filteredProperties}
          setFilteredProperties={setFilteredProperties}
          selectedPropertyIds={selectedPropertyIds}
          onSelectProperty={handleSelectProperty}
          deselectAllProperties={deselectAllProperties}
          selectAllProperties={selectAllProperties}
          delistProperties={delistProperties}
          listProperties={listProperties}
          clearNotifications={clearNotifications}
          updatingProperties={updatingProperties}
        />
      )}
      getAllProperties={getAllProperties}
      delistMultipleProperties={delistMultipleProperties}
      listMultipleProperties={listMultipleProperties}
      useStateContext={useStateContext}
      useAuthContext={useAuthContext}
      redirect={(path) => <Redirect to={path} />}
    />
  )
}
