import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withRouter } from 'react-router'

import { forgottenPasswordFormViewModel, notificationService } from '../../../container'

import PageTemplate from '../../templates/PageTemplate'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Heading from '../../atoms/Heading'
import Link from '../../atoms/Link'
import ForgottenPasswordForm from '../../organisms/ForgottenPasswordForm'

import './styles.scss'

const ForgottenPasswordPage = ({ className, ...props }) => {
  const componentClasses = classNames('forgot-password', className)
  const { history } = props
  const subHeadingText =
    "Enter the email address associated with your account, and we'll email you a link to reset your password."
  return (
    <PageTemplate
      className={componentClasses}
      header={<Header />}
      footer={<Footer />}
      title="Reset your password | AFS"
    >
      <div className="forgot-password__row">
        <div className="forgot-password__inner">
          <div className="forgot-password__content">
            <Heading className="forgot-password__heading">Reset your password</Heading>
            <p className="forgot-password__sub-heading">{subHeadingText}</p>
            <ForgottenPasswordForm
              className="forgot-password__form"
              history={history}
              viewModel={forgottenPasswordFormViewModel}
              notificationService={notificationService}
            />
            <Link to="/landlord-admin/login" className="forgot-password__back-link">
              Back to login
            </Link>
          </div>
        </div>
      </div>
    </PageTemplate>
  )
}

ForgottenPasswordPage.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
}

export default withRouter(ForgottenPasswordPage)
