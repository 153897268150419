import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import SavedCard from '../SavedCard'
import Money from '../../atoms/Money'
import Link from '../../atoms/Link'
import Overlay from '../Overlay'
import Button from '../../atoms/Button'
import LinkButton from '../../atoms/LinkButton'

import styles from './subscriptionCard.module.scss'

const SubscriptionCard = ({ className, subscription }) => {
  const [changePaymentMethodOverlayActive, setChangePaymentMethodOverlayActive] = useState(false)
  const [cancelSubscriptionOverlayActive, setCancelSubscriptionOverlayActive] = useState(false)

  const {
    status,
    productName,
    hasPaymentMethod,
    paymentMethod,
    pricePerMonth,
    nextPaymentDate,
    cancelsAt,
    isLastPaymentAttempt,
  } = subscription

  const cancelledSubscription = status === 'Cancelled'
  const pastDueSubscription = status === 'Past due'
  const expiredPaymentMethod = hasPaymentMethod && paymentMethod.hasExpired
  const componentClasses = classNames(styles.wrapper, className)

  if (cancelledSubscription)
    return (
      <div className={componentClasses} data-testid="subscription-card">
        <div className={styles.headerWrapper}>
          <p className={styles.name}>{productName}</p>
          <span className={styles.cancelledTag}>Cancelled</span>
        </div>
        <p className={styles.text}>
          This service will remain active until <span className={styles.boldText}>{cancelsAt}</span>
        </p>
      </div>
    )

  return (
    <>
      <div
        className={classNames(className, {
          [styles.expiredPaymentMethodWrapper]: expiredPaymentMethod,
          [styles.pastDueWrapper]: pastDueSubscription && !expiredPaymentMethod,
        })}
        data-testid="subscription-card"
      >
        {expiredPaymentMethod && (
          <div data-testid="expired-payment-method">
            <strong className={styles.notificationHeading}>Your card has expired.</strong>
            <p className={styles.notificationText}>
              To avoid any service interruptions, please{' '}
              <LinkButton
                className={styles.changePaymentLinkButton}
                onClick={() => setChangePaymentMethodOverlayActive(true)}
              >
                change your payment method.
              </LinkButton>
            </p>
          </div>
        )}

        {pastDueSubscription && !expiredPaymentMethod && (
          <p className={styles.notificationText} data-testid="next-payment-attempt">
            The previous automatic payment for this subscription has failed. We’ll try taking the
            payment again on <span className={styles.boldText}>{nextPaymentDate}.</span>
          </p>
        )}
        {isLastPaymentAttempt && !expiredPaymentMethod && (
          <p className={styles.notificationText} data-testid="last-payment-attempt">
            If the payment fails again, your subscription will be cancelled and your properties will
            no longer be visible on the website.
          </p>
        )}

        <div className={styles.wrapper}>
          <div className={styles.headerWrapper}>
            <p className={styles.name}>{productName}</p>
            {pastDueSubscription && <span className={styles.pastDueTag}>Past due</span>}
          </div>
          {hasPaymentMethod ? (
            <SavedCard
              className={classNames(styles.paymentMethod, {
                [styles.paymentMethodNotExpired]: !expiredPaymentMethod,
              })}
              card={paymentMethod}
            />
          ) : (
            <div className={styles.noPaymentMethod} data-testid="no-linked-card">
              <strong className={styles.noPaymentMethodHeading}>No linked card found.</strong>
              <p className={styles.noPaymentMethodText}>
                To avoid any service interruptions, please{' '}
                <LinkButton
                  className={styles.changePaymentLinkButton}
                  onClick={() => setChangePaymentMethodOverlayActive(true)}
                >
                  change your payment method.
                </LinkButton>
              </p>
            </div>
          )}
          <p className={styles.text}>
            Paying{' '}
            <span className={styles.boldText}>
              <Money
                value={{
                  currency: pricePerMonth.currency,
                  value: pricePerMonth.amount,
                }}
              />{' '}
              + VAT
            </span>{' '}
            per month
          </p>
          <p className={styles.textWithBorder}>
            Next payment on <span className={styles.boldText}>{nextPaymentDate}</span>
          </p>
          <button
            className={styles.changePaymentButton}
            onClick={() => setChangePaymentMethodOverlayActive(true)}
          >
            Change payment method
          </button>
          <button
            className={styles.cancelButton}
            onClick={() => setCancelSubscriptionOverlayActive(true)}
          >
            Cancel subscription
          </button>
        </div>
      </div>

      <Overlay
        className={styles.changeOverlay}
        id="change-payment-method-overlay"
        heading="Change payment method"
        isActive={changePaymentMethodOverlayActive}
        onClose={() => setChangePaymentMethodOverlayActive(false)}
        preserveScrollPositionOnClose
      >
        <p className={styles.overlayText}>
          To change your payment method, please contact our sales team on{' '}
          <Link
            className={classNames(styles.link, styles.overlayLink)}
            href="tel:+448000789659"
            target="_blank"
          >
            0800 078 9659
          </Link>{' '}
          or email us at{' '}
          <Link
            className={classNames(styles.link, styles.overlayLink)}
            href={`mailto:support@accommodationforstudents.com?subject=Request to change payment method for ${productName} Subscription`}
            target="_blank"
          >
            support@accommodationforstudents.com
          </Link>
        </p>
        <Button
          className={styles.overlayButton}
          onClick={() => setChangePaymentMethodOverlayActive(false)}
          secondary
        >
          Close
        </Button>
      </Overlay>

      <Overlay
        className={styles.cancelOverlay}
        id="cancel-subscription-overlay"
        heading="Cancel subscription"
        isActive={cancelSubscriptionOverlayActive}
        onClose={() => setCancelSubscriptionOverlayActive(false)}
        preserveScrollPositionOnClose
      >
        <p className={styles.overlayText}>
          To cancel your subscription, please contact our sales team on{' '}
          <Link
            className={classNames(styles.link, styles.overlayLink)}
            href="tel:+448000789659"
            target="_blank"
          >
            0800 078 9659
          </Link>{' '}
          or email us at{' '}
          <Link
            className={classNames(styles.link, styles.overlayLink)}
            href={`mailto:support@accommodationforstudents.com?subject=Request to cancel Subscription for ${productName}`}
            target="_blank"
          >
            support@accommodationforstudents.com
          </Link>
        </p>
        <Button
          className={styles.overlayButton}
          onClick={() => setCancelSubscriptionOverlayActive(false)}
          secondary
        >
          Close
        </Button>
      </Overlay>
    </>
  )
}

SubscriptionCard.propTypes = {
  className: PropTypes.string,
  subscription: PropTypes.shape({
    status: PropTypes.string,
    productName: PropTypes.string,
    pricePerMonth: PropTypes.shape({ amount: PropTypes.number, currency: PropTypes.string }),
    nextPaymentDate: PropTypes.string,
    hasPaymentMethod: PropTypes.bool,
    paymentMethod: PropTypes.shape({
      paymentMethodId: PropTypes.string,
      brand: PropTypes.string,
      lastDigits: PropTypes.string,
      expiryMonth: PropTypes.string,
      expiryYear: PropTypes.string,
    }),
    cancelsAt: PropTypes.string,
    isLastPaymentAttempt: PropTypes.string,
  }).isRequired,
}

export default SubscriptionCard
