import React from 'react'
import PropTypes from 'prop-types'
import SpinnerButton from '@afs/components/SpinnerButton'

import Overlay from '../../../molecules/Overlay'
import Button from '../../../atoms/Button'
import SavedCard from '../../../molecules/SavedCard'

import styles from './paymentSettingsOverlay.module.scss'

const PaymentSettingsOverlay = ({
  action,
  active,
  card,
  handleClose,
  handleRemoveCard,
  loading,
}) => {
  const removeCard = action === 'removeCard'
  const addCard = action === 'addCard'

  const overlayId = removeCard ? 'remove-card' : 'add-card'
  const overlayHeading = removeCard ? 'Remove card' : 'Add card'

  const handleOnClick = async event => {
    event.preventDefault()
    const response = await handleRemoveCard(card.paymentMethodId, card.lastDigits)
    if (response.success) {
      handleClose()
    }
  }

  return (
    <Overlay
      id={overlayId}
      heading={overlayHeading}
      closeLabel="cancel"
      isActive={active}
      onClose={handleClose}
      preserveScrollPositionOnClose
    >
      {removeCard && (
        <div className={styles.overlay}>
          <p className={styles.text}>Are you sure you want to remove this card?</p>
          <SavedCard className={styles.card} card={card} data-testid="card-to-remove" />
          <SpinnerButton
            className={styles.removeButton}
            onClick={handleOnClick}
            inactive={loading}
            deletion
            data-testid="remove-card-button"
          >
            Remove card
          </SpinnerButton>
          <Button className={styles.cancelButton} onClick={handleClose} data-testid="close-button">
            Cancel
          </Button>
        </div>
      )}
    </Overlay>
  )
}

PaymentSettingsOverlay.propTypes = {
  action: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  card: PropTypes.shape({
    brand: PropTypes.string,
    lastDigits: PropTypes.string,
    expiryMonth: PropTypes.string,
    expiryYear: PropTypes.string,
    paymentMethodId: PropTypes.string,
  }),
  handleClose: PropTypes.func.isRequired,
  handleRemoveCard: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default PaymentSettingsOverlay
