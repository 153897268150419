import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Field from '@afs/components/Field'

import { invalidPhoneNumber } from '../../../../utils/validation'

import AccountSettingsForm from '../../../molecules/AccountSettingsForm'

import publicPhoneNumber from './publicPhoneNumber.module.scss'

const PublicPhoneNumberForm = ({ account, onSave, loading, ...props }) => {
  const [fields, setFields] = useState({
    publicPhoneNumber: {
      value: account.publicPhoneNumber,
      touched: false,
      error: '',
    },
    receiveEnquiryMessagesToMobile: {
      value: account.receiveEnquiryMessagesToMobile,
      touched: false,
      error: '',
    },
  })

  const validatePublicPhoneNumber = string => {
    if (invalidPhoneNumber(string)) {
      return 'Valid phone number required.'
    }
    return ''
  }

  const handleChange = event => {
    const inputValue = event.target.value
    const inputName = event.target.name
    const error = validatePublicPhoneNumber(inputValue)
    const { checked } = event.target

    setFields(prevState => ({
      ...prevState,
      [inputName]: {
        value: inputName === 'receiveEnquiryMessagesToMobile' ? checked : inputValue,
        touched: true,
        error: inputName === 'publicPhoneNumber' ? error : '',
      },
    }))
  }

  const handleSave = event => {
    event.preventDefault()

    const fieldName = 'publicPhoneNumber'
    const payload = {
      phoneNumber: fields.publicPhoneNumber.value,
      receiveEnquiriesOnMobile: fields.receiveEnquiryMessagesToMobile.value,
    }

    onSave(fieldName, payload)
  }

  const formIsValid =
    (fields.publicPhoneNumber.touched && fields.publicPhoneNumber.error !== '') ||
    (fields.receiveEnquiryMessagesToMobile.touched &&
      fields.receiveEnquiryMessagesToMobile.error !== '')

  return (
    <AccountSettingsForm onSubmit={handleSave} invalid={formIsValid} loading={loading} {...props}>
      <Field
        name="publicPhoneNumber"
        aria-label="Public phone number"
        value={fields.publicPhoneNumber.value}
        type="tel"
        onChange={handleChange}
        onBlur={handleChange}
        invalid={formIsValid}
        error={fields.publicPhoneNumber.error}
        data-testid="input-field-phone-number"
      />

      <div className={publicPhoneNumber.enquiriesWrapper}>
        <Field
          name="receiveEnquiryMessagesToMobile"
          label="Receive enquiry messages to your mobile number for free."
          aria-label="Receive enquiry messages to mobile"
          type="checkbox"
          checked={fields.receiveEnquiryMessagesToMobile.value}
          onChange={handleChange}
          onBlur={handleChange}
          invalid={formIsValid}
          error={fields.receiveEnquiryMessagesToMobile.error}
          data-testid="input-field-enquiry-preferences"
        />
      </div>
    </AccountSettingsForm>
  )
}

PublicPhoneNumberForm.propTypes = {
  account: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default PublicPhoneNumberForm
