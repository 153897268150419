import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { config } from '../../../config'

import VirtualTourOverlay from '../VirtualTourOverlay'
import LazyImage from '../../atoms/LazyImage'

import TourIcon from '../../../svgs/icons/360.svg'

import styles from './virtualTourPreview.module.scss'

const VirtualTourPreview = ({ className, thumbnailImageUrl, virtualTourUrl }) => {
  const [isOverlayActive, setIsOverlayActive] = useState(false)

  const imageUrl = `${config.imgApiUrl}w=408,h=245,fit=cover/${thumbnailImageUrl}`

  return (
    <>
      <button
        className={classNames(styles.button, className)}
        onClick={() => setIsOverlayActive(true)}
        type="button"
      >
        <div className={styles.thumbnail}>
          <LazyImage src={imageUrl} dataSrc={imageUrl} alt="Preview virtual tour" />
        </div>
        <span className={styles.textWrapper}>
          <span className={styles.iconWrapper}>
            <TourIcon className={styles.icon} />
          </span>
          <span className={styles.buttonText}>Tap to preview</span>
        </span>
      </button>
      <VirtualTourOverlay
        isActive={isOverlayActive}
        onClose={() => setIsOverlayActive(false)}
        virtualTourUrl={virtualTourUrl || ''}
      />
    </>
  )
}

VirtualTourPreview.propTypes = {
  className: PropTypes.string,
  thumbnailImageUrl: PropTypes.string.isRequired,
  virtualTourUrl: PropTypes.string.isRequired,
}

export default VirtualTourPreview
