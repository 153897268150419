import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SpinnerButton from '@afs/components/SpinnerButton'
import InfoTooltip from '@afs/components/InfoTooltip'
import Field from '@afs/components/Field'
import FieldTextarea from '@afs/components/FieldTextarea'

import { includesHTML } from '../../../utils/validation'
import { config } from '../../../config'

import PageTemplate from '../../templates/PageTemplate'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Heading from '../../atoms/Heading'
import Hero from '../../organisms/Hero'
import Button from '../../atoms/Button'
import Address from '../../../models/address'
import Notification from '../../molecules/Notification'

import ThankYouIllustration from '../../../svgs/illustrations/thank-you.svg'

import styles from './submitTestimonialPage.module.scss'
import './styles.scss'

const SubmitTestimonialPage = ({ testimonialRequestData, submitTestimonial }) => {
  const {
    requestedBy,
    requestAccountType,
    tenantFirstName,
    tenantLastName,
    propertyAddress,
    testimonialRequestId,
  } = testimonialRequestData

  const address = new Address(propertyAddress)

  const [fields, setFields] = useState({
    testimonial: { value: '', error: '' },
    firstName: { value: tenantFirstName, error: '' },
    lastName: { value: tenantLastName, error: '' },
    permission: false,
  })
  const [loading, setLoading] = useState(false)
  const [notification, setNotification] = useState({ success: false, error: false })
  const [invalid, setInvalid] = useState(false)

  const { testimonial, permission } = fields

  const handleInputChange = event => {
    const { checked } = event.target
    const inputValue = event.target.value
    const inputName = event.target.name
    let error = ''

    if (inputName !== 'permission') {
      const hasHTML = includesHTML(inputValue)
      hasHTML ? (error = 'HTML tags are not allowed in this field.') : (error = '')
    }

    setFields(prevState => ({
      ...prevState,
      [inputName]: inputName === 'permission' ? checked : { value: inputValue, error },
    }))
  }

  const formIsInvalid =
    !permission ||
    testimonial.value === '' ||
    fields.firstName.value === '' ||
    fields.lastName.value === '' ||
    testimonial.error !== '' ||
    fields.firstName.error !== '' ||
    fields.lastName.error !== ''

  const handleSendTestimonial = () => {
    setLoading(true)

    const payload = {
      testimonialRequestId,
      text: testimonial.value,
      tenantFirstName: fields.firstName.value,
      tenantLastName: fields.lastName.value,
    }

    if (formIsInvalid) {
      setInvalid(true)
      setLoading(false)
    } else {
      submitTestimonial(payload).then(response => {
        if (response.success) {
          setNotification({ success: true, error: false })
          setLoading(false)
        } else {
          setNotification({ success: false, error: true })
          setLoading(false)
        }
      })
    }
  }

  const firstNameLabel = () => {
    return (
      <div className={styles.inputWithTooltip}>
        <span>Your first name</span>
        <InfoTooltip
          className={styles.inputTooltip}
          tooltipContent={
            <span className={styles.tooltiptext}>
              Your first name{' '}
              <span className={styles.tooltiptextHighlighted}>will be displayed</span> on the
              website alongside the testimonial.
            </span>
          }
        />
      </div>
    )
  }

  const testimonialInputLabel = () => {
    return (
      <>
        <p className={styles.text}>Tell us about your experience living here</p>
        <ul className={styles.suggestions}>
          A few things you might want to mention
          <li>the location</li>
          <li>how easy it was getting to and from university</li>
          <li>living in the property</li>
          <li>{`how helpful was the ${requestAccountType}`}</li>
        </ul>
      </>
    )
  }

  const heroText = () => {
    return (
      <>
        <p className={styles.heroText}>{requestedBy}</p>
        <Heading
          className={styles.heroHeading}
          level={1}
        >{`${tenantFirstName}, how did you find living at ${address.firstTwoLines()}?`}</Heading>
        <p className={styles.heroTextSmall}>
          {`Your testimonial will help other students who are considering renting this property from
          this ${requestAccountType}.`}
        </p>
      </>
    )
  }

  return (
    <PageTemplate
      className={styles.template}
      header={<Header />}
      footer={<Footer className={styles.footer} />}
      title="Submit a testimonial | AFS"
    >
      <Hero renderText={heroText} />
      <div className={styles.main}>
        {!notification.success && (
          <div className={styles.form}>
            <FieldTextarea
              className="submit-testimonial-page__text-area"
              name="testimonial"
              label={testimonialInputLabel()}
              aria-label="Your testimonial"
              placeholder="Type here..."
              value={testimonial.value}
              characterLimit={2000}
              maxLength={2000}
              onChange={handleInputChange}
              onBlur={handleInputChange}
              invalid={invalid && (testimonial.value === '' || testimonial.error !== '')}
              error={fields.testimonial.error}
              pageLocation="testimonial"
              data-testid="textarea"
            />
            <Field
              className={styles.input}
              name="firstName"
              label={firstNameLabel()}
              aria-label="Your first name"
              value={fields.firstName.value}
              type="text"
              onChange={handleInputChange}
              onBlur={handleInputChange}
              data-testid="input-field-first-name"
              invalid={invalid && (fields.firstName.value === '' || fields.firstName.error !== '')}
              error={fields.firstName.error}
            />
            <Field
              className={styles.input}
              name="lastName"
              label="Your last name"
              aria-label="Your last name"
              value={fields.lastName.value}
              type="text"
              onChange={handleInputChange}
              onBlur={handleInputChange}
              data-testid="input-field-last-name"
              invalid={invalid && (fields.lastName.value === '' || fields.lastName.error !== '')}
              error={fields.lastName.error}
            />
            <Field
              className={styles.checkbox}
              name="permission"
              label="I give permission for my first name only and testimonial to be displayed on the website."
              aria-label="Permission to display first name and testimonial on the website"
              type="checkbox"
              checked={fields.permission}
              onChange={handleInputChange}
              onBlur={handleInputChange}
              invalid={invalid && !permission}
              data-testid="input-field-permission"
            />
            {invalid && !permission && (
              <div className={styles.permissionError} data-testid="permission-error">
                Your permission is required to submit your testimonial.
              </div>
            )}
            {notification.error && (
              <Notification
                className={styles.notification}
                heading="Oops!"
                text="We were unable to submit your testimonial. Please try again later."
              />
            )}
            <SpinnerButton
              className={styles.button}
              onClick={handleSendTestimonial}
              primary
              inactive={loading}
              data-testid="submit-button"
            >
              Submit testimonial
            </SpinnerButton>
          </div>
        )}
        {notification.success && (
          <div className={styles.thankYou} data-testid="success-notification">
            <ThankYouIllustration className={styles.illustration} />
            <Heading
              level={3}
              className={styles.thankYouHeading}
            >{`Thank you, ${fields.firstName.value}! `}</Heading>
            <p className={styles.thankYouMessage}>Your testimonial has been submitted.</p>
            <Button href={config.mainSiteBaseUrl}>Go to Homepage</Button>
          </div>
        )}
      </div>
    </PageTemplate>
  )
}

SubmitTestimonialPage.propTypes = {
  testimonialRequestData: PropTypes.object.isRequired,
  submitTestimonial: PropTypes.func.isRequired,
}

export default SubmitTestimonialPage
