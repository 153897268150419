import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Heading from '../../atoms/Heading'

import notFound from './notFound.module.scss'

const NotFoundAnnouncement = ({
  className,
  icon: Illustration,
  heading,
  subHeading,
  button,
  children,
  ...props
}) => {
  const componentClasses = classNames(notFound.wrapper, className)

  return (
    <div className={componentClasses} {...props}>
      <Illustration className={notFound.illustration} />
      <Heading level={4} className={notFound.heading} data-testid="not-found-heading">
        {heading}
      </Heading>
      <p className={notFound.subHeading} dangerouslySetInnerHTML={{ __html: subHeading }} />
      {button}
      {children}
    </div>
  )
}

NotFoundAnnouncement.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.func,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  button: PropTypes.node,
  children: PropTypes.node,
}

export default NotFoundAnnouncement
