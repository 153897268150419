import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { loginFormViewModel, notificationService } from '../../container'

import AdminLoginPage from '../pages/AdminLoginPage'

const AdminLoginPageContainer = ({ history }) => {
  return (
    <AdminLoginPage
      history={history}
      loginFormViewModel={loginFormViewModel}
      notificationService={notificationService}
    />
  )
}

AdminLoginPageContainer.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(AdminLoginPageContainer)
