import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { config } from '../../../config'

import LazyImage from '../../atoms/LazyImage'

import NoImagesIcon from './no-images.svg'

import styles from './styles.module.scss'

const PropertyCardWithImageAndAddress = ({ className, photo, hasPhoto, address }) => {
  return (
    <article className={classNames(styles.propertyCard, className)}>
      <div className={styles.imageWrapper}>
        {hasPhoto ? (
          <LazyImage
            className={styles.propertyImage}
            src={`${config.imgApiUrl}w=112,h=112,fit=cover/${photo}`}
            dataSrc={`${config.imgApiUrl}w=112,h=112,fit=cover/${photo}`}
            alt={address.line1}
          />
        ) : (
          <NoImagesIcon className={styles.noImagesIcon} data-testid="no-images-icon" />
        )}
      </div>

      <p className={styles.propertyAddress}>
        <strong className={styles.addressFirstLine}>{address.line1},</strong>
        <span className={styles.addressSecondLine}>{address.line2}</span>
      </p>
    </article>
  )
}

PropertyCardWithImageAndAddress.propTypes = {
  className: PropTypes.string,
  photo: PropTypes.string.isRequired,
  hasPhoto: PropTypes.bool.isRequired,
  address: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
  }).isRequired,
}

export default PropertyCardWithImageAndAddress
