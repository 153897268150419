import React from 'react'
import {
  updateAccountCompanyName,
  updateAccountDescription,
  updateAccountPublicPhoneSettings,
} from '../../../api/accountApi'
import { truncateStringByCharacters } from '../../../utils/truncate'

import CompanyNameForm from '../../organisms/AccountSettingsForms/CompanyNameForm'
import DescriptionForm from '../../organisms/AccountSettingsForms/DescriptionForm'
import PublicPhoneNumberForm from '../../organisms/AccountSettingsForms/PublicPhoneNumberForm'
import PublicPhoneNumberValue from '../../organisms/AccountSettingsValues/PublicPhoneNumberValue'

export default [
  {
    name: 'companyName',
    heading: 'Company name',
    renderForm: ({ ...props }) => <CompanyNameForm {...props} />,
    renderValue: landlord => <p>{landlord.companyNameString() || '-'}</p>,
    saveAction: updateAccountCompanyName,
    expanded: false,
    disabled: false,
    loading: false,
  },
  {
    name: 'description',
    heading: 'About you and your business',
    renderForm: ({ ...props }) => <DescriptionForm {...props} />,
    renderValue: landlord => (
      <p>{truncateStringByCharacters(landlord.descriptionString(), 100) || '-'}</p>
    ),
    saveAction: updateAccountDescription,
    expanded: false,
    disabled: false,
    loading: false,
  },
  {
    name: 'publicPhoneNumber',
    heading: 'Public enquiries phone number',
    renderForm: ({ ...props }) => <PublicPhoneNumberForm {...props} />,
    renderValue: landlord => <PublicPhoneNumberValue landlord={landlord} />,
    saveAction: updateAccountPublicPhoneSettings,
    expanded: false,
    disabled: false,
    loading: false,
  },
]
