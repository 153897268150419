import React from 'react'
import PropTypes from 'prop-types'

import Card from '../../atoms/Card'
import ArrowLink from '../ArrowLink'

import styles from './boostsPurchased.module.scss'

const BoostCreditsPurchasedCard = ({ boostCount, isFeedAccount, ...props }) => {
  const boostPropertiesLink = isFeedAccount
    ? '/landlord-admin/feeds/boost-properties'
    : '/landlord-admin/boost-properties'

  const renderUseBoostsLink = () => (
    <ArrowLink className={styles.link} to={boostPropertiesLink}>
      Use boost credits
    </ArrowLink>
  )

  return (
    <Card bottomCard={renderUseBoostsLink()} data-testid="boost-credits-card" noPadding {...props}>
      <p className={styles.text}>
        <span
          className={styles.count}
          data-testid="boost-credits-card-count"
        >{`+${boostCount}`}</span>
        {' boost credits'}
      </p>
    </Card>
  )
}

BoostCreditsPurchasedCard.propTypes = {
  boostCount: PropTypes.number.isRequired,
  isFeedAccount: PropTypes.bool,
}

export default BoostCreditsPurchasedCard
