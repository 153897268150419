import React from 'react'

// For email form:
import { updateAccountEmailAddress, updateAccountPassword } from '../../../api/accountApi'
import { authService } from '../../../container'

import EmailForm from '../../organisms/AccountSettingsForms/EmailForm'
import PasswordForm from '../../organisms/AccountSettingsForms/PasswordForm'

export default [
  {
    name: 'emailAddress',
    heading: 'Email address',
    renderForm: ({ ...props }) => <EmailForm authService={authService} {...props} />,
    renderValue: landlord => <p className="u-hidden-in-percy">{landlord.emailAddressString()}</p>,
    saveAction: updateAccountEmailAddress,
    expanded: false,
    disabled: false,
    loading: false,
  },
  {
    name: 'password',
    heading: 'Password',
    renderForm: ({ ...props }) => <PasswordForm {...props} />,
    renderValue: () => <p>Change your account password.</p>,
    saveAction: updateAccountPassword,
    expanded: false,
    disabled: false,
    loading: false,
  },
]
