import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import card from './card.module.scss'

const Card = ({
  className,
  topCard,
  topCardBelow,
  bottomCard,
  children,
  noBorder,
  noShadow,
  noPadding,
  morePadding,
  tightPadding,
  light,
  middleCardClass,
  ...props
}) => {
  const wrapperClasses = classNames(
    card.wrapper,
    {
      [card.noBorder]: noBorder,
      [card.lightBorder]: light,
    },
    className
  )

  const topCardClasses = classNames(card.topCard, {
    [card.topCardBelow]: topCardBelow,
  })

  const middleCardClasses = classNames(
    card.middleCard,
    {
      [card.middleWithTopCard]: topCard && !topCardBelow,
      [card.morePadding]: morePadding,
      [card.tightPadding]: tightPadding,
      [card.noPadding]: noPadding,
      [card.noShadow]: noShadow,
    },
    middleCardClass
  )

  const bottomCardClasses = classNames(card.bottomCard, {
    [card.morePadding]: morePadding,
    [card.tightPadding]: tightPadding,
    [card.noPadding]: noPadding,
    [card.lightBackground]: light,
  })

  return (
    <div className={wrapperClasses} {...props}>
      {topCard && (
        <div className={topCardClasses} data-testid="top-card">
          {topCard}
        </div>
      )}
      <div className={middleCardClasses} data-testid="middle-card">
        {children}
      </div>
      {bottomCard && (
        <div className={bottomCardClasses} data-testid="bottom-card">
          {bottomCard}
        </div>
      )}
    </div>
  )
}

Card.propTypes = {
  className: PropTypes.string,
  topCard: PropTypes.any,
  topCardBelow: PropTypes.bool,
  bottomCard: PropTypes.any,
  children: PropTypes.any,
  noBorder: PropTypes.bool,
  noShadow: PropTypes.bool,
  noPadding: PropTypes.bool,
  morePadding: PropTypes.bool,
  tightPadding: PropTypes.bool,
  light: PropTypes.bool,
}

export default Card
