import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Tick from '../../../svgs/icons/tick-circle.svg'

import styles from './fieldPaymentMethodGroup.module.scss'

const FieldPaymentMethodGroup = ({
  className,
  name,
  selectedOption,
  handleSelectOption,
  advertiseCredits,
  notEnoughCredits,
}) => {
  const advertiseCreditsSelected = selectedOption === 'advertiseCredits'
  const cardPaymentSelected = selectedOption === 'cardPayment'
  const moreThanOneCreditRemaining = advertiseCredits.unlimited || advertiseCredits.amount > 1

  const componentClasses = classNames(styles.fieldset, className)

  const numberOfCreditsWithCommaSeparatedThousands = number => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const [showShakeAnimation, setShowShakeAnimation] = useState(false)

  const handleClickOnDisabledAdvertiseCreditsField = () => {
    setShowShakeAnimation(true)
    setTimeout(() => setShowShakeAnimation(false), 600)
  }

  const renderDisabledAdvertiseCreditsOption = () => {
    return (
      <li
        className={classNames(styles.listItem, styles.listItemDisabled)}
        data-testid="not-enough-credits"
      >
        <span
          className={classNames(styles.notEnoughCreditsText, {
            [styles.shakeAnimation]: showShakeAnimation,
          })}
          data-testid="not-enough-credits-text"
        >
          Not enough credits to pay for this transaction.
        </span>
        <input
          className={styles.input}
          name={name}
          id="advertise-credits"
          value="advertiseCredits"
          type="radio"
          checked={advertiseCreditsSelected}
          onChange={() => handleSelectOption('advertiseCredits')}
          disabled
          data-testid="input-advertise-credits"
        />
        <label
          className={styles.label}
          htmlFor="advertise-credits"
          onClick={handleClickOnDisabledAdvertiseCreditsField}
          data-testid="not-enough-credits-label"
        >
          <Tick className={styles.tick} />
          <div>
            <span className={styles.optionHeading}>Advertise credits</span>
            <span className={styles.optionSubheading} data-testid="advertising-period">
              {advertiseCredits.advertisingPeriod}
            </span>
          </div>
          <div className={styles.creditCounter}>
            <span className={styles.creditsRemaining} data-testid="credits-remaining">
              {numberOfCreditsWithCommaSeparatedThousands(advertiseCredits.amount)}
            </span>
            <span className={styles.optionSubheading} data-testid="credits-text">
              {moreThanOneCreditRemaining ? 'credits' : 'credit'}
            </span>
          </div>
        </label>
      </li>
    )
  }

  return (
    <fieldset className={componentClasses}>
      <legend className={styles.legend}>Select an option</legend>
      <ul className={styles.list}>
        {notEnoughCredits ? (
          renderDisabledAdvertiseCreditsOption()
        ) : (
          <li className={styles.listItem} data-testid="advertise-credits">
            <input
              className={styles.input}
              name={name}
              id="advertise-credits"
              value="advertiseCredits"
              type="radio"
              checked={advertiseCreditsSelected}
              onChange={() => handleSelectOption('advertiseCredits')}
              data-testid="input-advertise-credits"
            />
            <label className={styles.label} htmlFor="advertise-credits">
              <Tick className={styles.tick} />
              <div>
                <span className={styles.optionHeading}>Advertise credits</span>
                <span className={styles.optionSubheading} data-testid="advertising-period">
                  {advertiseCredits.advertisingPeriod}
                </span>
              </div>
              <div className={styles.creditCounter}>
                {advertiseCredits.unlimited ? (
                  <span
                    className={styles.unlimitedCreditsRemaining}
                    data-testid="credits-remaining"
                  >
                    Unlimited
                  </span>
                ) : (
                  <span className={styles.creditsRemaining} data-testid="credits-remaining">
                    {numberOfCreditsWithCommaSeparatedThousands(advertiseCredits.amount)}
                  </span>
                )}
                <span className={styles.optionSubheading} data-testid="credits-text">
                  {moreThanOneCreditRemaining ? 'credits' : 'credit'}
                </span>
              </div>
            </label>
          </li>
        )}
        <li className={styles.listItem}>
          <input
            className={styles.input}
            name={name}
            id="card-payment"
            value="cardPayment"
            type="radio"
            checked={cardPaymentSelected}
            onChange={() => handleSelectOption('cardPayment')}
            data-testid="input-card-payment"
          />
          <label className={styles.label} htmlFor="card-payment">
            <Tick className={styles.tick} />
            <span className={styles.optionHeading}>Card payment</span>
          </label>
        </li>
      </ul>
    </fieldset>
  )
}

FieldPaymentMethodGroup.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  selectedOption: PropTypes.string,
  handleSelectOption: PropTypes.func.isRequired,
  advertiseCredits: PropTypes.object.isRequired,
  notEnoughCredits: PropTypes.bool.isRequired,
}

export default FieldPaymentMethodGroup
