import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Address from '../../../models/address'
import useMedia from '../../../hooks/useMedia'
import { config } from '../../../config'

import ManagePropertiesPageTemplate from '../../templates/ManagePropertiesPageTemplate'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import ManagePropertiesHero from '../../atoms/ManagePropertiesHero'
import Heading from '../../atoms/Heading'
import Button from '../../atoms/Button'
import LinkButton from '../../atoms/LinkButton'
import TestimonialListRow from '../../molecules/TestimonialListRow/index'
import NotFoundAnnouncement from '../../molecules/NotFoundAnnouncement'
import IconButton from '../../molecules/IconButton'
import ViewTestimonialOverlay from './overlays/ViewTestimonial/ViewTestimonialOverlay'
import ViewLandlordTestimonialOverlay from './overlays/ViewLandlordTestimonial/ViewLandlordTestimonialOverlay'
import RequestOverlay from './overlays/RequestTestimonial/RequestOverlay'
import DeleteTestimonialOverlay from './overlays/DeleteTestimonial/DeleteTestimonialOverlay'
import Accordion from '../../molecules/Accordion'
import Notification from '../../molecules/Notification'

import Arrow from '../../../svgs/icons/chevron-right.svg'
import NewProperty from '../../../svgs/illustrations/new-property.svg'
import ChevronCircle from '../../../svgs/icons/chevron-circle.svg'
import Info from '../../../svgs/icons/info-square.svg'

import hero from '../PropertiesPage/modules/hero.module.scss'
import noProperties from '../PropertiesPage/modules/noProperties.module.scss'
import styles from './testimonialsPage.module.scss'
import actionsBanner from '../PropertyDetailsPage/modules/actionsBanner.module.scss'

const TestimonialsPage = ({
  testimonials,
  landlordTestimonials,
  requestTestimonial,
  deleteTestimonial,
  viewTestimonialParams,
  isLettingAgent,
  markTestimonialAsNotNew,
  toggleDisplayTestimonial,
  toggleDisplayLandlordTestimonial,
  clearNotifications,
  window,
}) => {
  const { accountNameForEmailPreview, properties } = testimonials

  const propertyAddresses = properties.map(property => {
    const address = new Address(property.address)
    const formattedAddress = address.addressFields().join(', ')

    return {
      value: { address: formattedAddress, propertyId: property.propertyId },
      label: formattedAddress,
      displayLabel: {
        firstLineOfAddress: address.firstTwoLines(),
        restOfAddress: address.withoutFirstTwoLines().join(', '),
      },
    }
  })

  const landlordHasProperties = properties.length > 0
  const landlordHasLandlordTestimonials = landlordTestimonials.length > 0

  const [overlayState, setOverlayState] = useState({
    viewTestimonial: {
      active: false,
      data: {
        propertyDetails: null,
        testimonial: null,
      },
    },
    viewLandlordTestimonial: {
      active: false,
      data: {
        testimonial: null,
      },
    },
    requestTestimonial: {
      active: false,
      data: {
        selectedProperty: { value: '', id: '' },
        preselectedAddress: true,
      },
    },
    deleteTestimonial: {
      active: false,
      data: { testimonialId: null, error: false },
    },
  })

  const openViewTestimonialOverlay = (property, testimonial) => {
    clearNotifications()

    if (testimonial.new) {
      markTestimonialAsNotNew(testimonial.id)
    }

    const propertyAddress = new Address(property.address)

    const propertyDetails = {
      id: property.propertyId,
      image: property.coverPhoto,
      addressFirstLine: propertyAddress.firstLine(),
      addressSecondLine: propertyAddress.withoutFirstLineAndPostcode().join(', '),
      addressPostcode: propertyAddress.postcodeOnly(),
    }

    setOverlayState(prevState => ({
      ...prevState,
      viewTestimonial: {
        active: true,
        data: {
          propertyDetails,
          testimonial,
        },
      },
    }))
  }

  const closeViewTestimonialOverlay = () => {
    clearNotifications()

    setOverlayState(prevState => ({
      ...prevState,
      viewTestimonial: {
        active: false,
        data: {
          propertyDetails: null,
          testimonial: null,
        },
      },
    }))
  }

  const openViewLandlordTestimonialOverlay = testimonial => {
    clearNotifications()

    setOverlayState(prevState => ({
      ...prevState,
      viewLandlordTestimonial: {
        active: true,
        data: {
          testimonial,
        },
      },
    }))
  }

  const closeViewLandlordTestimonialOverlay = () => {
    clearNotifications()

    setOverlayState(prevState => ({
      ...prevState,
      viewLandlordTestimonial: {
        active: false,
        data: {
          testimonial: null,
        },
      },
    }))
  }

  useEffect(() => {
    if (viewTestimonialParams) {
      const { property, testimonial } = viewTestimonialParams
      openViewTestimonialOverlay(property, testimonial)
    }
  }, [viewTestimonialParams])

  const showRequestOverlay = propertyDetails => {
    clearNotifications()
    setOverlayState(prevState => ({
      ...prevState,
      requestTestimonial: {
        active: true,
        data: {
          selectedProperty: { value: propertyDetails || '' },
          preselectedAddress: !!propertyDetails,
        },
      },
    }))
  }

  const handleRequestOverlayClose = () => {
    setOverlayState(prevState => ({
      ...prevState,
      requestTestimonial: {
        active: false,
        data: {
          selectedProperty: { value: '', id: '' },
          preselectedAddress: true,
        },
      },
    }))
  }

  const openDeleteTestimonialOverlay = id => {
    setOverlayState(prevState => ({
      ...prevState,
      deleteTestimonial: {
        active: true,
        data: {
          testimonialId: id,
          error: false,
        },
      },
    }))

    closeViewTestimonialOverlay()
  }

  const closeDeleteTestimonialOverlay = () => {
    setOverlayState(prevState => ({
      ...prevState,
      deleteTestimonial: {
        active: false,
        data: {
          testimonialId: null,
          error: false,
        },
      },
    }))

    closeViewTestimonialOverlay()
  }

  const handleDeleteTestimonial = async id => {
    const response = await deleteTestimonial(id)

    if (response.success) {
      closeDeleteTestimonialOverlay()
    } else {
      setOverlayState(prevState => ({
        ...prevState,
        deleteTestimonial: {
          active: true,
          data: {
            testimonialId: id,
            error: true,
          },
        },
      }))
    }
  }

  const renderButtonBanner = () => {
    const isMobile = useMedia(['(max-width: 768px)'], [true], false)

    if (!isMobile) return null

    return (
      <div className={actionsBanner.wrapper}>
        <Button primary className={actionsBanner.button} onClick={() => showRequestOverlay()}>
          Request a testimonial
        </Button>
      </div>
    )
  }

  const renderHero = () => {
    const rowClasses = classNames(hero.row, styles.heroRow)
    const buttonClasses = classNames(hero.button, styles.heroButton)
    return (
      <ManagePropertiesHero
        hasPatternBackgroundOnMobile
        pattern="green"
        banner={renderButtonBanner()}
      >
        <div className={rowClasses}>
          <Heading level={1} className={hero.heading}>
            Testimonials
          </Heading>
          <Button
            className={buttonClasses}
            primary
            onClick={() => showRequestOverlay()}
            data-testid="header-button"
          >
            Request a testimonial
          </Button>
        </div>
      </ManagePropertiesHero>
    )
  }

  const renderTestimonials = (property, testimonials) => {
    return testimonials.map(testimonial => (
      <TestimonialListRow
        key={`${property.propertyId}-${testimonial.id}`}
        className={styles.listRow}
        testimonial={testimonial}
        viewTestimonial={() => openViewTestimonialOverlay(property, testimonial)}
        markAsNotNew={markTestimonialAsNotNew}
        toggleDisplay={toggleDisplayTestimonial}
      />
    ))
  }

  const renderLandlordTestimonials = testimonials => {
    return testimonials.map(testimonial => (
      <TestimonialListRow
        key={testimonial.id}
        className={styles.listRow}
        testimonial={testimonial}
        viewTestimonial={() => openViewLandlordTestimonialOverlay(testimonial)}
        markAsNotNew={markTestimonialAsNotNew}
        toggleDisplay={toggleDisplayLandlordTestimonial}
      />
    ))
  }

  const renderNotFoundAnnouncement = () => {
    return (
      <div className={styles.layout}>
        <NotFoundAnnouncement
          className={styles.noProperties}
          icon={NewProperty}
          heading="You haven't added any properties yet"
          subHeading="Adding a property is super easy and it only takes a minute."
          button={
            <IconButton
              className={`${hero.button} ${noProperties.button}`}
              to="/landlord-admin/add-property"
              icon={{
                icon: 'plus-circle',
                width: '24',
                height: '24',
              }}
              primary
              data-testid="no-properties-button"
            >
              Add property
            </IconButton>
          }
          data-testid="no-properties"
        />
      </div>
    )
  }

  const openTestimonialPreview = (tenantFirstName, selectedProperty) => {
    let propertyAddress

    if (!selectedProperty) {
      propertyAddress = ''
    } else {
      propertyAddress = propertyAddresses.find(
        address => address.value.propertyId === selectedProperty.propertyId
      ).displayLabel.firstLineOfAddress
    }

    const requestedBy = isLettingAgent
      ? accountNameForEmailPreview
      : `Your landlord, ${accountNameForEmailPreview},`
    const accountType = isLettingAgent ? 'letting agent' : 'landlord'

    window.open(
      `${config.baseUrl}preview-testimonial-request?tenant=${tenantFirstName}&address=${propertyAddress}&requestedBy=${requestedBy}&accountType=${accountType}`,
      '_blank'
    )
  }

  return (
    <ManagePropertiesPageTemplate
      title="Testimonials | AFS"
      header={<Header />}
      footer={<Footer dark />}
      hero={renderHero()}
    >
      {landlordHasProperties || landlordHasLandlordTestimonials ? (
        <div className={styles.layout}>
          {landlordHasProperties && (
            <div className={styles.propertyTestimonials} data-testid="property-testimonial-section">
              {landlordHasLandlordTestimonials && (
                <Heading
                  className={styles.subHeadingProperty}
                  level={3}
                  data-testid="property-testimonials-sub-heading"
                >
                  Property testimonials
                </Heading>
              )}
              {properties.map(property => {
                const { testimonials, address, testimonialsCount, propertyId } = property
                const propertyHasTestimonials = testimonialsCount > 0
                const propertyHasOneTestimonial = testimonialsCount === 1
                const testimonialAddress = new Address(address)
                const formattedAddress = testimonialAddress.addressFields().join(', ')
                const propertyDetails = {
                  address: formattedAddress,
                  propertyId,
                }

                const renderPropertyDetails = accordionExpanded => {
                  return (
                    <div className={styles.propertyDetails}>
                      <div className={styles.addressAndDropdownIcon}>
                        <p className={styles.address}>
                          <span className={styles.firstLine}>
                            {testimonialAddress.firstTwoLines()},
                          </span>{' '}
                          {testimonialAddress.withoutFirstTwoLinesAndPostcode().map(line => (
                            <span key={line}>{`${line}, `}</span>
                          ))}
                          <span className={styles.postcode}>
                            {testimonialAddress.postcodeOnly()}
                          </span>
                        </p>
                        {accordionExpanded ? (
                          <ChevronCircle className={styles.collapseIcon} />
                        ) : (
                          <ChevronCircle className={styles.expandIcon} />
                        )}
                      </div>
                      {propertyHasTestimonials && (
                        <p className={styles.testimonialText} data-testid="testimonial-text">
                          {`${testimonialsCount} ${
                            propertyHasOneTestimonial ? 'testimonial' : 'testimonials'
                          }`}
                        </p>
                      )}
                    </div>
                  )
                }

                const renderRequestTestimonialButton = accordionExpanded => {
                  const componentClasses = classNames(styles.requestButton, {
                    [styles.requestButtonHidden]: !accordionExpanded,
                  })

                  return (
                    <LinkButton
                      className={componentClasses}
                      onClick={() => showRequestOverlay(propertyDetails)}
                      data-testid="request-link"
                    >
                      <span> Request a testimonial </span>
                      <Arrow className={styles.arrow} />
                    </LinkButton>
                  )
                }

                const renderTestimonialItems = () => {
                  if (propertyHasTestimonials) {
                    const testimonialItems = renderTestimonials(property, testimonials)

                    testimonialItems.push(
                      <LinkButton
                        key={`${propertyId}-link-button`}
                        className={styles.requestButtonMobile}
                        onClick={() => showRequestOverlay(propertyDetails)}
                      >
                        <span>Request a testimonial</span>
                        <Arrow className={styles.arrow} />
                      </LinkButton>
                    )

                    return testimonialItems
                  }

                  return [
                    <div
                      className={styles.noTestimonialWrapper}
                      key={`${propertyId}-no-testimonials`}
                    >
                      <div className={styles.noTestimonialInner}>
                        <p className={styles.noTestimonialText}>
                          You don't have any testimonials for this property.
                        </p>
                        <Button
                          className={styles.noTestimonialButton}
                          onClick={() => showRequestOverlay(propertyDetails)}
                          data-testid="no-testimonial-button"
                        >
                          Request a testimonial
                        </Button>
                      </div>
                    </div>,
                  ]
                }

                return (
                  <div
                    className={styles.testimonialSection}
                    key={property.id}
                    data-testid="testimonial-section"
                  >
                    <div className={styles.testimonialInfo}>
                      <Accordion
                        id="testimonials"
                        isExpanded
                        renderTitle={renderPropertyDetails}
                        renderHeaderElement={renderRequestTestimonialButton}
                        items={renderTestimonialItems()}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          )}

          {landlordHasLandlordTestimonials && (
            <div className={styles.landlordTestimonials} data-testid="landlord-testimonial-section">
              <Heading className={styles.subHeadingLandlord} level={3}>
                Legacy testimonials
              </Heading>
              <Notification
                className={styles.infoNotification}
                type="info"
                heading=""
                text={[
                  <Info className={styles.infoIcon} key={1} />,
                  <span className={styles.infoText} key={2}>
                    Based on student feedback, we’ve changed testimonials to be property specific.
                    These are <strong>your old testimonials</strong> which are non property
                    specific. This type of testimonial <strong>can no longer be requested</strong>,
                    however we can still display them on all of your advertised properties.
                  </span>,
                ]}
                nonDismissible
              />
              {renderLandlordTestimonials(landlordTestimonials)}
            </div>
          )}
        </div>
      ) : (
        renderNotFoundAnnouncement()
      )}

      <ViewTestimonialOverlay
        testimonial={overlayState.viewTestimonial.data.testimonial}
        propertyDetails={overlayState.viewTestimonial.data.propertyDetails}
        toggleDisplayTestimonial={(testimonialId, testimonialDisplayed) =>
          toggleDisplayTestimonial(
            testimonialId,
            testimonialDisplayed,
            overlayState.viewTestimonial.active
          )
        }
        isActive={overlayState.viewTestimonial.active}
        handleCloseOverlay={closeViewTestimonialOverlay}
        openDeleteTestimonialOverlay={() => {
          openDeleteTestimonialOverlay(overlayState.viewTestimonial.data.testimonial.id)
        }}
      />
      <ViewLandlordTestimonialOverlay
        testimonial={overlayState.viewLandlordTestimonial.data.testimonial}
        isActive={overlayState.viewLandlordTestimonial.active}
        toggleDisplayTestimonial={(testimonialId, testimonialDisplayed) =>
          toggleDisplayLandlordTestimonial(
            testimonialId,
            testimonialDisplayed,
            overlayState.viewLandlordTestimonial.active
          )
        }
        handleCloseOverlay={closeViewLandlordTestimonialOverlay}
      />
      <RequestOverlay
        isActive={overlayState.requestTestimonial.active}
        handleOverlayClose={handleRequestOverlayClose}
        overlayData={overlayState.requestTestimonial.data}
        propertyAddresses={propertyAddresses}
        handleRequestTestimonial={requestTestimonial}
        openTestimonialPreview={openTestimonialPreview}
        window={window}
      />
      <DeleteTestimonialOverlay
        isActive={overlayState.deleteTestimonial.active}
        handleDeleteTestimonial={() =>
          handleDeleteTestimonial(overlayState.deleteTestimonial.data.testimonialId)
        }
        handleCloseOverlay={closeDeleteTestimonialOverlay}
        showError={overlayState.deleteTestimonial.data.error}
      />
    </ManagePropertiesPageTemplate>
  )
}

TestimonialsPage.propTypes = {
  testimonials: PropTypes.object.isRequired,
  landlordTestimonials: PropTypes.array.isRequired,
  requestTestimonial: PropTypes.func.isRequired,
  deleteTestimonial: PropTypes.func.isRequired,
  markTestimonialAsNotNew: PropTypes.func.isRequired,
  toggleDisplayTestimonial: PropTypes.func.isRequired,
  toggleDisplayLandlordTestimonial: PropTypes.func.isRequired,
  viewTestimonialParams: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      property: PropTypes.object.isRequired,
      testimonial: PropTypes.object.isRequired,
    }),
  ]),
  isLettingAgent: PropTypes.bool.isRequired,
  clearNotifications: PropTypes.func.isRequired,
  window: PropTypes.object.isRequired,
}

export default TestimonialsPage
