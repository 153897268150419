import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import Property from './models/property'

import LoadingPage from './components/pages/LoadingPage'
import PropertyNotFoundPage from './components/pages/PropertyNotFoundPage'

const PropertyContext = React.createContext()

export const PropertyContextProvider = ({ children, getProperty, propertyId }) => {
  const [loading, setLoading] = useState(true)
  const [property, setProperty] = useState(
    new Property({
      id: '',
      landlordId: '',
      address: {
        flat: '',
        buildingName: '',
        buildingNumber: '',
        street: '',
        townCity: '',
        postcode: '',
        area: '',
      },
      accommodationType: '',
      bedrooms: [],
      lettingType: '',
      amenities: {
        appliances: [],
        interior: [],
        exterior: [],
        services: [],
      },
      bills: [],
      bathrooms: 0,
      photos: [],
      availableFrom: '',
      availableUntil: '',
      availabilityDisplay: [],
      referenceNumber: '',
      description: '',
    })
  )

  const refreshProperty = () => {
    getProperty(propertyId, response => {
      if (response.success) {
        setProperty(new Property(response.property))
        setLoading(false)
      } else {
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    refreshProperty()
  }, [])

  const state = {
    property,
    refreshProperty,
  }

  if (loading) return <LoadingPage />
  if (!property.id) return <PropertyNotFoundPage />

  return <PropertyContext.Provider value={state}>{children}</PropertyContext.Provider>
}

PropertyContextProvider.propTypes = {
  children: PropTypes.node,
  getProperty: PropTypes.func,
  propertyId: PropTypes.string,
}

export const usePropertyContext = () => useContext(PropertyContext)
