import React from 'react'
import {
  updateAccountReceiveExclusiveOffers,
  updateAccountReceiveEnquiryMessagesToMobile,
} from '../../../api/accountApi'

import ReceiveExclusiveOffersForm from '../../organisms/AccountSettingsForms/ReceiveExclusiveOffersForm'
import ReceiveEnquiriesOnMobileForm from '../../organisms/AccountSettingsForms/ReceiveEnquiriesOnMobileForm'

export const landlordFieldConfig = [
  {
    name: 'receiveExclusiveOffers',
    heading: 'Receive exclusive offers from AFS and/or other third parties',
    renderForm: ({ ...props }) => <ReceiveExclusiveOffersForm {...props} />,
    renderValue: account => <p>{account.receiveOffers() ? 'Yes' : 'No'}</p>,
    saveAction: updateAccountReceiveExclusiveOffers,
    expanded: false,
    disabled: false,
    loading: false,
  },
  {
    name: 'receiveEnquiryMessagesToMobile',
    heading: 'Receive enquiry messages to your mobile for free',
    renderForm: ({ ...props }) => <ReceiveEnquiriesOnMobileForm {...props} />,
    renderValue: account => <p>{account.receiveEnquiriesToMobile() ? 'Yes' : 'No'}</p>,
    saveAction: updateAccountReceiveEnquiryMessagesToMobile,
    expanded: false,
    disabled: false,
    loading: false,
  },
]

export const lettingAgentFieldConfig = [
  {
    name: 'receiveExclusiveOffers',
    heading: 'Receive exclusive offers from AFS and/or other third parties',
    renderForm: ({ ...props }) => <ReceiveExclusiveOffersForm {...props} />,
    renderValue: account => <p>{account.receiveOffers() ? 'Yes' : 'No'}</p>,
    saveAction: updateAccountReceiveExclusiveOffers,
    expanded: false,
    disabled: false,
    loading: false,
  },
]
