import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Spinner from '@afs/components/Spinner'

import toggle from './toggle.module.scss'

const TinyToggle = ({ className, enabled, toggleText, inactive, handleOnClick, ...props }) => {
  const wrapperClasses = classNames(toggle.wrapper, className)
  const innerClasses = classNames(toggle.inner, {
    [toggle.inactive]: inactive,
    [toggle.enabled]: enabled && !inactive,
    [toggle.disabled]: !enabled && !inactive,
  })

  const renderTestId = () => {
    if (inactive) return 'toggle-inactive'
    if (enabled && !inactive) return 'toggle-enabled'
    return 'toggle-disabled'
  }

  return (
    <button
      className={wrapperClasses}
      onClick={handleOnClick}
      disabled={inactive}
      data-testid="toggle"
      {...props}
    >
      <span className={innerClasses} data-testid={renderTestId()}>
        <Spinner className={toggle.spinner} data-testid="toggle-spinner" />
        <span className={toggle.enabledSwitch} />
        <span className={toggle.disabledSwitch} />
      </span>
      {toggleText && <>{enabled ? toggleText.enabled : toggleText.disabled}</>}
    </button>
  )
}

TinyToggle.propTypes = {
  className: PropTypes.string,
  enabled: PropTypes.bool.isRequired,
  toggleText: PropTypes.shape({
    enabled: PropTypes.any.isRequired,
    disabled: PropTypes.any.isRequired,
  }),
  inactive: PropTypes.bool,
  handleOnClick: PropTypes.func.isRequired,
}

export default TinyToggle
