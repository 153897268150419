import React from 'react'
import PropTypes from 'prop-types'

import RegisterFormPageTemplate from '../../templates/RegisterFormPageTemplate'
import LandlordRegisterForm from '../../organisms/LandlordRegisterForm'

import styles from './styles.module.scss'

const LandlordRegisterPage = ({ createAccount }) => {
  return (
    <RegisterFormPageTemplate title="Create Landlord Account | AFS">
      <div className={styles.formWrapper}>
        <LandlordRegisterForm createAccount={createAccount} />
      </div>
    </RegisterFormPageTemplate>
  )
}

LandlordRegisterPage.propTypes = {
  createAccount: PropTypes.func.isRequired,
}

export default LandlordRegisterPage
