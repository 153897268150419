import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  SuccessPill,
  WarningPill,
  ErrorPill,
  AdvertisedInTheFuturePill,
} from '@afs/components/Pill'

import styles from './styles.module.scss'

const AdStatusTag = ({ className, status, isListed, startDate, expiryDate, alignRight }) => {
  const groupClasses = classNames(styles.group, className, {
    [styles.alignRight]: alignRight,
  })

  const adStatusTestId = name => {
    return `ad-status-tag-${name}`
  }

  const AdStatusComponent = () => {
    if (status === 'CurrentlyAdvertised' && !isListed) {
      return (
        <div className={groupClasses}>
          <WarningPill className={styles.pill} data-testid={adStatusTestId('unlisted')}>
            Unlisted property
          </WarningPill>
          <SuccessPill className={styles.pill} data-testid={adStatusTestId('paid-until')}>
            <span>{'Paid for until '}</span>
            <span className={styles.date}>{expiryDate}</span>
          </SuccessPill>
        </div>
      )
    } else if (status === 'AdvertisedInTheFuture') {
      return (
        <AdvertisedInTheFuturePill
          className={styles.pill}
          data-testid={adStatusTestId('advertised-from')}
        >
          <span>{'Advertised from '}</span>
          <span className={styles.date}>{startDate}</span>
        </AdvertisedInTheFuturePill>
      )
    } else if (status === 'CurrentlyAdvertised') {
      return (
        <SuccessPill className={styles.pill} data-testid={adStatusTestId('advertised')}>
          <span>{'Advertised until '}</span>
          <span className={styles.date}>{expiryDate}</span>
        </SuccessPill>
      )
    } else {
      return (
        <ErrorPill className={styles.pill} data-testid={adStatusTestId('not-advertised')}>
          Not advertised
        </ErrorPill>
      )
    }
  }

  return AdStatusComponent()
}

AdStatusTag.propTypes = {
  className: PropTypes.string,
  advertised: PropTypes.bool,
  advertisedInFuture: PropTypes.bool,
  isListed: PropTypes.bool,
  startDate: PropTypes.string,
  expiryDate: PropTypes.string,
  alignRight: PropTypes.bool,
}

export default AdStatusTag
