import React, { useState } from 'react'
import PropTypes from 'prop-types'

import PageTemplate from '../../templates/PageTemplate'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import WelcomeToNewLandlordAdmin from '../../organisms/WelcomeToNewLandlordAdmin'

import useMedia from '../../../hooks/useMedia'
import { config } from '../../../config'

import styles from './welcomeToNewLandlordAdminPage.module.scss'
import { useIntercom } from 'react-use-intercom'

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)
const intercomActive = config.intercomActive && window.innerWidth >= 768

const WelcomeToNewLandlordAdminPage = ({ location, redirect, authService }) => {
  if (!location.state) return redirect('/landlord-admin/login')
  const { email } = location.state

  const isTabletOrDesktop = useMedia(['(min-width: 769px)'], [true], false)
  const [resettingPassword, setResettingPassword] = useState(false)
  const [notification, setNotification] = useState({ show: false, type: '' })

  const { boot, update, show } = useIntercom()

  const resetPassword = async () => {
    setResettingPassword(true)
    const response = await authService.resetPasswordAfterMigration(email)

    if (response.success) {
      setNotification({ show: true, type: 'success' })
    } else {
      setNotification({ show: true, type: 'error' })
    }

    setResettingPassword(false)
  }

  const showIntercom = () => {
    if (canUseDOM && !intercomActive) {
      boot()
    }

    if (canUseDOM && intercomActive) {
      update({ hideDefaultLauncher: false })
      show()
    }
  }

  return (
    <PageTemplate
      className={styles.layout}
      header={<Header />}
      footer={<Footer />}
      title="Welcome to the New Landlord Admin | AFS"
      dontDisplayNotifications
    >
      <WelcomeToNewLandlordAdmin
        isTabletOrDesktop={isTabletOrDesktop}
        handleResetPassword={resetPassword}
        handleShowIntercom={showIntercom}
        resettingPassword={resettingPassword}
        notification={notification}
      />
    </PageTemplate>
  )
}

WelcomeToNewLandlordAdminPage.propTypes = {
  location: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
  authService: PropTypes.object.isRequired,
}

export default WelcomeToNewLandlordAdminPage
