import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import SpinnerButton from '@afs/components/SpinnerButton'

import TickCircle from '../../../svgs/icons/tick-circle.svg'

import './styles.scss'

const ProceedToPaymentConfirmation = ({
  className,
  hasDiscountApplied,
  discount,
  nonDiscountedSubtotal,
  subtotal = 0,
  disabled,
  inactive,
}) => {
  const paymentConfirmationClassName = classNames(
    'payment-confirmation',
    { 'payment-confirmation payment-confirmation--with-discount': hasDiscountApplied },
    className
  )

  return (
    <div
      className={classNames('payment-confirmation__body-wrapper', {
        'payment-confirmation__body-wrapper--with-discount': hasDiscountApplied,
      })}
    >
      {hasDiscountApplied && (
        <div className="payment-confirmation__discount">
          <TickCircle className="payment-confirmation__discount-icon" />
          <span className="payment-confirmation__discount-label">
            {discount.alternativeName} applies
          </span>
        </div>
      )}
      <div className={paymentConfirmationClassName} data-testid="checkout">
        <div className="payment-confirmation__content">
          <div className="payment-confirmation__text-wrapper">
            <div>
              <p className="payment-confirmation__subtotal-text">Subtotal:</p>
              <p className="payment-confirmation__vat-text">(excl. VAT)</p>
            </div>
            <div
              className="h2 payment-confirmation__subtotal-number"
              data-testid="checkout-subtotal"
            >
              {hasDiscountApplied && (
                <div
                  className="payment-confirmation__non-discounted-subtotal"
                  data-testid="checkout-non-discounted-subtotal"
                >
                  £{Number(nonDiscountedSubtotal).toFixed(2)}
                </div>
              )}
              £{Number(subtotal).toFixed(2)}
            </div>
          </div>
          <SpinnerButton
            primary
            disabled={disabled}
            inactive={inactive}
            wide
            className="payment-confirmation__button"
            data-testid="checkout-button"
          >
            Proceed to Payment
          </SpinnerButton>
        </div>
      </div>
    </div>
  )
}

ProceedToPaymentConfirmation.propTypes = {
  className: PropTypes.string,
  subtotal: PropTypes.string,
  disabled: PropTypes.bool,
  inactive: PropTypes.bool,
  hasDiscountApplied: PropTypes.bool,
  discount: PropTypes.shape({ alternativeName: PropTypes.string }),
  nonDiscountedSubtotal: PropTypes.string,
}

export default ProceedToPaymentConfirmation
