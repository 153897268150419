import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Card from '../../atoms/Card'
import Heading from '../../atoms/Heading'
import IconText from '../IconText'
import Link from '../../atoms/Link'

import availability from './availability.module.scss'

const PropertyDetailsAvailability = ({ className, property }) => {
  const availableBedrooms = property.numberOfAvailableBedrooms()
  const totalBedrooms = property.totalBedrooms()

  const availabilityDates = availabilityText => (
    <div className={availability.text}>
      {availabilityText.map(textItem => {
        return (
          <span key={textItem} className={availability.item}>
            {textItem}
          </span>
        )
      })}
    </div>
  )

  const propertyUrl = `/landlord-admin/property/${property.id}`

  return (
    <Card
      className={classNames(availability.card, className)}
      noBorder
      morePadding
      tightPadding
      data-testid="availability-card"
    >
      <Heading className={availability.heading} level={5}>
        Availability
      </Heading>
      <div className={availability.row}>
        <div>
          <strong className={availability.strong}>Property availability</strong>
          <IconText
            className={availability.info}
            icon="calendar"
            text={availabilityDates(property.availability())}
          />
        </div>
        <Link to={`${propertyUrl}/edit/availability`} data-testid="change-availability-link">
          Change
        </Link>
      </div>
      <div className={availability.row}>
        <div>
          <strong className={availability.strong}>Bedrooms available</strong>
          <IconText
            className={availability.info}
            icon="bedSize"
            text={`${availableBedrooms} / ${totalBedrooms} bedrooms`}
          />
        </div>
        <Link to={`${propertyUrl}/edit/bedrooms`} data-testid="change-bedrooms-link">
          Change
        </Link>
      </div>
    </Card>
  )
}

PropertyDetailsAvailability.propTypes = {
  className: PropTypes.string,
  property: PropTypes.object.isRequired,
}

export default PropertyDetailsAvailability
