import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import { useAuthContext } from '../../../authContext'
import { notificationService } from '../../../container'

import FieldBedroomsAdapter from '../../adapters/FieldBedroomsAdapter'

const min = 0
const max = 14

export const BedroomsPage = ({ propertyId, propertyType, bedroomApi, updatePropertyBedrooms }) => {
  const { token } = useAuthContext()
  return (
    <Field name="bedrooms" component={FieldBedroomsAdapter}>
      {props => {
        return (
          <div>
            <FieldBedroomsAdapter
              {...props}
              label="Bedrooms"
              min={min}
              max={max}
              propertyId={propertyId}
              propertyType={propertyType}
              bedroomApi={bedroomApi}
              updatePropertyBedrooms={updatePropertyBedrooms}
              notificationService={notificationService}
              jwtToken={token}
            />
          </div>
        )
      }}
    </Field>
  )
}

export const renderBedroomPageSubHeading = propertyType => {
  return propertyType === 'Studio' ? (
    <p className="form-page-heading__sub-heading">
      Select a room size, bed size, rent amount, and deposit for this property.
    </p>
  ) : (
    <p className="form-page-heading__sub-heading">
      Please include <strong>all the bedrooms</strong> that are in the property, including the ones
      already let.
    </p>
  )
}

export const bedroomsPageValidation = () => {
  const errors = {}
  return errors
}

BedroomsPage.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
  propertyId: PropTypes.string,
  propertyType: PropTypes.string,
  bedroomApi: PropTypes.object,
  updatePropertyBedrooms: PropTypes.func,
  useAuthContext: PropTypes.func,
}
