import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

const renderItems = ({ items, selectedItem, highlightedIndex, getItemProps }) => {
  return items.map((item, index) => {
    const isHighlighted = highlightedIndex === index
    const isSelected = selectedItem && item.value === selectedItem.value
    const itemClasses = classNames(styles.item, {
      [styles.highlightedItem]: isHighlighted,
      [styles.selectedItem]: isSelected,
    })

    return (
      <li
        {...getItemProps({
          key: `${item.value}-${index}`,
          className: itemClasses,
          index,
          item,
          'data-testid': 'dropdown-option',
        })}
      >
        {item.renderInner ? item.renderInner(item) : item.label}
      </li>
    )
  })
}

const Menu = ({
  isOpen,
  options,
  inputValue,
  selectedItem,
  highlightedIndex,
  getMenuProps,
  getItemProps,
  notFoundText,
}) => {
  if (!isOpen) return false

  let itemsToShow

  const nonDuplicatedOptions = Array.from(new Set(options.map(option => option.value))).map(
    value => {
      return options.find(option => option.value === value)
    }
  )

  if (!inputValue) itemsToShow = options
  else
    itemsToShow = nonDuplicatedOptions.filter(
      item => !inputValue || item.value.toLowerCase().startsWith(inputValue.toLowerCase())
    )

  const hasItemsToShow = itemsToShow.length > 0
  const menuClasses = classNames(styles.menu, {
    [styles.noResults]: !hasItemsToShow,
  })

  return (
    <div className={styles.dropdown}>
      <ul
        {...getMenuProps({
          className: menuClasses,
        })}
      >
        {hasItemsToShow ? (
          renderItems({
            items: itemsToShow,
            inputValue,
            selectedItem,
            highlightedIndex,
            getItemProps,
          })
        ) : (
          <li className={styles.item} data-testid="dropdown-option--404">
            {notFoundText}
          </li>
        )}
      </ul>
    </div>
  )
}

Menu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  inputValue: PropTypes.string.isRequired,
  selectedItem: PropTypes.object,
  highlightedIndex: PropTypes.number,
  getMenuProps: PropTypes.func.isRequired,
  getItemProps: PropTypes.func.isRequired,
  notFoundText: PropTypes.string,
}

export default Menu
