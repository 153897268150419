class CognitoUser {
  constructor(amplifyUser) {
    this.user = amplifyUser
  }

  isAdmin() {
    return this.user['cognito:groups'].includes('Admin')
  }

  static default() {
    return new CognitoUser({
      'cognito:groups': [],
    })
  }
}

export default CognitoUser
