import React from 'react'
import SpinnerButton from '@afs/components/SpinnerButton'

import { updatePropertyDescription } from '../../../../api/propertyApi'

import {
  DescriptionPage,
  descriptionPageValidation,
} from '../../FormPages/DescriptionPage/DescriptionPage'
import SubmitButton from '../../../atoms/SubmitButton'

const descriptionPageConfig = propertyId => ({
  id: 10,
  component: () => <DescriptionPage />,
  route: `/landlord-admin/property/${propertyId}/edit/description`,
  validate: values => descriptionPageValidation(values),
  isValid: false,
  heading: 'Description',
  subheading:
    'Provide a quick summary about your property, any special features, proximity to shops, public transport, etc.',
  button: (values, loading) => {
    const { description } = values

    if (!description || description.length === 0) return null

    if (loading)
      return (
        <SpinnerButton primary inactive>
          Continue
        </SpinnerButton>
      )

    return <SubmitButton data-testid="save-button">Save changes</SubmitButton>
  },
  onSubmit: async (values, token) => {
    const payload = {
      propertyId,
      description: values.description,
    }
    return updatePropertyDescription(payload, token)
  },
})

export default descriptionPageConfig
