/* eslint-disable no-underscore-dangle */

class BillsOptionalAuthSettings {
  _hasBillsOptionalProperties
  _hasAgreedBillsPackageProviderTerms

  constructor(hasBillsOptionalProperties, hasAgreedBillsPackageProviderTerms) {
    this._hasBillsOptionalProperties = hasBillsOptionalProperties
    this._hasAgreedBillsPackageProviderTerms = hasAgreedBillsPackageProviderTerms
  }

  hasBillsOptionalProperties() {
    return this._hasBillsOptionalProperties
  }

  hasAgreedBillsPackageProviderTerms() {
    return this._hasAgreedBillsPackageProviderTerms
  }

  agreeTerms() {
    this._hasBillsOptionalProperties = true
    this._hasAgreedBillsPackageProviderTerms = true
  }
}

export default BillsOptionalAuthSettings
