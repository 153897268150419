import React from 'react'
import PropTypes from 'prop-types'
import FieldLocation from '../molecules/FieldLocation'

import locations from '../../services/locations'

const FieldLocationAdapter = ({
  input: { name, onChange, onBlur, value },
  label,
  meta,
  ...props
}) => {
  const { error, invalid, valid, initial, touched } = meta
  const showError = (invalid && touched) || (initial.length > 0 && invalid)

  return (
    <>
      <FieldLocation
        label={label}
        name={name}
        locations={locations}
        selectedLocation={value}
        onBlur={event => onBlur(event)}
        handleChange={selection => onChange(selection)}
        error={error}
        invalid={showError}
        validated={valid && touched}
        {...props}
      />
    </>
  )
}

FieldLocationAdapter.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
}

export default FieldLocationAdapter
