import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'

import styles from './twoColumnPageTemplate.module.scss'

const TwoColumnPageTemplate = ({
  className,
  header,
  progressBar,
  contextMenu,
  footer,
  title,
  leftColumn,
  rightColumn,
  stack,
  leftColumnInnerClassName,
  rightColumnClassName,
  rightColumnInnerClassName,
}) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {header}
    {progressBar}
    {contextMenu}
    <div className={classNames(styles.page, className, { [styles.pageStack]: stack })}>
      <main className={classNames(styles.leftCol, { [styles.leftColStack]: stack })}>
        <div className={classNames(styles.leftInner, leftColumnInnerClassName)}>{leftColumn}</div>
      </main>
      <aside
        className={classNames(styles.rightCol, rightColumnClassName, {
          [styles.rightColStack]: stack,
        })}
      >
        <div
          className={classNames(styles.rightInner, rightColumnInnerClassName, {
            [styles.rightInnerStack]: stack,
          })}
        >
          <div className={styles.sidebar}>{rightColumn}</div>
        </div>
      </aside>
    </div>
    {footer}
  </>
)

TwoColumnPageTemplate.propTypes = {
  className: PropTypes.string,
  header: PropTypes.node,
  progressBar: PropTypes.node,
  contextMenu: PropTypes.node,
  footer: PropTypes.object,
  title: PropTypes.string,
  leftColumn: PropTypes.object,
  rightColumn: PropTypes.object,
  leftColumnInnerClassName: PropTypes.string,
  rightColumnClassName: PropTypes.string,
  rightColumnInnerClassName: PropTypes.string,
  stack: PropTypes.bool,
}

export default TwoColumnPageTemplate
