import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'
import FieldAccommodationTypeAdapter from '../../adapters/FieldAccommodationTypeAdapter'

export const AccommodationTypePage = () => (
  <Field name="accommodationType" component={FieldAccommodationTypeAdapter}>
    {props => {
      return (
        <div>
          {props.meta.error && <span>{props.meta.error}</span>}
          <FieldAccommodationTypeAdapter {...props} />
        </div>
      )
    }}
  </Field>
)

AccommodationTypePage.propTypes = {
  meta: PropTypes.object,
}
