import React from 'react'
import { Page, Text, View, Document, Image, Font } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import styles from './documentStyles'

export const formatAddress = (address) => {
  const addressPieces = address.filter((x) => x !== '')

  const addressLength = addressPieces.length
  const addressChunkSize = Math.round(addressLength / 2)

  const addressLine1 = addressPieces.splice(0, addressChunkSize)
  const addressLine2 = addressPieces

  return [addressLine1, addressLine2]
}

const InvoicePdf = ({ invoice, assetsPath, isLettingAgentInvoice }) => {
  Font.register({
    family: 'Apax Three',
    fonts: [
      { src: `${assetsPath}/ApaxThree-Regular.ttf` }, // font-style: normal, font-weight: normal
      { src: `${assetsPath}/ApaxThree-Medium.ttf`, fontWeight: 500 },
      { src: `${assetsPath}/ApaxThree-Bold.ttf`, fontWeight: 600 },
    ],
  })

  const {
    number,
    date,
    landlord,
    lineItems,
    totalNetAmount,
    vat,
    totalGrossPaid,
    subtotal,
    hasDiscount,
    discount,
  } = invoice
  const { companyName, name, addressLine1, addressLine2, townCity, postcode, country } = landlord

  return (
    <Document title={`Accommodation for Students - Invoice #${number}`}>
      <Page size="A4" style={styles.page}>
        <View style={styles.wrapper}>
          <View style={styles.header}>
            <Text style={styles.invoiceNumber}>Invoice #{number}</Text>
            <Image style={styles.logo} src={`${assetsPath}/afs-logo.jpg`} />
            <Image style={styles.paidStamp} src={`${assetsPath}/paid-stamp.png`} />
          </View>

          <View style={styles.details}>
            <View style={styles.detailsColumnLeft}>
              <View style={styles.invoiceDate}>
                <Text style={styles.label}>Invoice date</Text>
                <Text style={styles.text}>{date}</Text>
              </View>
              <View>
                <Text style={styles.label}>Paid by</Text>
                <Text style={styles.text}>
                  {isLettingAgentInvoice ? `Attention of: ${name}` : name}
                </Text>
                <Text style={styles.text}>{companyName}</Text>
                <Text style={styles.text}>{addressLine1}</Text>
                <Text style={styles.text}>{addressLine2}</Text>
                <Text style={styles.text}>{townCity}</Text>
                <Text style={styles.text}>{postcode}</Text>
                <Text style={styles.text}>{country}</Text>
              </View>
            </View>

            <View style={styles.detailsColumnRight}>
              <Text style={styles.afsTitle}>Accommodation for Students</Text>
              <View style={styles.afsText}>
                <Text style={styles.text}>Jactin House</Text>
                <Text style={styles.text}>24 Hood Street</Text>
                <Text style={styles.text}>Ancoats</Text>
                <Text style={styles.text}>Manchester</Text>
                <Text style={styles.text}>M4 6WX</Text>
              </View>

              <View style={styles.afsText}>
                <Text style={styles.text}>VAT Number: GB 825 0136 61</Text>
                <Text style={styles.text}>Company Reg. No: 04111099</Text>
              </View>

              <View>
                <Text style={styles.text}>Phone: 0800 078 9659</Text>
                <Text style={styles.text}>support@accommodationforstudents.com</Text>
              </View>
            </View>
          </View>
          <View style={styles.lineItems}>
            {lineItems.map((order, i) => {
              const { title, address, netPrice } = order
              return (
                // eslint-disable-next-line
                <View key={i} style={styles.order} wrap={false}>
                  <View style={styles.orderDetails}>
                    <Text style={styles.lineItemTitle}>{title}</Text>
                    {formatAddress(address).map((line) => (
                      <Text style={styles.textSmall}>{line.join(', ')}</Text>
                    ))}
                  </View>
                  <View style={styles.orderBreakdown}>
                    <View style={styles.priceBox}>
                      <Text style={styles.orderPrice}>{netPrice}</Text>
                      <Text style={styles.textSmall}>Net price</Text>
                    </View>
                  </View>
                </View>
              )
            })}
          </View>
          <View style={styles.total} wrap={false}>
            {hasDiscount ? (
              <>
                <View style={styles.netDiscountSection}>
                  <View style={styles.totalBlocks}>
                    <Text style={styles.totalSmall}>Total net amount</Text>
                    <Text style={styles.totalAmount}>{totalNetAmount}</Text>
                  </View>
                  <View style={styles.totalVat}>
                    <Text style={styles.totalSmall}>{discount.name}</Text>
                    <Text style={styles.totalAmount}>{discount.amount}</Text>
                  </View>
                </View>
                <View style={styles.subTotalVatSection}>
                  <View style={styles.totalBlocks}>
                    <Text style={styles.totalSmall}>Subtotal</Text>
                    <Text style={styles.totalAmount}>{subtotal}</Text>
                  </View>
                  <View style={styles.totalVat}>
                    <Text style={styles.totalSmall}>VAT</Text>
                    <Text style={styles.totalAmount}>{vat}</Text>
                  </View>
                </View>
              </>
            ) : (
              <>
                <View style={styles.netVatSection}>
                  <View style={styles.totalBlocks}>
                    <Text style={styles.totalSmall}>Total net amount</Text>
                    <Text style={styles.totalAmount}>{totalNetAmount}</Text>
                  </View>

                  <View style={styles.totalVat}>
                    <Text style={styles.totalSmall}>VAT</Text>
                    <Text style={styles.totalAmount}>{vat}</Text>
                  </View>
                </View>
              </>
            )}

            <View style={styles.totalGrossBlocks}>
              <Text style={styles.totalMed}>Total gross paid:</Text>
              <Text style={styles.totalLarge}>{totalGrossPaid}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

InvoicePdf.propTypes = {
  invoice: PropTypes.object.isRequired,
  assetsPath: PropTypes.string.isRequired,
  isLettingAgentInvoice: PropTypes.bool.isRequired,
}

export default InvoicePdf
