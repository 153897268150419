import React from 'react'
import PropTypes from 'prop-types'

import Address from '../../../models/address'

import PropertyRoomCount from '../../atoms/PropertyRoomCount'
import AvatarImage from '../../atoms/AvatarImage'

import styles from './styles.module.scss'

const PropertyCardFeedsListView = ({ property }) => {
  const address = new Address(property.address)

  return (
    <article className={styles.wrapper}>
      <div className={styles.leftSideInner}>
        <div className={styles.property} data-testid="property-card-list-view-property">
          <AvatarImage
            className={styles.propertyImage}
            hasPhoto={property.hasPhoto}
            photoUrl={property.photoUrl}
            alt={address.firstTwoLines()}
            large
          />
          <div className={styles.propertyAddress}>
            <p className={styles.addressTitle}>{address.firstTwoLines()}</p>
            <p className={styles.addressSubtitle}>{address.withoutFirstTwoLines().join(', ')}</p>
          </div>
        </div>
      </div>
      <div className={styles.rightSideInner}>
        <div className={styles.rightSideItem}>
          <PropertyRoomCount className={styles.roomCount} count={property.numberOfBedrooms} />
        </div>
        <div className={styles.rightSideItem}>
          <p className={styles.priceValue}>{`£${property.averageRentPerWeek}`}</p>
          <p className={styles.priceText}>rent pp/pw</p>
        </div>
        <div className={styles.rightSideItem}>
          <p className={styles.priceValue}>{`£${property.averageDeposit}`}</p>
          <p className={styles.priceText}>deposit pp</p>
        </div>
      </div>
    </article>
  )
}

PropertyCardFeedsListView.propTypes = {
  property: PropTypes.object.isRequired,
}

export default PropertyCardFeedsListView
