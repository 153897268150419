import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { animated, useTransition } from 'react-spring'

import CloseIcon from './close-icon.svg'

import styles from './styles.module.scss'

const MultiDiscountBanner = ({ className, isVisible, handleDismiss }) => {
  const transitions = useTransition(isVisible, null, {
    config: { duration: 200 },
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div key={key} style={props} className={classNames(styles.banner, className)}>
          <div className={styles.inner}>
            <span className={styles.text}>
              <strong className={styles.strong}>Save 10%</strong> when you advertise two or more of
              these properties at the same time.
            </span>
            <button className={styles.closeButton} onClick={handleDismiss}>
              <CloseIcon className={styles.closeIcon} aria-label="Close banner" />
            </button>
          </div>
        </animated.div>
      )
  )
}

MultiDiscountBanner.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
}

export default MultiDiscountBanner
