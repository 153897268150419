import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Auth } from 'aws-amplify'

import PageTemplate from '../../templates/PageTemplate'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import LoginForm from '../../organisms/LoginForm'
import VerificationLinkForm from '../../organisms/VerificationLinkForm/index'

import './styles.scss'

const LandlordsLoginPage = ({
  className,
  history,
  loginFormViewModel,
  authService,
  notificationService,
  redirect,
  pathToRedirectToAfterLogin,
}) => {
  const componentClasses = classNames('login', className)
  const [shouldDisplayLoginForm, setShouldDisplayLoginForm] = useState(true)
  const [shouldDisplayVerificationLinkForm, setShouldDisplayVerificationLinkForm] = useState(false)
  const [userEmailAddress, setUserEmailAddress] = useState('')
  const [accountMigrated, setAccountMigrated] = useState(false)

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        history.push('/landlord-admin')
      })
      .catch(() => {})
  })

  const renderVerificationLinkForm = email => {
    setShouldDisplayLoginForm(false)
    setShouldDisplayVerificationLinkForm(true)
    setUserEmailAddress(email)
  }

  const redirectToWelcomeToNewLandlordAdminPage = email => {
    setUserEmailAddress(email)
    setAccountMigrated(true)
  }

  if (accountMigrated && userEmailAddress) {
    return redirect('/welcome-to-new-landlord-admin', userEmailAddress)
  }

  return (
    <PageTemplate
      className={componentClasses}
      header={<Header />}
      footer={<Footer />}
      title="Login | AFS"
    >
      <div className="login__row">
        <div className="login__inner">
          <div className="login__content">
            {shouldDisplayLoginForm && (
              <LoginForm
                className="login__form"
                history={history}
                viewModel={loginFormViewModel}
                verificationLinkForm={renderVerificationLinkForm}
                notificationService={notificationService}
                redirectToWelcomePage={redirectToWelcomeToNewLandlordAdminPage}
                data-testid="login-form"
                pathToRedirectToAfterLogin={pathToRedirectToAfterLogin}
              />
            )}
            {shouldDisplayVerificationLinkForm && (
              <VerificationLinkForm
                authService={authService}
                email={userEmailAddress}
                data-testid="verification-link-form"
              />
            )}
          </div>
        </div>
      </div>
    </PageTemplate>
  )
}

LandlordsLoginPage.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object.isRequired,
  loginFormViewModel: PropTypes.func.isRequired,
  authService: PropTypes.object.isRequired,
  notificationService: PropTypes.object.isRequired,
  redirect: PropTypes.func.isRequired,
  pathToRedirectToAfterLogin: PropTypes.string,
}

export default LandlordsLoginPage
