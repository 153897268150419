import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import AccountCard from '../../molecules/AccountCard'

import ManagePropertiesIcon from '../../../svgs/icons/manage-properties.svg'
import EnquiriesIcon from '../../../svgs/icons/enquiries.svg'
import InvoicesIcon from '../../../svgs/icons/invoices.svg'
import PerformanceIcon from '../../../svgs/icons/performance.svg'

import './styles.scss'

const AccountCardGridFeeds = ({ className, hasActiveFeed }) => {
  const accountCardGridClasses = classNames('account-card-grid', className)

  return (
    <div className={accountCardGridClasses}>
      <div className="account-card-grid__page">
        {hasActiveFeed && (
          <>
            <AccountCard
              className="account-card-grid__item"
              link="/landlord-admin/feeds/properties"
              text="Properties"
              icon={<ManagePropertiesIcon className="account-card__icon" />}
              data-testid="manage-properties-card"
            />
            <AccountCard
              className="account-card-grid__item"
              link="/landlord-admin/feeds/enquiries"
              text="Enquiries"
              icon={<EnquiriesIcon className="account-card__icon" />}
              data-testid="enquiries-card"
            />
            <AccountCard
              className="account-card-grid__item"
              link="/landlord-admin/feeds/performance"
              text="Performance"
              icon={<PerformanceIcon className="account-card__icon" />}
              data-testid="performance-card"
            />
          </>
        )}
        <AccountCard
          className="account-card-grid__item"
          link="/landlord-admin/invoices"
          text="Invoices"
          icon={<InvoicesIcon className="account-card__icon" />}
          data-testid="invoices-card"
        />
      </div>
    </div>
  )
}

AccountCardGridFeeds.propTypes = {
  className: PropTypes.string,
  hasActiveFeed: PropTypes.bool.isRequired,
}

export default AccountCardGridFeeds
