import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import settingsLink from './settingsLink.module.scss'

import ArrowRight from '../../../svgs/icons/chevron-right.svg'

const AccountLink = ({ className, children, icon: Icon, title, to, ...props }) => {
  const componentClasses = classNames(settingsLink.wrapper, className)

  if (to) {
    return (
      <NavLink className={componentClasses} to={to} {...props}>
        {Icon && <Icon className={settingsLink.icon} />}
        {children}
        <ArrowRight className={settingsLink.arrow} />
      </NavLink>
    )
  }

  return (
    <button className={componentClasses} {...props}>
      {Icon && <Icon className={settingsLink.icon} />}
      {children}
      <ArrowRight className={settingsLink.arrow} />
    </button>
  )
}

AccountLink.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.func,
  to: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
}

export default AccountLink
