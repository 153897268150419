import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

const AdminUserBanner = ({ managedAccount }) => {
  return (
    <div className={styles.wrapper} data-testid="admin-user-banner">
      <p className={styles.text}>
        {`You're logged in as `}
        <span className={styles.name}>{managedAccount.fullName}</span>{' '}
        <span className={styles.emailAddress}>({managedAccount.email}).</span>
      </p>
    </div>
  )
}

AdminUserBanner.propTypes = {
  managedAccount: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
}

export default AdminUserBanner
