import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.scss'

import Link from '../../atoms/Link'
import Icon from '../../atoms/Icon'

const BackLink = ({ className, children = 'Back', ...props }) => {
  const linkClasses = classNames('back-link', className)

  return (
    <Link className={linkClasses} {...props} data-testid="backlink">
      <Icon
        className="back-link__icon"
        icon="arrow-back"
        width="8"
        height="14"
        viewBox="0 0 8 14"
      />
      {children}
    </Link>
  )
}

BackLink.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node,
}

export default BackLink
