const isTruthy = value => {
  return !!value
}

export const validateStreet = street => {
  if (!isTruthy(street)) return false

  const regex = /^(?:(?![0-9]|-{2,}| {2,}| -|- |,|\.|\*|\(|\)).)+$/gm
  return regex.test(street)
}

export const validatePostcode = postcode => {
  if (!isTruthy(postcode)) return false

  const postcodeWithoutSpaces = postcode.replace(/\s/g, '')
  const regex = /^[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}$/i
  return regex.test(postcodeWithoutSpaces)
}

class Address {
  constructor(address) {
    if (address) {
      Object.assign(this, address)
    }
  }

  equals(otherAddress) {
    return JSON.stringify(this) === JSON.stringify(otherAddress)
  }

  isValid() {
    const requiredFields = ['street', 'townCity', 'postcode', 'hasValidLocation']

    if (!validatePostcode(this.postcode)) return false

    return requiredFields.every(field => this[field] && isTruthy(this[field]))
  }

  flatAndBuildingName() {
    return ['flat', 'buildingName']
      .map(property => this[property])
      .filter(value => isTruthy(value))
      .join(', ')
  }

  buildingNumberAndStreet() {
    return ['buildingNumber', 'street']
      .map(property => this[property])
      .filter(value => isTruthy(value))
      .join(' ')
  }

  addressFields() {
    return [
      this.flat,
      ...(this.buildingName
        ? [this.buildingName, this.buildingNumberAndStreet()]
        : [this.buildingNumberAndStreet()]),
      this.area,
      this.townCity,
      this.postcode,
    ].filter(value => isTruthy(value))
  }

  addressFieldsWithoutPostcode() {
    return [
      this.flat,
      ...(this.buildingName
        ? [this.buildingName, this.buildingNumberAndStreet()]
        : [this.buildingNumberAndStreet()]),
      this.area,
      this.townCity,
    ].filter(value => isTruthy(value))
  }

  postcodeOnly() {
    return this.postcode
  }

  firstLine() {
    return this.addressFields()[0]
  }

  withoutFirstLine() {
    return this.addressFields().slice(1)
  }

  withoutFirstLineAndPostcode() {
    return this.addressFieldsWithoutPostcode().slice(1)
  }

  firstTwoLines() {
    if (this.flat && this.buildingName) return this.flatAndBuildingName()
    if (this.flat && this.street) return `${this.flat}, ${this.buildingNumberAndStreet()}`
    if (this.buildingName) return `${this.buildingName}, ${this.buildingNumberAndStreet()}`
    return this.buildingNumberAndStreet()
  }

  withoutFirstTwoLines() {
    const addressHasFirstTwoLines =
      (this.flat && this.buildingName) || (this.flat && this.street) || this.buildingName
    if (addressHasFirstTwoLines) return this.addressFields().slice(2)
    return this.withoutFirstLine()
  }

  withoutFirstTwoLinesAndPostcode() {
    const addressHasFirstTwoLines =
      (this.flat && this.buildingName) || (this.flat && this.street) || this.buildingName
    if (addressHasFirstTwoLines) return this.addressFieldsWithoutPostcode().slice(2)
    return this.withoutFirstLineAndPostcode()
  }

  streetAreaCityAndPostcode() {
    const fields = ['street', 'area', 'townCity', 'postcode']
    return fields.map(property => this[property]).filter(value => isTruthy(value))
  }

  streetAreaAndCity() {
    const fields = ['street', 'area', 'townCity']
    return fields.map(property => this[property]).filter(value => isTruthy(value))
  }
}

export default Address
