import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Heading from '../../atoms/Heading'
import Button from '../../atoms/Button'
import Notification from '../../molecules/Notification'

import './styles.scss'

const VerificationLinkForm = ({ authService, email, ...props }) => {
  const [verificationEmailSent, setVerificationEmailSent] = useState(false)

  const resendVerificationLink = (event) => {
    event.preventDefault()
    authService.resendVerificationLink(email)
    setVerificationEmailSent(true)
  }

  const toggleNotification = () => {
    setVerificationEmailSent(false)
  }

  return (
    <div className="verification-link-form" {...props}>
      {verificationEmailSent && (
        <div className="login-form__notification">
          <Notification
            type="success"
            heading="Email sent."
            text="Please check your inbox for instructions."
            toggle={toggleNotification}
          />
        </div>
      )}
      <Heading className="verification-link-form__heading">Verify Email Address</Heading>
      <p className="verification-link-form__subheading">
        You have to verify your email address before you can login.
      </p>
      <p className="verification-link-form__text">
        <strong>Please check your inbox</strong> for an email with instructions on how to verify
        your email address.
      </p>
      {!verificationEmailSent && (
        <p className="verification-link-form__link-text">
          {"Haven't received it? "}
          <Button className="verification-link-form__link" onClick={resendVerificationLink}>
            Resend confirmation email.
          </Button>
        </p>
      )}
      <Button href="/landlord-admin/login">Back to login</Button>
    </div>
  )
}

VerificationLinkForm.propTypes = {
  email: PropTypes.string.isRequired,
  authService: PropTypes.object.isRequired,
}

export default VerificationLinkForm
