import { performApiDelete, performApiGet } from './requests'
import { config } from '../config'
import Enquiry from '../models/enquiry'

export const getAllEnquiries = async token => {
  try {
    const response = await performApiGet('/enquiry', token)
    return {
      success: response.status === 200,
      enquiries: response.data.map(enquiry => new Enquiry(enquiry, config)),
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getAllFeedEnquiries = async token => {
  try {
    const response = await performApiGet('/feeds/enquiry', token)
    return {
      success: response.status === 200,
      enquiries: response.data.map(enquiry => new Enquiry(enquiry, config)),
    }
  } catch (exception) {
    return { success: false }
  }
}

export const deleteEnquiryById = async (id, token) => {
  try {
    const response = await performApiDelete(`/enquiry/${id}`, token)
    return {
      success: response.status === 200,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const deleteFeedEnquiryById = async (id, token) => {
  try {
    const response = await performApiDelete(`/feeds/enquiry/${id}`, token)
    return {
      success: response.status === 200,
    }
  } catch (exception) {
    return { success: false }
  }
}
