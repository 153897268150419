import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.scss'

import Icon from '../../atoms/Icon'
import Button from '../../atoms/Button'

const IconButton = ({ className, icon, iconPosition = 'left', children, ...props }) => {
  const buttonClasses = classNames(
    'icon-button',
    props.deletion ? 'icon-button--recycle-bin' : '',
    className
  )
  const iconClasses = classNames('icon-button__icon', {
    'icon-button__icon--right': iconPosition === 'right',
  })

  return (
    <Button className={buttonClasses} {...props}>
      <span className="icon-button__flex">
        <Icon key={1} className={iconClasses} {...icon} />
        <span key={2} className="icon-button__text">
          {children}
        </span>
      </span>
    </Button>
  )
}

IconButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.object.isRequired,
  iconPosition: PropTypes.string,
  href: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  deletion: PropTypes.bool,
}

export default IconButton
