import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { RoomsAvailablePill } from '@afs/components/Pill'

import { config } from '../../../config'
import Property from '../../../models/property'

import Link from '../../atoms/Link'
import LazyImage from '../../atoms/LazyImage'
import PropertyTermsCard from '../../atoms/PropertyTermsCard'
import PropertyTitleAndAddress from '../PropertyTitleAndAddress'

import './styles.scss'

const altDescription = (rooms, type, address) => {
  return `${rooms} bed ${type}, ${address.join(', ')}`
}

const CardLink = ({ url, openInNewTab, children }) => {
  if (!url) {
    return <div className="property-card__link">{children}</div>
  }

  return (
    <Link href={url} target={openInNewTab && '_blank'} className="property-card__link">
      {children}
    </Link>
  )
}

const placeholderImage = {
  src: config.placeholderImageSrc,
}

const PropertyCard = ({
  className,
  property,
  withBorder,
  withoutLink,
  openLinkInNewTab,
  ...props
}) => {
  const cardClasses = classNames(
    'property-card',
    { 'property-card--with-border': withBorder },
    { 'property-card--with-link': !withoutLink },
    className
  )
  const propertyModel = new Property(property)

  const propertyImageSrc =
    property.photos.length !== 0
      ? `${config.imgApiUrl}w=440/${property.photos[0].src}`
      : `${config.imgApiUrl}w=440/${placeholderImage.src}`

  const propertyUrl = !withoutLink ? `${config.mainSiteBaseUrl}property/${property.path}` : ''

  const roomsAvailableText =
    propertyModel.numberOfAvailableBedrooms() > 1
      ? `${propertyModel.numberOfAvailableBedrooms()} rooms available`
      : '1 room available'

  return (
    <article className={cardClasses} {...props} data-testid="property-card">
      <CardLink url={propertyUrl} openInNewTab={openLinkInNewTab}>
        <div className="property-card__media">
          {!propertyModel.allBedroomsAreAvailable() && (
            <RoomsAvailablePill
              className="property-card__rooms-available"
              data-testid="property-card-availability"
            >
              {roomsAvailableText}
            </RoomsAvailablePill>
          )}
          <div className="property-card__img-container" data-testid="property-card-image-container">
            <LazyImage
              className="property-card__img"
              alt={altDescription(
                propertyModel.totalBedrooms(),
                propertyModel.accommodationType,
                propertyModel.address.streetAreaCityAndPostcode()
              )}
              src={propertyImageSrc}
              dataSrc={propertyImageSrc}
            />
          </div>
        </div>
        <div className="property-card__details" data-testid="property-card-details">
          <PropertyTitleAndAddress
            className="property-card"
            accommodationType={propertyModel.accommodationType}
            address={propertyModel.address}
            numberOfBedrooms={propertyModel.bedrooms.length}
          />
        </div>
        <PropertyTermsCard
          className="property-card__terms"
          terms={propertyModel.propertyCardTerms()}
          withBorder={withBorder}
          roundedUp
          data-testid="property-card-terms"
        />
      </CardLink>
    </article>
  )
}

CardLink.propTypes = {
  url: PropTypes.string,
  openInNewTab: PropTypes.bool,
  children: PropTypes.any,
}

PropertyCard.propTypes = {
  className: PropTypes.string,
  property: PropTypes.shape({
    id: PropTypes.number.isRequired,
    path: PropTypes.number.isRequired,
    accommodationType: PropTypes.string.isRequired,
    bedrooms: PropTypes.array.isRequired,
    address: PropTypes.object.isRequired,
    photos: PropTypes.array,
    bills: PropTypes.array.isRequired,
  }),
  withBorder: PropTypes.bool,
  withoutLink: PropTypes.bool,
  openLinkInNewTab: PropTypes.bool,
}

export default PropertyCard
