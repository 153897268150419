import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import FieldAddressListRow from './FieldAddressListRow'

const FieldAddressList = ({ className, name, addresses, selectedAddress, onSelectAddress }) => (
  <div className={classNames('address-list', className)} data-testid="address-list">
    {addresses.map((address) => {
      const { flat, buildingName, buildingNumber, street } = address
      const addressId = `${flat} ${buildingName} ${buildingNumber} ${street}`
      return (
        <FieldAddressListRow
          key={addressId}
          inputId={addressId}
          name={name}
          address={address}
          selected={selectedAddress && address.equals(selectedAddress)}
          onSelect={onSelectAddress}
        />
      )
    })}
  </div>
)

FieldAddressList.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  addresses: PropTypes.arrayOf(PropTypes.object),
  selectedAddress: PropTypes.object.isRequired,
  onSelectAddress: PropTypes.func.isRequired,
}

export default FieldAddressList
