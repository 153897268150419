import { performApiPost, performApiGet, performApiPatch, performApiDelete } from './requests'

export const requestTestimonial = async (payload, token) => {
  try {
    const response = await performApiPost('/testimonialrequests', payload, token)
    return {
      success: response.status === 200,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getTestimonials = async token => {
  try {
    const response = await performApiGet('/testimonials', token)
    return {
      success: response.status === 200,
      testimonials: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getLandlordTestimonials = async token => {
  try {
    const response = await performApiGet('/legacylandlordtestimonials', token)
    return {
      success: response.status === 200,
      testimonials: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getTestimonialRequestData = async testimonialRequestId => {
  try {
    const response = await performApiGet(`/testimonialrequests/${testimonialRequestId}`)

    return {
      success: response.status === 200,
      testimonialRequestData: response.data,
      status: response.status,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const markTestimonialAsNotNew = async (payload, token) => {
  try {
    const response = await performApiPatch('/testimonials/new', payload, token)
    return {
      success: response.status === 200,
    }
  } catch (exception) {
    return {
      success: false,
    }
  }
}

export const toggleDisplayTestimonial = async (payload, token) => {
  try {
    const response = await performApiPatch('/testimonials/display', payload, token)
    return {
      success: response.status === 200,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const toggleDisplayLandlordTestimonial = async (payload, token) => {
  try {
    const response = await performApiPatch('/legacylandlordtestimonials/display', payload, token)
    return {
      success: response.status === 200,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const submitTestimonial = async payload => {
  try {
    const response = await performApiPost('/testimonials', payload)
    return {
      success: response.status === 200,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const deleteTestimonial = async (id, token) => {
  try {
    const response = await performApiDelete(`/testimonials/${id}`, token)

    return {
      success: response.status === 200,
    }
  } catch (exception) {
    return {
      success: false,
    }
  }
}
