import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Field from '@afs/components/Field'

import { invalidPhoneNumber } from '../../../../utils/validation'

import AccountSettingsForm from '../../../molecules/AccountSettingsForm'

const PersonalPhoneNumberForm = ({ account, onSave, loading, ...props }) => {
  const [field, setField] = useState({
    value: account.personalPhoneNumberString(),
    touched: false,
    error: '',
  })

  const validatePhoneNumber = string => {
    if (invalidPhoneNumber(string)) {
      return 'Valid phone number required.'
    }
    return ''
  }

  const handleChange = event => {
    const inputValue = event.target.value
    const error = validatePhoneNumber(inputValue)

    setField(prevState => ({
      ...prevState,
      value: inputValue,
      touched: true,
      error,
    }))
  }

  const handleSave = event => {
    event.preventDefault()

    const fieldName = 'personalPhoneNumber'
    const payload = {
      [fieldName]: field.value,
    }

    onSave(fieldName, payload)
  }

  const formIsInvalid = field.touched && field.error !== ''

  return (
    <AccountSettingsForm onSubmit={handleSave} invalid={formIsInvalid} loading={loading} {...props}>
      <Field
        name="personalPhoneNumber"
        aria-label="Personal phone number"
        value={field.value}
        type="tel"
        onChange={handleChange}
        onBlur={handleChange}
        invalid={formIsInvalid}
        error={field.error}
        data-testid="input-field-phone-number"
      />
    </AccountSettingsForm>
  )
}

PersonalPhoneNumberForm.propTypes = {
  account: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default PersonalPhoneNumberForm
