import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'

import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'

import styles from './styles.module.scss'

const RegisterFormPageTemplate = ({ className, title, children, ...props }) => {
  const pageClasses = classNames('site-main', styles.main, className)

  return (
    <>
      <Helmet>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
              'event': 'formAbandonment',
              'eventCategory': 'Form Abandonment',
              'eventAction': 'fields that were filled by a visitor'
             });
          `}
        </script>
        <title>{title}</title>
      </Helmet>
      <Header />
      <main className={pageClasses} {...props}>
        {children}
      </main>
      <Footer />
    </>
  )
}

RegisterFormPageTemplate.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
}

export default RegisterFormPageTemplate
