import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import FormPageHeading from '../molecules/FormPageHeading'
import ExpandableField from '../molecules/ExpandableField'
import LoadingPage from '../pages/LoadingPage'
import Notification from '../molecules/Notification'
import styles from '../molecules/AccountSettingsForm/accountSettingsForm.module.scss'

const AccountSettingsContainer = ({ heading, subheading, fieldConfig, getLandlord, token }) => {
  const [formNotifications, setFormNotifications] = useState([])
  const [pageNotifications, setPageNotifications] = useState([])
  const [account, setAccount] = useState(null)
  const [loading, setLoading] = useState(true)
  const [fields, setFields] = useState(fieldConfig)

  const getLandlordDetails = () =>
    getLandlord(token).then(response => {
      if (response.success) {
        setAccount(response.landlord)
      }
    })

  const setFieldProperties = (name, properties) => {
    const updatedFields = fields.map(field => {
      if (field.name === name) {
        return {
          ...field,
          ...properties,
        }
      }

      return field
    })

    setFields(updatedFields)
  }

  const clearFormNotifications = () => setFormNotifications([])
  const clearPageNotifications = () => setPageNotifications([])

  const handleOpenField = name => {
    clearPageNotifications()

    setFields(
      fields.map(field => ({
        ...field,
        expanded: field.name === name,
        disabled: field.name !== name,
      }))
    )
  }

  const handleCloseFields = () => {
    setFields(
      fields.map(field => ({
        ...field,
        expanded: false,
        disabled: false,
        loading: false,
      }))
    )

    clearFormNotifications()
  }

  const handleSavePassword = async payload => {
    const fieldName = 'password'
    const updatedField = fields.find(field => field.name === fieldName)

    clearFormNotifications()
    setFieldProperties(fieldName, { disabled: true, loading: true })

    const response = await updatedField.saveAction(payload)

    if (response.success) {
      setPageNotifications(['Your password has been updated.'])
      handleCloseFields()
      return response
    }

    setFieldProperties(updatedField.name, { disabled: false, loading: false })

    if (response.code === 'LimitExceededException') {
      setFormNotifications([
        'You have exceeded the number of attempts to change your password, please try again later.',
      ])
      return response
    }

    if (response.code === 'NotAuthorizedException') {
      setFormNotifications(['Please enter your current account password.'])
      return response
    }

    setFormNotifications(['We could not connect to the server right now, please try again later.'])
    return response
  }

  const handleSaveField = (name, payload) => {
    const updatedField = fields.find(field => field.name === name)
    const updateMethod = updatedField.saveAction

    clearFormNotifications()
    setFieldProperties(name, { disabled: true, loading: true })

    updateMethod(payload, token).then(response => {
      if (response.success) {
        getLandlordDetails().then(() => {
          handleCloseFields()
        })
      } else {
        setFieldProperties(name, { disabled: false, loading: false })
        setFormNotifications([
          'We could not connect to the server right now, please try again later.',
        ])
      }
    })
  }

  useEffect(() => {
    getLandlordDetails().then(() => setLoading(false))
  }, [])

  if (loading) return <LoadingPage />

  return (
    <>
      <FormPageHeading
        className="account-settings-heading"
        heading={heading}
        subheading={subheading}
      />
      {pageNotifications && (
        <>
          {pageNotifications.map(notification => (
            <Notification
              key={notification}
              className={styles.pageNotification}
              type="success"
              text={notification}
            />
          ))}
        </>
      )}
      {fields.map(field => {
        if (field.name === 'password') {
          return (
            <ExpandableField
              key={field.name}
              name={field.name}
              heading={field.heading}
              renderForm={props =>
                field.renderForm({
                  account,
                  onSave: handleSavePassword,
                  notifications: formNotifications,
                  ...props,
                })
              }
              renderValue={() => field.renderValue(account)}
              onOpen={name => handleOpenField(name)}
              onClose={handleCloseFields}
              expanded={field.expanded}
              disabled={field.disabled}
              loading={field.loading}
            />
          )
        }
        return (
          <ExpandableField
            key={field.name}
            name={field.name}
            heading={field.heading}
            renderForm={props =>
              field.renderForm({
                account,
                onSave: handleSaveField,
                notifications: formNotifications,
                ...props,
              })
            }
            renderValue={() => field.renderValue(account)}
            onOpen={name => handleOpenField(name)}
            onClose={handleCloseFields}
            expanded={field.expanded}
            disabled={field.disabled}
            loading={field.loading}
          />
        )
      })}
    </>
  )
}

AccountSettingsContainer.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  fieldConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
  getLandlord: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
}

export default AccountSettingsContainer
