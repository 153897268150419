import React from 'react'
import PropTypes from 'prop-types'

const InvoicingAddressValue = ({ account }) => {
  return account.hasInvoicingAddress ? (
    account.invoicingAddressLines().map((addressLine, i) => (
      <p key={addressLine} data-testid={`invoicing-address-line-${i}`}>
        {addressLine}
      </p>
    ))
  ) : (
    <p data-testid="no-invoicing-address">-</p>
  )
}

InvoicingAddressValue.propTypes = {
  account: PropTypes.object.isRequired,
}

export default InvoicingAddressValue
