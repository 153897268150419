import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import PropertyAdminCard from '../PropertyAdminCard'

import Tick from '../../../svgs/icons/selectable-tick.svg'

import styles from './selectableCard.module.scss'

const SelectablePropertyAdminCard = ({
  property,
  selected,
  handleSelection,
  userIsSelecting,
  ...props
}) => {
  const [userIsHovering, setUserIsHovering] = useState(false)
  const multiSelectEnabled = userIsSelecting || selected
  const hideLabelText = !(userIsHovering || multiSelectEnabled)

  const handleChange = () => {
    handleSelection(property.id)
    setUserIsHovering(false)
  }

  return (
    <div className={styles.card}>
      <input
        id={`property-${property.id}`}
        className={styles.input}
        name="select-property"
        type="checkbox"
        value={property.id}
        onChange={() => {
          handleChange()
        }}
        checked={selected}
        aria-labelledby={`property-${property.id}-label`}
        data-testid="select-property-input"
      />
      <label
        className={classNames({
          [styles.label]: !multiSelectEnabled,
          [styles.expandedLabel]: userIsHovering || multiSelectEnabled,
        })}
        htmlFor={`property-${property.id}`}
        data-testid="select-property-label"
      >
        {!multiSelectEnabled ? (
          <span
            className={styles.labelHoverToggle}
            onMouseEnter={() => setUserIsHovering(true)}
            onMouseLeave={() => setUserIsHovering(false)}
          >
            <Tick
              className={classNames(styles.checkbox, {
                [styles.checkboxSelected]: selected,
              })}
            />
          </span>
        ) : (
          <Tick
            className={classNames(styles.checkbox, {
              [styles.checkboxSelected]: selected,
            })}
          />
        )}

        <div
          className={classNames(styles.overlay, {
            [styles.overlayUserIsHovering]: userIsHovering,
            [styles.overlayUserIsSelecting]: multiSelectEnabled,
            [styles.overlaySelected]: selected,
          })}
        >
          <span
            id={`property-${property.id}-label`}
            className={classNames(styles.labelText, {
              [styles.labelTextHidden]: hideLabelText,
            })}
            data-testid="select-property-label-text"
          >
            {selected ? 'Selected' : 'Add to selection'}
          </span>
        </div>
      </label>

      <PropertyAdminCard
        imageClassName={!userIsSelecting ? styles.notSelectedImage : ''}
        property={property}
        tabIndex={userIsSelecting || selected ? -1 : undefined}
        {...props}
      />
    </div>
  )
}

SelectablePropertyAdminCard.propTypes = {
  className: PropTypes.string,
  property: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  handleSelection: PropTypes.func.isRequired,
  userIsSelecting: PropTypes.bool,
}

export default SelectablePropertyAdminCard
