import React from 'react'
import PropTypes from 'prop-types'

import hero from './hero.module.scss'

const Hero = ({ renderText }) => {
  return (
    <div className={hero.wrapper} data-testid="hero">
      <div className={hero.inner}>{renderText()}</div>
    </div>
  )
}

Hero.propTypes = {
  renderText: PropTypes.func,
}

export default Hero
