import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import SpinnerButton from '@afs/components/SpinnerButton'
import Heading from '@afs/components/Heading'

import useMedia from '../../../hooks/useMedia'

import SingleColumnFormTemplate from '../../templates/SingleColumnFormTemplate'
import BillsOptionalQuoteCard from '../../organisms/BillsOptionalQuoteCard'
import Link from '../../atoms/Link'
import QuoteDetailsOverlay from '../../organisms/QuoteDetailsOverlay'

import styles from './styles.module.scss'

const BillsOptionalYourQuotesPage = ({
  history,
  properties,
  handleRetryQuote,
  handleRemoveProperty,
  handleConfirmBillsOptionalProperties,
  isLoading,
}) => {
  const isMobile = useMedia(['(max-width: 500px)'], [true], false)

  const atLeastOneQuotePending = !!properties.find(property => property.quote.status === 'pending')

  const numberOfSuccessfulQuotes = properties.filter(
    property => property.quote.status === 'success'
  ).length

  const showDisabledButton = atLeastOneQuotePending || numberOfSuccessfulQuotes === 0

  const propertiesWithRefs = properties.map(property => ({ ...property, ref: createRef() }))

  const renderQuoteDetailsOverlay = (property, renderButton, handleRemoveProperty) => {
    return (
      <QuoteDetailsOverlay
        property={property}
        renderButton={renderButton}
        handleRemoveProperty={handleRemoveProperty}
        quoteDetailsOverlayHeading="Bills optional quote"
        quoteDetailsOverlayButtonText={isMobile ? 'Remove' : 'Remove from selection'}
        removeOverlayHeading="Remove property from selection"
        removeOverlaySubheading="Are you sure you want to remove this property from your selection?"
        removeOverlayButtonText={isMobile ? 'Remove' : 'Remove from selection'}
      />
    )
  }

  return (
    <SingleColumnFormTemplate
      heading="2. Your quotes"
      subheading="View below the quote prices for each property. To see the cost breakdown for each service, click the quote card."
      title="Your Quotes | Bills Optional | AFS"
      history={history}
      showStickyMenu
      stickyMenuContent={
        <div className={styles.menuContent}>
          {numberOfSuccessfulQuotes > 0 ? (
            <span className={styles.selectedCount} data-testid="selected-property-counter">
              {numberOfSuccessfulQuotes} selected
            </span>
          ) : null}

          <SpinnerButton
            className={styles.button}
            primary
            inactive={isLoading}
            disabled={showDisabledButton}
            onClick={handleConfirmBillsOptionalProperties}
          >
            Confirm
          </SpinnerButton>
        </div>
      }
    >
      <Heading className={styles.quotesHeading} level={2}>
        Selected properties
      </Heading>

      <TransitionGroup>
        {propertiesWithRefs.map(property => {
          return (
            <CSSTransition
              key={property.propertyId}
              classNames={{
                exit: styles.quoteCardExiting,
                exitActive: styles.quoteCardExited,
                exitDone: styles.quoteCardExited,
              }}
              nodeRef={property.ref}
              unmountOnExit
              timeout={1000}
            >
              <BillsOptionalQuoteCard
                ref={property.ref}
                className={styles.quoteCard}
                property={property}
                handleRemoveProperty={handleRemoveProperty}
                handleRetryQuote={handleRetryQuote}
                renderQuoteDetailsOverlay={renderQuoteDetailsOverlay}
              />
            </CSSTransition>
          )
        })}
        {properties.length === 0 ? (
          <div className={styles.noPropertiesMessage}>
            <strong className={styles.noPropertiesHeading}>No properties selected</strong>
            <p className={styles.noPropertiesText}>
              Go back to step 1 and{' '}
              <Link
                className={styles.noPropertiesLink}
                to="/landlord-admin/bills-optional/select-properties"
              >
                select your properties.
              </Link>
            </p>
          </div>
        ) : null}
      </TransitionGroup>
    </SingleColumnFormTemplate>
  )
}

BillsOptionalYourQuotesPage.propTypes = {
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      propertyId: PropTypes.string.isRequired,
      photo: PropTypes.string.isRequired,
      hasPhoto: PropTypes.bool.isRequired,
      address: PropTypes.shape({
        line1: PropTypes.string.isRequired,
        line2: PropTypes.string.isRequired,
      }),
      rentFrom: PropTypes.string.isRequired,
      quote: PropTypes.shape({
        status: PropTypes.oneOf(['success', 'failed', 'pending']).isRequired,
        lastUpdated: PropTypes.string,
        totalPricePppw: PropTypes.string,
        services: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            pricePppw: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            available: PropTypes.bool.isRequired,
          })
        ),
      }),
    })
  ),
  handleRetryQuote: PropTypes.func.isRequired,
  handleRemoveProperty: PropTypes.func.isRequired,
  handleConfirmBillsOptionalProperties: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default BillsOptionalYourQuotesPage
