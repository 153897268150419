import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { config } from '../../../config'

import Close from '../../../svgs/icons/close.svg'

import styles from './browserBanner.module.scss'

const BrowserBanner = ({ browserBannerService, ...props }) => {
  const [showBanner, setShowBanner] = useState(browserBannerService.shouldShowBanner())

  const handleClose = () => {
    setShowBanner(false)
    browserBannerService.dismissBanner()
  }

  if (!showBanner) return null

  const isAppleDevice = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
  const s3BucketUrl = `https://${config.assetsPublicS3Bucket}.s3.eu-west-2.amazonaws.com/browser-logos`

  return (
    <div className={styles.banner} role="banner" data-testid="browser-banner" {...props}>
      <div className={styles.inner}>
        <div className={styles.content}>
          <strong className={styles.heading}>Your browser is not supported.</strong>
          <p className={styles.description}>
            It looks like you may be using a web browser version that we don’t support. Make sure
            you’re using the most recent version of your browser, or try one of these supported
            browsers:
          </p>
        </div>
        <ul className={styles.browserList}>
          <li className={styles.browser}>
            <a
              className={styles.browserLink}
              href="https://www.mozilla.org/en-US/firefox/new"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.logo}
                src={`${s3BucketUrl}/firefox@2x.png`}
                alt="Download Mozilla Firefox"
              />
              Firefox
            </a>
          </li>
          <li className={styles.browser}>
            <a
              className={styles.browserLink}
              href="https://www.google.com/chrome"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.logo}
                src={`${s3BucketUrl}/chrome@2x.png`}
                alt="Download Google Chrome"
              />
              Chrome
            </a>
          </li>
          <li className={styles.browser}>
            <a
              className={styles.browserLink}
              href="https://www.microsoft.com/en-us/edge"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.logo}
                src={`${s3BucketUrl}/edge@2x.png`}
                alt="Download Microsoft Edge"
              />
              Edge
            </a>
          </li>
          {isAppleDevice ? (
            <li className={styles.browser}>
              <a
                className={styles.browserLink}
                href="https://support.apple.com/en-us/HT204416"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={styles.logo}
                  src={`${s3BucketUrl}/safari@2x.png`}
                  alt="Upgrade Safari"
                />
                Safari
              </a>
            </li>
          ) : (
            <li className={styles.browser}>
              <a
                className={styles.browserLink}
                href="https://www.opera.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={styles.logo}
                  src={`${s3BucketUrl}/opera@2x.png`}
                  alt="Download Opera"
                />
                Opera
              </a>
            </li>
          )}
        </ul>
      </div>
      <button className={styles.close} onClick={handleClose} data-testid="close-button">
        <Close className={styles.closeIcon} />
        <span className={styles.closeText}>Close</span>
      </button>
    </div>
  )
}

BrowserBanner.propTypes = {
  browserBannerService: PropTypes.object.isRequired,
}

export default BrowserBanner
