import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import FormPageHeading from '../../molecules/FormPageHeading'
import { notificationService } from '../../../container'

import './styles.scss'

const FormNavigation = ({ className, history, initialValues, pages, auth, useStateContext }) => {
  const [loading, setLoading] = useState(false)
  const [activePageId, setActivePageId] = useState(pages.getIdByRoute(history.location.pathname))
  const { propertyIds } = useStateContext()

  useEffect(() => {
    const nextRouteId = pages.getIdByRoute(history.location.pathname)
    if (activePageId !== nextRouteId) {
      const reviewPageId = 0
      if (nextRouteId !== reviewPageId) {
        notificationService.clearNotifications()
      }

      setActivePageId(nextRouteId)
    }
  })

  const validate = (values) => {
    const activePage = pages.getById(activePageId)
    return activePage.validate ? activePage.validate(values) : {}
  }

  const authenticateUser = async () => {
    return auth.currentSession().then((data) => {
      return data.idToken.jwtToken
    })
  }

  const handleSubmit = async (values) => {
    setLoading(true)
    propertyIds.deselectAll()

    const jwtToken = await authenticateUser()
    const activePage = pages.getById(activePageId)
    const result = await activePage.onSubmit(values, jwtToken)

    if (result.success) {
      notificationService.showSuccessNotification('', 'Your changes have been saved.')
    } else {
      notificationService.showErrorNotification('', 'There was a problem saving your changes.')
    }

    setLoading(false)

    history.goBack()
  }

  const updatePageValidity = (values) => {
    pages.updatePageValidity(activePageId, values)
  }

  const activePage = pages.getById(activePageId)

  return (
    <Form
      className={className}
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, values }) => {
        updatePageValidity(values)

        return (
          <div className="form-navigation">
            <form className={className} onSubmit={handleSubmit}>
              <FormPageHeading
                className="form-navigation__heading"
                heading={activePage.heading}
                headingComponent={
                  activePage.headingComponent && activePage.headingComponent(values)
                }
                subheading={activePage.subheading}
                optional={activePage.optional}
                subheadingComponent={
                  activePage.subheadingComponent && activePage.subheadingComponent(values)
                }
              />
              {activePage.component(values)}
              <div className="form-navigation__buttons">
                {activePage.button(values, loading, history)}
              </div>
            </form>
          </div>
        )
      }}
    </Form>
  )
}

FormNavigation.propTypes = {
  className: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  pages: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  useStateContext: PropTypes.func.isRequired,
}

export default FormNavigation
