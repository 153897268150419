import React, { createRef } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Heading from '@afs/components/Heading'

import { config } from '../../../config'
import useMedia from '../../../hooks/useMedia'

import BillsOptionalQuoteSuccessCard from '../../organisms/BillsOptionalQuoteSuccessCard'
import ManagePropertiesPageTemplate from '../../templates/ManagePropertiesPageTemplate'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import ManagePropertiesHero from '../../atoms/ManagePropertiesHero'
import Button from '../../atoms/Button'
import Picture from '../../atoms/Picture'
import QuoteDetailsOverlay from '../../organisms/QuoteDetailsOverlay'

import PlusIcon from './svgs/plus.svg'
import FusedLogo from './svgs/fused-logo.svg'
import Checkmark from './svgs/checkmark.svg'

import styles from './styles.module.scss'

const noPropertiesPhoto =
  'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/bills-optional/properties-page/no-properties.png'

const renderQuoteDetailsOverlay = (
  property,
  renderButton,
  handleRemoveProperty,
  propertyIdToShowInOverlay,
  isMobile
) => {
  const shouldShowOpenInOverlay = property.propertyId === propertyIdToShowInOverlay

  return (
    <QuoteDetailsOverlay
      property={property}
      initialIsActive={shouldShowOpenInOverlay}
      renderButton={renderButton}
      handleRemoveProperty={handleRemoveProperty}
      quoteDetailsOverlayHeading="Bills optional quote"
      quoteDetailsOverlayButtonText={isMobile ? 'Remove' : 'Remove bills optional'}
      removeOverlayHeading="Remove bills optional"
      removeOverlaySubheading="Are you sure you want to remove the bills optional quote from this property?"
      removeOverlayButtonText="Remove"
    />
  )
}

const BillsOptionalPropertiesPage = ({ properties, handleRemoveProperty }) => {
  const isMobile = useMedia(['(max-width: 500px)'], [true], false)

  const { search } = useLocation()

  const propertyIdFromQuery = new URLSearchParams(search).get('propertyId')
  const propertyIdToShowInOverlay =
    propertyIdFromQuery !== null ? Number(propertyIdFromQuery) : propertyIdFromQuery

  const propertiesWithRefs = properties.map((property) => ({ ...property, ref: createRef() }))

  const renderEmptyState = () => {
    return (
      <div className={styles.noProperties}>
        <Picture
          className={styles.noPropertiesImage}
          alt="Add a property"
          src={`${config.imgApiUrl}w=273/${noPropertiesPhoto}`}
          lazyload={false}
          sources={[
            {
              id: 'desktop',
              src: `${config.imgApiUrl}w=273/${noPropertiesPhoto} 1x, ${config.imgApiUrl}w=273,dpr=2/${noPropertiesPhoto} 2x`,
              media: '(min-width: 661px)',
            },
            {
              id: 'mobile',
              src: `${config.imgApiUrl}w=153/${noPropertiesPhoto} 1x, ${config.imgApiUrl}w=153,dpr=2/${noPropertiesPhoto} 2x`,
              media: '(max-width: 660px)',
            },
          ]}
        />
        <Heading level={2} className={styles.noPropertiesHeading}>
          You’ve not added any properties
        </Heading>
        <p className={styles.noPropertiesSubheading}>
          Enhance your properties in seconds by advertising them as bills optional.
        </p>
        <Button
          className={styles.noPropertiesButton}
          to="/landlord-admin/bills-optional/select-properties"
          violet
        >
          <PlusIcon className={styles.buttonIcon} />
          Add a property
        </Button>
        <ul className={styles.benefits}>
          <li>
            <Checkmark className={styles.benefitsIcon} />
            <p className={styles.benefitsText}>Fully managed utility bills with no liability</p>
          </li>
          <li>
            <Checkmark className={styles.benefitsIcon} />
            <p className={styles.benefitsText}>Receive £50 commission per property</p>
          </li>
          <li>
            <Checkmark className={styles.benefitsIcon} />
            <p className={styles.benefitsText}>One simple payment for tenants</p>
          </li>
          <li>
            <Checkmark className={styles.benefitsIcon} />
            <p className={styles.benefitsText}>100% unlimited energy</p>
          </li>
        </ul>
        <span className={styles.poweredBy}>
          In partnership with: <span className={styles.hiddenText}>Fused</span>
        </span>
        <FusedLogo className={styles.fusedLogo} />
      </div>
    )
  }

  const renderCardGrid = () => {
    return (
      <TransitionGroup className={styles.cardGrid}>
        {propertiesWithRefs.map((property) => {
          return (
            <CSSTransition
              key={property.propertyId}
              classNames={{
                exit: styles.cardExiting,
                exitActive: styles.cardExited,
                exitDone: styles.cardExited,
              }}
              nodeRef={property.ref}
              unmountOnExit
              timeout={1000}
            >
              <BillsOptionalQuoteSuccessCard
                ref={property.ref}
                className={styles.card}
                key={property.propertyId}
                property={property}
                handleRemoveProperty={handleRemoveProperty}
                renderQuoteDetailsOverlay={(property, renderButton, handleRemoveProperty) =>
                  renderQuoteDetailsOverlay(
                    property,
                    renderButton,
                    handleRemoveProperty,
                    propertyIdToShowInOverlay,
                    isMobile
                  )
                }
              />
            </CSSTransition>
          )
        })}

        <div className={styles.partnership}>
          <span className={styles.poweredBy}>
            Powered by <span className={styles.hiddenText}>Fused</span>
          </span>
          <FusedLogo className={styles.fusedLogo} />
        </div>
      </TransitionGroup>
    )
  }

  return (
    <ManagePropertiesPageTemplate
      className={styles.page}
      title="Bills Optional Properties | AFS"
      header={<Header />}
      footer={<Footer dark />}
      hero={
        <ManagePropertiesHero pattern="none" paddingType="small">
          <div className={styles.row}>
            <div className={styles.details}>
              <Heading className={styles.heading}>Bills optional properties</Heading>
              <span className={styles.count} data-testid="property-count">
                {properties.length} {properties.length === 1 ? 'property' : 'properties'}
              </span>
            </div>
            <Button
              className={styles.button}
              to="/landlord-admin/bills-optional/select-properties"
              primary
            >
              <PlusIcon className={styles.buttonIcon} />
              {properties.length === 0 ? 'Add a property' : 'Add another property'}
            </Button>
          </div>
        </ManagePropertiesHero>
      }
    >
      {properties.length === 0 ? renderEmptyState() : renderCardGrid()}
    </ManagePropertiesPageTemplate>
  )
}

BillsOptionalPropertiesPage.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      propertyId: PropTypes.number.isRequired,
      rentFrom: PropTypes.string.isRequired,
      numberOfBedrooms: PropTypes.number.isRequired,
      hasPhoto: PropTypes.bool.isRequired,
      photo: PropTypes.string.isRequired,
      address: PropTypes.shape({
        line1: PropTypes.string.isRequired,
        line2: PropTypes.string.isRequired,
        postcode: PropTypes.string.isRequired,
      }),
      quote: {
        totalPricePppw: PropTypes.string.isRequired,
        services: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            pricePppw: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            available: PropTypes.string.isRequired,
          })
        ).isRequired,
        tenancyStartDate: PropTypes.string.isRequired,
        lastUpdated: PropTypes.string.isRequired,
      },
    })
  ).isRequired,
  handleRemoveProperty: PropTypes.func.isRequired,
}

export default BillsOptionalPropertiesPage
