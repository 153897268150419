import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'

import Address, { validateStreet, validatePostcode } from '../../../../models/address'
import locationMatcher from '../../../../services/locationMatcher'

import Button from '../../../atoms/Button'

import FieldAdapter from '../../../adapters/FieldAdapter'
import FieldLocationAdapter from '../../../adapters/FieldLocationAdapter'

const EditAddressForm = ({ existingAddress, onSelectAddress, notificationService, showError }) => {
  const separateFlatString = flatWithBuildingName => {
    if (flatWithBuildingName) return flatWithBuildingName.split(',')[0]
    return ''
  }
  const separateBuildingString = flatWithBuildingName => {
    if (flatWithBuildingName && flatWithBuildingName.split(',').length > 1)
      return flatWithBuildingName.split(',')[1].trim()
    return ''
  }

  const onSubmit = values => {
    const createdAddress = new Address({
      ...existingAddress,
      flat: separateFlatString(values.flat),
      buildingName: separateBuildingString(values.flat),
      buildingNumber: values.buildingNumber || '',
      street: values.street,
      townCity: values.townCity,
      postcode: values.postcode,
      hasValidLocation: true,
    })

    onSelectAddress(createdAddress)
  }

  const initialFormValues = existingAddress
    ? {
        ...existingAddress,
        flat: existingAddress.flatAndBuildingName(),
      }
    : {
        flat: '',
        buildingName: '',
        buildingNumber: '',
        street: '',
        townCity: '',
        postcode: '',
        latitude: '',
        longitude: '',
      }

  const hasLocation = !!initialFormValues.townCity
  const locationIsValid = locationMatcher.isValidLocation(initialFormValues.townCity)

  if (hasLocation && !locationIsValid) {
    notificationService.showErrorNotification(
      null,
      "The city name doesn't match our records. Please select it manually from the list below."
    )
  }

  if (showError) {
    notificationService.showErrorNotification(null, showError)
  }

  return (
    <>
      <Form
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, invalid, submitFailed }) => (
          <form
            className="edit-address-form"
            onSubmit={handleSubmit}
            data-testid="edit-address-form"
          >
            <div className="edit-address-form__row">
              <Field
                className="edit-address-form__field edit-address-form__field--building"
                name="buildingNumber"
                component={FieldAdapter}
                validate={value => {
                  if (!value) return false

                  const amountNumber = parseFloat(value)
                  const numberIsValid = !Number.isNaN(amountNumber) && amountNumber > 0
                  return !numberIsValid ? 'Please enter a number.' : ''
                }}
              >
                {props => <FieldAdapter label="Building no." {...props} />}
              </Field>

              <Field
                className="edit-address-form__field edit-address-form__field--street"
                name="street"
                component={FieldAdapter}
                validate={value => {
                  if (value) {
                    const streetIsValid = validateStreet(value)
                    return !streetIsValid
                      ? 'Please enter a valid street name (letters, commas, spaces and hyphens only).'
                      : ''
                  } else {
                    return 'Please enter a street name.'
                  }
                }}
              >
                {props => <FieldAdapter label="Street name" {...props} />}
              </Field>
            </div>

            <Field className="edit-address-form__field" name="flat" component={FieldAdapter}>
              {props => <FieldAdapter label="Flat, unit, apartment" optional {...props} />}
            </Field>

            <Field
              className="edit-address-form__field"
              name="townCity"
              component={FieldLocationAdapter}
              validate={value => {
                const locationFieldNotPopulated = !value && !existingAddress

                if (locationFieldNotPopulated) return false

                if (value) {
                  const locationIsValid = locationMatcher.isValidLocation(value)
                  return !locationIsValid ? 'City name doesn’t match our records.' : ''
                } else {
                  return 'Please enter a town/city.'
                }
              }}
            >
              {props => <FieldLocationAdapter label="Town/City" {...props} />}
            </Field>

            <Field
              className="edit-address-form__field"
              name="postcode"
              component={FieldAdapter}
              validate={value => {
                const postcodeIsValid = validatePostcode(value)
                return !postcodeIsValid ? 'Please enter a valid postcode.' : ''
              }}
            >
              {props => <FieldAdapter label="Postcode" {...props} />}
            </Field>

            <Button
              primary
              className="edit-address-form__submit overlay__continue"
              type="submit"
              inactive={invalid && submitFailed}
              data-testid="continue-button"
            >
              Continue
            </Button>
          </form>
        )}
      />
    </>
  )
}

EditAddressForm.propTypes = {
  onSelectAddress: PropTypes.func,
  existingAddress: PropTypes.object,
  notificationService: PropTypes.object,
  showError: PropTypes.string,
}

export default EditAddressForm
