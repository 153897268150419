import xss from 'xss'

const parseQueryString = queryString => {
  const query = {}
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
  pairs.forEach(pair => {
    const splitPair = pair.split(/=(.+)/)
    const queryPropertyName = decodeURIComponent(splitPair[0])
    query[queryPropertyName] = xss(decodeURIComponent(splitPair[1] || ''))
  })
  return query
}

export default parseQueryString
