import React, { useContext } from 'react'
import PropTypes from 'prop-types'

const AppStateContext = React.createContext()

export const StateProvider = ({ children, state }) => (
  <AppStateContext.Provider value={state}>{children}</AppStateContext.Provider>
)

StateProvider.propTypes = {
  children: PropTypes.node,
  state: PropTypes.object,
}

export const useStateContext = () => useContext(AppStateContext)
