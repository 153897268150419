import React from 'react'

import LoginRegisterPageTemplate from '../../templates/LoginRegisterPageTemplate'
import LoginCard from '../../molecules/LoginCard'

const LoginPage = () => (
  <LoginRegisterPageTemplate title="Login | AFS">
    <LoginCard className="login-register__login-card" pageType="login" />
  </LoginRegisterPageTemplate>
)

export default LoginPage
