import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'
import Notifications from '../../organisms/Notifications'

import './styles.scss'

const PageTemplate = ({
  className,
  header,
  progressBar,
  footer,
  children,
  title,
  dontDisplayNotifications,
}) => {
  const pageClasses = classNames('site-main', className)

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {header}
      {progressBar}
      <main className={pageClasses}>
        {!dontDisplayNotifications && (
          <Notifications className="site-main__notifications-container" />
        )}
        {children}
      </main>
      {footer}
    </>
  )
}

PageTemplate.propTypes = {
  className: PropTypes.string,
  header: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
  children: PropTypes.any.isRequired,
  title: PropTypes.string,
  progressBar: PropTypes.node,
  dontDisplayNotifications: PropTypes.bool,
}

export default PageTemplate
