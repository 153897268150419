import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import AfsButton from '@afs/components/Button'

const Button = props => {
  const renderLinkComponent = ({ children, ...props }) => <NavLink {...props}>{children}</NavLink>

  return <AfsButton {...props} renderLinkComponent={renderLinkComponent} />
}

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  href: PropTypes.string,
  to: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  dark: PropTypes.bool,
  inactive: PropTypes.bool,
  deletion: PropTypes.bool,
  wide: PropTypes.bool,
  violet: PropTypes.bool,
  yellow: PropTypes.bool,
  teal: PropTypes.bool,
  secondaryOrange: PropTypes.bool,
  secondaryViolet: PropTypes.bool,
}

export default Button
