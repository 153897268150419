import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import SpinnerButton from '@afs/components/SpinnerButton'
import Heading from '@afs/components/Heading'
import Field from '@afs/components/Field'

import Link from '../../atoms/Link'
import PasswordRequirements from '../../atoms/PasswordRequirements'
import FieldHoneypot from '../../molecules/FieldHoneypot'

import {
  validateFieldDoesNotIncludeHTML,
  validateEmailAddress,
  validatePassword,
  validateConfirmPasswordMatchesPassword,
} from './validation'

import styles from './styles.module.scss'

const LettingAgentRegisterForm = ({ createAccount }) => {
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    setError,
    trigger,
    formState,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      personalPhoneNumber: '',
      publicPhoneNumber: '',
      companyName: '',
      accountEmail: '',
      accountPassword: '',
      accountPasswordConfirm: '',
      termsAccepted: false,
      copyPhoneNumbers: false,
      accountType: 'LettingAgent',
    },
    mode: 'onBlur',
  })

  const { touched } = formState

  const [userIsUpdating, setUserIsUpdating] = useState(true)
  const [password, setPassword] = useState('')
  const [copyPhoneNumbersEnabled, setCopyPhoneNumbersEnabled] = useState(
    getValues('copyPhoneNumbers')
  )
  const [honeypot, setHoneypot] = useState(null)
  const [loading, setLoading] = useState(false)

  const botNotSubmittingForm = honeypot ? !honeypot.state.value : true

  const accountFieldsNotApplicableToLettingAgents = {
    smsAccepted: false,
  }

  const submitForm = formData => {
    if (botNotSubmittingForm) {
      setLoading(true)

      let payload = {
        ...formData,
        ...accountFieldsNotApplicableToLettingAgents,
        accountType: 'LettingAgent',
      }

      const { copyPhoneNumbers, publicPhoneNumber } = formData

      if (copyPhoneNumbers) {
        payload = {
          ...payload,
          personalPhoneNumber: publicPhoneNumber,
        }
      }

      createAccount(payload).then(response => {
        setLoading(false)
        if (response === 'AFS4001') {
          setError('accountEmail', {
            type: 'manual',
            message: 'A user with this email address already exists.',
          })
        }
      })
    }
  }

  return (
    <div className={styles.wrapper}>
      <Heading className={styles.heading} level={1}>
        <span className={styles.headingSmallThin}>Sign up for a </span>
        Letting Agent Account
      </Heading>

      <form onSubmit={handleSubmit(submitForm)}>
        <fieldset className={styles.fieldset}>
          <legend className={styles.headingSmall}>Letting agency details</legend>
          <p className={styles.text}>
            Please note that the information in this section{' '}
            <strong className={styles.bold}>will be displayed publicly</strong> on all your property
            listings.
          </p>
          <Field
            className={styles.field}
            name="companyName"
            label="Agency name"
            ref={register({
              required: 'The agency name is required.',
              maxLength: 150,
              validate: validateFieldDoesNotIncludeHTML,
            })}
            invalid={!!errors.companyName}
            error={errors.companyName ? errors.companyName.message : ''}
            validated={touched.companyName && !errors.companyName}
          />
          <Field
            name="publicPhoneNumber"
            label="Enquiries phone number"
            type="tel"
            ref={register({
              required: 'The enquiries phone number is required.',
              maxLength: 20,
              pattern: {
                value: /(((\+44)? ?(\(0\))? ?)|(0))( ?[0-9]{3,4}){3}/,
                message: 'Please enter a valid phone number.',
              },
              validate: validateFieldDoesNotIncludeHTML,
            })}
            placeholder="07400 123 456"
            onBlur={async e => {
              if (copyPhoneNumbersEnabled) {
                const publicPhoneNumber = e.target.value
                setValue('personalPhoneNumber', publicPhoneNumber, {
                  shouldValidate: false,
                })
              }
            }}
            invalid={!!errors.publicPhoneNumber}
            error={errors.publicPhoneNumber ? errors.publicPhoneNumber.message : ''}
            validated={touched.publicPhoneNumber && !errors.publicPhoneNumber}
          />
        </fieldset>

        <fieldset className={styles.fieldset}>
          <legend className={styles.headingSmall}>Main person of contact</legend>
          <p className={styles.text}>
            The information in this section will only be used by our team and it{' '}
            <strong className={styles.bold}>will not be made public</strong> or shared with any 3rd
            parties.
          </p>
          <Field
            className={styles.field}
            name="firstName"
            label="First name"
            ref={register({
              required: 'The first name is required.',
              maxLength: 100,
              validate: validateFieldDoesNotIncludeHTML,
            })}
            invalid={!!errors.firstName}
            error={errors.firstName ? errors.firstName.message : ''}
            validated={touched.firstName && !errors.firstName}
          />
          <Field
            className={styles.field}
            name="lastName"
            label="Last name"
            ref={register({
              required: 'The last name is required.',
              maxLength: 100,
              validate: validateFieldDoesNotIncludeHTML,
            })}
            invalid={!!errors.lastName}
            error={errors.lastName ? errors.lastName.message : ''}
            validated={touched.lastName && !errors.lastName}
          />
          <Field
            name="personalPhoneNumber"
            label="Phone number"
            type="tel"
            ref={register({
              required: 'The phone number is required.',
              maxLength: 20,
              validate: validateFieldDoesNotIncludeHTML,
              pattern: {
                value: /(((\+44)? ?(\(0\))? ?)|(0))( ?[0-9]{3,4}){3}/,
                message: 'Please enter a valid phone number.',
              },
            })}
            placeholder="07400 123 456"
            onBlur={async e => {
              if (copyPhoneNumbersEnabled) {
                const personalPhoneNumber = e.target.value
                setValue('publicPhoneNumber', personalPhoneNumber, {
                  shouldValidate: false,
                })
              }
            }}
            disabled={copyPhoneNumbersEnabled}
            invalid={!copyPhoneNumbersEnabled && !!errors.personalPhoneNumber}
            error={errors.personalPhoneNumber ? errors.personalPhoneNumber.message : ''}
            validated={
              getValues('copyPhoneNumbers')
                ? true
                : touched.personalPhoneNumber && !errors.personalPhoneNumber
            }
          />
          <Field
            className={classNames(styles.field, styles.checkbox, styles.bottomField)}
            name="copyPhoneNumbers"
            label="Same as the enquiries phone number."
            type="checkbox"
            ref={register}
            onChange={event => {
              const publicPhoneNumber = getValues('publicPhoneNumber')
              const copyPhoneNumbers = event.target.checked
              setCopyPhoneNumbersEnabled(copyPhoneNumbers)

              return copyPhoneNumbers
                ? setValue('personalPhoneNumber', publicPhoneNumber, {
                    shouldValidate: false,
                  })
                : setValue('personalPhoneNumber', '', { shouldValidate: true })
            }}
          />
        </fieldset>

        <fieldset className={styles.fieldset}>
          <legend className={styles.headingSmall}>Account details</legend>
          <p className={styles.text}>These will be used for logging in and managing the account.</p>
          <Field
            className={styles.field}
            name="accountEmail"
            label="Email address"
            ref={register({
              required: 'The email address is required.',
              maxLength: 320,
              validate: {
                validEmail: validateEmailAddress,
                doesNotIncludeHTML: validateFieldDoesNotIncludeHTML,
              },
            })}
            autoComplete="username"
            invalid={!!errors.accountEmail}
            error={errors.accountEmail ? errors.accountEmail.message : ''}
            validated={touched.accountEmail && !errors.accountEmail}
          />
          <Field
            className={styles.passwordField}
            name="accountPassword"
            label="Password"
            autoComplete="new-password"
            ref={register({
              required: 'The password is required.',
              maxLength: 120,
              validate: {
                validPassword: validatePassword,
                doesNotIncludeHTML: validateFieldDoesNotIncludeHTML,
              },
            })}
            type="password"
            onChange={async e => {
              const updatedPassword = e.target.value
              setPassword(updatedPassword)

              if (touched.accountPassword) {
                await trigger('accountPassword')
              }
              if (touched.accountPasswordConfirm) {
                await trigger('accountPasswordConfirm')
              }
            }}
            onBlur={() => setUserIsUpdating(false)}
            invalid={!!errors.accountPassword}
            error={errors.accountPassword ? errors.accountPassword.message : ''}
            validated={touched.accountPassword && !errors.accountPassword}
          />
          <PasswordRequirements
            className={styles.passwordRequirements}
            password={password}
            userIsUpdating={userIsUpdating}
          />
          <Field
            className={styles.field}
            name="accountPasswordConfirm"
            label="Confirm password"
            autoComplete="new-password"
            ref={register({
              required: 'The password confirmation is required.',
              maxLength: 120,
              validate: () =>
                validateConfirmPasswordMatchesPassword(
                  getValues('accountPasswordConfirm'),
                  getValues('accountPassword')
                ),
            })}
            onChange={async () => {
              if (touched.accountPasswordConfirm) {
                await trigger('accountPasswordConfirm')
              }
            }}
            type="password"
            invalid={!!errors.accountPasswordConfirm}
            error={errors.accountPasswordConfirm ? errors.accountPasswordConfirm.message : ''}
            validated={touched.accountPasswordConfirm && !errors.accountPasswordConfirm}
          />
          <div className={styles.termsCheckboxGroup}>
            <Field
              className={
                !!errors.termsAccepted
                  ? classNames(styles.checkbox, styles.checkboxTerms, styles.checkboxInvalid)
                  : classNames(styles.checkbox, styles.checkboxTerms)
              }
              name="termsAccepted"
              label={[
                <span key={1}>I have read and agree to the </span>,
                <Link
                  key={2}
                  className={!!errors.termsAccepted ? styles.labelLinkInvalid : styles.labelLink}
                  href="/terms"
                  target="_blank"
                >
                  terms and conditions.
                </Link>,
              ]}
              type="checkbox"
              ref={register({ required: true })}
              invalid={!!errors.termsAccepted}
              error={errors.termsAccepted ? errors.termsAccepted.message : ''}
              onChange={() => trigger('termsAccepted')}
              data-testid="terms-checkbox"
            />
            <Field
              className={classNames(styles.checkbox, styles.checkboxTerms)}
              name="offersAccepted"
              label="I wish to receive exclusive offers from AFS and/or third parties."
              type="checkbox"
              ref={register}
            />
          </div>
        </fieldset>
        <FieldHoneypot
          key={6}
          ref={input => {
            setHoneypot(input)
          }}
        />
        <SpinnerButton data-testid="button" primary inactive={loading}>
          Create account
        </SpinnerButton>
      </form>
    </div>
  )
}

LettingAgentRegisterForm.propTypes = {
  createAccount: PropTypes.func.isRequired,
}

export default LettingAgentRegisterForm
