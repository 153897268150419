import React from 'react'
import Heading from '@afs/components/Heading'
import { useLocation } from 'react-router-dom'

import PageTemplate from '../../templates/PageTemplate'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Button from '../../atoms/Button'

import CircularCheckmark from './circular-checkmark.svg'

import styles from './styles.module.scss'

const BillsOptionalThankYouPage = () => {
  const { search } = useLocation()

  const numberOfProperties = new URLSearchParams(search).get('numberOfProperties')

  return (
    <PageTemplate
      className={styles.page}
      header={<Header />}
      footer={<Footer noDecoration noMarginTop />}
      title="Thank You | Bills Optional | AFS"
    >
      <div className={styles.content}>
        <div className={styles.thankYou}>
          <CircularCheckmark className={styles.checkMark} />
          <Heading className={styles.thankYouHeading}>Congratulations!</Heading>
          <p className={styles.thankYouText} data-testid="thank-you-text">
            <span className={styles.thankYouTextFirstLine}>
              Your {numberOfProperties > 1 ? 'properties' : 'property'} will now appear in both{' '}
            </span>
            No bills and Bills inclusive searches!
          </p>
          <Button to="/landlord-admin/bills-optional/properties" className={styles.button} violet>
            View bills optional properties
          </Button>
        </div>
      </div>
    </PageTemplate>
  )
}

export default BillsOptionalThankYouPage
