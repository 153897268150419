import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Link from '../../atoms/Link'

import Arrow from '../../../svgs/icons/chevron-right.svg'
import listRow from './listRow.module.scss'

const ListRow = ({
  className,
  singleCard,
  leftCard,
  rightCard,
  leftCardWrapperProps,
  rightCardWrapperProps,
  isBelowBreakPoint,
  ...props
}) => {
  const showTwoCards = !singleCard || isBelowBreakPoint
  const showSingleCard = singleCard && !isBelowBreakPoint

  const wrapperClasses = classNames(
    listRow.wrapper,
    { [listRow.singleCard]: showSingleCard },
    className
  )

  const leftCardClasses = classNames(listRow.leftCard, leftCardWrapperProps?.className)
  const rightCardClasses = classNames(listRow.rightCard, rightCardWrapperProps?.className)

  return (
    <div className={wrapperClasses} {...props}>
      {showTwoCards && !singleCard ? (
        <Link {...leftCardWrapperProps} className={leftCardClasses}>
          {leftCard}
          <Arrow className={listRow.leftArrow} />
        </Link>
      ) : (
        <div {...leftCardWrapperProps} className={leftCardClasses}>
          {leftCard}
          {!showSingleCard && <Arrow className={listRow.leftArrow} />}
        </div>
      )}

      <div {...rightCardWrapperProps} className={rightCardClasses}>
        {rightCard}
        {showSingleCard && <Arrow className={listRow.rightArrow} />}
      </div>
    </div>
  )
}

ListRow.propTypes = {
  className: PropTypes.string,
  singleCard: PropTypes.bool,
  leftCard: PropTypes.object.isRequired,
  rightCard: PropTypes.object.isRequired,
  leftCardWrapperProps: PropTypes.object,
  rightCardWrapperProps: PropTypes.object,
  isBelowBreakPoint: PropTypes.bool,
}

export default ListRow
