/* eslint-disable no-undef */
const isMixpanelAvailable = () => typeof window.mixpanel !== 'undefined'
const shouldLogEventsToConsole = window.location.hostname !== 'www.accommodationforstudents.com'

export const logEvent = (eventName, eventProperties) => {
  if (shouldLogEventsToConsole) {
    console.log(eventProperties, eventName)
  }

  if (isMixpanelAvailable()) {
    mixpanel.track(eventName, eventProperties)
  }
}

export const logVisitPageInLandlordAdmin = (pagePath, campaign, accountType) => {
  logEvent('Visit Page In Landlord Admin', {
    'Page Path': pagePath,
    Campaign: campaign,
    'Account Type': accountType,
  })
}

export const identifyUser = (userId) => {
  if (isMixpanelAvailable()) {
    mixpanel.identify(userId)
  }
}

export const setUserProperty = (key, value) => {
  if (isMixpanelAvailable()) {
    mixpanel.people.set(key, value)
  }
}
