import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'
import Heading from '@afs/components/Heading'

import useMedia from '../../../hooks/useMedia'

import Notifications from '../../organisms/Notifications'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import BackLink from '../../molecules/BackLink'
import IconBackLink from '../../atoms/IconBackLink'

import styles from './styles.module.scss'

const SingleColumnFormTemplate = ({
  title,
  heading,
  subheading,
  children,
  stickyMenuContent,
  showStickyMenu,
  history,
}) => {
  const showMobileHeader = useMedia(['(max-width: 660px)'], [true], false)
  const showMobileNotifications = useMedia(['(max-width: 660px)'], [true], false)

  const onBackLinkClick = (e) => {
    e.preventDefault()

    history.goBack()
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header />
      <main className={styles.main}>
        <div className={styles.headingSection}>
          <div className={styles.headingSectionContent}>
            {showMobileHeader ? (
              <IconBackLink className={styles.mobileBackLink} onClick={onBackLinkClick} href="/" />
            ) : (
              <BackLink className={styles.backLink} onClick={onBackLinkClick} href="/">
                Back
              </BackLink>
            )}

            <Heading className={styles.heading} level={1}>
              {heading}
            </Heading>
          </div>
        </div>

        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            {showMobileNotifications && <Notifications className={styles.notifications} />}
            <p className={styles.subheading}>{subheading}</p>
            {!showMobileNotifications && <Notifications className={styles.notifications} />}
            {children}
          </div>
        </div>
        {stickyMenuContent ? (
          <div
            className={classNames(styles.stickyMenuWrapper, {
              [styles.stickyMenuWrapperVisible]: showStickyMenu,
            })}
            data-testid="sticky-menu"
          >
            <div className={styles.stickyMenuContent}>{stickyMenuContent}</div>
          </div>
        ) : null}
      </main>
      <Footer className={styles.footer} />
    </>
  )
}

SingleColumnFormTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
  stickyMenuContent: PropTypes.node,
  showStickyMenu: PropTypes.bool,
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
}

export default SingleColumnFormTemplate
