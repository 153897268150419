import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import AccountCard from '../../molecules/AccountCard'
import AddPropertyIcon from '../../../svgs/icons/add-property.svg'
import ManagePropertiesIcon from '../../../svgs/icons/manage-properties.svg'
import BoostPropertiesIcon from '../../../svgs/icons/boost.svg'
import EnquiriesIcon from '../../../svgs/icons/enquiries.svg'
import InvoicesIcon from '../../../svgs/icons/invoices.svg'
import PerformanceIcon from '../../../svgs/icons/performance.svg'
import TestimonialIcon from '../../../svgs/icons/testimonial.svg'
import BillsOptionalIcon from '../../../svgs/icons/bills-optional.svg'

import './styles.scss'

const AccountCardGrid = ({ className, accountHasAdvertised, hasBillsOptionalProperties }) => {
  const accountCardGridClasses = classNames('account-card-grid', className)

  return (
    <div className={accountCardGridClasses}>
      <div className="account-card-grid__page">
        <AccountCard
          className="account-card-grid__item"
          link="/landlord-admin/add-property"
          text="Add property"
          icon={<AddPropertyIcon className="account-card__icon" />}
          data-testid="add-property-card"
        />
        <AccountCard
          className="account-card-grid__item"
          link="/landlord-admin/properties"
          text="Properties"
          icon={<ManagePropertiesIcon className="account-card__icon" />}
          data-testid="manage-properties-card"
        />
        {accountHasAdvertised && (
          <AccountCard
            className="account-card-grid__item"
            link="/landlord-admin/boost-properties"
            text="Boost properties"
            icon={<BoostPropertiesIcon className="account-card__icon" />}
            data-testid="boost-properties-card"
          />
        )}
        <AccountCard
          className="account-card-grid__item"
          link="/landlord-admin/enquiries"
          text="Enquiries"
          icon={<EnquiriesIcon className="account-card__icon" />}
          data-testid="enquiries-card"
        />
        <AccountCard
          className="account-card-grid__item"
          link="/landlord-admin/performance"
          text="Performance"
          icon={<PerformanceIcon className="account-card__icon" />}
          data-testid="performance-card"
        />
        <AccountCard
          className="account-card-grid__item"
          link="/landlord-admin/testimonials"
          text="Testimonials"
          icon={<TestimonialIcon className="account-card__icon" />}
          data-testid="testimonials-card"
        />
        <AccountCard
          className="account-card-grid__item"
          link="/landlord-admin/invoices"
          text="Invoices"
          icon={<InvoicesIcon className="account-card__icon" />}
          data-testid="invoices-card"
        />
        {hasBillsOptionalProperties ? (
          <AccountCard
            className="account-card-grid__item"
            link="/landlord-admin/bills-optional/properties"
            text="Bills optional"
            tagline="with Fused"
            icon={<BillsOptionalIcon className="account-card__icon" />}
            data-testid="bills-optional-card"
          />
        ) : (
          <AccountCard
            className="account-card-grid__item"
            link="/landlord-admin/bills-optional"
            text="Go bills optional"
            tagline="with Fused"
            icon={<BillsOptionalIcon className="account-card__icon" />}
            data-testid="go-bills-optional-card"
          />
        )}
      </div>
    </div>
  )
}

AccountCardGrid.propTypes = {
  className: PropTypes.string,
  accountHasAdvertised: PropTypes.bool.isRequired,
  hasBillsOptionalProperties: PropTypes.bool.isRequired,
}

export default AccountCardGrid
