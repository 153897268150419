import axios from 'axios'
import { Auth } from 'aws-amplify'

import { config } from '../config'
import { performApiGet, performApiPost, performApiPatch, performApiDelete } from './requests'

import Property from '../models/property'

export const delistProperty = async (propertyId, token) => {
  const url = `/property/${propertyId}/delist`

  try {
    const response = await performApiPatch(url, '', token)
    return {
      success: response.status === 200,
      property: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const relistProperty = async (propertyId, token) => {
  const url = `/property/${propertyId}/relist`

  try {
    const response = await performApiPatch(url, '', token)
    return {
      success: response.status === 200,
      property: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const togglePropertyListingStatus = async (propertyId, callback) => {
  const url = `/property/toggle-listing/${propertyId}`
  Auth.currentSession().then(data => {
    const axiosInstance = axios.create({
      baseURL: config.apiBaseUrl,
      timeout: 30000,
      headers: {
        Authorization: `Bearer ${data.idToken.jwtToken}`,
        'Content-Type': 'application/json',
      },
    })

    axiosInstance.patch(url).then(() => {
      callback()
    })
  })
}

const getPropertyByIdFromApi = async (propertyId, token) => {
  const url = `/property/${propertyId}`

  try {
    const response = await performApiGet(url, token)
    return {
      success: response.status === 200,
      property: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getPropertyById = async (propertyId, token) => {
  const response = await getPropertyByIdFromApi(propertyId, token)

  if (!response.success) return response

  return {
    success: true,
    property: new Property(response.property),
  }
}

export const deletePropertyById = async (propertyId, token) => {
  const url = `/property/${propertyId}`

  try {
    const response = await performApiDelete(url, token)
    return {
      success: response.status === 200,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const addProperty = async (property, token) => {
  const url = '/property/add'
  try {
    const response = await performApiPost(url, property, token)
    return {
      success: response.status === 200,
      propertyId: response.data.propertyId,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const boostPropertyById = async (propertyId, token) => {
  const url = '/property/boost'

  const data = {
    propertyId,
  }

  try {
    const response = await performApiPost(url, data, token)
    return {
      success: response.status === 200,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getAllProperties = async token => {
  try {
    const response = await performApiGet('/property', token)
    const propertiesWithUnorderedPhotos = response.data
    return {
      success: response.status === 200,
      properties: propertiesWithUnorderedPhotos.map(property => new Property(property)),
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getAllFeedProperties = async token => {
  try {
    const response = await performApiGet('/feeds/property', token)

    return {
      success: response.status === 200,
      properties: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getSelectedProperties = async (propertyIds, token) => {
  const url = '/property/selected'

  const data = { propertyIds }

  try {
    const response = await performApiPost(url, data, token)

    return {
      success: response.status === 200,
      properties: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getSelectedUnadvertisedProperties = async (propertyIds, token) => {
  const url = '/property/selected/unadvertised'

  const data = { propertyIds }

  try {
    const response = await performApiPost(url, data, token)

    return {
      success: response.status === 200,
      ...response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getBoostableProperties = async token => {
  try {
    const response = await performApiGet('/property/boostable', token)

    return {
      success: response.status === 200,
      properties: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getUnadvertisedProperties = async token => {
  try {
    const response = await performApiGet('/property/unadvertised', token)

    return {
      success: response.status === 200,
      properties: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getPropertiesForPaymentSuccessPage = async (propertyIds, token) => {
  const url = '/property/payment-success'

  const data = { propertyIds }

  try {
    const response = await performApiPost(url, data, token)
    return {
      success: response.status === 200,
      properties: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const getPropertyCount = async token => {
  const url = '/property/count'

  const response = await performApiGet(url, token)

  return response.data
}

export const deletePropertyPhoto = async (token, photoSrc) => {
  const url = `/property/photo/delete/?src=${photoSrc}`

  try {
    const response = await performApiDelete(url, token)
    return {
      success: response.status === 200,
    }
  } catch (exception) {
    return { success: false }
  }
}

const updateProperty = async (url, payload, token) => {
  try {
    const response = await performApiPatch(url, payload, token)
    return {
      success: response.status === 200,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const delistMultipleProperties = (propertyIds, token) => {
  return updateProperty('/property/delist', { propertyIds }, token)
}

export const listMultipleProperties = (propertyIds, token) => {
  return updateProperty('/property/list', { propertyIds }, token)
}

export const boostMultipleProperties = (propertyIds, token) => {
  return updateProperty('/property/boost', { propertyIds }, token)
}

export const changeAvailabilityForMultipleProperties = (
  propertyIds,
  availableFrom,
  availableUntil,
  token
) => {
  return updateProperty(
    '/property/availability',
    { propertyIds, availableFrom, availableUntil },
    token
  )
}

export const advertisePropertiesUsingAdvertiseCredits = async (payload, token) => {
  try {
    const response = await performApiPost('/ad-credits', payload, token)

    return {
      success: response.status === 200,
      errorMessage: response.data,
    }
  } catch (exception) {
    return { success: false }
  }
}

export const updatePropertyLettingType = (payload, token) => {
  return updateProperty('/property/update/letting-type', payload, token)
}

export const updatePropertyAvailability = (payload, token) => {
  return updateProperty('/property/update/availability', payload, token)
}

export const updatePropertyBedrooms = (payload, token) => {
  return updateProperty('/property/update/bedrooms', payload, token)
}

export const updatePropertyBathrooms = (payload, token) => {
  return updateProperty('/property/update/bathrooms', payload, token)
}

export const updatePropertyAmenities = (payload, token) => {
  return updateProperty('/property/update/amenities', payload, token)
}

export const updatePropertyBillsIncluded = (payload, token) => {
  return updateProperty('/property/update/bills', payload, token)
}

export const updatePropertyPhotos = (payload, token) => {
  return updateProperty('/property/update/photos', payload, token)
}

export const updatePropertyDescription = (payload, token) => {
  return updateProperty('/property/update/description', payload, token)
}

export const updatePropertyReferenceNumber = (payload, token) => {
  return updateProperty('/property/update/reference-number', payload, token)
}

export const updatePropertyVideoTour = (payload, token) => {
  return updateProperty('/property/update/video-tour', payload, token)
}

export const updatePropertyVirtualTour = (payload, token) => {
  return updateProperty('/property/update/virtual-tour', payload, token)
}

export default {
  addProperty,
  getPropertyById,
  getAllProperties,
  listProperty: relistProperty,
  delistProperty,
  togglePropertyListingStatus,
  updatePropertyBathrooms,
  getPropertyCount,
  deletePropertyPhoto,
}
