import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { MostPopularPill, BestValuePill } from '@afs/components/Pill'

import styles from './boostQuantity.module.scss'

const FieldBoostQuantityGroup = ({ name, options, selectedOption, handleSelectOption }) => {
  return (
    <fieldset className={styles.fieldset} data-testid="boost-quantity-group">
      <legend className={styles.legend}>Select a package</legend>
      <ul className={styles.list}>
        {options.map(({ numberOfCredits, totalCost, costPerCredit, currency }) => {
          const isChecked = numberOfCredits === selectedOption.numberOfCredits
          const isPopular = numberOfCredits === 50
          const isBestValue = numberOfCredits === 100

          const formattedCostPerCredit = parseFloat(costPerCredit).toFixed(2)

          return (
            <li className={styles.listItem} key={numberOfCredits}>
              <input
                id={numberOfCredits}
                className={styles.input}
                name={name}
                type="radio"
                value={numberOfCredits}
                checked={isChecked}
                onChange={() => handleSelectOption({ numberOfCredits, totalCost })}
                data-testid="boost-quantity-input"
              />
              <label
                htmlFor={numberOfCredits}
                className={classNames(styles.label, {
                  [styles.checked]: isChecked,
                })}
                data-testid="boost-quantity-label"
              >
                <span className={styles.content}>
                  {isPopular && (
                    <MostPopularPill className={styles.pill}>Most Popular</MostPopularPill>
                  )}
                  {isBestValue && <BestValuePill className={styles.pill}>Best Value</BestValuePill>}
                  <span className={styles.description} data-testid="boost-quantity-description">
                    {numberOfCredits} Boost credits
                  </span>
                </span>
                <span>
                  <span
                    className={styles.totalPrice}
                    data-testid="boost-quantity-total-price"
                  >{`${currency}${totalCost}`}</span>
                  <span
                    className={styles.priceEach}
                    data-testid="boost-quantity-price-each"
                  >{`${currency}${formattedCostPerCredit} each`}</span>
                </span>
              </label>
            </li>
          )
        })}
      </ul>
    </fieldset>
  )
}

FieldBoostQuantityGroup.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.object.isRequired,
  handleSelectOption: PropTypes.func.isRequired,
}

export default FieldBoostQuantityGroup
