import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntercom } from 'react-use-intercom'
import { Helmet } from 'react-helmet-async'
import { OverlayProvider } from '@afs/components/OverlayContext'

import { StateProvider } from './appStateContext'
import { createInitialState } from './stateInitialiser'
import useTabbingFocus from './hooks/useTabbingFocusListener/'
import { config } from './config'

import '@afs/styles/main.scss'
import '@afs/components/styles/landlord-admin.min.css'

if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React)

  const ReactDOM = require('react-dom')
  const axe = require('react-axe')
  const axeConfig = {
    rules: [
      {
        // disable colour contrast checks for the AFS brand
        id: 'color-contrast',
        enabled: false,
      },
    ],
  }
  axe(React, ReactDOM, 1000, axeConfig)
}

const App = ({ intercomActive = true, children }) => {
  useTabbingFocus(window)

  const { boot, shutdown, hide, update } = useIntercom()

  useEffect(() => {
    if (intercomActive) {
      boot()
      update({ hideDefaultLauncher: false })
    } else {
      hide()
      update({ hideDefaultLauncher: true })
      shutdown()
    }
  }, [intercomActive])

  return (
    <StateProvider state={createInitialState()}>
      <OverlayProvider>
        <Helmet>
          {config.environment !== 'local' ? (
            <script type="text/javascript" defer src="/static/js/gtm.js" />
          ) : null}
        </Helmet>

        <noscript
          dangerouslySetInnerHTML={{
            __html: `
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MRTQC53" height="0" width="0"
              style="display: none; visibility: hidden;"></iframe>
          `,
          }}
          data-testid="gtm-iframe"
        />
        {children}
      </OverlayProvider>
    </StateProvider>
  )
}

App.propTypes = {
  children: PropTypes.any,
  intercomActive: PropTypes.bool,
}

export default App
