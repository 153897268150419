
const reorderPhotos = (photoItems, order, idToRemove) => {
  const updatedItems = photoItems.filter(photo => photo.id !== idToRemove)
  const updatedOrder = order.filter(i => i !== idToRemove)

  const newUpdatedItems = updatedItems.map(item => item.id > idToRemove
    ? {
      ...item,
      id: item.id - 1
    }
    : item)

  const newUpdatedOrder = updatedOrder.map(itemId => itemId > idToRemove
    ? itemId - 1
    : itemId)

    return {
      items: newUpdatedItems,
      order: newUpdatedOrder,
    }
}

export default reorderPhotos
