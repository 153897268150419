import React from 'react'
import PropTypes from 'prop-types'
import FieldPropertyAddress from '../molecules/FieldPropertyAddress'
import AddressFinder from '../../services/addressFinder'
import locationMatcher from '../../services/locationMatcher'

const addressFinder = AddressFinder(locationMatcher)

const FieldPropertyAddressAdapter = ({ input: { name, onChange, value }, notificationService }) => (
  <FieldPropertyAddress
    name={name}
    existingAddress={value}
    onUpdateAddress={selectedAddress => onChange(selectedAddress)}
    addressService={addressFinder}
    notificationService={notificationService}
  />
)

FieldPropertyAddressAdapter.propTypes = {
  input: PropTypes.object,
  notificationService: PropTypes.object,
}

export default FieldPropertyAddressAdapter
