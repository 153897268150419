import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Address from '../../../models/address'
import Property from '../../../models/property'

import { config } from '../../../config'

import Heading from '../../atoms/Heading'
import PropertyTitleAndAddress from '../../molecules/PropertyTitleAndAddress'
import ConfirmationOverlay from '../ConfirmationOverlay'
import IconText from '../../molecules/IconText'
import Picture from '../../atoms/Picture'
import ImagePlaceholder from '../../atoms/ImagePlaceholder'
import AdStatusTag from '../../atoms/AdStatusTag'
import { FadeInOutGroup } from '../../transitions/FadeInOut'

import RemoveIcon from '../../../svgs/icons/remove.svg'

import './styles.scss'

const SelectedPropertiesInBasket = ({
  className,
  heading,
  selectedProperties,
  deselectProperty,
}) => {
  const componentClasses = classNames('selected-properties', className)
  const multiplePropertiesSelected = selectedProperties.length > 1

  const renderRemoveFromBasketButton = onClick => {
    return (
      <button
        className="selected-properties__remove-button"
        data-testid="remove-property-overlay-button"
        onClick={onClick}
      >
        <RemoveIcon className="selected-properties__remove" data-testid="remove-property" />
      </button>
    )
  }

  const renderOverlayContent = selectedProperty => {
    const {
      address,
      coverPhoto,
      status,
      advertisedFrom,
      advertisedUntil,
      isListed,
    } = selectedProperty
    const addressFormat = new Address(selectedProperty.address)
    const addressFirstTwoLines = addressFormat.firstTwoLines()
    const property = new Property(selectedProperty)
    const imageApiUrl = config.imgApiUrl
    const hasPhoto = coverPhoto !== ''
    const notListedAndAdvertised = !isListed && status === 'CurrentlyAdvertised'

    return (
      <div className={notListedAndAdvertised ? '' : 'selected-properties__overlay-content'}>
        <div className="selected-properties__overlay-content-image-wrapper">
          {hasPhoto ? (
            <Picture
              className="selected-properties__overlay-content-image"
              alt={addressFirstTwoLines}
              src={coverPhoto}
              lazyload={false}
              sources={[
                {
                  id: 'any',
                  src: `${imageApiUrl}w=396/${coverPhoto}`,
                  media: '(min-width: 1px)',
                },
              ]}
            />
          ) : (
            <ImagePlaceholder
              className="selected-properties__overlay-content-image-placeholder"
              maxWidth={396}
              alt="No image"
            />
          )}
        </div>
        <p className="selected-properties__overlay-content-address">
          <span className="selected-properties__overlay-content-address--bold">
            {`${addressFirstTwoLines}, `}
          </span>
          {`${address.area}, ${address.townCity}, ${address.postcode}`}
        </p>
        <IconText
          className="selected-properties__overlay-content-bedrooom-icon"
          icon="bedSize"
          text={
            <span data-testid="admin-rooms">
              <strong>
                {property.numberOfAvailableBedrooms()} / {property.totalBedrooms()}
              </strong>{' '}
              beds available
            </span>
          }
        />
        <AdStatusTag
          className="selected-properties__overlay-content-status"
          status={status}
          startDate={advertisedFrom}
          expiryDate={advertisedUntil}
          isListed={isListed}
        />
      </div>
    )
  }

  return (
    <div className={componentClasses}>
      {heading && (
        <Heading level={4} className="selected-properties__heading">
          {heading}
        </Heading>
      )}

      <ul className="selected-properties__list">
        <FadeInOutGroup
          renderItems={fadeInOutItem =>
            selectedProperties.map(property => {
              return fadeInOutItem(
                <li className="selected-properties__item" data-testid="summary-item">
                  <PropertyTitleAndAddress
                    className="selected-properties"
                    numberOfBedrooms={property.bedrooms.length}
                    accommodationType={property.accommodationType}
                    address={new Address(property.address)}
                  />
                  {multiplePropertiesSelected && (
                    <ConfirmationOverlay
                      renderOverlayContent={() => renderOverlayContent(property)}
                      renderButton={renderRemoveFromBasketButton}
                      onConfirm={() => deselectProperty(property.id)}
                      overlayHeading="Remove from selection"
                      buttonText="Remove from selection"
                    />
                  )}
                </li>,
                property.id
              )
            })
          }
        />
      </ul>
    </div>
  )
}

SelectedPropertiesInBasket.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  selectedProperties: PropTypes.array.isRequired,
  deselectProperty: PropTypes.func.isRequired,
}

export default SelectedPropertiesInBasket
