import React from 'react'
import PropTypes from 'prop-types'

import { withRouter, Route } from 'react-router-dom'
import { LoggedOutAuthStateProvider } from './authContext'

const PublicRoute = props => {
  const { component: Component, ...rest } = props
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <LoggedOutAuthStateProvider>
            <Component {...props} />
          </LoggedOutAuthStateProvider>
        )
      }}
    />
  )
}

PublicRoute.propTypes = {
  history: PropTypes.object,
  component: PropTypes.func,
}

export default withRouter(PublicRoute)
