import React from 'react'
import PropTypes from 'prop-types'

import EnquiryCard from '../../molecules/EnquiryCard'

import enquiryGrid from './enquiryGrid.module.scss'

const EnquiryGrid = ({ enquiries, deleteEnquiry, isFeedAccount, ...props }) => {
  return (
    <div className={enquiryGrid.grid} {...props}>
      {enquiries.map(enquiry => (
        <EnquiryCard
          key={enquiry.id}
          className={enquiryGrid.item}
          enquiry={enquiry}
          deleteEnquiry={deleteEnquiry}
          isFeedAccount={isFeedAccount}
        />
      ))}
    </div>
  )
}

EnquiryGrid.propTypes = {
  enquiries: PropTypes.array.isRequired,
  deleteEnquiry: PropTypes.func.isRequired,
  isFeedAccount: PropTypes.bool,
}

export default EnquiryGrid
