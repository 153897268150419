import PropertyIds from './models/propertyIds'
import Basket from './models/basket'
import OrderHistory from './models/orderHistory'
import Products from './models/products'
import { GBP } from './models/currency'
import ManagedAccount from './models/managedAccount'
import BillsOptional from './models/billsOptional'

export const createInitialState = () => {
  const currency = GBP
  const products = Products()
  const propertyIds = new PropertyIds()
  const orderHistory = new OrderHistory(products)
  const basket = new Basket(orderHistory, currency, products)
  const managedAccount = new ManagedAccount()
  const billsOptional = new BillsOptional()

  return {
    propertyIds,
    basket,
    orderHistory,
    managedAccount,
    billsOptional,
  }
}
