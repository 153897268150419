import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { logEvent } from '../../logger'
import { useAuthContext } from '../../authContext'
import { useStateContext } from '../../appStateContext'
import requestStatus from '../../constants/requestStatus'
import {
  createBillsPackageQuoteForProperty,
  confirmPropertiesAsBillsOptional,
} from '../../api/billsOptionalApi'
import { notificationService } from '../../container'
import formatDateForApi from '../../utils/formatDateForApi'

import BillsOptionalYourQuotesPage from '../pages/BillsOptionalYourQuotesPage'

export const getQuotePackageErrorText = (properties) => {
  const numberOfPropertiesWithFailedQuotes = properties.filter(
    (property) => property.quote.status === 'failed'
  ).length

  let quoteText = 'quotes'
  let propertiesText = 'some of these properties'

  if (
    numberOfPropertiesWithFailedQuotes === properties.length &&
    numberOfPropertiesWithFailedQuotes > 1
  ) {
    quoteText = 'quotes'
    propertiesText = 'these properties'
  }

  if (
    numberOfPropertiesWithFailedQuotes === properties.length &&
    numberOfPropertiesWithFailedQuotes === 1
  ) {
    quoteText = 'quote'
    propertiesText = 'this property'
  }

  if (numberOfPropertiesWithFailedQuotes === 1 && properties.length > 1) {
    quoteText = 'quote'
    propertiesText = 'one of these properties'
  }

  return { quoteText, propertiesText }
}

export const BillsOptionalYourQuotesPageContainer = ({
  yourQuotesPage,
  useStateContext,
  useAuthContext,
  history,
  createBillsPackageQuoteForProperty,
  confirmPropertiesAsBillsOptional,
  notificationService,
}) => {
  const { billsOptional } = useStateContext()

  const { token } = useAuthContext()

  const propertiesWithQuotesPending = billsOptional.properties().map((property) => ({
    ...property,
    quote: { status: 'pending' },
  }))

  const [properties, setProperties] = useState(propertiesWithQuotesPending)

  const [
    confirmBillsOptionalPropertiesRequestStatus,
    setConfirmBillsOptionalPropertiesRequestStatus,
  ] = useState(requestStatus.idle)

  const updatePropertiesWithASuccessfulQuote = (currentState, quote) => {
    const propertyToUpdate = currentState.find((property) => {
      return property.propertyId === quote.propertyId
    })

    if (!propertyToUpdate) return currentState

    const propertiesCopy = currentState.slice()

    const indexOfProperty = propertiesCopy.indexOf(propertyToUpdate)

    const updatedProperty = { ...propertyToUpdate, quote: { ...quote, status: 'success' } }

    propertiesCopy.splice(indexOfProperty, 1, updatedProperty)

    return propertiesCopy
  }

  const updatePropertiesWithAFailedQuote = (currentState, propertyId) => {
    const propertyToUpdate = properties.find((property) => {
      return property.propertyId === propertyId
    })

    if (!propertyToUpdate) return currentState

    const propertiesCopy = currentState.slice()

    const indexOfProperty = propertiesCopy.indexOf(propertyToUpdate)

    const updatedProperty = { ...propertyToUpdate, quote: { status: 'failed' } }

    propertiesCopy.splice(indexOfProperty, 1, updatedProperty)

    return propertiesCopy
  }

  const updatePropertiesWithAPendingQuote = (currentState, propertyToRetry) => {
    const propertiesCopy = currentState.slice()

    const indexOfProperty = propertiesCopy.indexOf(propertyToRetry)

    const updatedProperty = { ...propertyToRetry, quote: { status: 'pending' } }

    propertiesCopy.splice(indexOfProperty, 1, updatedProperty)

    return propertiesCopy
  }

  const showErrorNotificationIfAnyQuotesFailed = (currentState, nextState) => {
    const isTheLastQuoteToResolve =
      currentState.filter((property) => property.quote.status === 'pending').length === 1

    const atLeastOneQuoteFailed = !!nextState.find((property) => property.quote.status === 'failed')

    if (isTheLastQuoteToResolve && atLeastOneQuoteFailed) {
      const { quoteText, propertiesText } = getQuotePackageErrorText(nextState)

      notificationService.showErrorNotification(
        'Oops!',
        `We couldn’t retrieve ${quoteText} for ${propertiesText}.`
      )
    }
  }

  const createBillsPackageQuote = (property) => {
    const payload = {
      propertyId: property.propertyId,
      postcode: property.address.postcode,
      numberOfTenants: property.numberOfBedrooms,
      tenancyStartDate: formatDateForApi(new Date()),
    }

    notificationService.clearNotifications()

    createBillsPackageQuoteForProperty(payload, token).then((response) => {
      if (response.success) {
        const { quote } = response

        setProperties((prevState) => {
          const nextState = updatePropertiesWithASuccessfulQuote(prevState, quote)

          showErrorNotificationIfAnyQuotesFailed(prevState, nextState)

          return nextState
        })
      }

      if (!response.success) {
        setProperties((prevState) => {
          const nextState = updatePropertiesWithAFailedQuote(prevState, property.propertyId)

          showErrorNotificationIfAnyQuotesFailed(prevState, nextState)

          return nextState
        })
      }
    })
  }

  useEffect(() => {
    if (properties.length === 0) {
      history.push('/landlord-admin/bills-optional/select-properties')
      return
    }

    properties.forEach(createBillsPackageQuote)

    logEvent('Fused Get Quotes', {
      'Number of quotes requested': properties.length,
    })
  }, [])

  const propertyIdsForPropertiesWithQuotes = properties
    .filter((property) => property.quote.status === 'success')
    .map((property) => property.propertyId)

  const handleRetryQuote = (propertyId) => {
    notificationService.clearNotifications()

    const propertyToRetry = properties.find((property) => {
      return property.propertyId === propertyId
    })

    setProperties((prevState) => {
      return updatePropertiesWithAPendingQuote(prevState, propertyToRetry)
    })

    createBillsPackageQuote(propertyToRetry)
  }

  const handleRemoveProperty = (propertyId, onSuccessCallback) => {
    notificationService.clearNotifications()

    setProperties((prevState) => {
      return prevState.filter((property) => property.propertyId !== propertyId)
    })

    const billsOptionalPropertiesWithPropertyRemoved = billsOptional
      .properties()
      .filter((property) => property.propertyId !== propertyId)

    billsOptional.selectProperties(billsOptionalPropertiesWithPropertyRemoved)

    onSuccessCallback()

    notificationService.showSuccessNotification('Success!', 'Property removed from selection.')
  }

  const handleConfirmBillsOptionalProperties = () => {
    notificationService.clearNotifications()
    setConfirmBillsOptionalPropertiesRequestStatus(requestStatus.waiting)

    const payload = {
      propertyIds: propertyIdsForPropertiesWithQuotes,
    }

    confirmPropertiesAsBillsOptional(payload, token).then((response) => {
      setConfirmBillsOptionalPropertiesRequestStatus(requestStatus.idle)

      if (response.success) {
        billsOptional.viewModelsOfPropertiesToConfirm().forEach((property) => {
          logEvent('Fused Confirm Property Bills Optional', {
            'Page Source': 'Your Quotes Page',
            'Property ID': property.propertyId,
            'Property City': property.address.line2.split(',')[1].trim(),
            'Property Number of Beds': property.numberOfBedrooms,
            'Property Rent From': property.rentFrom,
          })
        })

        billsOptional.clearProperties()

        history.push(
          `/landlord-admin/bills-optional/thank-you?numberOfProperties=${propertyIdsForPropertiesWithQuotes.length}`
        )
      } else {
        notificationService.showErrorNotification(
          'Oops!',
          'Something went wrong. Please try again.'
        )
      }
    })
  }

  const isConfirmingBillsOptionalProperties =
    confirmBillsOptionalPropertiesRequestStatus === requestStatus.waiting

  return yourQuotesPage(
    properties,
    handleRemoveProperty,
    handleRetryQuote,
    handleConfirmBillsOptionalProperties,
    history,
    isConfirmingBillsOptionalProperties
  )
}

BillsOptionalYourQuotesPageContainer.propTypes = {
  yourQuotesPage: PropTypes.func.isRequired,
  useStateContext: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
  createBillsPackageQuoteForProperty: PropTypes.func.isRequired,
  confirmPropertiesAsBillsOptional: PropTypes.func.isRequired,
  notificationService: PropTypes.shape({
    showErrorNotification: PropTypes.func.isRequired,
    showSuccessNotification: PropTypes.func.isRequired,
    clearNotifications: PropTypes.func.isRequired,
  }).isRequired,
}

export const BillsOptionalYourQuotesPageContainerCompositionRoot = ({ history }) => {
  return (
    <BillsOptionalYourQuotesPageContainer
      yourQuotesPage={(
        properties,
        handleRemoveProperty,
        handleRetryQuote,
        handleConfirmBillsOptionalProperties,
        history,
        isLoading
      ) => (
        <BillsOptionalYourQuotesPage
          properties={properties}
          handleRemoveProperty={handleRemoveProperty}
          handleRetryQuote={handleRetryQuote}
          handleConfirmBillsOptionalProperties={handleConfirmBillsOptionalProperties}
          history={history}
          isLoading={isLoading}
        />
      )}
      history={history}
      useAuthContext={useAuthContext}
      useStateContext={useStateContext}
      createBillsPackageQuoteForProperty={createBillsPackageQuoteForProperty}
      confirmPropertiesAsBillsOptional={confirmPropertiesAsBillsOptional}
      notificationService={notificationService}
    />
  )
}

BillsOptionalYourQuotesPageContainerCompositionRoot.propTypes = {
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
}
