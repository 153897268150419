import React from 'react'

import ManagePropertiesPageTemplate from '../../templates/ManagePropertiesPageTemplate'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Button from '../../atoms/Button'
import NotFoundAnnouncement from '../../molecules/NotFoundAnnouncement'

import NotFound from '../../../svgs/illustrations/property-not-found.svg'

import propertyNotFound from './propertyNotFound.module.scss'

const PropertyNotFoundPage = () => {
  return (
    <ManagePropertiesPageTemplate
      title="Property Not Found | AFS"
      header={<Header />}
      footer={<Footer dark />}
    >
      <div className={propertyNotFound.layout}>
        <NotFoundAnnouncement
          className={propertyNotFound.notFoundWrapper}
          icon={NotFound}
          heading="This property could not be found"
          subHeading="It is likely that this property does not exist or it has been deleted."
          button={() => (
            <Button to="/landlord-admin/properties" primary data-testid="properties-button">
              Back to properties
            </Button>
          )}
          data-testid="property-not-found"
        />
      </div>
    </ManagePropertiesPageTemplate>
  )
}

export default PropertyNotFoundPage
