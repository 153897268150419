import axios from 'axios'
import Address from '../models/address'

const baseApiUrl = 'https://api.getAddress.io/find/'
const apiKey = 'ex6wluA8n0Wt3-xkhmMvPg21025'

const substituteTownCityWithAfsLocationName = townCity => {
  return townCity === 'Newcastle upon Tyne' ? 'Newcastle' : townCity
}

const AddressFinder = locationMatcher => {
  const getAddress = async postcode => {
    try {
      const response = await axios.get(
        `${baseApiUrl}${postcode}?api-key=${apiKey}&expand=true&sort=true`
      )
      const addresses = response.data.addresses.map(address => {
        const locationName = substituteTownCityWithAfsLocationName(address.town_or_city)

        return new Address({
          flat: address.sub_building_name,
          buildingName: address.building_name,
          buildingNumber: address.building_number,
          street: address.thoroughfare,
          townCity: locationName,
          postcode: response.data.postcode,
          latitude: response.data.latitude,
          longitude: response.data.longitude,
          hasValidLocation: locationMatcher.isValidLocation(locationName),
        })
      })

      return {
        success: true,
        addresses,
      }
    } catch (error) {
      let message = 'Something wrong happened.'
      let status = 500

      if (error.response) {
        status = error.response.status
        switch (status) {
          case 404: {
            message = `No addresses found for ${postcode}.`
            break
          }
          case 400: {
            message = `The postcode ${postcode} was not found.`
            break
          }
          default:
        }
      }

      return {
        status,
        message,
        success: false,
      }
    }
  }

  return {
    getAddress,
  }
}

export default AddressFinder
