import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import useMedia from '../../../hooks/useMedia'
import Address from '../../../models/address'
import propertyStatus from '../../../constants/propertyAdvertisementStatus'
import { getItem, setItem } from '../../../services/sessionApi'

import ManagePropertiesPageTemplate from '../../templates/ManagePropertiesPageTemplate'
import ManagePropertiesHero from '../../atoms/ManagePropertiesHero'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Heading from '../../atoms/Heading'
import IconButton from '../../molecules/IconButton'
import SelectablePropertyCardListView from '../../molecules/SelectablePropertyCardListView'
import NotFoundAnnouncement from '../../molecules/NotFoundAnnouncement'
import SelectedPropertiesMenu from '../../molecules/SelectedPropertiesMenu'
import MultiSelectActionOverlay from '../../molecules/MultiSelectActionOverlay'
import UpdatingPropertiesOverlay from '../../molecules/UpdatingPropertiesOverlay'
import Button from '../../atoms/Button'
import { Filtering } from '../../molecules/Filtering'
import FilterByAddressOrReference from './filters/FilterByAddressOrReference'
import PropertyCardListView from '../../molecules/PropertyCardListView'
import MultiDiscountBanner from '../../molecules/MultiDiscountBanner'

import NewProperty from '../../../svgs/illustrations/new-property.svg'
import NoMatches from '../../../svgs/illustrations/no-matches-found.svg'

import hero from './modules/hero.module.scss'
import layout from './modules/layout.module.scss'
import noProperties from './modules/noProperties.module.scss'
import selectedPropertiesMenu from './modules/selectedPropertiesMenu.module.scss'

const MULTI_PROPERTY_BANNER_DISMISSED_KEY = 'mpdBannerDismissed'

const PropertiesPage = ({
  properties,
  filteredProperties,
  setFilteredProperties,
  selectedPropertyIds,
  onSelectProperty,
  deselectAllProperties,
  selectAllProperties,
  delistProperties,
  listProperties,
  clearNotifications,
  updatingProperties,
}) => {
  const [currentSearchTerm, setCurrentSearchTerm] = useState('')
  const [overlay, setOverlay] = useState({
    active: false,
  })

  const hasDismissedMultiPropertyDiscountBanner = !!getItem(MULTI_PROPERTY_BANNER_DISMISSED_KEY)

  const hasAtLeastOneNotAdvertisedProperty =
    properties.filter((property) => property.status === propertyStatus.notAdvertised).length > 0

  const [showMultiDiscountBanner, setShowMultiDiscountBanner] = useState(
    () => hasAtLeastOneNotAdvertisedProperty && !hasDismissedMultiPropertyDiscountBanner
  )

  const handleDismissMultiDiscountBanner = () => {
    setShowMultiDiscountBanner(false)
    setItem(MULTI_PROPERTY_BANNER_DISMISSED_KEY, true)
  }

  const isMobile = useMedia(['(max-width: 768px)'], [true], false)

  const handleOverlayClose = () => {
    setOverlay({
      active: false,
    })
  }

  const showOverlay = () => {
    setOverlay({
      active: true,
    })
    clearNotifications()
  }

  const onDelistProperties = () => {
    delistProperties(selectedPropertyIds)
    handleOverlayClose()
  }

  const onListProperties = () => {
    listProperties(selectedPropertyIds)
    handleOverlayClose()
  }

  const selectedProperties = properties.filter((property) =>
    selectedPropertyIds.includes(property.id)
  )
  const allPropertiesSelected = properties.length === selectedPropertyIds.length
  const hideSelectOrDeselectAllButton = filteredProperties.length !== properties.length
  const userHasSelectedAProperty = selectedPropertyIds.length > 0
  const showLoadingOverlay = updatingProperties.active

  const renderHero = () => {
    return (
      <>
        <ManagePropertiesHero
          className={classNames({
            [hero.noBoxShadow]: userHasSelectedAProperty,
          })}
          pattern="green"
        >
          <div className={hero.row}>
            <div className={hero.details}>
              <Heading className={hero.heading}>Properties</Heading>
            </div>
            <IconButton
              className={hero.button}
              to="/landlord-admin/add-property"
              icon={{
                icon: 'plus-circle',
                width: '24',
                height: '24',
              }}
              primary
            >
              Add new property
            </IconButton>
          </div>
        </ManagePropertiesHero>

        <MultiDiscountBanner
          className={classNames(hero.multiDiscountBanner, {
            [hero.multiDiscountBannerNegativeTopMargin]: userHasSelectedAProperty,
          })}
          isVisible={showMultiDiscountBanner}
          handleDismiss={handleDismissMultiDiscountBanner}
        />
      </>
    )
  }

  const renderSelectedPropertiesMenu = () => {
    return (
      <SelectedPropertiesMenu
        className={selectedPropertiesMenu.menu}
        isActive={userHasSelectedAProperty}
        allPropertiesSelected={allPropertiesSelected}
        numberOfSelectedProperties={selectedPropertyIds.length}
        renderButton={() => {
          return (
            <Button
              className={selectedPropertiesMenu.button}
              violet
              onClick={showOverlay}
              data-testid="available-actions-button"
            >
              <span>See available actions</span>
            </Button>
          )
        }}
        handleDeselectAll={deselectAllProperties}
        handleSelectAll={selectAllProperties}
        hideAllPropertiesButton={hideSelectOrDeselectAllButton}
      />
    )
  }

  const renderNoPropertiesAddedAnnouncement = () => {
    return (
      <div className={layout.inner}>
        <NotFoundAnnouncement
          className={noProperties.wrapper}
          icon={NewProperty}
          heading="You haven't added any properties yet"
          subHeading="Adding a property is super easy and it only takes a minute."
          button={
            <IconButton
              className={`${hero.button} ${noProperties.button}`}
              to="/landlord-admin/add-property"
              icon={{
                icon: 'plus-circle',
                width: '24',
                height: '24',
              }}
              primary
              data-testid="no-properties-button"
            >
              Add property
            </IconButton>
          }
          data-testid="no-properties"
        />
      </div>
    )
  }

  const renderNoPropertiesMatchingFiltersFoundAnnouncement = (removeAllFilters) => {
    return (
      <NotFoundAnnouncement
        className={noProperties.wrapper}
        icon={NoMatches}
        heading="Couldn't match any properties"
        subHeading="Try changing the search term."
        button={
          <Button violet onClick={removeAllFilters}>
            View all properties
          </Button>
        }
        data-testid="no-matching-properties"
      />
    )
  }

  const renderFooter = () => {
    return (
      <>
        <Footer dark />
      </>
    )
  }

  return (
    <>
      <ManagePropertiesPageTemplate
        title="Your Properties | AFS"
        header={<Header />}
        footer={renderFooter()}
        hero={renderHero()}
        className={layout.main}
        multiSelectMenu={renderSelectedPropertiesMenu()}
      >
        {properties.length > 0 && (
          <div className={layout.inner}>
            <Filtering
              originalData={properties}
              setFilteredData={setFilteredProperties}
              onRemoveAll={[() => setCurrentSearchTerm('')]}
              applyTogether
            >
              {({ removeAll, ...props }) => {
                return (
                  <>
                    <FilterByAddressOrReference
                      className={layout.searchField}
                      currentSearchTerm={currentSearchTerm}
                      setCurrentSearchTerm={setCurrentSearchTerm}
                      tabIndex={userHasSelectedAProperty && !isMobile ? '-1' : '0'}
                      {...props}
                    />
                    {filteredProperties.length === 0 &&
                      renderNoPropertiesMatchingFiltersFoundAnnouncement(removeAll)}
                  </>
                )
              }}
            </Filtering>
            {filteredProperties.length > 0 && (
              <div>
                {filteredProperties.map((property) => {
                  const propertyIsSelected = selectedPropertyIds.includes(property.id)

                  const propertyAddressString = new Address(property.address)
                    .addressFields()
                    .join(',')

                  return (
                    <SelectablePropertyCardListView
                      key={property.id}
                      className={layout.card}
                      to={`/landlord-admin/property/${property.id}`}
                      checkboxName={property.id.toString()}
                      checkboxValue={propertyAddressString}
                      checkboxLabel={propertyAddressString}
                      checked={propertyIsSelected}
                      checkboxOnChange={() => onSelectProperty(property.id)}
                      data-testid="property-grid-item"
                      data-propertyid={property.id}
                    >
                      <PropertyCardListView property={property} />
                    </SelectablePropertyCardListView>
                  )
                })}
              </div>
            )}
          </div>
        )}
        {properties.length === 0 && renderNoPropertiesAddedAnnouncement()}
      </ManagePropertiesPageTemplate>

      {showLoadingOverlay ? (
        <UpdatingPropertiesOverlay>{updatingProperties.message}</UpdatingPropertiesOverlay>
      ) : (
        <MultiSelectActionOverlay
          overlay={overlay}
          selectedProperties={selectedProperties}
          handleOverlayClose={handleOverlayClose}
          handleDelistProperties={onDelistProperties}
          handleListProperties={onListProperties}
        />
      )}
    </>
  )
}

PropertiesPage.propTypes = {
  properties: PropTypes.array.isRequired,
  filteredProperties: PropTypes.array.isRequired,
  setFilteredProperties: PropTypes.func.isRequired,
  selectedPropertyIds: PropTypes.array.isRequired,
  onSelectProperty: PropTypes.func.isRequired,
  deselectAllProperties: PropTypes.func.isRequired,
  selectAllProperties: PropTypes.func.isRequired,
  delistProperties: PropTypes.func.isRequired,
  listProperties: PropTypes.func.isRequired,
  clearNotifications: PropTypes.func.isRequired,
  updatingProperties: PropTypes.object.isRequired,
}

export default PropertiesPage
