import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import CognitoUser from './models/cognitoUser'

const AuthContext = React.createContext()

export const AuthStateProvider = ({ children, state }) => (
  <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
)

export const LoggedOutAuthStateProvider = ({ children }) => (
  <AuthContext.Provider
    value={{
      user: CognitoUser.default(),
      isAuthenticated: false,
      token: '',
    }}
  >
    {children}
  </AuthContext.Provider>
)

AuthStateProvider.propTypes = {
  children: PropTypes.node,
  state: PropTypes.object,
}

LoggedOutAuthStateProvider.propTypes = {
  children: PropTypes.node,
}

export const useAuthContext = () => useContext(AuthContext)
