import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { config } from '../../../../../config'

import Overlay from '../../../../molecules/Overlay'
import Toggle from '../../../../molecules/TinyToggle'
import Button from '../../../../atoms/Button'
import IconButton from '../../../../molecules/IconButton'
import LazyImage from '../../../../atoms/LazyImage'
import Link from '../../../../atoms/Link'
import Notification from '../../../../molecules/Notification'

import PictureIcon from '../../../../../svgs/icons/picture.svg'
import Arrow from '../../../../../svgs/icons/chevron-right.svg'

import styles from './viewTestimonialOverlay.module.scss'

const renderPropertyImage = property => {
  if (property.image) {
    return (
      <div className={styles.imageWrapper} data-testid="testimonial-image">
        <LazyImage
          className={styles.image}
          src={`${config.imgApiUrl}w=112/${property.image}`}
          dataSrc={`${config.imgApiUrl}w=112/${property.image}`}
          alt={property.addressFirstLine}
        />
      </div>
    )
  }

  return (
    <div className={styles.imageWrapper} data-testid="testimonial-image-placeholder">
      <PictureIcon className={styles.icon} data-testid="picture-icon" />
    </div>
  )
}

const renderPropertyCard = propertyDetails => {
  return (
    <Link
      className={styles.propertyCard}
      href={`/landlord-admin/property/${propertyDetails.id}`}
      target="_blank"
      data-testid="testimonial-property-card"
    >
      {renderPropertyImage(propertyDetails)}
      <div className={styles.address}>
        <strong className={styles.addressTitle} data-testid="testimonial-address-title">
          {propertyDetails.addressFirstLine}
        </strong>
        <p className={styles.addressSubtitle} data-testid="testimonial-address-subtitle">
          <span>{propertyDetails.addressSecondLine}</span>,{' '}
          <span className={styles.postcode}>{propertyDetails.addressPostcode}</span>
        </p>
      </div>
      <Arrow className={styles.arrowRight} />
    </Link>
  )
}

const ViewTestimonialOverlay = ({
  testimonial,
  propertyDetails,
  toggleDisplayTestimonial,
  isActive,
  handleCloseOverlay,
  openDeleteTestimonialOverlay,
}) => {
  if (testimonial === null) return null

  const [toggling, setToggling] = useState(false)
  const [showNotification, setShowNotification] = useState(false)

  const toggleText = {
    enabled: <span className={styles.toggleText}>Displayed</span>,
    disabled: <span className={styles.toggleText}>Not displayed</span>,
  }

  const toggleDisplay = () => {
    setToggling(true)

    toggleDisplayTestimonial(testimonial.id, testimonial.displayed).then(response => {
      if (!response.success) {
        setShowNotification(true)
      }
      setTimeout(() => setToggling(false), 600) // Delay toggle animation
    })
  }

  return (
    <Overlay
      id="view-testimonial"
      heading="Testimonial"
      isActive={isActive}
      onClose={handleCloseOverlay}
      closeLabel="Close"
      preserveScrollPositionOnClose
    >
      {showNotification && (
        <Notification
          className={styles.notification}
          heading="Oops!"
          text="We couldn't change the display state of this testimonial. Please try again later."
        />
      )}
      <article>
        <section className={styles.sender}>
          <div>
            <strong className={styles.name} data-testid="testimonial-tenant-name">
              {testimonial.tenantName}
            </strong>
            <span className={styles.email} data-testid="testimonial-email">
              {testimonial.tenantEmail}
            </span>
          </div>
          <span className={styles.date} data-testid="testimonial-date">
            {testimonial.date}
          </span>
        </section>
        <section className={styles.body} data-testid="testimonial-body">
          {testimonial.text}
        </section>
      </article>
      <Toggle
        className={styles.toggle}
        enabled={testimonial.displayed}
        toggleText={toggleText}
        handleOnClick={toggleDisplay}
        inactive={toggling}
        data-testid="testimonial-toggle"
      />
      {renderPropertyCard(propertyDetails)}
      <div className={styles.buttons}>
        <Button
          className={styles.closeButton}
          onClick={handleCloseOverlay}
          data-testid="testimonial-close-button"
        >
          Close
        </Button>
        <IconButton
          className={styles.deleteButton}
          icon={{
            icon: 'delete',
            width: '18',
            height: '18',
            viewBox: '0 0 18 18',
          }}
          primary
          onClick={openDeleteTestimonialOverlay}
          data-testid="testimonial-delete-button"
        >
          Delete
        </IconButton>
      </div>
    </Overlay>
  )
}

ViewTestimonialOverlay.propTypes = {
  testimonial: PropTypes.shape({
    id: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    new: PropTypes.bool,
    displayed: PropTypes.bool.isRequired,
    tenantName: PropTypes.string.isRequired,
    tenantEmail: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  propertyDetails: PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.string.isRequired,
    addressFirstLine: PropTypes.string.isRequired,
    addressSecondLine: PropTypes.string.isRequired,
    addressPostcode: PropTypes.string.isRequired,
  }),
  toggleDisplayTestimonial: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  handleCloseOverlay: PropTypes.func.isRequired,
  openDeleteTestimonialOverlay: PropTypes.func.isRequired,
}

export default ViewTestimonialOverlay
