import React from 'react'
import PropTypes from 'prop-types'

import { useStateContext } from '../../../appStateContext'

import TwoColumnPageTemplate from '../../templates/TwoColumnPageTemplate'
import Header from '../../organisms/Header'
import FormPageHeading from '../../molecules/FormPageHeading'
import Button from '../../atoms/Button'
import PublishPropertyCard from '../../molecules/PublishPropertyCard'
import Notification from '../../molecules/Notification'

import './styles.scss'
import TreePlantingMessage from '../../atoms/TreePlantingMessage'

const HardcodedProgressBar = () => {
  return (
    <div
      className="hardcoded-progress-bar"
      role="progressbar"
      aria-valuenow="100"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <div className="hardcoded-filler" />
    </div>
  )
}

const PublishPropertyPage = ({ history, property, displayWarningNotification }) => {
  const { propertyIds } = useStateContext()

  return (
    <TwoColumnPageTemplate
      header={<Header />}
      title="Landlord Admin | AFS"
      progressBar={<HardcodedProgressBar />}
      className="publish-property-page"
      leftColumn={
        <>
          <div>
            <div className="publish-property__notifications-container">
              <Notification
                className="publish-property__notification"
                type="success"
                text="Your property has been saved!"
                heading=""
              />
              {displayWarningNotification === 'warning' && (
                <Notification
                  className="publish-property__notification"
                  type="warning"
                  text="Our system was not able to find the geographic coordinates for this property. Our team has been notified and will update it manually. In the meanwhile, your property won’t be displayed on the website or in search results. We’ll email you as soon as the location has been updated."
                />
              )}
            </div>

            <div className="publish-property">
              <FormPageHeading heading="Advertise property" className="publish-property__heading" />
              <div className="publish-property__subheading">
                <p>
                  Your property has been successfully saved but it is
                  <strong className="publish-property__subheading--strong">
                    {' '}
                    currently not advertised
                  </strong>
                  .
                </p>
                <p>
                  The property must be advertised in order for it to show up on the website and in
                  search results.
                </p>
              </div>
              {property && (
                <PublishPropertyCard
                  className="publish-property-page__property-card"
                  property={property}
                  data-testid="publish-property-card"
                />
              )}
              <Button
                onClick={(event) => {
                  event.preventDefault()

                  propertyIds.select(property.id)

                  history.push('/landlord-admin/payment-method')
                }}
                primary
                data-testid="advertise-button"
              >
                Advertise property
              </Button>
              <TreePlantingMessage className="publish-property-page__tree-planting-message" />
            </div>
          </div>
        </>
      }
      rightColumnInnerClassName="publish-property-page__right-column-inner"
      rightColumn={<TreePlantingMessage />}
    />
  )
}

PublishPropertyPage.propTypes = {
  history: PropTypes.object,
  property: PropTypes.object,
  displayWarningNotification: PropTypes.string,
}

export default PublishPropertyPage
