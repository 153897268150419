import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import { useAuthContext } from '../../authContext'
import { useStateContext } from '../../appStateContext'
import { getAccount, getAccountHasEverAdvertisedAProperty } from '../../api/accountApi'

import HomePage from '../pages/HomePage'
import HomePageFeeds from '../pages/HomePageFeeds'
import LoadingPage from '../pages/LoadingPage'
import LandlordAndLettingAgentHomePageContainer from './LandlordAndLettingAgentHomeContainer'
import FeedsHomePageContainer from './FeedsHomePageContainer'

export const HomePageContainer = ({
  landlordAndLettingAgentHomePageContainer,
  feedsHomePageContainer,
  useAuthContext,
}) => {
  const { accountType } = useAuthContext()

  if (accountType === 'Feed') {
    return feedsHomePageContainer()
  }

  return landlordAndLettingAgentHomePageContainer()
}

HomePageContainer.propTypes = {
  landlordAndLettingAgentHomePageContainer: PropTypes.func.isRequired,
  feedsHomePageContainer: PropTypes.func.isRequired,
  useAuthContext: PropTypes.func.isRequired,
}

export const HomePageContainerCompositionRoot = () => (
  <HomePageContainer
    landlordAndLettingAgentHomePageContainer={() => (
      <LandlordAndLettingAgentHomePageContainer
        getLandlord={getAccount}
        getLandlordHasEverAdvertisedAProperty={getAccountHasEverAdvertisedAProperty}
        homePage={(name, accountHasAdvertised, isLettingAgent, hasBillsOptionalProperties) => (
          <HomePage
            name={name}
            accountHasAdvertised={accountHasAdvertised}
            isLettingAgent={isLettingAgent}
            hasBillsOptionalProperties={hasBillsOptionalProperties}
          />
        )}
        loadingPage={() => <LoadingPage />}
        useAuthContext={useAuthContext}
        useStateContext={useStateContext}
        redirect={path => <Redirect to={path} />}
      />
    )}
    feedsHomePageContainer={() => (
      <FeedsHomePageContainer
        getAccount={getAccount}
        homePage={(name, hasActiveFeed) => (
          <HomePageFeeds name={name} hasActiveFeed={hasActiveFeed} />
        )}
        loadingPage={() => <LoadingPage />}
        useAuthContext={useAuthContext}
        useStateContext={useStateContext}
        redirect={path => <Redirect to={path} />}
      />
    )}
    useAuthContext={useAuthContext}
  />
)
